import React, {useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Table from '@frontend/ui-kit/Components/Table';
import Tabs from '@frontend/ui-kit/Components/Tabs';
import BroadcastTemplates from './BroadcastTemplates';
import withBroadcastOnboarding from '../../../../HOC/withBroadcastOnboarding';
import {redirectTo} from '../../../../actions/general';
import {requestBroadcasts, setActiveBroadcastsTab} from '../../../../actions/broadcaster';
import {getBroadcasts, getActiveBroadcastsTab} from '../../../../selectors/broadcaster';
import {formatDate, equal, toCapitalize} from '../../../../utils';
import {BROADCAST_STATUSES, ROUTES, SORTING_ORDERS} from '../../../../constants';
import './index.scss';

const BROADCASTS_TABS = ['Scheduled Broadcasts'];
const SCHEDULED_BROADCASTS_TAB_INDEX = 0;

const STICKER_TYPE_BY_BROADCAST_STATUS = {
    [BROADCAST_STATUSES.scheduled]: STICKER_TYPES.success,
    [BROADCAST_STATUSES.pending]: STICKER_TYPES.warning,
    [BROADCAST_STATUSES.sent]: STICKER_TYPES.primary,
    [BROADCAST_STATUSES.drafting]: STICKER_TYPES.default
};

const BROADCASTS_TABLE_COLUMNS = [
    {Header: 'Title', accessor: 'name'},
    // eslint-disable-next-line react/prop-types
    {Header: 'Status', accessor: 'status', Cell: ({value}) => <Sticker type={STICKER_TYPE_BY_BROADCAST_STATUS[value]}>{toCapitalize(value)}</Sticker>},
    {Header: 'Delivery Date and Time (CT)', accessor: 'scheduled_for', Cell: ({value}) => formatDate(value, 'MM/dd/yyyy h:mm aa')},
    {Header: 'Delivered', accessor: 'delivered_count', width: 140},
    {Header: 'Opened', accessor: 'state_finished_count', width: 140}
];

const WelcomeLayout = () => {
    const [unitCount, setUnitCount] = useState(0);
    const activeTab = useSelector(getActiveBroadcastsTab);
    const broadcasts = useSelector(getBroadcasts);
    const dispatch = useDispatch();

    const onClickBroadcastsTableRow = ({original}) => dispatch(redirectTo(`${ROUTES.broadcastDetails}/${original.id}`));

    const onChangeTab = activeTab => dispatch(setActiveBroadcastsTab(activeTab));

    const onFetchBroadcastsTableData = useMemo(() => async ({pageIndex, pageSize, sortBy, orderBy}) => {
        const params = {
            sortBy: sortBy ?? 'created_at',
            orderBy: orderBy ?? SORTING_ORDERS.desc,
            limit: pageSize,
            offset: pageIndex * pageSize
        };
        const {count} = await dispatch(requestBroadcasts(params));

        setUnitCount(count);
    }, [dispatch]);

    const broadcastsTableProps = {
        data: broadcasts,
        columns: BROADCASTS_TABLE_COLUMNS,
        isFilterable: false,
        onFetchData: onFetchBroadcastsTableData,
        onRowClick: onClickBroadcastsTableRow,
        unitCount
    };

    return (
        <div className='broadcaster-welcome-layout'>
            <Heading type={HEADING_TYPES['4']}>Create a Broadcast from a Template</Heading>
            <BroadcastTemplates/>
            <Tabs tabs={BROADCASTS_TABS} activeTab={activeTab} onChange={onChangeTab} className='broadcasts'>

                <ContentSection className='broadcasts__list'>
                    {equal(activeTab, SCHEDULED_BROADCASTS_TAB_INDEX) && <Table {...broadcastsTableProps}/>}
                </ContentSection>
            </Tabs>
        </div>
    );
};

export {WelcomeLayout as TestableWelcomeLayout};
export default withBroadcastOnboarding(WelcomeLayout);
