import React from 'react';
import PagesTabs from '../../../Containers/shared/PagesTabs';
import {ROUTES} from '../../../constants';

const REPORTING_ROUTES = [
    ROUTES.summaryReporting,
    ROUTES.programSavingsReporting,
    ROUTES.utilizationReporting,
    ROUTES.memberActivationReporting,
    ROUTES.reportDownloadsReporting
];

const ReportingPagesTabs = () => <PagesTabs routes={REPORTING_ROUTES}/>;

export {ReportingPagesTabs as TestableReportingPagesTabs};
export default ReportingPagesTabs;
