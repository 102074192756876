import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import classnames from 'classnames';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Link from '@frontend/ui-kit/Components/Link';
import Separator from '@frontend/ui-kit/Components/Separator';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import FileUploader from '../../shared/FileUploader';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CopyableField from '../CopyableField';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {equal, normalizeBoolean, normalizePositiveNumber, trimStart, validateAlphanumeric, validateRequired} from '../../../utils';
import {FILE_FORMATS, ACCEPTABLE_TEXT_FORMATS, DEFAULT_PHONE_PLACEHOLDER, FIN_ACCOUNT_TYPES, TELEMED_BLOG_URL} from '../../../constants';
import {CONDITION_OPTIONS, FIN_ACCOUNT_OPTIONS, MEDICAL_PLAN_TYPE_OPTIONS} from '../../../options';
import {requestConsultFees} from '../../../actions/benefits';
import './index.scss';

const memberIdPrefixContent = 'Select carriers require a prefix, which can be alphanumeric characters, for all medical member IDs for the population.';
const telemedConsultFeeContent = (
    <React.Fragment>
        Current law is ambiguous on proper telemedicine consult fees under an HDHP plan.
        HealthJoy’s platform permits you, the client, to select the fee to charge your employees and their dependents.
        Please consult your benefits advisor before electing the fee.
        Read our blog post <Link href={TELEMED_BLOG_URL}>here</Link> to learn more about telemedicine and HSA eligibility.
    </React.Fragment>
);

/* istanbul ignore next */
const validateOnSubmit = (values, {isCustomizedMode} = {}) => {
    const {is_financial_account: isFinAccount, financial_account_type: finAccountType} = values;
    const isHsaFinAccount = equal(finAccountType, FIN_ACCOUNT_TYPES.hsa);

    return {
        name: validateRequired(values.name),
        type: validateRequired(values.type),
        sbc_url: validateRequired(values.sbc_url),
        deductible_embedded: validateRequired(values.deductible_embedded, {isBooleanExpected: true}),
        group_number: isCustomizedMode ? undefined : validateRequired(values.group_number),
        financial_account_type: isFinAccount ? validateRequired(values.financial_account_type) : undefined,
        hsa_telemed_consult_fee: isHsaFinAccount ? validateRequired(values.hsa_telemed_consult_fee?.toString()) : undefined
    };
};

/* istanbul ignore next */
const validate = values => ({
    member_id_prefix: validateAlphanumeric(values.member_id_prefix)
});

const MedicalPlanBasicInfo = ({isReadonly, onChangeValidatableFile, isCustomizedMode}) => {
    const dispatch = useDispatch();
    const {planPeriodId} = useParams();
    const form = useForm();
    const {values} = useFormState();
    const {financial_account_type: finAccountType, is_financial_account: isFinAccount} = values;
    const isHsaFinAccount = equal(finAccountType, FIN_ACCOUNT_TYPES.hsa);

    const resetFields = useCallback(fields => () => {
        return form.batch(() => fields.forEach(field => form.change(field, null)));
    }, [form]);

    const onChangeFinAccountType = useCallback(async value => {
        resetFields(['hsa_telemed_consult_fee']);

        if (equal(value, FIN_ACCOUNT_TYPES.hsa)) {
            const {data} = await dispatch(requestConsultFees(planPeriodId));
            form.change('hsa_telemed_consult_fee', data.hsa_telemed_consult_fee);
        }
    }, [planPeriodId, dispatch, form, resetFields]);

    return (
        <div className='medical-plan-basic-info'>
            <Row className='medical-plan-basic-info-row'>
                <Column className='medical-plan-basic-info-row__column' sm={4}>
                    <CopyableField name='name' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Plan Name'/>}
                    </CopyableField>
                </Column>

                <Column className='medical-plan-basic-info-row__column' sm={4}>
                    <CopyableField name='app_name' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Plan Name Show in the App'/>}
                    </CopyableField>
                </Column>

                <Column className='medical-plan-basic-info-row__column' sm={4}>
                    <CopyableField name='type'>
                        {props => <Select {...props} readonly={isReadonly} placeholder='Type to select...' options={MEDICAL_PLAN_TYPE_OPTIONS} label='Plan Type'/>}
                    </CopyableField>
                </Column>
            </Row>

            <Separator className='medical-plan-basic-info__separator medical-plan-basic-info__separator_dashed'/>

            <Row className='medical-plan-basic-info-row'>
                <Column sm={4} className='upload-file-wrapper medical-plan-basic-info-row__column'>
                    <CopyableField name='spd_urls'>
                        {props => <FileUploader {...props} readonly={isReadonly} isMultiple accept={ACCEPTABLE_TEXT_FORMATS} className='plan-details-form-label' label='Upload SPD'/>}
                    </CopyableField>
                </Column>

                <Column sm={4} className='upload-file-wrapper medical-plan-basic-info-row__column'>
                    <CopyableField name='sbc_url' onChange={onChangeValidatableFile('sbc_url', 'sbc_valid')}>
                        {props => <FileUploader {...props} readonly={isReadonly} accept={[`.${FILE_FORMATS.pdf}`]} className='plan-details-form-label' label='Upload SBC' isRequired/>}
                    </CopyableField>

                    <CopyableField name='sbc_valid'>{props => <Input {...props} type='hidden'/>}</CopyableField>
                </Column>

                <Column sm={4} className='upload-file-wrapper medical-plan-basic-info-row__column'>
                    <CopyableField name='sample_id_card_urls'>
                        {props => <FileUploader {...props} readonly={isReadonly} isMultiple accept={ACCEPTABLE_TEXT_FORMATS} className='plan-details-form-label' label='Upload Sample ID Card'/>}
                    </CopyableField>
                </Column>
            </Row>

            <Separator className='medical-plan-basic-info__separator medical-plan-basic-info__separator_dashed'/>

            <Row className='medical-plan-basic-info-row'>
                {!isCustomizedMode && (
                    <Column className='medical-plan-basic-info-row__column' sm={6}>
                        <CopyableField name='group_number' parse={trimStart}>
                            {props => <Input {...props} readonly={isReadonly} maxLength={40} placeholder='Please enter...' wrapperClassName='mb-12' label='Group ID' isRequired/>}
                        </CopyableField>
                    </Column>
                )}

                <Column className='medical-plan-basic-info-row__column' sm={isCustomizedMode ? 4 : 6}>
                    <CopyableField name='member_id_prefix' parse={trimStart}>
                        {props => (
                            <Input {...props}
                                readonly={isReadonly}
                                maxLength={30}
                                placeholder='Please enter...'
                                wrapperClassName={classnames({'mb-12': !isCustomizedMode})}
                                label={<LabeledTooltip title='Member ID Prefix' content={memberIdPrefixContent}/>}/>
                        )}
                    </CopyableField>
                </Column>

                <Column className='medical-plan-basic-info-row__column' sm={isCustomizedMode ? 4 : 6}>
                    <CopyableField name='carrier_phone'>
                        {props => <Input {...props} readonly={isReadonly} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Member Support Phone'/>}
                    </CopyableField>
                </Column>

                <Column className='medical-plan-basic-info-row__column' sm={isCustomizedMode ? 4 : 6}>
                    <CopyableField name='preauthorization_phone'>
                        {props => <Input {...props} readonly={isReadonly} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Preauthorization (optional)'/>}
                    </CopyableField>
                </Column>
            </Row>

            <Separator className='medical-plan-basic-info__separator medical-plan-basic-info__separator_dashed'/>

            <Row className='medical-plan-basic-info-row'>
                <Column sm={6}>
                    <CopyableField name='deductible_embedded'>
                        {props => <Select {...props} readonly={isReadonly} label='Does Embedded Deductible Apply?' isRequired options={CONDITION_OPTIONS} wrapperClassName='mb-12'/>}
                    </CopyableField>
                </Column>

                <Column sm={6}/>

                <Column className='medical-plan-basic-info-row__column' sm>
                    <CopyableField name='is_financial_account' onChange={resetFields(['financial_account_type', 'hsa_telemed_consult_fee'])} parse={normalizeBoolean}>
                        {props => <RadioGroup {...props} readonly={isReadonly} options={CONDITION_OPTIONS} label='Is this plan eligible for a financial account?'/>}
                    </CopyableField>
                </Column>

                <Column sm className='financial-account medical-plan-basic-info-row__column'>
                    {isFinAccount && (
                        <CopyableField name='financial_account_type' onChange={onChangeFinAccountType}>
                            {props => <Select {...props} readonly={isReadonly} options={FIN_ACCOUNT_OPTIONS} placeholder='Type to select...' label='Which Account?' wrapperClassName='mb-12'/>}
                        </CopyableField>
                    )}

                    {isHsaFinAccount && (
                        <CopyableField name='hsa_telemed_consult_fee' parse={normalizePositiveNumber}>
                            {props => (
                                <Input {...props}
                                    readonly={isReadonly}
                                    placeholder=''
                                    icon={ICON_TYPES.money}
                                    iconClassName='financial-account__icon'
                                    description='This consult fee will apply to all HSA plans'
                                    label={<LabeledTooltip title='Telemedicine Consult Fee' content={telemedConsultFeeContent}/>}/>
                            )}
                        </CopyableField>
                    )}
                </Column>
            </Row>

            <Separator className='medical-plan-basic-info__separator medical-plan-basic-info__separator_dashed'/>
        </div>
    );
};

MedicalPlanBasicInfo.propTypes = {
    isReadonly: PropTypes.bool,
    isCustomizedMode: PropTypes.bool,
    onChangeValidatableFile: PropTypes.func
};

export {validate, validateOnSubmit, MedicalPlanBasicInfo as TestableMedicalPlanBasicInfo};
export default MedicalPlanBasicInfo;
