import {RECEIVE_REPORTING_INFO, SET_REPORTING_PLAN_PERIOD, SET_ACTIVE_REPORTING_TAB} from '../actions/actionTypes';
import {LOCAL_STORAGE_ITEMS, REPORTING_PLAN_PERIODS} from '../constants';

export const initialState = {
    activeTab: 0,
    planPeriod: localStorage.getItem(LOCAL_STORAGE_ITEMS.reportingPlanPeriod) || REPORTING_PLAN_PERIODS.year,
    info: {
        error: null,
        isRoiVisible: null,
        isTelemedVisible: null,
        isMedicalBillVisible: null,
        isMskVisible: null,
        isPreCertificationVisible: null,
        summary: {},
        program_savings: {},
        utilization: {},
        member_activation: {},
        download_reports: {}
    }
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case SET_ACTIVE_REPORTING_TAB: {
            const {activeTab} = payload;

            return {...state, activeTab};
        }

        case SET_REPORTING_PLAN_PERIOD: {
            const {planPeriod} = payload;

            return {...state, planPeriod};
        }

        case RECEIVE_REPORTING_INFO: {
            const info = {...state.info, ...payload.info};

            return {...state, info};
        }

        default: {
            return state;
        }
    }
};
