import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {redirectTo} from '../../../../actions/general';

const ActionsCell = ({row: {index}}) => {
    const dispatch = useDispatch();
    const {search, pathname} = useLocation();
    const onGoToErrorDetails = () => dispatch(redirectTo(`${pathname}/${index}${search}`));

    return (
        <Button data-testid='view-button' onClick={onGoToErrorDetails} type={BUTTON_TYPES.tertiary}>
            View
            <Icon type={ICON_TYPES.arrowRight} className='ml-2'/>
        </Button>
    );
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number
    })
};

export {ActionsCell as TestableActionsCell};
export default ActionsCell;
