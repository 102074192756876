import React from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-final-form';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Row from '@frontend/ui-kit/Components/Row';
import Separator from '@frontend/ui-kit/Components/Separator';
import {Field} from '../../shared/FormComponents';

const TiersDeductible = ({isMoop, preferredInNetworkDescription, inNetworkDescription, outOfNetworkDescription}) => {
    const {getState} = useForm();
    const {values} = getState();
    const {medtool_plan: {cost_share_variance: costShareVariance} = {}} = values || {};
    const tier2Description = costShareVariance?.tier2_description || (isMoop ? 'MOOP Tier 2' : 'Deductible Tier 2');
    const tier3Description = costShareVariance?.tier3_description || (isMoop ? 'MOOP Tier 3' : 'Deductible Tier 3');

    return (
        <React.Fragment>
            <Row>
                <Column sm={4}>
                    <Field name='tier2_preferred_in_network'>
                        {props => <Input {...props} autoComplete='on' maxLength={255} label={`${tier2Description}\n(${preferredInNetworkDescription})`}/>}
                    </Field>
                </Column>
                <Column sm={4}>
                    <Field name='tier2_in_network'>
                        {props => <Input {...props} autoComplete='on' maxLength={255} label={`${tier2Description}\n(${inNetworkDescription})`}/>}
                    </Field>
                </Column>
                <Column sm={4}>
                    <Field name='tier2_out_of_network'>
                        {props => <Input {...props} autoComplete='on' maxLength={255} label={`${tier2Description}\n(${outOfNetworkDescription})`}/>}
                    </Field>
                </Column>
            </Row>

            <Separator/>

            <Row>
                <Column sm={4}>
                    <Field name='tier3_preferred_in_network'>
                        {props => <Input {...props} autoComplete='on' maxLength={255} label={`${tier3Description}\n(${preferredInNetworkDescription})`}/>}
                    </Field>
                </Column>
                <Column sm={4}>
                    <Field name='tier3_in_network'>
                        {props => <Input {...props} autoComplete='on' maxLength={255} label={`${tier3Description}\n(${inNetworkDescription})`}/>}
                    </Field>
                </Column>
                <Column sm={4}>
                    <Field name='tier3_out_of_network'>
                        {props => <Input {...props} autoComplete='on' maxLength={255} label={`${tier3Description}\n(${outOfNetworkDescription})`}/>}
                    </Field>
                </Column>
            </Row>
        </React.Fragment>
    );
};

TiersDeductible.propTypes = {
    preferredInNetworkDescription: PropTypes.string.isRequired,
    inNetworkDescription: PropTypes.string.isRequired,
    outOfNetworkDescription: PropTypes.string.isRequired,
    isMoop: PropTypes.bool.isRequired
};

export {TiersDeductible as TestableTiersDeductible};
export default TiersDeductible;
