import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import VersionHistoryList from '../../../Components/shared/VersionHistoryList';
import VersionHistoryDetails from '../../../Components/shared/VersionHistoryDetails';
import './index.scss';

const VersionHistoryPopup = ({versionHistoryList = [], title, description, actionBar, selectedVersion, onChangeVersion, versionHistoryDetails}) => {
    return (
        <div>
            <div className='version-history-popup__header'>
                <Heading className='version-history-popup__header-title' type={HEADING_TYPES['2']}>{title}</Heading>
                <div className='version-history-popup__header-caption'>{description}</div>
            </div>

            {!versionHistoryList.length && (
                <Row>
                    <Column sm={12}>
                        <Text type={TEXT_TYPES.bodyBold} className='version-history-popup__no-data'>No data found</Text>
                    </Column>
                </Row>
            )}

            {!!versionHistoryList.length && (
                <Row>
                    <Column sm={3}>
                        <VersionHistoryList versionHistoryList={versionHistoryList} selectedVersion={selectedVersion} onChange={onChangeVersion}/>
                    </Column>
                    <Column sm={9}>
                        <VersionHistoryDetails {...versionHistoryDetails}/>
                    </Column>
                </Row>
            )}

            <Row>
                <Column smOffset={9} sm={3}>
                    {actionBar && <div className='version-history-popup__action-bar'>{actionBar}</div>}
                </Column>
            </Row>
        </div>
    );
};

VersionHistoryPopup.propTypes = {
    versionHistoryList: PropTypes.arrayOf(PropTypes.shape({
        version: PropTypes.number,
        created_at: PropTypes.string,
        created_by: PropTypes.number
    })),
    versionHistoryDetails: PropTypes.shape({
        added: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        edited: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        deleted: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    }),
    title: PropTypes.string,
    description: PropTypes.string,
    actionBar: PropTypes.node,
    selectedVersion: PropTypes.number,
    onChangeVersion: PropTypes.func
};

export {VersionHistoryPopup as TestableVersionHistoryPopup};
export default React.memo(VersionHistoryPopup);
