import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Table from '@frontend/ui-kit/Components/Table';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {requestContactList} from '../../../actions/contacts';
import {getProfilePermissions} from '../../../selectors/general';
import {getContactList} from '../../../selectors/contacts';
import {ROUTES, USER_ROLE_LABELS, USER_TYPE_LABELS} from '../../../constants';
import './index.scss';

const ContactsBoard = ({onComplete}) => {
    const dispatch = useDispatch();
    const profilePermissions = useSelector(getProfilePermissions);
    const contactList = useSelector(getContactList);

    useEffect(() => {
        dispatch(requestContactList());
    }, [dispatch]);

    const tableProps = {
        data: contactList,
        columns: [
            {Header: 'First Name', accessor: 'first_name'},
            {Header: 'Last Name', accessor: 'last_name'},
            {Header: 'Email Address', accessor: 'email', isSortable: false},
            {Header: 'Contact Type', accessor: 'user_type', Cell: ({value: userType}) => USER_TYPE_LABELS[userType]},
            {Header: 'Role', accessor: 'role', Cell: ({value: role}) => USER_ROLE_LABELS[role]},
            {
                Header: 'Actions',
                accessor: 'id',
                Cell: ({value: id}) => {
                    return <Link href={`${ROUTES.contactInfo}?userId=${id}`}>View{profilePermissions.contact_edit && ' & Edit'}</Link>;
                },
                isSortable: false,
                width: 100,
                isHidden: !profilePermissions.contact_edit && !profilePermissions.contact_delete
            }
        ],
        pageSize: 10,
        isFilterable: false
    };

    return (
        <ContentSection className='contacts-board'>
            <Table {...tableProps}/>

            {profilePermissions.timeline_edit && (
                <div className='contacts-board-action-bar'>
                    <Button className='contacts-board-action-bar__button' type={BUTTON_TYPES.primary} onClick={onComplete}>Continue</Button>
                </div>
            )}
        </ContentSection>
    );
};

ContactsBoard.propTypes = {
    onComplete: PropTypes.func.isRequired
};

export {ContactsBoard as TestableContactsBoard};
export default withSubStepCompletion(ContactsBoard);
