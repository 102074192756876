import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';

const UnsavedFormPopup = ({onLeave, onStay}) => {
    const actionBar = (
        <React.Fragment>
            <Button type={BUTTON_TYPES.secondary} onClick={onLeave}>Leave Without Saving</Button>
            <Button type={BUTTON_TYPES.primary} onClick={onStay}>Stay on Page</Button>
        </React.Fragment>
    );
    const props = {title: 'Are you sure you want to leave without saving?', actionBar};

    return (
        <PopupContent {...props}>
            <Text>You have unsaved changes and will lose that information if you leave this page.</Text>
        </PopupContent>
    );
};

UnsavedFormPopup.propTypes = {
    onLeave: PropTypes.func.isRequired,
    onStay: PropTypes.func.isRequired
};

export {UnsavedFormPopup as TestableUnsavedFormPopup};
export default React.memo(UnsavedFormPopup);
