import {useCallback} from 'react';

const useSortByText = () => useCallback((rowA, rowB, columnId) => {
    const a = rowA.values[columnId]?.toLowerCase() || '';
    const b = rowB.values[columnId]?.toLowerCase() || '';

    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}, []);

export default useSortByText;
