import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import useBroadcast from '../../../../hooks/useBroadcast';
import {Form, Field, FieldArray} from '../../../shared/FormComponents';
import DynamicActionBar from '../../../shared/DynamicActionBar';
import withBroadcastStepCommon from '../../../../HOC/withBroadcastStepCommon';
import {requestWalletLinks} from '../../../../actions/broadcaster';
import {getWalletLinks, getBroadcast} from '../../../../selectors/broadcaster';
import {equal, getEqual, validateRequired, validateSecureLink, validateMaxLength} from '../../../../utils';
import {FORMS, BROADCAST_STATUSES, BROADCASTER_LINK_TYPES, BROADCAST_VERSIONS} from '../../../../constants';
import analyticsService from '../../../../analyticsService';
import {Messages} from './ContentStepFields/Messages';
import {LinkIcons} from './ContentStepFields/LinkIcons';
import {WalletSelector} from './ContentStepFields/WalletSelector';
import {WebLinkField} from './ContentStepFields/WebLinkField';
import BroadcastPreview from './BroadcastPreview';
import {FileField} from './ContentStepFields/FileField';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    const {name, content = {}} = values;
    const {link, link_type: linkType, messages = []} = content.entry ?? {};
    const validateLink = {
        [BROADCASTER_LINK_TYPES.wallet]: () => undefined,
        [BROADCASTER_LINK_TYPES.file]: () => undefined,
        [BROADCASTER_LINK_TYPES.web]: link => validateSecureLink(link)
    }[linkType ?? BROADCASTER_LINK_TYPES.wallet];

    const messageErrors = messages.map(value => validateRequired(value) || validateMaxLength(value, 128));
    const areMessageErrors = messageErrors.some(Boolean);

    return {
        name: validateRequired(name) || validateMaxLength(name, 255),
        content: {
            push_text: validateRequired(content.push_text) || validateMaxLength(content.push_text, 175),
            entry: {
                messages: areMessageErrors ? messageErrors : undefined,
                link: link ? validateLink(link) : undefined
            }
        }
    };
};

const getEnhancedValues = (walletLinks, values) => {
    const {link_type: linkType} = values.content?.entry ?? {link_type: BROADCASTER_LINK_TYPES.wallet};
    const {content, condition_templates_arguments: conditionTemplatesArguments, ...restValues} = values;
    const {link, link_title: linkTitle, ...restEntry} = content.entry;
    const walletLink = walletLinks.find(getEqual(link, 'link'));
    const LINK_TITLE_BY_TYPE = {
        [BROADCASTER_LINK_TYPES.wallet]: walletLink?.name,
        [BROADCASTER_LINK_TYPES.web]: null,
        [BROADCASTER_LINK_TYPES.file]: link?.name || linkTitle
    };
    const linkData = {link, link_title: link ? LINK_TITLE_BY_TYPE[linkType] : null, link_type: link ? linkType : null};

    return {
        ...restValues,
        status: BROADCAST_STATUSES.drafting,
        content: {...content, entry: {...restEntry, ...linkData, type: 'broadcast'}},
        condition_templates_arguments: {
            ...conditionTemplatesArguments,
            'has-wallet-category': equal(linkType, BROADCASTER_LINK_TYPES.wallet) && link ? {wallet_category: walletLink?.category} : undefined
        }
    };
};

// FYI: we decide to set 'wallet_link' as default value for link_type due to many sources of initial data for form (template, custom broadcast, backend).
// We need to use default one if link_type is undefined or null because of UI requirements but we should send null to backend if link fiend isn't filled. (21.01.2020, Oleh)
const ContentStep = props => {
    const dispatch = useDispatch();

    const walletLinks = useSelector(getWalletLinks);
    const {id} = useParams();
    const selectedBroadcast = useSelector(getBroadcast);
    const broadcastId = id || selectedBroadcast?.id;
    const broadcast = useBroadcast(broadcastId, BROADCAST_VERSIONS.v1);

    useEffect(() => {
        dispatch(requestWalletLinks());
    }, [dispatch]);

    const onSubmit = async values => {
        analyticsService.trackClicked('Continue', {broadcast_id: broadcast.id});

        return props.submit(getEnhancedValues(walletLinks, values));
    };

    return (
        <Form name={FORMS.broadcastContentStep} initialValues={broadcast} onSubmit={onSubmit} validate={validate}>
            {({handleSubmit, values, form}) => {
                const {link_type: linkType} = values.content?.entry ?? {link_type: BROADCASTER_LINK_TYPES.wallet};
                const onClickSubmitButton = isTest => () => form.change('is_test', isTest);

                return (
                    <div>
                        {broadcast && (
                            <form onSubmit={handleSubmit} noValidate>
                                <Row className='broadcast-wizard-step-v1'>
                                    <Column sm={8} className='broadcast-section'>
                                        <Field name='name'>
                                            {props => <Input {...props} maxLength={255} isAllowInputOverflow label='Campaign Name' isRequired wrapperClassName='mb-12'/>}
                                        </Field>

                                        <Field name='content.push_text'>
                                            {props => <Input {...props} maxLength={175} isAllowInputOverflow label='Notification Message' isRequired wrapperClassName='mb-12'/>}
                                        </Field>

                                        <Heading type={HEADING_TYPES['4']} className='broadcast-section__title'>
                                            Journey Content
                                        </Heading>
                                        <FieldArray name='content.entry.messages'>
                                            {props => <Messages {...props}/>}
                                        </FieldArray>
                                        <LinkIcons linkType={linkType}/>

                                        <Field name='content.entry.link_type' type='hidden'>
                                            {props => <Input {...props}/>}
                                        </Field>
                                        <Field name='content.entry.link_title' type='hidden'>
                                            {props => <Input {...props}/>}
                                        </Field>
                                        {equal(linkType, BROADCASTER_LINK_TYPES.wallet) && <WalletSelector walletLinks={walletLinks}/>}
                                        {equal(linkType, BROADCASTER_LINK_TYPES.web) && <WebLinkField/>}
                                        {equal(linkType, BROADCASTER_LINK_TYPES.file) && <FileField/>}
                                    </Column>
                                    <Column sm={4} className='broadcast-section'>
                                        <div className='broadcast-phone-preview' data-testid='phone-preview'>
                                            <div className='title'>Broadcast Preview</div>
                                            <BroadcastPreview messages={values?.content?.entry?.messages}
                                                walletLinks={walletLinks}
                                                linkType={linkType}
                                                link={values?.content?.entry?.link}/>
                                        </div>
                                    </Column>
                                </Row>

                                <DynamicActionBar>
                                    <Button type={BUTTON_TYPES.primary} isSubmit onClick={onClickSubmitButton(false)}>Continue</Button>
                                </DynamicActionBar>
                            </form>
                        )}
                    </div>
                );
            }}
        </Form>
    );
};

ContentStep.propTypes = {
    isBroadcasterOnboarded: PropTypes.bool,
    submit: PropTypes.func.isRequired,
    requestBroadcasterOnboardedSetting: PropTypes.func.isRequired
};

export {ContentStep as TestableContentStep};
export default withBroadcastStepCommon(ContentStep);
