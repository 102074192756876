import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import FileUploader from '../../shared/FileUploader';
import {Field} from '../../shared/FormComponents';
import CarrierAutocomplete from '../../shared/CarrierAutocomplete';
import useForm from '../../../hooks/useForm';
import {getCoreCarriers} from '../../../selectors/shared';
import {getEqual, validateRequired} from '../../../utils';
import {ACCEPTABLE_IMAGE_FORMATS, MAX_IMAGE_SIZE} from '../../../constants';

const CARRIER_NAME = 'carrier_name';
const CARRIER_LOGO_NAME = 'carrier_logo';

/* istanbul ignore next */
const validateOnSubmit = values => ({
    [CARRIER_NAME]: validateRequired(values[CARRIER_NAME])
});

const CoreCarrierInfo = ({isReadonly}) => {
    const form = useForm();
    const coreCarriers = useSelector(getCoreCarriers);

    const onCarrierChange = useCallback(value => {
        const carrierData = coreCarriers.find(getEqual(value, 'issuer_name')) || {};
        const {logo_url: logo = null} = carrierData;

        form.change(CARRIER_LOGO_NAME, logo);
    }, [form, coreCarriers]);

    return (
        <Row className='core-carrier-info'>
            <Column className='core-carrier-info__column' sm={4}>
                <CarrierAutocomplete isCore name={CARRIER_NAME} isReadonly={isReadonly} onChange={onCarrierChange} label='Carrier'/>
            </Column>

            <Column className='core-carrier-info__column' sm={8}>
                <Field name={CARRIER_LOGO_NAME}>
                    {props => <FileUploader {...props} readonly={isReadonly} isImage accept={ACCEPTABLE_IMAGE_FORMATS} maxSize={MAX_IMAGE_SIZE} label='Upload Carrier Logo' caption='240px x 50px'/>}
                </Field>
            </Column>
        </Row>
    );
};

CoreCarrierInfo.propTypes = {
    isReadonly: PropTypes.bool
};

export {validateOnSubmit, CoreCarrierInfo as TestableCoreCarrierInfo};
export default React.memo(CoreCarrierInfo);
