import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {TIMELINE_STEP_STATUSES} from '../../../constants';
import './index.scss';

const LABEL_BY_STATUS = {
    [TIMELINE_STEP_STATUSES.new]: 'due',
    [TIMELINE_STEP_STATUSES.started]: 'due by',
    [TIMELINE_STEP_STATUSES.incompleted]: <Icon className='timeline-timestamp-label__alert-slim' type={ICON_TYPES.alertCircleFull}/>,
    [TIMELINE_STEP_STATUSES.completed]: <Icon className='timeline-timestamp-label__check' type={ICON_TYPES.confirm}/>
};

const ImplementationTimestamp = React.forwardRef(({value, onClick, status}, ref) => {
    const className = `timeline-timestamp timeline-timestamp_${status}`;
    const label = LABEL_BY_STATUS[status];
    const [month, day, year] = value?.split('/') || [];

    return (
        <div className={className} ref={ref} onClick={onClick}>
            {label && <Text type={TEXT_TYPES.caption} className='timeline-timestamp-label'>{label}</Text>}

            <div className='timeline-timestamp-date'>
                <Text type={TEXT_TYPES.helperBold} className='timeline-timestamp-date__month'>{month}/{day}</Text>
                <Text type={TEXT_TYPES.caption} className='timeline-timestamp-date__year'>{year}</Text>
            </div>
        </div>
    );
});

ImplementationTimestamp.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    status: PropTypes.string
};

export {ImplementationTimestamp as TestableImplementationTimestamp};
export default React.memo(ImplementationTimestamp);
