import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import './index.scss';

const ImportCard = ({title, value}) => (
    <div className='import-card'>
        <Text type={TEXT_TYPES.helper}>{title}</Text>
        <Heading type={HEADING_TYPES['5']} className='mt-2 import-card__value'>{value}</Heading>
    </div>
);

ImportCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export {ImportCard as TestableImportCard};
export default ImportCard;
