import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import DateTimePicker from '@frontend/ui-kit/Components/DateTimePicker';
import usePrevious from '../../../../hooks/usePrevious';
import DynamicActionBar from '../../../shared/DynamicActionBar';
import {Form, Field} from '../../../shared/FormComponents';
import withBroadcastStepCommon from '../../../../HOC/withBroadcastStepCommon';
import {redirectTo} from '../../../../actions/general';
import {requestBroadcasts} from '../../../../actions/broadcaster';
import {getBroadcasts} from '../../../../selectors/broadcaster';
import {pipe, equal, getEqual, negate, isEmpty, isDateWithinRange, validateRequired, getItemKeyValue} from '../../../../utils';
import {FORMS, ROUTES, FAMILY_SEGMENTS, BROADCAST_STATUSES} from '../../../../constants';
import analyticsService from '../../../../analyticsService';
import './index.scss';

const RECOMMENDED_DELIVERY_TIME = {from: '06:00', to: '21:00'};

const RELATIONS_BY_FAMILY_SEGMENT = {
    [FAMILY_SEGMENTS.primary]: ['primary'],
    [FAMILY_SEGMENTS.primarySpouse]: ['primary', 'spouse']
};

/* istanbul ignore next */
const validate = values => {
    return {
        scheduled_for: validateRequired(values.scheduled_for)
    };
};

// FYI: string 'T' we manipulate - delimiter of date and time within 'yyyy-MM-DDTHH:mm'. Also we request list of filtered
// by picked date broadcasts within componentDidUpdate method instead of onChange handler of DateTimePicker
// because we need initial list right after we get current schedule_for from backend. It can be replaced with
// onChange and componentDidMount if we add isReady property on withBroadcastStepCommon level. (25.01.2020, Oleh);
const InformationStep = ({onGoBack, submit, broadcast}) => {
    const dispatch = useDispatch();
    const broadcasts = useSelector(getBroadcasts);
    const prevScheduledFor = usePrevious(broadcast?.scheduled_for);
    useEffect(() => {
        const [prevDeliveryDate, deliveryDate] = [prevScheduledFor, broadcast?.scheduled_for].map(value => value?.split('T')?.[0]);

        if (!deliveryDate || equal(prevDeliveryDate, deliveryDate)) {
            return;
        }

        dispatch(requestBroadcasts({scheduledFor: deliveryDate, status: BROADCAST_STATUSES.scheduled}));
    }, [prevScheduledFor, broadcast, dispatch]);

    const schduleButtonText = 'Schedule';
    const onSubmit = values => {
        analyticsService.trackClicked(schduleButtonText, {broadcast_id: broadcast?.id});
        // TODO: roll segment logic back when HJ is ready (03.03.2021, Oleh)
        // const {family_segment: familySegment, condition_templates_arguments: conditionTemplatesArguments, ...restValues} = values;
        // const relationIn = equal(familySegment, FAMILY_SEGMENTS.all) ? undefined : {relations: RELATIONS_BY_FAMILY_SEGMENT[familySegment]};
        const relationIn = {relations: RELATIONS_BY_FAMILY_SEGMENT[FAMILY_SEGMENTS.primary]};

        const enhancedValues = {
            ...broadcast,
            scheduled_for: values.scheduled_for,
            status: BROADCAST_STATUSES.scheduled,
            condition_templates_arguments: {...broadcast.condition_templates_arguments, 'relation-in': relationIn}
        };

        return submit(enhancedValues);
    };

    const onBackToContent = ({target}) => {
        analyticsService.trackClicked(target.textContent, {broadcast_id: broadcast?.id});
        onGoBack();
    };

    const onSubmitSuccess = () => dispatch(redirectTo(`${ROUTES.broadcastDetails}/${broadcast?.id}`));

    const getFieldWarning = ({message}) => <div key={message} className='field-warning'><Icon className='field-warning__icon' type={ICON_TYPES.alert}/>{message}</div>;

    // FYI: we need to filter out current broadcast because of overlapping with broadcasts scheduled on picked date (28.01.2020, Oleh);
    const scheduledFor = broadcast?.scheduled_for;
    const processableBroadcasts = broadcasts.filter(pipe(getEqual(broadcast?.id, 'id'), negate));
    const [recommendedDeliveryDateFrom, recommendedDeliveryDateTo] = [
        RECOMMENDED_DELIVERY_TIME.from,
        RECOMMENDED_DELIVERY_TIME.to
    ].map(time => scheduledFor?.replace(/T.*/g, `T${time}`));
    const deliveryTimeFieldWarnings = [
        {isHidden: isEmpty(processableBroadcasts), message: 'We recommend only sending one broadcast per day.'},
        {isHidden: isDateWithinRange(scheduledFor, recommendedDeliveryDateFrom, recommendedDeliveryDateTo), message: 'We recommend sending broadcasts between the hours of 6am and 9pm.'}
    ].filter(pipe(getItemKeyValue('isHidden'), negate));

    return (
        <Form name={FORMS.broadcastContentStep}
            initialValues={{
                scheduled_for: broadcast?.scheduled_for
            }}
            onSubmit={onSubmit}
            validate={validate}
            onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit}) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Row className='broadcast-wizard-step'>
                        <Column sm={6} className='broadcast-section broadcast-section_schedule'>
                            <Heading type={HEADING_TYPES['4']} className='broadcast-section__title'>Delivery Time</Heading>
                            <Text className='broadcast-section__sub-title'>Select delivery date and time</Text>
                            <Text className='broadcast-section__sub-title'>Please note - all Broadcasts will be sent in CT time</Text>

                            <div className='broadcast-section__label'>
                                <Field name='scheduled_for'>
                                    {props => <DateTimePicker {...props} minDate={new Date()} label='Delivery Time (CT)' isRequired/>}
                                </Field>

                                {scheduledFor && deliveryTimeFieldWarnings.map(getFieldWarning)}
                            </div>
                        </Column>

                        {/* TODO: roll segment logic back when HJ is ready (03.03.2021, Oleh) */}
                        {/* <Column sm={6} className='broadcast-section'> */}
                        {/*    <Heading type={HEADING_TYPES['4']} className='broadcast-section__title'>Segment</Heading> */}
                        {/*    <Text className='broadcast-section__sub-title'>Choose who should get this broadcast</Text> */}

                        {/*    <Field name='family_segment' component={RadioGroup} options={FAMILY_SEGMENT_OPTIONS}/> */}
                        {/* </Column> */}

                        <Column sm={6} className='broadcast-section'>
                            <Heading type={HEADING_TYPES['4']} className='broadcast-section__title'>Segment</Heading>
                            <Text className='broadcast-section__sub-title'>The ability to send Broadcasts to dependents will be coming soon.</Text>
                        </Column>

                        {/* TODO: roll segment logic back when HJ is ready (03.03.2021, Oleh) */}
                        {/* <Column sm={12} className='broadcast-section broadcast-section_client-overview'> */}
                        {/*    <Heading type={HEADING_TYPES['4']} className='broadcast-section__title'> */}
                        {/*        Recipient Count */}
                        {/*    </Heading> */}

                        {/*    <InformationRow title='Outbound Segment size'>{estimatedCount}</InformationRow> */}
                        {/* </Column> */}
                    </Row>

                    <DynamicActionBar>
                        <Button onClick={onBackToContent} type={BUTTON_TYPES.secondary}>Back to Content</Button>
                        <Button type={BUTTON_TYPES.primary} isSubmit>{schduleButtonText}</Button>
                    </DynamicActionBar>
                </form>
            )}
        </Form>
    );
};

InformationStep.propTypes = {
    submit: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    broadcast: PropTypes.shape({id: PropTypes.number}),
};

export {InformationStep as TestableInformationStep};
export default withBroadcastStepCommon(InformationStep);
