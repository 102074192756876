import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import ImportGroupConfigContent from '../ImportGroupConfigContent';
import ImportConfigVersions from '../ImportConfigVersions';
import {Form} from '../../shared/FormComponents';
import {formatDate, parseQuery} from '../../../utils';
import {FORMS, IMPORT_CONFIG_TYPES, IMPORT_VERSIONS_CONFIG_TYPES} from '../../../constants';
import {requestIndividualConfigVersion, requestTPAPartnerConfigVersion} from '../../../actions/adminPortal';
import {getImportConfigJSONFields, getStringifiedImportConfigData} from '../../../helpers';

// FYI: Sorry for this. But we should match IMPORT_CONFIG_TYPES types with config version types from el_processing. BE team didn't discuss naming (Pasha, 05.30.2024)
const IMPORT_CONFIG_TYPES_BY_CONFIG_VERSIONS = {
    [IMPORT_VERSIONS_CONFIG_TYPES['New Launch']]: IMPORT_CONFIG_TYPES.launch,
    [IMPORT_VERSIONS_CONFIG_TYPES.Maintenance]: IMPORT_CONFIG_TYPES.maintenance,
    [IMPORT_VERSIONS_CONFIG_TYPES.Renewal]: IMPORT_CONFIG_TYPES.renewal
};

const ImportConfigArchived = () => {
    const dispatch = useDispatch();
    const {search} = useLocation();
    const {group_alias: groupAlias, id: importSessionId, partner: partnerId} = useParams();
    const {group_name: groupName} = parseQuery(search);
    const [importConfig, setImportConfig] = useState({});
    const [importConfigType, setImportConfigType] = useState(null);

    useEffect(() => {
        (async () => {
            const requestConfigVersionFunc = partnerId ? requestTPAPartnerConfigVersion : requestIndividualConfigVersion;
            const {data: importConfig} = await dispatch(requestConfigVersionFunc(importSessionId));
            const enhancedImportConfig = {
                ...partnerId ? importConfig : {import_config: importConfig},
                updated_at: importConfig?.updated_at,
                updated_by: importConfig?.updated_by
            };

            const configType = partnerId ? IMPORT_CONFIG_TYPES.tpa : IMPORT_CONFIG_TYPES_BY_CONFIG_VERSIONS[importConfig?.config_type];
            const jsonFields = getImportConfigJSONFields(configType);

            setImportConfig(getStringifiedImportConfigData(jsonFields, enhancedImportConfig));
            setImportConfigType(configType);
        })();
    }, [dispatch, partnerId, importSessionId]);

    return (
        <React.Fragment>
            <Alert className='mb-20'
                type={ALERT_TYPES.warning}
                title='This is an Archived Config'
                description={(
                    <span>
                        You are currently looking at a configuration from <Text isInline type={TEXT_TYPES.bodyBold}>{formatDate(importConfig?.updated_at, 'MM/dd/yyyy')}</Text> that was created by <Text isInline type={TEXT_TYPES.bodyBold}>{importConfig?.updated_by}</Text>
                    </span>
                )}/>

            <Row between='xs'>
                <Column xs>
                    <Heading type={HEADING_TYPES['3']} className='mb-5'>{groupName}</Heading>
                    <Heading type={HEADING_TYPES['1']}>{groupAlias}</Heading>
                </Column>
                <Column className='pr-5' constant>
                    <ImportConfigVersions/>
                </Column>
            </Row>

            <Form name={FORMS.importGroupConfig} initialValues={importConfig} onSubmit={() => {}}>
                {() => (
                    <form noValidate>
                        <ImportGroupConfigContent configType={importConfigType} isArchived/>
                    </form>
                )}
            </Form>
        </React.Fragment>
    );
};

export {ImportConfigArchived as TestableImportConfigArchived};
export default ImportConfigArchived;
