import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import PageHeader from '../../../Components/shared/PageHeader';
import NewContactInfoForm from '../../../Components/ContactsAndCompany/NewContactInfoForm';
import ExistedContactInfoForm from '../../../Components/ContactsAndCompany/ExistedContactInfoForm';
import RolePermissions from '../../../Components/ContactsAndCompany/RolePermissions';
import {getProfilePermissions} from '../../../selectors/general';
import {parseQuery} from '../../../utils';
import './index.scss';

const ContactInfo = ({location}) => {
    const {userId: id} = parseQuery(location.search);
    const profilePermissions = useSelector(getProfilePermissions);

    return (
        <div className='contact-info'>
            <Row>
                <Column sm={9}>
                    {id && profilePermissions.contact_edit && <PageHeader title='Your Contacts'/>}
                    {id && !profilePermissions.contact_edit && <PageHeader title='Your Contacts' caption='View company contact details below.'/>}
                    {!id && <PageHeader title='Add Contact' caption='Fill out all of the contact details below. All fields are required. An invitation to gain access to HealthJoy’s Dashboard will be sent after adding the contact.'/>}
                </Column>
            </Row>
            <Row>
                <Column sm>
                    {id ? <ExistedContactInfoForm id={id}/> : <NewContactInfoForm/>}
                </Column>

                <Column className='contact-info__role-permissions-wrapper' constant>
                    <ContentSection className='role-permissions-section'>
                        <Text className='mb-12' type={TEXT_TYPES.bodyBold}>Role and Permissions</Text>

                        <RolePermissions/>
                    </ContentSection>
                </Column>
            </Row>
        </div>
    );
};

ContactInfo.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string
    })
};

export default React.memo(ContactInfo);
