import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@frontend/ui-kit/Components/Icon';
import './index.scss';

const SidebarNavigationItem = ({isActive, title, icon, onClick}) => {
    const className = `sidebar-navigation-item ${isActive ? 'sidebar-navigation-item_active' : ''}`;

    return (
        <div className={className}>
            <div className='sidebar-navigation-item-header' onClick={onClick}>
                <Icon type={icon}/>
                <div className='sidebar-navigation-item-header__title'>{title}</div>
            </div>
        </div>
    );
};

SidebarNavigationItem.propTypes = {
    isActive: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func
};

export {SidebarNavigationItem as TestableSidebarNavigationItem};
export default React.memo(SidebarNavigationItem);
