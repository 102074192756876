import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Input from '@frontend/ui-kit/Components/Input';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {Field, FieldArray} from '../../shared/FormComponents';
import TeladocEligibilitySegments, {validate as validateEligibilitySegments} from './TeladocEligibilitySegments';
import {ELIGIBILITY_OPTIONS} from '../../../options';
import {TELADOC_ELIGIBILITY_TYPES} from '../../../constants';
import {formatDate, normalizePositiveNumber} from '../../../utils';
import useFormState from '../../../hooks/useFormState';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    const {separate_consult_fees_by_segment: isConsultFeeSeparatedBySegment} = values;

    return {
        ...(!isConsultFeeSeparatedBySegment && validateEligibilitySegments(values))
    };
};

const TeladocChronicCare = ({isEditDisabled}) => {
    const {values} = useFormState();
    const {separate_consult_fees_by_segment: isConsultFeeSeparatedBySegment} = values;

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={4}>
                        <Input value={formatDate(values.start_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.end_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                    <Column sm={4}>
                        <Field name='is_consult_fee_applied_to_deductible' className='checkbox-middle'>
                            {props => <Checkbox {...props} caption='Consult Fee Applied to Deductible' disabled={isEditDisabled || isConsultFeeSeparatedBySegment}/>}
                        </Field>
                    </Column>
                </Row>

                <Separator className='mt-20 mb-20'/>

                {isConsultFeeSeparatedBySegment && (
                    <FieldArray name='consult_fees'>{({fields = []}) => (
                        <React.Fragment>
                            {fields.map((field, index) => {
                                const consultFeeLabel = fields.value[index].segment === TELADOC_ELIGIBILITY_TYPES.HDHPOnly ? 'Registrant Fee - HSA Only' : 'Registrant Fee';

                                return (
                                    <React.Fragment>
                                        <Row middle='xs'>
                                            <Column sm={4}>
                                                <Field name={`${field}.segment`}>
                                                    {props => <Select {...props} options={ELIGIBILITY_OPTIONS} disabled label='Eligibility' isRequired/>}
                                                </Field>
                                            </Column>
                                            <Column sm={4}>
                                                <Field name={`${field}.registrant_fee`} parse={normalizePositiveNumber}>
                                                    {props => <Input {...props} icon={ICON_TYPES.money} placeholder='' disabled label={consultFeeLabel} isRequired/>}
                                                </Field>
                                            </Column>
                                        </Row>

                                        {index + 1 < ELIGIBILITY_OPTIONS.length && <Separator/>}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    )}
                    </FieldArray>
                )}

                {!isConsultFeeSeparatedBySegment && (
                    <React.Fragment>
                        <Heading className='mb-10'>Eligibility</Heading>
                        <TeladocEligibilitySegments isEditDisabled={isEditDisabled}/>
                    </React.Fragment>
                )}
            </ContentSection>
        </div>
    );
};

TeladocChronicCare.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired,
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {validate, TeladocChronicCare as TestableTeladocChronicCare};
export default React.memo(TeladocChronicCare);
