import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PdfHeading from '../PdfHeading';
import PdfText from '../PdfText';
import {REVERSED_UNITS} from '../../../constants';
import './index.scss';

const PdfReportBox = ({className, title, caption, description, value, unit, isPlaceholder}) => {
    const formattedValue = REVERSED_UNITS.includes(unit) ? `${unit}${value}` : `${value}${unit}`;

    return (
        <div className={`pdf-report-box ${className}`}>
            <Row className='pdf-template-row pdf-template-row_small'>
                <Column xs={4}>
                    <PdfHeading className='pdf-report-box-value' type={2}>
                        {isPlaceholder ? 'N/A' : formattedValue}
                    </PdfHeading>

                    <PdfText className='pdf-report-box__caption' type='caption'>{caption}</PdfText>
                </Column>

                <Column xs={8}>
                    <PdfText className='pdf-report-box-title' type='body-bold'>{title}</PdfText>

                    <PdfText className='pdf-report-box__description'>{description}</PdfText>
                </Column>
            </Row>
        </div>
    );
};

PdfReportBox.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    isPlaceholder: PropTypes.bool
};

PdfReportBox.defaultProps = {
    className: '',
    unit: '',
    isPlaceholder: false
};

export {PdfReportBox as TestablePdfReportBox};
export default React.memo(PdfReportBox);
