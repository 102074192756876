import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import emptyDataImage from '../../../static/images/empty_data.svg';
import './index.scss';

const EmptyData = ({title, description, image = emptyDataImage}) => (
    <div className='empty-data'>
        <img src={image} alt='' className='empty-data__image'/>

        {title && <Heading type={HEADING_TYPES['4']} className='empty-data__title'>{title}</Heading>}

        {description && <Text className='empty-data__description'>{description}</Text>}
    </div>
);

EmptyData.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.node
};

export {EmptyData as TestableEmptyData};
export default EmptyData;
