import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import Select from '@frontend/ui-kit/Components/Select';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import CopyableField from '../CopyableField';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import AncillaryCarrierInfo, {validateOnSubmit as validateAncillaryCarrierOnSubmit} from '../AncillaryCarrierInfo';
import DocumentUploader from '../DocumentUploader';
import withCorePlanDetails from '../../../HOC/withCorePlanDetails';
import {normalizeBoolean, trimStart, validateRequired} from '../../../utils';
import {CONDITION_OPTIONS, PLAN_TYPE_OPTIONS} from '../../../options';
import dentalCard from '../../../static/images/cards/dental_card.svg';

const recommendedFields = [
    {name: 'deductible_member', label: 'Deductible: Member'},
    {name: 'deductible_family', label: 'Deductible: Family'},
    {name: 'benefit_maximum', label: 'Benefit Maximum'},
    {name: 'preventative', label: 'Preventative'},
    {name: 'basic_restorative', label: 'Basic Restorative'},
    {name: 'major_restorative', label: 'Major Restorative'},
    {name: 'orthodontia', label: 'Orthodontia'},
    {name: 'ortho_max', label: 'Ortho Max'}
];

/* istanbul ignore next */
const validateOnSubmit = values => {
    const {is_additional_network: isAdditionalNetwork} = values;

    return ({
        ...validateAncillaryCarrierOnSubmit(values),
        group_number: validateRequired(values.group_number),
        secondary_network_name: isAdditionalNetwork ? validateRequired(values.secondary_network_name) : undefined
    });
};

/* istanbul ignore next */
const validate = values => ({
    name: validateRequired(values.name)
});

const DentalPlanForm = ({isReadonly}) => {
    const form = useForm();
    const {values} = useFormState();

    const {is_additional_network: isAdditionalNetwork} = values;

    const resetFields = useCallback(fields => () => {
        return form.batch(() => fields.forEach(field => form.change(field, null)));
    }, [form]);

    return (
        <div className='plan-details-form dental-plan-form'>
            <Row className='plan-details-form-row'>
                <Column className='plan-details-form-row__column' sm>
                    <CopyableField name='name' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Plan Name' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='app_name' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Plan Name Show in the App' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='type'>
                        {props => <Select {...props} readonly={isReadonly} placeholder='Type to select...' options={PLAN_TYPE_OPTIONS} label='Plan Type'/>}
                    </CopyableField>
                </Column>
            </Row>

            <Row className='plan-details-form-row'>
                <Column className='plan-details-form-row__column' sm>
                    <AncillaryCarrierInfo isReadonly={isReadonly}/>
                </Column>
            </Row>

            <Row className='plan-details-form-row' columnGap='sm'>
                <Column className='plan-details-form-row__column' sm={7}>
                    <DocumentUploader label='Upload File' isReadonly={isReadonly}/>

                    <Text className='plan-details-form__description'>
                        Enter the relevant information to complete your Dental Plan. This will appear in your HealthJoy Benefits Wallet.
                    </Text>

                    <div className='numbered-title numbered-title_first'>
                        <div className='numbered-title__num'>1</div> Member Information
                    </div>

                    <CopyableField name='group_number' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} maxLength={40} placeholder='Please enter...' label='Group ID' isRequired wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='network_name' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Provider network' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='is_additional_network' onChange={resetFields(['secondary_network_name'])} parse={normalizeBoolean}>
                        {props => <RadioGroup {...props} readonly={isReadonly} options={CONDITION_OPTIONS} label='Does this plan have an additional network?' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    {isAdditionalNetwork && (
                        <CopyableField name='secondary_network_name' parse={trimStart}>
                            {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Additional provider network' wrapperClassName='mb-12'/>}
                        </CopyableField>
                    )}

                    <div className='numbered-title numbered-title_second'>
                        <div className='numbered-title__num'>2</div> Benefit Highlights
                    </div>

                    <Row className='plan-details-form-row plan-details-form-row_nested'>
                        <Column className='plan-details-form-row__column' sm={6}>
                            <CopyableField name='deductible_member' parse={trimStart}>
                                {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $XX' label='Deductible: Member'/>}
                            </CopyableField>
                        </Column>

                        <Column className='plan-details-form-row__column' sm={6}>
                            <CopyableField name='deductible_family' parse={trimStart}>
                                {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $XXX' label='Deductible: Family'/>}
                            </CopyableField>
                        </Column>
                    </Row>

                    <CopyableField name='benefit_maximum' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $X,XXX' label='Benefit Maximum' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='preventative' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: Covered in full' label='Preventative' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='basic_restorative' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: XX% after deductible' label='Basic Restorative' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='major_restorative' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: XX% after deductible' label='Major Restorative' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <Row className='plan-details-form-row plan-details-form-row_nested'>
                        <Column className='plan-details-form-row__column' sm={6}>
                            <CopyableField name='orthodontia' parse={trimStart}>
                                {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: XX%' label='Orthodontia'/>}
                            </CopyableField>
                        </Column>

                        <Column className='plan-details-form-row__column' sm={6}>
                            <CopyableField name='ortho_max' parse={trimStart}>
                                {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $X,XXX' label='Ortho Max'/>}
                            </CopyableField>
                        </Column>
                    </Row>
                </Column>

                <Column sm={5} className='numerated-img plan-details-form-row__column'>
                    <div className='img-numerator'>
                        <div className='img-numerator-step num_one'>1</div>
                        <div className='img-numerator-step num_two'>2</div>
                    </div>

                    <img className='numerated-img-content' src={dentalCard} alt=''/>
                </Column>
            </Row>
        </div>
    );
};

DentalPlanForm.propTypes = {
    isReadonly: PropTypes.bool.isRequired
};

export {DentalPlanForm as TestableDentalPlanForm};
export default withCorePlanDetails({recommendedFields, validate, validateOnSubmit})(DentalPlanForm);
