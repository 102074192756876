import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import AncillaryPlansDetailsLayout from '../../../Components/Benefits/AncillaryPlansDetailsLayout';

const PAGE_HEADER_CAPTION = `Personalize your HealthJoy Benefits Wallet by completing the sections below.
                 You will see a sample wallet card for each benefit you indicated you offer on the Ancillary Benefits Overview page.
                 After all benefits are Marked as Done, our team will process the information and notify you
                 when you can view your company’s Benefits Wallet in Benefits Preview.
                 At any point, you can go back to the Overview to add or change benefits.`;

const AncillaryDetails = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='All Your Benefits' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <AncillaryPlansDetailsLayout/>
    </React.Fragment>
);

export {AncillaryDetails as TestableAncillaryDetails};
export default React.memo(AncillaryDetails);
