import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Link from '@frontend/ui-kit/Components/Link';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Badge from '@frontend/ui-kit/Components/Badge';
import CompanyInfoSummary from '../CompanyInfoSummary';
import CoreBenefitsSummary from '../CoreBenefitsSummary';
import AncillaryBenefitsSummary from '../AncillaryBenefitsSummary';
import EligibilityDetailsSummary from '../EligibilityDetailsSummary';
import EligibilityCoreBenefitsSummary from '../EligibilityCoreBenefitsSummary';
import EligibilityAncillaryBenefitsSummary from '../EligibilityAncillaryBenefitsSummary';
import {requestBenefitsOverview} from '../../../actions/overview';
import {getActiveCompanyPlanPeriods} from '../../../selectors/general';
import {getBenefitsOverview} from '../../../selectors/overview';
import {ROUTES, PLAN_PERIOD_TYPES} from '../../../constants';
import {getEqual} from '../../../utils';
import './index.scss';

const OverviewResults = () => {
    const dispatch = useDispatch();
    const benefitsOverview = useSelector(getBenefitsOverview);
    const planPeriods = useSelector(getActiveCompanyPlanPeriods);

    useEffect(() => {
        dispatch(requestBenefitsOverview());
    }, [dispatch]);

    const {id: activePlanPeriodId} = planPeriods.find(getEqual(PLAN_PERIOD_TYPES.launch, 'status')) || {};
    const {company, eligibility_info: eligibilityInfo, core: corePlans, ancillary: ancillaryPlans, medicalTraits} = benefitsOverview;

    return (
        <div className='benefits-overview-layout'>
            <ContentSection className='section-summary'>
                <div className='section-summary-header'>
                    <div className='section-summary-header__title'>Company Information</div>
                    <Link className='section-summary-header-link' href={ROUTES.companyInfo}>
                        <Icon className='section-summary-header-link__icon' type={ICON_TYPES.edit}/> Edit
                    </Link>
                </div>

                <CompanyInfoSummary company={company}/>
            </ContentSection>

            <ContentSection className='section-summary'>
                <div className='section-summary-header'>
                    <div className='section-summary-header__title'>Core Benefits</div>
                    <Link className='section-summary-header-link' href={`${ROUTES.benefits}/${activePlanPeriodId}/core_benefits`}>
                        <Icon className='section-summary-header-link__icon' type={ICON_TYPES.edit}/> Edit
                    </Link>
                </div>

                <CoreBenefitsSummary plans={corePlans} medicalTraits={medicalTraits}/>
            </ContentSection>

            <ContentSection className='section-summary'>
                <div className='section-summary-header'>
                    <div className='section-summary-header__title'>
                        Ancillary Benefits <Badge className='plan-counter'>{ancillaryPlans?.length || 0}</Badge>
                    </div>
                    <Link className='section-summary-header-link' href={`${ROUTES.benefits}/${activePlanPeriodId}/ancillary_benefits`}>
                        <Icon className='section-summary-header-link__icon' type={ICON_TYPES.edit}/> Edit
                    </Link>
                </div>

                <AncillaryBenefitsSummary plans={ancillaryPlans}/>
            </ContentSection>

            <ContentSection className='section-summary'>
                <div className='section-summary-header'>
                    <div className='section-summary-header__title'>Eligibility: Details</div>
                    <Link className='section-summary-header-link' href={`${ROUTES.eligibility}/${activePlanPeriodId}/details`}>
                        <Icon className='section-summary-header-link__icon' type={ICON_TYPES.edit}/> Edit
                    </Link>
                </div>

                <EligibilityDetailsSummary eligibilityInfo={eligibilityInfo}/>
            </ContentSection>

            <ContentSection className='section-summary'>
                <div className='section-summary-header'>
                    <div className='section-summary-header__title'>Eligibility: Core Benefits</div>
                    <Link className='section-summary-header-link' href={`${ROUTES.eligibility}/${activePlanPeriodId}/implementation`}>
                        <Icon className='section-summary-header-link__icon' type={ICON_TYPES.edit}/> Edit
                    </Link>
                </div>

                <EligibilityCoreBenefitsSummary plans={corePlans}/>
            </ContentSection>

            <ContentSection className='section-summary'>
                <div className='section-summary-header'>
                    <div className='section-summary-header__title'>Eligibility: Ancillary Benefits</div>
                    <Link className='section-summary-header-link' href={`${ROUTES.eligibility}/${activePlanPeriodId}/implementation`}>
                        <Icon className='section-summary-header-link__icon' type={ICON_TYPES.edit}/> Edit
                    </Link>
                </div>

                <EligibilityAncillaryBenefitsSummary plans={ancillaryPlans}/>
            </ContentSection>
        </div>
    );
};

export {OverviewResults as TestableOverviewResults};
export default React.memo(OverviewResults);
