import React, {useMemo} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import './index.scss';

const LoadingBar = ({className, progress}) => {
    const barStyle = useMemo(() => ({width: `${progress}%`}), [progress]);

    return (
        <div className={classnames('loading-bar', className)}>
            <div className='bar'>
                <div className='bar__progress' style={barStyle}/>
            </div>
            <Text type={TEXT_TYPES.helper} className='ml-2'>{progress}%</Text>
        </div>
    );
};

LoadingBar.propTypes = {
    className: PropTypes.string,
    progress: PropTypes.number
};

LoadingBar.defaultProps = {
    className: '',
    progress: 0
};

export {LoadingBar as TestableLoadingBar};
export default React.memo(LoadingBar);
