import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import UtilizationReporting from '../../../Components/Reporting/Utilization';
import PlanPeriodSwitcher from '../../../Components/Reporting/PlanPeriodSwitcher';

const Utilization = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='Performance Report'/>
            </Column>

            <Column constant>
                <PlanPeriodSwitcher/>
            </Column>
        </Row>

        <ReportingPagesTabs/>

        <UtilizationReporting/>
    </React.Fragment>
);

export {Utilization as TestableUtilization};
export default React.memo(Utilization);
