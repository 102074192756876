import React from 'react';
import PropTypes from 'prop-types';
import useClickOutsideSidebar from '../../../hooks/useClickOutsideSidebar';
import {DASHBOARD_LAYOUT_SELECTOR} from '../../../constants';
import './index.scss';

const Layout = ({children}) => {
    const onClickOutsideSidebar = useClickOutsideSidebar();
    const layoutClassName = DASHBOARD_LAYOUT_SELECTOR.slice(1);

    return <div data-testid='dashboard-layout' className={layoutClassName} onClick={onClickOutsideSidebar}>{children}</div>;
};

Layout.propTypes = {
    children: PropTypes.node
};

export {Layout as TestableLayout};
export default React.memo(Layout);
