import React from 'react';
import Layout from '../../../Components/Error/Layout';
import ServerErrorContent from '../../../Components/Error/ServerError';

const ServerError = () => (
    <Layout>
        <ServerErrorContent/>
    </Layout>
);

export {ServerError as TestableServerError};
export default ServerError;
