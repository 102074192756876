import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import ProgressBar from '@frontend/ui-kit/Components/ProgressBar';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import ImportGroupConfig from '../ImportGroupConfig';
import TPAUploadFileForm from '../TPAUploadFileForm';
import {requestCompaniesByTPAPartner} from '../../../actions/general';
import {requestImportSessionStatuses} from '../../../actions/adminPortal';
import {equal, getEqual, getItemKeyValue, isEmpty, parseQuery} from '../../../utils';
import {ELIGIBILITY_IMPORT_STATUSES, IMPORT_CONFIG_TYPES} from '../../../constants';

const WIZARD_STEPS = [
    {activeStep: 0, StepComponent: ImportGroupConfig, stepName: 'configuration', stepTitle: 'Configure', description: 'Items configured here will be applied to all groups within this TPA.'},
    {activeStep: 1, StepComponent: TPAUploadFileForm, stepName: 'upload_file', stepTitle: 'Upload File', description: 'Upload the TPA Partner file for pre-processing to check for potential global and duplicate member errors.'}
];

const TPAPartnerConfig = () => {
    const dispatch = useDispatch();
    const {partner} = useParams();
    const {search, hash} = useLocation();
    const {push} = useHistory();
    const partnerDisplayName = parseQuery(search).partner_name || partner;
    const {activeStep, StepComponent, stepName, stepTitle, description} = useMemo(() => {
        const tabsIndex = WIZARD_STEPS.findIndex(getEqual(hash.slice(1), 'stepName'));
        const activeTabIndex = equal(tabsIndex, -1) ? 0 : tabsIndex;

        return WIZARD_STEPS[activeTabIndex];
    }, [hash]);
    const [showImportedGroupsAlert, setShowImportedGroupsAlert] = useState(false);

    useEffect(() => {
        (async () => {
            const {groupsData} = await dispatch(requestCompaniesByTPAPartner({partnerId: partner, limit: 999}));
            const groupsAliases = groupsData?.data.map?.(getItemKeyValue('alias')) || [];
            const {data: sessionsInProgress} = await dispatch(requestImportSessionStatuses({alias: groupsAliases, status: [ELIGIBILITY_IMPORT_STATUSES.new, ELIGIBILITY_IMPORT_STATUSES.processing, ELIGIBILITY_IMPORT_STATUSES.failed, ELIGIBILITY_IMPORT_STATUSES.pending_confirmation, ELIGIBILITY_IMPORT_STATUSES.writing]}));

            setShowImportedGroupsAlert(!isEmpty(sessionsInProgress));
        })();
    }, [partner]);

    const onChangeActiveStep = stepName => push(`${search}#${stepName}`);

    return (
        <React.Fragment>
            {showImportedGroupsAlert && equal(stepName, 'configuration') && <Alert className='mb-20' type={ALERT_TYPES.danger} description='This TPA Partner contains groups that are being imported. Any edits to the TPA Parent Config will override the imports currently in place and require group modifications to be re-done.'/>}

            <Heading type={HEADING_TYPES['1']} className='mb-5'>{partnerDisplayName}</Heading>
            <Text>{description}</Text>

            <Text className='mt-20' type={TEXT_TYPES.bodyBold}>Step {activeStep + 1} of {WIZARD_STEPS.length}: <Text isInline>{stepTitle}</Text></Text>
            <ProgressBar className='mt-5' stepsCount={WIZARD_STEPS.length} activeStep={activeStep}/>

            <StepComponent configType={IMPORT_CONFIG_TYPES.tpa} key={stepName} onChangeActiveStep={onChangeActiveStep}/>
        </React.Fragment>
    );
};

export {TPAPartnerConfig as TestableTPAPartnerConfig};
export default TPAPartnerConfig;
