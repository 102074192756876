import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import Separator from '@frontend/ui-kit/Components/Separator';
import Heading from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import emptyDataImage from '../../../static/images/empty_miletones.svg';
import {Field} from '../../shared/FormComponents';
import {getTimelineStepsWithUrl} from '../../../helpers';
import {equal} from '../../../utils';
import './index.scss';

const Milestones = ({milestones, isMilestonesShown, isEditDisabled, launchDate}) => {
    const timelineStepsData = useMemo(() => getTimelineStepsWithUrl(), []);

    return (
        <ContentSection className='milestones'>
            <Heading className='section__title'>Milestones</Heading>

            {!isMilestonesShown && (
                <div className='milestones__placeholder'>
                    <img src={emptyDataImage} alt='' className='milestones__placeholder__image'/>

                    <Text>
                        Select a template type and launch date to view and assign dates to the relevant milestones.
                    </Text>
                </div>
            )}

            {isMilestonesShown && milestones.map((milestone, index) => (
                <React.Fragment key={milestone}>
                    <Row className='milestones__milestone'>
                        <Column sm={8}>
                            <Text type={TEXT_TYPES.bodyBold}>
                                <Text className='milestones__milestone__counter' isInline>{index + 1}</Text>
                                {timelineStepsData[milestone]?.title}
                            </Text>
                        </Column>
                        <Column sm={4}>
                            <Field name={milestone}>
                                {props => <DatePicker {...props} disabled={isEditDisabled} minDate={new Date()} maxDate={new Date(launchDate)} label='Due by' isRequired/>}
                            </Field>
                        </Column>
                    </Row>

                    {!equal(index, milestones.length - 1) && <Separator/>}
                </React.Fragment>
            ))}
        </ContentSection>
    );
};

Milestones.propTypes = {
    milestones: PropTypes.arrayOf(PropTypes.string).isRequired,
    launchDate: PropTypes.string.isRequired,
    isEditDisabled: PropTypes.bool,
    isMilestonesShown: PropTypes.bool
};

Milestones.defaultProps = {
    isEditDisabled: false,
    isMilestonesShown: true
};

export {Milestones as TestableMilestones};
export default Milestones;
