import React from 'react';
import Link from '@frontend/ui-kit/Components/Link';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {ROUTES} from '../../../constants';
import hjLogo from '../../../static/images/hjLogoPurple.svg';

const NotFound = () => {
    return (
        <div className='error-page not-found'>
            <img className='error-page__logo' src={hjLogo} alt=''/>

            <Text type={TEXT_TYPES.bodyBold} className='error-page__header'>404</Text>

            <Text className='error-page__subheader'>Page Not Found.</Text>

            <Text className='error-page__description'>
                We couldn’t find the page you’re looking for. This is either a broken link or the page has been moved/deleted.
            </Text>

            <Link className='error-page__button' isButton type={BUTTON_TYPES.primary} href={ROUTES.root}>Go to homepage</Link>
        </div>
    );
};

export {NotFound as TestableNotFound};
export default NotFound;
