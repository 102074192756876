import React from 'react';
import PropTypes from 'prop-types';
import PageContent from '../PageContent';
import PdfText from '../PdfText';

const GlossaryContinuedFirst = ({isMskVisible, isPreCertificationVisible, isTelemedVisible}) => (
    <PageContent title='4.0 Glossary Continued' data-testid='glossary-continued-first'>
        <PdfText>
            <PdfText type='body-bold' isInline>Member Satisfaction</PdfText> -
            Calculated as the total number of satisfied member ratings divided by the total number of member ratings.
            Members are able to rate HealthJoy’s service each time they receive a completed service request.
            This metric shows once there are 10 or more reviews.
        </PdfText>

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Member Utilization</PdfText> -
            The total number of unique actions that members have taken within the HealthJoy app.
            This includes Benefit Wallet views and Service Requests that have been completed for your company.
        </PdfText>

        {isTelemedVisible && (
            <PdfText className='mt-small' data-testid='telemed-section'>
                <PdfText type='body-bold' isInline>MeMD Consults</PdfText> - Count of total telemedicine consults completed.
            </PdfText>
        )}

        {isMskVisible && (
            <PdfText className='mt-small' data-testid='msk-section'>
                <PdfText className='mb-small'>
                    <PdfText type='body-bold' isInline>Musculoskeletal Therapy Savings</PdfText> -
                    Calculated by incorporating each participant’s prior care and current care intentions to determine their savings potential.
                    Total MSK Savings is the sum of the realized savings values.
                </PdfText>

                Participants and their savings potential fall into three buckets:
                <ul>
                    <li>No intention of getting surgery and prior care is non-invasive: $1,800</li>
                    <li>No intention of getting surgery but received more expensive and/or invasive prior care: $4,000</li>
                    <li>Intention to get surgery: varies per participants</li>
                </ul>
            </PdfText>
        )}

        {isPreCertificationVisible && (
            <PdfText className='mt-small' data-testid='pre-certification-section'>
                <PdfText type='body-bold' isInline>Pre-certification Steerage Savings</PdfText> -
                Calculated as the difference between the average price for procedure and the estimated price of the recommendation to determine the total savings opportunity.
                Average price is determined by procedure type and facility location.
            </PdfText>
        )}

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Program Savings</PdfText> -
            Calculated as sum of the estimated savings for categories of utilization.
            You can read more about how each is calculated in the Program Savings Formula.
        </PdfText>

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Provider Guidance</PdfText> -
            Count of total provider requests completed. These can include doctors from primary care to surgery.
        </PdfText>
    </PageContent>
);

GlossaryContinuedFirst.propTypes = {
    isMskVisible: PropTypes.bool,
    isPreCertificationVisible: PropTypes.bool,
    isTelemedVisible: PropTypes.bool
};

export {GlossaryContinuedFirst as TestableGlossaryContinuedFirst};
export default GlossaryContinuedFirst;
