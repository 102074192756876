import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Input from '@frontend/ui-kit/Components/Input';
import {Form, Field} from '../../Components/shared/FormComponents';
import DetailsActionBar from '../../Components/shared/DetailsActionBar';
import {requestFilesInfoDetails, requestFilesInfoDetailsUpdating} from '../../actions/eligibility';
import {getErrorFieldNames, getRegisteredFieldsValues, isEmpty} from '../../utils';
import {FORMS} from '../../constants';

const withEligibilityDetailsForm = ({validate, validateOnSubmit}) => Component => {
    const WithEligibilityDetailsForm = ({category, isReadonly, onSuccess, ...restProps}) => {
        const dispatch = useDispatch();
        const [initialValues, setInitialValues] = useState({});
        const {planPeriodId} = useParams();

        useEffect(() => {
            (async () => {
                const {filesInfoDetails, isSuccess} = await dispatch(requestFilesInfoDetails(planPeriodId, category));

                if (isSuccess) {
                    setInitialValues(filesInfoDetails);
                }
            })();
        }, [dispatch, planPeriodId, category]);

        const onSubmit = useCallback(async (values, form) => {
            const {meta = {}, ...restValues} = values;
            const isCompleted = values.completed || meta.completed; // FYI: if plan is already completed according to requirements we need to send always true as "completed", even if it's save progress action (Pasha, 18.01.2021)
            const registeredFieldsValues = getRegisteredFieldsValues(form.getRegisteredFields(), restValues);

            const validationErrors = validateOnSubmit(values, {category});
            if (meta.completed && !isEmpty(getErrorFieldNames(validationErrors))) {
                return validationErrors;
            }

            const enhancedValues = {...registeredFieldsValues, completed: isCompleted};
            const {filesInfoDetails, isSuccess, submissionErrors} = await dispatch(requestFilesInfoDetailsUpdating(category, enhancedValues));

            if (!isSuccess) {
                return submissionErrors;
            }

            setInitialValues(filesInfoDetails);
        }, [dispatch, category]);

        return (
            <Form name={FORMS.eligibilityDetails} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit}) => {
                    const actionBarProps = {onSubmit: handleSubmit, isReadonly, onSuccess};

                    return (
                        <form noValidate data-testid='eligibility-details-form'>
                            <Field name='plan_period_id'>{props => <Input {...props} type='hidden'/>}</Field>

                            <Component {...restProps} isReadonly={isReadonly}/>

                            <DetailsActionBar {...actionBarProps}/>
                        </form>
                    );
                }}
            </Form>
        );
    };

    WithEligibilityDetailsForm.propTypes = {
        category: PropTypes.string,
        isReadonly: PropTypes.bool,
        onSuccess: PropTypes.func.isRequired
    };

    return WithEligibilityDetailsForm;
};

export {withEligibilityDetailsForm as testableWithEligibilityDetailsForm};
export default withEligibilityDetailsForm;
