import React from 'react';
import ImportConfigArchived from '../../../Components/AdminPortal/ImportConfigArchived';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'partner_name'},
    {skip: true},
    {displayName: 'version'}
];

const TPAPartnerConfigurationArchived = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <ImportConfigArchived/>
    </React.Fragment>
);

export {TPAPartnerConfigurationArchived as TestableTPAPartnerConfigurationArchived};
export default React.memo(TPAPartnerConfigurationArchived);
