import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Text from '@frontend/ui-kit/Components/Text';
import Layout from '../../Components/Reporting/Layout';
import {requestDownloadReports, requestReportingInfo} from '../../actions/reporting';
import {getReportingInfo, getReportingPlanPeriod} from '../../selectors/reporting';
import {equal} from '../../utils';

const DOWNLOAD_REPORTS_REPORTING_SECTION = 'download_reports';

const withReportingInfo = ({isDisclaimerShowed}) => Component => {
    const WithReportingInfo = props => {
        const {pathname} = useLocation();
        const reportingPlanPeriod = useSelector(getReportingPlanPeriod); // FYI: it's controlled by PlanPeriodSwitcher (10.04.2020, Oleh);
        const reportingInfo = useSelector(getReportingInfo);
        const dispatch = useDispatch();

        const [reportingSection] = pathname.split('/').reverse();
        const {[reportingSection]: info, ...restReportingInfo} = reportingInfo;
        const componentProps = {info, ...restReportingInfo, ...props};

        useEffect(() => {
            if (equal(reportingSection, DOWNLOAD_REPORTS_REPORTING_SECTION)) {
                dispatch(requestDownloadReports(reportingPlanPeriod));
            } else {
                dispatch(requestReportingInfo(reportingSection, reportingPlanPeriod));
            }
        }, [dispatch, reportingSection, reportingPlanPeriod]);

        return (
            <Layout>
                <Component {...componentProps}/>

                {isDisclaimerShowed && (
                    <Text className='reporting-disclaimer'>
                        Data on this page is updated every day at 5:00 A.M CST. Daily processing includes events received during the previous day.
                    </Text>
                )}
            </Layout>
        );
    };

    WithReportingInfo.propTypes = {};

    return WithReportingInfo;
};

export {withReportingInfo as testableWithReportingInfo};
export default withReportingInfo;
