import React from 'react';
import PropTypes from 'prop-types';
import PageContent from '../PageContent';
import TextSection from '../TextSection';
import PdfText from '../PdfText';

const ProgramSavingsFormulaContinuedFourth = ({isTelemedVisible, isTeladocGeneralMedicalVisible, isTeladocMentalHealthVisible}) => (
    <PageContent title='2.1 Program Savings Formula Continued'>
        <TextSection title='Rx Savings' className='mb-default'>
            Calculated as the sum of the actual savings found in actual identified prescriptions.
            Savings are accrued according to the refill cadence.
            For example, if a member has a $10 savings opportunity on a monthly prescription, over 12 months that would total $120 in savings.
        </TextSection>

        {isTelemedVisible && (
            <TextSection title='MeMD Savings Per Consult' className='mb-default'>
                <PdfText>
                    Calculated by using the percentage of members who would have visited another care site and the cost of visiting that care site.
                    The time savings of the consult is then added to get an average savings per consult.
                    We multiply this average by the total number of telemedicine consults.
                </PdfText>
                <PdfText>
                    National Average numbers per visit and telemedicine savings are as calculated by Veracity Healthcare Analytics in an independent 2017 study across 1.8 million patients.
                    The dollar value includes the total cost of care over 30 days for patients who use that site of care.
                </PdfText>
            </TextSection>
        )}

        {isTeladocGeneralMedicalVisible && (
            <TextSection title='Teladoc Health General Medical (Urgent Care) Savings' className='mb-default'>
                Calculated as the difference between the total weighted average cost of visiting a traditional place of treatment
                and the total weighted average cost of Teladoc Health General Medical to determine the average claims savings per consult.
                We then multiply the average claims savings per consult by the total number of consults to determine the total Teladoc Health General Medical savings value.
            </TextSection>
        )}

        {isTeladocMentalHealthVisible && (
            <TextSection title='Teladoc Health Mental Health Savings' className='mb-default'>
                Calculated as $195 per consult, an average dollar savings value.
                We multiply this average by the total number of Mental Health consults to determine total savings.
            </TextSection>
        )}
    </PageContent>
);

ProgramSavingsFormulaContinuedFourth.propTypes = {
    isTelemedVisible: PropTypes.bool,
    isTeladocGeneralMedicalVisible: PropTypes.bool,
    isTeladocMentalHealthVisible: PropTypes.bool
};

export {ProgramSavingsFormulaContinuedFourth as TestableProgramSavingsFormulaContinuedFourth};
export default ProgramSavingsFormulaContinuedFourth;
