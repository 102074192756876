import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import InfoTab from '../InfoTab';
import './index.scss';

const MBR_SECTION_TOOLTIP_CONTENT = 'Average savings value is based on an analysis of more than 3,000 bills that HealthJoy reviewed in 2021.';

const MSK_NO_SURGERY_TOOLTIP_CONTENT = 'Examples of invasive care are PCP visits, orthopedist visits, MRI, injections, and PT visits.';

const MSK_SURGERY_TOOLTIP_CONTENT = `
    The difference in a participant's starting and ending surgery likelihood is multiplied by an average surgery cost of $40,000 to determine the savings value.
    As a formula (Starting Likelihood - Ending Likelihood)*$40,000 = Savings Value.
`;

const TELEMED_COST_TOOLTIP_CONTENT = `
    Members who would have used this site of care if they did not have telemedicine.
    National Average numbers per visit and telemedicine savings as calculated by Veracity Healthcare Analytics in
    an independent 2017 study across 1.8 Million patients.
    The dollar value includes the total cost of care over 30 days for patients who use that site of care.
`;

const TELEMED_NO_SOUGHT_CARE_COST_TOOLTIP_CONTENT = `
    Members who would not have sought care if they did not have telemedicine.
    National Average numbers per visit and telemedicine savings as calculated by Veracity Healthcare Analytics in
    an independent 2017 study across 1.8 Million patients.
    The dollar value includes the total cost of care over 30 days for patients who use that site of care.
`;

const TELEMED_REDIRECT_TOOLTIP_CONTENT = `
    The percentage of telemedicine users who would have used this site of care,
    as calculated by Veracity Healthcare Analytics in an independent 2017 study across 1.8 Million patients.
`;

const TELEMED_NO_SOUGHT_CARE_REDIRECT_TOOLTIP_CONTENT = `
    The percentage of telemedicine users who would not have sought care,
    as calculated by Veracity Healthcare Analytics in an independent 2017 study across 1.8 Million patients.
`;

const TELADOC_TRADITIONAL_COSTS_TOOLTIP_CONTENT = `
    Calculated by using the percentage of members who would have visited a traditional place of treatment
    and the cost of visiting that place of treatment.
`;

const TELADOC_GENERAL_MEDICAL_COSTS_TOOLTIP_CONTENT = `
    Calculated as the sum of the initial cost of a Teladoc Health Urgent Care consult,
    follow-up costs of the consult, and an adjustment for no treatment.
`;

const ADMIN_SAVINGS_SPECIALIST_SALARY_TOOLTIP_CONTENT = `
    The avg. Benefits Specialist salary is a conservative estimate based on the national salary avg.
    range of $56,112 to $63,838 aggregated from data from various job search engines.
`;

const RoiCalculator = ({isTelemedVisible, isTeladocGeneralMedicalVisible, isTeladocMentalHealthVisible, isMedicalBillVisible, isMskVisible, isPreCertificationVisible, isFindCareVisible}) => {
    return (
        <div className='roi-calculator'>
            <Heading className='reporting-info-title' type={HEADING_TYPES['5']}>
                How We Calculate Program Savings by Service
            </Heading>

            <div data-testid='administrative-section'>
                <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>Administrative Savings</Text>
                <Text className='roi-calculator__info'>
                    Calculated as the sum of Health Coverage views, Wallet Card CTA clicks, and Benefit Question tickets completed.
                    We multiply the sum by $8.80 as the estimated value added per administrative connection to calculate the total administrative savings value.
                </Text>

                <Row className='info-tab-group' columnGap='xxs' middle='sm'>
                    <Column sm={5} className='info-tab-group__item'>
                        <InfoTab title='Avg. Benefits Specialist Annual Salary'
                            tooltipContent={ADMIN_SAVINGS_SPECIALIST_SALARY_TOOLTIP_CONTENT}>
                            $55,000
                        </InfoTab>
                    </Column>
                    <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                        <Text>÷</Text>
                    </Column>
                    <Column sm={3} className='info-tab-group__item'>
                        <InfoTab title='Annual Work Hours'>2,080</InfoTab>
                    </Column>
                    <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                        <Text>=</Text>
                    </Column>
                    <Column sm className='info-tab-group__item'>
                        <InfoTab title='Est. Full Time Value/Hour'>$26.40</InfoTab>
                    </Column>
                </Row>

                <Separator type='solid'/>

                <Row className='info-tab-group' columnGap='xxs' middle='sm'>
                    <Column sm={5} className='info-tab-group__item'>
                        <InfoTab title='Est. Full Time Value/Hour'>$26.40</InfoTab>
                    </Column>
                    <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                        <Text>X</Text>
                    </Column>
                    <Column sm={3} className='info-tab-group__item'>
                        <InfoTab title='Avg. Time To Resolution'>.33 hrs.</InfoTab>
                    </Column>
                    <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                        <Text>=</Text>
                    </Column>
                    <Column sm className='info-tab-group__item'>
                        <InfoTab title='Est. Value Added per Admin Connection'>$8.80</InfoTab>
                    </Column>
                </Row>
            </div>

            {isMedicalBillVisible && (
                <div className='mbr-section' data-testid='mbr-section'>
                    <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>
                        Medical Bill Review Savings
                        <Tooltip className='subtitle-description' content={MBR_SECTION_TOOLTIP_CONTENT}>
                            <Icon className='subtitle-description__icon' type={ICON_TYPES.info}/>
                        </Tooltip>
                    </Text>
                    <Text className='roi-calculator__info'>
                        Calculated as $689 per medical bill, an average dollar savings value.
                        Total Medical Bill Review savings is the sum of all of the savings from each review.
                    </Text>
                </div>
            )}

            {isTelemedVisible && (
                <div className='telemed-section' data-testid='telemed-section'>
                    <React.Fragment>
                        <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>MeMD Savings</Text>
                        <Text className='roi-calculator__info'>
                            Calculated by using the percentage of members who would have visited another care site and the cost of visiting that care site.
                            The time savings of the consult is then added to get an average savings per consult.
                            We multiply this average by the total number of telemedicine consults.
                        </Text>
                    </React.Fragment>

                    <Row className='info-tab-group'>
                        <Column sm={6} lg={8}>
                            <InfoTab title='Average PCP / UC / Specialist Cost' tooltipContent={TELEMED_COST_TOOLTIP_CONTENT}>$141</InfoTab>
                        </Column>

                        <Column sm={6} lg={4}>
                            <InfoTab title='Redirection' tooltipContent={TELEMED_REDIRECT_TOOLTIP_CONTENT}>71%</InfoTab>
                        </Column>
                    </Row>

                    <Row className='info-tab-group'>
                        <Column sm={6} lg={8}>
                            <InfoTab className='info-tab-group__item' title='Average emergency room cost' tooltipContent={TELEMED_COST_TOOLTIP_CONTENT}>$2,561</InfoTab>
                        </Column>

                        <Column sm={6} lg={4}>
                            <InfoTab className='info-tab-group__item' title='Redirection' tooltipContent={TELEMED_REDIRECT_TOOLTIP_CONTENT}>15%</InfoTab>
                        </Column>
                    </Row>

                    <Row className='info-tab-group'>
                        <Column className='info-tab-group__item' sm={6} lg={8}>
                            <InfoTab title='Do nothing cost' tooltipContent={TELEMED_NO_SOUGHT_CARE_COST_TOOLTIP_CONTENT}>$0</InfoTab>
                        </Column>

                        <Column className='info-tab-group__item' sm={6} lg={4}>
                            <InfoTab title='Redirection' tooltipContent={TELEMED_NO_SOUGHT_CARE_REDIRECT_TOOLTIP_CONTENT}>14%</InfoTab>
                        </Column>
                    </Row>

                    <Separator type='solid'/>

                    <InfoTab className='roi-calculator__info-tab' title='Total savings per consult'>$517</InfoTab>
                </div>
            )}

            {isMskVisible && (
                <div className='msk-section' data-testid='msk-section'>
                    <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>MSK Therapy Savings</Text>
                    <Text className='roi-calculator__info'>
                        Calculated by incorporating each participant’s prior care and current care intentions to determine their savings potential.
                        Total MSK Therapy savings is the sum of the realized savings values. Participants and their savings potential fall into three buckets:
                    </Text>

                    <InfoTab className='roi-calculator__info-tab' title='No intention of getting surgery and prior care is non-invasive'>
                        $1800
                    </InfoTab>
                    <InfoTab className='roi-calculator__info-tab'
                        title='No intention of getting surgery but received more expensive and/or invasive prior care'
                        tooltipContent={MSK_NO_SURGERY_TOOLTIP_CONTENT}>
                        $4,000
                    </InfoTab>
                    <InfoTab className='roi-calculator__info-tab'
                        title='Intention to get surgery'
                        tooltipContent={MSK_SURGERY_TOOLTIP_CONTENT}>
                        Varies per participant
                    </InfoTab>
                </div>
            )}

            {isPreCertificationVisible && (
                <div className='pre-certification-section' data-testid='pre-certification-section'>
                    <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>Pre-certification Steerage Savings</Text>
                    <Text className='roi-calculator__info'>
                        Calculated as the difference between the average price for procedure and the estimated price of the recommendation to determine the total savings opportunity.
                        Average price is determined by procedure type and facility location.
                    </Text>
                </div>
            )}

            <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>Provider/Facility Steerage Savings</Text>
            <Text className='roi-calculator__info'>
                Calculated as the sum of the cost savings found in all of the provider and facility steerage recommendations.
                Steerage consists of several subcategories with unique dollar values attached to each recommendation.
                Values range from $50 (dental) - $4,320 (surgery) per request. {isFindCareVisible && 'Find Care searches are included in the calculation. '}
                A detailed breakdown of each subcategory can be found in the downloadable report.
            </Text>

            <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>Rx Savings</Text>
            <Text className='roi-calculator__info'>
                Calculated as the sum of the actual savings found in actual identified prescriptions.
                Savings are accrued according to the refill cadence.
                For example, if a member has a $10 savings opportunity on a monthly prescription, over 12 months that would total $120 in savings.
            </Text>

            {isTeladocGeneralMedicalVisible && (
                <div className='teladoc-general-medical-section' data-testid='teladoc-general-medical-section'>
                    <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>
                        Teladoc Health General Medical (Urgent Care) Savings
                    </Text>
                    <Text className='roi-calculator__info'>
                        Calculated as the difference between the total weighted average cost of visiting a traditional place of treatment
                        and the total weighted average cost of Teladoc Health General Medical to determine the average claims savings per consult.
                        We then multiply the average claims savings per consult by the total number of consults to determine the total Teladoc Health General Medical savings value.
                    </Text>

                    <Row className='info-tab-group' columnGap='xxs' middle='sm'>
                        <Column sm={5} className='info-tab-group__item'>
                            <InfoTab title='Avg. office visit cost'>$451</InfoTab>
                        </Column>
                        <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                            <Text>X</Text>
                        </Column>
                        <Column sm={3} className='info-tab-group__item'>
                            <InfoTab title='Redirection'>93.4%</InfoTab>
                        </Column>
                        <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                            <Text>=</Text>
                        </Column>
                        <Column sm className='info-tab-group__item'>
                            <InfoTab title='Weighted avgs.'>$431</InfoTab>
                        </Column>
                    </Row>

                    <Row className='info-tab-group' columnGap='xxs' middle='sm'>
                        <Column sm={5} className='info-tab-group__item'>
                            <InfoTab title='Avg. emergency room cost'>$3,441</InfoTab>
                        </Column>
                        <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                            <Text>X</Text>
                        </Column>
                        <Column sm={3} className='info-tab-group__item'>
                            <InfoTab title='Redirection'>6.6%</InfoTab>
                        </Column>
                        <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                            <Text>=</Text>
                        </Column>
                        <Column sm className='info-tab-group__item'>
                            <InfoTab title='Weighted avgs.'>$227</InfoTab>
                        </Column>
                    </Row>

                    <Separator type='solid'/>

                    <Row className='info-tab-group' middle='sm'>
                        <Column sm className='info-tab-group__item'>
                            <InfoTab title='Total weighted avg. of traditional treatment costs'
                                tooltipContent={TELADOC_TRADITIONAL_COSTS_TOOLTIP_CONTENT}>
                                $648
                            </InfoTab>
                        </Column>
                        <Column constant className='info-tab-group__item info-tab-group__item_sign'>
                            <Text>-</Text>
                        </Column>
                        <Column sm className='info-tab-group__item'>
                            <InfoTab title='Total weighted avg. of Teladoc health general medical costs'
                                tooltipContent={TELADOC_GENERAL_MEDICAL_COSTS_TOOLTIP_CONTENT}>
                                $122
                            </InfoTab>
                        </Column>
                    </Row>

                    <Separator type='solid'/>

                    <InfoTab title='Total avg. claims savings per consult'>$526</InfoTab>
                </div>
            )}

            {isTeladocMentalHealthVisible && (
                <div className='teladoc-mental-health-section' data-testid='teladoc-mental-health-section'>
                    <Text className='roi-calculator-subtitle' type={TEXT_TYPES.bodyBold}>Teladoc Health Mental Health Savings</Text>
                    <Text className='roi-calculator__info'>
                        Calculated as $195 per consult, an average dollar savings value.
                        We multiply this average by the total number of Mental Health consults to determine total savings.
                    </Text>
                </div>
            )}

            <Text className='reporting-note' type={TEXT_TYPES.caption}>
                NOTE: This report is for informational purposes only.
                HealthJoy has made every attempt to ensure the accuracy and reliability of the information provided herein.
                However, the information is provided "as is" without warranty of any kind.
                HealthJoy does not accept any responsibility or liability for the accuracy, content,
                completeness, legality, or reliability of the information contained in this report.
            </Text>
        </div>
    );
};

RoiCalculator.propTypes = {
    isTelemedVisible: PropTypes.bool,
    isTeladocGeneralMedicalVisible: PropTypes.bool,
    isTeladocMentalHealthVisible: PropTypes.bool,
    isMedicalBillVisible: PropTypes.bool,
    isMskVisible: PropTypes.bool,
    isPreCertificationVisible: PropTypes.bool,
    isFindCareVisible: PropTypes.bool
};

export {RoiCalculator as TestableRoiCalculator};
export default React.memo(RoiCalculator);
