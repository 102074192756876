import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SimpleToast from '@frontend/ui-kit/Components/Toast';
import {hideToast} from '../../../actions/shared';
import {getToastState} from '../../../selectors/shared';

const Toast = () => {
    const dispatch = useDispatch();
    const {isVisible, content, visibilityTime} = useSelector(getToastState);
    const timeout = useRef();

    useEffect(() => {
        if (isVisible && visibilityTime) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => dispatch(hideToast()), visibilityTime);
        }
    }, [dispatch, isVisible, content, visibilityTime]);

    return <SimpleToast content={content} isVisible={isVisible}/>;
};

export {Toast as TestableToast};
export default Toast;
