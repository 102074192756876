import React from 'react';
import {useDispatch} from 'react-redux';
import SimpleWysiwygEditor, {getDraftFromHtml, getHtmlFromDraft} from '@frontend/ui-kit/Components/WysiwygEditor';
import {requestPublicFileUploading} from '../../../actions/general';

const WysiwygEditor = props => {
    const dispatch = useDispatch();

    const onUploadImage = async file => {
        const {url} = await dispatch(requestPublicFileUploading(file));

        return {data: {link: url}};
    };

    return <SimpleWysiwygEditor onUploadImage={onUploadImage} {...props}/>;
};

export default WysiwygEditor;
export {getDraftFromHtml, getHtmlFromDraft, WysiwygEditor as TestableWysiwygEditor};
