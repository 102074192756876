import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {Form, Field} from '../../shared/FormComponents';
import {requestConsultFees, requestConsultFeesUpdating} from '../../../actions/adminPortal';
import {FORMS} from '../../../constants';
import {compose, normalizePositiveNumber, omit} from '../../../utils';
import withUnsavedFormWarning from '../../../HOC/withUnsavedFormWarning';
import './index.scss';

const ConsultFeeReviewPopup = ({companyAlias, isEditDisabled, onCancel, onWarnAboutUnsavedForm}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestConsultFees(companyAlias));
            const enhancedData = {...data, hsa_consult_fee: data.hsa_consult_fee === null ? 'N/A' : data.hsa_consult_fee};
            setInitialValues(enhancedData);
        })();
    }, [companyAlias, dispatch]);

    const onSubmit = useCallback(async values => {
        const config = omit(values, ['hsa_consult_fee']);
        const {isSuccess, submissionErrors, data} = await dispatch(requestConsultFeesUpdating({companyAlias, config}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(data);
        onCancel();
    }, [onCancel, companyAlias, dispatch]);

    return (
        <Form name={FORMS.consultFees} initialValues={initialValues} onSubmit={onSubmit}>
            {({handleSubmit, dirty}) => {
                const cancelHandler = () => !dirty ? onCancel() : onWarnAboutUnsavedForm(onCancel);

                const actionBar = (
                    <React.Fragment>
                        <Button data-testid='cancel-button' type={BUTTON_TYPES.secondary} onClick={cancelHandler}>Cancel</Button>
                        {!isEditDisabled && <Button type={BUTTON_TYPES.primary} onClick={handleSubmit} disabled={!dirty} className='consult-fee__save-button' data-testid='save-form'>Save</Button>}
                    </React.Fragment>
                );
                const popupProps = {title: 'Consult Fees', actionBar};

                return (
                    <PopupContent {...popupProps}>
                        <form onSubmit={handleSubmit} className='template-details' noValidate>
                            <Field name='consult_fee' icon={ICON_TYPES.money} parse={normalizePositiveNumber} placeholder='0.00'>
                                {props => <Input {...props} disabled={isEditDisabled} label='Telemedicine Consult' wrapperClassName='mb-12'/>}
                            </Field>

                            <Field name='hsa_consult_fee' icon={ICON_TYPES.money} placeholder=''>
                                {props => <Input {...props} disabled label='Telemedicine Consult - HSA Members' wrapperClassName='mb-12'/>}
                            </Field>

                            <Field name='bh_consult_fee' icon={ICON_TYPES.money} parse={normalizePositiveNumber} placeholder='0.00'>
                                {props => <Input {...props} disabled={isEditDisabled} label='Behavioral Health Consult' wrapperClassName='mb-12'/>}
                            </Field>

                            <Field name='bh_hsa_consult_fee' icon={ICON_TYPES.money} parse={normalizePositiveNumber} placeholder='0.00'>
                                {props => <Input {...props} disabled={isEditDisabled} label='Behavioral Health Consult - HSA Members'/>}
                            </Field>
                        </form>
                    </PopupContent>
                );
            }}
        </Form>
    );
};

ConsultFeeReviewPopup.propTypes = {
    companyAlias: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onWarnAboutUnsavedForm: PropTypes.func.isRequired,
    isEditDisabled: PropTypes.bool.isRequired
};

export {ConsultFeeReviewPopup as TestableConsultFeeReviewPopup};
export default compose(
    withUnsavedFormWarning,
    React.memo
)(ConsultFeeReviewPopup);
