import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import VideoTooltip from '../VideoTooltip';
import {getProfileInfo} from '../../../selectors/general';
import {setUserOnboarded} from '../../../actions/general';
import {getItemKeyValue} from '../../../utils';

const HelpVideoTooltip = ({videoOptions}) => {
    const [isOpened, setIsOpened] = useState(false);
    const {tooltipType, isSkipOnLoad} = videoOptions;
    const dispatch = useDispatch();
    const {user_onboarded: userOnboarded = []} = useSelector(getProfileInfo) ?? {};

    const toggleIsOpened = isOpened => setIsOpened(isOpened);

    const getIsUserOnboarded = () => userOnboarded.map(getItemKeyValue('onboarded_type')).includes(tooltipType);

    const updateIsOpened = () => {
        if (isSkipOnLoad) {
            return false;
        }

        const isUserOnboarded = getIsUserOnboarded();

        setIsOpened(!isUserOnboarded);
    };

    const dismissVideo = () => {
        const isUserOnboarded = getIsUserOnboarded();

        if (!isUserOnboarded) {
            dispatch(setUserOnboarded(tooltipType));
        }

        setIsOpened(false);
    };

    useEffect(() => {
        updateIsOpened();
    }, [userOnboarded]);

    return <VideoTooltip videoOptions={videoOptions} isOpened={isOpened} dismissVideo={dismissVideo} toggleIsOpened={toggleIsOpened}/>;
};

HelpVideoTooltip.propTypes = {
    videoOptions: PropTypes.shape({tooltipType: PropTypes.string, isSkipOnLoad: PropTypes.bool}).isRequired,
};

export {HelpVideoTooltip as TestableHelpVideoTooltip};
export default HelpVideoTooltip;
