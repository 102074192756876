import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Link from '@frontend/ui-kit/Components/Link';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {expandNextTimelineStep} from '../../../actions/home';
import {getActiveCompanyPlanPeriods, getTimelineImplementationManager} from '../../../selectors/general';
import {requestTimelineStepUpdating} from '../../../actions/general';
import {equal, getEqual} from '../../../utils';
import {ROUTES, STEP_ALIASES, TIMELINE_STEP_STATUSES, PLAN_PERIOD_TYPES} from '../../../constants';
import './index.scss';

const TimelineStepActionBar = ({subSteps, alias, status}) => {
    const dispatch = useDispatch();
    const {calendly_link: calendlyLink} = useSelector(getTimelineImplementationManager) || {};
    const planPeriods = useSelector(getActiveCompanyPlanPeriods);

    const {url: newSubStepUrl} = subSteps.find(getEqual(TIMELINE_STEP_STATUSES.new, 'status')) || {};
    const {url: incompletedSubStepUrl} = subSteps.find(getEqual(TIMELINE_STEP_STATUSES.incompleted, 'status')) || {};
    const {id: activePlanPeriodId} = planPeriods.find(({status}) => [PLAN_PERIOD_TYPES.launch, PLAN_PERIOD_TYPES.renewal, PLAN_PERIOD_TYPES.inService].some(getEqual(status))) || {};

    const [isBenefitsOverviewStep, isBenefitsReviewStep, isEligibilityReviewStep, isLaunchPreparationStep, isKickoffCallStep] = [
        STEP_ALIASES.benefitsOverview,
        STEP_ALIASES.benefitsReview,
        STEP_ALIASES.eligibilityReview,
        STEP_ALIASES.launchPreparation,
        STEP_ALIASES.kickoffCall
    ].map(getEqual(alias));
    const isCustomStep = [isBenefitsReviewStep, isEligibilityReviewStep, isLaunchPreparationStep, isKickoffCallStep].some(Boolean);

    const onCompleteSubsteps = async () => {
        subSteps.forEach(({id: subStepId}) => dispatch(requestTimelineStepUpdating(subStepId, {status: TIMELINE_STEP_STATUSES.completed})));
    };

    const onExpandNextTimelineStep = () => dispatch(expandNextTimelineStep());

    return (
        <div className='timeline-step-action-bar'>
            {isKickoffCallStep && calendlyLink && <Link className='timeline-step-action-bar__button' isButton href={calendlyLink} type={BUTTON_TYPES.primary} target='_blank'>Select a Time</Link>}

            {isBenefitsReviewStep && (
                <React.Fragment>
                    <Link className='timeline-step-action-bar__button' isButton href={`${ROUTES.benefits}/${activePlanPeriodId}/preview`}>Review</Link>

                    {!equal(TIMELINE_STEP_STATUSES.completed, status) && <Button className='timeline-step-action-bar__button' onClick={onCompleteSubsteps} type={BUTTON_TYPES.primary}>Mark Review as Done</Button>}
                </React.Fragment>
            )}

            {!isCustomStep && (
                <React.Fragment>
                    {equal(TIMELINE_STEP_STATUSES.new, status) && <Link className='timeline-step-action-bar__button' isButton href={newSubStepUrl} type={BUTTON_TYPES.primary}>Start</Link>}

                    {equal(TIMELINE_STEP_STATUSES.started, status) && <Link className='timeline-step-action-bar__button' isButton href={newSubStepUrl} type={BUTTON_TYPES.primary}>Continue</Link>}

                    {equal(TIMELINE_STEP_STATUSES.incompleted, status) && <Link className='timeline-step-action-bar__button' isButton href={incompletedSubStepUrl} type={BUTTON_TYPES.primary}>Complete missing info</Link>}

                    {equal(TIMELINE_STEP_STATUSES.completed, status) && (
                        <React.Fragment>
                            {isBenefitsOverviewStep && <Link className='timeline-step-action-bar__button' isButton href={ROUTES.benefitsOverview} type={BUTTON_TYPES.secondary}>Review information</Link>}

                            <Button className='timeline-step-action-bar__button' onClick={onExpandNextTimelineStep} type={BUTTON_TYPES.secondary}>Go to next step</Button>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

TimelineStepActionBar.propTypes = {
    alias: PropTypes.string,
    status: PropTypes.string,
    subSteps: PropTypes.arrayOf(PropTypes.shape({}))
};

export {TimelineStepActionBar as TestableTimelineStepActionBar};
export default TimelineStepActionBar;
