import React from 'react';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import macUserIcon from '../../../../static/images/macUser.svg';
import './index.scss';

const EmptyListPlaceholder = () => (
    <ContentSection className='empty-list'>
        <img src={macUserIcon} alt='user' className='empty-list__icon'/>
        <Text className='mt-20 empty-list__text' type={TEXT_TYPES.bodyBold}>Nothing here...</Text>
    </ContentSection>
);

export {EmptyListPlaceholder as TestableEmptyListPlaceholder};
export default EmptyListPlaceholder;
