import {
    ADD_ADMIN_CONTACT,
    RECEIVE_ADMIN_CONTACTS,
    CLEAR_ADMIN_CONTACTS,
    RECEIVE_IMPLEMENTATION_MANAGERS_LIST,
    RECEIVE_IMPLEMENTATION_SPECIALISTS_LIST,
    SET_EXPANDED_TIMELINE_STEP,
    SWITCH_TIMELINE_IMPLEMENTATION_MANAGER,
    RECEIVE_CSM_LIST
} from './actionTypes';
import {receiveTimeline, setActiveCompany} from './general';
import {getActiveCompany, getTimelineImplementationSpecialists} from '../selectors/general';
import {getExpandedTimelineStep} from '../selectors/home';
import apiServices from '../apiServices';
import {USER_ROLES} from '../constants';
import {getEnhancedTimelineSteps} from '../helpers';
import {getItemKeyValue, isEmpty} from '../utils';

const receiveImplementationManagersList = implementationManagersList => ({type: RECEIVE_IMPLEMENTATION_MANAGERS_LIST, implementationManagersList});

const receiveImplementationSpecialistsList = implementationSpecialists => ({type: RECEIVE_IMPLEMENTATION_SPECIALISTS_LIST, implementationSpecialists});

const receiveCsmList = csmList => ({type: RECEIVE_CSM_LIST, csmList});

const receiveAdminContacts = adminContacts => ({type: RECEIVE_ADMIN_CONTACTS, adminContacts});

const switchTimelineImplementationManager = () => ({type: SWITCH_TIMELINE_IMPLEMENTATION_MANAGER});

export const addAdminContact = (adminContact = {}) => ({type: ADD_ADMIN_CONTACT, adminContact});

export const setExpandedTimelineStep = expandedTimelineStep => ({type: SET_EXPANDED_TIMELINE_STEP, expandedTimelineStep});

export const expandNextTimelineStep = () => (dispatch, getState) => {
    const state = getState();
    const expandedTimelineStep = getExpandedTimelineStep(state);

    dispatch(setExpandedTimelineStep(expandedTimelineStep + 1));
};

export const requestImplementationManagersList = () => async dispatch => {
    const {data: implementationManagersList, isSuccess} = await apiServices.getManagersList({type: 'implementation_manager'});

    if (!isSuccess) {
        return false;
    }

    dispatch(receiveImplementationManagersList(implementationManagersList));
};

export const requestImplementationSpecialistsList = () => async dispatch => {
    const {data: implementationSpecialists, isSuccess} = await apiServices.getManagersList({type: 'implementation_specialist'});

    if (!isSuccess) {
        return false;
    }

    dispatch(receiveImplementationSpecialistsList(implementationSpecialists));
};

export const requestCsmList = () => async dispatch => {
    const {data: csmList} = await apiServices.getManagersList({type: 'customer_success_manager'});

    dispatch(receiveCsmList(csmList));
};

const getRequestTimelineSubmission = requestFunc => timelineInfo => async (dispatch, getState) => {
    const state = getState();
    const activeCompany = getActiveCompany(state);
    const {timeline_id: timelineId, alias: companyAlias} = activeCompany;
    const enhancedTimelineInfo = {...timelineInfo, company_alias: companyAlias};

    const {data, isSuccess} = await requestFunc({timelineId, timelineInfo: enhancedTimelineInfo});
    const timeline = isSuccess ? getEnhancedTimelineSteps(data) : {};

    if (isSuccess) {
        dispatch(receiveTimeline(timeline));
        dispatch(setActiveCompany({...activeCompany, timeline_id: data.id}));
    }

    return {isSuccess, submissionErrors: data.messages};
};

export const requestTimelineUpdating = getRequestTimelineSubmission(apiServices.updateTimeline);
export const requestTimelineCreating = getRequestTimelineSubmission(apiServices.generateTimeline);

export const requestBasicTimelineUpdating = query => async () => {
    const {data, isSuccess} = await apiServices.updateTimeline(query);
    const timeline = isSuccess ? getEnhancedTimelineSteps(data) : {};

    return {timeline, isSuccess};
};

export const requestTimelineImplementationManagerSwitching = (implementationManagerId, ownerId) => async (dispatch, getState) => {
    const state = getState();
    const {timeline_id: timelineId} = getActiveCompany(state);
    const implementationSpecialists = getTimelineImplementationSpecialists(state);
    const implementationSpecialistsIDs = implementationSpecialists?.map(getItemKeyValue('id')) || [];
    const timelineInfo = {implementation_manager_id: implementationManagerId, owner_id: ownerId, implementation_specialist_ids: implementationSpecialistsIDs};

    const {data, isSuccess} = await apiServices.updateTimeline({timelineId, timelineInfo});

    if (!isSuccess) {
        return false;
    }

    const timeline = getEnhancedTimelineSteps(data);

    dispatch(switchTimelineImplementationManager());
    dispatch(receiveTimeline(timeline));
};

export const clearAdminContacts = () => ({type: CLEAR_ADMIN_CONTACTS});

export const requestAdminContacts = () => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getContactList({companyId, role: USER_ROLES.admin, active: false});
    const adminContacts = isSuccess && !isEmpty(data) ? data : [{}];

    dispatch(receiveAdminContacts(adminContacts));
};

export const requestAdminInvitation = contact => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);
    const {id: contactId} = contact;
    const enhancedContact = {...contact, role: USER_ROLES.admin, user_id: contactId};

    const {data, isSuccess} = contactId
        ? await apiServices.updateContact({companyId, contact: enhancedContact, reinvite: true})
        : await apiServices.addContact({companyId, contact: enhancedContact});
    const {user, messages, message} = data;

    return {isSuccess, invitedContact: user || data, submissionErrors: messages, submissionGlobalError: message};
};
