import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Separator from '@frontend/ui-kit/Components/Separator';
import Select from '@frontend/ui-kit/Components/Select';
import {Field} from '../../shared/FormComponents';
import {STATES_OPTIONS} from '../../../options';
import {validateMaxLength, validateRequired} from '../../../utils';
import {DEFAULT_PHONE_PLACEHOLDER} from '../../../constants';

/* istanbul ignore next */
const validate = values => {
    return {
        pokitdoc_id: validateRequired(values.pokitdoc_id),
        npi: validateRequired(values.npi) || validateMaxLength(values.npi, 10),
        issuer_name: validateRequired(values.issuer_name),
        display_name: validateRequired(values.display_name),
        state: validateRequired(values.state),
        logo_url: validateRequired(values.logo_url),
        url: validateRequired(values.url)
    };
};

const CarrierInfo = ({isIssuerId}) => (
    <React.Fragment>
        <Row>
            <Column sm={4}>
                <Field name='pokitdoc_id'>{props => <Input {...props} label='Change Healthcare ID' isRequired/>}</Field>
            </Column>

            {isIssuerId && (
                <Column sm={4}>
                    <Field name='hios_id'>{props => <Input {...props} disabled label='Hios ID (Issuer ID)' isRequired/>}</Field>
                </Column>
            )}

            <Column sm={4}>
                <Field name='npi'>{props => <Input {...props} maxLength={10} type='number' label='NPI' isRequired/>}</Field>
            </Column>
        </Row>

        <Separator/>

        <Row>
            <Column sm={4}>
                <Field name='issuer_name'>{props => <Input {...props} label='Issuer Name' isRequired/>}</Field>
            </Column>

            <Column sm={4}>
                <Field name='display_name'>{props => <Input {...props} label='Display Name' isRequired/>}</Field>
            </Column>

            <Column sm={4}>
                <Field name='state'>{props => <Select {...props} options={STATES_OPTIONS} label='State' isRequired/>}</Field>
            </Column>
        </Row>

        <Separator/>

        <Row>
            <Column sm={4}>
                <Field name='customer_service_phone_local'>
                    {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Customer Service Phone Local'/>}
                </Field>
            </Column>

            <Column sm={4}>
                <Field name='customer_service_phone_toll_free'>
                    {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Customer Service Phone toll free'/>}
                </Field>
            </Column>

            <Column sm={4}>
                <Field name='customer_service_phone_tty'>
                    {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Customer Service Phone TTY'/>}
                </Field>
            </Column>
        </Row>

        <Separator/>

        <Row>
            <Column sm={4}>
                <Field name='logo_url'>{props => <Input {...props} label='Logo URL' isRequired/>}</Field>
            </Column>

            <Column sm={4}>
                <Field name='url'>{props => <Input {...props} label='Issuer URL' isRequired/>}</Field>
            </Column>
        </Row>
    </React.Fragment>
);

CarrierInfo.propTypes = {
    isIssuerId: PropTypes.bool
};

export {validate, CarrierInfo as TestableCarrierInfo};
export default React.memo(CarrierInfo);
