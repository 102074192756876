import {
    RECEIVE_BENEFITS_CLASSES,
    RECEIVE_COMPANY_EMPLOYEES,
    RECEIVE_COMPANY_EMPLOYEE_COUNT,
    RECEIVE_INDUSTRY_LIST,
    RECEIVE_REASON_LIST
} from '../actions/actionTypes';

export const initialState = {
    details: {
        employees: [],
        employeeCount: null,
        demoUserCount: null
    },
    benefitsClasses: [],
    industryList: [],
    reasonList: []
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case RECEIVE_COMPANY_EMPLOYEES: {
            const {employees} = payload;
            const details = {...state.details, employees};

            return {...state, details};
        }

        case RECEIVE_COMPANY_EMPLOYEE_COUNT: {
            const {employeeCount, demoUserCount} = payload;
            const details = {...state.details, employeeCount, demoUserCount};

            return {...state, details};
        }

        case RECEIVE_INDUSTRY_LIST: {
            const {industryList} = payload;

            return {...state, industryList};
        }

        case RECEIVE_REASON_LIST: {
            const {reasonList} = payload;

            return {...state, reasonList};
        }

        case RECEIVE_BENEFITS_CLASSES: {
            const {benefitsClasses} = payload;

            return {...state, benefitsClasses};
        }

        default: {
            return state;
        }
    }
};
