import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {Form, Field, FieldArray} from '../../shared/FormComponents';
import {requestBenefitsClassesInfo, requestBenefitsClassesInfoUpdating} from '../../../actions/company';
import {validateRequired} from '../../../utils';
import {FORMS} from '../../../constants';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    const {classes = []} = values;

    return {
        classes: classes.map(({label}) => ({
            label: validateRequired(label)
        }))
    };
};

const BenefitsClassesFormPopup = ({onClose}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const {planPeriodId} = useParams();

    useEffect(() => {
        (async () => {
            const {benefitsClassesInfo, isSuccess} = await dispatch(requestBenefitsClassesInfo(planPeriodId));

            if (isSuccess) {
                setInitialValues(benefitsClassesInfo);
            }
        })();
    }, [dispatch, planPeriodId]);

    const getClasses = ({fields}) => {
        const onAddClass = () => fields.push({});

        return (
            <React.Fragment>
                {fields.map((field, index) => (
                    <React.Fragment key={field}>
                        <Field name={`${field}.value`}>{props => <Input {...props} type='hidden'/>}</Field>

                        <Field name={`${field}.label`}>
                            {props => <Input {...props} placeholder='Enter class name...' className='class-item' label={`Class ${index + 1}`} wrapperClassName='mb-12'/>}
                        </Field>
                    </React.Fragment>
                ))}

                <Button className='popup-body__button' type={BUTTON_TYPES.secondary} onClick={onAddClass}>
                    <Icon type={ICON_TYPES.circlePlus}/> Add Class
                </Button>
            </React.Fragment>
        );
    };

    const onSubmit = useCallback(async values => {
        const {benefitsClassesInfo, isSuccess, submissionErrors} = await dispatch(requestBenefitsClassesInfoUpdating(values));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(benefitsClassesInfo);
        onClose();
    }, [dispatch, onClose]);

    return (
        <Form name={FORMS.companyClasses} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit}) => {
                const popupContentProps = {
                    title: 'Create Benefits Classes',
                    actionBar: (
                        <React.Fragment>
                            <Button className='company-class-popup-body__button' onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
                            <Button className='company-class-popup-body__button' isSubmit>Save</Button>
                        </React.Fragment>
                    )
                };

                return (
                    <form onSubmit={handleSubmit} noValidate className='benefits-classes-form-popup'>
                        <PopupContent {...popupContentProps}>
                            <div className='popup-body'>
                                <Field name='plan_period_id'>{props => <Input {...props} type='hidden'/>}</Field>

                                <Text className='popup-body__description'>
                                    Please create your different Benefit classes so that you can easily assign them to your benefits.
                                    Hit <Text isInline type={TEXT_TYPES.bodyBold}>Save</Text> to submit the changes.
                                </Text>

                                <FieldArray name='classes'>{getClasses}</FieldArray>
                            </div>
                        </PopupContent>
                    </form>
                );
            }}
        </Form>
    );
};

BenefitsClassesFormPopup.propTypes = {
    onClose: PropTypes.func.isRequired
};

export {BenefitsClassesFormPopup as TestableBenefitsClassesFormPopup};
export default BenefitsClassesFormPopup;
