import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Select from '@frontend/ui-kit/Components/Select';
import AssignableActionBar from '../../shared/AssignableActionBar';
import {Form, Field} from '../../shared/FormComponents';
import LabeledTooltip from '../../shared/LabeledTooltip';
import {validateRequired, getRegisteredFieldsValues} from '../../../utils';
import {FORMS} from '../../../constants';
import {IMPLEMENTING_OPTIONS, FUNDING_STRUCTURE_OPTIONS} from '../../../options';

const IMPLEMENTING_TOOLTIP_TITLE = 'Are we implementing with the in-force carrier or a new one?';
const IMPLEMENTING_TOOLTIP_CONTENT = `
    Whether you are using the same medical plan or switching carrier(s)/plan(s) at renewal may impact your
    implementation timeline if your HealthJoy launch date is the same as your plan renewal date.
`;
const RENEWAL_DATE_TOOLTIP_CONTENT = `
    This field is used by HealthJoy to control when the app switches over to your new plan year's benefits at renewal.
    This may change based on when HealthJoy receives certain renewal information and is not necessarily the same date as your Benefits Plan Renewal.
    If your Benefits Plan Renewal Date is changing, please contact your Customer Success Team.
`;

const validate = values => ({
    funding_structure: validateRequired(values.funding_structure),
    tpa: validateRequired(values.tpa),
    pbm: validateRequired(values.pbm),
    is_existing_carrier: validateRequired(values.is_existing_carrier)
});

const HealthPlanStep = ({isReadonly, requestCompany, requestCompanyUpdating, ...props}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});

    const onSubmit = async (values, {getRegisteredFields}) => {
        const registeredFieldsValues = getRegisteredFieldsValues(getRegisteredFields(), values);

        const {isSuccess, submissionErrors} = await dispatch(requestCompanyUpdating(registeredFieldsValues));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(values);
    };

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestCompany());
            setInitialValues(data);
        })();
    }, []);

    return (
        <Form name={FORMS.companyHealthPlan} initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {({handleSubmit}) => {
                const actionBarProps = {...props, isReadonly, onSubmitStep: handleSubmit};

                return (
                    <React.Fragment>
                        <form onSubmit={handleSubmit}>
                            <Row className='company-wizard-step health-plan-step'>
                                <Column className='company-wizard-step-section' sm>
                                    <Field name='funding_structure'>
                                        {props => <Select {...props} readonly={isReadonly} options={FUNDING_STRUCTURE_OPTIONS} placeholder='Please select...' label='Funding Structure' isRequired wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='tpa'>
                                        {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='TPA or Carrier' isRequired/>}
                                    </Field>
                                </Column>

                                <Column className='company-wizard-step-section' sm>
                                    <Field name='renewal_date'>
                                        {props => <DatePicker {...props} disabled readonly={isReadonly} label={<LabeledTooltip title='Renewal Date' content={RENEWAL_DATE_TOOLTIP_CONTENT}/>} wrapperClassName='mb-12'/>}
                                    </Field>

                                    <Field name='pbm'>
                                        {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Pharmacy Benefit Manager (PBM)' isRequired/>}
                                    </Field>
                                </Column>

                                <Column className='company-wizard-step-section' sm>
                                    <Field name='is_existing_carrier'>
                                        {props => <RadioGroup {...props} readonly={isReadonly} options={IMPLEMENTING_OPTIONS} label={<LabeledTooltip title={IMPLEMENTING_TOOLTIP_TITLE} content={IMPLEMENTING_TOOLTIP_CONTENT}/>}/>}
                                    </Field>
                                </Column>
                            </Row>
                        </form>

                        <AssignableActionBar {...actionBarProps}/>
                    </React.Fragment>
                );
            }}
        </Form>
    );
};

HealthPlanStep.propTypes = {
    isReadonly: PropTypes.bool.isRequired,
    requestCompany: PropTypes.func.isRequired,
    requestCompanyUpdating: PropTypes.func.isRequired
};

export {HealthPlanStep as TestableHealthPlanStep};
export default HealthPlanStep;
