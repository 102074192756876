import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import TextSection from '../TextSection';
import {getDelimitedNum, getEqual} from '../../../utils';
import './index.scss';

const CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS = {
    telemed: 'MeMD',
    billReview: 'Medical Bill Review',
    msk: 'Musculoskeletal',
    preCertification: 'Pre-certification Steerage'
};

const ProgramSavingsOverviewContinued = props => {
    const {
        isTelemedVisible,
        isTeladocVisible,
        isTeladocGeneralMedicalVisible,
        isTeladocMentalHealthVisible,
        isMedicalBillVisible,
        isMskVisible,
        isPreCertificationVisible,
        medicalBillReviewSavings,
        rxSavings,
        telemedSavings,
        teladocGeneralMedicalSavings,
        teladocMentalHealthSavings,
        mskSavings,
        preCertificationSavings,
        providerFacilitySavings,
        savingsByServiceChartData
    } = props;
    const teladocHealthSavings = (isTeladocGeneralMedicalVisible ? teladocGeneralMedicalSavings : 0)
        + (isTeladocMentalHealthVisible ? teladocMentalHealthSavings : 0);
    const {datasets: savingsByServiceChartDatasets, labels: savingsByServiceCharLabels} = savingsByServiceChartData || {};
    const isSavingsByServiceChartDataByLabel = {
        [CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS.telemed]: isTelemedVisible,
        [CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS.billReview]: isMedicalBillVisible,
        [CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS.msk]: isMskVisible,
        [CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS.preCertification]: isPreCertificationVisible
    };
    const getSavingsByServiceChartData = ({label}) => !Object.values(CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS).some(getEqual(label)) || isSavingsByServiceChartDataByLabel[label];
    const filteredSavingsByServiceChartDatasets = savingsByServiceChartDatasets.filter(getSavingsByServiceChartData);

    const savingsByServiceChartProps = {
        Chart: LineChart,
        title: 'Program Savings by Service',
        unit: '$',
        data: {
            datasets: filteredSavingsByServiceChartDatasets,
            labels: savingsByServiceCharLabels
        },
        options: {scales: {yAxes: [{ticks: {suggestedMax: 300}}]}}
    };

    return (
        <PageContent title='2.0 Program Savings Continued'>
            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...savingsByServiceChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Program Savings Breakdown'>
                        <ul>
                            {isMedicalBillVisible && <li data-testid='mbr-list-item'>${getDelimitedNum(medicalBillReviewSavings)} in Medical Bill Review Savings</li>}
                            {isMskVisible && <li data-testid='msk-list-item'>${getDelimitedNum(mskSavings)} in MSK Therapy Savings</li>}
                            {isPreCertificationVisible && <li data-testid='pre-certification-list-item'>${getDelimitedNum(preCertificationSavings)} in Pre-certification Steerage Savings</li>}
                            <li>${getDelimitedNum(providerFacilitySavings)} in Provider/Facility Steerage Savings</li>
                            <li>${getDelimitedNum(rxSavings)} in Rx Savings</li>
                            {isTelemedVisible && <li data-testid='telemed-list-item'>${getDelimitedNum(telemedSavings)} in MeMD Consult Savings</li>}
                            {isTeladocVisible && (
                                <li data-testid='teladoc-health-list-item'>
                                    ${getDelimitedNum(teladocHealthSavings)} in Teladoc Health Consult Savings

                                    <ul>
                                        {isTeladocGeneralMedicalVisible && <li>${getDelimitedNum(teladocGeneralMedicalSavings)} General Medical (Urgent Care)</li>}
                                        {isTeladocMentalHealthVisible && <li>${getDelimitedNum(teladocMentalHealthSavings)} Mental Health</li>}
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </TextSection>
                </Column>
            </Row>
        </PageContent>
    );
};

ProgramSavingsOverviewContinued.propTypes = {
    medicalBillReviewSavings: PropTypes.number,
    rxSavings: PropTypes.number,
    telemedSavings: PropTypes.number,
    teladocGeneralMedicalSavings: PropTypes.number,
    teladocMentalHealthSavings: PropTypes.number,
    mskSavings: PropTypes.number,
    preCertificationSavings: PropTypes.number,
    providerFacilitySavings: PropTypes.number,
    isTelemedVisible: PropTypes.bool,
    isTeladocVisible: PropTypes.bool,
    isTeladocGeneralMedicalVisible: PropTypes.bool,
    isTeladocMentalHealthVisible: PropTypes.bool,
    isMedicalBillVisible: PropTypes.bool,
    isMskVisible: PropTypes.bool,
    isPreCertificationVisible: PropTypes.bool,
    savingsByServiceChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    })
};

export {ProgramSavingsOverviewContinued as TestableProgramSavingsOverviewContinued};
export default ProgramSavingsOverviewContinued;
