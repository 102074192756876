import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Input from '@frontend/ui-kit/Components/Input';
import {Form, Field} from '../../shared/FormComponents';
import AssignableActionBar from '../../shared/AssignableActionBar';
import {requestReasonList} from '../../../actions/company';
import {getReasonList} from '../../../selectors/company';
import {getRegisteredFieldsValues} from '../../../utils';
import {FORMS} from '../../../constants';
import './index.scss';

const stringifyReasonsKey = ({id, ...rest}) => ({id: `_${id}`, ...rest});
const restringifyReasonsKey = stringKey => stringKey.replace('_', '');

const getMappedFormData = data => {
    const {reason_ids = [], other} = data?.reasons;
    // FYI: final form doesn't allow us to use numeric keys as field name, that's why we need so convert such keys exact to string (10.1@.21, Yuri)
    const reasons = reason_ids.reduce((acc, id) => ({...acc, [`_${id}`]: true}), {});

    return {...data, is_other: !!other, other, reasons};
};

const HealthjoyOptionsStep = ({isReadonly, requestCompany, requestCompanyUpdating, ...props}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const reasonList = useSelector(getReasonList).map(stringifyReasonsKey);

    const onSubmit = async (values, {getRegisteredFields}) => {
        const registeredFieldsValues = getRegisteredFieldsValues(getRegisteredFields(), values);
        const {reasons = {}, other} = registeredFieldsValues;

        const selectedReasons = Object.entries(reasons)
            .filter(([_, value]) => value)
            .map(([id]) => parseInt(restringifyReasonsKey(id), 10));

        const {isSuccess, data, submissionErrors} = await dispatch(requestCompanyUpdating({reasons: {reason_ids: selectedReasons, other}}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(getMappedFormData(data));
    };

    const getReasonField = ({id, reason}) => (
        <Field name={`reasons.${id}`} key={id}>
            {props => <Checkbox {...props} readonly={isReadonly} caption={reason} wrapperClassName='mb-5'/>}
        </Field>
    );

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestCompany());
            await dispatch(requestReasonList());

            setInitialValues(getMappedFormData(data));
        })();
    }, []);

    return (
        <Form name={FORMS.companyHealthjoyOptions} initialValues={initialValues} onSubmit={onSubmit}>
            {({handleSubmit, values}) => {
                const actionBarProps = {...props, isReadonly, onSubmitStep: handleSubmit};

                return (
                    <React.Fragment>
                        <Row className='company-wizard-step healthjoy-options-step'>
                            <Column className='company-wizard-step-section' sm={12}>
                                <Text>Primary reasons for using HealthJoy (check all that apply)</Text>
                                <div className='healthjoy-options-step__content '>
                                    {reasonList.map(getReasonField)}

                                    <Field name='is_other'>{props => <Checkbox {...props} readonly={isReadonly} caption='Other'/>}</Field>
                                    {values.is_other && (
                                        <Field name='other'>
                                            {props => <Input {...props} readonly={isReadonly} className='other mt-5' type='text' placeholder='Write your reason'/>}
                                        </Field>
                                    )}
                                </div>
                            </Column>
                        </Row>

                        <AssignableActionBar {...actionBarProps}/>
                    </React.Fragment>
                );
            }}
        </Form>
    );
};

HealthjoyOptionsStep.propTypes = {
    isReadonly: PropTypes.bool.isRequired,
    requestCompany: PropTypes.func,
    requestCompanyUpdating: PropTypes.func
};

export {HealthjoyOptionsStep as TestableHealthjoyOptionsStep};
export default HealthjoyOptionsStep;
