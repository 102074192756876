import React from 'react';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Link from '@frontend/ui-kit/Components/Link';
import {ROUTES} from '../../../constants';
import {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';

const AddPhoneNumber = () => (
    <div className='authorization-form add-phone'>
        <div className='authorization-form-header'>
            <Heading className='authorization-form-header__title' type={HEADING_TYPES['2']}>Add a Phone Number</Heading>
            <Heading className='authorization-form-header__subtitle'>
                Add a phone number to your account to enable faster and easier Two-Factor Authentication codes.
            </Heading>
        </div>

        <div className='authorization-form-body'>
            <div className='authorization-form-action-bar authorization-form-action-bar_vertical'>
                <Link className='authorization-form-action-bar__button' isButton type={BUTTON_TYPES.secondary} href={ROUTES.baseContactInfo}>Add Phone Number</Link>
                <Link className='authorization-form-action-bar__button' isButton type={BUTTON_TYPES.primary} href={ROUTES.root}>Set Up Later</Link>
            </div>
        </div>
    </div>
);

export {AddPhoneNumber as TestableAddPhoneNumber};
export default React.memo(AddPhoneNumber);
