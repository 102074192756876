import React from 'react';
import PropTypes from 'prop-types';
import {FormSpy as SimpleFormSpy} from 'react-final-form';

const FormSpy = ({children, ...restProps}) => <SimpleFormSpy {...restProps}>{children}</SimpleFormSpy>;

FormSpy.propTypes = {
    children: PropTypes.func,
    subscription: PropTypes.shape({}),
    onChange: PropTypes.func
};

export default React.memo(FormSpy);
