import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Text from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import './index.scss';

const getMissingFieldLabel = label => <li key={label} className='missing-field-list__item'>{label}</li>;

const MissingFieldsPopup = ({missingFieldLabels, onClose, onMarkAsDone}) => {
    const actionBar = (
        <React.Fragment>
            <Button onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
            <Button onClick={onMarkAsDone} type={BUTTON_TYPES.primary}>Mark as Done</Button>
        </React.Fragment>
    );
    const props = {title: 'Incomplete Information', actionBar};

    return (
        <PopupContent {...props}>
            <div className='missing-fields-popup-body'>
                <Text>Are you sure you want to mark this section as done? It looks like the following information and documents are missing:</Text>

                <ul className='missing-field-list'>
                    {missingFieldLabels.map(getMissingFieldLabel)}
                </ul>

                <Text>These fields will not be included on your wallet card at launch. Would you still like to mark this as done?</Text>
            </div>
        </PopupContent>
    );
};

MissingFieldsPopup.propTypes = {
    missingFieldLabels: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onMarkAsDone: PropTypes.func.isRequired
};

export {MissingFieldsPopup as TestableMissingFieldsPopup};
export default React.memo(MissingFieldsPopup);
