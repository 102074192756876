import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Text from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';

const ConfirmationPopup = ({onClose, onDelete}) => {
    const actionBar = (
        <React.Fragment>
            <Button data-testid='cancel-button' onClick={onClose} type={BUTTON_TYPES.primary}>Keep Item</Button>
            <Button data-testid='continue-button' onClick={onDelete} type={BUTTON_TYPES.destructive}>Remove Item</Button>
        </React.Fragment>
    );
    const popupProps = {title: 'Delete Item?', actionBar};

    return (
        <PopupContent {...popupProps}>
            <Text className='mb-12'>
                Are you sure you want to delete this item from your broadcast message?
            </Text>
        </PopupContent>
    );
};

ConfirmationPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default React.memo(ConfirmationPopup);
