import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import TextSection from '../TextSection';

const ServiceUtilizationContinuedFirst = props => {
    const {
        isMedicalBillVisible,
        isFindCareVisible,
        facilityRecommendationUtilizationChartData,
        findCareSearchUtilizationChartData,
        medicalBillReviewUtilizationChartData
    } = props;

    const facilityRecommendationUtilizationChartProps = {
        Chart: LineChart,
        title: 'Facility Requests',
        description: 'Count of total facility requests completed. Requests can include various facilities for imaging, surgeries, and medical examinations.',
        data: facilityRecommendationUtilizationChartData
    };
    const findCareSearchUtilizationChartProps = {
        Chart: LineChart,
        title: 'Find Care Searches',
        description: 'Count of total number of Find Care searches with at least one facility/provider result within network.',
        data: findCareSearchUtilizationChartData
    };
    const medicalBillReviewUtilizationChartProps = {
        Chart: LineChart,
        title: 'Medical Bill Reviews',
        description: `
            Count of Medical Bill Review requests completed.
            Complicated claims advocacy can take months to resolve, so there can be more bills in review than are reflected here.
        `,
        data: medicalBillReviewUtilizationChartData
    };

    return (
        <PageContent title='3.1 Service Utilization (Continued)'>
            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...facilityRecommendationUtilizationChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Facility Requests'>
                        Count of total facility requests completed. Requests can include various facilities for imaging, surgeries, and medical examinations.
                    </TextSection>
                </Column>
            </Row>

            {isFindCareVisible && (
                <Row className='pdf-template-row mt-small'>
                    <Column className='pdf-template-row__column' xs={7}>
                        <PdfChart {...findCareSearchUtilizationChartProps}/>
                    </Column>

                    <Column className='pdf-template-row__column' xs={5}>
                        <TextSection title='Find Care Searches'>
                            Count of total number of Find Care searches with at least one facility/provider result within network.
                        </TextSection>
                    </Column>
                </Row>
            )}

            {isMedicalBillVisible && (
                <Row className='pdf-template-row mt-small'>
                    <Column className='pdf-template-row__column' xs={7}>
                        <PdfChart {...medicalBillReviewUtilizationChartProps}/>
                    </Column>

                    <Column className='pdf-template-row__column' xs={5}>
                        <TextSection title='Medical Bill Reviews'>
                            Count of Medical Bill Review requests completed.
                            Complicated claims advocacy can take months to resolve, so there can be more bills in review than are reflected here.
                        </TextSection>
                    </Column>
                </Row>
            )}
        </PageContent>
    );
};

ServiceUtilizationContinuedFirst.propTypes = {
    isMedicalBillVisible: PropTypes.bool,
    isFindCareVisible: PropTypes.bool,
    facilityRecommendationUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    findCareSearchUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    medicalBillReviewUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    })

};

export {ServiceUtilizationContinuedFirst as TestableServiceUtilizationContinuedFirst};
export default ServiceUtilizationContinuedFirst;
