import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {LookerEmbedSDK} from '@looker/embed-sdk';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import {getActiveCompany} from '../../../selectors/general';
import './index.scss';

const Looker = () => {
    const {alias: companyAlias} = useSelector(getActiveCompany);

    useEffect(() => {
        LookerEmbedSDK.init('externalhealthjoy.cloud.looker.com', {
            url: '/api/v1/looker/auth',
            params: [{name: 'company_alias', value: companyAlias}]
        });

        LookerEmbedSDK.createDashboardWithId(1)
            .appendTo('#looker-wrapper')
            .withClassName('looker-iframe')
            .build()
            .connect()
            .then(dashboard => dashboard.send('dashboard:run'))
            .catch(error => {
                console.error('An unexpected error occurred', error);
            });
    }, [companyAlias]);

    return (
        <React.Fragment>
            <Row>
                <Column sm>
                    <PageHeader title='Looker POC'/>
                </Column>
            </Row>

            <ReportingPagesTabs/>

            <div id='looker-wrapper'/>
        </React.Fragment>
    );
}

export default React.memo(Looker);
