import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfHeading from '../PdfHeading';
import PdfMetricBox from '../PdfMetricBox';
import {getDelimitedNum} from '../../../utils';

const MskProgramSummary = props => {
    const {
        mskApplicants,
        mskEnrollments,
        mskActiveMembers,
        mskPainImprovement,
        mskGraduatedPainImprovement,
        mskFunctionImprovement,
        mskGraduatedFunctionImprovement,
        mskSavings,
        mskSurgeryLikelihoodReduction
    } = props;

    return (
        <PageContent title='1.0 Program Summary'>
            <PdfHeading className='mb-small'>Member Summary</PdfHeading>
            <Row className='pdf-template-row pdf-template-row_small mb-default'>
                <Column className='pdf-template-row__column' xs={4}>
                    <PdfMetricBox className='msk-metric-box' title='Applicants' value={getDelimitedNum(mskApplicants)}/>
                </Column>

                <Column className='pdf-template-row__column' xs={4}>
                    <PdfMetricBox className='msk-metric-box' title='Accepted Members' value={getDelimitedNum(mskEnrollments)}/>
                </Column>

                <Column className='pdf-template-row__column' xs={4}>
                    <PdfMetricBox className='msk-metric-box' title='Active Members' value={getDelimitedNum(mskActiveMembers)}/>
                </Column>
            </Row>

            <PdfHeading className='mb-small'>Average Pain and Function Improvement</PdfHeading>
            <Row className='pdf-template-row pdf-template-row_small mb-default'>
                <Column className='pdf-template-row__column' xs={3}>
                    <PdfMetricBox className='msk-metric-box' title='Pain Improvement (All)' value={mskPainImprovement} unit='%' isPlaceholder={!mskPainImprovement}/>
                </Column>

                <Column className='pdf-template-row__column' xs={3}>
                    <PdfMetricBox className='msk-metric-box' title='Pain Improvement (Graduated)' value={mskGraduatedPainImprovement} unit='%' isPlaceholder={!mskGraduatedPainImprovement}/>
                </Column>

                <Column className='pdf-template-row__column' xs={3}>
                    <PdfMetricBox className='msk-metric-box' title='Function Improvement (All)' value={mskFunctionImprovement} unit='%' isPlaceholder={!mskFunctionImprovement}/>
                </Column>

                <Column className='pdf-template-row__column' xs={3}>
                    <PdfMetricBox className='msk-metric-box' title='Function Improvement (Graduated)' value={mskGraduatedFunctionImprovement} unit='%' isPlaceholder={!mskGraduatedFunctionImprovement}/>
                </Column>
            </Row>

            <PdfHeading className='mb-small'>Cost Reduction</PdfHeading>
            <Row className='pdf-template-row pdf-template-row_small mb-default'>
                <Column className='pdf-template-row__column' xs={6}>
                    <PdfMetricBox className='msk-metric-box' title='Estimated Claims Savings' value={getDelimitedNum(mskSavings)} unit='$' isPlaceholder={!mskSavings}/>
                </Column>

                <Column className='pdf-template-row__column' xs={6}>
                    <PdfMetricBox className='msk-metric-box' title='Surgery Likelihood Reduction' value={mskSurgeryLikelihoodReduction} unit='%' isPlaceholder={!mskSurgeryLikelihoodReduction}/>
                </Column>
            </Row>
        </PageContent>
    );
};

MskProgramSummary.propTypes = {
    mskApplicants: PropTypes.number,
    mskEnrollments: PropTypes.number,
    mskActiveMembers: PropTypes.number,
    mskPainImprovement: PropTypes.number,
    mskGraduatedPainImprovement: PropTypes.number,
    mskFunctionImprovement: PropTypes.number,
    mskGraduatedFunctionImprovement: PropTypes.number,
    mskSavings: PropTypes.number,
    mskSurgeryLikelihoodReduction: PropTypes.number
};

export {MskProgramSummary as TestableMskProgramSummary};
export default MskProgramSummary;
