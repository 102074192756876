import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import InternalMenu from '../../../Containers/shared/InternalMenu';
import Forms from '../DetailsForms';
import RenewalEligibilityInfoAlert from '../RenewalEligibilityInfoAlert';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {requestFilesInfoList} from '../../../actions/eligibility';
import {setInternalMenuItems} from '../../../actions/internalMenu';
import {getInternalMenuActiveItem, getInternalMenuItems} from '../../../selectors/internalMenu';
import {getFilesInfoList} from '../../../selectors/eligibility';
import {getPlanPeriodTypeCriteria, getProfilePermissions} from '../../../selectors/general';
import {isEmpty, equal, getEqual, pass} from '../../../utils';
import {
    DEFAULT_INTERNAL_MENU_INDEX,
    ELIGIBILITY_FILES_CATEGORIES,
    ELIGIBILITY_FILES_LABEL_BY_CATEGORY,
    PLAN_PERIOD_TYPES
} from '../../../constants';
import './index.scss';

const DEFAULT_FORM_CAPTION = 'If you marked that eligibility varies by benefits class in OverviewResults, make sure that the classes in the eligibility file match what you listed.';
const CUSTOM_FORM_CAPTION = 'If there are benefits or information that are not captured in the other files, please provide them individually.';

const FORM_BY_CATEGORY = {
    [ELIGIBILITY_FILES_CATEGORIES.eligibility]: Forms.EligibilityCensusFile,
    [ELIGIBILITY_FILES_CATEGORIES.medicalIds]: Forms.MedicalIds,
    [ELIGIBILITY_FILES_CATEGORIES.dental]: Forms.DentalVisionIds,
    [ELIGIBILITY_FILES_CATEGORIES.voluntary]: Forms.VoluntaryElections,
    [ELIGIBILITY_FILES_CATEGORIES.custom]: Forms.Custom
};

const EligibilityDetailsLayout = ({onComplete}) => {
    const dispatch = useDispatch();
    const {planPeriodId} = useParams();
    const profilePermissions = useSelector(getProfilePermissions);
    const activeItem = useSelector(getInternalMenuActiveItem);
    const menuItems = useSelector(getInternalMenuItems);
    const filesInfoList = useSelector(getFilesInfoList);
    const isRenewalPlanPeriod = useSelector(state => getPlanPeriodTypeCriteria(state, planPeriodId, PLAN_PERIOD_TYPES.renewal));

    const fileInfo = activeItem ? filesInfoList.find(getEqual(activeItem, 'category')) : filesInfoList[DEFAULT_INTERNAL_MENU_INDEX];
    const {category} = fileInfo || {};
    const label = ELIGIBILITY_FILES_LABEL_BY_CATEGORY[category];
    const caption = equal(category, ELIGIBILITY_FILES_CATEGORIES.custom) ? CUSTOM_FORM_CAPTION : DEFAULT_FORM_CAPTION;
    const Form = FORM_BY_CATEGORY[category];
    const formProps = {
        category,
        isReadonly: !profilePermissions.eligibility_details_edit,
        onSuccess: profilePermissions.timeline_edit ? onComplete : pass
    };

    useEffect(() => {
        (async () => {
            const {filesInfoList} = await dispatch(requestFilesInfoList(planPeriodId));
            const normalizedInternalMenuItems = filesInfoList.map(({category, completed}) => {
                return {id: category, label: ELIGIBILITY_FILES_LABEL_BY_CATEGORY[category], completed};
            });

            dispatch(setInternalMenuItems(normalizedInternalMenuItems));
        })();
    }, [dispatch, planPeriodId]);

    return !isEmpty(filesInfoList) && (
        <div className='eligibility-details-layout'>
            <Row className='eligibility-details-layout-row'>
                <Column className='eligibility-details-layout-row__column' sm={4}>
                    {!isEmpty(menuItems) && <InternalMenu/>}
                </Column>

                <Column className='eligibility-details-layout-row__column' sm={8}>
                    <ContentSection className='eligibility-details-form'>
                        <Text className='eligibility-details-form__title' type={TEXT_TYPES.bodyBold}>{label}</Text>

                        {isRenewalPlanPeriod && <RenewalEligibilityInfoAlert className='eligibility-details-layout__renewal-alert'/>}

                        <Text className='eligibility-details-form__caption'>{caption}</Text>

                        <div className='eligibility-details-form-body'>
                            {fileInfo && <Form {...formProps}/>}
                        </div>
                    </ContentSection>
                </Column>
            </Row>
        </div>
    );
};

EligibilityDetailsLayout.propTypes = {
    onComplete: PropTypes.func
};

export {EligibilityDetailsLayout as TestableEligibilityDetailsLayout};
export default withSubStepCompletion(EligibilityDetailsLayout);
