import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {generateEnhancedCardPreview, getSortedCards} from '@frontend/wallet-cards';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Sorter from '@frontend/ui-kit/Components/Sorter';
import {requestWalletCards, requestWalletCardsSetting} from '../../../actions/benefits';
import {moveArrayItem} from '../../../utils';
import './index.scss';

const CardsReorderingPopup = ({onSave, onCancel}) => {
    const [walletCards, setWalletCards] = useState([]);
    const dispatch = useDispatch();
    const {planPeriodId} = useParams();

    useEffect(() => {
        (async () => {
            const {walletCards = []} = await dispatch(requestWalletCards(planPeriodId));
            const sortedCards = getSortedCards(walletCards);

            setWalletCards(sortedCards);
        })();
    }, []);

    const getSortableCardPreview = card => {
        const {logo, title} = generateEnhancedCardPreview(card);

        return (
            <div className='sortable-card-preview' key={card.view.id}>
                <div className='sortable-card-preview__logo'>{logo}</div>
                <div className='sortable-card-preview__title' dangerouslySetInnerHTML={{__html: title}}/>
            </div>
        );
    };

    const onCardsSave = async () => {
        await dispatch(requestWalletCardsSetting(walletCards));

        onSave();
    };

    const onCardsReordering = ({oldIndex, newIndex}) => setWalletCards(moveArrayItem(walletCards, oldIndex, newIndex));

    const actionBar = (
        <React.Fragment>
            <Button type={BUTTON_TYPES.secondary} onClick={onCancel}>Cancel</Button>
            <Button onClick={onCardsSave}>Save</Button>
        </React.Fragment>
    );

    return (
        <PopupContent title='Reorder Wallet Cards' actionBar={actionBar}>
            <div className='cards-reordering-popup'>
                <Text type={TEXT_TYPES.helper}>Select and drag cards to reorder how they appear in your employees' benefits wallet.</Text>
                <Text type={TEXT_TYPES.helper} className='cards-reordering-popup__note'>Note: This view represents all of your wallet cards at the company level. Your employees will only see the cards that are applicable to them.</Text>

                <Sorter className='sortable-card-previews' onChange={onCardsReordering}>
                    {walletCards.map(getSortableCardPreview)}
                </Sorter>

                <Text className='cards-reordering-popup__caption'>(Drag to reorder)</Text>
            </div>
        </PopupContent>
    );
};

CardsReorderingPopup.propTypes = {
    onSave: PropTypes.func,
    onCancel: PropTypes.func
};

export {CardsReorderingPopup as TestableCardsReorderingPopup};
export default CardsReorderingPopup;
