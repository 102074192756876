import React from 'react';
import Link from '@frontend/ui-kit/Components/Link';
import {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {ROUTES} from '../../../constants';
import './index.scss';

const HomeButton = () => (
    <div className='home-button'>
        <Link className='home-button__button' isButton href={ROUTES.root} type={BUTTON_TYPES.secondary}>Return home</Link>
    </div>
);

export {HomeButton as TestableHomeButton};
export default React.memo(HomeButton);
