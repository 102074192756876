import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import NavigationController from '@frontend/ui-kit/Components/NavigationController';
import withUnsavedFormWarning from '../withUnsavedFormWarning';
import {GO_PREV_WIZARD_STEP, SET_INTERNAL_MENU_ACTIVE_ITEM} from '../../actions/actionTypes';
import {getFormsDirtiness} from '../../selectors/shared';
import {AUTHORIZATION_BASE_ROUTE, ERROR_BASE_ROUTE, LEAVING_IGNORING_FORMS} from '../../constants';
import {compose, omit} from '../../utils';

const withNavigationControl = Component => {
    const WithNavigationControl = ({onWarnAboutUnsavedForm, ...props}) => {
        const dispatch = useDispatch();
        const formsDirtiness = useSelector(getFormsDirtiness);
        const isDirtyForm = useMemo(() => {
            return Object.values(omit(formsDirtiness, LEAVING_IGNORING_FORMS)).some(Boolean);
        }, [formsDirtiness]);
        const routerNavigationControllerProps = {
            // TODO: extend nav controller with some ability to unblock user navigation even with dirty forms (remove contact as an example) (Yuri, 24.11.2021)
            when: isDirtyForm,
            showPrompt: onWarnAboutUnsavedForm,
            accessibleEntities: [AUTHORIZATION_BASE_ROUTE, ERROR_BASE_ROUTE]
        };
        const actionNavigationControllerProps = {
            when: isDirtyForm,
            showPrompt: onWarnAboutUnsavedForm,
            applyEntity: dispatch,
            rejectableEntities: [SET_INTERNAL_MENU_ACTIVE_ITEM, GO_PREV_WIZARD_STEP]
        };

        return (
            <React.Fragment>
                <NavigationController.Router {...routerNavigationControllerProps}/>
                <NavigationController.Action {...actionNavigationControllerProps}/>

                <Component {...props}/>
            </React.Fragment>
        );
    };

    WithNavigationControl.propTypes = {
        onWarnAboutUnsavedForm: PropTypes.func
    };

    return WithNavigationControl;
};

export {withNavigationControl as testableWithNavigationControl};
export default compose(
    withUnsavedFormWarning,
    withNavigationControl
);
