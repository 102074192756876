import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import Input from '@frontend/ui-kit/Components/Input';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import {Form, Field} from '../../shared/FormComponents';
import {requestPasswordRecovering} from '../../../actions/authorization';
import {validateEmail} from '../../../utils';
import {FORMS, ROUTES} from '../../../constants';

/* istanbul ignore next */
const validate = values => ({
    username: validateEmail(values.username)
});

const PasswordRecoveryForm = () => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({username: ''});
    const onSubmit = useCallback(async values => {
        const {isSuccess, submissionErrors} = await dispatch(requestPasswordRecovering(values));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(values);
    }, [dispatch]);

    return (
        <Form name={FORMS.passwordRecovery} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, values, submitSucceeded}) => (
                <form className='authorization-form' onSubmit={handleSubmit} noValidate>
                    {!submitSucceeded && (
                        <React.Fragment>
                            <div className='authorization-form-header'>
                                <Heading className='authorization-form-header__title' type={HEADING_TYPES['1']}>Reset Password</Heading>

                                <Text className='authorization-form-header__subtitle'>
                                    Enter your email address below to receive an email with instructions to reset your password.
                                </Text>
                            </div>

                            <div className='authorization-form-body'>
                                <Field name='username'>
                                    {props => <Input {...props} type='email' placeholder='Enter your email...' label='Email'/>}
                                </Field>

                                <div className='authorization-form-action-bar'>
                                    <Button className='authorization-form-action-bar__button' type={BUTTON_TYPES.primary} isSubmit>Reset Password</Button>

                                    <div className='authorization-form-action-bar__link-wrapper'>
                                        Back to <Link href={ROUTES.login}>Login</Link>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    {submitSucceeded && (
                        <React.Fragment>
                            <div className='authorization-form-header'>
                                <Heading className='authorization-form-header__title' type={HEADING_TYPES['2']}>We've sent you an email to reset your password</Heading>

                                <Text className='authorization-form-header__subtitle'>
                                    You will receive an email at <span className='user-email'>{values.username}</span> with a link to reset your password.
                                    If you don't see it within a few minutes, check your spam or junk folder.
                                </Text>
                            </div>

                            <div className='authorization-form-body'>
                                <div className='authorization-form-action-bar'>
                                    <div className='authorization-form-action-bar__link-wrapper'>
                                        Back to <Link href={ROUTES.login}>Login</Link>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </form>
            )}
        </Form>
    );
};

export {PasswordRecoveryForm as TestablePasswordRecoveryForm};
export default PasswordRecoveryForm;
