import React, {useCallback, useMemo, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Table from '@frontend/ui-kit/Components/Table';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import LoadingBar from '../../shared/LoadingBar';
import UnavailableEligibilityDataAlert from '../../shared/UnavailableEligibilityDataAlert';
import {requestJobs, requestJob, requestJobSetting} from '../../../actions/general';
import {requestExportedFiles, setStartedFileExportIds, requestExportFileSaving} from '../../../actions/eligibility';
import {getActiveCompany, getActiveCompanyPlanPeriodById} from '../../../selectors/general';
import {getExportedFiles, getStartedFileExportIds} from '../../../selectors/eligibility';
import {equal, getEqual, formatDate, pipe, getItemKeyValue, formatBytes, negateFunc, getFileName, convertDateToTimeZone} from '../../../utils';
import {PLAN_PERIOD_TYPES, JOB_STATUSES, JOB_TYPES, FILE_FORMATS} from '../../../constants';
import {requestCompanyEmployeeCount} from '../../../actions/company';
import {getCompanyDetails} from '../../../selectors/company';
import './index.scss';

const INTERVAL_DELAY = 5000;

const TABLE_PAGE_SIZE = 20;

const FileExport = () => {
    const dispatch = useDispatch();
    const [startedFileExport, setStartedFileExport] = useState(null);
    const [table, setTable] = useState({unitCount: 0});
    const {planPeriodId} = useParams();
    const intervalIdRef = useRef(null);
    const {alias: companyAlias} = useSelector(getActiveCompany);
    const exportedFiles = useSelector(getExportedFiles);
    const startedFileExportIds = useSelector(getStartedFileExportIds);
    const {start_date: startDate, end_date: endDate, status: planPeriodStatus} = useSelector(state => getActiveCompanyPlanPeriodById(state, planPeriodId));
    const {employeeCount, demoUserCount} = useSelector(getCompanyDetails);
    const areEmployees = useMemo(() => [employeeCount, demoUserCount].some(negateFunc(getEqual(0))), [employeeCount, demoUserCount]);
    const isPastPlanPeriod = equal(planPeriodStatus, PLAN_PERIOD_TYPES.past);

    const fileExportContext = useMemo(() => ({
        type: JOB_TYPES.enrollmentExport,
        company_alias: companyAlias,
        from_date: startDate,
        to_date: endDate
    }), [companyAlias, startDate, endDate]);

    const getTableColumns = useCallback(() => {
        return [
            {Header: 'Requester', accessor: 'created_by', width: 220},
            {Header: 'File Type', accessor: 'file_type', width: 120, Cell: ({value}) => <Sticker type={STICKER_TYPES.default} className='file-type'>{value}</Sticker>},
            {Header: 'File Size', accessor: 'file_size', width: 120, Cell: pipe(getItemKeyValue('value'), formatBytes)},
            {Header: 'Date', accessor: 'created_at', width: 200, Cell: ({value}) => `${formatDate(convertDateToTimeZone(value), 'MM/dd/yyyy h:mm aa')} (CDT)`},
            {
                Header: 'Action',
                accessor: 'url',
                minWidth: 120,
                align: 'right',
                Cell: ({value: url}) => {
                    const onDownloadFile = () => dispatch(requestExportFileSaving({url, name: getFileName(url)}));

                    return <Button data-testid='download-old-file' className='file-action' type={BUTTON_TYPES.tertiary} onClick={onDownloadFile}>Download Old File</Button>;
                }
            }
        ];
    }, [dispatch]);

    const onFetchTableData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE} = {}) => {
        const params = {
            startDate,
            endDate,
            limit: pageSize,
            offset: pageIndex * pageSize
        };
        const {count: unitCount} = await dispatch(requestExportedFiles(params));

        setTable({unitCount});
    }, [dispatch, startDate, endDate]);

    const onFinishFileExport = useCallback(async ({id, result}, startedFileExportIds) => {
        const {url} = result ?? {};
        const {isSuccess: isFileSaved} = await dispatch(requestExportFileSaving({url, name: getFileName(url)}));

        if (!isFileSaved) {
            return;
        }

        const updatedStartedFileExportIds = startedFileExportIds.filter(negateFunc(getEqual(id)));
        dispatch(setStartedFileExportIds(updatedStartedFileExportIds));

        setStartedFileExport(null);
        onFetchTableData();
    }, [dispatch, onFetchTableData]);

    const checkStartedFileExport = useCallback(async ({id}, startedFileExportIds) => {
        const {job: fileExport} = await dispatch(requestJob(id));
        const [isFileExportFinished, isFileExportFailed] = [JOB_STATUSES.finished, JOB_STATUSES.failed].map(getEqual(fileExport.status));

        setStartedFileExport(fileExport);

        if (isFileExportFailed || isFileExportFinished) {
            clearInterval(intervalIdRef.current);
        }

        if (isFileExportFinished) {
            if (startedFileExportIds.includes(fileExport.id)) {
                return onFinishFileExport(fileExport, startedFileExportIds);
            }

            setStartedFileExport(null);
            onFetchTableData();
        }
    }, [dispatch, onFinishFileExport, onFetchTableData]);

    const onRequestFileExport = useCallback(async startedFileExportIds => {
        const {job: fileExport, isSuccess} = await dispatch(requestJobSetting(fileExportContext));

        if (!isSuccess) {
            return;
        }

        const updatedStartedFileExportIds = [...startedFileExportIds, fileExport.id];
        dispatch(setStartedFileExportIds(updatedStartedFileExportIds));

        setStartedFileExport(fileExport);
        intervalIdRef.current = setInterval(() => checkStartedFileExport(fileExport, updatedStartedFileExportIds), INTERVAL_DELAY);
    }, [dispatch, fileExportContext, checkStartedFileExport]);

    const onRestartFileExport = useCallback(async startedFileExportIds => {
        const updatedStartedFileExportIds = startedFileExportIds.filter(negateFunc(getEqual(startedFileExport.id)));
        dispatch(setStartedFileExportIds(updatedStartedFileExportIds));

        onRequestFileExport(updatedStartedFileExportIds);
    }, [dispatch, startedFileExport, onRequestFileExport]);

    useEffect(() => {
        (async () => {
            dispatch(requestCompanyEmployeeCount());
            const {jobs: fileExports} = await dispatch(requestJobs(fileExportContext));

            // FYI: we cannot combine these cycles cuz we need to check each status separately by priority (23.03.2022, Oleh)
            const processableFileExport = fileExports.find(({id, status}) => equal(status, JOB_STATUSES.failed) && startedFileExportIds.includes(id))
                ?? fileExports.find(getEqual(JOB_STATUSES.running, 'status'))
                ?? fileExports.find(getEqual(JOB_STATUSES.new, 'status'))
                ?? fileExports.find(({id, status}) => equal(status, JOB_STATUSES.finished) && startedFileExportIds.includes(id));

            if (processableFileExport) {
                intervalIdRef.current = setInterval(() => checkStartedFileExport(processableFileExport, startedFileExportIds), INTERVAL_DELAY);
                checkStartedFileExport(processableFileExport, startedFileExportIds);
            }
        })();

        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, []);

    const tableProps = {
        data: exportedFiles,
        columns: getTableColumns(),
        pageSize: TABLE_PAGE_SIZE,
        isFilterable: false,
        isSortable: false,
        onFetchData: onFetchTableData,
        ...table
    };

    return (
        <div className='file-export'>
            {!isPastPlanPeriod && (
                <React.Fragment>
                    <Text className='file-export__title' type={TEXT_TYPES.bodyBold}>Current File</Text>
                    <ContentSection className='current-file'>
                        <Row className='current-file-row' middle='sm'>
                            <Column className='current-file-row__column' sm={3}>
                                <Text className='file-name'>Example File Name</Text>
                            </Column>
                            <Column className='current-file-row__column' sm={3}>
                                <Sticker className='file-type' type={STICKER_TYPES.default}>{FILE_FORMATS.xlsx}</Sticker>
                            </Column>
                            <Column className='current-file-row__column' sm={6}>
                                {!startedFileExport && (
                                    <Button data-testid='request-file' className='file-action' type={BUTTON_TYPES.tertiary} onClick={() => onRequestFileExport(startedFileExportIds)} disabled={!areEmployees}>
                                        Request File
                                    </Button>
                                )}

                                {[JOB_STATUSES.new, JOB_STATUSES.running].some(getEqual(startedFileExport?.status)) && (
                                    <div className='file-progress'>
                                        <LoadingBar progress={startedFileExport.progress}/>
                                        <Text type={TEXT_TYPES.helper}>{startedFileExport.progress_eta}</Text>
                                    </div>
                                )}

                                {equal(JOB_STATUSES.finished, startedFileExport?.status) && (
                                    <React.Fragment>
                                        <Tooltip className='file-action-info'
                                            data-testid='try-again-tooltip'
                                            contentClassName='file-info-content'
                                            content={(
                                                <Text>
                                                    Your download should have started. Please check your downloads folder or&nbsp;
                                                    <button data-testid='try-again' className='file-info-content__button' type='button' onClick={() => onFinishFileExport(startedFileExport, startedFileExportIds)}>
                                                        try again
                                                    </button>.
                                                </Text>
                                            )}>
                                            <Icon className='file-action-info__icon file-action-info__icon_finished' type={ICON_TYPES.info}/>
                                        </Tooltip>
                                        <Text className='file-action-placeholder'>Your download already started</Text>
                                    </React.Fragment>
                                )}

                                {equal(JOB_STATUSES.failed, startedFileExport?.status) && (
                                    <React.Fragment>
                                        <Tooltip className='file-action-info' contentClassName='file-info-content' content={<Text>Request failed</Text>}>
                                            <Icon className='file-action-info__icon file-action-info__icon_failed' type={ICON_TYPES.alert}/>
                                        </Tooltip>
                                        <Button data-testid='restart' className='file-action' type={BUTTON_TYPES.tertiary} onClick={() => onRestartFileExport(startedFileExportIds)}>
                                            Restart
                                        </Button>
                                    </React.Fragment>
                                )}
                            </Column>
                        </Row>
                    </ContentSection>

                    {!areEmployees && <UnavailableEligibilityDataAlert className='file-export__alert'/>}
                </React.Fragment>
            )}

            <Text className='file-export__title' type={TEXT_TYPES.bodyBold}>File Request History</Text>
            <ContentSection className='exported-files'>
                <Table {...tableProps}/>
            </ContentSection>
        </div>
    );
};

export {FileExport as TestableFileExport};
export default React.memo(FileExport);
