import React from 'react';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {getEqual} from '../../../utils';
import './index.scss';

const ArrowStepper = ({activeStep, stepsLength, className, onPrevStep, onNextStep}) => {
    const [isPrevButtonDisabled, isNextButtonDisabled] = [0, stepsLength - 1].map(getEqual(activeStep));

    return (
        <div className={`arrow-stepper ${className}`}>
            <Button disabled={isPrevButtonDisabled} type={BUTTON_TYPES.tertiary} onClick={onPrevStep} className='arrow-stepper__button arrow-stepper__button_prev'>
                <Icon type={ICON_TYPES.arrowLeft} className='arrow-stepper__arrow'/>
            </Button>

            <div className='arrow-stepper__info'>
                <span className='arrow-stepper__active-step'>{activeStep + 1}</span> of {stepsLength}
            </div>

            <Button disabled={isNextButtonDisabled} type={BUTTON_TYPES.tertiary} onClick={onNextStep} className='arrow-stepper__button'>
                <Icon type={ICON_TYPES.arrowRight} className='arrow-stepper__arrow'/>
            </Button>
        </div>
    );
};

ArrowStepper.propTypes = {
    activeStep: PropTypes.number,
    stepsLength: PropTypes.number,
    className: PropTypes.string,
    onPrevStep: PropTypes.func,
    onNextStep: PropTypes.func
};

ArrowStepper.defaultProps = {
    activeStep: 0,
    stepsLength: 1,
    className: ''
};

export {ArrowStepper as TestableArrowStepper};
export default ArrowStepper;
