import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Separator from '@frontend/ui-kit/Components/Separator';
import {requestAncillaryTemplates} from '../../../actions/benefits';
import './index.scss';

const AncillaryTemplatesPopup = ({selectedTemplates, onSave, onClose}) => {
    const dispatch = useDispatch();
    const selectedValue = useMemo(() => selectedTemplates.map(({product_alias: productAlias}) => productAlias), [selectedTemplates]);
    const [templatesValue, setTemplatesValue] = useState(selectedValue);
    const [templatesOptions, setTemplatesOptions] = useState([]);
    const [receivedTemplates, setReceivedTemplates] = useState([]);

    useEffect(() => {
        (async () => {
            const {templates} = await dispatch(requestAncillaryTemplates());
            const templatesOptions = templates.map(({product_alias: productAlias, name}) => ({value: productAlias, label: name}));

            setTemplatesOptions(templatesOptions);
            setReceivedTemplates(templates);
        })();
    }, []);

    const onChange = value => setTemplatesValue(value);

    const onSaveTemplates = () => {
        const filteredTemplates = receivedTemplates.filter(({product_alias: productAlias}) => templatesValue.includes(productAlias));

        onSave(filteredTemplates);
    };

    const actionBar = (
        <React.Fragment>
            <Button type={BUTTON_TYPES.secondary} data-testid='ancillary-templates-popup-cancel-button' onClick={onClose}>Cancel</Button>
            <Button onClick={onSaveTemplates} data-testid='ancillary-templates-popup-save-button'>
                Save Changes
            </Button>
        </React.Fragment>
    );

    return (
        <PopupContent title='Manage Common Ancillary Benefits' actionBar={actionBar}>
            <Autocomplete value={templatesValue}
                options={templatesOptions}
                className='ancillary-templates-popup__autocomplete'
                isMenuPortal
                isMulti
                label='Common Ancillary Benefits'
                onChange={onChange}/>

            <Separator className='ancillary-templates-popup__separator'/>
        </PopupContent>
    );
};

AncillaryTemplatesPopup.propTypes = {
    selectedTemplates: PropTypes.arrayOf(PropTypes.shape(
        PropTypes.shape({
            product_alias: PropTypes.string
        })
    )),
    onSave: PropTypes.func,
    onClose: PropTypes.func
};

export {AncillaryTemplatesPopup as TestableAncillaryTemplatesPopup};
export default AncillaryTemplatesPopup;
