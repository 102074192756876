import React from 'react';
import PropTypes from 'prop-types';
import PdfText from '../PdfText';
import './index.scss';

const TextSection = ({title, children, className, textClassName, ...props}) => (
    <div className={`pdf-text-section ${className}`} {...props}>
        {title && <PdfText className='pdf-text-section__title' type='body-bold'>{title}</PdfText>}
        <PdfText className={textClassName}>{children}</PdfText>
    </div>
);

TextSection.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    textClassName: PropTypes.string
};

TextSection.defaultProps = {
    className: '',
    textClassName: ''
};

export {TextSection as TestableTextSection};
export default TextSection;
