import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import CoreWizard from '../../../Components/Benefits/CoreWizard';

const PAGE_HEADER_CAPTION = 'Provide information on Medical, Dental and Vision plans so the HealthJoy team can start to build the framework for your HealthJoy app.';

const Core = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='Your Core Benefits' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <CoreWizard/>
    </React.Fragment>
);

export {Core as TestableCore};
export default React.memo(Core);
