import apiServices from '../apiServices';
import {RECEIVE_BENEFITS_OVERVIEW, RECEIVE_OPEN_ENROLLMENT_OVERVIEW} from './actionTypes';
import {getActiveCompany} from '../selectors/general';

const receiveBenefitsOverview = benefitsOverview => ({type: RECEIVE_BENEFITS_OVERVIEW, benefitsOverview});
const receiveOpenEnrollmentOverview = openEnrollmentOverview => ({type: RECEIVE_OPEN_ENROLLMENT_OVERVIEW, openEnrollmentOverview});

export const requestBenefitsOverview = () => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);
    const {data} = await apiServices.getBenefitsOverview({companyId});
    const {medical_traits: medicalTraits, ...rest} = data;

    dispatch(receiveBenefitsOverview({medicalTraits, ...rest}));
};

export const requestOpenEnrollmentOverview = () => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);
    const {data: openEnrollmentOverview} = await apiServices.getOpenEnrollment({companyId});

    dispatch(receiveOpenEnrollmentOverview(openEnrollmentOverview));
};

export const requestOpenEnrollmentOverviewUpdating = overview => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setOpenEnrollment({companyId, overview});

    if (isSuccess) {
        dispatch(receiveOpenEnrollmentOverview(data));
    }

    return {openEnrollmentOverview: data, isSuccess, submissionErrors: data.messages};
};
