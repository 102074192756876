import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import classnames from 'classnames';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Sticker from '@frontend/ui-kit/Components/Sticker';
import Select from '@frontend/ui-kit/Components/Select';
import EmptyListPlaceholder from './EmptyListPlaceholder';
import {requestRecommendationsManagerListConfig, requestRecommendationsCards} from '../../../actions/adminPortal';
import {redirectTo} from '../../../actions/general';
import {getEqual, isEmpty, formatDate} from '../../../utils';
import {ROUTES} from '../../../constants';
import './index.scss';

const CARDS_PER_PAGE = 9;
const DEFAULT_PAGINATION = {offset: 0, limit: CARDS_PER_PAGE};

const RecommendationsManagerLayout = () => {
    const dispatch = useDispatch();
    const [{isLast, cards}, setCardsData] = useState({isLast: false, cards: []});
    const [listConfig, setListConfig] = useState({});
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

    const onCreateCard = useCallback(() => dispatch(redirectTo(ROUTES.createRecommendationCard)), [dispatch]);
    const onOpenCard = useCallback(id => dispatch(redirectTo(`${ROUTES.recommendationsManager}/${id}`)), [dispatch]);

    const onChangeFilter = value => {
        if (value !== filters.status) {
            setFilters({status: value});
            setPagination(DEFAULT_PAGINATION);
            setCardsData(prevState => ({...prevState, cards: []}));
        }
    };

    const onShowMore = () => {
        setPagination(pagination => ({offset: pagination.offset + CARDS_PER_PAGE, limit: CARDS_PER_PAGE}));
    };

    useEffect(() => {
        (async () => {
            if (isEmpty(listConfig)) {
                return;
            }

            const {data: {is_last: isLast, cards}} = await dispatch(requestRecommendationsCards({...filters, ...pagination}));

            setCardsData(prevState => ({isLast, cards: [...prevState.cards, ...cards]}));
        })();
    }, [filters, pagination]);

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestRecommendationsManagerListConfig());

            setListConfig(data);
            setFilters({status: data.filters.status.options[0].value});
        })();
    }, []);

    const getCard = ({
        id, title, description, internal_description, card_action_label, status, placement, is_old, card_type_label,
        start_date, expiration_date, updated_at, has_push_notification_channel, has_email_channel
    }) => {
        const {label: stickerLabel, type: stickerType} = listConfig.filters.status.options.find(getEqual(status, 'value'));

        return (
            <ContentSection key={id} className={classnames('recommendation-card', {clickable: !is_old})} onClick={() => !is_old && onOpenCard(id)}>
                <Heading type={HEADING_TYPES['5']} className='mb-4'>
                    {title}
                    {internal_description && <span className='recommendation-card__header-dot'/>}
                    {internal_description}
                </Heading>
                <Sticker type={stickerType} className='mb-6'>{stickerLabel}</Sticker>

                <Row className='mb-6'>
                    <Column xs={6}>
                        <Text className='recommendation-card__label' type={TEXT_TYPES.helper}>Start Date</Text>
                        <Text>{formatDate(start_date, 'M/dd/yyyy')}</Text>
                    </Column>
                    <Column xs={6}>
                        <Text className='recommendation-card__label' type={TEXT_TYPES.helper}>Expiration Date</Text>
                        <Text>{formatDate(expiration_date, 'M/dd/yyyy')}</Text>
                    </Column>
                </Row>
                <Row className='mb-6'>
                    <Column xs={6}>
                        <Text className='recommendation-card__label' type={TEXT_TYPES.helper}>Last Updated</Text>
                        <Text>{formatDate(updated_at, 'M/dd/yyyy')}</Text>
                    </Column>
                    <Column xs={6}>
                        <Text className='recommendation-card__label' type={TEXT_TYPES.helper}>Placement</Text>
                        <Text>{placement}</Text>
                    </Column>
                </Row>
                <Row className='mb-6'>
                    <Column xs={6}>
                        <Text className='recommendation-card__label mb-2' type={TEXT_TYPES.helper}>Communication channel</Text>
                        {has_push_notification_channel && <Icon type={ICON_TYPES.notification} className='recommendation-card__icon mr-8'/>}
                        {has_email_channel && <Icon type={ICON_TYPES.message} className='recommendation-card__icon'/>}
                    </Column>
                </Row>

                <Text className='recommendation-card__label' type={TEXT_TYPES.helper}>Card Type</Text>
                <Text className='mb-6'>{card_type_label}</Text>

                <Text className='recommendation-card__label' type={TEXT_TYPES.helper}>Description</Text>
                <Text className='mb-6 recommendation-card__description'>{description}</Text>

                {card_action_label && (
                    <div className='recommendation-card__action-row'>
                        <Icon type={ICON_TYPES.finish} className='recommendation-card__icon'/>
                        <span className='recommendation-card__action-row__label'>Card Action</span>
                        <span className='recommendation-card__action-row__action'>{card_action_label}</span>
                    </div>
                )}
            </ContentSection>
        );
    };

    return (
        <React.Fragment>
            <Heading className='mb-10' type={HEADING_TYPES['1']}>Recommendations Manager</Heading>

            <Row middle='xs'>
                <Column xs>
                    <Heading type={HEADING_TYPES['3']}>Current Health Recommendations</Heading>
                </Column>
                <Column xs constant>
                    <Button onClick={onCreateCard} data-testid='homescreen_recomendation-create_card'>
                        <Icon type={ICON_TYPES.add}/> Create a New Card
                    </Button>
                </Column>
            </Row>

            <ContentSection className='recommendation-card__filters'>
                <Select options={listConfig?.filters?.status?.options}
                    wrapperClassName='mb-12'
                    label={listConfig?.filters?.status?.label}
                    value={filters.status}
                    onChange={onChangeFilter}/>
            </ContentSection>

            {cards.length
                ? <div className='recommendation-card__container'>{cards.map(getCard)}</div>
                : <EmptyListPlaceholder/>}

            {!isLast && (
                <Button type={BUTTON_TYPES.secondary} onClick={onShowMore} className='recommendation-card__show-more mt-16' data-testid='homescreen_recomendation-show_more'>
                    <Icon type={ICON_TYPES.arrowDown}/> Show More
                </Button>
            )}
        </React.Fragment>
    );
};

export {RecommendationsManagerLayout as TestableRecommendationsManagerLayout};
export default RecommendationsManagerLayout;
