import React from 'react';
import VerificationReport from '../../../Components/AdminPortal/VerificationReport';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'partner_name'},
    {name: 'Verification Report'},
    {skip: true}
];

const TPAVerificationReport = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <VerificationReport/>
    </React.Fragment>
);

export {TPAVerificationReport as TestableTPAVerificationReport};
export default TPAVerificationReport;
