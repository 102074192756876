import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {getHtmlFromDraft} from '../../../shared/WysiwygEditor';
import {FONT_SCALE_OPTIONS} from '../../../../options';
import './index.scss';

const CustomPagePreviewPopup = ({values}) => {
    const [previewFontScale, setPreviewFontScale] = useState(FONT_SCALE_OPTIONS[0].value);

    const popupContent = (
        <div className='custom-page-preview-popup'>
            <div className='custom-page-preview-popup__wrapper' style={{fontSize: `${previewFontScale}em`}}>
                <p className='custom-page-preview-popup__title'>{values?.custom_page_config?.title}</p>

                {values?.custom_page_config?.body && (
                    <div className='custom-page-preview-popup__body' data-testid='custom-page-body' dangerouslySetInnerHTML={{__html: getHtmlFromDraft(values.custom_page_config.body)}}/>
                )}

                {values?.custom_page_config?.cta?.is_enabled && values?.custom_page_config?.cta?.text && (
                    <Button data-testid='custom-page-cta-button' className='mt-10 custom-page-preview-popup__button' type={BUTTON_TYPES.primary}>{values.custom_page_config.cta.text}</Button>
                )}
            </div>

            <RadioGroup value={previewFontScale}
                onChange={({target}) => setPreviewFontScale(Number(target.value))}
                options={FONT_SCALE_OPTIONS}
                label='Check how the Custom Page Preview will look when the font is scaled'
                wrapperClassName='mt-10 mb-15'
                isInline/>
        </div>
    );

    const popupProps = {title: values?.category?.text, children: popupContent};

    return <PopupContent {...popupProps}/>;
};

CustomPagePreviewPopup.propTypes = {
    values: PropTypes.shape({
        category: PropTypes.shape({
            text: PropTypes.string
        }),
        custom_page_config: PropTypes.shape({
            title: PropTypes.string,
            body: PropTypes.string,
            cta: PropTypes.shape({
                is_enabled: PropTypes.bool,
                text: PropTypes.string
            })
        })
    })
};

export {CustomPagePreviewPopup as TestableCustomPagePreviewPopup};
export default CustomPagePreviewPopup;
