import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import DropdownList, {DROPDOWN_LIST_ALIGNING} from '@frontend/ui-kit/Components/DropdownList';
import Link from '@frontend/ui-kit/Components/Link';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {requestImplementationManagersList, requestTimelineImplementationManagerSwitching} from '../../../actions/home';
import {getIsSuperAdmin, getTimeline, getTimelineImplementationManager} from '../../../selectors/general';
import {getImplementationManagersList} from '../../../selectors/home';
import {formatPhone} from '../../../utils';
import './index.scss';

const CampaignImplementationManager = () => {
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const {id, email, phone, full_name: name, calendly_link: calendlyLink} = useSelector(getTimelineImplementationManager) || {};
    const implementationManagersList = useSelector(getImplementationManagersList) || [];
    const {owner} = useSelector(getTimeline) || {};
    const {id: ownerId} = owner || {};
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(requestImplementationManagersList());
    }, [dispatch]);

    const onImplementationManagerChange = useCallback(implementationManagerId => {
        dispatch(requestTimelineImplementationManagerSwitching(implementationManagerId, ownerId));
    }, [dispatch, ownerId]);

    const dropdownListProps = {
        renderDropdownInitiator: () => <Icon type={ICON_TYPES.dots}/>,
        aligning: DROPDOWN_LIST_ALIGNING.right,
        title: 'Select Implementation Manager',
        value: id,
        options: implementationManagersList,
        onChange: onImplementationManagerChange,
        className: 'campaign-im__dropdown-list'
    };

    return (
        <ContentSection className='campaign-im'>
            <Text className='campaign-im__title' type={TEXT_TYPES.bodyBold}>Meet Your Implementation Manager</Text>

            {isSuperAdmin && <DropdownList {...dropdownListProps}/>}

            <div className='campaign-im-profile'>
                <div className='campaign-im-profile__avatar'>
                    <Icon className='campaign-im-profile__icon' type={ICON_TYPES.avatar}/>
                </div>

                <Text className='campaign-im-profile__name' type={TEXT_TYPES.helperBold}>{name}</Text>
                <Text className='campaign-im-profile__email' type={TEXT_TYPES.helper}>{email}</Text>
                <Text className='campaign-im-profile__phone' type={TEXT_TYPES.helper}>{formatPhone(phone)}</Text>
            </div>

            {calendlyLink && <Link href={calendlyLink} target='_blank'>Schedule a call</Link>}
        </ContentSection>
    );
};

export {CampaignImplementationManager as TestableCampaignImplementationManager};
export default React.memo(CampaignImplementationManager);
