import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {requestZendeskSSO} from '../../../actions/authorization';
import {JWT_ZENDESK_ACCESS_URL, ZENDESK_HELP_CENTER_URL} from '../../../constants';

const HelpCenter = () => {
    const dispatch = useDispatch();
    const [SSOToken, setSSOToken] = useState('');
    const formRef = useRef(null);

    useEffect(() => {
        (async () => {
            const {data, isSuccess} = await dispatch(requestZendeskSSO());
            const {external_access_token: token} = data || {};

            if (isSuccess) {
                setSSOToken(token);
            }
        })();
    }, [dispatch]);

    const onClickHelpCenter = () => {
        if (!formRef.current) {
            return;
        }

        formRef.current.submit();
    };

    return (
        <div className='help-center'>
            <Button type={BUTTON_TYPES.tertiary} onClick={onClickHelpCenter} data-testid='help-center-item'>
                Help Center
            </Button>

            <form ref={formRef} method='POST' action={`${JWT_ZENDESK_ACCESS_URL}/access/jwt`}>
                <input type='hidden' name='jwt' value={SSOToken}/>
                <input type='hidden' name='return_to' value={`${ZENDESK_HELP_CENTER_URL}/hc/en-us`}/>
            </form>
        </div>
    );
};

export {HelpCenter as TestableHelpCenter};
export default React.memo(HelpCenter);
