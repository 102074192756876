import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Column from '@frontend/ui-kit/Components/Column';
import Link from '@frontend/ui-kit/Components/Link';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Row from '@frontend/ui-kit/Components/Row';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Table from '@frontend/ui-kit/Components/Table';
import {requestEligibilityImportSession, requestVerificationReport} from '../../../actions/adminPortal';
import {equal, getEqual, isEmpty} from '../../../utils';
import {REDASH_URL} from '../../../constants';
import './index.scss';

const VerificationReportDetailedCorePlans = () => {
    const dispatch = useDispatch();
    const {id: importId} = useParams();
    const [importSession, setImportSession] = useState({});
    const [corePlans, setCorePlans] = useState([]);

    const getPreparedCorePlans = ({core_plans__file: corePlansFile, core_plans__db: corePlansDB}) => {
        return corePlansFile.map(({category, plans}) => {
            const {plans: plansDB = []} = corePlansDB.find(getEqual(category, 'category')) || {};

            return plans.map(({name, count: countFile, variations}) => {
                const tableData = Object.entries(variations).map(([variationName, count]) => ({category: variationName, eligibility_file: count}));
                const {count: countDB} = plansDB.find(getEqual(name, 'name')) || {};

                return ({title: `${category} Plan - ${name}`, tableData, countFile, countDB});
            });
        }).flat();
    };

    useEffect(() => {
        (async () => {
            const [
                {data: importSession},
                {verificationReport}
            ] = await Promise.all([
                dispatch(requestEligibilityImportSession(importId)),
                dispatch(requestVerificationReport({session_id: importId}))
            ]);

            setImportSession(importSession);
            setCorePlans(getPreparedCorePlans(verificationReport));
        })();
    }, [importId, dispatch]);

    const getCorePlan = ({countFile, countDB, title, tableData}) => {
        const alertTitle = equal(countFile, countDB)
            ? <span>Database and eligibility file counts are equal: <span className='success-count'>{countDB}</span></span>
            : <span>Database and eligibility file counts are NOT equal: <span className='error-count'>{countDB}</span>. Review of errors required.</span>;

        const getTableColumns = () => {
            return [
                {Header: 'Category', accessor: 'category'},
                {Header: 'Eligibility File', accessor: 'eligibility_file', align: 'center'}
            ];
        };

        const tableProps = {
            data: tableData,
            isFilterable: false,
            isSortable: false,
            columns: getTableColumns(),
            className: 'mt-20'
        };

        return !isEmpty(tableData) && (
            <React.Fragment key={title}>
                <Heading className='mt-20' type={HEADING_TYPES['3']}>{title}</Heading>
                <Alert type={equal(countFile, countDB) ? ALERT_TYPES.success : ALERT_TYPES.danger} title={alertTitle} className='mt-10'/>
                <Table {...tableProps}/>
            </React.Fragment>
        );
    };

    return (
        <div className='verification-report-detailed-core-plans'>
            <Heading type={HEADING_TYPES['5']} className='mb-5'>{importSession?.additional_data?.company_name || ''}</Heading>
            <Heading type={HEADING_TYPES['1']}>Detailed Core Plans</Heading>

            <ContentSection className='mt-20 pb-10 pt-15'>
                <Row end='xs'>
                    <Column constant>
                        <Link href={`${REDASH_URL}/queries/4626?p_alias=${importSession?.company_alias}&p_config_type=maintenance&p_plan_condition=Current`} target='_blank'>
                            <Icon className='mr-3' type={ICON_TYPES.externalLink}/> Number of Employees Enrolled in each Core Plans
                        </Link>
                    </Column>
                </Row>

                {corePlans.map(getCorePlan)}
            </ContentSection>
        </div>
    );
};

export {VerificationReportDetailedCorePlans as TestableVerificationReportDetailedCorePlans};
export default VerificationReportDetailedCorePlans;
