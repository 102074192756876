import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const PageInfo = ({children}) => <div className='page-info'>{children}</div>;

PageInfo.propTypes = {
    children: PropTypes.node
};

export default React.memo(PageInfo);
