import React from 'react';
import PropTypes from 'prop-types';
import PdfHeading from '../PdfHeading';
import Separator from '../Separator';
import PdfText from '../PdfText';
import Contents from '../Contents';
import './index.scss';

const ContentsPage = ({title, description, contents}) => (
    <div className='contents-page'>
        <div className='contents-info'>
            <PdfHeading type={2}>{title}</PdfHeading>
            <Separator/>

            <PdfText>{description}</PdfText>
        </div>
        <div className='contents-details'>
            <PdfHeading type={2}>Contents</PdfHeading>
            <Separator/>

            <Contents contents={contents}/>
        </div>
    </div>
);

ContentsPage.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contents: PropTypes.arrayOf(PropTypes.shape({
        chapter: PropTypes.string,
        title: PropTypes.string,
        page: PropTypes.number
    })).isRequired
};

export {ContentsPage as TestableContentsPage};
export default ContentsPage;
