import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import CopyableField from '../CopyableField';
import DocumentUploader from '../DocumentUploader';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';
import {trimStart} from '../../../utils';

const POLICY_HOLDER_TOOLTIP_CONTENT = 'If you’re unsure, most of the time this is the Client’s name.';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'policy_holder', label: 'Policyholder'},
    {name: 'group_policy', label: 'Group Policy'},
    {name: 'weekly_benefit', label: 'Weekly Benefit'},
    {name: 'accident_benefits_begin', label: 'Accident Benefits Begin'},
    {name: 'sickness_benefits_begin', label: 'Sickness Benefits Begin'},
    {name: 'max_duration', label: 'Max Duration'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const StdForm = ({isReadonly, card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader isReadonly={isReadonly}/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below. It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo isReadonly={isReadonly}/>

            <CardHeaderInfo isReadonly={isReadonly}/>

            <CopyableField name='policy_holder' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter' label={<LabeledTooltip title='Policyholder' content={POLICY_HOLDER_TOOLTIP_CONTENT}/>} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='group_policy' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter' label='Group Policy' wrapperClassName='mb-12'/>}
            </CopyableField>

            <Text type={TEXT_TYPES.bodyBold} className='ancillary-plan-details-form__title'>Benefit Summary</Text>

            <CopyableField name='weekly_benefit' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter specifics' label='Weekly Benefit' wrapperClassName='mb-12'/>}
            </CopyableField>

            <Row className='ancillary-plan-details-form__row'>
                <Column sm={6}>
                    <CopyableField name='accident_benefits_begin' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='Accident' label='Accident Benefits Begin'/>}
                    </CopyableField>
                </Column>

                <Column sm={6}>
                    <CopyableField name='sickness_benefits_begin' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='Sickness' label='Sickness Benefits Begin'/>}
                    </CopyableField>
                </Column>
            </Row>

            <CopyableField name='max_duration' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Max Duration'/>}
            </CopyableField>
        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

StdForm.propTypes = {
    isReadonly: PropTypes.bool,
    card: PropTypes.string
};

export {StdForm as TestableStdForm};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(StdForm);
