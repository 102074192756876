import React, {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Input from '@frontend/ui-kit/Components/Input';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import GlobalErrorMessage from '@frontend/ui-kit/Components/GlobalErrorMessage';
import {Form, Field} from '../../shared/FormComponents';
import {redirectTo} from '../../../actions/general';
import {verifyCode, setAuthorizationData, requestUserAuthData} from '../../../actions/authorization';
import {getTwoStepCodeChannel} from '../../../selectors/authorization';
import {equal, validateRequired} from '../../../utils';
import {FORM_GLOBAL_ERROR, FORMS, POLICY_URLS, ROUTES, TWO_STEP_CODE_CHANNELS} from '../../../constants';

const TIP_BY_CODE_CHANNEL = {
    [TWO_STEP_CODE_CHANNELS.call]: 'To finish signing in, enter the code we provided to you in a phone call.',
    [TWO_STEP_CODE_CHANNELS.sms]: 'To finish signing in, enter the code we sent to you in a text message.',
    [TWO_STEP_CODE_CHANNELS.email]: 'To finish signing in, enter the code we sent to your email.'
};

/* istanbul ignore next */
const validate = values => ({
    two_step_code: validateRequired(values.two_step_code)
});

const VerificationForm = () => {
    const dispatch = useDispatch();
    const [authData, setAuthData] = useState(null);
    const [initialValues, setInitialValues] = useState({two_step_code: ''});
    const twoStepCodeChannel = useSelector(getTwoStepCodeChannel);
    const isEmailCodeChannel = equal(twoStepCodeChannel, TWO_STEP_CODE_CHANNELS.email);
    const tip = TIP_BY_CODE_CHANNEL[twoStepCodeChannel];

    const onSubmit = useCallback(async values => {
        const {isSuccess, authData, submissionGlobalError} = await dispatch(verifyCode(values.two_step_code));

        if (!isSuccess) {
            if (authData.is_temporary_banned) {
                dispatch(redirectTo(ROUTES.locked));

                return;
            }

            return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
        }

        setInitialValues(values);
        setAuthData(authData);
    }, [dispatch]);

    const onSubmitSuccess = useCallback(async () => {
        const {access_token: accessToken, refresh_token: refreshToken, two_step_is_required: isTwoStepRequired} = authData;

        dispatch(setAuthorizationData({accessToken, refreshToken, isTwoStepRequired}));
        const {isSuccess: isUserAuthDataSuccess} = await dispatch(requestUserAuthData());

        if (isUserAuthDataSuccess) {
            dispatch(redirectTo(isEmailCodeChannel ? ROUTES.baseContactInfo : ROUTES.root));
        }
    }, [dispatch, authData, isEmailCodeChannel]);

    return (
        <Form name={FORMS.verification} initialValues={initialValues} validate={validate} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit, submitError}) => (
                <form className='authorization-form' onSubmit={handleSubmit} noValidate>
                    <div className='authorization-form-header'>
                        <Heading className='authorization-form-header__title' type={HEADING_TYPES['1']}>Welcome Back</Heading>

                        <Text className='authorization-form-header__subtitle'>Your employer requires a second factor of authentication.</Text>
                    </div>

                    <div className='authorization-form-body'>
                        <Field name='two_step_code'>
                            {props => <Input {...props} placeholder='Enter code...' label='One-Time Password' wrapperClassName='mb-5'/>}
                        </Field>
                        <Text className='authorization-form-body__tip'>{tip}</Text>
                        <Link href={ROUTES.resendCode}>Didn’t Get a Code?</Link>

                        {submitError && <GlobalErrorMessage className='authorization-form-body__error-message'>{submitError}</GlobalErrorMessage>}

                        <div className='authorization-form-action-bar'>
                            <Button className='authorization-form-action-bar__button' type={BUTTON_TYPES.primary} isSubmit>Verify</Button>
                        </div>
                        <Text type={TEXT_TYPES.helper}>
                            By selecting Verify, you accept our&nbsp;
                            <Link href={POLICY_URLS.termsAndConditions} target='_blank'>Client Terms and Conditions</Link>,&nbsp;
                            <Link href={POLICY_URLS.termsOfUse} target='_blank'>Terms of Use</Link>, and&nbsp;
                            <Link href={POLICY_URLS.privacyPolicy} target='_blank'>Privacy Policy</Link>.
                        </Text>
                    </div>
                </form>
            )}
        </Form>
    );
};

export {VerificationForm as TestableVerificationForm};
export default VerificationForm;
