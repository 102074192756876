import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import TerminationsMembers from '../../../Components/AdminPortal/PendingTerminationsMembers';
import {requestEligibilityImportSessions, requestEligibilityImportSessionStats} from '../../../actions/adminPortal';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'group_name'},
    {name: 'Pending Terminations'}
];

const PendingTerminationsMembers = () => {
    const dispatch = useDispatch();
    const {group_alias: groupAlias} = useParams();
    const [importData, setImportData] = useState({});

    useEffect(() => {
        (async () => {
            const {sessions = []} = await dispatch(requestEligibilityImportSessions({limit: 1, offset: 0, company_alias: groupAlias}));
            const {data: importData, isSuccess} = await dispatch(requestEligibilityImportSessionStats(sessions[0]?.id));

            if (!isSuccess) {
                return false;
            }

            setImportData(importData);
        })();
    }, [dispatch, groupAlias]);

    return (
        <React.Fragment>
            <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
            <TerminationsMembers groupAlias={groupAlias} importData={importData}/>
        </React.Fragment>
    );
};

export {PendingTerminationsMembers as TestablePendingTerminationsMembers};
export default React.memo(PendingTerminationsMembers);
