import {
    RECEIVE_ELIGIBILITY_FILES_INFO_LIST,
    RECEIVE_ELIGIBILITY_PLANS,
    RECEIVE_ELIGIBILITY_BEN_ADMIN_LIST,
    RECEIVE_EXPORTED_FILES,
    SET_STARTED_FILE_EXPORT_IDS,
    DOWNLOAD_ELIGIBILITY_EXPORT_FILE
} from './actionTypes';
import {getActiveCompany} from '../selectors/general';
import apiServices from '../apiServices';
import {HTTP_RESPONSE_TYPES, LOCAL_STORAGE_ITEMS} from '../constants';
import {saveFile} from '../utils';

const receivePlans = plans => ({type: RECEIVE_ELIGIBILITY_PLANS, plans});
const receiveFilesInfoList = filesInfoList => ({type: RECEIVE_ELIGIBILITY_FILES_INFO_LIST, filesInfoList});
const receiveBenAdminList = benAdminList => ({type: RECEIVE_ELIGIBILITY_BEN_ADMIN_LIST, benAdminList});
const receiveExportedFiles = exportedFiles => ({type: RECEIVE_EXPORTED_FILES, exportedFiles});
const downloadEligibilityExportFile = fileData => ({type: DOWNLOAD_ELIGIBILITY_EXPORT_FILE, fileData});

export const requestInfo = planPeriodId => async (dispatch, getState) => {
    const DEFAULT_UNIQUE_KEY = 'ssn';
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getEligibilityInfo({companyId, planPeriodId});
    const info = {...data, unique_member_key: data.unique_member_key || DEFAULT_UNIQUE_KEY};

    return {info, isSuccess};
};

export const requestInfoUpdating = info => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setEligibilityInfo({companyId, info});

    return {info: data, isSuccess, submissionErrors: data.messages};
};

export const requestCorePlansInfo = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data: corePlansInfo, isSuccess} = await apiServices.getCorePlansEligibilityInfo({companyId, planPeriodId});

    if (isSuccess) {
        dispatch(receivePlans(corePlansInfo.plans));
    }

    return {corePlansInfo, isSuccess};
};

export const requestCorePlansInfoUpdating = corePlansInfo => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setCorePlansEligibilityInfo({companyId, corePlansInfo});

    return {corePlansInfo: data, isSuccess, submissionErrors: data.messages};
};

export const requestAncillaryPlansInfo = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getAncillaryPlansEligibilityInfo({companyId, planPeriodId});

    return {ancillaryPlansInfo: data, isSuccess};
};

export const requestAncillaryPlansInfoUpdating = ancillaryPlansInfo => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setAncillaryPlansEligibilityInfo({companyId, ancillaryPlansInfo});

    return {ancillaryPlansInfo: data, isSuccess, submissionErrors: data.messages};
};

export const requestFilesInfoList = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getEligibilityFilesInfoList({companyId, planPeriodId});
    const filesInfoList = isSuccess ? data : [];

    dispatch(receiveFilesInfoList(filesInfoList));

    return {filesInfoList, isSuccess};
};

export const requestFilesInfoDetails = (planPeriodId, category) => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getEligibilityFilesInfoDetails({companyId, planPeriodId, category});

    return {filesInfoDetails: data, isSuccess};
};

export const requestFilesInfoDetailsUpdating = (category, details) => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setEligibilityFilesInfoDetails({companyId, category, details});

    return {filesInfoDetails: data, isSuccess, submissionErrors: data.messages};
};

export const requestBenAdminList = query => async dispatch => {
    const {data, isSuccess} = await apiServices.getBenAdminList({query});
    const benAdminList = isSuccess ? data.data : [];

    dispatch(receiveBenAdminList(benAdminList));

    return {benAdminList, isSuccess};
};

export const requestBenAdminCreating = name => async () => {
    const {isSuccess} = await apiServices.setBenAdmin({name});

    return {isSuccess};
};

export const requestExportedFiles = params => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getExportedEligibilityFiles({companyAlias, ...params});
    const {data: exportedFiles = [], count = 0} = isSuccess ? data : {};

    dispatch(receiveExportedFiles(exportedFiles));

    return {exportedFiles, count, isSuccess};
};

export const setStartedFileExportIds = startedFileExportIds => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.startedEligibilityFileExportIds, JSON.stringify(startedFileExportIds));

    return {type: SET_STARTED_FILE_EXPORT_IDS, startedFileExportIds};
};

export const requestExportFileSaving = fileData => async dispatch => {
    const {url, name} = fileData;
    const {data: exportFile, isSuccess} = await apiServices.getFromThirdParty(url, null, {responseType: HTTP_RESPONSE_TYPES.blob});

    if (isSuccess) {
        dispatch(downloadEligibilityExportFile(fileData));
        saveFile(exportFile, name);
    }

    return {exportFile, isSuccess};
};
