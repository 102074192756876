import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import Text from '@frontend/ui-kit/Components/Text';
import Select from '@frontend/ui-kit/Components/Select';
import {Form, Field} from '../../../shared/FormComponents';
import {equal, getEqual, validateRequired, validateHost} from '../../../../utils';
import {SFTP_HOST_OPTIONS} from '../../../../options';
import {FORMS, SFTP_HOST_TYPES} from '../../../../constants';

/* istanbul ignore next */
const validate = values => {
    return {
        host_type: validateRequired(values.host_type),
        host: equal(values.host_type, SFTP_HOST_TYPES.external)
            ? validateRequired(values.host) || validateHost(values.host)
            : undefined
    };
};

const SFTPHostPopup = ({onClose, onSave}) => {
    const onSubmit = useCallback(values => {
        onSave(values);
    }, [onSave]);

    const onChangeHostType = (value, form) => {
        const host = equal(value, SFTP_HOST_TYPES.external) ? null : SFTP_HOST_OPTIONS.find(getEqual(value, 'value'))?.label;

        form.change('host', host);
    };

    return (
        <Form name={FORMS.SFTPHost} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, values}) => {
                const actionBar = (
                    <React.Fragment>
                        <Button data-testid='cancel-button' onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
                        <Button data-testid='continue-button' onClick={handleSubmit}>Continue</Button>
                    </React.Fragment>
                );
                const popupProps = {title: 'Select SFTP Host', actionBar};

                return (
                    <PopupContent {...popupProps}>
                        <form onSubmit={handleSubmit} noValidate>
                            <Text className='mb-12'>
                                Please note the SFTP host cannot be changed after making the selection.
                                You will need to delete and add a new one.
                            </Text>

                            <Field name='host_type' onChange={onChangeHostType}>
                                {props => <Select {...props} options={SFTP_HOST_OPTIONS} label='Host' isMenuPortal/>}
                            </Field>

                            {equal(values.host_type, SFTP_HOST_TYPES.external) && (
                                <Field name='host'>
                                    {props => <Input {...props} placeholder='Type sftp host here...' maxLength={128} wrapperClassName='mt-12' data-testid='host-input'/>}
                                </Field>
                            )}
                        </form>
                    </PopupContent>
                );
            }}
        </Form>
    );
};

SFTPHostPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export {SFTPHostPopup as TestableSFTPHostPopup};
export default React.memo(SFTPHostPopup);
