import React from 'react';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Link from '@frontend/ui-kit/Components/Link';
import Text from '@frontend/ui-kit/Components/Text';
import {ROUTES, CSS_TEAM_EMAIL} from '../../../constants';

const Locked = () => (
    <div className='authorization-form'>
        <div className='authorization-form-header'>
            <Heading className='authorization-form-header__title' type={HEADING_TYPES['2']}>Account Locked</Heading>
            <Text className='authorization-form-header__subtitle'>
                Due to multiple invalid login attempts, your account has been locked.
                Please wait 30 minutes before trying to access your account.
            </Text>
        </div>

        <div className='authorization-form-body'>
            <div className='authorization-form-action-bar'>
                <div className='authorization-form-action-bar__link-wrapper'>
                    Back to <Link href={ROUTES.login}>Login</Link>
                </div>

                <div className='authorization-form-action-bar__link-wrapper'>
                    Contact Support: <Link href={`mailto:${CSS_TEAM_EMAIL}`}>{CSS_TEAM_EMAIL}</Link>
                </div>
            </div>
        </div>
    </div>
);

export {Locked as TestableLocked};
export default React.memo(Locked);
