import React from 'react';
import PropTypes from 'prop-types';
import VersionHistoryItem from '../VersionHistoryItem';
import {equal} from '../../../utils';

const getVersionHistoryItem = (versionInfo, selectedVersion, onChange) => {
    const {id} = versionInfo;
    return (
        <VersionHistoryItem key={id}
            versionInfo={versionInfo}
            isVersionHistoryItemSelected={equal(selectedVersion, id)}
            onVersionItemClick={() => onChange(id)}/>
    );
};

const VersionHistoryList = ({versionHistoryList, selectedVersion, onChange}) => (
    <ul>{versionHistoryList.map(versionInfo => getVersionHistoryItem(versionInfo, selectedVersion, onChange))}</ul>
);

VersionHistoryList.propTypes = {
    versionHistoryList: PropTypes.arrayOf(PropTypes.shape({
        version: PropTypes.number,
        created_at: PropTypes.string,
        created_by: PropTypes.number
    })),
    selectedVersion: PropTypes.number,
    onChange: PropTypes.func
};

export {VersionHistoryList as TestableVersionHistoryList};
export default React.memo(VersionHistoryList);
