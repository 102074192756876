import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import {Form, Field, FieldArray, FormSpy} from '../../../shared/FormComponents';
import SFTPAffectableClients from '../SFTPAffectableClients';
import {isEmpty, isEmptyNested, validateSFTPWhiteListItem} from '../../../../utils';
import {FORMS} from '../../../../constants';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    return {
        ip_whitelist: values.ip_whitelist?.map(validateSFTPWhiteListItem)
    };
};

const FORM_SPY_SUBSCRIPTION = {values: true};

const SFTPWhitelistPopup = ({initialValues, affectableClients, onClose, onSave}) => {
    const [isEditable, setIsEditable] = useState(false);
    let form;

    const onEdit = () => setIsEditable(true);
    const onSubmit = useCallback(values => {
        onSave(values);
    }, [onSave]);

    const onChangeFormSpy = useCallback(({values}) => {
        const {is_ben_admin, ip_whitelist} = values;

        if (is_ben_admin && isEmptyNested(ip_whitelist)) {
            form.change('is_ben_admin', false);
        }
    }, [form]);

    return (
        <Form name={FORMS.SFTPWhitelist} validate={validate} initialValues={initialValues} onSubmit={onSubmit}>
            {({handleSubmit, values, form: formInstance}) => {
                form = formInstance;

                const actionBar = (
                    <React.Fragment>
                        <Button data-testid='cancel-button' onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
                        {!isEditable && <Button data-testid='edit-button' onClick={onEdit}>Yes, Continue</Button>}
                        {isEditable && <Button data-testid='save-button' onClick={handleSubmit}>Save</Button>}
                    </React.Fragment>
                );
                const popupProps = {isScrollableContent: true, title: 'Edit Whitelist', actionBar};

                return (
                    <PopupContent {...popupProps}>
                        <form onSubmit={handleSubmit} noValidate className='sftp-whitelist-popup'>
                            <Text className='mb-12'>
                                Are you sure you want to edit this whitelist?
                                {!isEmpty(affectableClients) && `The following ${affectableClients.length} clients credentials are linked and will be affected:`}
                            </Text>

                            {!isEmpty(affectableClients) && <SFTPAffectableClients clients={affectableClients}/>}

                            {isEditable && (
                                <React.Fragment>
                                    <FormSpy subscription={FORM_SPY_SUBSCRIPTION} onChange={onChangeFormSpy}/>

                                    <FieldArray name='ip_whitelist'>{({fields = []}) => (
                                        <Row className='mb-16 mt-16' rowGap='md'>
                                            {fields.map((field, index) => (
                                                <Column key={field} sm={4}>
                                                    <Field name={field}>
                                                        {props => <Input {...props} placeholder='' icon={ICON_TYPES.delete} onIconClick={() => fields.remove(index)}/>}
                                                    </Field>
                                                </Column>
                                            ))}

                                            <Column sm={4}>
                                                <Button className='add-button' type={BUTTON_TYPES.tertiary} onClick={() => fields.push()} data-testid='add-button'>
                                                    <Icon type={ICON_TYPES.circlePlus}/> Add SFTP Whitelist
                                                </Button>
                                            </Column>
                                        </Row>
                                    )}
                                    </FieldArray>

                                    <Field name='is_ben_admin'>
                                        {props => <Checkbox {...props} caption='Allow Ben Admin Access to SFTP folder' disabled={isEmptyNested(values.ip_whitelist)}/>}
                                    </Field>
                                </React.Fragment>
                            )}
                        </form>
                    </PopupContent>
                );
            }}
        </Form>
    );
};

SFTPWhitelistPopup.propTypes = {
    initialValues: PropTypes.shape({
        ip_whitelist: PropTypes.arrayOf(PropTypes.string),
        is_ben_admin: PropTypes.bool
    }),
    affectableClients: PropTypes.arrayOf(PropTypes.string),
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export {SFTPWhitelistPopup as TestableSFTPWhitelistPopup};
export default React.memo(SFTPWhitelistPopup);
