import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Alert from '@frontend/ui-kit/Components/Alert';
import PageHeader from '../../../Components/shared/PageHeader';
import PageInfo from '../../../Components/shared/PageInfo';
import BroadcastWizard from '../../../Components/Broadcaster/BroadcastWizard';
import {requestFeatureFlag} from '../../../actions/general';
import {getIsBroadcasterApprovalProcess} from '../../../selectors/general';
import {getBroadcastTemplate, getBroadcast} from '../../../selectors/broadcaster';
import {getWizardActiveStep} from '../../../selectors/shared';
import {BROADCAST_TEMPLATE_TITLES, FEATURE_FLAGS} from '../../../constants';

const OPEN_ENROLLMENT_DISCLAIMER = 'Remember to add your company\'s specific OE information and add appropriate links or PDFs at the end of the Broadcast.';
const BROACAST_ALERT_TEXT = 'Please note: Only members who have updated their app since July 15, 2024 will receive Broadcasts.';
const HEADER_TITLE_V1 = 'Segment and Schedule';
const HEADER_TITLE_V2 = 'Create Broadcast';
const CAPTION_V1 = 'Select a date, time, and segment for your broadcast';
const CAPTION_V2 = 'Select a date and time for your broadcast';

const getHeaderTitleAndCaption = isNewBroadcasterUI => ({
    title: isNewBroadcasterUI ? HEADER_TITLE_V2 : HEADER_TITLE_V1,
    caption: isNewBroadcasterUI ? CAPTION_V2 : CAPTION_V1
});

const getHeaderInfo = (activeStep, broadcastId, isNewBroadcasterUI) => {
    const headerTitleAndCaption = getHeaderTitleAndCaption(isNewBroadcasterUI);

    return [
        {
            title: `${broadcastId ? 'Edit' : 'Create'} Broadcast`,
            caption: `
                Your broadcast contains a push notification and a message displayed in a members 
                app inbox. Add links, wallet cards, PDFs, and online resources to inform members.
            `
        },
        headerTitleAndCaption
    ][activeStep];
};

const BroadcastEditor = () => {
    const dispatch = useDispatch();
    const isBroadcasterApprovalProcess = useSelector(getIsBroadcasterApprovalProcess);
    const broadcast = useSelector(getBroadcast);
    const broadcastTemplate = useSelector(getBroadcastTemplate);
    const wizardActiveStep = useSelector(getWizardActiveStep);
    const {id: broadcastId} = useParams();
    const [isNewBroadcasterUI, setIsNewBroadcasterUI] = useState(false);

    const {title, caption} = getHeaderInfo(wizardActiveStep, broadcastId, isNewBroadcasterUI);
    const broadcastName = broadcastId ? broadcast?.name : broadcastTemplate?.name;
    const isOpenEnrollmentBroadcast = [
        BROADCAST_TEMPLATE_TITLES.openEnrollment,
        BROADCAST_TEMPLATE_TITLES.openEnrollmentMeeting,
        BROADCAST_TEMPLATE_TITLES.openEnrollmentPlan
    ].includes(broadcastName);

    const getMainDisclaimer = () => {
        if (!isBroadcasterApprovalProcess) {
            return 'Remember to add your company’s specific Open Enrollment information and add appropriate links or PDFs at the end of the message';
        }
        if (!broadcastId && broadcastTemplate) {
            // Do not show that disclaimer to OE templates
            return (
                !isOpenEnrollmentBroadcast
                    ? 'If you edit this template, HealthJoy will need to approve the content before it’s sent'
                    : null
            );
        }
        return 'You are creating a custom broadcast, which will require HealthJoy approval before it can be sent';
    };
    const mainDisclaimer = getMainDisclaimer();
    const additionalDisclaimer = isOpenEnrollmentBroadcast ? OPEN_ENROLLMENT_DISCLAIMER : null;

    useEffect(() => {
        (async () => {
            const {flag: isNewBroadcast} = await dispatch(requestFeatureFlag(FEATURE_FLAGS.NewBroadcasterUI));

            setIsNewBroadcasterUI(isNewBroadcast);
        })();
    }, [dispatch]);

    return (
        <React.Fragment>
            <Row>
                <Column sm={8}>
                    <PageHeader title={title} caption={caption}/>
                </Column>

                <Column sm={12}>
                    {isNewBroadcasterUI && <Alert className='mb-12' description={BROACAST_ALERT_TEXT}/>}

                    {!isNewBroadcasterUI && (
                        <PageInfo>
                            {mainDisclaimer && <Alert description={mainDisclaimer}/>}
                            {additionalDisclaimer && <Alert description={additionalDisclaimer}/>}
                        </PageInfo>
                    )}
                </Column>
            </Row>

            <BroadcastWizard isNewBroadcasterUI={isNewBroadcasterUI}/>
        </React.Fragment>
    );
};

export {BroadcastEditor as TestableBroadcastEditor};
export default React.memo(BroadcastEditor);
