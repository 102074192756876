import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import CorePlansDetailsLayout from '../../../Components/Benefits/CorePlansDetailsLayout';

const PAGE_HEADER_CAPTION = `Share the details and relevant documents for Medical, Dental and Vision plans.
                 You can select a plan to fill out from the list on the left.
                 Hit “Save and Mark as Done” for each plan once you’ve entered and reviewed all of the information.
                 At any point you can go back to the Overview to add or change plans.`;

const CoreDetails = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='Your Core Benefits' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <CorePlansDetailsLayout/>
    </React.Fragment>
);

export {CoreDetails as TestableCoreDetails};
export default React.memo(CoreDetails);
