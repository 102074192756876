import {RECEIVE_CONTACTS} from './actionTypes';
import {getActiveCompany} from '../selectors/general';
import apiServices from '../apiServices';

const receiveContacts = contactList => ({type: RECEIVE_CONTACTS, contactList});

export const requestContactList = () => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {isSuccess, data} = await apiServices.getContactList({companyId});
    const contactList = isSuccess ? data : [];

    dispatch(receiveContacts(contactList));
};

export const requestContactInfo = userId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {isSuccess, data} = await apiServices.getContact({companyId, user_id: userId});

    return {isSuccess, data};
};

export const requestContactAdding = contact => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {isSuccess, data} = await apiServices.addContact({companyId, contact});

    return {isSuccess, data, submissionErrors: data.messages};
};

export const requestContactUpdating = contact => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {isSuccess, data} = await apiServices.updateContact({companyId, contact});

    return {isSuccess, data, submissionErrors: data.messages};
};

export const requestContactDeletion = userId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {isSuccess} = await apiServices.deleteContact({companyId, user_id: userId});

    return {isSuccess};
};

export const requestContactInvitation = userId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {isSuccess} = await apiServices.inviteContact({companyId, user_id: userId});

    return {isSuccess};
};

export const requestUserValidation = query => async () => {
    const {data: {is_valid: isValid}, isSuccess} = await apiServices.validateUser({query});

    return {isValid, isSuccess};
};
