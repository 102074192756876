import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Text from '@frontend/ui-kit/Components/Text';

const CustomizablePopup = ({content, title, actionBar}) => {
    const props = {title, actionBar};

    return (
        <PopupContent {...props}>
            <Text>{content}</Text>
        </PopupContent>
    );
};

CustomizablePopup.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    actionBar: PropTypes.element
};

export {CustomizablePopup as TestableCustomizablePopup};
export default React.memo(CustomizablePopup);
