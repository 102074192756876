import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setWorkspace} from '../../actions/general';

const withWorkspaceControl = workspace => Component => {
    return props => {
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(setWorkspace(workspace));
        }, [dispatch]);

        return <Component {...props}/>;
    };
};

export {withWorkspaceControl as testableWithWorkspaceControl};
export default ({workspace}) => withWorkspaceControl(workspace);
