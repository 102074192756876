import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import './index.scss';

const BroadcastPreview = props => {
    const {imageSrc, title, description, onClick, broadcastId} = props;

    return (
        <div className={classnames('broadcast-preview', {'broadcast-preview_custom': !imageSrc})} onClick={onClick} data-testid={broadcastId}>
            <div className='preview-image-wrapper'>
                {imageSrc ? <img className='preview-image-wrapper__image' src={imageSrc} alt=''/> : <Icon className='preview-image-wrapper__icon' type={ICON_TYPES.add}/>}
            </div>

            <div className='preview-caption'>
                <Text className='preview-caption__title' type={TEXT_TYPES.bodyBold}>{title}</Text>

                <Text type={TEXT_TYPES.helper}>{description}</Text>
            </div>
        </div>
    );
};

BroadcastPreview.propTypes = {
    broadcastId: PropTypes.string,
    imageSrc: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export {BroadcastPreview as TestableBroadcastPreview};
export default BroadcastPreview;
