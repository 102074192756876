import React from 'react';
import IndividualImportConfig from '../../../Components/AdminPortal/IndividualImportConfig';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'group_name'},
    {name: 'Individual Configuration'}
];

const IndividualGroupConfiguration = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <IndividualImportConfig/>
    </React.Fragment>
);

export {IndividualGroupConfiguration as TestableIndividualGroupConfiguration};
export default React.memo(IndividualGroupConfiguration);
