import {
    RECEIVE_ANCILLARY_PLANS,
    CLEAR_ANCILLARY_PLANS,
    RECEIVE_CORE_PLANS,
    CLEAR_CORE_PLANS,
    RECEIVE_PHARMACY_BENEFIT_MANAGERS,
    RECEIVE_WEBVIEW_PARAMS,
    RECEIVE_WALLET_CARDS,
    SET_IS_COPYING_ENABLED
} from './actionTypes';
import {getActiveCompany} from '../selectors/general';
import {getCorePlans, getAncillaryPlans} from '../selectors/benefits';
import {ANCILLARY_PLAN_TYPES, ANCILLARY_PLANS_DATA_BY_CATEGORY, CORE_PLAN_TYPES} from '../constants';
import {equal, getEqual, getItemKeyValue, isEmpty, negateFunc} from '../utils';
import apiServices from '../apiServices';

const receiveCorePlans = corePlans => ({type: RECEIVE_CORE_PLANS, corePlans});
const receiveAncillaryPlans = ancillaryPlans => ({type: RECEIVE_ANCILLARY_PLANS, ancillaryPlans});
const receivePharmacyBenefitManagers = pharmacyBenefitManagers => ({type: RECEIVE_PHARMACY_BENEFIT_MANAGERS, pharmacyBenefitManagers});
const receiveWebviewParams = webviewParams => ({type: RECEIVE_WEBVIEW_PARAMS, webviewParams});
const receiveWalletCards = walletCards => ({type: RECEIVE_WALLET_CARDS, walletCards});
export const setIsCopyingEnabled = isCopyingEnabled => ({type: SET_IS_COPYING_ENABLED, isCopyingEnabled});

const getNormalizedCorePlans = planList => {
    const {additional_plan_info: additionalPlanInfo = {}, plans = []} = planList;
    const additionalInfo = {...additionalPlanInfo, name: 'Plan information', plan_kind: CORE_PLAN_TYPES.additional, active: true};
    const normalizedPlans = [...plans, additionalInfo];

    return isEmpty(additionalPlanInfo.items) ? plans : normalizedPlans;
};

const getEnhancedPlanDetails = planDetails => {
    const {
        financial_account_type: finAccountType,
        secondary_network_name: additionalNetworkName,
        secondary_network_url: additionalNetworkUrl,
        secondary_network_url_alias: additionalNetworkUrlAlias,
        secondary_network_support_phone: additionalNetworkSupportPhone
    } = planDetails ?? {};

    const isFinAccount = !!finAccountType;
    // FYI: need to show block if at least one field is filled (13.08.2020, Oleh);
    const isAdditionalNetwork = !!(additionalNetworkName || additionalNetworkUrl || additionalNetworkUrlAlias || additionalNetworkSupportPhone);

    return {...planDetails, is_financial_account: isFinAccount, is_additional_network: isAdditionalNetwork};
};

// TODO: Actual plans data with customized value consists in-store and we can't update it after form saving with customized equal true without additional request. So we decided to update plans manually with this func. Remove it after giving up customized mode (Pasha, 29.01.2021)
const updatePlanById = updatedPlan => plan => equal(plan.id, updatedPlan.id) ? updatedPlan : plan;

const requestAncillaryPlanDetails = async (companyAlias, planId, planCategory) => {
    const {data, isSuccess} = await apiServices.getAncillaryPlanDetails({companyAlias, planId});
    const {type} = ANCILLARY_PLANS_DATA_BY_CATEGORY[planCategory] || {};
    const isDisabledEmployerContribution = [ANCILLARY_PLAN_TYPES.fsa, ANCILLARY_PLAN_TYPES.k401].some(getEqual(type)); // FYI: related to DEV-6277 (Pasha, 28.04.2022)
    const {display_card_settings: displayCardSettings} = data;
    const enhancedDisplayCardSettings = {
        show_additional_contribution: true,
        show_dependent_care: true,
        show_employer_contribution: !isDisabledEmployerContribution,
        show_healthcare: true,
        show_irs_contribution_limit: true,
        show_limited_purpose: true,
        ...displayCardSettings
    };
    const enhancedData = isSuccess ? {...data, display_card_settings: enhancedDisplayCardSettings} : {};

    return {isSuccess, data: enhancedData};
};

export const requestCustomizedAncillaryPlanDetails = async (companyAlias, planId) => {
    const {data, isSuccess} = await apiServices.getCustomizedAncillaryPlanDetails({companyAlias, planId});

    return {isSuccess, data: isSuccess ? data : {}};
};

export const requestCorePlansData = (planPeriodId, planType) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getCorePlansByType({companyAlias, planPeriodId, planType});
    const isPlan = isSuccess && !isEmpty(data);
    const plans = isPlan ? data : [];

    return {isSuccess, isPlan, plans};
};

export const requestCorePlansSetting = (planPeriodId, planType, plans, copiedPlansDetailsMap) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const copiedPlans = plans.map((plan, index) => ({...plan, index})).filter(getItemKeyValue('isCopiedPlan'));

    const {data, isSuccess} = await apiServices.setCorePlansByType({companyAlias, planPeriodId, planType, plans: plans.map(({isCopiedPlan, cid, ...plan}) => plan)});

    if (!isSuccess) {
        return {isSuccess, submissionErrors: data.messages};
    }

    // FYI: After copying overview we need to copy details too, needs to be refactored (Vlad, 06.10.2021)
    copiedPlans.forEach(({index, cid, ...plan}) => {
        const {id: planId} = data[index]; // HACK: The only way to get the id of a newly created plan is by the index for now. (Vlad, 06.10.2021)

        apiServices.setPlanDetails({companyAlias, planType, planId, planDetails: {...copiedPlansDetailsMap[cid], ...plan}});
    });

    return {data, isSuccess};
};

export const requestMedicalTraits = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getMedicalTraits({companyAlias, planPeriodId});
    const {reference_based_pricing_vendor: vendor, claims_analytics_platform: platform} = data;

    const medicalTraits = {...data, is_vendor: !!vendor, is_platform: !!platform};

    return {isSuccess, medicalTraits};
};

export const requestMedicalTraitsSetting = (planPeriodId, medicalTraits) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setMedicalTraits({companyAlias, planPeriodId, medicalTraits});

    return {medicalTraits: data, isSuccess, submissionErrors: data.messages};
};

export const requestCorePlans = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getCorePlansInfo({companyAlias, planPeriodId});
    const corePlans = isSuccess ? data : [];

    const normalizedCorePlans = getNormalizedCorePlans(corePlans);

    dispatch(receiveCorePlans(normalizedCorePlans));

    return {corePlans: normalizedCorePlans, isSuccess};
};

export const clearCorePlans = () => ({type: CLEAR_CORE_PLANS});

export const requestCorePlanDetails = (planType, planId) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getPlanDetails({companyAlias, planType, planId});
    const corePlanDetails = isSuccess ? data : {};

    dispatch(setIsCopyingEnabled(false));

    return {corePlanDetails: getEnhancedPlanDetails(corePlanDetails), isSuccess};
};

export const requestCorePlanDetailsSetting = (planType, planId, planDetails) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setPlanDetails({companyAlias, planType, planId, planDetails});

    const corePlans = getCorePlans(state);
    const updatedCorePlans = corePlans.map(updatePlanById(data));

    dispatch(setIsCopyingEnabled(false));
    dispatch(receiveCorePlans(updatedCorePlans));

    return {corePlanDetails: data, isSuccess, submissionErrors: data.messages};
};

export const requestPlanDetailsUpdating = (planType, planId, updatedFields) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.updatePlanDetails({companyAlias, planType, planId, updatedFields});
    const corePlanDetails = isSuccess ? data : {};

    dispatch(setIsCopyingEnabled(false));

    return {corePlanDetails, isSuccess};
};

export const requestAdditionalInfo = planId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getAdditionalInfo({companyAlias, planId});

    return {additionalInfo: data, isSuccess};
};

export const requestAdditionalInfoSetting = (planId, planInfo) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setAdditionalInfo({companyAlias, planId, planInfo});

    return {additionalInfo: data, isSuccess, submissionErrors: data.messages};
};

export const clearAncillaryPlans = () => ({type: CLEAR_ANCILLARY_PLANS});

export const requestAncillaryPlans = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getAncillaryPlansInfo({companyAlias, planPeriodId});
    const ancillaryPlans = isSuccess ? data : [];

    dispatch(receiveAncillaryPlans(ancillaryPlans));

    return {items: ancillaryPlans, isSuccess};
};

export const requestAncillaryPlansOverview = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getAncillaryPlansOverview({companyAlias, planPeriodId});
    const {plans = [], templates = []} = data ?? {};
    const ancillaryPlansOverview = (isSuccess ? plans : []).reduce((acc, {category, ...values}) => ({...acc, [category]: values}), {});

    return {isSuccess, plans: ancillaryPlansOverview, templates};
};

export const requestAncillaryPlansOverviewSetting = (planPeriodId, plans, templates) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setAncillaryPlansOverview({companyAlias, planPeriodId, plans, templates});

    return {isSuccess, submissionErrors: data.messages};
};

export const requestAncillaryTemplates = () => async () => {
    const {data} = await apiServices.getAncillaryTemplates();
    const {templates = []} = data ?? {};

    return {templates};
};

export const requestAncillaryPlanDetailsData = (planId, planCategory) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {isSuccess, data: ancillaryPlanDetails} = await requestAncillaryPlanDetails(companyAlias, planId, planCategory);

    dispatch(setIsCopyingEnabled(false));

    return {isSuccess, ancillaryPlanDetails};
};

export const requestAncillaryPlanDetailsSetting = (planId, ancillaryPlanDetails) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setAncillaryPlanDetails({companyAlias, planId, ancillaryPlanDetails});

    dispatch(setIsCopyingEnabled(false));

    return {ancillaryPlanDetails: data, isSuccess, submissionErrors: data.messages};
};

export const requestAncillaryPlanDetailsDeleting = planId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.deleteAncillaryPlanDetails({companyAlias, planId});
    const {id} = data ?? {};

    const ancillaryPlans = getAncillaryPlans(state);
    const updatedAncillaryPlans = ancillaryPlans.filter(negateFunc(getEqual(id, 'id')));

    dispatch(receiveAncillaryPlans(updatedAncillaryPlans));
    dispatch(setIsCopyingEnabled(false));

    return {data, isSuccess};
};

export const requestCustomizedAncillaryPlanDetailsData = planId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {isSuccess, data: ancillaryPlanDetails} = await requestCustomizedAncillaryPlanDetails(companyAlias, planId);

    dispatch(setIsCopyingEnabled(false));

    return {isSuccess, ancillaryPlanDetails};
};

export const requestCustomizedAncillaryPlanDetailsSetting = (planId, ancillaryPlanDetails) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setCustomizedAncillaryPlanDetails({companyAlias, planId, ancillaryPlanDetails});

    const ancillaryPlans = getAncillaryPlans(state);
    const updatedAncillaryPlans = ancillaryPlans.map(updatePlanById(data));

    dispatch(receiveAncillaryPlans(updatedAncillaryPlans));
    dispatch(setIsCopyingEnabled(false));

    return {ancillaryPlanDetails: data, isSuccess, submissionErrors: data.messages};
};

export const requestCustomizedAncillaryPlanDetailsDeleting = planId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.deleteCustomizedAncillaryPlanDetails({companyAlias, planId});
    const {id} = data ?? {};

    const ancillaryPlans = getAncillaryPlans(state);
    const updatedAncillaryPlans = ancillaryPlans.filter(negateFunc(getEqual(id, 'id')));

    dispatch(receiveAncillaryPlans(updatedAncillaryPlans));
    dispatch(setIsCopyingEnabled(false));

    return {data, isSuccess};
};

export const requestBenefitGuideDetails = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getBenefitGuideDetails({companyAlias, planPeriodId});

    return {isSuccess, data: isSuccess ? data : {}};
};

export const requestBenefitGuideDetailsSetting = (planPeriodId, benefitGuideDetails) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setBenefitGuideDetails({companyAlias, planPeriodId, benefitGuideDetails});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestConsultFees = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getConsultFees({companyAlias, planPeriodId});

    return {data, isSuccess};
};

export const requestPharmacyBenefitManagers = query => async dispatch => {
    const {data, isSuccess} = await apiServices.getPharmacyBenefitManagerList({query});
    const pharmacyBenefitManagers = isSuccess ? data : [];

    dispatch(receivePharmacyBenefitManagers(pharmacyBenefitManagers));

    return {pharmacyBenefitManagers, isSuccess};
};

export const requestAllWalletCardsPreviewParams = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data: webviewParams} = await apiServices.getAllWalletCardsPreviewParams({companyId, planPeriodId});

    dispatch(receiveWebviewParams(webviewParams));
};

export const requestPlanPreviewParams = (planPeriodId, planId) => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data: planWebviewParams} = await apiServices.getPlanPreviewParams({companyId, planId, planPeriodId});
    const {data: allWalletCardsWebviewParams} = await apiServices.getAllWalletCardsPreviewParams({companyId, planPeriodId});

    dispatch(receiveWebviewParams({...planWebviewParams, ...allWalletCardsWebviewParams}));
};

export const requestEmployeePreviewParams = (planPeriodId, userId, date) => async dispatch => {
    const {data} = await apiServices.getEmployeePreviewParams({planPeriodId, userId, date});
    const {plan_id: planId, ...webviewParams} = data;

    dispatch(receiveWebviewParams(webviewParams));

    return {planId};
};

export const requestWalletCards = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getWalletCards({companyId, planPeriodId});
    const {cards: walletCards} = isSuccess ? data : {};

    dispatch(receiveWalletCards(walletCards));

    return {walletCards, isSuccess};
};

export const requestWalletCardsSetting = walletCards => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    return await apiServices.setWalletCards({companyId, walletCards});
};

const requestConditionalVariablesSetting = requestFunc => (planId, conditions) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await requestFunc({companyAlias, planId, conditions});

    return {data, isSuccess, submissionErrors: data.messages};
};
export const requestCoreConditionalVariablesSetting = requestConditionalVariablesSetting(apiServices.setCoreConditionalVariables);
export const requestAncillaryConditionalVariablesSetting = requestConditionalVariablesSetting(apiServices.setAncillaryConditionalVariables);

export const requestDefaultConditionalVariables = category => async () => {
    const {data, isSuccess} = await apiServices.getDefaultConditionalVariables({category});
    const conditions = isSuccess ? data?.conditions : {};

    return {conditions, isSuccess};
};

export const requestCorePlansList = query => async () => {
    const {data, isSuccess} = await apiServices.getCorePlans({query});
    const plans = isSuccess ? data : [];

    return {plans, isSuccess};
};

export const requestAncillaryPlansList = query => async () => {
    const {data, isSuccess} = await apiServices.getAncillaryPlans({query});
    const plans = isSuccess ? data : [];

    return {plans, isSuccess};
};

export const requestCorePlanDetailsForCopying = (companyAlias, planType, planId) => async () => {
    const {data, isSuccess} = await apiServices.getPlanDetails({companyAlias, planType, planId});
    const planDetails = isSuccess ? data : {};

    return {planDetails: getEnhancedPlanDetails(planDetails), isSuccess};
};

export const requestAncillaryPlanDetailsForCopying = (companyAlias, planId, isPlanCustomized) => async () => {
    const requestPlanDetails = isPlanCustomized ? requestCustomizedAncillaryPlanDetails : requestAncillaryPlanDetails;
    const {data, isSuccess} = await requestPlanDetails(companyAlias, planId);

    return {planDetails: data, isSuccess};
};

export const requestCategoryAndTrackingInfoDetailsConfig = () => async () => {
    const {data, isSuccess} = await apiServices.getCategoryAndTrackingInfoDetailsConfig();
    const {
        roi_categories: categories = [],
        specialty_groups: specialtyGroups = [],
        salesforce_products: salesforceProducts = [],
        health_goals_questionnaire: healthGoalsQuestionnaire
    } = isSuccess ? data : {};
    const {questions: healthGoalsQuestions = []} = healthGoalsQuestionnaire?.questionnaire ?? {};

    return {categories, specialtyGroups, salesforceProducts, healthGoalsQuestions, isSuccess};
};

export const requestCategoryAndTrackingInfoSetting = (planId, cardView) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setCategoryAndTrackingInfo({companyAlias, planId, cardView});
    const updatedCardView = isSuccess ? data : {};

    return {cardView: updatedCardView, isSuccess, submissionErrors: data.messages};
};

export const requestCategoryAndTrackingInfoDataById = id => async () => {
    const {data, isSuccess} = await apiServices.getCategoryAndTrackingInfoDataById({id});

    return {data: isSuccess ? data : {}, isSuccess};
};
