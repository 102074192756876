import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Heading from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Link from '@frontend/ui-kit/Components/Link';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import {requestResourceFile} from '../../../actions/educationAndMarketing';
import {equal, getEqual, getFileFormat, bytesToMegabytes} from '../../../utils';
import {FILE_FORMATS} from '../../../constants';
import analyticsService from '../../../analyticsService';
import './index.scss';

const RESOURCE_TYPES = {
    link: 'link',
    video: 'video',
    file: 'file'
};

const RESOURCE_ICON_BY_TYPE = {
    [RESOURCE_TYPES.link]: ICON_TYPES.link,
    [RESOURCE_TYPES.video]: ICON_TYPES.videoFile,
    [RESOURCE_TYPES.file]: {
        [FILE_FORMATS.csv]: ICON_TYPES.excelFile,
        [FILE_FORMATS.tsv]: ICON_TYPES.excelFile,
        [FILE_FORMATS.xls]: ICON_TYPES.excelFile,
        [FILE_FORMATS.xlsx]: ICON_TYPES.excelFile,
        [FILE_FORMATS.numbers]: ICON_TYPES.excelFile,
        [FILE_FORMATS.png]: ICON_TYPES.imageFile,
        [FILE_FORMATS.jpg]: ICON_TYPES.imageFile,
        [FILE_FORMATS.jpeg]: ICON_TYPES.imageFile,
        [FILE_FORMATS.icon]: ICON_TYPES.imageFile,
        [FILE_FORMATS.heic]: ICON_TYPES.imageFile,
        [FILE_FORMATS.tiff]: ICON_TYPES.imageFile,
        [FILE_FORMATS.psd]: ICON_TYPES.imageFile,
        [FILE_FORMATS.txt]: ICON_TYPES.fileFull,
        [FILE_FORMATS.pages]: ICON_TYPES.fileFull,
        [FILE_FORMATS.doc]: ICON_TYPES.fileFull,
        [FILE_FORMATS.docx]: ICON_TYPES.fileFull,
        [FILE_FORMATS.rtf]: ICON_TYPES.fileFull,
        [FILE_FORMATS.heiv]: ICON_TYPES.videoFile,
        [FILE_FORMATS.mp4]: ICON_TYPES.videoFile,
        [FILE_FORMATS.m4v]: ICON_TYPES.videoFile,
        [FILE_FORMATS.mov]: ICON_TYPES.videoFile,
        [FILE_FORMATS.pdf]: ICON_TYPES.pdfFile,
        [FILE_FORMATS.ppt]: ICON_TYPES.presentationFile,
        [FILE_FORMATS.pptx]: ICON_TYPES.presentationFile
    }
};

const RESOURCE_ACTION_ICON_BY_TYPE = {
    [RESOURCE_TYPES.link]: ICON_TYPES.link,
    [RESOURCE_TYPES.video]: ICON_TYPES.link,
    [RESOURCE_TYPES.file]: ICON_TYPES.downloadCloud
};

const trackResourceLinkClick = (title, href) => () => analyticsService.trackExternalLink(title, href);

const MarketingResource = ({title, type_: type, file_data: fileData, link_url: linkUrl, embed_code: embedCode, with_preview: withPreview, preview_image_path: previewPath}) => {
    const dispatch = useDispatch();
    const [isFile, isVideo] = [RESOURCE_TYPES.file, RESOURCE_TYPES.video].map(getEqual(type));
    const isPreview = withPreview && previewPath;
    const previewClassName = isPreview ? 'marketing-resource_preview' : '';
    const isVideoPreview = isVideo && embedCode;

    const getResourceInfo = () => {
        const isFile = equal(RESOURCE_TYPES.file, type);
        const {name: fileName, size: fileSize} = fileData || {};
        const fileFormat = getFileFormat(fileName);
        const formattedFileSize = bytesToMegabytes(fileSize).toFixed(2);
        const subTitle = isFile ? fileName : linkUrl;
        const iconFile = isFile ? RESOURCE_ICON_BY_TYPE[type][fileFormat] : RESOURCE_ICON_BY_TYPE[type];
        const iconAction = RESOURCE_ACTION_ICON_BY_TYPE[type];

        return (
            <Row className='resource-info'>
                <Column className='resource-info__col resource-info__col_icon'>
                    <Icon type={iconFile} className={`resource-icon-file resource-icon-file_${iconFile}`}/>
                </Column>
                <Column className='resource-info__col'>
                    <Heading className='resource-title'>{title}</Heading>
                    <Text className='resource-subtitle'>
                        <span className='resource-subtitle__text'>{subTitle}</span>
                        {fileSize && <span className='resource-subtitle__file-size'>{formattedFileSize} MB</span>}
                    </Text>
                </Column>
                <Column className='resource-info__col resource-info__col_icon'>
                    <Icon type={iconAction} className='resource-icon-action'/>
                </Column>
            </Row>
        );
    };

    const onResourceClick = () => dispatch(requestResourceFile(fileData));

    const resourceInfo = getResourceInfo();

    return (
        <div className={`marketing-resource ${previewClassName}`}>
            {isPreview && (
                <div className='resource-preview'>
                    <img className='resource-preview__img' src={previewPath} alt={title}/>
                </div>
            )}

            {isVideoPreview && (
                <div className='resource-video'>
                    <div className='resource-video__content' dangerouslySetInnerHTML={{__html: embedCode}}/>
                </div>
            )}

            {isFile && <div className='marketing-resource__content' onClick={onResourceClick}>{resourceInfo}</div>}
            {!isFile && (
                <Link className='marketing-resource__content'
                    onClick={trackResourceLinkClick(title, linkUrl)}
                    href={linkUrl}
                    target='_blank'
                    rel='noreferrer'>
                    {resourceInfo}
                </Link>
            )}
        </div>
    );
};

MarketingResource.propTypes = {
    title: PropTypes.string,
    type_: PropTypes.string,
    embed_code: PropTypes.string,
    link_url: PropTypes.string,
    with_preview: PropTypes.bool,
    preview_image_path: PropTypes.string,
    file_data: PropTypes.shape({
        url: PropTypes.string,
        size: PropTypes.number,
        name: PropTypes.string
    })
};

export {MarketingResource as TestableMarketingResource};
export default React.memo(MarketingResource);
