import React, {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import {Form, Field} from '../../shared/FormComponents';
import LabeledTooltip from '../../shared/LabeledTooltip';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {requestOpenEnrollmentOverview, requestOpenEnrollmentOverviewUpdating} from '../../../actions/overview';
import {getOpenEnrollmentOverview} from '../../../selectors/overview';
import {getRegisteredFieldsValues, validateRequired} from '../../../utils';
import {FORMS} from '../../../constants';
import {ACCESS_RESTRICTION_TO_HJ_OPTIONS, ELIGIBILITY_UPDATE_FREQUENCY_OPTIONS, ENROLMENT_OPTIONS} from '../../../options';
import './index.scss';

const ELIGIBILITY_FILE_ATTR_TOOLTIP_CONTENT = 'We will apply this criteria every time we get a new eligibility file to determine who will no longer have access to HealthJoy.';
const ELIGIBILITY_LAST_FILE_TOOLTIP_CONTENT = 'This is the date of the last current year eligibility file you will send to HealthJoy. After this date we will no longer process current plan year elections.';

/* istanbul ignore next */
const validate = values => ({
    open_enrollment_due_date: validateRequired(values.open_enrollment_due_date),
    eligibility_file_enrollments: validateRequired(values.eligibility_file_enrollments),
    voluntary_source: validateRequired(values.voluntary_source)
});

const OpenEnrollmentForm = ({onComplete}) => {
    const dispatch = useDispatch();
    const openEnrollmentOverview = useSelector(getOpenEnrollmentOverview);

    useEffect(() => {
        dispatch(requestOpenEnrollmentOverview());
    }, [dispatch]);

    const onSubmit = useCallback(async (values, form) => {
        const registeredFieldsValues = getRegisteredFieldsValues(form.getRegisteredFields(), values);

        dispatch(requestOpenEnrollmentOverviewUpdating(registeredFieldsValues));
    }, [dispatch]);

    return (
        <ContentSection>
            <Form name={FORMS.openEnrollmentOverview} initialValues={openEnrollmentOverview} validate={validate} onSubmit={onSubmit} onSubmitSuccess={onComplete}>
                {({handleSubmit}) => (
                    <form className='open-enrollment-form' onSubmit={handleSubmit} noValidate>
                        <Row className='open-enrollment-form__row'>
                            <Column className='open-enrollment-form__row__column' sm={4}>
                                <Field name='open_enrollment_due_date'>
                                    {props => <DatePicker {...props} placeholder='Choose date...' label='Open Enrollment End Date'/>}
                                </Field>
                            </Column>

                            <Column className='open-enrollment-form__row__column' sm={4}>
                                <Field name='eligibility_file_enrollments'>
                                    {props => <DatePicker {...props} placeholder='Choose date...' label='When do you expect to send the eligibility file with new enrollments?'/>}
                                </Field>
                            </Column>

                            <Column className='open-enrollment-form__row__column' sm={4}>
                                <Field name='eligibility_fire_attr'>
                                    {props => <Select {...props} options={ACCESS_RESTRICTION_TO_HJ_OPTIONS} label={<LabeledTooltip title='How will we know someone should no longer have access to HealthJoy?' content={ELIGIBILITY_FILE_ATTR_TOOLTIP_CONTENT}/>}/>}
                                </Field>
                            </Column>
                        </Row>

                        <Row>
                            <Column className='open-enrollment-form__row__column' sm={4}>
                                <Field name='eligibility_last_file_when'>
                                    {props => <DatePicker {...props} placeholder='Choose date...' label={<LabeledTooltip title='When will you send the last eligibility file for the current plan year?' content={ELIGIBILITY_LAST_FILE_TOOLTIP_CONTENT}/>}/>}
                                </Field>
                            </Column>

                            <Column className='open-enrollment-form__row__column' sm={4}>
                                <Field name='eligibility_update_frequency'>
                                    {props => <Select {...props} options={ELIGIBILITY_UPDATE_FREQUENCY_OPTIONS} label='How often will you be sending a new eligibility file in the upcoming plan year?'/>}
                                </Field>
                            </Column>

                            <Column className='open-enrollment-form__row__column' sm={4}>
                                <Field name='voluntary_source'>
                                    {props => <Select {...props} options={ENROLMENT_OPTIONS} label='Where will the voluntary elections be coming from?'/>}
                                </Field>
                            </Column>
                        </Row>

                        <Separator type='solid'/>

                        <div className='open-enrollment-action-bar'>
                            <Button className='open-enrollment-action-bar__button' type={BUTTON_TYPES.primary} isSubmit>Save</Button>
                        </div>
                    </form>
                )}
            </Form>
        </ContentSection>
    );
};

OpenEnrollmentForm.propTypes = {
    onComplete: PropTypes.func.isRequired
};

export {OpenEnrollmentForm as TestableOpenEnrollmentForm};
export default withSubStepCompletion(OpenEnrollmentForm);
