import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import SimpleFileUploader from '@frontend/ui-kit/Components/FileUploader';
import {requestPrivateFileUploading, requestPublicFileUploading} from '../../../actions/general';
import {getIsSuperAdmin} from '../../../selectors/general';
import {MAX_TEXT_FILE_SIZE} from '../../../constants';

const FileUploader = ({isProtected, isDownloadable, isPrivateFile, maxSize, onChange, onBlur, ...props}) => {
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const dispatch = useDispatch();

    const onUpload = async file => {
        const uploadFunction = isPrivateFile ? requestPrivateFileUploading : requestPublicFileUploading;
        const {url, errorMessage, isSuccess} = await dispatch(uploadFunction(file));
        const error = new Error(errorMessage);
        error.name = file.name;

        return isSuccess ? url : error;
    };

    const enhancedOnChange = value => {
        // FYI: we need to force blur manually to force field touched state (18.05.2023, Yuri);
        onBlur();
        onChange(value);
    };

    const fileUploaderProps = {
        ...props,
        isDownloadable: isProtected ? isSuperAdmin : isDownloadable,
        onUpload,
        onChange: enhancedOnChange,
        maxSize
    };

    return <SimpleFileUploader {...fileUploaderProps}/>;
};

FileUploader.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    isProtected: PropTypes.bool,
    isDownloadable: PropTypes.bool,
    isPrivateFile: PropTypes.bool,
    maxSize: PropTypes.number
};

FileUploader.defaultProps = {
    isPrivateFile: false,
    maxSize: MAX_TEXT_FILE_SIZE
};

export {FileUploader as TestableFileUploader};
export default React.memo(FileUploader);
