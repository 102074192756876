import {RECEIVE_BENEFITS_OVERVIEW, RECEIVE_OPEN_ENROLLMENT_OVERVIEW} from '../actions/actionTypes';

export const initialState = {
    benefits: {
        company: {},
        core: {medical: [], dental: [], vision: []},
        medicalTraits: {},
        ancillary: [],
        eligibility_info: {}
    },
    openEnrollment: {}
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case RECEIVE_BENEFITS_OVERVIEW: {
            const {benefitsOverview: benefits} = payload;

            return {...state, benefits};
        }

        case RECEIVE_OPEN_ENROLLMENT_OVERVIEW: {
            const {openEnrollmentOverview: openEnrollment} = payload;

            return {...state, openEnrollment};
        }

        default: {
            return state;
        }
    }
};
