import React from 'react';
import {getEqual, equal, getObjWithoutPaths, generateUniqueId} from '../../utils';
import {CORE_PLAN_TYPES} from '../../constants';

const COMMON_CORE_UNCOPYABLE_FIELDS = ['card_view', 'id', 'documents', 'spd_urls', 'sbc_url', 'sample_id_card_urls', 'group_number', 'medical_group_id', 'member_id_prefix'];
const COMMON_CORE_UNCOPYABLE_FIELDS_ACROSS_DIFFERENT_COMPANIES = [...COMMON_CORE_UNCOPYABLE_FIELDS, 'network_name', 'is_additional_network', 'secondary_network_name'];
const COMMON_CORE_UNCOPYABLE_BLOCKS_FIELDS = ['medical_group_id'];
const MEDICAL_COPYABLE_FIELDS_ACROSS_DIFFERENT_COMPANIES = [
    'customized',
    'carrier_name',
    'carrier_logo',
    'carrier_phone',
    'name',
    'app_name',
    'type',
    'preauthorization_phone',
    'medical_group_id',
    'network_url',
    'network_url_alias',
    'network_member_support_phone',
    'secondary_network_url',
    'secondary_network_url_alias',
    'secondary_network_support_phone',
    'pharmacy_benefit_manager',
    'pbm_logo',
    'pbm_member_services_phone',
    'pbm_support_phone',
    'drug_list_name',
    'formulary_url',
    'formulary_url_alias'
];

const COMMON_ANCILLARY_UNCOPYABLE_CARDVIEW_FIELDS = ['card_view.category_and_tracking_info.id', 'card_view.category_and_tracking_info.card_view_id', 'card_view.id', 'card_view.card_id', 'card_view.order', 'card_view.company_alias', 'card_view.plan_id', 'card_view.plan_period_id', 'card_view.plan_source', 'card_view.plan_kind', 'card_view.legacy_card_id', 'card_view.is_published', 'card_view.is_reviewed', 'card_view.conditions'];
const COMMON_ANCILLARY_UNCOPYABLE_FIELDS = [...COMMON_ANCILLARY_UNCOPYABLE_CARDVIEW_FIELDS, 'id', 'documents', 'employer_contribution', 'policy_holder', 'group_policy', 'individual_contribution', 'family_contribution', 'display_card_settings.show_employer_contribution'];

const withPlanCopying = Component => {
    const WithPlanCopying = props => {
        const getPreparedBlocksForCopying = (blocks = [], uncopyableFields) => blocks.map(block => {
            const {widgets = []} = block;
            const updatedWidgets = widgets.map(widget => {
                const {content} = widget ?? {};

                const updatedContent = !Array.isArray(content) ? content : content.map(contentItem => {
                    const {field, value} = contentItem ?? {};

                    return {...contentItem, value: !uncopyableFields.some(getEqual(field)) ? value : '', variables: null};
                });

                return {...widget, content: updatedContent, variables: null};
            });

            return {...block, cid: generateUniqueId(), widgets: updatedWidgets};
        });

        const getCopiedPlanDetails = (planDetails, isCopyingAcrossDifferentCompanies) => {
            const {category: planCategory, blocks, customized} = planDetails;
            const isCorePlan = Object.values(CORE_PLAN_TYPES).some(getEqual(planCategory));
            const isMedicalPlan = equal(planCategory, CORE_PLAN_TYPES.medical);
            const coreUncopyableFieldsAcrossDifferentCompanies = isMedicalPlan ? Object.keys(planDetails).filter(key => !MEDICAL_COPYABLE_FIELDS_ACROSS_DIFFERENT_COMPANIES.includes(key)) : COMMON_CORE_UNCOPYABLE_FIELDS_ACROSS_DIFFERENT_COMPANIES;
            const coreUncopyableFields = isCopyingAcrossDifferentCompanies ? coreUncopyableFieldsAcrossDifferentCompanies : COMMON_CORE_UNCOPYABLE_FIELDS;
            const uncopyableFields = isCorePlan ? coreUncopyableFields : COMMON_ANCILLARY_UNCOPYABLE_FIELDS;
            const uncopyableBlocksFields = isCorePlan ? [...uncopyableFields, ...COMMON_CORE_UNCOPYABLE_BLOCKS_FIELDS] : uncopyableFields;
            const copiedBlocks = getPreparedBlocksForCopying(blocks, uncopyableBlocksFields);

            return {
                ...getObjWithoutPaths(uncopyableFields, planDetails),
                ...(isCorePlan && {name: `Copy - ${planDetails.name}`}),
                blocks: customized ? copiedBlocks : [],
                completed: false
            };
        };

        const enhancedProps = {...props, getCopiedPlanDetails};

        return <Component {...enhancedProps}/>;
    };

    return WithPlanCopying;
};

export default withPlanCopying;
