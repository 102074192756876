import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {requestZendeskProfileSSO} from '../../../actions/authorization';
import {parseQuery} from '../../../utils';
import {JWT_ZENDESK_ACCESS_URL} from '../../../constants';

const ZendeskRedirectionLayout = () => {
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const [ssoToken, setSSOToken] = useState('');
    const [returnTo, setReturnTo] = useState('');
    const {search} = useLocation();
    const {redirect_to: redirectTo} = parseQuery(search);

    useEffect(() => {
        (async () => {
            try {
                const {data} = await dispatch(requestZendeskProfileSSO());
                const {external_access_token: token} = data || {};
                setSSOToken(token);
                setReturnTo(redirectTo);
            } catch (error) {
                console.error('Error fetching Zendesk SSO data:', error);
            }
        })();
    }, [dispatch, redirectTo]);

    useEffect(() => {
        if (ssoToken && returnTo && formRef.current) {
            formRef.current.submit();
        }
    }, [ssoToken, returnTo]);

    return (
        <form ref={formRef} id='jwtForm' method='POST' action={`${JWT_ZENDESK_ACCESS_URL}/access/jwt`}>
            <input type='hidden' name='jwt' value={ssoToken}/>
            <input type='hidden' name='return_to' value={redirectTo}/>
        </form>
    );
};

export default ZendeskRedirectionLayout;
