import React, {useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {AdvancedCustomCard} from '@frontend/wallet-cards';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import Input from '@frontend/ui-kit/Components/Input';
import Separator from '@frontend/ui-kit/Components/Separator';
import ShadowCustomizableInput from '../ShadowCustomizableInput';
import LinkableField from '../LinkableField';
import DocumentUploader from '../DocumentUploader';
import AncillaryCarrierInfo from '../AncillaryCarrierInfo';
import CoreCarrierInfo from '../CoreCarrierInfo';
import CopyableField from '../CopyableField';
import ProviderNetworks from '../ProviderNetworks';
import MedicalPlanBasicInfo, {validateOnSubmit as validateMedicalPlanBasicInfoOnSubmit} from '../MedicalPlanBasicInfo';
import PharmacyBenefitManagerInfo from '../PharmacyBenefitManagerInfo';
import LabeledTooltip from '../../shared/LabeledTooltip';
import {Field, FieldArray} from '../../shared/FormComponents';
import UnsupportedBrowserAlert from '../../shared/UnsupportedBrowserAlert';
import withVariableLinking from '../../../HOC/withVariableLinking';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {getIsSuperAdmin} from '../../../selectors/general';
import {compose, equal, isEven, isBoolean, getUnfilledArray, generateUniqueId, isEmpty, trimStart} from '../../../utils';
import {CORE_PLAN_TYPES, PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES, PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS, IS_MS_BROWSER} from '../../../constants';
import {PLAN_TYPE_OPTIONS, PLAN_ADVANCED_CUSTOMIZATION_SECTION_OPTIONS} from '../../../options';
import './index.scss';

const AUTO_REPLACEMENT_ALERT_TEXT = 'Data entered on this page can be replaced with member-specific values depending on members’ eligibility and plan data.';
const formularySearchUrlTooltipContent = 'Please enter the link to this plan’s formulary, such as a link from the PBM’s website.';
const formularySearchUrlAliasTooltipContent = 'This is how the link will appear on the wallet card.';

/* istanbul ignore next */
const validateOnSubmit = (values, {planType, isCustomizedMode}) => {
    const isMedicalPlan = equal(planType, CORE_PLAN_TYPES.medical);

    return {
        ...(isMedicalPlan ? validateMedicalPlanBasicInfoOnSubmit(values, {isCustomizedMode}) : null)
    };
};

const AdvancedCustomizationForm = ({planId, planType, isReadonly, onChangeValidatableFile, onLinkToVariable}) => {
    const [isNewSectionShowed, setIsNewSectionShowed] = useState();
    const form = useForm();
    const {values} = useFormState();
    const isSuperAdmin = useSelector(getIsSuperAdmin);

    const isMedical = equal(planType, CORE_PLAN_TYPES.medical);
    const medicalPlanBasicInfoProps = {onChangeValidatableFile, isReadonly, isCustomizedMode: true};

    const enhancedValues = useMemo(() => ({
        ...values,
        blocks: values.blocks?.map((item, index) => {
            const enhancedWidgets = item.widgets?.map((item, index) => ({...item, order: item.order ?? index}));

            return {...item, order: item.order ?? index, widgets: enhancedWidgets};
        })
    }), [values]);

    const getListWidget = useCallback(type => ({fields}) => {
        const onAddListItem = () => fields.push({cid: generateUniqueId(), value: ''});

        return (
            <div className='list-widget'>
                <div className='list-widget__list'>
                    {fields.map((field, index) => {
                        const {cid} = fields.value[index] || {};
                        const isNumberedList = equal(type, PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.numberedList);
                        const onRemoveListItem = () => fields.remove(index);
                        const onLink = () => onLinkToVariable(field);

                        return (
                            <React.Fragment key={`${cid}_${index}`}>
                                <li className='shadow-customizable-list-item'>
                                    <div className='item-details'>
                                        {isNumberedList ? <Text className='item-details__numeration'>{index + 1}</Text> : <div className='item-details__numeration item-details__numeration_bulleted'/>}

                                        <div className='item-details__field'>
                                            <LinkableField name={`${field}.value`} onLink={onLink}>
                                                {props => <ShadowCustomizableInput {...props} readonly={isReadonly} data-testid={`${type}-value-${index}`}/>}
                                            </LinkableField>
                                        </div>
                                    </div>

                                    {!isReadonly && <Icon className='trash-icon' type={ICON_TYPES.delete} onClick={onRemoveListItem}/>}
                                </li>

                                {!equal(index, fields.value.length - 1) && <Separator className='list-item-separator'/>}
                            </React.Fragment>
                        );
                    })}
                </div>

                {!isReadonly && (
                    <Button className='list-widget__button' type={BUTTON_TYPES.secondary} onClick={onAddListItem}>
                        <Icon type={ICON_TYPES.circlePlus}/> Add new item
                    </Button>
                )}
            </div>
        );
    }, [isReadonly, onLinkToVariable]);

    const getTextTableWidget = useCallback(({fields}) => fields.map((field, index) => {
        const {label, cid, is_hidden: isHidden, is_disabled: isDisabled} = fields.value[index];
        const onLink = () => onLinkToVariable(field);
        const formattedName = field
            .substring(0, field.length - 1)
            .replaceAll('.', '')
            .replace(/[\[\]]/g, '-');

        return (
            <React.Fragment key={`${cid}_${index}`}>
                {isBoolean(isHidden) && (
                    <Field name={`${field}.is_hidden`}>
                        {props => <Switcher {...props} readonly={isReadonly} className='card-widget__switcher' caption={`Hide ${label}`} data-testid={`text-table-widget-is_hidden-${index}`}/>}
                    </Field>
                )}
                <LinkableField name={`${field}.value`} onLink={onLink} key={`${cid}_${index}`}>
                    {props => <ShadowCustomizableInput {...props} readonly={isReadonly} className='text-table-item' label={label} disabled={isDisabled} wrapperClassName='mb-12' data-testid={`text-table-widget-value-${formattedName}`}/>}
                </LinkableField>
            </React.Fragment>
        );
    }), [isReadonly, onLinkToVariable]);

    const getTableWidget = useCallback(({fields}) => {
        const TABLE_COLUMN_COUNT = 2;
        const iterateThroughRow = fn => getUnfilledArray(TABLE_COLUMN_COUNT).map((unfilledItem, index) => fn(index));
        const onAddRow = () => iterateThroughRow(() => fields.push({cid: generateUniqueId(), value: ''}));

        return (
            <div className='table-widget'>
                {!isEmpty(fields.value) && (
                    <div className='table-header'>
                        <div className='table-item'>First Column</div>
                        <div className='table-item'>Second Column</div>
                    </div>
                )}

                <div className='table-items'>
                    {fields.map((field, index) => {
                        const {cid} = fields.value[index] || {};
                        const onDeleteRow = () => form.batch(() => {
                            iterateThroughRow(rowItemIndex => {
                                fields.remove(index - rowItemIndex);
                            });
                        });
                        const onLink = () => onLinkToVariable(field);

                        return (
                            <React.Fragment key={`${cid}_${index}`}>
                                <div className='table-item'>
                                    <LinkableField name={`${field}.value`} onLink={onLink}>
                                        {props => <ShadowCustomizableInput {...props} readonly={isReadonly} data-testid={`table-value-${index}`}/>}
                                    </LinkableField>
                                </div>

                                {!isEven(index) && (
                                    <div className='table-items__icon-wrapper'>
                                        {!isReadonly && <Icon className='trash-icon' type={ICON_TYPES.delete} onClick={onDeleteRow}/>}
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>

                {!isReadonly && (
                    <Button className='table-widget__button' type={BUTTON_TYPES.secondary} onClick={onAddRow}>
                        <Icon type={ICON_TYPES.circlePlus}/> Add a new row
                    </Button>
                )}
            </div>
        );
    }, [isReadonly, onLinkToVariable]);

    const getWidgets = useCallback(({fields}) => fields.map((field, index) => {
        const {field: fieldName, type, label, is_hidden: isHidden, is_disabled: isDisabled, cid} = fields.value[index];
        const onLink = () => onLinkToVariable(field);

        const fieldsMaxLength = {
            card_subtitle: 128
        };

        const WIDGETS_MAP = {
            [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.text]: (
                <LinkableField name={`${field}.content`} onLink={onLink} maxLength={fieldsMaxLength[fieldName]}>
                    {props => <ShadowCustomizableInput {...props} readonly={isReadonly} disabled={isDisabled} label={label} wrapperClassName='mb-12' data-testid={`text-content-${index}`}/>}
                </LinkableField>
            ),
            [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.twoColumnTable]: <FieldArray name={`${field}.content`}>{getTableWidget}</FieldArray>,
            [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.textTwoColumnTable]: <FieldArray name={`${field}.content`}>{getTextTableWidget}</FieldArray>,
            [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.numberedList]: <FieldArray name={`${field}.content`}>{getListWidget(PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.numberedList)}</FieldArray>,
            [PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.bulleted_list]: <FieldArray name={`${field}.content`}>{getListWidget(PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.bulleted_list)}</FieldArray>
        };
        const widgetComponent = WIDGETS_MAP[type];

        return (
            <div key={`${cid}_${index}`} className='card-widget'>
                {isBoolean(isHidden) && (
                    <Field name={`${field}.is_hidden`}>
                        {props => <Switcher {...props} readonly={isReadonly} className='card-widget__switcher' caption={`Hide ${label}`} data-testid={`switcher-is_hidden-${index}`}/>}
                    </Field>
                )}

                {widgetComponent}
            </div>
        );
    }), [isReadonly, getListWidget, getTableWidget, getTextTableWidget, onLinkToVariable]);

    const getBlocks = useCallback(({fields}) => {
        const onAddBlock = widgetType => {
            const isTextWidget = equal(widgetType, PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.text);
            const widgetLabel = PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS[widgetType];

            fields.push({cid: generateUniqueId(), type: 'custom', title: widgetLabel, widgets: [{cid: generateUniqueId(), type: widgetType, label: widgetLabel, content: isTextWidget ? '' : []}]});
            setIsNewSectionShowed(false);
        };
        const onToggleNewSection = () => setIsNewSectionShowed(isNewSectionShowed => !isNewSectionShowed);

        return (
            <div className='card-blocks'>
                {fields.map((field, index) => {
                    const {title, type, widgets, cid} = fields.value[index] || {};
                    const isLast = equal(index, fields.value.length - 1);
                    const isCustom = equal(type, 'custom');

                    const onResetBlocks = () => widgets.map((widget, widgetIndex) => {
                        const path = `${field}.widgets[${widgetIndex}].content`;

                        if (!Array.isArray(widget.content)) {
                            return form.change(path, '');
                        }

                        return form.batch(() => {
                            widget.content.forEach((widgetItem, widgetItemIndex) => form.change(`${path}[${widgetItemIndex}].value`, ''));
                        });
                    });
                    const onDeleteBlock = () => fields.remove(index);

                    return (
                        <div className='card-block' key={`${cid}_${index}`}>
                            <div className='card-block-header'>
                                <Heading>{title}</Heading>

                                {!isReadonly && isCustom && <Icon className='card-block-header__icon trash-icon' type={ICON_TYPES.delete} onClick={onDeleteBlock}/>}
                                {!isReadonly && !isCustom && <Icon className='card-block-header__icon' type='reset' onClick={onResetBlocks}/>}
                            </div>

                            <div className='card-widgets'>
                                <FieldArray name={`${field}.widgets`}>{getWidgets}</FieldArray>
                            </div>

                            {!isLast && <Separator className='card-block__separator'/>}
                        </div>
                    );
                })}

                {isNewSectionShowed && (
                    <div className='new-block'>
                        <Heading className='new-block__title'>New Section</Heading>
                        <Select onChange={onAddBlock} readonly={isReadonly} options={PLAN_ADVANCED_CUSTOMIZATION_SECTION_OPTIONS}/>
                    </div>
                )}

                {!isReadonly && (
                    <div className='blocks-action-bar'>
                        <Button className='blocks-action-bar__button' type={BUTTON_TYPES.tertiary} onClick={onToggleNewSection}>
                            <Icon type={ICON_TYPES.add}/> Add new section
                        </Button>
                    </div>
                )}
            </div>
        );
    }, [isReadonly, form, getWidgets, isNewSectionShowed]);

    const onChangeCard = useCallback(value => form.change('blocks', value), [form]);

    return (
        <Row className='ancillary-plan-details-form advanced-customization-form'>
            <Field name='category'>{props => <Input {...props} type='hidden'/>}</Field>

            <Column className='ancillary-plan-details-form__column' sm={12}>
                {IS_MS_BROWSER && <UnsupportedBrowserAlert className='advanced-customization-form__alert'/>}

                <Alert className='advanced-customization-form__alert' type={ALERT_TYPES.warning} description={AUTO_REPLACEMENT_ALERT_TEXT}/>

                {planType && !isMedical && (
                    <React.Fragment>
                        <CopyableField name='name' parse={trimStart}>
                            {props => <Input {...props} readonly={isReadonly} className='mb-12' placeholder='Please enter...' label='Plan Name'/>}
                        </CopyableField>

                        <CopyableField name='app_name' parse={trimStart}>
                            {props => <Input {...props} readonly={isReadonly} className='mb-12' placeholder='Please enter...' label='Plan Name Show in the App'/>}
                        </CopyableField>

                        <CopyableField name='type'>
                            {props => <Select {...props} readonly={isReadonly} className='mb-12' options={PLAN_TYPE_OPTIONS} placeholder='Type to select...' label='Plan Type'/>}
                        </CopyableField>
                    </React.Fragment>
                )}

                {isMedical ? <CoreCarrierInfo isReadonly={isReadonly}/> : <AncillaryCarrierInfo isReadonly={isReadonly}/>}

                <Separator/>

                {!isMedical && <DocumentUploader isReadonly={isReadonly}/>}

                {isMedical && (
                    <React.Fragment>
                        <MedicalPlanBasicInfo {...medicalPlanBasicInfoProps}/>

                        <Row className='row_bottom'>
                            <Column className='ancillary-plan-details-form__column' sm={6}>
                                <ProviderNetworks isReadonly={isReadonly} isCustomizedMode/>
                            </Column>
                            <Column className='ancillary-plan-details-form__column' sm={6}>
                                <PharmacyBenefitManagerInfo isReadonly={isReadonly} isCustomizedMode/>

                                <CopyableField name='drug_list_name' parse={trimStart}>
                                    {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Drug List Name' wrapperClassName='mb-12'/>}
                                </CopyableField>

                                <CopyableField name='rx_pcn' parse={trimStart}>
                                    {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Rx PCN' wrapperClassName='mb-12'/>}
                                </CopyableField>

                                <CopyableField name='rx_group' parse={trimStart}>
                                    {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Rx Group Number' wrapperClassName='mb-12'/>}
                                </CopyableField>

                                <CopyableField name='rx_bin' parse={trimStart}>
                                    {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Rx BIN' wrapperClassName='mb-12'/>}
                                </CopyableField>

                                <CopyableField name='formulary_url' parse={trimStart}>
                                    {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label={<LabeledTooltip title='Formulary Search URL' content={formularySearchUrlTooltipContent}/>} wrapperClassName='mb-12'/>}
                                </CopyableField>

                                <CopyableField name='formulary_url_alias' parse={trimStart}>
                                    {props => <Input {...props} readonly={isReadonly} placeholder='e.g. Prescription Coverage List' maxLength={24} label={<LabeledTooltip title='Formulary Search URL Alias' content={formularySearchUrlAliasTooltipContent}/>}/>}
                                </CopyableField>
                            </Column>
                        </Row>
                    </React.Fragment>
                )}
                <Separator/>
            </Column>

            <Column className='ancillary-plan-details-form__column' sm={6}>
                <Text className='advanced-customization-form__description'>
                    Add, remove and edit sections to manage the look & feel of the wallet card appearing in the employees' benefits wallet.
                    Fields left blank will not appear in the resulting wallet card.
                </Text>

                {/* FYI: we have to pass key prop to FieldArray due to same cids within basic and copied plan. */}
                {/* We must be sure that blocks will be rerendered once we switch plans (17.12.2022, Oleh) */}
                <FieldArray key={planId} name='blocks'>{getBlocks}</FieldArray>

                {isSuperAdmin && (
                    <div className='advanced-customization-form__publishing-switcher'>
                        <Field name='card_view.is_published'>{props => <Switcher {...props} readonly={isReadonly} className='card-widget__switcher' caption='Card Published'/>}</Field>
                    </div>
                )}
            </Column>

            <Column className='ancillary-plan-details-form__column' sm={6}>
                <div className='preview-wrapper'>
                    <AdvancedCustomCard isEditable={!isReadonly} isHeader content={enhancedValues} onChange={onChangeCard}/>
                </div>
            </Column>
        </Row>
    );
};

AdvancedCustomizationForm.propTypes = {
    planId: PropTypes.number,
    planType: PropTypes.string,
    isReadonly: PropTypes.bool,
    onLinkToVariable: PropTypes.func,
    onChangeValidatableFile: PropTypes.func
};

export {validateOnSubmit, AdvancedCustomizationForm as TestableAdvancedCustomizationForm};
export default compose(
    withVariableLinking,
    React.memo
)(AdvancedCustomizationForm);
