import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import HorizontalTimeline from '../../../Components/shared/HorizontalTimeline';
import {redirectTo} from '../../../actions/general';
import {getProcessedTimelineStep, getProfileInfo} from '../../../selectors/general';
import {getEqual, negateFunc} from '../../../utils';
import {ROUTES, TIMELINE_STEP_STATUSES} from '../../../constants';
import './index.scss';

const StepProgressPopup = ({isSubStepCompleted, subStepId, onClose}) => {
    const dispatch = useDispatch();
    const {first_name: name} = useSelector(getProfileInfo);
    const {children: subSteps, title: stepName} = useSelector(getProcessedTimelineStep);

    const completedSubSteps = useMemo(() => {
        return subSteps.filter(getEqual(TIMELINE_STEP_STATUSES.completed, 'status'));
    }, [subSteps]);
    const remainedSubStepsCount = useMemo(() => {
        return subSteps.length - completedSubSteps.length;
    }, [subSteps, completedSubSteps]);

    const onContinue = () => {
        const neighborSubSteps = subSteps.filter(negateFunc(getEqual(subStepId, 'id')));
        const {url: newSubStepUrl} = neighborSubSteps.find(getEqual(TIMELINE_STEP_STATUSES.new, 'status')) || {};
        const {url: incompletedSubStepUrl} = neighborSubSteps.find(getEqual(TIMELINE_STEP_STATUSES.incompleted, 'status')) || {};

        onClose();
        dispatch(redirectTo(newSubStepUrl || incompletedSubStepUrl || ROUTES.root));
    };

    return (
        <div data-testid='step-progress-popup' className='step-progress-popup'>
            <div className='progress-popup-content'>
                {isSubStepCompleted && (
                    <React.Fragment>
                        {!!remainedSubStepsCount && (
                            <React.Fragment>
                                <Heading className='progress-popup-content__title' type={HEADING_TYPES['1']}>Great going, {name}!</Heading>
                                <Heading className='progress-popup-content__description'>
                                    You have only {remainedSubStepsCount} more steps to complete <span className='step-name'>{stepName}</span>.
                                </Heading>

                                <HorizontalTimeline className='step-info-timeline' steps={subSteps}/>

                                <div data-testid='remained-sub-steps-count-action-bar' className='action-bar'>
                                    <Button className='action-bar__button' type={BUTTON_TYPES.secondary} onClick={onContinue}>Continue</Button>
                                </div>
                            </React.Fragment>
                        )}

                        {!remainedSubStepsCount && (
                            <React.Fragment>
                                <Icon className='step-progress-popup__icon-circle' type={ICON_TYPES.checkCircleFull}/>

                                <Heading className='progress-popup-content__title' type={HEADING_TYPES['1']}>Congrats, {name}!</Heading>
                                <Heading className='progress-popup-content__description'>You’ve entered all the info we need at this point. Nice job!</Heading>

                                <div data-testid='no-remained-sub-steps-count-action-bar' className='action-bar'>
                                    <Button className='action-bar__button' type={BUTTON_TYPES.secondary} onClick={onContinue}>Take me home</Button>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {!isSubStepCompleted && (
                    <React.Fragment>
                        <Heading className='progress-popup-content__title' type={HEADING_TYPES['1']}>Great going, {name}!</Heading>
                        <Heading className='progress-popup-content__description'>
                            You need to add more information to “Mark as Done” <span className='step-name'>{stepName}</span>.
                        </Heading>

                        <HorizontalTimeline className='step-info-timeline' steps={subSteps}/>

                        <div data-testid='no-completed-sub-step-action-bar' className='action-bar'>
                            <Button data-testid='no-completed-sub-step-close-button' className='action-bar__button' type={BUTTON_TYPES.secondary} onClick={onClose}>Close</Button>
                            <Button data-testid='no-completed-sub-step-continue-button' className='action-bar__button' type={BUTTON_TYPES.primary} onClick={onContinue}>Continue</Button>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

StepProgressPopup.propTypes = {
    subStepId: PropTypes.number,
    isSubStepCompleted: PropTypes.bool,
    onClose: PropTypes.func
};

export {StepProgressPopup as TestableStepProgressPopup};
export default React.memo(StepProgressPopup);
