import {
    FIN_ACCOUNT_TYPES,
    MEMBER_SERVICE_UTILIZATION_TYPES,
    REPORTING_PLAN_PERIODS,
    TIMELINE_STEP_STATUSES,
    USER_ROLES,
    USER_ROLE_LABELS,
    USER_TYPES,
    USER_TYPE_LABELS,
    FAMILY_SEGMENTS,
    AVAILABILITY_CONDITIONS_TYPE,
    VARIABLE_CONDITIONS_OPERATORS,
    STATES,
    TIMELINE_TEMPLATE_TYPES,
    TIMELINE_TEMPLATE_STATUSES,
    TELADOC_ELIGIBILITY_TYPES,
    RECOMMENDATION_CARD_ACTIONS,
    ELIGIBILITY_FILES_SOURCES,
    ACCESS_RESTRICTION_TO_HJ_TYPES,
    HJ_SFTP_HOST,
    SFTP_HOST_TYPES,
    PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES,
    PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS,
    FAMILY_MANAGEMENT_TYPES,
    IMPORT_VERSIONS_CONFIG_TYPES,
    PLATFORM_AUTOMATION_TYPES
} from './constants';
import {getUnfilledArray, getYear} from './utils';

export const CONDITION_OPTIONS = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
];

export const TEXT_CONDITION_OPTIONS = [
    {label: 'Yes', value: 'yes'},
    {label: 'No', value: 'no'}
];

export const TIMELINE_STATUS_OPTIONS = [
    {label: 'Mark As Completed', value: TIMELINE_STEP_STATUSES.completed},
    {label: 'Mark As Incompleted', value: TIMELINE_STEP_STATUSES.incompleted}
];

export const USER_TYPE_OPTIONS = [
    {value: USER_TYPES.employee, label: USER_TYPE_LABELS[USER_TYPES.employee]},
    {value: USER_TYPES.broker, label: USER_TYPE_LABELS[USER_TYPES.broker]},
    {value: USER_TYPES.tpa, label: USER_TYPE_LABELS[USER_TYPES.tpa]},
    {value: USER_TYPES.other, label: USER_TYPE_LABELS[USER_TYPES.other]}
];

export const USER_ROLE_OPTIONS = [
    {value: USER_ROLES.admin, label: USER_ROLE_LABELS[USER_ROLES.admin]},
    {value: USER_ROLES.contributor, label: USER_ROLE_LABELS[USER_ROLES.contributor]},
    {value: USER_ROLES.viewer, label: USER_ROLE_LABELS[USER_ROLES.viewer]},
    {value: USER_ROLES.zendesk, label: USER_ROLE_LABELS[USER_ROLES.zendesk]}
];

export const IMPLEMENTING_OPTIONS = [
    {label: 'Existing Carrier', value: 'existing_carrier'},
    {label: 'New Carrier', value: 'new_carrier'}
];

export const FUNDING_STRUCTURE_OPTIONS = [
    {value: 'Self Funded', label: 'Self Funded'},
    {value: 'Fully Funded', label: 'Fully Funded'},
    {value: 'RBP', label: 'RBP'},
    {value: 'Level Funded', label: 'Level Funded'}
];

export const APPROXIMATION_OPTIONS = [
    {label: '0%', value: 0},
    {label: '25%', value: 25},
    {label: '50%', value: 50},
    {label: '75%', value: 75},
    {label: '100%', value: 100}
];

export const FIN_ACCOUNT_OPTIONS = [
    {label: FIN_ACCOUNT_TYPES.hsa, value: FIN_ACCOUNT_TYPES.hsa},
    {label: FIN_ACCOUNT_TYPES.fsa, value: FIN_ACCOUNT_TYPES.fsa},
    {label: FIN_ACCOUNT_TYPES.hra, value: FIN_ACCOUNT_TYPES.hra}
];

export const VENDOR_OPTIONS = [
    {value: 'AMPS', label: 'AMPS'},
    {value: 'HST', label: 'HST'},
    {value: 'ELAP', label: 'ELAP'},
    {value: 'PayerCompass', label: 'PayerCompass'},
    {value: 'Other', label: 'Other'}
];

export const ANALYTIC_OPTIONS = [
    {value: 'Springbuk', label: 'Springbuk'},
    {value: 'Deerwalk', label: 'Deerwalk'},
    {value: 'Other', label: 'Other'}
];

export const SYSTEM_NAME_OPTIONS = [
    {label: 'Benefit Administration Software', value: 'Benefit Administration Software'},
    {label: 'Client or Broker will get them from the Carrier or TPA and upload them as a spreadsheet to the HealthJoy Dashboard', value: 'Carrier or TPA'},
    {label: 'We will provide a spreadsheet', value: 'Spreadsheet'},
    {label: 'Employees will take a picture of their ID\'s in the HealthJoy app', value: 'Employees will take a picture of their ID\'s in the HealthJoy app'},
    {label: 'Other', value: 'other'}
];

export const IDENTIFICATION_OPTIONS = [
    {value: 'Social Security Number', label: 'Social Security Number'},
    {value: 'Group ID', label: 'Group ID'},
    {value: 'other', label: 'Other'}
];

export const WALLET_VISIBILITY_OPTIONS = [
    {label: 'All employees', value: 'except_cobra'},
    {label: 'All employees and COBRA', value: 'all'},
    {label: 'Only COBRA members', value: 'only_cobra'},
    {label: 'Enrollments for this benefit will be populated on the eligibility file', value: 'file'},
    {label: 'All employees in certain benefit classes', value: 'classes'}
];

export const ENROLMENT_OPTIONS = [
    {label: 'Benefit Administration Software', value: 'benadmin'},
    {label: 'Client or Broker will get enrollments from the carriers and upload them as a spreadsheet to the Dashboard', value: 'tpa'},
    {label: 'We will provide a spreadsheet', value: 'spreadsheet'},
    {label: 'Other', value: 'other'}
];

export const ENROLMENT_SOURCE_OPTIONS = [
    {value: 'ben_admin', label: 'Benefit Administration Software'},
    {value: 'spreadsheet', label: 'Updating a Spreadsheet'},
    {value: 'employee_navigator', label: 'Employee Navigator'},
    {value: 'other', label: 'Other'}
];

export const MEDICAL_IDS_PROVIDING_FILE = [
    {value: ELIGIBILITY_FILES_SOURCES.upload, label: 'Uploading documents to the Dashboard'},
    {value: ELIGIBILITY_FILES_SOURCES.sftp, label: 'SFTP'}
];

export const ELIGIBILITY_PROVIDING_FILE = [
    ...MEDICAL_IDS_PROVIDING_FILE,
    {value: ELIGIBILITY_FILES_SOURCES.employeeNavigator, label: 'Employee Navigator'}
];

export const ELIGIBLE_OPTIONS = [
    {value: 'all', label: 'All Employees in file'},
    {value: 'medical', label: 'Employees enrolled in medical plan only'},
    {value: 'telemedicine', label: 'Employees enrolled in telemedicine ancillary plan'},
    {value: 'other', label: 'Other'}
];

export const UNIQUE_MEMBER_IDENTIFIER_OPTIONS = [
    {value: 'ssn', label: 'SSN as an Individual ID (the most preferred)'},
    {value: 'email', label: 'Email'},
    {value: 'phone', label: 'Phone number'},
    {value: 'name_dob', label: 'First name, last name and DOB combination'},
    {value: 'other', label: 'Other'}
];

export const COBRA_SENDING_OPTIONS = [
    {value: 'Benefit Administration Software', label: 'Benefit Administration Software'},
    {value: 'Carrier or TPA', label: 'Client or Broker will get them from the Carrier or TPA and upload them as a spreadsheet to the HealthJoy Dashboard'},
    {value: 'Spreadsheet', label: 'We will provide a spreadsheet'},
    {value: 'other', label: 'Other'}
];

export const ELIGIBILITY_UPDATE_FREQUENCY_OPTIONS = [
    {value: 'Monthly', label: 'Monthly'},
    {value: 'Weekly', label: 'Weekly'},
    {value: 'Other', label: 'Other'}
];

export const ACCESS_RESTRICTION_TO_HJ_OPTIONS = [
    {value: ACCESS_RESTRICTION_TO_HJ_TYPES.notAppear, label: 'They will no longer appear in the eligibility file or census'},
    {value: ACCESS_RESTRICTION_TO_HJ_TYPES.terminationDate, label: 'They will have a termination date in the eligibility file or census'},
    {value: ACCESS_RESTRICTION_TO_HJ_TYPES.other, label: 'Other'}
];

export const ALLOW_PROFILE_UPDATES_OPTIONS = [
    {value: true, label: 'HealthJoy should allow members to update their data. Those changes will not be overwritten by ongoing eligibility updates. Member change files will be reported back to the client'},
    {value: false, label: 'HealthJoy should not allow members to update their data. Direct them to HR to get it updated via new eligibility file'}
];

export const INVITATION_OPTIONS = [
    {value: 'On the Start Date listed in the file', label: 'On the Start Date listed in the file'},
    {value: 'On the day that the Member appears in the file', label: 'On the day that the Member appears in the file'}
];

export const MEDICAL_ID_FREQUENCY_OPTIONS = [
    {value: 'Only at plan renewals', label: 'Only at plan renewals'},
    {value: 'Quarterly', label: 'Quarterly'},
    {value: 'Monthly', label: 'Monthly'},
    {value: 'Other', label: 'Other'}
];

export const PLAN_TYPE_OPTIONS = [
    {label: 'PPO', value: 'PPO'},
    {label: 'HMO', value: 'HMO'},
    {label: 'EPO', value: 'EPO'},
    {label: 'POS', value: 'POS'},
    {label: 'Indemnity', value: 'Indemnity'},
    {label: 'STM', value: 'STM'}
];

export const MEDICAL_PLAN_TYPE_OPTIONS = [
    ...PLAN_TYPE_OPTIONS,
    {label: 'HDHP', value: 'HDHP'},
    {label: 'POS+', value: 'POS+'},
    {label: 'MSA', value: 'MSA'},
    {label: 'PACE', value: 'PACE'}
];

export const PLAN_ADVANCED_CUSTOMIZATION_SECTION_OPTIONS = [
    {
        label: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS[PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.text],
        value: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.text
    },
    {
        label: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS[PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.bulleted_list],
        value: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.bulleted_list
    },
    {
        label: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS[PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.numberedList],
        value: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.numberedList
    },
    {
        label: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS[PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.twoColumnTable],
        value: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.twoColumnTable
    }
];

export const MEMBER_SERVICE_UTILIZATION_OPTIONS = [
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.benefitsSupportUtilization, label: 'Benefits Support Interactions'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.benefitsWalletUtilization, label: 'Benefits Wallet Views'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.mbr, label: 'Medical Bill Reviews'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.telemedConsults, label: 'MeMD Consults'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.preCertificationSteerage, label: 'Pre-certification Steerage'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.mskEnrollments, label: 'MSK Therapy Program Enrollments'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.providerFacilityGuidance, label: 'Provider & Facility Guidance'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.rxSavingsReviews, label: 'Rx Savings Requests'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.teladocHealthConsults, label: 'Teladoc Health Consults'},
    {value: MEMBER_SERVICE_UTILIZATION_TYPES.totalUtilization, label: 'Total Service Requests'}
];

export const REPORTING_PLAN_PERIOD_OPTIONS = [
    {label: 'Current Plan Year', value: REPORTING_PLAN_PERIODS.year},
    {label: 'All Time', value: REPORTING_PLAN_PERIODS.all}
];

export const FAMILY_SEGMENT_OPTIONS = [
    {label: 'Primary activated', value: FAMILY_SEGMENTS.primary},
    {label: 'Primary + Spouse activated', value: FAMILY_SEGMENTS.primarySpouse},
    {label: 'Primary + Spouse + Dependent activated', value: FAMILY_SEGMENTS.all}
];

export const CONDITIONS_MATCHING_OPTIONS = [
    {label: 'All', value: VARIABLE_CONDITIONS_OPERATORS.and},
    {label: 'At least one', value: VARIABLE_CONDITIONS_OPERATORS.or}
];

export const CONDITIONS_TYPE_OPTIONS = [
    {label: 'Set default variable', value: AVAILABILITY_CONDITIONS_TYPE.variable},
    {label: 'Set conditional content', value: AVAILABILITY_CONDITIONS_TYPE.conditions}
];

export const VARIABLE_CONDITION_OPTIONS = [
    {label: 'is equal to', value: 'equal'},
    {label: 'is not equal to', value: 'not_equal'},
    {label: 'exists', value: 'exists'},
    {label: 'does not exist', value: 'not_exist'}
];

const INITIAL_PLAN_YEAR = 2015;
export const PLAN_YEAR_OPTIONS = getUnfilledArray(getYear(new Date()) + 1 - (INITIAL_PLAN_YEAR - 1)) // FYI: All years started from 2015 (inclusive) to current year + 1 (Vlad, 25.03.20)
    .map((unfilledItem, index) => ({label: `${INITIAL_PLAN_YEAR + index}`, value: INITIAL_PLAN_YEAR + index}))
    .reverse();

export const PLAN_KIND_OPTIONS = [
    {label: 'Medical', value: 'medical'},
    {label: 'Dental', value: 'dental'},
    {label: 'Vision', value: 'vision'}
];

export const STATES_OPTIONS = STATES.map(([value, label]) => ({value, label}));

export const TIMELINE_TEMPLATE_TYPES_OPTIONS = [
    {label: 'Implementation', value: TIMELINE_TEMPLATE_TYPES.implementation},
    {label: 'Renewal', value: TIMELINE_TEMPLATE_TYPES.renewal}
];

export const TIMELINE_TEMPLATE_STATUS_OPTIONS = [
    {label: 'Active', value: TIMELINE_TEMPLATE_STATUSES.active},
    {label: 'Inactive', value: TIMELINE_TEMPLATE_STATUSES.inactive}
];

export const BH_ELIGIBILITY_OPTIONS = [
    {label: 'All Members', value: 'all_members'},
    {label: 'HSA Only', value: 'hsa_only'}
];

export const MBR_ELIGIBILITY_OPTIONS = [
    {label: 'All Members', value: 'all_members'},
    {label: 'Medical Plan Enrollment', value: 'medical_plan_enrollment'},
    {label: 'Non HDHP Members', value: 'non_hdhp_members'}
];

export const SURGERY_PLUS_ELIGIBILITY_OPTIONS = [
    {label: 'All Members', value: 'all_members'},
    {label: 'Medical Plan Enrollment', value: 'medical_plan_enrollment'}
];

export const EAP_ELIGIBILITY_OPTIONS = [
    {label: 'Only COBRA', value: 'Only COBRA'},
    {label: 'Disabled', value: 'Disabled'},
    {label: 'All Employees without COBRA', value: 'All employees without COBRA'},
    {label: 'All Employees & COBRA', value: 'All employees & COBRA'}
];

export const MSK_ACCESS_MANAGEMENT_OPTIONS = [
    {label: 'All Members', value: 'all_members'},
    {label: 'Medical Plan Enrollment', value: 'medical_plan_enrollment'},
    {label: 'Non HDHP Members', value: 'non_hdhp_members'}
];

export const TELEMED_ELIGIBILITY_OPTIONS = [
    {label: 'All Members', value: 'all_members'},
    {label: 'Medical Plan Enrollment', value: 'medical_plan_enrollment'},
    {label: 'HSA Only', value: 'hsa_only'}
];

export const TELEMED_PROVIDER_OPTIONS = [
    {label: 'Walmart Health', value: 'memd'},
    {label: 'UCM', value: 'ucm'}
];

export const TELEMED_UTILIZATION_REPORT_OPTIONS = [
    {label: 'null', value: null},
    {label: '837', value: '837'},
    {label: 'CSV', value: 'csv'},
    {label: 'Healthscope Benefits', value: 'healthscope_benefits'}
];

export const PLATFORM_AUTOMATION_OPTIONS = [
    {label: 'SFTP Standard - Fully Automated', value: PLATFORM_AUTOMATION_TYPES['SFTP Standard - Fully Automated']},
    {label: 'SFTP Standard - Semi-Automated', value: PLATFORM_AUTOMATION_TYPES['SFTP Standard - Semi-Automated']},
    {label: 'SFTP Custom', value: PLATFORM_AUTOMATION_TYPES['SFTP Custom']},
    {label: 'Non-SFTP Standard', value: PLATFORM_AUTOMATION_TYPES['Non-SFTP Standard']},
    {label: 'Non-SFTP Custom', value: PLATFORM_AUTOMATION_TYPES['Non-SFTP Custom']},
    {label: 'On Hold', value: PLATFORM_AUTOMATION_TYPES['On Hold']}
];

export const IMPORT_FILE_SOURCE_OPTIONS = [
    {label: 'SFTP', value: 'sftp'},
    {label: 'EEN', value: 'een'}
];

export const SFTP_HOST_OPTIONS = [
    {label: HJ_SFTP_HOST, value: SFTP_HOST_TYPES.internal},
    {label: 'sftp-tpa.healthjoy.com', value: SFTP_HOST_TYPES.tpa},
    {label: 'Add External Host', value: SFTP_HOST_TYPES.external}
];

export const SYNC_DEDUCTIBLE_TYPES_OPTIONS = [
    {label: 'Change Healthcare', value: 'pokitdok'},
    {label: 'SFTP', value: 'sftp'}
];

export const FAMILY_MANAGEMENT_OPTIONS = [
    {label: 'Full Access', value: FAMILY_MANAGEMENT_TYPES.fullAccess},
    {label: 'Eligible', value: FAMILY_MANAGEMENT_TYPES.eligible},
    {label: 'Disabled', value: FAMILY_MANAGEMENT_TYPES.disabled}
];

export const FIND_CARE_VENDOR_OPTIONS = [
    {label: 'Ideon', value: 'ideon'},
    {label: 'Ribbon', value: 'ribbon'}
];

export const EXPLANATION_OPTIONS = [
    {label: 'Please Select', value: null},
    {label: 'Preauthorization may be required. Please refer to your Plan Description.', value: 'Preauthorization may be required. Please refer to your Plan Description.'},
    {label: 'Not all services are considered preventive. Contact your insurance plan to determine if your visit will be considered preventive.', value: 'Not all services are considered preventive. Contact your insurance plan to determine if your visit will be considered preventive.'},
    {label: 'Limitations may apply. Please refer to your Plan Description.', value: 'Limitations may apply. Please refer to your Plan Description.'},
    {label: 'Copay waived if admitted.', value: 'Copay waived if admitted.'},
    {label: 'Out-of-network service is covered as in-network.', value: 'Out-of-network service is covered as in-network.'},
    {label: 'Included services and/or service locations are limited. Please refer to your Plan Description.', value: 'Included services and/or service locations are limited. Please refer to your Plan Description.'},
    {label: 'In-network deductible applies.', value: 'In-network deductible applies.'},
    {label: 'Other', value: ''}
];

export const ELIGIBILITY_OPTIONS = [
    {label: 'All Members', value: TELADOC_ELIGIBILITY_TYPES.allMembers},
    {label: 'HDHP Only', value: TELADOC_ELIGIBILITY_TYPES.HDHPOnly},
    {label: 'Medical Plan Enrollment', value: TELADOC_ELIGIBILITY_TYPES.medicalPlanEnrollment}
];

export const ELIGIBILITY_SEGMENT_OPTIONS = [
    {label: 'All Members', value: true},
    {label: 'Other', value: false}
];

export const CARD_ACTION_OPTIONS = [
    {label: 'Custom Page', value: RECOMMENDATION_CARD_ACTIONS.customPage},
    {label: 'Application Flow', value: RECOMMENDATION_CARD_ACTIONS.applicationFlow}
];

export const PREFILLED_FIND_CARE_OPTIONS = [
    {label: 'Procedure', value: 'procedure'},
    {label: 'Specialty', value: 'specialty'}
];

export const FONT_SCALE_OPTIONS = [
    {label: 'Standard', value: 1},
    {label: 'Small', value: 0.86},
    {label: 'Large', value: 1.29}
];

export const IMPORT_CONFIG_STATUS_OPTIONS = [
    {label: 'Launch Config', value: IMPORT_VERSIONS_CONFIG_TYPES['New Launch']},
    {label: 'Maintain Config', value: IMPORT_VERSIONS_CONFIG_TYPES.Maintenance},
    {label: 'Renewal Config', value: IMPORT_VERSIONS_CONFIG_TYPES.Renewal}
];

export const BROADCAST_USA_TIMEZONES_OPTIONS = [
    {label: '(UTC-05:00) Eastern Time - New York', value: '05:00'},
    {label: '(UTC-06:00) Central Time - Chicago', value: '06:00'},
    {label: '(UTC-07:00) Mountain Time - Denver', value: '07:00'},
    {label: '(UTC-08:00) Pacific Time - Los Angeles', value: '08:00'},
    {label: '(UTC-09:00) Alaska Time', value: '09:00'},
    {label: '(UTC-10:00) Hawaii', value: '10:00'}
];
