import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Text from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import {Field, Form} from '../../../shared/FormComponents';
import {requestImportsCompanyConfigCreating, requestImportsCompanyConfigUpdating} from '../../../../actions/adminPortal';
import {getProfileInfo} from '../../../../selectors/general';
import {FORMS} from '../../../../constants';
import {formatDate, validateInt} from '../../../../utils';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    const validateTerminationThreshold = value => validateInt(Number(value), {from: 0, to: 99}, 'Please enter a number greater than or equal to 0');
    return {
        custom_termination_threshold: {
            new_launch: validateTerminationThreshold(values.custom_termination_threshold.new_launch),
            maintenance: validateTerminationThreshold(values.custom_termination_threshold.maintenance),
            renewal: validateTerminationThreshold(values.custom_termination_threshold.renewal)
        }
    };
};

const EditTerminationsPopup = ({groupAlias, companyConfig, closePopup, onSuccess, isRenewal}) => {
    const dispatch = useDispatch();
    const {full_name: userFullName} = useSelector(getProfileInfo);
    const {updated_by: updatedBy, updated_at: updatedAt} = companyConfig?.custom_termination_threshold || {};

    const onSubmit = useCallback(async ({id, ...values}) => {
        const enhancedValues = {
            ...values,
            custom_termination_threshold: {
                ...values.custom_termination_threshold,
                updated_at: Date.now(),
                updated_by: userFullName
            }
        };
        const requestFunction = id ? requestImportsCompanyConfigUpdating : requestImportsCompanyConfigCreating;
        const {data, isSuccess, submissionErrors} = await dispatch(requestFunction(enhancedValues));

        if (!isSuccess) {
            return submissionErrors;
        }

        closePopup();
        onSuccess(data);
    }, [dispatch, onSuccess, closePopup, userFullName]);

    return (
        <Form name={FORMS.editTerminationsThreshold} initialValues={companyConfig} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, dirty}) => {
                const actionBar = (
                    <React.Fragment>
                        <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                        <Button disabled={!dirty} type={BUTTON_TYPES.primary} onClick={handleSubmit}>Save Changes</Button>
                    </React.Fragment>
                );
                const popupContent = (
                    <React.Fragment>
                        <Text>
                            {updatedBy || updatedAt
                                ? `Last updated ${updatedBy ? `by ${updatedBy}` : ''} ${updatedAt ? formatDate(updatedAt, 'MM/dd/yyyy') : ''}`
                                : 'Last updated by system'}
                        </Text>

                        <div className='mt-5 terminations-threshold'>
                            <Text className='terminations-threshold__type'>Launch</Text>
                            <div className='terminations-threshold__field-wrapper'>
                                <Field name='custom_termination_threshold.new_launch'>{props => <Input {...props} type='number' maxLength={2} isMaxLengthDisplayed={false} placeholder='10' className='terminations-threshold__field'/>}</Field>
                                <Text className='terminations-threshold__percentage'>%</Text>
                            </div>
                        </div>

                        <div className='mt-5 terminations-threshold'>
                            <Text className='terminations-threshold__type'>Maintenance</Text>
                            <div className='terminations-threshold__field-wrapper'>
                                <Field name='custom_termination_threshold.maintenance'>{props => <Input {...props} type='number' maxLength={2} isMaxLengthDisplayed={false} placeholder='10' className='terminations-threshold__field'/>}</Field>
                                <Text className='terminations-threshold__percentage'>%</Text>
                            </div>
                        </div>

                        {isRenewal && (
                            <div className='mt-5 terminations-threshold'>
                                <Text className='terminations-threshold__type'>Renewal</Text>
                                <div className='terminations-threshold__field-wrapper'>
                                    <Field name='custom_termination_threshold.renewal'>{props => <Input {...props} type='number' maxLength={2} isMaxLengthDisplayed={false} placeholder='10' className='terminations-threshold__field'/>}</Field>
                                    <Text className='terminations-threshold__percentage'>%</Text>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                );
                const popupProps = {title: `Edit ${groupAlias} Terminations Threshold`, children: popupContent, actionBar};

                return <PopupContent {...popupProps}/>;
            }}
        </Form>
    );
};

EditTerminationsPopup.propTypes = {
    isRenewal: PropTypes.bool,
    groupAlias: PropTypes.string,
    companyConfig: PropTypes.shape({
        custom_termination_threshold: PropTypes.shape({
            maintenance: PropTypes.number,
            renewal: PropTypes.number,
            updated_at: PropTypes.string,
            updated_by: PropTypes.string
        })
    }),
    closePopup: PropTypes.func,
    onSuccess: PropTypes.func
};

export {EditTerminationsPopup as TestableEditTerminationsPopup};
export default React.memo(EditTerminationsPopup);
