import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import FileSource, {validateOnSubmit as validateFileSourceOnSubmit} from '../FileSource';
import {Field} from '../../shared/FormComponents';
import withEligibilityDetailsForm from '../../../HOC/withEligibilityDetailsForm';

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateFileSourceOnSubmit(values)});

const Custom = ({isReadonly}) => {
    return (
        <div className='eligibility-details-form custom-file'>
            <Separator/>

            <Row className='eligibility-details-form-row'>
                <Column className='eligibility-details-form-row__column' sm>
                    <Row className='eligibility-details-form-row'>
                        <Column className='eligibility-details-form-row__column' sm={6}>
                            <FileSource isReadonly={isReadonly}/>
                        </Column>

                        <Column className='eligibility-details-form-row__column' sm={6}>
                            <Field name='description'>
                                {props => (
                                    <Textarea {...props}
                                        readonly={isReadonly}
                                        className='custom-file__description'
                                        maxLength={500}
                                        placeholder='Please describe file content...'
                                        label='Short description'/>
                                )}
                            </Field>
                        </Column>
                    </Row>
                </Column>
            </Row>
        </div>
    );
};

Custom.propTypes = {
    isReadonly: PropTypes.bool.isRequired
};

export {Custom as TestableCustom};
export default withEligibilityDetailsForm({validateOnSubmit})(Custom);
