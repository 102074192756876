import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Field} from '../../shared/FormComponents';
import {getIsCopyingEnabled} from '../../../selectors/benefits';
import {isEmpty} from '../../../utils';

const CopyableField = ({children, ...props}) => {
    const isCopyingEnabled = useSelector(getIsCopyingEnabled);

    return (
        <Field {...props}>
            {props => {
                const isFieldHighlighted = isCopyingEnabled && isEmpty(props.value);
                const highlightType = isFieldHighlighted ? 'success' : 'none';

                return children({...props, highlightType});
            }}
        </Field>
    );
};

CopyableField.propTypes = {
    children: PropTypes.func.isRequired
};

export {CopyableField as TestableCopyableField};
export default React.memo(CopyableField);
