import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CoverPage from '../CoverPage';
import ContentsPage from '../ContentsPage';
import PerformanceSummary from '../PerformanceSummary';
import ProgramSavingsOverview from '../ProgramSavingsOverview';
import ProgramSavingsOverviewContinued from '../ProgramSavingsOverviewContinued';
import ProgramSavingsFormula from '../ProgramSavingsFormula';
import {
    ProgramSavingsFormulaContinuedFirst,
    ProgramSavingsFormulaContinuedSecond,
    ProgramSavingsFormulaContinuedThird,
    ProgramSavingsFormulaContinuedFourth,
    ProgramSavingsFormulaContinuedFifth
} from '../ProgramSavingsFormulaContinued';
import MemberUtilization from '../MemberUtilization';
import MemberUtilizationContinued from '../MemberUtilizationContinued';
import ServiceUtilization from '../ServiceUtilization';
import {
    ServiceUtilizationContinuedFirst,
    ServiceUtilizationContinuedSecond,
    ServiceUtilizationContinuedThird
} from '../ServiceUtilizationContinued';
import Glossary from '../Glossary';
import {GlossaryContinuedFirst, GlossaryContinuedSecond} from '../GlossaryContinued';
import Page from '../Page';
import {requestPdfReportInfo} from '../../../actions/pdfTemplate';
import {getPdfTemplateCompanyData, getPdfTemplateMetrics} from '../../../selectors/pdfTemplate';
import {CONTENTS_DESCRIPTION, REPORT_FULL_NAME, REPORT_NAME, REPORT_NOTATION, CONTENTS} from './constants';
import {isEmpty} from '../../../utils';
import {PDF_TEMPLATE_TYPES} from '../../../constants';

const PAGES_SHIFT = 2;

const PerformanceReport = () => {
    const companyData = useSelector(getPdfTemplateCompanyData);
    const metrics = useSelector(getPdfTemplateMetrics);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(requestPdfReportInfo(PDF_TEMPLATE_TYPES.performanceReport));
    }, [dispatch]);

    const getPage = (content, index, arr) => {
        const {company_name: companyName} = companyData;
        const lastPageIndex = arr.length - 1;
        const isLast = index === lastPageIndex;

        return (
            <Page key={index}
                pageNum={PAGES_SHIFT + index}
                title={`${REPORT_NAME} | ${companyName}`}
                notation={REPORT_NOTATION}
                isLast={isLast}>
                {content}
            </Page>
        );
    };

    const {
        report_start_date: startDate,
        report_end_date: endDate,
        launch_date: launchDate,
        company_name: companyName,
        show_roi: isRoiVisible,
        show_telemed: isTelemedVisible,
        show_teladoc_general_medical: isTeladocGeneralMedicalVisible,
        show_teladoc_mental_health: isTeladocMentalHealthVisible,
        show_mbr: isMedicalBillVisible,
        show_msk: isMskVisible,
        show_precert: isPreCertificationVisible,
        show_find_care: isFindCareVisible
    } = companyData;
    const isTeladocVisible = isTeladocGeneralMedicalVisible || isTeladocMentalHealthVisible;

    const coverPageProps = {
        title: REPORT_FULL_NAME,
        startDate,
        endDate,
        launchDate,
        companyName
    };

    const contentsProps = {
        title: REPORT_NAME,
        description: CONTENTS_DESCRIPTION,
        contents: CONTENTS
    };

    const performanceSummaryProps = {
        isRoiVisible,
        ...metrics.summary
    };

    const memberUtilizationProps = {...metrics.memberUtilization};

    const memberUtilizationContinuedProps = {
        isTelemedVisible,
        isMedicalBillVisible,
        ...metrics.memberUtilization
    };

    const programSavingsOverviewProps = {
        isRoiVisible,
        ...metrics.programSavings
    };

    const programSavingsOverviewContinuedProps = {
        isTelemedVisible,
        isTeladocVisible,
        isTeladocGeneralMedicalVisible,
        isTeladocMentalHealthVisible,
        isMedicalBillVisible,
        isMskVisible,
        isPreCertificationVisible,
        ...metrics.programSavings
    };

    const programSavingsFormulaProps = {isMedicalBillVisible, isMskVisible, isPreCertificationVisible, isFindCareVisible};

    const pages = [
        <ContentsPage {...contentsProps}/>,
        <PerformanceSummary {...performanceSummaryProps}/>,
        <ProgramSavingsOverview {...programSavingsOverviewProps}/>,
        <ProgramSavingsOverviewContinued {...programSavingsOverviewContinuedProps}/>,
        <ProgramSavingsFormula {...programSavingsFormulaProps}/>,
        <ProgramSavingsFormulaContinuedFirst/>,
        <ProgramSavingsFormulaContinuedSecond/>,
        <ProgramSavingsFormulaContinuedThird/>,
        <ProgramSavingsFormulaContinuedFourth isTelemedVisible={isTelemedVisible} isTeladocGeneralMedicalVisible={isTeladocGeneralMedicalVisible} isTeladocMentalHealthVisible={isTeladocMentalHealthVisible}/>,
        <ProgramSavingsFormulaContinuedFifth/>,
        <MemberUtilization {...memberUtilizationProps}/>,
        <MemberUtilizationContinued {...memberUtilizationContinuedProps}/>,
        <ServiceUtilization {...metrics.serviceUtilization} isTelemedVisible={isTelemedVisible}/>,
        <ServiceUtilizationContinuedFirst {...metrics.serviceUtilization} isMedicalBillVisible={isMedicalBillVisible} isFindCareVisible={isFindCareVisible}/>,
        <ServiceUtilizationContinuedSecond {...metrics.serviceUtilization} isPreCertificationVisible={isPreCertificationVisible}/>,
        <ServiceUtilizationContinuedThird {...metrics.serviceUtilization} isMskVisible={isMskVisible} isTeladocVisible={isTeladocVisible} isTeladocGeneralMedicalVisible={isTeladocGeneralMedicalVisible} isTeladocMentalHealthVisible={isTeladocMentalHealthVisible}/>,
        <Glossary isTelemedVisible={isTelemedVisible} isMedicalBillVisible={isMedicalBillVisible}/>,
        <GlossaryContinuedFirst isMskVisible={isMskVisible} isPreCertificationVisible={isPreCertificationVisible} isTelemedVisible={isTelemedVisible}/>,
        <GlossaryContinuedSecond isTeladocVisible={isTeladocVisible}/>
    ];

    const isLoaded = !isEmpty(companyData);

    return (
        <React.Fragment>
            {isLoaded && (
                <div className='performance-report'>
                    <CoverPage {...coverPageProps}/>

                    {pages.map(getPage)}
                </div>
            )}
        </React.Fragment>
    );
};

export {PerformanceReport as TestablePerformanceReport};
export default PerformanceReport;
