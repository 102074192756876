import React from 'react';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Link from '@frontend/ui-kit/Components/Link';
import HJLogo from '../../../static/images/hjLogoPurple.svg';
import girlWithPaper from '../../../static/images/girlWithPaper.svg';
import chromeIcon from '../../../static/images/icons/chromeIcon.svg';
import edgeIcon from '../../../static/images/icons/edgeIcon.svg';
import firefoxIcon from '../../../static/images/icons/firefoxIcon.svg';
import safariIcon from '../../../static/images/icons/safariIcon.svg';
import './index.scss';

const RECOMMENDED_BROWSERS = [
    {name: 'Chrome', logo: chromeIcon, link: 'https://www.google.com/chrome'},
    {name: 'Edge', logo: edgeIcon, link: 'https://www.microsoft.com/en-us/edge'},
    {name: 'Firefox', logo: firefoxIcon, link: 'https://www.mozilla.org/en-US/firefox/new'},
    {name: 'Safari', logo: safariIcon, link: 'https://support.apple.com/downloads/safari'}
];

const IESunsetLayout = () => {
    return (
        <div className='ie-sunset-layout'>
            <div className='layout-header'>
                <img className='layout-header__logo' src={HJLogo} alt=''/>
            </div>

            <div className='layout-content'>
                <div className='info'>
                    <img className='info__image' src={girlWithPaper} alt=''/>

                    <Heading className='info__title' type={HEADING_TYPES['2']}>
                        Oh no! Your browser is no longer supported!
                    </Heading>

                    <Heading className='info__description' type={HEADING_TYPES['5']}>
                        We're sorry, but we no longer support your browser (Internet Explorer).
                        To continue using the HealthJoy Dashboard you'll need to access it via a different web browser.
                    </Heading>
                    <Heading className='info__recommendation' type={HEADING_TYPES['5']}>We recommend,</Heading>
                </div>

                <Row className='supported-browsers'>
                    {RECOMMENDED_BROWSERS.map(({name, logo, link}) => {
                        return (
                            <Column sm={3} key={name}>
                                <ContentSection className='browser'>
                                    <Link className='browser-link' href={link} target='_blank' rel='noreferrer'>
                                        <img className='browser-link__logo' src={logo} alt=''/>
                                        <Text className='browser-link__name'>{name}</Text>
                                    </Link>
                                </ContentSection>
                            </Column>
                        );
                    })}
                </Row>
            </div>

            <div className='layout-footer'>
                <Text>© HealthJoy</Text>
            </div>
        </div>
    );
};

export {IESunsetLayout as TestableIESunsetLayout};
export default IESunsetLayout;
