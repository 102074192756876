import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Tooltip, {TOOLTIP_DIRECTIONS} from '@frontend/ui-kit/Components/Tooltip';
import {REVERSED_UNITS} from '../../../constants';
import './index.scss';

const ReportBox = ({className, title, iconType, iconClassName, tooltipText, caption, description, value, unit, isPlaceholder}) => {
    const formattedValue = REVERSED_UNITS.includes(unit) ? `${unit}${value}` : `${value}${unit}`;

    return (
        <div className={`report-box ${className}`}>
            <Heading className='report-box-title' type={HEADING_TYPES['5']}>
                {title}
                {tooltipText && (
                    <Tooltip className='title-tooltip' content={tooltipText} direction={TOOLTIP_DIRECTIONS.right}>
                        <Icon className='title-tooltip__icon' type={ICON_TYPES.info}/>
                    </Tooltip>
                )}
            </Heading>

            <div className='report-box-value'>
                <Icon className={`report-box-value__icon report-box-value__icon_${iconType} ${iconClassName}`} type={iconType}/>

                <Heading className='report-box-value__value' type={HEADING_TYPES['2']}>
                    {isPlaceholder ? 'N/A' : formattedValue}
                </Heading>
            </div>

            <Text className='report-box__caption' type={TEXT_TYPES.caption}>{caption}</Text>

            <Text className='report-box__description' type={TEXT_TYPES.helper}>{description}</Text>
        </div>
    );
};

ReportBox.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.string,
    iconClassName: PropTypes.string,
    title: PropTypes.string,
    tooltipText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    caption: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    isPlaceholder: PropTypes.bool
};

ReportBox.defaultProps = {
    className: '',
    iconClassName: '',
    unit: '',
    isPlaceholder: false
};

export {ReportBox as TestableReportBox};
export default React.memo(ReportBox);
