import React from 'react';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import {Field} from '../../../shared/FormComponents';
import useFormState from '../../../../hooks/useFormState';
import {requestProcedures, requestSpecialties} from '../../../../actions/general';
import {equal, promisifyAsyncFunction} from '../../../../utils';
import {PREFILLED_FIND_CARE_ACTION_FLOW_TYPES} from '../../../../constants';
import {PREFILLED_FIND_CARE_OPTIONS} from '../../../../options';

const PrefilledFindCareActionFlow = () => {
    const dispatch = useDispatch();
    const {values} = useFormState();
    const {context} = values.action_flow;
    const {type, value} = context ?? {};

    const onChangeType = (_, form) => {
        if (value) {
            form.change('action_flow.context.value', null);
        }
    };

    const loadProcedureOptions = promisifyAsyncFunction(async query => {
        const {data} = await dispatch(requestProcedures({query}));

        return data.map(({name, id}) => ({label: name, value: id}));
    });

    const loadSpecialtyOptions = promisifyAsyncFunction(async query => {
        const {data} = await dispatch(requestSpecialties({query}));

        return data.map(({name, id}) => ({label: name, value: id}));
    });

    const asyncAutocompleteDefaultProps = {
        isCreatable: false,
        isRequired: true,
        formatValue: ({name, id}) => ({label: name, value: id}),
        parseValue: ({label, value}) => ({name: label, id: value})
    };

    return (
        <div>
            <Row>
                <Column sm={12} className='mt-16 mb-16'>
                    <Field name='action_flow.context.type' onChange={onChangeType}>
                        {props => <Select {...props} options={PREFILLED_FIND_CARE_OPTIONS} label='Specialty / Procedure' isRequired/>}
                    </Field>
                </Column>

                {equal(type, PREFILLED_FIND_CARE_ACTION_FLOW_TYPES.procedure) && (
                    <Column sm={12}>
                        <Field name='action_flow.context.value'>
                            {props => (
                                <AsyncAutocomplete {...props}
                                    {...asyncAutocompleteDefaultProps}
                                    data-testid='procedure-autocomplete'
                                    loadOptions={loadProcedureOptions}
                                    label='Type Procedure'/>
                            )}
                        </Field>
                    </Column>
                )}

                {equal(type, PREFILLED_FIND_CARE_ACTION_FLOW_TYPES.specialty) && (
                    <Column sm={12}>
                        <Field name='action_flow.context.value'>
                            {props => (
                                <AsyncAutocomplete {...props}
                                    {...asyncAutocompleteDefaultProps}
                                    data-testid='procedure-autocomplete'
                                    loadOptions={loadSpecialtyOptions}
                                    label='Type Specialty'/>
                            )}
                        </Field>
                    </Column>
                )}
            </Row>
        </div>
    );
};

export {PrefilledFindCareActionFlow as TestablePrefilledFindCareActionFlow};
export default PrefilledFindCareActionFlow;
