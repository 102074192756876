import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {isEmpty, equal, formatDate} from '../../../utils';
import './index.scss';

const fieldPlaceholder = <Text type={TEXT_TYPES.body}>Not Entered</Text>;

const getReason = (reason, index) => <div key={index}>{index + 1}. {reason}</div>;

const CompanyInfoSummary = ({company}) => {
    return (
        <Row className='company-info-summary'>
            <Column sm={6}>
                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Company Name:</Text></Column>
                    <Column sm={6}>
                        <Text type={TEXT_TYPES.bodyBold}>{company?.name || fieldPlaceholder}</Text>
                    </Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Corporate Address (HQ):</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{company.address || fieldPlaceholder}</Text></Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Industry:</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{company.industry || fieldPlaceholder}</Text></Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>General Phone Number:</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{company.phone_number || fieldPlaceholder}</Text></Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Uploaded Logo:</Text></Column>
                    <Column sm={6}>
                        {company.logo ? <img src={company.logo} className='company-info-summary-item__company-logo' alt=''/> : fieldPlaceholder}
                    </Column>
                </Row>
            </Column>

            <Column sm={6}>
                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Funding Structure:</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{company.funding_structure || fieldPlaceholder}</Text></Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>TPA or Carrier:</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{company.tpa || fieldPlaceholder}</Text></Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Renewal Date:</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{formatDate(company.renewal_date, 'M/d/yyyy') || fieldPlaceholder}</Text></Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Pharmacy Benefit Manager (PBM):</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{company.pbm || fieldPlaceholder}</Text></Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Are we implementing with the in-force carrier or a new one?</Text></Column>
                    <Column sm={6}>
                        <Text type={TEXT_TYPES.bodyBold}>
                            {equal(company.is_existing_carrier, 'existing_carrier') ? 'Existing Carrier' : 'New Carrier'}
                        </Text>
                    </Column>
                </Row>

                <Row className='company-info-summary-item'>
                    <Column sm={6}><Text>Primary reasons for using HealthJoy:</Text></Column>
                    <Column sm={6}>
                        <Text type={TEXT_TYPES.bodyBold}>
                            {!isEmpty(company.reasons) ? company.reasons.map(getReason) : fieldPlaceholder}
                        </Text>
                    </Column>
                </Row>
            </Column>
        </Row>
    );
};

CompanyInfoSummary.propTypes = {
    company: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        industry: PropTypes.string,
        phone_number: PropTypes.string,
        logo: PropTypes.string,
        funding_structure: PropTypes.string,
        tpa: PropTypes.string,
        renewal_date: PropTypes.string,
        pbm: PropTypes.string,
        is_existing_carrier: PropTypes.string,
        reasons: PropTypes.arrayOf(PropTypes.string)
    })
};

export {CompanyInfoSummary as TestableCompanyInfoSummary};
export default React.memo(CompanyInfoSummary);
