import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import {FieldArray} from '../../shared/FormComponents';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {getEqual, getItemKeyValue, promisifyAsyncFunction} from '../../../utils';
import {requestCompanyByAlias} from '../../../actions/general';
import {requestUsersByCompany} from '../../../actions/adminPortal';
import {showToast} from '../../../actions/shared';
import {APP_CONFIG_STATUSES} from '../../../constants';
import softLaunchPlaceholder from '../../../static/images/soft_launch_placeholder.svg';
import softLaunchEmptyPlaceholder from '../../../static/images/soft_launch_empty_placeholder.svg';

const PENDING_STATUS_DESCRIPTION = `
    If any changes are made to Pre-launch users after invites are scheduled, before Pre-launch Demo is active,
    the original invite will need to be removed and rescheduled to account for the update.
    Please note updates cannot be made after Pre-launch Demo is active.
`;
const ACTIVE_STATUS_DESCRIPTION = 'Pre-launch Demo is currently active; changes can no longer be made.';
const COMPLETED_STATUS_DESCRIPTION = 'Pre-launch Demo period has ended. No changes or edits can be made to soft launch users.';

const SoftLaunch = ({isEditDisabled, status}) => {
    const dispatch = useDispatch();
    const form = useForm();
    const {values} = useFormState();
    const {companyAlias} = useParams();
    const [companyId, setCompanyId] = useState(null);
    const [isInactive, isPending, isActive, isCompleted] = [
        APP_CONFIG_STATUSES.inactive,
        APP_CONFIG_STATUSES.pending,
        APP_CONFIG_STATUSES.active,
        APP_CONFIG_STATUSES.completed
    ].map(getEqual(status));
    const areEligibleUsers = !!values.eligible_users_count;

    const onChangeUserList = fields => value => fields.push(value);

    const loadUserOptions = promisifyAsyncFunction(async query => {
        const {values} = form.getState();
        const {users} = await dispatch(requestUsersByCompany(companyId, query));

        const excludedUserIds = values.soft_launch_users?.map(getItemKeyValue('id')) ?? [];
        const filteredUsers = users.filter(({id}) => !excludedUserIds.includes(id));

        return filteredUsers.map(({display, id, full_name, email}) => ({label: display, value: {id, full_name, email}}));
    });

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestCompanyByAlias(companyAlias));

            setCompanyId(data.id);
        })();
    }, [companyAlias, dispatch]);

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={6}>
                        <DatePicker value={values.start_date} label='Pre-launch Demo Start Date' description='This field is populated from Salesforce' placeholder='MM/DD/YYYY' disabled/>
                    </Column>
                    <Column sm={6}>
                        <DatePicker value={values.end_date} label='Full Launch Date' description='This field is populated from Company Manager' placeholder='MM/DD/YYYY' disabled/>
                    </Column>
                </Row>
            </ContentSection>

            <ContentSection className='app-configuration-content__section mt-20'>
                {isPending && <Alert className='mb-12' type={ALERT_TYPES.info} description={PENDING_STATUS_DESCRIPTION}/>}
                {isActive && <Alert className='mb-12' type={ALERT_TYPES.danger} description={ACTIVE_STATUS_DESCRIPTION}/>}
                {isCompleted && <Alert className='mb-12' type={ALERT_TYPES.danger} description={COMPLETED_STATUS_DESCRIPTION}/>}

                <Heading className='app-configuration-content__title'>Pre-launch Demo Users</Heading>

                <FieldArray name='soft_launch_users'>
                    {({fields}) => {
                        const isAutocomplete = companyId && (isInactive || isPending);
                        const isPlaceholder = !fields.length;

                        return (
                            <React.Fragment>
                                {isAutocomplete && (
                                    <AsyncAutocomplete wrapperClassName='mb-12'
                                        disabled={isEditDisabled || !areEligibleUsers}
                                        placeholder='Search by name or email to add'
                                        onChange={onChangeUserList(fields)}
                                        loadOptions={loadUserOptions}
                                        isCreatable={false}/>
                                )}

                                {isPlaceholder && (
                                    <React.Fragment>
                                        {!areEligibleUsers && (
                                            <div className='soft-launch-placeholder'>
                                                <img src={softLaunchEmptyPlaceholder} alt='' className='mr-24'/>
                                                <Text>The eligibility file must be imported first in order to search and add Pre-launch users.</Text>
                                            </div>
                                        )}
                                        {areEligibleUsers && (
                                            <div className='soft-launch-placeholder'>
                                                <img src={softLaunchPlaceholder} alt='' className='mr-24'/>
                                                <Text>Search by name or email to add Pre-launch users</Text>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}

                                {!isPlaceholder && (
                                    <div className='soft-launch-users'>
                                        {fields.map((field, index) => {
                                            const {full_name: fullName, email} = fields.value[index];
                                            const onDelete = () => {
                                                fields.remove(index);
                                                dispatch(showToast({content: `${fullName} removed from Pre-launch Users list`}));
                                            };

                                            return (
                                                <div className='soft-launch-users__item mb-5'>
                                                    <Row middle='sm'>
                                                        <Column sm={5}>{fullName}</Column>
                                                        <Column sm={5}>{email}</Column>
                                                        <Column sm={2}>
                                                            {isPending && (
                                                                <Button type={BUTTON_TYPES.tertiaryDestructive}
                                                                    iconLeft={<Icon type={ICON_TYPES.close}/>}
                                                                    onClick={onDelete}>
                                                                    Remove
                                                                </Button>
                                                            )}
                                                        </Column>
                                                    </Row>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    }}
                </FieldArray>
            </ContentSection>
        </div>
    );
};

SoftLaunch.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired
};

export {SoftLaunch as TestableSoftLaunch};
export default SoftLaunch;
