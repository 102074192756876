import React from 'react';
import PropTypes from 'prop-types';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';

const SuccessfulPlanCopyingAlert = ({copiedPlanName, ...props}) => {
    return <Alert type={ALERT_TYPES.info} description={`You've successfully made a copy of a ${copiedPlanName} plan! Don't forget to update the plan details with the correct information.`} {...props}/>;
};

SuccessfulPlanCopyingAlert.propTypes = {
    copiedPlanName: PropTypes.string
};

export {SuccessfulPlanCopyingAlert as TestableSuccessfulPlanCopyingAlert};
export default SuccessfulPlanCopyingAlert;
