import {getIn} from 'final-form';
import {isEmptyNested, scrollToComponent, isFunction} from '../../../../utils';

export default form => {
    const scrollToFirstError = errors => {
        const inputs = [...document.forms].reduce((acc, form) => [...acc, ...form], []);
        const firstErrorInput = inputs.find(({name}) => name && getIn(errors, name));

        if (!firstErrorInput) {
            return false;
        }

        scrollToComponent(firstErrorInput.closest('[class^="enhanced-field-wrapper"]'));
    };

    let state = {};
    const onSubscribe = nextState => {
        state = nextState;
    };
    const unsubscribe = form.subscribe(onSubscribe, {errors: true, submitErrors: true});

    const afterSubmit = () => {
        const {errors, submitErrors} = state;

        // Nested array will have array of undefined
        if (!isEmptyNested(errors)) {
            scrollToFirstError(errors);
        }
        if (!isEmptyNested(submitErrors)) {
            scrollToFirstError(submitErrors);
        }
    };

    const originalSubmit = form.submit;
    const enhancedSubmit = () => {
        const result = originalSubmit(form);

        if (isFunction(result?.then)) {
            result.then(afterSubmit, () => {});
        } else {
            afterSubmit();
        }

        return result;
    };

    // eslint-disable-next-line no-param-reassign
    form.submit = enhancedSubmit;

    return () => {
        unsubscribe();
        // eslint-disable-next-line no-param-reassign
        form.submit = originalSubmit;
    };
};
