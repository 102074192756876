import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {Form} from '../FormComponents';
import CardAvailabilityCondition, {validate as validateCardAvailabilityCondition} from '../CardAvailabilityCondition';
import {FORMS, VARIABLE_CONDITIONS_MATCHING, VARIABLE_CONDITIONS_OPERATORS} from '../../../constants';
import {isEmpty} from '../../../utils';

/* istanbul ignore next */
const validate = values => ({...validateCardAvailabilityCondition(values)});

const CardAvailabilityConditionPopup = ({planId, cardView, onCancel, onSave, requestConditionalVariablesSetting}) => {
    const {conditions, id} = cardView;
    const {operator, settings} = conditions || {};
    const initialValues = useMemo(() => ({
        id,
        conditions: {
            operator: operator ?? VARIABLE_CONDITIONS_OPERATORS.and,
            settings: isEmpty(settings) ? [{operator: VARIABLE_CONDITIONS_MATCHING.exists}] : settings
        }
    }), [id, operator, settings]);

    const onSubmit = useCallback(async values => {
        const {data, isSuccess, submissionErrors} = await requestConditionalVariablesSetting(planId, values);

        if (!isSuccess) {
            return submissionErrors;
        }

        onSave(data);
    }, [planId, onSave, requestConditionalVariablesSetting]);

    return (
        <Form name={FORMS.conditionalVariables} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit}) => {
                const actionBar = (
                    <React.Fragment>
                        <Button onClick={onCancel} type={BUTTON_TYPES.secondary}>Cancel</Button>
                        <Button type={BUTTON_TYPES.primary} onClick={handleSubmit}>Save</Button>
                    </React.Fragment>
                );
                const popupContentProps = {title: 'Set Card’s availability condition', actionBar};

                return (
                    <form noValidate data-testid='card-availability-condition-form'>
                        <PopupContent {...popupContentProps}>
                            <CardAvailabilityCondition/>
                        </PopupContent>
                    </form>
                );
            }}
        </Form>
    );
};

CardAvailabilityConditionPopup.propTypes = {
    planId: PropTypes.number,
    cardView: PropTypes.shape({
        id: PropTypes.number,
        conditions: PropTypes.shape({operator: PropTypes.string, settings: PropTypes.array})
    }),
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    requestConditionalVariablesSetting: PropTypes.func
};

export {CardAvailabilityConditionPopup as TestableCardAvailabilityConditionPopup};
export default React.memo(CardAvailabilityConditionPopup);
