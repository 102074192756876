import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Button from '@frontend/ui-kit/Components/Button';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import SidebarMainItem from '../../../Components/shared/SidebarMainItem';
import SidebarNavigationItem from '../../../Components/shared/SidebarNavigationItem';
import SidebarNavigationGroup from '../SidebarNavigationGroup';
import CustomizablePopup from '../../../Components/shared/CustomizablePopup';
import withPopup from '../../../HOC/withPopup';
import {redirectTo} from '../../../actions/general';
import {changeSidebarMode} from '../../../actions/sidebar';
import {
    getIsBroadcasterOnboarded,
    getMarketingPages,
    getRoutesUnavailabilityMap,
    getActiveCompanyPlanPeriods,
    getWorkspace
} from '../../../selectors/general';
import {getSidebarMode} from '../../../selectors/sidebar';
import {SIDEBAR_FULL_MODE, SIDEBAR_HALF_MODE, WORKSPACES} from '../../../constants';
import {equal, getEqual, getItemKeyValue, negateFunc} from '../../../utils';
import {getItems as getDashboardItems} from './dashboardItems';
import {getItems as getAdminPortalItems} from './adminPortalItems';
import './index.scss';

const POPUP_ID = 'unavailableRoutePopup';

const Sidebar = ({openPopup, closePopup}) => {
    const workspace = useSelector(getWorkspace);
    const [showedCategory, setShowedCategory] = useState(null);
    const dispatch = useDispatch();
    const routesUnavailabilityMap = useSelector(getRoutesUnavailabilityMap);
    const marketingPages = useSelector(getMarketingPages);
    const isBroadcasterOnboarded = useSelector(getIsBroadcasterOnboarded);
    const planPeriods = useSelector(getActiveCompanyPlanPeriods);
    const mode = useSelector(getSidebarMode);
    const {pathname} = useLocation();
    const getItemsByWorkspace = {
        [WORKSPACES.dashboard]: getDashboardItems,
        [WORKSPACES.adminPortal]: getAdminPortalItems
    }[workspace];

    const [activeCategory, adminPortalActiveCategory] = pathname.split('/').filter(Boolean);
    const isMainActive = useMemo(() => mode >= SIDEBAR_HALF_MODE, [mode]);
    const isNavigationActive = useMemo(() => equal(mode, SIDEBAR_FULL_MODE), [mode]);

    const itemsData = {routesUnavailabilityMap, marketingPages, isBroadcasterOnboarded, planPeriods};
    const items = getItemsByWorkspace(itemsData).filter(negateFunc(getItemKeyValue('isHidden')));
    const {subItems = []} = items.find(getEqual(showedCategory, 'path')) || {};
    const displayedSubItems = subItems.filter(negateFunc(getItemKeyValue('isHidden')));

    useEffect(() => {
        setShowedCategory(equal(workspace, WORKSPACES.adminPortal) ? adminPortalActiveCategory : activeCategory);
    }, [activeCategory, adminPortalActiveCategory, workspace]);

    const onOpenPopup = ({title, content}) => {
        const popupProps = {title, content, actionBar: <Button onClick={closePopup}>Continue</Button>};
        const children = <CustomizablePopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onRedirect = route => {
        dispatch(changeSidebarMode(SIDEBAR_HALF_MODE));
        dispatch(redirectTo(route));
    };

    const getMainItem = ({path, subItems, isUnavailable, unavailabilityExplanation, ...rest}) => {
        const isActive = path.includes(showedCategory);
        const onClick = () => {
            if (isUnavailable) {
                return onOpenPopup(unavailabilityExplanation);
            }

            setShowedCategory(path);

            return subItems ? dispatch(changeSidebarMode(SIDEBAR_FULL_MODE)) : onRedirect(`/${path}`);
        };
        const props = {...rest, key: path, isActive, onClick, path};

        return <SidebarMainItem {...props}/>;
    };

    const getNavigationItem = ({title, icon, path, basePath, isUnavailable, unavailabilityExplanation}) => {
        const currPath = `${basePath}/${path}`;
        const isActive = equal(pathname, currPath);
        const onClick = () => isUnavailable ? onOpenPopup(unavailabilityExplanation) : onRedirect(currPath);
        const props = {key: title, title, icon, isActive, onClick};

        return <SidebarNavigationItem {...props}/>;
    };

    const getNavigationGroup = (groupData, index) => {
        const {
            title,
            icon,
            path,
            basePath,
            label,
            labelType,
            isOpenedByDefault = equal(index, 0), // FYI: By default, the first navigation group should be opened (Vlad, 29.09.20)
            subItems
        } = groupData;
        const getNavigationSubItem = subItem => getNavigationItem({...subItem, basePath: `${basePath}/${path}`});
        const displayedSubItems = subItems.filter(negateFunc(getItemKeyValue('isHidden')));
        const props = {key: title, title, icon, label, labelType, isOpenedByDefault};

        return (
            <SidebarNavigationGroup {...props}>
                {displayedSubItems.map(getNavigationSubItem)}
            </SidebarNavigationGroup>
        );
    };

    const getSubItem = (subItem, index) => {
        const enhancedSubItem = {...subItem, basePath: `/${showedCategory}`};

        return subItem.subItems ? getNavigationGroup(enhancedSubItem, index) : getNavigationItem(enhancedSubItem);
    };

    return (
        <div className='sidebar'>
            <div className={`sidebar-main ${isMainActive ? 'sidebar-main_active' : ''}`}>
                {items.map(getMainItem)}
            </div>

            <div className={`sidebar-navigation ${isNavigationActive ? 'sidebar-navigation_active' : ''}`}>
                {displayedSubItems.map(getSubItem)}
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {Sidebar as TestableSidebar};
export default withPopup(POPUP_ID)(Sidebar);
