import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import {getProfileInfo} from '../../../selectors/general';
import './index.scss';

const CongratulationPopup = ({className, actionBar, children, icon}) => {
    const {first_name: name} = useSelector(getProfileInfo);

    return (
        <div data-testid='congratulation-popup' className={`congratulation-popup ${className}`}>
            {icon || <Icon className='congratulation-popup__icon' type={ICON_TYPES.checkCircleFull}/>}

            <Heading className='congratulation-popup__title' type={HEADING_TYPES['1']}>Congrats, {name}!</Heading>

            {children && <Text className='congratulation-popup__content'>{children}</Text>}

            {actionBar && <div className='congratulation-popup__action-bar'>{actionBar}</div>}
        </div>
    );
};

CongratulationPopup.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.node,
    actionBar: PropTypes.node,
    children: PropTypes.node
};

CongratulationPopup.defaultProps = {
    className: ''
};

export {CongratulationPopup as TestableCongratulationPopup};
export default React.memo(CongratulationPopup);
