import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/Home/PageHeader';
import VerticalTimeline from '../../../Components/Home/Timeline';
import ActionPanel from '../../../Components/Home/ActionPanel';
import CampaignImplementationManager from '../../../Components/Home/CampaignImplementationManager';

const Timeline = () => (
    <React.Fragment>
        <Row>
            <Column sm={9}>
                <PageHeader>
                    Welcome to the HealthJoy dashboard! The dashboard is your go-to resource for implementing and renewing HealthJoy, measuring engagement and performance, and accessing additional benefits communication tools and resources throughout the year.
                </PageHeader>
            </Column>
        </Row>

        <Row>
            <Column sm>
                <VerticalTimeline/>
            </Column>

            <Column constant>
                <ActionPanel>
                    <CampaignImplementationManager/>
                </ActionPanel>
            </Column>
        </Row>
    </React.Fragment>
);

export {Timeline as TestableTimeline};
export default React.memo(Timeline);
