import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import BaseContactInfoForm from '../../../Components/ContactsAndCompany/BaseContactInfoForm';

const BaseContactInfo = () => (
    <React.Fragment>
        <Row>
            <Column sm={9}>
                <PageHeader title='Your Contacts' caption='Add a phone number to your account to enable faster and easier Two-Factor Authentication codes.'/>
            </Column>
        </Row>

        <Row>
            <Column sm={8}>
                <BaseContactInfoForm/>
            </Column>
        </Row>
    </React.Fragment>
);

export {BaseContactInfo as TestableBaseContactInfo};
export default React.memo(BaseContactInfo);
