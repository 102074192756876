import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {isEmpty} from '../../../utils';
import {ANCILLARY_PLANS_DATA_BY_CATEGORY} from '../../../constants';
import './index.scss';

const getPlan = plan => {
    const {id, category, category_index: categoryIndex} = plan;
    const {label} = ANCILLARY_PLANS_DATA_BY_CATEGORY[category];

    return <Text key={id} type={TEXT_TYPES.bodyBold} className='plan-list__plan'>{label} {categoryIndex}</Text>;
};

const AncillaryBenefitsSummary = ({plans}) => {
    const arePlans = !isEmpty(plans);

    return (
        <div className='ancillary-benefits-summary'>
            {arePlans && <div className='plan-list'>{plans.map(getPlan)}</div>}

            {!arePlans && <Text className='ancillary-benefits-summary__placeholder'>No Benefits selected</Text>}
        </div>
    );
};

AncillaryBenefitsSummary.propTypes = {
    plans: PropTypes.arrayOf(PropTypes.shape({}))
};

export {AncillaryBenefitsSummary as TestableAncillaryBenefitsSummary};
export default React.memo(AncillaryBenefitsSummary);
