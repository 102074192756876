import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray as SimpleFieldArray} from 'react-final-form-arrays';
import {getUpdatedValues, isEmpty} from '../../../../utils';

const FieldArray = ({children, ...restProps}) => {
    const isEqual = (initialValue, currValue) => {
        return isEmpty(getUpdatedValues({value: currValue}, {value: initialValue}));
    };

    return <SimpleFieldArray {...restProps} isEqual={isEqual}>{children}</SimpleFieldArray>;
};

FieldArray.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    subscription: PropTypes.shape({}),
    allowNull: PropTypes.bool,
    format: PropTypes.func,
    parse: PropTypes.func
};

export default React.memo(FieldArray);
