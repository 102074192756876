import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import {Field} from '../../shared/FormComponents';
import {BH_ELIGIBILITY_OPTIONS} from '../../../options';
import {formatDate, normalizePositiveNumber} from '../../../utils';
import useFormState from '../../../hooks/useFormState';

const BehavioralHealth = ({isEditDisabled}) => {
    const {values} = useFormState();

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={4}>
                        <Field name='bh_availability'>
                            {props => <Select {...props} options={BH_ELIGIBILITY_OPTIONS} disabled={isEditDisabled} label='Eligibility'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.bh_effective_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.bh_expiration_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                </Row>

                <Separator/>

                <Row middle='xs'>
                    <Column sm={4}>
                        <Field name='deductible_bh'>
                            {props => <Checkbox {...props} caption='Consult Fee Applied to Deductible' disabled={isEditDisabled}/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='bh_consult_fee' icon={ICON_TYPES.money} parse={normalizePositiveNumber} placeholder='0.00'>
                            {props => <Input {...props} disabled={isEditDisabled} label='Consult Fee'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='bh_hsa_consult_fee' icon={ICON_TYPES.money} parse={normalizePositiveNumber} placeholder='0.00'>
                            {props => <Input {...props} disabled={isEditDisabled} label='Consult Fee - HSA Members'/>}
                        </Field>
                    </Column>
                </Row>
            </ContentSection>
        </div>
    );
};

BehavioralHealth.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {BehavioralHealth as TestableBehavioralHealth};
export default React.memo(BehavioralHealth);
