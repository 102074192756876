import React from 'react';
import FileUploader from '../../../../shared/FileUploader';
import {Field} from '../../../../shared/FormComponents';
import {ACCEPTABLE_TEXT_FORMATS} from '../../../../../constants';

export const LINK_FILE_MAX_SIZE = 10;

export const FileField = () => (
    <Field name='content.entry.link'>
        {props => <FileUploader isProtected accept={ACCEPTABLE_TEXT_FORMATS} maxSize={LINK_FILE_MAX_SIZE} {...props} label='Download File'/>}
    </Field>
);
