import '@frontend/wallet-cards/index.css'; // FYI: made in the top of the file because ui-kit && wallet-cards CSS needs to have less priority than app styles. (Vlad, 24.03.21)
import '@frontend/ui-kit/index.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import GoogleTagManager from 'react-gtm-module';
import elementClosest from 'element-closest';
import App from './App';
import analyticsService from './analyticsService';
import * as serviceWorker from './serviceWorker';
import {ROOT_ELEMENT, ENV, GTM_ID, SENTRY_DSN, SEGMENT_KEY, IS_PRODUCTION} from './constants';
import './index.scss';

// third-party libs initializing
if (IS_PRODUCTION && GTM_ID) {
    GoogleTagManager.initialize({gtmId: GTM_ID});
}

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0,
        environment: ENV
    });
}

// analytics initialization
analyticsService.load(SEGMENT_KEY);

// polyfills initializing
elementClosest(window);

ReactDOM.render(<App/>, ROOT_ELEMENT);

serviceWorker.unregister();
