import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Input from '@frontend/ui-kit/Components/Input';
import {Form, Field} from '../../Components/shared/FormComponents';
import OverviewStepHeader from '../../Components/Eligibility/OverviewStepHeader';
import AssignableActionBar from '../../Components/shared/AssignableActionBar';
import {requestInfo, requestInfoUpdating} from '../../actions/eligibility';
import {FORMS} from '../../constants';

const withEligibilityInfoForm = ({validate}) => Component => {
    const WithEligibilityInfoForm = props => {
        const {stepsCount, activeStep, isReadonly, isStepSkippable, onSuccess} = props;
        const dispatch = useDispatch();
        const [initialValues, setInitialValues] = useState({});
        const {planPeriodId} = useParams();

        useEffect(() => {
            (async () => {
                const {info, isSuccess} = await dispatch(requestInfo(planPeriodId));

                if (isSuccess) {
                    setInitialValues(info);
                }
            })();
        }, [dispatch, planPeriodId]);

        const onSubmit = useCallback(async values => {
            const {info, isSuccess, submissionErrors} = await dispatch(requestInfoUpdating(values));

            if (!isSuccess) {
                return submissionErrors;
            }

            setInitialValues(info);
        }, [dispatch]);

        return (
            <div data-testid='eligibility-info-form' className='eligibility-info-form'>
                <OverviewStepHeader/>

                <Form name={FORMS.eligibilityInfo} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                    {({handleSubmit}) => {
                        const actionBarProps = {stepsCount, activeStep, isReadonly, isStepSkippable, onSuccess, onSubmitStep: handleSubmit};

                        return (
                            <form noValidate>
                                <Field name='plan_period_id'>{props => <Input {...props} type='hidden'/>}</Field>
                                <Component {...props}/>

                                <AssignableActionBar {...actionBarProps}/>
                            </form>
                        );
                    }}
                </Form>
            </div>
        );
    };

    WithEligibilityInfoForm.propTypes = {
        stepsCount: PropTypes.number,
        activeStep: PropTypes.number,
        isReadonly: PropTypes.bool,
        isStepSkippable: PropTypes.bool,
        onSuccess: PropTypes.func
    };

    return WithEligibilityInfoForm;
};

export {withEligibilityInfoForm as testableWithEligibilityInfoForm};
export default withEligibilityInfoForm;
