import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import {Field, FieldArray, FormSpy} from '../FormComponents';
import LabeledTooltip from '../LabeledTooltip';
import ShadowCustomizableInput from '../../Benefits/ShadowCustomizableInput';
import useForm from '../../../hooks/useForm';
import {equal, getEqual, isEmpty, validateRequired, getFromObjSafe} from '../../../utils';
import {VARIABLE_CONDITIONS_MATCHING, VARIABLE_CONDITIONS_OPERATORS, AVAILABILITY_CONDITIONS_TYPE} from '../../../constants';
import {CONDITIONS_MATCHING_OPTIONS, VARIABLE_CONDITION_OPTIONS, CONDITIONS_TYPE_OPTIONS} from '../../../options';
import './index.scss';

const CONDITIONS_WITHOUT_VALUE = [VARIABLE_CONDITIONS_MATCHING.exists, VARIABLE_CONDITIONS_MATCHING.notExists];
const FORM_SPY_SUBSCRIPTION = {values: true};

/* istanbul ignore next */
const validate = values => {
    const conditions = values?.conditions || {};
    const settings = conditions?.settings || [];

    return {
        conditions: {
            settings: settings.map(({variable, operator, value}) => {
                const isValueRequired = !CONDITIONS_WITHOUT_VALUE.some(getEqual(operator));

                return {
                    variable: validateRequired(variable),
                    value: isValueRequired ? validateRequired(value) : undefined
                };
            })
        }
    };
};

const CardAvailabilityCondition = ({prefixName, isFieldLevelConditions}) => {
    const form = useForm();
    const [contentType, setContentType] = useState(null);
    const [conditions, setConditions] = useState({});
    const {operator, settings} = conditions ?? {};

    const fieldNamePrefix = prefixName ? `${prefixName}.` : '';

    const isConditionalContent = equal(contentType, AVAILABILITY_CONDITIONS_TYPE.conditions);
    const isMultipleThen = equal(operator, VARIABLE_CONDITIONS_OPERATORS.or);

    const onChangeValues = useCallback(({values}) => {
        const {content_type: contentType, conditions} = prefixName
            ? getFromObjSafe(prefixName, values) ?? {}
            : values;

        setContentType(contentType);
        setConditions(conditions);
    }, [prefixName]);

    const getConditions = useCallback(({fields}) => {
        const onAddCondition = () => fields.push({operator: VARIABLE_CONDITIONS_MATCHING.exists});

        return (
            <React.Fragment>
                {fields.map((field, index) => {
                    const condition = fields.value[index];
                    const isValueDisabled = CONDITIONS_WITHOUT_VALUE.some(getEqual(condition.operator));
                    const onChangeOperator = operator => fields.update(index, {operator, variable: condition.variable, value: null});
                    const onDeleteCondition = () => {
                        fields.remove(index);
                        form.change(`${fieldNamePrefix}conditions.then`, null);
                    };

                    return (
                        <React.Fragment>
                            {!equal(index, 0) && (
                                <Row className='plan-linking-condition'>
                                    <Column sm={2}>
                                        <Text type={TEXT_TYPES.bodyBold} className={`matching-marker matching-marker_${operator}`}>{operator}</Text>
                                    </Column>
                                </Row>
                            )}

                            <Row className='plan-linking-condition' middle='xs'>
                                <Column sm={4}>
                                    <Field name={`${field}.variable`}>
                                        {props => <Input {...props} placeholder='${variable-name}' label={<LabeledTooltip title='Variable: ' content={condition.variable} contentClassName='card-availability-condition__tooltip'/>}/>}
                                    </Field>
                                </Column>

                                <Column sm={3}>
                                    <Field name={`${field}.operator`} onChange={onChangeOperator}>
                                        {props => <Select {...props} isMenuPortal className='plan-linking-condition__operator' options={VARIABLE_CONDITION_OPTIONS} placeholder='condition'/>}
                                    </Field>
                                </Column>

                                <Column sm={4}>
                                    <Field name={`${field}.value`}>
                                        {props => <Input {...props} disabled={isValueDisabled} placeholder='value' label={<LabeledTooltip title='Value: ' content={condition.value} contentClassName='card-availability-condition__tooltip'/>}/>}
                                    </Field>
                                </Column>

                                <Column sm={1} className='plan-linking-condition__action-bar' constant>
                                    <Icon data-testid='delete-condition' className='plan-linking-condition__trash' type={ICON_TYPES.delete} onClick={onDeleteCondition}/>
                                </Column>
                            </Row>

                            {isConditionalContent && isMultipleThen && (
                                <Row className='plan-linking-condition conditional-multiple-content' middle='xs'>
                                    <Column sm={2}>
                                        <Text type={TEXT_TYPES.bodyBold} className='matching-marker matching-marker_then'>Then</Text>
                                    </Column>

                                    <Column sm={10}>
                                        <Field name={`${field}.then`}>{props => <Input {...props} placeholder='${variable-name} and surrounding text...'/>}</Field>
                                    </Column>
                                </Row>
                            )}
                        </React.Fragment>
                    );
                })}

                {isConditionalContent && !isMultipleThen && !isEmpty(settings) && (
                    <Row className='plan-linking-condition' data-testid='condition-no-empty-settings' middle='xs'>
                        <Column sm={2}/>

                        <Column sm={2}>
                            <Text type={TEXT_TYPES.bodyBold} className='matching-marker matching-marker_then'>Then</Text>
                        </Column>

                        <Column sm={7}>
                            <Field name={`${fieldNamePrefix}conditions.then`}>
                                {props => <Input {...props} placeholder='${variable-name} and surrounding text...'/>}
                            </Field>
                        </Column>
                    </Row>
                )}

                <Row className='plan-linking-conditions__addition'>
                    <Column sm={2}>
                        <Text type={TEXT_TYPES.bodyBold} className={`matching-marker matching-marker_${operator}`}>{operator}</Text>
                    </Column>

                    <Column sm={4}>
                        <Button type={BUTTON_TYPES.primary} onClick={onAddCondition}>Add condition</Button>
                    </Column>
                </Row>
            </React.Fragment>
        );
    }, [form, fieldNamePrefix, isConditionalContent, isMultipleThen, operator, settings]);

    return (
        <div className='card-availability-condition'>
            <FormSpy subscription={FORM_SPY_SUBSCRIPTION} onChange={onChangeValues}/>

            {isFieldLevelConditions && (
                <div className='field-level-condition'>
                    <Row rowGap='lg'>
                        <Column sm={6}>
                            <Field name={`${fieldNamePrefix}content_type`}>
                                {props => <Select {...props} isMenuPortal options={CONDITIONS_TYPE_OPTIONS} placeholder='Matching of the conditions...'/>}
                            </Field>
                        </Column>

                        <Column sm={6}/>

                        {!isConditionalContent && (
                            <Column sm={6} data-testid='no-conditional-content'>
                                <Field name={`${fieldNamePrefix}content`}>
                                    {props => <ShadowCustomizableInput {...props} placeholder='${variable-name} and surrounding text...' label='Set Variable and surrounding text'/>}
                                </Field>
                            </Column>
                        )}
                    </Row>
                </div>
            )}

            {(!isFieldLevelConditions || isConditionalContent) && (
                <React.Fragment>
                    <Row>
                        <Column sm={12}>
                            <div className='operator-label'>
                                <div className='operator-label__matcher-wrapper'>
                                    <Field name={`${fieldNamePrefix}conditions.operator`}>
                                        {props => <Select {...props} isMenuPortal options={CONDITIONS_MATCHING_OPTIONS} placeholder='Matching of the conditions...'/>}
                                    </Field>
                                </div>

                                <Text>of the following conditions match</Text>
                            </div>
                        </Column>
                    </Row>

                    <div className='plan-linking-conditions'>
                        <FieldArray name={`${fieldNamePrefix}conditions.settings`}>{getConditions}</FieldArray>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

CardAvailabilityCondition.propTypes = {
    prefixName: PropTypes.string,
    isFieldLevelConditions: PropTypes.bool
};

export {validate, CardAvailabilityCondition as TestableCardAvailabilityCondition};
export default CardAvailabilityCondition;
