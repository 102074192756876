import React from 'react';
import PropTypes from 'prop-types';
import PdfText from '../PdfText';
import HJLogo from '../../../static/images/hjLogoPurple.svg';
import {trimByMaxLength} from '../../../utils';
import './index.scss';

const PAGE_TITLE_MAX_LENGTH = 50;

const Page = ({children, className, title, pageNum, notation, isBlank, isLast}) => {
    const trimmedTitle = trimByMaxLength(title, PAGE_TITLE_MAX_LENGTH);
    const lastPageClassName = isLast ? 'pdf-page_last' : '';

    return (
        <div className={`pdf-page ${className} ${lastPageClassName}`}>
            {!isBlank && (
                <header className='pdf-header'>
                    <img className='pdf-header__logo' src={HJLogo} alt=''/>
                    <div className='pdf-header-title'>
                        <PdfText className='pdf-header-title__text' type='body-bold'>{trimmedTitle}</PdfText>
                        <PdfText className='pdf-header-title__page-num'>{pageNum}</PdfText>
                    </div>
                </header>
            )}

            <div className='pdf-page__body'>{children}</div>

            {!isBlank && (
                <footer className='pdf-footer'>
                    <PdfText type='caption' className='pdf-footer__notation'>NOTE: {notation}</PdfText>
                </footer>
            )}
        </div>
    );
};

Page.propTypes = {
    className: PropTypes.string,
    isBlank: PropTypes.bool,
    isLast: PropTypes.bool,
    title: PropTypes.string,
    pageNum: PropTypes.number,
    notation: PropTypes.string,
    children: PropTypes.node
};

Page.defaultProps = {
    className: '',
    isBlank: false,
    isLast: false
};

export {Page as TestablePage};
export default React.memo(Page);
