import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import './index.scss';

const Notation = ({className, title, icon, children}) => (
    <div className={`notation-wrapper ${className}`}>
        {title && (
            <Heading className='notation-wrapper-title'>
                <Icon className={`notation-wrapper-title__icon notation-wrapper-title__icon_${icon}`} type={icon}/> {title}
            </Heading>
        )}
        <div className='notation-wrapper-content'>{children}</div>
    </div>
);

Notation.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.node.isRequired
};

Notation.defaultProps = {
    className: '',
    icon: ICON_TYPES.info
};

export {Notation as TestableNotation};
export default React.memo(Notation);
