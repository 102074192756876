import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Table from '@frontend/ui-kit/Components/Table';
import Text from '@frontend/ui-kit/Components/Text';
import {requestEligibilityImportSessionErrorsDetails} from '../../../actions/adminPortal';
import {formatDate} from '../../../utils';

const TABLE_PAGE_SIZE = 20;

const ImportBlockerErrorsReportDetails = () => {
    const dispatch = useDispatch();
    const {id: sessionId, column_name: columnName} = useParams();
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0});
    const [tableData, setTableData] = useState([]);
    const [{error_type: errorType, explanation}, setErrorInfo] = useState({});

    const onFetchData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE}) => {
        const query = {
            id: sessionId,
            limit: pageSize,
            offset: pageIndex * pageSize,
            columnName
        };
        const {isSuccess, data} = await dispatch(requestEligibilityImportSessionErrorsDetails(query));

        if (!isSuccess) {
            return false;
        }

        setTable({unitCount: data?.count, pageIndex});
        setErrorInfo({error_type: data?.error_type, explanation: data?.explanation});
        setTableData(data?.data);
    }, [sessionId, columnName, dispatch]);

    const getTableColumns = () => {
        const getName = ({value, cell}) => [value, cell?.row?.original?.first_name].filter(Boolean).join(', ');
        const getBirthDateCell = ({value}) => <span>{value && formatDate(value, 'MM/dd/yyyy')}</span>;

        return [
            {Header: 'Name', accessor: 'last_name', Cell: getName},
            {Header: 'Email Address', accessor: 'email'},
            {Header: 'Date of Birth', accessor: 'birthday', Cell: getBirthDateCell},
            {Header: 'Employee ID', accessor: 'employee_id'}
        ];
    };

    const tableProps = {
        table,
        onFetchData,
        data: tableData,
        isFilterable: false,
        isSortable: false,
        isCellTooltip: false,
        className: 'pb-10',
        columns: getTableColumns()
    };

    return (
        <div className='import-blocker-errors-report-details'>
            <Heading type={HEADING_TYPES['3']}>{errorType}</Heading>
            <Text className='mt-5'>{explanation}</Text>

            <ContentSection className='mt-20'>
                <Table {...tableProps}/>
            </ContentSection>
        </div>
    );
};

export {ImportBlockerErrorsReportDetails as TestableImportBlockerErrorsReportDetails};
export default React.memo(ImportBlockerErrorsReportDetails);
