import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import SimplePageHeader from '../../shared/PageHeader';
import {getProfileInfo} from '../../../selectors/general';

const PageHeader = ({children}) => {
    const {first_name: name} = useSelector(getProfileInfo);

    return (
        <SimplePageHeader title={`Hello, ${name}!`} caption={children}/>
    );
};

PageHeader.propTypes = {
    children: PropTypes.node,
    profileInfo: PropTypes.shape({first_name: PropTypes.string})
};

export {PageHeader as TestablePageHeader};
export default React.memo(PageHeader);
