import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Badge from '@frontend/ui-kit/Components/Badge';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {equal, getEqual, isEmpty} from '../../../utils';
import {CORE_PLAN_TYPES, SIMPLE_ANSWERS, SSN} from '../../../constants';
import {SYSTEM_NAME_OPTIONS} from '../../../options';
import './index.scss';

const fieldPlaceholder = <Text type={TEXT_TYPES.body}>Not Entered</Text>;

const getPlan = (plan, index, plans) => {
    const isMedical = equal(plan.plan_kind, CORE_PLAN_TYPES.medical);
    const isSSN = equal(plan.identify_by, SSN);
    const isLast = equal(index, plans.length - 1);
    const {label: systemName} = SYSTEM_NAME_OPTIONS.find(getEqual(plan.system_name, 'value')) || {};

    return (
        <div key={plan.id} className='plan'>
            <Text className='plan__title'>{index + 1}. {plan.plan_name}</Text>

            <Row className='plan-info'>
                <Column className='plan-info-column' sm={6}>
                    <Row className='plan-info-column__item'>
                        <Column sm={6}><Text>Carrier:</Text></Column>
                        <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{plan.carrier_name}</Text></Column>
                    </Row>

                    <Row className='plan-info-column__item'>
                        <Column sm={6}>
                            <Text>Plan Name:</Text>
                            <Text type={TEXT_TYPES.helper}>As indicated in the carrier contract or SPD</Text>
                        </Column>
                        <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{plan.plan_name}</Text></Column>
                    </Row>

                    <Row className='plan-info-column__item'>
                        <Column sm={6}>
                            <Text>Plan Name Shown in the App:</Text>
                            <Text type={TEXT_TYPES.helper}>Leave blank if same as carrier plan name</Text>
                        </Column>
                        <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{plan.app_name || fieldPlaceholder}</Text></Column>
                    </Row>

                    <Row className='plan-info-column__item'>
                        <Column sm={6}><Text>Plan Type:</Text></Column>
                        <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{plan.plan_type}</Text></Column>
                    </Row>
                </Column>

                <Column className='plan-info-column' sm={6}>
                    {isMedical && (
                        <Row className='plan-info-column__item'>
                            <Column sm={6}><Text>Where will the Medical IDs be coming from?</Text></Column>
                            <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{systemName || fieldPlaceholder}</Text></Column>
                        </Row>
                    )}

                    {!isMedical && (
                        <React.Fragment>
                            <Row className='plan-info-column__item'>
                                <Column sm={6}><Text>Does this plan assign unique member ID's?</Text></Column>
                                <Column sm={6}>
                                    <Text type={TEXT_TYPES.bodyBold}>{systemName ? SIMPLE_ANSWERS.yes : SIMPLE_ANSWERS.no}</Text>
                                </Column>
                            </Row>

                            {systemName && (
                                <Row className='plan-info-column__item'>
                                    <Column sm={6}><Text>Where will the {plan.plan_kind} ID's be coming from?</Text></Column>
                                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{systemName}</Text></Column>
                                </Row>
                            )}

                            {!plan.system_name && plan.identify_by && (
                                <React.Fragment>
                                    <Row className='plan-info-column__item'>
                                        <Column sm={6}><Text>What is Used for Member Identification?</Text></Column>
                                        <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{plan.identify_by}</Text></Column>
                                    </Row>

                                    {isSSN && (
                                        <Row className='plan-info-column__item'>
                                            <Column sm={6}>
                                                <Text>
                                                    In order to ensure that Social Security Numbers are not appearing in the HealthJoy app,
                                                    what would you like to substitute for their wallet card?
                                                </Text>
                                            </Column>
                                            <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{plan.member_id_default}</Text></Column>
                                        </Row>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </Column>
            </Row>

            {!isLast && <Separator/>}
        </div>
    );
};

const EligibilityCoreBenefitsSummary = ({plans}) => {
    const {medical: medicalPlans = [], dental: dentalPlans = [], vision: visionPlans = []} = plans || {};

    return (
        <Row className='eligibility-core-benefits-summary'>
            <Column sm={12} className='plans-header plans-header_medical'>
                <Text type={TEXT_TYPES.bodyBold}>Medical Plans</Text>
                <Badge className='plans-header__count'>{medicalPlans.length}</Badge>
            </Column>
            <Column sm={12} className='plans-list'>
                {!isEmpty(medicalPlans) ? medicalPlans.map(getPlan) : <Text className='plans-list__placeholder'>No Medical Plans selected</Text>}
            </Column>

            <Column sm={12} className='plans-header plans-header_dental'>
                <Text type={TEXT_TYPES.bodyBold}>Dental Plans</Text>
                <Badge className='plans-header__count'>{dentalPlans.length}</Badge>
            </Column>
            <Column sm={12} className='plans-list'>
                {!isEmpty(dentalPlans) ? dentalPlans.map(getPlan) : <Text className='plans-list__placeholder'>No Dental Plans selected</Text>}
            </Column>

            <Column sm={12} className='plans-header plans-header_vision'>
                <Text type={TEXT_TYPES.bodyBold}>Vision Plans</Text>
                <Badge className='plans-header__count'>{visionPlans.length}</Badge>
            </Column>
            <Column sm={12} className='plans-list'>
                {!isEmpty(visionPlans) ? visionPlans.map(getPlan) : <Text className='plans-list__placeholder'>No Vision Plans selected</Text>}
            </Column>
        </Row>
    );
};

EligibilityCoreBenefitsSummary.propTypes = {
    plans: PropTypes.object
};

export {EligibilityCoreBenefitsSummary as TestableEligibilityCoreBenefitsSummary};
export default React.memo(EligibilityCoreBenefitsSummary);
