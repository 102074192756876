import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MarkupEditorField from '@frontend/ui-kit/Components/MarkupEditorField';
import './index.scss';

const ShadowCustomizableInput = ({className, readonly, ...props}) => {
    const enhancedOptions = useMemo(() => ({toolbar: false, disableExtraSpaces: true}), []);
    const markupEditorFieldProps = {
        className: classnames({'shadow-customizable-input': !readonly}, className),
        readonly,
        options: enhancedOptions,
        isMultiline: false,
        ...props
    };

    return <MarkupEditorField {...markupEditorFieldProps}/>;
};

ShadowCustomizableInput.propTypes = {
    className: PropTypes.string,
    readonly: PropTypes.bool
};

export {ShadowCustomizableInput as TestableShadowCustomizableInput};
export default React.memo(ShadowCustomizableInput);
