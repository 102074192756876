import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {equal} from '../../../utils';
import './index.scss';

const ColorPicker = ({colors, disabled, className, onChange, value}) => {
    const [customColor, setCustomColor] = useState('#eeeff1');

    const onUpdateColor = event => {
        const nextColor = event.target.value;
        setCustomColor(nextColor);
        onChange(nextColor);
    };

    const getCustomColorPicker = () => {
        const isCustomColor = value && !colors.includes(value);
        const selectedColor = isCustomColor ? value : customColor;
        const labelClassName = classnames('color', 'color__custom', {
            active: isCustomColor,
            disabled
        });
        const inputProps = !disabled ? {onClick: event => onChange(event.target.value), onChange: onUpdateColor} : {disabled: true};

        return (
            <label className={labelClassName} style={{borderColor: selectedColor}}>
                <Icon type={ICON_TYPES.pipette} className='color__custom__icon'/>
                <input {...inputProps}
                    type='color'
                    value={selectedColor}/>
            </label>
        );
    };

    const getColor = color => {
        const className = classnames('color', {
            active: equal(color, value),
            disabled
        });
        const inputProps = !disabled ? {onClick: () => onChange(color)} : {};

        return (
            <div {...inputProps} key={color} className={className} style={{backgroundColor: color}}/>
        );
    };

    return (
        <div className={`color-picker ${className}`}>
            {getCustomColorPicker()}
            {colors.map(getColor)}
        </div>
    );
};

ColorPicker.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

ColorPicker.defaultProps = {
    colors: [],
    disabled: false
};

export {ColorPicker as TestableColorPicker};
export default React.memo(ColorPicker);
