import React from 'react';
import PropTypes from 'prop-types';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import RoiCalculator from '../RoiCalculator';
import withReportingInfo from '../../../HOC/withReportingInfo';
import {ROI_CHART_BREAKEVEN_POINT_LIMIT} from '../../../constants';
import {getDelimitedNum, getEqual} from '../../../utils';
import './index.scss';

const CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS = {
    telemed: 'Telemedicine',
    billReview: 'Medical Bill Review',
    msk: 'Musculoskeletal',
    preCertification: 'Pre-certification Steerage'
};

const ProgramSavings = props => {
    const {
        info,
        isRoiVisible,
        isTelemedVisible,
        isTeladocVisible,
        isTeladocGeneralMedicalVisible,
        isTeladocMentalHealthVisible,
        isMskVisible,
        isPreCertificationVisible,
        isFindCareVisible,
        isMedicalBillVisible
    } = props;
    const {
        totalSavings,
        telemedSavings,
        teladocGeneralMedicalSavings,
        teladocMentalHealthSavings,
        mskSavings,
        preCertificationSavings,
        providerFacilitySavings,
        rxSavings,
        medicalBillReviewSavings,
        totalSavingsChartData,
        savingsByServiceChartData,
        roiChartData
    } = info;
    const teladocHealthSavings = (isTeladocGeneralMedicalVisible ? teladocGeneralMedicalSavings : 0)
        + (isTeladocMentalHealthVisible ? teladocMentalHealthSavings : 0);
    const [{data: roiDataByMonth}] = roiChartData?.datasets || [{data: []}];
    const isBreakevenPoint = Math.min(...roiDataByMonth) <= ROI_CHART_BREAKEVEN_POINT_LIMIT;
    const totalSavingsChartProps = {
        title: 'Total Program Savings',
        description: `
            Total Program Savings is calculated as the sum of the estimated savings for categories of utilization.
            You can read more about how each category is calculated in the Program Savings Formula below.
        `,
        unit: '$',
        data: totalSavingsChartData,
        options: {scales: {yAxes: [{ticks: {suggestedMax: 250}}]}}
    };
    const roiChartProps = {
        title: 'Return on Investment',
        description: `
            Return on Investment, calculated at the end of each month, is the Total Company Savings divided by the Company’s prorated investment in HealthJoy.
            An ROI of greater than 0 means that your company is saving more money than you are spending with the HealthJoy program.
        `,
        unit: 'x',
        data: roiChartData,
        options: {
            scales: {yAxes: [{ticks: {suggestedMin: 0, suggestedMax: 3}}]},
            annotation: {
                annotations: [{value: isBreakevenPoint ? 0 : null, borderColor: '#20c997', label: {content: 'Breakeven point'}}]
            }
        }
    };

    const {datasets: savingsByServiceChartDatasets = [], labels: savingsByServiceChartLabels} = savingsByServiceChartData || {};
    const isSavingsByServiceChartDataByLabel = {
        [CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS.telemed]: isTelemedVisible,
        [CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS.billReview]: isMedicalBillVisible,
        [CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS.msk]: isMskVisible,
        [CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS.preCertification]: isPreCertificationVisible
    };
    const getSavingsByServiceChartData = ({label}) => !Object.values(CONDITIONAL_SAVINGS_BY_SERVICE_CHART_LABELS).some(getEqual(label)) || isSavingsByServiceChartDataByLabel[label];
    const filteredSavingsByServiceChartDatasets = savingsByServiceChartDatasets.filter(getSavingsByServiceChartData);
    const savingsByServiceChartProps = {
        title: 'Program Savings by Service',
        unit: '$',
        data: {
            datasets: filteredSavingsByServiceChartDatasets,
            labels: savingsByServiceChartLabels
        },
        options: {scales: {yAxes: [{ticks: {suggestedMax: 300}}]}}
    };

    return (
        <div className='reporting reporting_program-savings' data-testid='program-savings'>
            <ContentSection className='reporting-section'>
                <Row columnGap='xlg'>
                    <Column className='reporting-section__column reporting-section__column_separated' sm={6}>
                        <div className='reporting-chart'>
                            <LineChart {...totalSavingsChartProps}/>
                        </div>
                    </Column>

                    <Column className='reporting-section__column' sm={6}>
                        <Heading className='reporting-info-title' type={HEADING_TYPES['5']} data-testid='program-savings-title'>
                            Program Savings Breakdown
                        </Heading>

                        <Text className='reporting-info-subtitle' type={TEXT_TYPES.bodyBold}>
                            ${getDelimitedNum(totalSavings)} saved with HealthJoy
                        </Text>

                        <Text className='reporting-info'>
                            In addition to streamlining the benefits experience, HealthJoy is focused on saving both you and your members money on healthcare.
                        </Text>
                        <Text className='reporting-info'>
                            Total Savings reflect the sum of the total estimated savings for each service and administrative savings.
                            Please note that Administrative Savings is included in Total Savings, but a breakdown of Administrative Savings is currently unavailable.
                        </Text>

                        <Text>Here is a breakdown of your program savings:</Text>
                        <ul className='reporting-list'>
                            {isMedicalBillVisible && (
                                <li className='reporting-list__item' data-testid='mbr-list-item'>
                                    <Text>${getDelimitedNum(medicalBillReviewSavings)} in Medical Bill Review Savings</Text>
                                </li>
                            )}
                            {isTelemedVisible && (
                                <li className='reporting-list__item' data-testid='telemed-list-item'>
                                    <Text>${getDelimitedNum(telemedSavings)} in MeMD Savings</Text>
                                </li>
                            )}
                            {isTeladocVisible && (
                                <li className='reporting-list__item' data-testid='teladoc-health-list-item'>
                                    <Text>${getDelimitedNum(teladocHealthSavings)} in Teladoc Health Savings</Text>

                                    <ul className='reporting-list'>
                                        {isTeladocGeneralMedicalVisible && <li className='reporting-list__item'>${getDelimitedNum(teladocGeneralMedicalSavings)} General Medical (Urgent Care)</li>}
                                        {isTeladocMentalHealthVisible && <li className='reporting-list__item'>${getDelimitedNum(teladocMentalHealthSavings)} Mental Health</li>}
                                    </ul>
                                </li>
                            )}
                            {isMskVisible && (
                                <li className='reporting-list__item' data-testid='msk-list-item'>
                                    <Text>${getDelimitedNum(mskSavings)} in MSK Therapy Savings</Text>
                                </li>
                            )}
                            {isPreCertificationVisible && (
                                <li className='reporting-list__item' data-testid='pre-certification-list-item'>
                                    <Text>${getDelimitedNum(preCertificationSavings)} in Pre-certification Steerage Savings</Text>
                                </li>
                            )}
                            <li className='reporting-list__item'>
                                <Text>${getDelimitedNum(providerFacilitySavings)} in Provider/Facility Steerage Savings</Text>
                            </li>
                            <li className='reporting-list__item'>
                                <Text>${getDelimitedNum(rxSavings)} in Rx Savings</Text>
                            </li>
                        </ul>
                    </Column>
                </Row>
            </ContentSection>

            <Row className='reporting-section'>
                <Column className='reporting-section__column' sm={6}>
                    <ContentSection className='roi-calculator-wrapper'>
                        <RoiCalculator isTelemedVisible={isTelemedVisible}
                            isTeladocGeneralMedicalVisible={isTeladocGeneralMedicalVisible}
                            isTeladocMentalHealthVisible={isTeladocMentalHealthVisible}
                            isMedicalBillVisible={isMedicalBillVisible}
                            isMskVisible={isMskVisible}
                            isPreCertificationVisible={isPreCertificationVisible}
                            isFindCareVisible={isFindCareVisible}/>
                    </ContentSection>
                </Column>

                <Column className='reporting-section__column' sm={6}>
                    {isRoiVisible && (
                        <ContentSection className='reporting-chart'>
                            <LineChart {...roiChartProps}/>
                        </ContentSection>
                    )}

                    <ContentSection className='reporting-chart'>
                        <LineChart {...savingsByServiceChartProps}/>
                    </ContentSection>
                </Column>
            </Row>
        </div>
    );
};

ProgramSavings.propTypes = {
    info: PropTypes.shape({
        medicalBillReviewSavings: PropTypes.number,
        mskSavings: PropTypes.number,
        preCertificationSavings: PropTypes.number,
        providerFacilitySavings: PropTypes.number,
        roiChartData: PropTypes.shape(),
        rxSavings: PropTypes.number,
        savingsByServiceChartData: PropTypes.shape(),
        telemedSavings: PropTypes.number,
        teladocGeneralMedicalSavings: PropTypes.number,
        teladocMentalHealthSavings: PropTypes.number,
        totalSavings: PropTypes.number,
        totalSavingsChartData: PropTypes.shape()
    }),
    isRoiVisible: PropTypes.bool,
    isTelemedVisible: PropTypes.bool,
    isTeladocVisible: PropTypes.bool,
    isTeladocGeneralMedicalVisible: PropTypes.bool,
    isTeladocMentalHealthVisible: PropTypes.bool,
    isMskVisible: PropTypes.bool,
    isPreCertificationVisible: PropTypes.bool,
    isFindCareVisible: PropTypes.bool,
    isMedicalBillVisible: PropTypes.bool
};

export {ProgramSavings as TestableProgramSavings};
export default withReportingInfo({isDisclaimerShowed: true})(ProgramSavings);
