import React from 'react';
import PropTypes from 'prop-types';
import Select from '@frontend/ui-kit/Components/Select';
import {Field} from '../../../../shared/FormComponents';

export const WalletSelector = ({
    walletLinks
}) => {
    const walletLinkOptions = [
        {label: 'Choose nothing', value: null},
        ...walletLinks.map(({name, link}) => ({label: name, value: link}))
    ];
    return (
        <Field name='content.entry.link'>
            {props => <Select {...props} options={walletLinkOptions} placeholder='Click to select...' label='Link Wallet Card'/>}
        </Field>
    );
};

WalletSelector.propTypes = {
    walletLinks: PropTypes.arrayOf(PropTypes.shape({}))
};
