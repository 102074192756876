import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';

const Category = ({children, onClick, hasIcon, isSelected}) => (
    <div onClick={onClick} className={classnames('ancillary-category', {'ancillary-category_selected': isSelected, 'ancillary-category_no-action': !onClick})}>
        <Row middle='xs'>
            <Column xs>{children}</Column>
            {(hasIcon || isSelected) && (
                <Column constant className='ancillary-category__icon_box'>
                    <Icon type={isSelected ? ICON_TYPES.confirm : ICON_TYPES.add}/>
                </Column>
            )}
        </Row>
    </div>
);

Category.propTypes = {
    hasIcon: PropTypes.bool,
    isSelected: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func
};

export {Category as TestableCategory};
export default Category;
