import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Table from '@frontend/ui-kit/Components/Table';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import {requestPreprocessingReport} from '../../../actions/adminPortal';
import {formatDate} from '../../../utils';
import './index.scss';

const TABLE_PAGE_SIZE = 20;

const PreprocessingReportErrorDetails = () => {
    const [table, setTable] = useState({unitCount: 0});
    const {session_id: sessionId, error_index: errorIndex} = useParams();
    const [errorsData, setErrorsData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const {report, isSuccess} = await dispatch(requestPreprocessingReport(sessionId));

            if (!isSuccess) {
                return false;
            }

            const errors = report.duplicate_errors[errorIndex] || {};

            setErrorsData(errors);
            setTable({unitCount: errors?.affected_members?.length || 0});
        })();
    }, [dispatch, sessionId, errorIndex]);

    const getBirthDateCell = ({value}) => <span>{formatDate(value, 'MM/dd/yyyy')}</span>;

    const companyAliasHeader = (
        <React.Fragment>
            Company Alias
            <Tooltip className='ml-3 tooltip' content={<Text>For any companies where group ID is not listed in Houston, the group ID value will be pulled from file and shown here</Text>}>
                <Icon className='tooltip__icon' type={ICON_TYPES.info}/>
            </Tooltip>
        </React.Fragment>
    );

    const tableProps = {
        ...table,
        pageSize: TABLE_PAGE_SIZE,
        isFilterable: false,
        isCellTooltip: false,
        data: errorsData?.affected_members || [],
        columns: [
            {Header: 'Name', accessor: 'name'},
            {Header: 'Email Address', accessor: 'email'},
            {Header: 'Date of Birth', accessor: 'dob', Cell: getBirthDateCell},
            {Header: companyAliasHeader, accessor: 'company_identifier'}
        ]
    };

    return (
        <React.Fragment>
            <Heading type={HEADING_TYPES['1']}>{errorsData?.error_type}</Heading>
            <Text className='mt-5'>{errorsData?.explanation}</Text>

            <ContentSection className='mt-20'>
                <Table {...tableProps}/>
            </ContentSection>
        </React.Fragment>
    );
};

export {PreprocessingReportErrorDetails as TestablePreprocessingReportErrorDetails};
export default React.memo(PreprocessingReportErrorDetails);
