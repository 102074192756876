import React from 'react';
import {withRouter} from 'react-router-dom';
import Link from '@frontend/ui-kit/Components/Link';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {ROUTES} from '../../../constants';
import hjLogo from '../../../static/images/hjLogoPurple.svg';

const ServerError = ({match}) => {
    const {errorCode} = match.params;

    return (
        <div className='error-page server-error'>
            <img className='error-page__logo' src={hjLogo} alt=''/>

            <Text type={TEXT_TYPES.bodyBold} className='error-page__header'>{errorCode}</Text>

            <Text className='error-page__subheader'>Server Error</Text>

            <Text className='error-page__description'>
                This service is currently unavailable. Our team is working hard to resolve the issue as soon as possible.
            </Text>

            <Link className='error-page__button' isButton type={BUTTON_TYPES.secondary} href={ROUTES.root}>Go to homepage</Link>
        </div>
    );
};

export {ServerError as TestableServerError};
export default withRouter(ServerError);
