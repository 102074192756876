import React from 'react';
import PropTypes from 'prop-types';
import MultiSelectInput, {MULTI_SELECT_VALUE_TYPES} from '@frontend/ui-kit/Components/MultiSelectInput';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {Field} from '../../../shared/FormComponents';
import {equal, filterUniqArrayValues, isEmpty} from '../../../../utils';

const CategoryFormContent = ({disabled, duplicatedValues: duplicatedValuesData = {}, category = ''}) => {
    const FORM_CONTENT_DATA = [
        {name: 'name', label: 'Name'},
        {name: 'policy_number', label: 'Policy Number', className: 'mt-10'},
        {name: 'group_number', label: 'Group Number', className: 'mt-10'},
        {name: 'policy_amount', label: 'Policy Amount', className: 'mt-10'},
        {name: 'coverage_tier', label: 'Coverage Tier', className: 'mt-10'},
        {name: 'effective_date', label: 'Effective Date', className: 'mt-10'},
        {name: 'expiration_date', label: 'Expiration Date', className: 'mt-10'}
    ];

    return FORM_CONTENT_DATA.map(({name, label, className = ''}) => (
        <Field name={name} key={name}>
            {props => {
                const {value} = props;
                const duplicatedValues = Object.keys(duplicatedValuesData).filter(duplicatedValue => value.includes(duplicatedValue));
                const isDuplicate = !isEmpty(duplicatedValues);
                const relatedCategories = isDuplicate && duplicatedValues
                    .reduce((acc, value) => [...acc, ...Object.keys(duplicatedValuesData[value])], [])
                    .filter(relatedCategory => !equal(category, relatedCategory))
                    .filter(filterUniqArrayValues)
                    .join(', ');

                const getMultiValuesProps = () => Object
                    .keys(duplicatedValuesData)
                    .reduce((acc, value) => ({...acc, [value]: {highlightType: MULTI_SELECT_VALUE_TYPES.warning}}), {});

                const multiSelectInputProps = {
                    label,
                    disabled,
                    className,
                    multiValuesProps: isDuplicate ? getMultiValuesProps() : {}
                };

                return (
                    <React.Fragment>
                        <MultiSelectInput {...props} {...multiSelectInputProps}/>

                        {isDuplicate && (
                            <Text className='pl-20 mt-5 ancillary-category-duplicates-text'>
                                <Icon className='ancillary-category-duplicates-text__icon' type={ICON_TYPES.alert}/>
                                The highlighted value already exists in <Text isInline type={TEXT_TYPES.bodyBold} className='ancillary-category-duplicates-text_alert'>{relatedCategories}</Text>. Please make corrections as the system will not be able correctly map the values.
                            </Text>
                        )}
                    </React.Fragment>
                );
            }}
        </Field>
    ));
};

CategoryFormContent.propTypes = {
    category: PropTypes.string,
    disabled: PropTypes.bool,
    duplicatedValues: PropTypes.shape({})
};

export {CategoryFormContent as TestableCategoryFormContent};
export default CategoryFormContent;
