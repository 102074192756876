import {SET_INTERNAL_MENU_ACTIVE_ITEM, SET_INTERNAL_MENU_ITEMS} from './actionTypes';
import {getInternalMenuActiveItem, getInternalMenuItems} from '../selectors/internalMenu';
import {equal, getEqual, negateFunc} from '../utils';

export const setInternalMenuItems = items => ({type: SET_INTERNAL_MENU_ITEMS, items});

export const setInternalMenuActiveItem = activeItem => ({type: SET_INTERNAL_MENU_ACTIVE_ITEM, activeItem});

export const markInternalMenuItemAsDone = itemId => (dispatch, getState) => {
    const state = getState();
    const items = getInternalMenuItems(state);
    const updatedItems = items.map(item => ({...item, completed: equal(item.id, itemId) || item.completed}));

    dispatch(setInternalMenuItems(updatedItems));

    return {items: updatedItems};
};

export const removeInternalMenuItem = itemId => (dispatch, getState) => {
    const state = getState();
    const items = getInternalMenuItems(state);
    const filteredItems = items.filter(negateFunc(getEqual(itemId, 'id')));

    dispatch(setInternalMenuItems(filteredItems));

    return {items: filteredItems};
};

export const goPrevInternalMenuItem = () => (dispatch, getState) => {
    const state = getState();
    const items = getInternalMenuItems(state);
    const activeItem = getInternalMenuActiveItem(state);
    const activeItemIndex = items.findIndex(getEqual(activeItem, 'id'));
    const prevItemIndex = equal(activeItemIndex, 0) ? 1 : activeItemIndex - 1; // FYI: we change activeItem before updating internalMenuItems (removing element) therefore during removing index = 0 next activeItem should be item with index 1 (Pasha, 21.12.2020)
    const {id: prevItemId} = items[prevItemIndex];

    dispatch(setInternalMenuActiveItem(prevItemId));
};

export const goNextInternalMenuItem = () => (dispatch, getState) => {
    const state = getState();
    const items = getInternalMenuItems(state);
    const activeItem = getInternalMenuActiveItem(state);
    const activeItemIndex = items.findIndex(getEqual(activeItem, 'id'));

    const incompleteItemBefore = items.findIndex(({completed}, index) => index < activeItemIndex && !completed);
    const incompleteItemAfter = items.findIndex(({completed}, index) => index > activeItemIndex && !completed);
    const nextItemIndex = incompleteItemAfter >= 0 ? incompleteItemAfter : incompleteItemBefore;
    const {id: nextItemId} = items[nextItemIndex] || items[activeItemIndex];

    dispatch(setInternalMenuActiveItem(nextItemId));
};
