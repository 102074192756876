import React from 'react';
import VerificationReportDetailedCorePlans from '../../../Components/AdminPortal/VerificationReportDetailedCorePlans';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'group_name'},
    {skip: true},
    {name: 'Verification Report'},
    {name: 'Detailed Core Plans'}
];

const IndividualVerificationReportDetailedCorePlans = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <VerificationReportDetailedCorePlans/>
    </React.Fragment>
);

export {IndividualVerificationReportDetailedCorePlans as TestableIndividualVerificationReportDetailedCorePlans};
export default IndividualVerificationReportDetailedCorePlans;
