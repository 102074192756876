import React from 'react';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import TPAAutoimportGroup from '../../../Components/AdminPortal/TPAAutoimportGroup';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'partner_name'},
    {skip: true},
    {name: 'Individual Configuration'}
];

const TPAAutoimportGroupConfiguration = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <TPAAutoimportGroup/>
    </React.Fragment>
);

export {TPAAutoimportGroupConfiguration as TestableTPAAutoimportGroupConfiguration};
export default React.memo(TPAAutoimportGroupConfiguration);
