import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Select from '@frontend/ui-kit/Components/Select';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Separator from '@frontend/ui-kit/Components/Separator';
import SFTPCredentialsList, {validate as validateSFTPCredentialsList} from './SFTPCredentialsList';
import {Field} from '../../shared/FormComponents';
import LabeledTooltip from '../../shared/LabeledTooltip';
import {requestCompanyByAlias} from '../../../actions/general';
import useFormState from '../../../hooks/useFormState';
import {equal, validateRequired} from '../../../utils';
import {COMPANY_STAGES} from '../../../constants';
import {IMPORT_FILE_SOURCE_OPTIONS, PLATFORM_AUTOMATION_OPTIONS, SYNC_DEDUCTIBLE_TYPES_OPTIONS} from '../../../options';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    return {
        ...validateSFTPCredentialsList(values),
        sync_deductible_type: values.sync_deductible ? validateRequired(values.sync_deductible_type) : undefined
    };
};

const SFTP = ({isEditDisabled}) => {
    const dispatch = useDispatch();
    const {companyAlias} = useParams();
    const {values} = useFormState();
    const [isCompanyLaunching, setIsCompanyLaunching] = useState(false);

    const onChangeSyncDeductible = ({target}, form) => {
        if (!target.checked) {
            form.change('sync_deductible_type', null);
        }
    };

    useEffect(() => {
        (async () => {
            const {data: company} = await dispatch(requestCompanyByAlias(companyAlias));
            const {activity_stage: activityStage} = company;
            const isCompanyLaunching = equal(activityStage, COMPANY_STAGES.launch);

            setIsCompanyLaunching(isCompanyLaunching);
        })();
    }, [dispatch, companyAlias]);

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Text className='app-configuration-content__title' type={TEXT_TYPES.bodyBold}>SFTP Automation Settings</Text>

                <Row middle='xs'>
                    <Column sm={4}>
                        <Field name='use_sftp_for_import'>
                            {props => (
                                <Checkbox {...props}
                                    caption={<LabeledTooltip title='Enable Member Eligibility Imports' content={isCompanyLaunching ? 'This group has not launched yet. You cannot check this box until after launch day.' : null}/>}
                                    disabled={isEditDisabled || isCompanyLaunching}/>
                            )}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='automation_status'>
                            {props => <Select {...props} options={PLATFORM_AUTOMATION_OPTIONS} disabled={isEditDisabled} label='Automation Status'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='import_file_source'>
                            {props => <Select {...props} options={IMPORT_FILE_SOURCE_OPTIONS} disabled={isEditDisabled} label='Automation Import File Source'/>}
                        </Field>
                    </Column>
                </Row>

                <Row className='mt-24' middle='xs'>
                    <Column sm={4}/>
                    <Column sm={8}>
                        <Field name='automation_notes'>
                            {props => <Textarea {...props} maxLength={200} disabled={isEditDisabled} label='General Automation Notes'/>}
                        </Field>
                    </Column>
                </Row>

                <Separator className='mt-24 mb-24'/>

                <Row>
                    <Column sm={4}>
                        <Field name='sync_deductible' onChange={onChangeSyncDeductible}>
                            {props => <Checkbox {...props} caption='Sync Deductible' disabled={isEditDisabled}/>}
                        </Field>
                    </Column>
                    <Column sm={8}>
                        <Field name='sync_deductible_type'>
                            {props => <RadioGroup {...props} options={SYNC_DEDUCTIBLE_TYPES_OPTIONS} disabled={isEditDisabled || !values.sync_deductible} isInline isLabelInline label='Type:'/>}
                        </Field>
                    </Column>
                </Row>
            </ContentSection>

            <SFTPCredentialsList isEditDisabled={isEditDisabled} isCompanyLaunching={isCompanyLaunching}/>
        </div>
    );
};

SFTP.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {validate, SFTP as TestableSFTP};
export default React.memo(SFTP);
