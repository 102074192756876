import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Table from '@frontend/ui-kit/Components/Table';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Link from '@frontend/ui-kit/Components/Link';
import PendingGroupNotes from '../PendingGroupNotes';
import withPopup from '../../../HOC/withPopup';
import {
    requestEligibilityImportMigratedMembers,
    requestImportsJsonrpc,
    requestPendingMigrationsMembers
} from '../../../actions/adminPortal';
import {redirectTo} from '../../../actions/general';
import {CRM_URL, FILE_FORMATS, ROUTES, RELATIONSHIPS, JSON_RPC_RESULTS} from '../../../constants';
import {
    equal,
    generateUniqueId,
    isEmpty,
    formatDate,
    saveFile
} from '../../../utils';
import './index.scss';

const POPUP_ID = 'pendingMigrationsMembersPopup';

const TABLE_PAGE_SIZE = 20;

const TABLE_ROW_ID_SEPARATOR = '=_=';

const PendingMigrationsMembers = ({importData, groupAlias, openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const {id: importId} = useParams();
    const [table, setTable] = useState({unitCount: 0, pageIndex: 0, filterBy: null});
    const [tableData, setTableData] = useState([]);
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
    const [isAllMembersSelected, setIsAllMembersSelected] = useState(false);
    const [toggleAllRowsSelected, setToggleAllRowsSelected] = useState(null);
    const selectedMembersCount = useMemo(() => {
        const isAllMembersSelectedNoPagination = TABLE_PAGE_SIZE >= table?.unitCount ? tableData.length : table?.unitCount;
        return isAllMembersSelected ? isAllMembersSelectedNoPagination : selectedEmployeeIds.length;
    }, [isAllMembersSelected, selectedEmployeeIds, table, tableData]);
    const title = useMemo(() => {
        const membersPercentage = Math.round((table?.unitCount / importData?.general_statistics?.all_members_count) * 100);

        return `${table?.unitCount} Members ${isFinite(membersPercentage) ? `(${membersPercentage}%)` : ''}`;
    }, [importData, table]);
    const [tableRowProps, setTableRowProps] = useState({});
    // FYI: For properly disabling rows with no ext_id, we should use a new row_id value and store it for selection rows with pagination (state: {[row_id]: ext_id}) (Pasha, 8.23.24)
    const [rowIdData, setRowIdData] = useState({});

    const getRowId = (firstName, lastName, employeeId) => `${firstName}${TABLE_ROW_ID_SEPARATOR}${lastName}${TABLE_ROW_ID_SEPARATOR}${employeeId}`;

    const prepareTableData = migratedMembers => migratedMembers
        .reduce((acc, member, index) => {
            const {employee_id: employeeId, relationship_field: relationshipField} = member;

            if (!equal(relationshipField, RELATIONSHIPS.self)) {
                const employeeIndex = acc.findIndex(({employee_id: id, relationship_field: relationship}) => equal(id, employeeId) && equal(relationship, 'self'));

                if (equal(employeeIndex, -1)) {
                    acc[index] = {...member, no_self: true};
                } else {
                    acc[employeeIndex].subRows = (acc[employeeIndex].subRows ? [...acc[employeeIndex].subRows, member] : [member]);
                }
            }

            return acc;
        }, migratedMembers)
        .filter(({relationship_field}) => equal(RELATIONSHIPS.self, relationship_field));

    const setMigratedMembersData = ({migratedMembers = [], count, pageIndex, filterBy}) => {
        const enhancedMigratedMembers = migratedMembers.map(member => ({
            ...member,
            full_name: `${member?.last_name}, ${member?.first_name}`,
            row_id: getRowId(member?.first_name, member?.last_name, member?.employee_id)
        }));
        const rowProps = enhancedMigratedMembers.reduce((acc, {ext_id: extId, first_name: firstName, last_name: lastName, employee_id: employeeId}) => {
            return {...acc, ...!extId && {[getRowId(firstName, lastName, employeeId)]: {isSelectableDisabled: true}}};
        }, {});

        setTable({unitCount: count || migratedMembers.length, pageIndex, filterBy});
        setTableData(prepareTableData(enhancedMigratedMembers));
        setTableRowProps(rowProps);
    };

    const onFetchData = useCallback(async ({pageIndex = 0, pageSize = TABLE_PAGE_SIZE, filterBy}) => {
        const query = {
            ...groupAlias ? {alias: groupAlias} : {id: importId},
            limit: pageSize,
            offset: pageIndex * pageSize,
            ...filterBy && {
                name: filterBy,
                email: filterBy,
                employee_id: filterBy
            }
        };
        const requestMigratedMembers = groupAlias ? requestPendingMigrationsMembers : requestEligibilityImportMigratedMembers;
        const {isSuccess, migratedMembers, count} = await dispatch(requestMigratedMembers(query));

        if (!isSuccess) {
            return dispatch(redirectTo(ROUTES.eligibilityImports));
        }

        setMigratedMembersData({migratedMembers, count, pageIndex, filterBy});
    }, [importId, groupAlias, dispatch]);

    const onDownloadPendingMembers = async ({selectedIds}) => {
        const isMembersSelectedPartly = selectedIds && !isAllMembersSelected;
        const query = {
            alias: groupAlias,
            format_type: FILE_FORMATS.csv,
            ...isMembersSelectedPartly && {ext_ids: selectedIds.map(Number)}
        };
        const {isSuccess, fileData} = await dispatch(requestPendingMigrationsMembers(query));

        if (!isSuccess) {
            return;
        }

        const fileName = `pending_migration_${groupAlias}_${formatDate(new Date(), 'MM-dd-yyyy')}`;
        saveFile(`data:text/csv;charset=utf-8,${fileData}`, fileName);
    };

    const getTableColumns = () => {
        const getMultiInstance = ({value}) => value && <Icon type={ICON_TYPES.checkCircle} className='multi-instance-icon'/>;

        const getSystem = ({value: accountId}) => accountId && <Link data-testid='system-button' href={`${CRM_URL}/pha/crm/${accountId}`} target='_blank'>CRM</Link>;

        const getRelationship = ({value: relationship}) => equal(relationship, RELATIONSHIPS.self) ? 'Employee' : 'Dependent';

        return [
            {Header: 'Name', accessor: 'full_name', width: 200},
            {Header: 'Email', accessor: 'email', width: 200},
            {Header: 'Employee ID', accessor: 'employee_id', width: 200},
            {Header: 'Relationship', accessor: 'relationship_field', width: 200, Cell: getRelationship, isFilterable: false},
            {Header: 'Migration From', accessor: 'migrate_from', width: 200, isFilterable: false},
            {Header: 'Multi-Instance', accessor: 'multi_instance', width: 200, Cell: getMultiInstance, align: 'center', isFilterable: false},
            {Header: 'System', accessor: 'account_id', width: 220, Cell: getSystem, isFilterable: false}
        ];
    };

    const onConfirmAccess = isApprove => () => {
        const onConfirm = async () => {
            const jsonrpcObj = {
                jsonrpc: '2.0',
                method: isApprove ? 'approve_pending_migrations' : 'reject_pending_migrations',
                id: generateUniqueId(),
                params: {
                    company_alias: groupAlias,
                    account_ext_ids: selectedEmployeeIds,
                    all_pending_members: isAllMembersSelected
                }
            };
            const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

            if (!equal(jsonrpc?.result, JSON_RPC_RESULTS.success)) {
                return false;
            }

            closePopup();
            setSelectedEmployeeIds([]);
            onFetchData({pageIndex: table?.pageIndex, filterBy: table?.filterBy});
            // FYI: After removing members from table data selected rows are still in store. So we need to reset all selected rows manually (2.20.2024, Pasha)
            toggleAllRowsSelected(false);
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='cancel-popup-button' type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                {isApprove
                    ? <Button data-testid='approve-migration-button' type={BUTTON_TYPES.primary} onClick={onConfirm}>Yes, Approve Migration ({selectedMembersCount})</Button>
                    : <Button data-testid='concel-migration-button' type={BUTTON_TYPES.destructive} onClick={onConfirm}>Yes, Cancel Migration ({selectedMembersCount})</Button>}
            </React.Fragment>
        );
        const popupContent = (
            <React.Fragment>
                {isApprove
                    ? <Text>Are you sure you want to <b>approve migration</b> for {selectedMembersCount} selected members? Members will be removed from {groupAlias}.</Text>
                    : <Text>Are you sure you want to <b>cancel migration</b> for {selectedMembersCount} selected members? Members will continue to remain with their current group.</Text>}
                <Text>This will remove them from the group’s pending migration list.</Text>
            </React.Fragment>
        );

        const popupProps = {title: 'Confirmation Required', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const filterActionBar = groupAlias ? (
        <div className={classnames('selected-pending-members', {'selected-pending-members_disabled': isEmpty(selectedEmployeeIds)})}>
            <Text className='selected-pending-members__members'>
                <Text className='selected-pending-members__count mr-5' type={TEXT_TYPES.helperBold}>{selectedMembersCount}</Text>members selected:
            </Text>
            <Button onClick={onConfirmAccess(true)} data-testid='approve-migration' type={BUTTON_TYPES.tertiarySuccess} disabled={isEmpty(selectedEmployeeIds)} iconLeft={<Icon type={ICON_TYPES.confirm}/>}>Approve Migration</Button>
            <Button onClick={onConfirmAccess()} data-testid='cancel-migration' type={BUTTON_TYPES.tertiaryDestructive} disabled={isEmpty(selectedEmployeeIds)} iconLeft={<Icon type={ICON_TYPES.close}/>}>Cancel Migration</Button>
            <Button onClick={() => onDownloadPendingMembers({selectedIds: selectedEmployeeIds})} data-testid='download-selected' type={BUTTON_TYPES.tertiary} disabled={isEmpty(selectedEmployeeIds)} iconLeft={<Icon type={ICON_TYPES.download}/>}>Download Selected</Button>
        </div>
    ) : React.Fragment;

    const getExtIdByRowId = rowId => {
        if (rowIdData[rowId]) {
            return rowIdData[rowId];
        }

        const [firstName, lastName, employeeId] = rowId.split(TABLE_ROW_ID_SEPARATOR) || '';
        const {ext_id: extId} = tableData.find(({first_name, last_name, employee_id}) => equal(first_name, firstName) && equal(last_name, lastName) && equal(employee_id, employeeId)) || {};

        setRowIdData(state => ({...state, [rowId]: extId}));
        return extId || null;
    };

    const onSelectRow = useCallback(({selectedRowIds, isAllSelected, toggleAllRowsSelected}) => {
        const filteredSelectedRowIds = Object
            .keys(selectedRowIds)
            .map(getExtIdByRowId)
            .filter(Boolean);

        setIsAllMembersSelected(isAllSelected);
        setSelectedEmployeeIds(filteredSelectedRowIds);
        setToggleAllRowsSelected(() => toggleAllRowsSelected);
    }, [tableData]);

    const tableProps = {
        data: tableData,
        pageSize: TABLE_PAGE_SIZE,
        isSortable: true,
        isFilterable: true,
        isSubRowsSelectable: false,
        columns: getTableColumns(),
        filterPlaceholder: 'Search by name, email, or employee ID',
        filterActionBar,
        onFetchData,
        ...groupAlias && {onSelectRow},
        ...table,
        tableConfig: {
            autoResetSelectedRows: false,
            getRowId: row => row.row_id
        },
        rowProps: tableRowProps
    };

    return (
        <div className='pending-migrations-members'>
            {groupAlias && (
                <Alert className='mb-20' type={ALERT_TYPES.warning} description='Review the migration surge. Download pending migration list and reach out to Customer Success to confirm member status.'/>
            )}

            <Row middle='xs' columnGap='xs'>
                <Column xs>
                    <Heading type={HEADING_TYPES['1']}>{title}</Heading>
                </Column>
                {groupAlias && (
                    <Column constant>
                        <Button onClick={onDownloadPendingMembers} data-testid='download-members-button' type={BUTTON_TYPES.secondary} iconLeft={<Icon type={ICON_TYPES.download}/>}>Download List of Pending Members</Button>
                    </Column>
                )}
            </Row>

            {groupAlias && <PendingGroupNotes groupAlias={groupAlias}/>}

            <ContentSection className='mt-20'>
                {Array.isArray(tableData) && <Table {...tableProps}/>}
            </ContentSection>
        </div>
    );
};

PendingMigrationsMembers.propTypes = {
    importData: PropTypes.shape({
        general_statistics: PropTypes.shape({
            all_members_count: PropTypes.number
        })
    }),
    groupAlias: PropTypes.string,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {PendingMigrationsMembers as TestablePendingMigrationsMembers};
export default withPopup(POPUP_ID)(PendingMigrationsMembers);
