import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import Input from '@frontend/ui-kit/Components/Input';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CopyableField from '../CopyableField';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {normalizeBoolean, validateRequired, trimStart} from '../../../utils';
import {CONDITION_OPTIONS} from '../../../options';
import {DEFAULT_PHONE_PLACEHOLDER} from '../../../constants';

const netUrlTooltipContent = 'Please enter the provider search URL, such as a link from the carrier’s website.';
const netUrlAliasTooltipContent = 'This is how the link will appear on the wallet card.';

/* istanbul ignore next */
const validateOnSubmit = values => {
    const {is_additional_network: isAdditionalNetwork} = values;

    return {
        network_name: validateRequired(values.network_name),
        network_url: validateRequired(values.network_url),
        network_member_support_phone: validateRequired(values.network_member_support_phone),
        secondary_network_name: isAdditionalNetwork ? validateRequired(values.secondary_network_name) : undefined,
        secondary_network_url: isAdditionalNetwork ? validateRequired(values.secondary_network_url) : undefined,
        secondary_network_support_phone: isAdditionalNetwork ? validateRequired(values.secondary_network_support_phone) : undefined
    };
};

const ProviderNetworks = ({isReadonly, isCustomizedMode}) => {
    const form = useForm();
    const {values} = useFormState();
    const {is_additional_network: isAdditionalNetwork} = values;

    const resetFields = useCallback(fields => () => {
        return form.batch(() => fields.forEach(field => form.change(field, null)));
    }, [form]);

    return (
        <React.Fragment>
            {!isCustomizedMode && (
                <CopyableField name='network_name' parse={trimStart}>
                    {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label='Medical Network Name' isRequired wrapperClassName='mb-12'/>}
                </CopyableField>
            )}

            <CopyableField name='network_url' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter...' label={<LabeledTooltip title='Medical Network URL' content={netUrlTooltipContent}/>} isRequired={!isCustomizedMode} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='network_url_alias' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='e.g. Provider Directory' maxLength={24} label={<LabeledTooltip title='Medical Network URL Alias' content={netUrlAliasTooltipContent}/>} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='network_member_support_phone'>
                {props => <Input {...props} readonly={isReadonly} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Medical Network Phone Number' isRequired={!isCustomizedMode} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='is_additional_network'
                onChange={resetFields([
                    'secondary_network_name',
                    'secondary_network_url',
                    'secondary_network_url_alias',
                    'secondary_network_support_phone'
                ])}
                parse={normalizeBoolean}>
                {props => <RadioGroup {...props} readonly={isReadonly} options={CONDITION_OPTIONS} label='Does this plan have an additional provider network?' wrapperClassName='mb-7'/>}
            </CopyableField>

            {isAdditionalNetwork && (
                <React.Fragment>
                    {!isCustomizedMode && (
                        <CopyableField name='secondary_network_name' parse={trimStart}>
                            {props => <Input {...props} readonly={isReadonly} placeholder='Type network name...' label='Additional Medical Network Name' isRequired wrapperClassName='mb-12'/>}
                        </CopyableField>
                    )}

                    <CopyableField name='secondary_network_url' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='Type network url...' label={<LabeledTooltip title='Additional Medical Network URL' content={netUrlTooltipContent}/>} isRequired={!isCustomizedMode} wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='secondary_network_url_alias' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g. Provider Directory' maxLength={24} label={<LabeledTooltip title='Additional Medical Network URL Alias' content={netUrlAliasTooltipContent}/>} wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='secondary_network_support_phone' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Additional Medical Network Phone Number' isRequired={!isCustomizedMode}/>}
                    </CopyableField>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

ProviderNetworks.propTypes = {
    isReadonly: PropTypes.bool,
    isCustomizedMode: PropTypes.bool
};

export {validateOnSubmit, ProviderNetworks as TestableProviderNetworks};
export default ProviderNetworks;
