import {RECEIVE_MARKETING_PAGE_INFO} from '../actions/actionTypes';

export const initialState = {
    info: {
        sections: []
    }
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case RECEIVE_MARKETING_PAGE_INFO: {
            const {info} = payload;

            return {...state, info};
        }

        default: {
            return state;
        }
    }
};
