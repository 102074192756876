import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Select from '@frontend/ui-kit/Components/Select';
import Input from '@frontend/ui-kit/Components/Input';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import {Form, Field} from '../../shared/FormComponents';
import CustomizablePopup from '../../shared/CustomizablePopup';
import withPopup from '../../../HOC/withPopup';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import {showToast} from '../../../actions/shared';
import {redirectTo} from '../../../actions/general';
import {requestContactDeletion, requestContactInvitation, requestUserValidation} from '../../../actions/contacts';
import {getActiveCompany, getProfilePermissions} from '../../../selectors/general';
import {formatDate, validateEmail, validateRequired, equal, isEmail} from '../../../utils';
import {ROUTES, DEFAULT_PHONE_PLACEHOLDER, USER_ROLES} from '../../../constants';
import {USER_ROLE_OPTIONS, USER_TYPE_OPTIONS} from '../../../options';
import './index.scss';

const POPUP_ID = 'contactDeletionPopup';

const USER_ROLE_VALIDATION_MESSAGE = 'The email is already used for another role';

/* istanbul ignore next */
export const validate = isUserRoleAvailable => values => ({
    first_name: validateRequired(values.first_name),
    last_name: validateRequired(values.last_name),
    user_type: validateRequired(values.user_type),
    role: validateRequired(values.role) || (!isUserRoleAvailable ? USER_ROLE_VALIDATION_MESSAGE : undefined),
    title: validateRequired(values.title),
    email: validateEmail(values.email) || (!isUserRoleAvailable ? USER_ROLE_VALIDATION_MESSAGE : undefined),
    phone: validateRequired(values.phone)
});

const ContactInfoBasicForm = ({id, formName, isExisted, initialValues, openPopup, closePopup, onSubmit, onSubmitSuccess, onAddAdmin}) => {
    const [isUserRoleAvailable, setIsUserRoleAvailable] = useState(true);
    const dispatch = useDispatch();
    const {name: companyName, alias: companyAlias} = useSelector(getActiveCompany);
    const profilePermissions = useSelector(getProfilePermissions);
    const isReadonly = !profilePermissions.contact_create && !profilePermissions.contact_edit;

    const requestUserRoleAvailable = useCallback(async (email, role) => {
        const {isValid, isSuccess} = await dispatch(requestUserValidation({email, role, company_alias: companyAlias}));

        if (!isSuccess) {
            return false;
        }

        setIsUserRoleAvailable(isValid);
    }, [companyAlias, dispatch]);

    const goToBoard = () => dispatch(redirectTo(ROUTES.contacts));

    const reinvite = useCallback(async form => {
        const {isSuccess} = await dispatch(requestContactInvitation(id));

        if (!isSuccess) {
            return false;
        }

        form.restart();
        goToBoard();
        dispatch(showToast({content: 'Invitation resent!'}));
    }, [dispatch, id]);

    const deleteContact = useCallback(form => {
        const onDelete = async () => {
            const {isSuccess} = await dispatch(requestContactDeletion(id));

            if (!isSuccess) {
                return false;
            }

            form.restart();
            goToBoard();
            dispatch(showToast({content: 'Contact removed'}));
        };

        const popupProps = {
            title: 'Confirmation',
            content: `Are you sure you want to delete this contact? ${initialValues.full_name} will no longer be able to access the dashboard for ${companyName}`,
            actionBar: (
                <React.Fragment>
                    <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                    <Button type={BUTTON_TYPES.destructive} onClick={onDelete} data-testid='popup-remove-button'>Yes, Remove Contact</Button>
                </React.Fragment>
            )
        };

        return openPopup({type: POPUP_TYPES.simple, children: <CustomizablePopup {...popupProps}/>});
    }, [dispatch, initialValues, id]);

    const onUserRoleAvailable = useDebouncedCallback((email, role) => isEmail(email) && role && requestUserRoleAvailable(email, role));

    return (
        <ContentSection className='contact-info-basic-form'>
            {!isReadonly && <Text type={TEXT_TYPES.bodyBold}>{isExisted ? 'Edit' : 'Add'} Contact</Text>}
            <Form name={formName} initialValues={initialValues} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} validate={validate(isUserRoleAvailable)}>
                {({handleSubmit, form, values}) => {
                    const isAdmin = equal(values.role, USER_ROLES.admin);
                    const onReinvite = () => reinvite(form);
                    const onDelete = () => deleteContact(form);
                    const onSave = () => !isExisted && isAdmin ? onAddAdmin(values, handleSubmit) : handleSubmit();

                    const onEmailChange = ({target: {value: email}}) => onUserRoleAvailable(email, values.role);
                    const onRoleChange = role => onUserRoleAvailable(values.email, role);

                    return (
                        <form className='contact-form' noValidate>
                            <Row className='mb-16'>
                                <Column sm={6}>
                                    <Field name='first_name'>
                                        {props => <Input {...props} readonly={isReadonly} placeholder='Enter first name...' label='First Name' isRequired/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='last_name'>
                                        {props => <Input {...props} readonly={isReadonly} placeholder='Enter last name...' label='Last Name' isRequired/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Row className='mb-16'>
                                <Column sm={6}>
                                    <Field name='user_type'>
                                        {props => <Select {...props} readonly={isReadonly} options={USER_TYPE_OPTIONS} label='Contact Type' isRequired/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='role' onChange={onRoleChange}>
                                        {props => <Select {...props} readonly={isReadonly} options={USER_ROLE_OPTIONS} label='Role' isRequired/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Row className='mb-16'>
                                <Column sm={6}>
                                    <Field name='title'>
                                        {props => <Input {...props} readonly={isReadonly} placeholder='Enter title...' label='Title' isRequired/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='email' onChange={onEmailChange}>
                                        {props => <Input {...props} readonly={isReadonly} disabled={isExisted} maxLength={100} placeholder='Enter valid email...' label='Email Address' isRequired/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Row className='mb-16' middle='sm'>
                                <Column sm={6}>
                                    <Field name='phone'>
                                        {props => <Input {...props} readonly={isReadonly} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Phone' isRequired/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    <Field name='is_billing_contact'>
                                        {props => <Checkbox {...props} readonly={isReadonly} caption='Mark as Billing Contact'/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Row>
                                <Column sm={6}>
                                    <Field name='is_eligibility_contact'>
                                        {props => <Checkbox {...props} readonly={isReadonly} caption='Eligibility Contact'/>}
                                    </Field>
                                </Column>
                                <Column sm={6}>
                                    {isExisted && !initialValues.active && (
                                        <div className='invitation-wrapper'>
                                            <Text>Invitation email sent on {formatDate(initialValues.last_invited_at, 'MM/dd/yyyy')}</Text>
                                            <Button className='invitation-wrapper__button' type={BUTTON_TYPES.tertiary} onClick={onReinvite} data-testid='resend-invitation-button'>
                                                Resend Invitation
                                            </Button>
                                        </div>
                                    )}
                                </Column>
                            </Row>

                            <Separator type='solid'/>

                            <Row end='sm'>
                                <Column/>

                                <Column className='contact-form-action-bar' constant>
                                    <Button className='contact-form-action-bar__button' type={BUTTON_TYPES.secondary} onClick={goToBoard} data-testid='cancel-button'>
                                        {isReadonly ? 'View All Contacts' : 'Cancel'}
                                    </Button>

                                    {profilePermissions.contact_delete && isExisted && (
                                        <Button className='contact-form-action-bar__button' type={BUTTON_TYPES.destructive} onClick={onDelete} data-testid='remove-button'>
                                            Remove Contact
                                        </Button>
                                    )}

                                    {!isReadonly && (
                                        <Button className='contact-form-action-bar__button' type={BUTTON_TYPES.primary} onClick={onSave} data-testid='save-button'>
                                            {isExisted ? 'Save Changes' : 'Add & Invite Contact'}
                                        </Button>
                                    )}
                                </Column>
                            </Row>
                        </form>
                    );
                }}
            </Form>
        </ContentSection>
    );
};

ContactInfoBasicForm.propTypes = {
    isExisted: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    id: PropTypes.string,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        full_name: PropTypes.string,
        active: PropTypes.bool,
        last_invited_at: PropTypes.string,
        role: PropTypes.string,
        user_type: PropTypes.string,
        email: PropTypes.string
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func,
    onAddAdmin: PropTypes.func,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {ContactInfoBasicForm as TestableContactInfoBasicForm};
export default withPopup(POPUP_ID)(ContactInfoBasicForm);
