import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import CoverPage from '../CoverPage';
import ContentsPage from '../ContentsPage';
import MskProgramSummary from '../MskProgramSummary';
import MskProgramSummaryContinued from '../MskProgramSummaryContinued';
import MskMemberTestimonials from '../MskMemberTestimonials';
import Page from '../Page';
import {requestPdfReportInfo} from '../../../actions/pdfTemplate';
import {getPdfTemplateCompanyData, getPdfTemplateMetrics} from '../../../selectors/pdfTemplate';
import {CONTENTS_DESCRIPTION, REPORT_FULL_NAME, REPORT_NAME, REPORT_NOTATION} from './constants';
import {PDF_TEMPLATE_TYPES, ROOT_ELEMENT} from '../../../constants';
import {isEmpty} from '../../../utils';
import PdfText from '../PdfText';

const PAGES_SHIFT = 2;
const TESTIMONIAL_LENGTH_LIMIT = 2000;
const PAGE_BODY_HEIGHT = 700;
const TESTIMONIAL_MARGIN = 20;

const measureElement = async (element, elementClassName) => {
    const container = document.createElement('div');
    ROOT_ELEMENT.appendChild(container);

    ReactDOM.render(element, container);

    return await new Promise(resolve => setTimeout(() => {
        const {clientHeight: height, clientWidth: width} = ROOT_ELEMENT.querySelector(`.${elementClassName}`);

        // FYI: next line is needed for avoiding extra blank pages in generated PDF reports (21.06.2023, Oleh);
        container.style.display = 'none';
        ReactDOM.unmountComponentAtNode(container);
        resolve({height, width});
    }, 0));
};

const MskReport = () => {
    const [mskTestimonialsByPage, setMskTestimonialsByPage] = useState([]);
    const companyData = useSelector(getPdfTemplateCompanyData);
    const metrics = useSelector(getPdfTemplateMetrics);
    const dispatch = useDispatch();
    const {mskTestimonials} = metrics.mskMemberTestimonials;

    useEffect(() => {
        dispatch(requestPdfReportInfo(PDF_TEMPLATE_TYPES.mskReport));
    }, [dispatch]);

    useEffect(() => {
        if (isEmpty(mskTestimonials)) {
            return;
        }

        (async () => {
            const measuredTestimonials = await Promise.all(mskTestimonials.map(async (testimonial, index) => {
                const formattedTestimonial = testimonial.length > TESTIMONIAL_LENGTH_LIMIT
                    ? `${testimonial.slice(0, TESTIMONIAL_LENGTH_LIMIT)}...`
                    : testimonial;
                const testimonialClassName = `testimonial_${index}`;
                const {height} = await measureElement(
                    <Page><PdfText className={testimonialClassName}>{formattedTestimonial}</PdfText></Page>,
                    testimonialClassName
                );

                return {testimonial: formattedTestimonial, height};
            }));

            const {testimonialsByPage} = measuredTestimonials.reduce((acc, item) => {
                const totalTestimonialHeight = item.height + TESTIMONIAL_MARGIN;
                const isHeightLimitExceeded = acc.height + totalTestimonialHeight > PAGE_BODY_HEIGHT;

                const page = isHeightLimitExceeded ? acc.page + 1 : acc.page;
                const height = isHeightLimitExceeded ? totalTestimonialHeight : acc.height + totalTestimonialHeight;
                const testimonialsByPage = isHeightLimitExceeded
                    ? [...acc.testimonialsByPage, [item.testimonial]]
                    : [...acc.testimonialsByPage.slice(0, page), [...(acc.testimonialsByPage[page] ?? []), item.testimonial]];

                return {page, height, testimonialsByPage};
            }, {page: 0, height: 0, testimonialsByPage: []});

            setMskTestimonialsByPage(testimonialsByPage);
        })();
    }, [mskTestimonials]);

    const getPage = (content, index) => {
        const {company_name: companyName} = companyData;

        return (
            <Page key={index}
                pageNum={PAGES_SHIFT + index}
                title={`${REPORT_NAME} | ${companyName}`}
                notation={REPORT_NOTATION}>
                {content}
            </Page>
        );
    };

    const {report_start_date: startDate, report_end_date: endDate, company_name: companyName} = companyData;

    const coverPageProps = {title: REPORT_FULL_NAME, startDate, endDate, companyName};
    const contentsProps = {
        title: REPORT_NAME,
        description: CONTENTS_DESCRIPTION,
        contents: [
            {chapter: '1.0', title: 'Program Summary', page: 3},
            !isEmpty(mskTestimonials) && {chapter: '2.0', title: 'Member Testimonials', page: 5}
        ].filter(Boolean)
    };

    const pages = [
        <ContentsPage {...contentsProps}/>,
        <MskProgramSummary {...metrics.mskSummary}/>,
        <MskProgramSummaryContinued {...metrics.mskSummary}/>,
        ...mskTestimonialsByPage.map((mskTestimonials, index) => <MskMemberTestimonials mskTestimonials={mskTestimonials} isContinued={!!index}/>)
    ];

    const isLoaded = !isEmpty(companyData);

    return (
        <React.Fragment>
            {isLoaded && (
                <div className='performance-report'>
                    <CoverPage {...coverPageProps}/>

                    {pages.map(getPage)}
                </div>
            )}
        </React.Fragment>
    );
};

export {MskReport as TestableMskReport};
export default MskReport;
