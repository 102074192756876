import {
    RECEIVE_PLAN_MANAGER_BENEFITS_CATEGORIES,
    RECEIVE_CARRIER_MANAGER_CARRIERS,
    RECEIVE_PLAN_MANAGER_PLAN_VERSIONS,
    RECEIVE_PLAN_MANAGER_PLANS,
    RECEIVE_PLAN_MANAGER_BENEFITS_ORDERING,
    SET_MULTIPLE_CHOICE_CHOICES,
    SET_MULTIPLE_CHOICE_VALUES,
    CLEAR_MULTIPLE_CHOICE
} from '../actions/actionTypes';
import {MULTIPLE_CHOICE_DEFAULT_NAME} from '../constants';

export const initialState = {
    planManagerPlans: [],
    planManagerPlanVersions: [],
    planManagerBenefitsCategories: [],
    planManagerBenefitsOrdering: [],
    carrierManagerCarriers: [],
    multipleChoice: {
        [MULTIPLE_CHOICE_DEFAULT_NAME]: {
            choices: [], // FYI: multipleChoice.choices structure - [{name: '', type: ''}] (15.10.21, Pasha)
            values: {} // FYI: multipleChoice.values structure - {[type]: value} (15.10.21, Pasha)
        }
    }
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case RECEIVE_PLAN_MANAGER_PLANS: {
            const {planManagerPlans} = payload;

            return {...state, planManagerPlans};
        }

        case RECEIVE_PLAN_MANAGER_PLAN_VERSIONS: {
            const {planManagerPlanVersions} = payload;

            return {...state, planManagerPlanVersions};
        }

        case RECEIVE_CARRIER_MANAGER_CARRIERS: {
            const {carrierManagerCarriers} = payload;

            return {...state, carrierManagerCarriers};
        }

        case RECEIVE_PLAN_MANAGER_BENEFITS_CATEGORIES: {
            const {planManagerBenefitsCategories} = payload;

            return {...state, planManagerBenefitsCategories};
        }

        case RECEIVE_PLAN_MANAGER_BENEFITS_ORDERING: {
            const {planManagerBenefitsOrdering} = payload;

            return {...state, planManagerBenefitsOrdering};
        }

        case SET_MULTIPLE_CHOICE_CHOICES: {
            const {choices, name} = payload;

            return {...state, multipleChoice: {...state.multipleChoice, [name]: {...state.multipleChoice[name], choices}}};
        }

        case SET_MULTIPLE_CHOICE_VALUES: {
            const {values, name} = payload;

            return {...state, multipleChoice: {...state.multipleChoice, [name]: {...state.multipleChoice[name], values}}};
        }

        case CLEAR_MULTIPLE_CHOICE: {
            return {...state, multipleChoice: {default: {choices: [], values: {}}}};
        }

        default: {
            return state;
        }
    }
};
