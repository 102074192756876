import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import PerformanceReport from '../../Components/PdfTemplate/PerformanceReport';
import MskReport from '../../Components/PdfTemplate/MskReport';
import Loader from '../../Containers/shared/Loader';
import {getAuthorizationStatus} from '../../selectors/authorization';
import {authorizeByAppSecret} from '../../actions/authorization';
import {setPdfTemplateSubject} from '../../actions/pdfTemplate';
import {PDF_TEMPLATE_TYPES} from '../../constants';
import {parseQuery} from '../../utils';
import './index.scss';

const TEMPLATE_TYPE_MAP = {
    [PDF_TEMPLATE_TYPES.performanceReport]: PerformanceReport,
    [PDF_TEMPLATE_TYPES.mskReport]: MskReport
};

const PdfTemplate = ({match, location}) => {
    const isAuthorized = useSelector(getAuthorizationStatus);
    const dispatch = useDispatch();
    const {params} = match || {};
    const {templateType} = params || {};
    const TemplateComponent = TEMPLATE_TYPE_MAP[templateType];

    useEffect(() => {
        const {companyAlias, dataPath} = params || {};
        const {search} = location || {};
        const {app_id: appId, app_secret: appSecret} = parseQuery(search);

        dispatch(authorizeByAppSecret(appId, appSecret));
        dispatch(setPdfTemplateSubject(companyAlias, dataPath));
    }, [dispatch, location, params]);

    return (
        <div className='pdf-template'>
            <Loader/>

            {isAuthorized && <TemplateComponent/>}
        </div>
    );
};

PdfTemplate.propTypes = {
    location: PropTypes.shape({search: PropTypes.string}),
    match: PropTypes.shape({
        params: PropTypes.shape({
            companyAlias: PropTypes.string,
            dataPath: PropTypes.string,
            templateType: PropTypes.string
        })
    })
};

export {PdfTemplate as TestablePdfTemplate};
export default PdfTemplate;
