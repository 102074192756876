import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Text from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import DocumentUploader from '../DocumentUploader';
import DetailsActionBar from '../../shared/DetailsActionBar';
import {Form} from '../../shared/FormComponents';
import PlanFormHeader from '../PlanFormHeader';
import {requestBenefitGuideDetails, requestBenefitGuideDetailsSetting} from '../../../actions/benefits';
import {FORMS} from '../../../constants';

const BenefitGuide = ({isReadonly, onSuccess, title, planId}) => {
    const dispatch = useDispatch();
    const {planPeriodId} = useParams();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestBenefitGuideDetails(planPeriodId));
            setInitialValues({...data, id: planId});
        })();
    }, [dispatch, planPeriodId]);

    const onSubmit = async values => {
        const isCompleted = values.completed || values.meta.completed; // FYI: if plan is already completed according to requirements we need to send always true as "completed", even if it's save progress action (Pasha, 18.01.2021)
        const {isSuccess, submissionErrors, data} = await dispatch(requestBenefitGuideDetailsSetting(planPeriodId, {...values, completed: isCompleted}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(data);
    };

    return (
        <Form name={FORMS.benefitGuide} initialValues={initialValues} onSubmit={onSubmit}>
            {({handleSubmit}) => {
                const actionBarProps = {onSuccess, isReadonly, onSubmit: handleSubmit};

                return (
                    <form onSubmit={handleSubmit} noValidate data-testid='benefit-guide-form'>
                        <PlanFormHeader title={title} isReadonly={isReadonly}/>

                        <Row className='ancillary-plan-details-form'>
                            <Column className='ancillary-plan-details-form__column' sm={12}>
                                <DocumentUploader isReadonly={isReadonly}/>
                            </Column>

                            <Column className='ancillary-plan-details-form__column' sm>
                                <Text>Please upload benefit guide.</Text>
                            </Column>
                        </Row>

                        <DetailsActionBar {...actionBarProps}/>
                    </form>
                );
            }}
        </Form>
    );
};

BenefitGuide.propTypes = {
    isReadonly: PropTypes.bool,
    title: PropTypes.string,
    planId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func
};

export {BenefitGuide as TestableBenefitGuide};
export default BenefitGuide;
