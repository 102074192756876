import React, {useState} from 'react';
import classnames from 'classnames';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import useFormState from '../../../../hooks/useFormState';
import {FONT_SCALE_OPTIONS} from '../../../../options';
import './index.scss';

const CardPreviewPopup = () => {
    const [fontScale, setFontScale] = useState(FONT_SCALE_OPTIONS[0].value);
    const {values} = useFormState();

    const popupContent = (
        <div className='card-preview-popup'>
            <div className={classnames('card-preview-popup__container mb-6', {
                'without-image': !values?.background?.image_url,
                'without-cta': !values?.cta?.is_enabled
            })}
                style={{
                    backgroundColor: values?.background?.color,
                    color: values?.title?.color,
                    fontSize: `${fontScale}em`
                }}>

                {values?.background?.image_url && (
                    <img data-testid='preview-image' className='card-preview-popup__background-image' src={values.background.image_url} alt='card-background'/>
                )}

                {(values?.category?.text || values?.label?.text) && (
                    <div data-testid='preview-header' className='card-preview-popup__header'>
                        <span>{values?.category?.text}</span>

                        {values?.label?.is_enabled && (
                            <span data-testid='preview-header-label'
                                className='preview-header-label'
                                style={{
                                    color: values?.label?.color,
                                    backgroundColor: values?.label?.background_color
                                }}>
                                {values?.label?.text}
                            </span>
                        )}
                    </div>
                )}

                <p className='card-preview-popup__title'>{values?.title?.text}</p>
                <p>{values?.description?.text}</p>
                {values?.cta?.is_enabled && (
                    <span data-testid='preview-cta-label' className='card-preview-popup__cta' style={{color: values?.cta?.color}}>{values?.cta?.text}</span>
                )}
            </div>

            <RadioGroup value={fontScale}
                onChange={({target}) => setFontScale(Number(target.value))}
                wrapperClassName='mb-15'
                options={FONT_SCALE_OPTIONS}
                label='Check how the card will look when the font is scaled'
                isInline/>
        </div>
    );

    const popupProps = {title: 'In-app preview of Health Recommendation card', children: popupContent};

    return <PopupContent {...popupProps}/>;
};

export {CardPreviewPopup as TestableCardPreviewPopup};
export default CardPreviewPopup;
