import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Button from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import {requestTermsAndConditionsSignUpdating} from '../../../actions/general';
import {TERMS_AND_CONDITIONS} from './constants';
import './index.scss';

const TermsAndConditionsPopup = ({onClose}) => {
    const dispatch = useDispatch();
    const onAgree = useCallback(async () => {
        const {isSuccess} = await dispatch(requestTermsAndConditionsSignUpdating({isSigned: true}));

        if (!isSuccess) {
            return;
        }

        onClose();
    }, [dispatch, onClose]);

    return (
        <div data-testid='terms-and-conditions-popup' className='terms-and-conditions-popup'>
            <Heading className='terms-and-conditions-popup__title mb-8' type={HEADING_TYPES['1']}>
                Terms and Conditions
            </Heading>

            <Text className='terms-and-conditions-popup__content mb-16'>{TERMS_AND_CONDITIONS}</Text>

            <div className='terms-and-conditions-popup__action-bar'>
                <Text className='mb-16'>
                    By clicking “Agree and Continue” you agree to the
                    HealthJoy <Link href='https://www.healthjoy.com/client-terms-conditions' target='_blank'>Client Terms & Conditions</Link>.
                </Text>

                <Button className='action-bar__button' onClick={onAgree} data-testid='agree-and-continue-button'>
                    Agree and Continue
                </Button>
            </div>
        </div>
    );
};

TermsAndConditionsPopup.propTypes = {
    onClose: PropTypes.func.isRequired
};

export {TermsAndConditionsPopup as TestableTermsAndConditionsPopup};
export default React.memo(TermsAndConditionsPopup);
