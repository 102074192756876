import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import DropdownList, {DROPDOWN_LIST_ALIGNING} from '@frontend/ui-kit/Components/DropdownList';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {getEqual} from '../../../utils';
import './index.scss';

const ActionButton = ({listTitle, options, className, children, buttonClassName, buttonType}) => {
    const normalizedOptions = useMemo(() => options.map(({label}) => ({label, value: label})), [options]);

    const getDropdownInitiator = ({isOpened}) => {
        const iconType = isOpened ? ICON_TYPES.chevronUp : ICON_TYPES.chevronDown;

        return (
            <Button type={buttonType} className={`action-button ${buttonClassName}`} disabled={isOpened}>
                {children}
                <Icon className='action-button__icon' type={iconType}/>
            </Button>
        );
    };

    const onDropdownListChange = value => {
        const {action} = options.find(getEqual(value, 'label'));

        action();
    };

    const dropdownListProps = {
        className,
        aligning: DROPDOWN_LIST_ALIGNING.center,
        title: listTitle,
        options: normalizedOptions,
        renderDropdownInitiator: getDropdownInitiator,
        onChange: onDropdownListChange
    };

    return (
        <DropdownList {...dropdownListProps}/>
    );
};

ActionButton.propTypes = {
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    buttonType: PropTypes.string,
    listTitle: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        action: PropTypes.func
    })).isRequired,
    children: PropTypes.node
};

ActionButton.defaultProps = {
    className: '',
    buttonClassName: '',
    buttonType: BUTTON_TYPES.primary,
    listTitle: 'Details'
};

export {ActionButton as TestableActionButton};
export default ActionButton;
