import React from 'react';
import BlockerErrorsReport from '../../../Components/AdminPortal/ImportBlockerErrorsReport';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'group_name'},
    {name: 'Blocker Errors Report'},
    {skip: true}
];

const ImportBlockerErrorsReport = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <BlockerErrorsReport/>
    </React.Fragment>
);

export {ImportBlockerErrorsReport as TestableImportBlockerErrorsReport};
export default React.memo(ImportBlockerErrorsReport);
