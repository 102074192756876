import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import TextSection from '../TextSection';

const MemberUtilizationContinued = props => {
    const {
        isTelemedVisible,
        isMedicalBillVisible,
        totalServiceRequestsChartData
    } = props;

    const totalServiceRequestsChartProps = {
        Chart: LineChart,
        title: 'Total Service Requests',
        description: `
            The total number of service requests that have been completed for your company. Service Requests include
            ${isTelemedVisible ? 'Telemedicine Consults, ' : ''}RX Savings Reviews, Provider and Facility Recommendations,
            Appointment Schedules, ${isMedicalBillVisible ? 'Medical Bill Reviews, ' : ''}and Benefits Questions asked via phone call or chat.
        `,
        data: totalServiceRequestsChartData
    };

    return (
        <PageContent title='3.0 Member Utilization Continued'>
            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...totalServiceRequestsChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Total Service Requests' data-testid='total-service-request-description'>
                        The total number of service requests that have been completed for your company.
                        Service Requests include {isTelemedVisible && 'Telemedicine Consults,'} RX Savings Reviews, Provider and Facility Recommendations,
                        Appointment Schedules, {isMedicalBillVisible && 'Medical Bill Reviews,'} and Benefits Questions asked via phone call or chat.
                    </TextSection>
                </Column>
            </Row>
        </PageContent>
    );
};

MemberUtilizationContinued.propTypes = {
    isTelemedVisible: PropTypes.bool,
    isMedicalBillVisible: PropTypes.bool,
    totalServiceRequestsChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    })
};

export {MemberUtilizationContinued as TestableMemberUtilizationContinued};
export default MemberUtilizationContinued;
