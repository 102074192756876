import React from 'react';
import PropTypes from 'prop-types';
import PdfText from '../PdfText';
import './index.scss';

const getContentsItem = ({chapter, title, page}, index) => (
    <div className='contents-item' key={index}>
        <PdfText className='contents-item__text contents-item__text_chapter' type='body-bold'>{chapter}</PdfText>
        <PdfText className='contents-item__text contents-item__text_title'>{title}</PdfText>
        <PdfText className='contents-item__text'>pg {page}</PdfText>
    </div>
);

const Contents = ({contents}) => (
    <div className='pdf-contents'>
        {contents.map(getContentsItem)}
    </div>
);

Contents.propTypes = {
    contents: PropTypes.arrayOf(PropTypes.shape({
        chapter: PropTypes.string,
        title: PropTypes.string,
        page: PropTypes.number
    })).isRequired
};

export {Contents as TestableContents};
export default Contents;
