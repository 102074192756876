import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import TPATweakContent from '../TPATweakContent';
import {Form} from '../../shared/FormComponents';
import {FORMS, TPA_GROUP_TWEAK_JSON_FIELDS} from '../../../constants';
import {
    requestIndividualConfig,
    requestTpaConfigTweaksUpdating,
    requestTPAGroupTweak,
    requestTPAGroupTweakUpdating
} from '../../../actions/adminPortal';
import {
    getIndividualConfig,
    getParsedImportConfigData,
    getStringifiedImportConfigData,
    getTPAGroupTweakValidation
} from '../../../helpers';
import {getRegisteredFieldsValues} from '../../../utils';

const TPAAutoimportGroupConfig = ({onChangeActiveStep}) => {
    const dispatch = useDispatch();
    const {group_alias: groupAlias, partner: partnerId} = useParams();
    const [initialValues, setInitialValues] = useState({});

    const getStringifiedTweak = tweak => getStringifiedImportConfigData(TPA_GROUP_TWEAK_JSON_FIELDS, {import_config: tweak}).import_config;

    const getParsedTweak = tweak => getParsedImportConfigData(TPA_GROUP_TWEAK_JSON_FIELDS, {import_config: tweak}).import_config;

    const setDefaultValues = async () => {
        const {data: configs} = await dispatch(requestIndividualConfig({companyAlias: groupAlias}));
        const individualConfig = getIndividualConfig(false, configs);
        const stringifiedTweak = getStringifiedTweak(individualConfig);

        setInitialValues(stringifiedTweak);
    };

    useEffect(() => {
        (async () => {
            const {tweak, isSuccess} = await dispatch(requestTPAGroupTweak(groupAlias));

            if (!isSuccess) {
                setDefaultValues();

                return false;
            }

            const stringifiedTweak = getStringifiedTweak(tweak);
            setInitialValues(stringifiedTweak);
        })();
    }, [dispatch, groupAlias]);

    const onSubmit = async (values, {getRegisteredFields}) => {
        const registeredFieldsValues = getRegisteredFieldsValues(getRegisteredFields(), values);
        const parsedTweak = getParsedTweak(registeredFieldsValues);
        const {tweak, isSuccess, submissionErrors} = await dispatch(requestTPAGroupTweakUpdating(groupAlias, parsedTweak));

        if (!isSuccess) {
            return submissionErrors;
        }

        const stringifiedTweak = getStringifiedTweak(tweak);
        setInitialValues(stringifiedTweak);
    };

    const onSubmitSuccess = ({values}) => {
        dispatch(requestTpaConfigTweaksUpdating({tpa_partner_id: partnerId, company_alias: groupAlias}));

        if (values?.is_submit) {
            onChangeActiveStep('upload_file');
        }
    };

    return (
        <Form name={FORMS.configureTPAAutoimportGroup} initialValues={initialValues} validate={getTPAGroupTweakValidation} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit, form}) => {
                const onSubmitClicked = () => {
                    form.change('is_submit', true);
                    handleSubmit();
                };

                const onSaveClicked = () => {
                    form.change('is_submit', false);
                    handleSubmit();
                };

                return (
                    <form noValidate data-testid='configure-tpa-group'>
                        <ContentSection className='mt-20'>
                            <TPATweakContent/>
                        </ContentSection>

                        <Row end='xs' className='mt-20'>
                            <Button data-testid='save-tweak-button' className='mr-10' onClick={onSaveClicked} type={BUTTON_TYPES.secondary}>Save Progress</Button>
                            <Button data-testid='submit-continue-tweak-button' className='mr-5' onClick={onSubmitClicked}>Save & Continue</Button>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

TPAAutoimportGroupConfig.propTypes = {
    onChangeActiveStep: PropTypes.func
};

export {TPAAutoimportGroupConfig as TestableTPAAutoimportGroupConfig};
export default TPAAutoimportGroupConfig;
