import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import {Field} from '../../shared/FormComponents';
import {EAP_ELIGIBILITY_OPTIONS} from '../../../options';
import useFormState from '../../../hooks/useFormState';
import {formatDate} from '../../../utils';

const EmployeeAssistanceProgram = ({isEditDisabled}) => {
    const {values} = useFormState();

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={4}>
                        <Field name='eap_enabled_for'>
                            {props => <Select {...props} options={EAP_ELIGIBILITY_OPTIONS} disabled={isEditDisabled} label='Eligibility'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.eap_effective_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.eap_expiration_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                </Row>

                <Separator/>

                <Row>
                    <Column sm={4}>
                        <Input value={values.eap_product} description='This field is populated from Salesforce' disabled label='EAP Product'/>
                    </Column>
                    <Column sm={4}>
                        <Field name='eap_login'>
                            {props => <Input {...props} disabled={isEditDisabled} label='CuraLink Portal Login'/>}
                        </Field>
                    </Column>
                </Row>
            </ContentSection>
        </div>
    );
};

EmployeeAssistanceProgram.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {EmployeeAssistanceProgram as TestableEmployeeAssistanceProgram};
export default React.memo(EmployeeAssistanceProgram);
