import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import SFTPHostPopup from '../SFTPHostPopup';
import SFTPCredentialsInfo, {validate as validateSFTPCredentialsInfo} from '../SFTPCredentialsInfo';
import {FieldArray} from '../../../shared/FormComponents';
import withPopup from '../../../../HOC/withPopup';
import {getDefaultSFTPCredentialsInfo} from '../../../../helpers';
import {compose, getEqual, isEmptyNested} from '../../../../utils';
import {SFTP_SETTINGS_TYPES} from '../../../../constants';
import './index.scss';

const POPUP_ID = 'SFTPCredentialsListPopup';
const MAX_SFTP_CREDENTIALS_INFO_COUNT = 4;

/* istanbul ignore next */
const validate = values => {
    return {
        creds: values.creds?.map(validateSFTPCredentialsInfo)
    };
};

const SFTPCredentialsList = ({isEditDisabled, isCompanyLaunching, closePopup, openPopup}) => {
    const onOpenSFTPHostPopup = SFTPCredentialsInfoFields => {
        const onSave = ({host, host_type}) => {
            SFTPCredentialsInfoFields.push({host, host_type, ...getDefaultSFTPCredentialsInfo(host_type)});
            closePopup();
        };

        return openPopup({type: POPUP_TYPES.simple, children: <SFTPHostPopup onClose={closePopup} onSave={onSave}/>});
    };

    return (
        <div className='sftp-credentials-list'>
            <FieldArray name='creds'>{({fields = [], meta = {}}) => {
                const settingsActivity = Object.values(SFTP_SETTINGS_TYPES).reduce((acc, type) => {
                    const isActive = fields.value?.some(({settings}) => settings.find(getEqual(type, 'type'))?.active) ?? false;

                    return {...acc, [type]: isActive};
                }, {});

                return (
                    <React.Fragment>
                        {fields.map((field, index) => {
                            const isValid = !meta.submitFailed || isEmptyNested(meta.error?.[index]);
                            const credentialsInfoProps = {fieldName: field, settingsActivity, isEditDisabled, isCompanyLaunching, closePopup, openPopup};
                            const collapseProps = {
                                key: field,
                                isOpened: true,
                                hasCollapseIcon: true,
                                initiator: <Text type={TEXT_TYPES.bodyBold}>SFTP #{index + 1} Credential Information</Text>,
                                actionBar: (
                                    <Button disabled={isEditDisabled} type={BUTTON_TYPES.tertiary} onClick={() => fields.remove(index)}>
                                        Delete SFTP #{index + 1}
                                    </Button>
                                ),
                                content: <SFTPCredentialsInfo {...credentialsInfoProps}/>
                            };

                            return (
                                <ContentSection key={field} className={classnames('app-configuration-content__section', {'app-configuration-content__section_invalid': !isValid})}>
                                    <Collapse {...collapseProps}/>
                                </ContentSection>
                            );
                        })}

                        {fields.length < MAX_SFTP_CREDENTIALS_INFO_COUNT && (
                            <div className='wrapper-highlighter'>
                                <Button className='button-wrapper__button' disabled={isEditDisabled} type={BUTTON_TYPES.tertiary} onClick={() => onOpenSFTPHostPopup(fields)} data-testid='add-cred-info-button'>
                                    <Icon type={ICON_TYPES.circlePlus}/> Add SFTP Credential Information
                                </Button>
                            </div>
                        )}
                    </React.Fragment>
                );
            }}
            </FieldArray>
        </div>
    );
};

SFTPCredentialsList.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired,
    isCompanyLaunching: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    openPopup: PropTypes.func.isRequired
};

export {validate, SFTPCredentialsList as TestableSFTPCredentialsList};
export default compose(
    withPopup(POPUP_ID),
    React.memo
)(SFTPCredentialsList);
