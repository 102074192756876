import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import MultiSelectInput from '@frontend/ui-kit/Components/MultiSelectInput';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import {Field} from '../../shared/FormComponents';

const JSON_FIELD_PLACEHOLDER = 'Add JSON values...';

const TPATweakContent = ({field = ''}) => {
    const AdditionalFieldsContent = (
        <Row rowGap='md'>
            <Column sm={6}>
                <Field name={`${field}inherit_member_ids_for_dependents`}>
                    {props => <Checkbox {...props} caption='Inherit Member IDs for Dependents'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}inherit_core_plans`}>
                    {props => <Checkbox {...props} caption='Inherit Core Plans'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}inherit_ancillary_plans`}>
                    {props => <Checkbox {...props} caption='Inherit Ancillary Plans'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}invite_relatives`}>
                    {props => <Checkbox {...props} caption='Invite Relatives'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}inherit_core_condition`}>
                    {props => <Input {...props} label='Inherit Core Condition' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}ignore_fields`}>
                    {props => <Input {...props} label='Ignore Fields' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}custom_fields`}>
                    {props => <MultiSelectInput {...props} label='Custom Fields'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}ignore_termination_date_condition`}>
                    {props => <Input {...props} label='Ignore Termination Date Condition' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}delete_plans_if_empty`}>
                    {props => <Checkbox {...props} caption='Delete Plans if Empty'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}delete_ancillary_plans_if_empty`}>
                    {props => <Checkbox {...props} caption='Delete Ancillary Plans if Empty'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}terminate_if_not_in_file`}>
                    {props => <Checkbox {...props} caption='Terminate if Not in File'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}cobra_in_separate_file`}>
                    {props => <Checkbox {...props} caption='COBRA in Separate File'/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}cobra_condition`}>
                    {props => <Input {...props} label='COBRA Condition' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                </Field>
            </Column>

            <Column sm={6}>
                <Field name={`${field}cobra_file_pattern`}>
                    {props => <Input {...props} label='Cobra Manual File Pattern'/>}
                </Field>
            </Column>
        </Row>
    );

    return (
        <React.Fragment>
            <Row className='pt-10' rowGap='md'>
                <Column sm={6}>
                    <Field name={`${field}allowable_condition`}>
                        {props => <Input {...props} label='Allowable Condition' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'A single column can be listed here. Users with a populated value for that column will be imported.'}} description='Example: {"column_name_from_file" : "value"}' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                    </Field>
                </Column>

                <Column sm={6}>
                    <Field name={`${field}not_allowable_condition`}>
                        {props => <Input {...props} label='Not Allowable Condition' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'Multiple fields can be listed. Users who have a value associated with 1 or more of these fields will NOT be imported.'}} description='Example: [{"key1": "Value 1"}, {"key1": "Value 2"}]' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                    </Field>
                </Column>

                <Column sm={6}>
                    <Field name={`${field}allowable_condition_or`}>
                        {props => <Input {...props} label='Allowable Condition Or' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'Multiple fields can be listed. Users must have a value associated with 1 or more of these fields in order to be imported.'}} description='Example: [{"key1": "Value 1"}, {"key1": "Value 2"}]' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                    </Field>
                </Column>

                <Column sm={6}>
                    <Field name={`${field}not_empty_fields`}>
                        {props => <MultiSelectInput {...props} label='Not Empty Fields' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'A single column can be listed here. Users must have a populated value for that column in order to be imported.'}}/>}
                    </Field>
                </Column>

                <Column sm={6}>
                    <Field name={`${field}not_empty_fields_or`}>
                        {props => <MultiSelectInput {...props} label='Not Empty Fields Or' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'Multiple fields can be listed. Users must have a value associated with at least 1 of the listed fields in order to be imported.'}}/>}
                    </Field>
                </Column>

                <Column sm={6}>
                    <Field name={`${field}empty_fields`}>
                        {props => <MultiSelectInput {...props} label='Empty Fields' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'A single column can be listed here. Users must have and empty value associated with the column in order to be imported.'}}/>}
                    </Field>
                </Column>

                <Column sm={6}>
                    <Field name={`${field}empty_fields_or`}>
                        {props => <MultiSelectInput {...props} label='Empty Fields Or' icon={ICON_TYPES.alertCircle} tooltipProps={{content: 'Multiple fields can be listed. Users must have an empty value associated at least 1 of the listed field in order to be imported.'}}/>}
                    </Field>
                </Column>

                <Column sm={6}>
                    <Field name={`${field}ignore_members`}>
                        {props => <Textarea {...props} label='Ignore Members' icon={ICON_TYPES.info} tooltipProps={{content: 'Values listed here will NOT be processed.'}} description='Example: {"column_name_from_file" : "value"}' placeholder={JSON_FIELD_PLACEHOLDER}/>}
                    </Field>
                </Column>
            </Row>

            <Separator/>

            <Collapse hasCollapseIcon initiator={<Heading type={HEADING_TYPES['4']}>Additional Fields</Heading>} content={AdditionalFieldsContent}/>
        </React.Fragment>
    );
};

TPATweakContent.propTypes = {
    field: PropTypes.string
};

export {TPATweakContent as TestableTPATweakContent};
export default React.memo(TPATweakContent);
