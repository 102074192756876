import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ImportCardDetails from '../../ImportCardDetails';
import {equal, toCapitalize} from '../../../../utils';
import {ANCILLARY_PLANS_DATA_BY_CATEGORY} from '../../../../constants';
import './index.scss';

const BASE_PLAN_LABELS = {
    active: 'Active',
    active_new: 'Active New',
    active_removed: 'Active Removed',
    active_existing: 'Active Existing',
    expired: 'Expired',
    future: 'Future',
    unknown: 'Unknown',
    member_ids: 'Member IDs',
    medical_ids_equal_to_ssn: 'IDs may be SSN',
    dependents_inherited_plan: 'Dependents Inherited Plan',
    duplicated_member_ids_in_file: 'Duplicated Member IDs in File',
    existing_member_ids_in_db: 'Existing Member IDs in DB'
};

const BASE_CORE_PLANS_ORDER = ['expired', 'future', 'unknown', 'member_ids', 'duplicated_member_ids_in_file', 'existing_member_ids_in_db', 'medical_ids_equal_to_ssn', 'dependents_inherited_plan', 'plans'];
const CORE_PLANS_ORDER = ['active_new', 'active_existing', 'active_removed', ...BASE_CORE_PLANS_ORDER];
const COBRA_CORE_PLANS_ORDER = ['active', ...BASE_CORE_PLANS_ORDER];

const BenefitStatistics = ({benefits_statistics: benefitsStats = {}}) => {
    const {medical, dental, vision, medical_cobra: medicalCobra, dental_cobra: dentalCobra, vision_cobra: visionCobra, ancillary} = benefitsStats;
    const {plans: ancillaryPlans = []} = ancillary || {};

    const getEnhancedData = (data, type, order = CORE_PLANS_ORDER) => order.map(name => {
        if (equal(name, 'plans')) {
            return data[name].map(({name, members_count: value}) => ({key: name, value}));
        }

        return ({key: BASE_PLAN_LABELS[name], value: data[name] || 0});
    }).flat();

    const corePlanDetails = useMemo(() => [
        {title: 'Medical', data: getEnhancedData(medical, 'Medical')},
        {title: 'Dental', data: getEnhancedData(dental, 'Dental')},
        {title: 'Vision', data: getEnhancedData(vision, 'Vision')}
    ], [medical, dental, vision]);

    const cobraPlanDetails = useMemo(() => [
        {title: 'COBRA Medical', data: getEnhancedData(medicalCobra, 'COBRA Medical', COBRA_CORE_PLANS_ORDER)},
        {title: 'COBRA Dental', data: getEnhancedData(dentalCobra, 'COBRA Dental', COBRA_CORE_PLANS_ORDER)},
        {title: 'COBRA Vision', data: getEnhancedData(visionCobra, 'COBRA Vision', COBRA_CORE_PLANS_ORDER)}
    ], [medicalCobra, dentalCobra, visionCobra]);

    const getAncillaryName = category => category.split('_').map(word => toCapitalize(word)).join(' ');

    const ancillaryData = ancillaryPlans.reduce((acc, detail) => {
        const {category, members_active_new_count: activeNewCount, members_active_existing_count: activeExistingCount, members_active_removed_count: activeRemovedCount} = detail || {};
        const categoryName = ANCILLARY_PLANS_DATA_BY_CATEGORY[category]?.label || getAncillaryName(category);

        return ({
            activeNewData: [...acc.activeNewData, {key: categoryName, value: activeNewCount || 0}],
            activeExistingData: [...acc.activeExistingData, {key: categoryName, value: activeExistingCount || 0}],
            activeRemovedData: [...acc.activeRemovedData, {key: categoryName, value: activeRemovedCount || 0}]
        });
    }, {activeNewData: [], activeExistingData: [], activeRemovedData: []});

    return (
        <div className='benefit-statistics'>
            <Heading className='mb-10'>Core Plan Details</Heading>

            <Row>
                {corePlanDetails.map(({title, data}) => (
                    <Column key={title} sm={4}>
                        <ImportCardDetails title={title} data={data}/>
                    </Column>
                ))}
            </Row>

            <Separator/>

            <Heading className='mb-10'>Ancillary Plan Details</Heading>

            <Row>
                <Column sm={4}>
                    <ImportCardDetails title='Active New' data={ancillaryData.activeNewData}/>
                </Column>

                <Column sm={4}>
                    <ImportCardDetails title='Active Existing' data={ancillaryData.activeExistingData}/>
                </Column>

                <Column sm={4}>
                    <ImportCardDetails title='Active Removed' data={ancillaryData.activeRemovedData}/>
                </Column>
            </Row>

            <Separator/>

            <Heading className='mb-10'>COBRA Plan Details</Heading>

            <Row>
                {cobraPlanDetails.map(({title, data}) => (
                    <Column key={title} sm={4}>
                        <ImportCardDetails title={title} data={data}/>
                    </Column>
                ))}
            </Row>
        </div>
    );
};

const BaseCoreDetails = {
    expired: PropTypes.number,
    future: PropTypes.number,
    unknown: PropTypes.number,
    member_ids: PropTypes.number,
    medical_ids_equal_to_ssn: PropTypes.number,
    dependents_inherited_plan: PropTypes.number,
    duplicated_member_ids_in_file: PropTypes.number,
    existing_member_ids_in_db: PropTypes.number,
    plans: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string, members_count: PropTypes.number}))
};

const CoreDetails = {
    ...BaseCoreDetails,
    active_new: PropTypes.number,
    active_removed: PropTypes.number,
    active_existing: PropTypes.number
};

const CobraCoreDetails = {
    ...BaseCoreDetails,
    active: PropTypes.number
};

BenefitStatistics.propTypes = {
    benefits_statistics: PropTypes.shape({
        medical: PropTypes.shape({...CoreDetails}),
        dental: PropTypes.shape({...CoreDetails}),
        vision: PropTypes.shape({...CoreDetails}),
        medical_cobra: PropTypes.shape({...CobraCoreDetails}),
        dental_cobra: PropTypes.shape({...CobraCoreDetails}),
        vision_cobra: PropTypes.shape({...CobraCoreDetails}),
        ancillary: PropTypes.shape({
            plans: PropTypes.arrayOf(PropTypes.shape({category: PropTypes.string, members_count: PropTypes.number}))
        })
    })
};

export {BenefitStatistics as TestableBenefitStatistics};
export default React.memo(BenefitStatistics);
