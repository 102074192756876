import React from 'react';
import PageContent from '../PageContent';
import PdfTable from '../PdfTable';
import PdfText from '../PdfText';
import './index.scss';

const TABLE_DATA = [
    {
        category: 'Dental/Vision Provider',
        savings: '$50',
        explanation: (
            <React.Fragment>
                This is based on the average difference between contract negotiated rates vs. average rates for dental and eye care services.
            </React.Fragment>
        )
    },
    {
        category: 'Imaging (Freestanding)',
        savings: '$450',
        explanation: (
            <React.Fragment>
                Patients save money by avoiding hospital markups.
                $450 savings is based on an internal analysis of savings comparing the fee for HealthJoy-recommended facilities with average costs listed by MD Save.
            </React.Fragment>
        )
    },
    {
        category: 'Lab (Freestanding)',
        savings: '$170',
        explanation: (
            <React.Fragment>
                Patients save money by avoiding hospital charges for lab work.
                $170 savings is based on an internal analysis of savings comparing the fee for HealthJoy-recommended facilities with average costs listed by MD Save.
            </React.Fragment>
        )
    },
    {
        category: 'Mental Health Provider',
        savings: '$115',
        explanation: (
            <React.Fragment>
                According to a study done by Cigna Corporation’s health services business,
                Evernorth, behavioral health treatment can decrease costs by up to $1,377 per person in one year.<sup>1</sup>&nbsp;
                Since we are researching and making a recommendation, we are only taking credit for the initial introduction and attributing one month (1/12) of that value to our recommendation.
            </React.Fragment>
        )
    },
    {
        category: 'Provider Verification',
        savings: '$100',
        explanation: (
            <React.Fragment>
                HealthJoy’s team ensures the provider is in-network and works at a private/freestanding facility.
                This is based on the average difference between contract negotiated rates vs. average rates for medical services.
            </React.Fragment>
        )
    }
];

const ProgramSavingsFormulaContinuedFirst = () => {
    const tableProps = {
        className: 'mb-small',
        columns: [
            {header: 'Category', accessor: 'category'},
            {header: 'Savings Per Service', accessor: 'savings', width: '34mm', textAlign: 'center'},
            {header: 'Explanation', accessor: 'explanation'}
        ],
        data: TABLE_DATA
    };

    return (
        <PageContent title='2.1 Program Savings Formula Continued' className='program-savings-formula-continued'>
            <PdfText className='mb-extra-small' type='body-bold'>Provider/Facility Steerage Savings Breakdown (continued)</PdfText>

            <PdfTable {...tableProps}/>

            <PdfText className='program-savings-formula-continued__table-explanation' type='helper'>
                <sup>1</sup>. Justine Sessions, “Behavioral Health Treatment Helps Reduce Total Cost of Care, New Evernorth Analysis Finds” (2021), Cigna Newsworthy
            </PdfText>
        </PageContent>
    );
};

export {ProgramSavingsFormulaContinuedFirst as TestableProgramSavingsFormulaContinuedFirst};
export default ProgramSavingsFormulaContinuedFirst;
