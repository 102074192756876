import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Table from '@frontend/ui-kit/Components/Table';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Link from '@frontend/ui-kit/Components/Link';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import ImportCard from '../ImportCard';
import ActionsCell from './ActionsCell';
import ReUploadFilePopup from './ReUploadFilePopup';
import EmptyData from '../../shared/EmptyData';
import withPopup from '../../../HOC/withPopup';
import {
    requestImportsJsonrpc,
    requestTPAPartnerImportSession,
    requestTPAPartnerImportSessionCreating,
    requestTPAPartnerImportSessions
} from '../../../actions/adminPortal';
import {
    convertDateToTimeZone,
    getUTCDate,
    equal,
    formatDate,
    generateUniqueId,
    getFileName,
    getItemKeyValue,
    isEmpty
} from '../../../utils';
import {redirectTo, requestCompaniesByTPAPartner} from '../../../actions/general';
import {getProfileInfo} from '../../../selectors/general';
import {JSON_RPC_RESULTS, ROUTES, TPA_PARTNER_IMPORT_SESSION_STATUSES} from '../../../constants';
import './index.scss';

const globalErrorsTableProps = {
    isFilterable: false,
    isSortable: false,
    isCellTooltip: false,
    columns: [
        {Header: 'Error Type', accessor: 'error_type'},
        {Header: 'Explanation', accessor: 'explanation'}
    ]
};

const duplicateErrorsTableProps = {
    isFilterable: false,
    isSortable: false,
    isCellTooltip: false,
    columns: [
        {Header: 'Error Type', accessor: 'error_type'},
        {Header: 'Explanation', accessor: 'explanation'},
        {Header: '# of Members', accessor: 'members_count', width: 100},
        {Header: 'Actions', accessor: 'id', width: 70, Cell: ActionsCell}
    ]
};

const POPUP_ID = 'reportDetailsPopup';

const PreprocessingReportDetails = ({openPopup, closePopup}) => {
    const [tpaGroups, setTpaGroups] = useState([]);
    const [reportData, setReportData] = useState({});
    const {global_errors: globalErrors, duplicate_errors: duplicateErrors} = reportData || {};
    const [session, setSession] = useState({});
    const [isLastSession, setIsLastSession] = useState(false);
    const {full_name: userFullName} = useSelector(getProfileInfo);
    const {partner, session_id: sessionId} = useParams();
    const {search} = useLocation();
    const dispatch = useDispatch();
    const isCanceledStatus = useMemo(() => equal(session?.status, TPA_PARTNER_IMPORT_SESSION_STATUSES.canceled), [session]);
    const missingGroups = useMemo(() => tpaGroups.filter(group => !reportData.all_groups_in_file.includes(group)), [tpaGroups, reportData]);

    useEffect(() => {
        (async () => {
            const [
                {session},
                {groupsData},
                {sessions}
            ] = await Promise.all([
                dispatch(requestTPAPartnerImportSession(sessionId)),
                dispatch(requestCompaniesByTPAPartner({partnerId: partner, limit: 999})),
                dispatch(requestTPAPartnerImportSessions({limit: 1, tpa_partner_id: partner, only_active: false}))
            ]);
            const {preprocessing_report: report, ...restSession} = session || {};

            setReportData(report);
            setSession(restSession);
            setTpaGroups(groupsData.data.map(getItemKeyValue('alias')));
            setIsLastSession(equal(Number(sessionId), sessions?.data[0].id));
        })();
    }, [dispatch, sessionId]);

    const getFile = url => <Link className='file-name' href={url} target='_blank'>{decodeURIComponent(getFileName(url))}</Link>;

    const onGoToModifyGroupConfig = () => dispatch(redirectTo(`${ROUTES.importsTpa}/${partner}/configure_tpa_groups${search}`));

    const getErrorsComponent = (data, title, tableProps) => {
        return <Collapse title={title} isOpened className='mt-20' hasCollapseIcon initiator={<Heading type={HEADING_TYPES['4']}>{title} ({data.length})</Heading>} content={<Table {...tableProps} data={data}/>}/>;
    };

    const onReUploadFile = file => {
        const {created_at, updated_at, id, preprocessing_report, status, failed_reason, ...sessionData} = session || {};

        const onUploadFile = async () => {
            const enhancedSession = {...sessionData, file_path: file, additional_data: {...sessionData.additional_data, created_by: userFullName}};
            const {session, isSuccess} = await dispatch(requestTPAPartnerImportSessionCreating(enhancedSession));

            if (!isSuccess) {
                return false;
            }

            const jsonrpcObj = {jsonrpc: '2.0', method: 'run_tpa_preprocessing', id: generateUniqueId(), params: {session}};
            const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

            if (!equal(jsonrpc?.result, JSON_RPC_RESULTS.success)) {
                return false;
            }

            closePopup();
            dispatch(redirectTo(`${ROUTES.importsTpa}/${partner}${search}`));
        };

        const onReturn = () => {
            closePopup();
            dispatch(redirectTo(`${ROUTES.importsTpa}/${sessionData?.tpa_partner_id}/tpa_config?partner_name=${encodeURIComponent(sessionData?.additional_data?.tpa_partner_name)}#configuration`));
        };

        return openPopup({type: POPUP_TYPES.simple, children: <ReUploadFilePopup file={file} onReturn={onReturn} onReUploadFile={onReUploadFile} onSave={onUploadFile}/>});
    };

    return (
        <div className='pre-processing-report'>
            <Row middle='xs'>
                <Column sm>
                    <Heading type={HEADING_TYPES['1']}>Pre-processing Report</Heading>
                </Column>

                <Column>
                    <Button data-testid='re-upload-file-button' disabled={isCanceledStatus} onClick={() => onReUploadFile()} type={BUTTON_TYPES.secondary} className='mr-8'>Re-upload File</Button>
                    <Button data-testid='modify-group-configs-button' disabled={!isLastSession || isCanceledStatus || !isEmpty(globalErrors) || !isEmpty(duplicateErrors)} onClick={onGoToModifyGroupConfig}>Modify Group Configs</Button>
                </Column>
            </Row>

            <ContentSection className='mt-20'>
                <Row>
                    <Column sm={3}>
                        <ImportCard title='Date' value={`${formatDate(convertDateToTimeZone(getUTCDate(reportData?.date)), 'MM/dd/yyyy H:mm')} CST`}/>
                    </Column>
                    <Column sm={3}>
                        <ImportCard title='Total Upload Time' value={`${reportData?.total_upload_time?.toFixed(2) || 0} seconds`}/>
                    </Column>
                    <Column sm={3}>
                        <ImportCard title='File Name' value={getFile(session?.file_path)}/>
                    </Column>
                    <Column sm={3}>
                        <ImportCard title='Uploaded By' value={session?.additional_data?.created_by}/>
                    </Column>
                </Row>

                {!isEmpty(missingGroups) && (
                    <Alert className='mt-20 mb-20' type={ALERT_TYPES.danger} title='MISSING GROUPS' description={`We couldn’t find ${missingGroups.join(', ')} in the file. Check the file and/or Houston to ensure the group(s) are included, and the group identifier values are accurate.`}/>
                )}

                {!isEmpty(globalErrors) && getErrorsComponent(globalErrors, 'Global Errors', globalErrorsTableProps)}

                {!isEmpty(duplicateErrors) && getErrorsComponent(duplicateErrors, 'Duplicate Errors', duplicateErrorsTableProps)}

                {isEmpty(globalErrors) && isEmpty(duplicateErrors) && <EmptyData title='Great job! There are no global or duplicate errors to review.'/>}
            </ContentSection>
        </div>
    );
};

PreprocessingReportDetails.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {PreprocessingReportDetails as TestablePreprocessingReportDetails};
export default withPopup(POPUP_ID)(PreprocessingReportDetails);
