import React from 'react';
import TPAGroupsAllComponent from '../../../Components/AdminPortal/TPAGroupsAll';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'partner_name'},
    {name: 'All TPA Groups'}
];

const TPAGroupsAll = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <TPAGroupsAllComponent/>
    </React.Fragment>
);

export {TPAGroupsAll as TestableTPAGroupsAll};
export default React.memo(TPAGroupsAll);
