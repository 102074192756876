import {SET_INTERNAL_MENU_ITEMS, SET_INTERNAL_MENU_ACTIVE_ITEM} from '../actions/actionTypes';

export const initialState = {
    items: [],
    activeItem: 0
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case SET_INTERNAL_MENU_ITEMS: {
            const {items} = payload;

            return {...state, items};
        }

        case SET_INTERNAL_MENU_ACTIVE_ITEM: {
            const {activeItem} = payload;

            return {...state, activeItem};
        }

        default: {
            return state;
        }
    }
};
