import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import AdminContactForm from '../AdminContactForm';
import {requestAdminContacts, clearAdminContacts, addAdminContact} from '../../../actions/home';
import {getAdminContacts} from '../../../selectors/home';
import {FORMS} from '../../../constants';
import './index.scss';

const InvitationBoard = () => {
    const dispatch = useDispatch();
    const adminContacts = useSelector(getAdminContacts);
    const onAddAdminContact = () => dispatch(addAdminContact());

    useEffect(() => {
        dispatch(requestAdminContacts());

        return () => dispatch(clearAdminContacts());
    }, []);

    return (
        <ContentSection className='invitation-board'>
            <Text className='invitation-board__title' type={TEXT_TYPES.bodyBold}>Invite Admins</Text>
            <Text className='invitation-board__note'>
                NOTE: These Admins will be able to see all employee eligibility and benefits information.
                You should generally invite the Broker, Account Manager and top client HR person.
                Only invite people with their company email address (gmail, yahoo, etc. are not permitted).
            </Text>

            {adminContacts.map((adminContact, index) => {
                return (
                    <React.Fragment key={index}>
                        <AdminContactForm initialAdminContact={adminContact} name={`${FORMS.adminInvitation}_${index}`}/>

                        <Separator/>
                    </React.Fragment>
                );
            })}

            <div className='invitation-board-action-bar'>
                <Button className='invitation-board-action-bar__button' type={BUTTON_TYPES.secondary} onClick={onAddAdminContact}>Add another admin</Button>
            </div>
        </ContentSection>
    );
};

export {InvitationBoard as TestableInvitationBoard};
export default InvitationBoard;
