import {RECEIVE_CONTACTS} from '../actions/actionTypes';

export const initialState = {
    contactList: []
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case RECEIVE_CONTACTS: {
            const {contactList} = payload;

            return {...state, contactList};
        }

        default: {
            return state;
        }
    }
};
