import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import {requestDeleteGroupId, requestGroupIdGenerate} from '../../../../actions/adminPortal';
import useFormState from '../../../../hooks/useFormState';
import useForm from '../../../../hooks/useForm';
import withPopup from '../../../../HOC/withPopup';
import {compose} from '../../../../utils';
import {ELIGIBILITY_OPTIONS} from '../../../../options';
import './index.scss';

const POPUP_ID = 'eligibilitySectionPopup';

const EligibilityInformation = ({isEditDisabled, openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const {companyAlias} = useParams();
    const form = useForm();
    const {values} = useFormState();
    const [tooltip, setTooltip] = useState('Copy');

    const generateGroupId = async segment => {
        const {data, isSuccess} = await dispatch(requestGroupIdGenerate({companyAlias, segment}));

        if (isSuccess) {
            const nextGroups = values.eligibility_groups?.some(group => group.segment === segment)
                ? values.eligibility_groups.map(group => group.segment === segment ? data : group)
                : [...values.eligibility_groups, data];

            form.change('eligibility_groups', nextGroups);
        }
    };

    const deleteGroupId = async segment => {
        const {isSuccess} = await dispatch(requestDeleteGroupId({companyAlias, segment}));

        if (isSuccess) {
            const nextGroups = values.eligibility_groups?.filter(group => group.segment !== segment);

            form.change('eligibility_groups', nextGroups);
        }
    };

    const copyToClipboard = value => {
        navigator.clipboard.writeText(value);
        setTooltip('Copied to Clipboard');
    };

    const onDeleteGroupId = segment => {
        const onDelete = () => {
            deleteGroupId(segment);
            closePopup();
        };

        const actionBar = (
            <React.Fragment>
                <Button data-testid='cancel-popup-button' type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button data-testid='delete-popup-button' type={BUTTON_TYPES.primary} onClick={onDelete}>Yes, delete</Button>
            </React.Fragment>
        );
        const popupContent = <Text>This action cannot be undone.</Text>;

        const popupProps = {title: 'Are you sure you want to delete this Eligibility Group?', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    return (
        <ContentSection className='app-configuration-content__section'>
            <Heading className='app-configuration-content__title'>Eligibility Information</Heading>

            <Row>
                <Column sm={8}>
                    <Input value={values.eligibility_definition} placeholder='All Employees in file' description='This field is populated from step 4 of the ‘Eligibility Overview’ section in Dashboard' disabled label='Eligibility Definition'/>
                </Column>
            </Row>

            <Separator/>

            {ELIGIBILITY_OPTIONS.map(({value, label}) => {
                const eligibilityGroupSegment = values.eligibility_groups?.find(group => group.segment === value);
                const groupID = eligibilityGroupSegment?.group_id || '';

                return (
                    <Row className='eligibility-section__row'>
                        <Column sm={4}>
                            <Input value={label} disabled label='Eligibility'/>
                        </Column>
                        <Column sm={4}>
                            <Input value={groupID}
                                icon={ICON_TYPES.copy}
                                onIconClick={() => copyToClipboard(groupID)}
                                tooltipProps={{
                                    content: tooltip,
                                    hideOnClick: false,
                                    onHidden: () => setTooltip('Copy')
                                }}
                                placeholder=''
                                label='Group ID'
                                disabled/>
                        </Column>

                        {!isEditDisabled && (
                            <Column sm={4} className='eligibility-section__actions'>
                                {!groupID
                                    ? <Button data-testid='generate-group-id' type={BUTTON_TYPES.tertiary} className='eligibility-section__actions__generate-group' onClick={() => generateGroupId(value)}>GENERATE GROUP ID</Button>
                                    : <Icon data-testid='remove-group' className='eligibility-section__actions__remove-group' type={ICON_TYPES.delete} onClick={() => onDeleteGroupId(value)}/>}
                            </Column>
                        )}
                    </Row>
                );
            })}
        </ContentSection>
    );
};

EligibilityInformation.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired,
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {EligibilityInformation as TestableEligibilityInformation};
export default compose(
    withPopup(POPUP_ID),
    React.memo
)(EligibilityInformation);
