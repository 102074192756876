import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import PageHeader from '../../shared/PageHeader';
import CompanyInfoVersionHistoryPopup from '../CompanyInfoVersionHistoryPopup';
import CompanyInfoWizard from '../CompanyInfoWizard';
import withPopup from '../../../HOC/withPopup';
import {getIsSuperAdmin} from '../../../selectors/general';

const POPUP_ID = 'COMPANY_VERSION_HISTORY_POPUP';

const CompanyInfoLayout = ({openPopup, closePopup}) => {
    const isSuperAdmin = useSelector(getIsSuperAdmin);

    const onOpenVersionHistoryPopup = () => {
        const children = <CompanyInfoVersionHistoryPopup closePopup={closePopup}/>;

        openPopup({type: POPUP_TYPES.fullscreen, children});
    };

    return (
        <React.Fragment>
            <Row>
                <Column sm={12}>
                    <PageHeader title='Company Info' caption='Confirm, edit or add company overview information below.'>
                        {isSuperAdmin && <Button onClick={onOpenVersionHistoryPopup} type={BUTTON_TYPES.secondary}>Visit Version History</Button>}
                    </PageHeader>
                </Column>
            </Row>

            <CompanyInfoWizard/>
        </React.Fragment>
    );
};

CompanyInfoLayout.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {CompanyInfoLayout as TestableCompanyInfoLayout};
export default withPopup(POPUP_ID)(CompanyInfoLayout);
