import {CHANGE_SIDEBAR_MODE} from '../actions/actionTypes';
import {SIDEBAR_EMPTY_MODE, SIDEBAR_HALF_MODE} from '../constants';

export const initialState = {
    prevMode: SIDEBAR_EMPTY_MODE,
    mode: SIDEBAR_HALF_MODE
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case CHANGE_SIDEBAR_MODE:
            const prevMode = state.mode;
            const mode = !isNaN(payload.mode) ? payload.mode : Number(!state.mode) * -state.prevMode;

            return {...state, prevMode, mode};

        default:
            return state;
    }
};
