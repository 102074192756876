import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReduxFormWizard from '@frontend/ui-kit/Components/Wizard';
import {setWizardActiveStep} from '../../../actions/shared';
import {getWizardActiveStep} from '../../../selectors/shared';

const Wizard = props => {
    const dispatch = useDispatch();
    const activeStep = useSelector(getWizardActiveStep);

    useEffect(() => {
        return () => {
            dispatch(setWizardActiveStep(0));
        };
    }, [dispatch]);

    return <ReduxFormWizard {...props} activeStep={activeStep}/>;
};

export {Wizard as TestableWizard};
export default React.memo(Wizard);
