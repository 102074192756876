import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import EligibilityWizard from '../../../Components/Eligibility/EligibilityWizard';

const PAGE_HEADER_CAPTION = `Tell us a bit more about how you collect eligibility, who is eligible for each benefit,
                 and who will be eligible for HealthJoy.`;

const Overview = () => (
    <React.Fragment>
        <Row>
            <Column sm={9}>
                <PageHeader title='Eligibility' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <EligibilityWizard/>
    </React.Fragment>
);

export {Overview as TestableOverview};
export default React.memo(Overview);
