import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import './index.scss';

const RolePermissions = ({className}) => (
    <div className={classnames('role-permissions', className)}>
        <Text className='role-permissions-item'>
            <Text className='role-permissions-item__title' type={TEXT_TYPES.bodyBold}>Admin</Text>

            <ul className='permissions-list'>
                <li>View and edit all benefits and eligibility information.</li>
                <li>View all HealthJoy materials and performance.</li>
                <li>Can remove contacts from dashboard.</li>
            </ul>
        </Text>

        <Text className='role-permissions-item'>
            <Text className='role-permissions-item__title' type={TEXT_TYPES.bodyBold}>Contributor</Text>

            <ul className='permissions-list'>
                <li>Same as Admin but cannot remove contacts from Dashboard.</li>
                <li>Cannot view contact details, remove contacts, export eligibility files or view member activation.</li>
            </ul>
        </Text>

        <Text className='role-permissions-item'>
            <Text className='role-permissions-item__title' type={TEXT_TYPES.bodyBold}>Viewer</Text>

            <ul className='permissions-list'>
                <li>View only access to all benefits information, HealthJoy materials and performance.</li>
                <li>Cannot export eligibility files or view member activation.</li>
            </ul>
        </Text>

        <Text className='role-permissions-item'>
            <Text className='role-permissions-item__title' type={TEXT_TYPES.bodyBold}>Help Center-Only Viewer</Text>

            <ul className='permissions-list'>
                <li>View only access to HealthJoy’s Help Center.</li>
            </ul>
        </Text>
    </div>
);

RolePermissions.propTypes = {
    className: PropTypes.string
};

export {RolePermissions as TestableRolePermissions};
export default React.memo(RolePermissions);
