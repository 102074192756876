import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import TextSection from '../TextSection';

const MemberUtilization = ({memberUtilizationChartData, benefitsWalletUtilizationChartData}) => {
    const memberUtilizationChartProps = {
        Chart: LineChart,
        title: 'Total Unique Member Actions',
        description: 'Total Unique Member Actions is calculated as the total number of services that members have received through the HealthJoy app.',
        data: memberUtilizationChartData
    };
    const benefitsWalletUtilizationChartProps = {
        Chart: LineChart,
        title: 'Benefits Wallet Views',
        description: 'The total number of unique Benefits Wallet views, compared against your total eligible population.',
        data: benefitsWalletUtilizationChartData
    };

    return (
        <PageContent title='3.0 Member Utilization'>
            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...memberUtilizationChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Member Utilization'>
                        Calculated as the total number of services that members have received through the HealthJoy app.
                        This includes views of the Benefit Wallet as well as Service Requests that have been completed on behalf of your employees.
                        Service Requests include Telemedicine Consults, RX Savings Reviews, Provider and Facility Recommendations, Appointment Schedules, Medical Bill Reviews, and Benefit Questions asked via phone call or chat.
                    </TextSection>
                </Column>
            </Row>

            <Row className='pdf-template-row mt-small'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...benefitsWalletUtilizationChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Benefit Wallet Views'>
                        The total number of unique Benefits Wallet views, compared against your total eligible population.
                    </TextSection>
                </Column>
            </Row>
        </PageContent>
    );
};

MemberUtilization.propTypes = {
    memberUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    benefitsWalletUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    })
};

export {MemberUtilization as TestableMemberUtilization};
export default MemberUtilization;
