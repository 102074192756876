import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import BroadcastPreview from '../BroadcastPreview';
import {requestBroadcastTemplates, setBroadcastTemplate} from '../../../../actions/broadcaster';
import {redirectTo} from '../../../../actions/general';
import {getBroadcastTemplates} from '../../../../selectors/broadcaster';
import {BROADCAST_TEMPLATE_IMAGES, ROUTES, BROADCAST_VERSIONS} from '../../../../constants';

const BroadcastTemplates = () => {
    const broadcastTemplates = useSelector(getBroadcastTemplates);
    const dispatch = useDispatch();

    const onCreateBroadcast = template => {
        dispatch(setBroadcastTemplate(template ?? null));
        dispatch(redirectTo(ROUTES.broadcastEditor));
    };

    useEffect(() => {
        dispatch(requestBroadcastTemplates({version: BROADCAST_VERSIONS.v1}));
    }, [dispatch]);

    return (
        <Row className='broadcaster-previews'>
            {broadcastTemplates.map(broadcast => (
                <Column className='broadcaster-previews__preview-wrapper' sm={3} key={broadcast.templateId} data-testid={`${broadcast.templateId}-wrapper`}>
                    <BroadcastPreview title={broadcast.title}
                        description={broadcast.description}
                        imageSrc={BROADCAST_TEMPLATE_IMAGES[broadcast.templateId]}
                        onClick={() => onCreateBroadcast(broadcast.broadcast)}
                        broadcastId={broadcast.templateId}/>
                </Column>
            ))}

            <Column className='broadcaster-previews__preview-wrapper' sm={3}>
                <BroadcastPreview title='Create Custom Broadcast'
                    description='Start a new message from scratch using a blank template.'
                    onClick={() => onCreateBroadcast()}/>
            </Column>
        </Row>
    );
};

export {BroadcastTemplates as TestableBroadcastTemplates};
export default BroadcastTemplates;
