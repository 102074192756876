import React, {useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import Select from '@frontend/ui-kit/Components/Select';
import {requestCompanyByAlias} from '../../../actions/general';
import {requestPlanManagerPlans, requestPlanManagerPlan} from '../../../actions/adminPortal';
import {getEqual} from '../../../utils';
import {CORE_PLAN_TYPES} from '../../../constants';

const PlanBenefitsCustomizationImportPopup = ({companyAlias, planPeriodId, onSave, onClose}) => {
    const dispatch = useDispatch();
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [plans, setPlans] = useState([]);
    const planOptions = useMemo(() => plans.map(({name, id}) => ({label: name, value: id})), [plans]);

    const onImport = useCallback(async () => {
        const {data: plan} = await dispatch(requestPlanManagerPlan(selectedPlanId));

        onSave(plan);
    }, [dispatch, selectedPlanId, onSave]);

    useEffect(() => {
        (async () => {
            const {data: company} = await dispatch(requestCompanyByAlias(companyAlias));
            const planPeriodIdIndex = company.all_plan_periods.findIndex(getEqual(planPeriodId, 'id'));
            const {id: previousPlanPeriodId} = company.all_plan_periods[planPeriodIdIndex + 1] ?? {};

            if (!previousPlanPeriodId) {
                return;
            }

            const {data: plans} = await dispatch(requestPlanManagerPlans({
                company_alias: companyAlias,
                plan_period_id: previousPlanPeriodId,
                plan_kinds: [CORE_PLAN_TYPES.medical]
            }));

            setPlans(plans);
        })();
    }, [dispatch, companyAlias, planPeriodId]);

    const actionBar = (
        <React.Fragment>
            <Button onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
            <Button onClick={onImport} disabled={!selectedPlanId}>Import</Button>
        </React.Fragment>
    );

    return (
        <PopupContent title='Import Plan Benefits Customization Data From Another Plan' actionBar={actionBar}>
            <Text>
                Select the plan to import data from. This will overwrite the current Plan Benefits Customization setup.
            </Text>

            <Select value={selectedPlanId} options={planOptions} onChange={setSelectedPlanId} isMenuPortal label='Select Plan' wrapperClassName='mt-12'/>
        </PopupContent>
    );
};

PlanBenefitsCustomizationImportPopup.propTypes = {
    planPeriodId: PropTypes.number.isRequired,
    companyAlias: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export {PlanBenefitsCustomizationImportPopup as TestablePlanBenefitsCustomizationImportPopup};
export default React.memo(PlanBenefitsCustomizationImportPopup);
