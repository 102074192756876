import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Tabs from '@frontend/ui-kit/Components/Tabs';
import {redirectTo} from '../../../actions/general';
import {getRoutesUnavailabilityMap} from '../../../selectors/general';

const PagesTabs = ({routes}) => {
    const [availableRoutes, setAvailableRoutes] = useState([]);
    const routesUnavailabilityMap = useSelector(getRoutesUnavailabilityMap);
    const {pathname} = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const availableRoutes = routes.filter(route => !routesUnavailabilityMap[route]?.isHidden);

        setAvailableRoutes(availableRoutes);
    }, []);

    const onChangeTab = activeTab => {
        const route = availableRoutes[activeTab];

        dispatch(redirectTo(route));
    };

    const getTabNameFromRoute = route => {
        const [lastSubPath] = route.split('/').reverse();

        return lastSubPath.replace('_', ' ');
    };

    const tabs = useMemo(() => availableRoutes.map(getTabNameFromRoute), [availableRoutes]);
    const activeTab = useMemo(() => availableRoutes.findIndex(route => pathname.startsWith(route)), [availableRoutes, pathname]);

    return <Tabs className='pages-tabs' tabs={tabs} onChange={onChangeTab} activeTab={activeTab}/>;
};

PagesTabs.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.string)
};

export {PagesTabs as TestablePagesTabs};
export default React.memo(PagesTabs);
