import React from 'react';
import PropTypes from 'prop-types';
import CoreBenefitsStepHeader from '../../Components/Benefits/CoreBenefitsStepHeader';

const withCoreBenefitsStepLayout = Component => {
    const WithCoreBenefitsStepLayout = props => {
        const {isReadonly, stepName: planType} = props;

        return (
            <React.Fragment>
                <CoreBenefitsStepHeader isReadonly={isReadonly} planType={planType}/>
                <Component {...props}/>
            </React.Fragment>
        );
    };

    WithCoreBenefitsStepLayout.propTypes = {
        isReadonly: PropTypes.bool,
        stepName: PropTypes.string
    };

    return WithCoreBenefitsStepLayout;
};

export {withCoreBenefitsStepLayout as testableWithCoreBenefitsStepLayout};
export default withCoreBenefitsStepLayout;
