import React from 'react';
import {useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/Home/PageHeader';
import TimelinePreparingForm from '../../../Components/Home/TimelinePreparingForm';
import InvitationBoard from '../../../Components/Home/InvitationBoard';
import {getTimelineExistenceStatus, getActiveCompanyName} from '../../../selectors/general';

const TimelineGeneration = () => {
    const isTimelineExisted = useSelector(getTimelineExistenceStatus);
    const companyName = useSelector(getActiveCompanyName);

    return (
        <React.Fragment>
            <Row>
                <Column sm={9}>
                    <PageHeader>
                        Use the left panel to choose the CSM that {companyName} will see, their launch date,
                        and the timeline type. After generating and reviewing their timeline, you can invite
                        Client and Broker Admins on the right. The Implementation Manager will later subscribe
                        Benefits and Eligibility Specialists to this account.
                    </PageHeader>
                </Column>
            </Row>

            <Row>
                <Column sm={4}>
                    <TimelinePreparingForm/>
                </Column>

                <Column sm={8}>
                    {isTimelineExisted && <InvitationBoard/>}
                </Column>
            </Row>
        </React.Fragment>
    );
};

export {TimelineGeneration as TestableTimelineGeneration};
export default React.memo(TimelineGeneration);
