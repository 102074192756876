import React from 'react';
import PropTypes from 'prop-types';
import PageContent from '../PageContent';
import TextSection from '../TextSection';
import PdfText from '../PdfText';

const ProgramSavingsFormula = ({isMedicalBillVisible, isMskVisible, isPreCertificationVisible, isFindCareVisible}) => (
    <PageContent title='2.1 Program Savings Formula'>
        {isMedicalBillVisible && (
            <TextSection title='Medical Bill Review Savings' className='mb-default'>
                Calculated as $689 per medical bill, an average dollar savings value.
                Total Medical Bill Review savings is the sum of all of the savings from each review.
            </TextSection>
        )}

        {isMskVisible && (
            <TextSection title='Musculoskeletal Therapy Savings' className='mb-default'>
                <PdfText className='mb-small'>
                    Calculated by incorporating each participant’s prior care and current care intentions to determine their savings potential.
                    Total MSK Savings is the sum of the realized savings values.
                </PdfText>

                Participants and their savings potential fall into three buckets:
                <ul>
                    <li>No intention of getting surgery and prior care is non-invasive: $1,800</li>
                    <li>No intention of getting surgery but received more expensive and/or invasive prior care: $4,000</li>
                    <li>Intention to get surgery: Varies per participants</li>
                </ul>
            </TextSection>
        )}

        {isPreCertificationVisible && (
            <TextSection title='Pre-certification Steerage Savings' className='mb-default'>
                Calculated as the difference between the average price for procedure and the estimated price of the recommendation to determine the total savings opportunity.
                Average price is determined by procedure type and facility location.
            </TextSection>
        )}

        <TextSection title='Provider/Facility Steerage Savings'>
            Calculated as the sum of the cost savings found in all of the provider and facility steerage recommendations.
            Steerage consists of several subcategories with unique dollar values attached to each recommendation.
            Values range from $50 (dental) - $4,320 (surgery) per request. {isFindCareVisible && 'Find Care searches are included in the calculation. '}
            See breakdown of each subcategory on the next page.
        </TextSection>
    </PageContent>
);

ProgramSavingsFormula.propTypes = {
    isMedicalBillVisible: PropTypes.bool,
    isMskVisible: PropTypes.bool,
    isPreCertificationVisible: PropTypes.bool,
    isFindCareVisible: PropTypes.bool
};

export {ProgramSavingsFormula as TestableProgramSavingsFormula};
export default ProgramSavingsFormula;
