import {
    RECEIVE_ANCILLARY_PLANS,
    CLEAR_ANCILLARY_PLANS,
    RECEIVE_CORE_PLANS,
    CLEAR_CORE_PLANS,
    RECEIVE_MEDICAL_TRAITS,
    RECEIVE_PHARMACY_BENEFIT_MANAGERS,
    RECEIVE_WEBVIEW_PARAMS,
    RECEIVE_WALLET_CARDS,
    SET_IS_COPYING_ENABLED
} from '../actions/actionTypes';

export const initialState = {
    isCopyingEnabled: false,
    corePlans: [],
    medicalTraits: {},
    ancillaryPlans: [],
    pharmacyBenefitManagers: [],
    webviewParams: {},
    walletCards: []
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case RECEIVE_CORE_PLANS: {
            const {corePlans} = payload;

            return {...state, corePlans};
        }

        case CLEAR_CORE_PLANS: {
            const corePlans = [];

            return {...state, corePlans};
        }

        case RECEIVE_MEDICAL_TRAITS: {
            const {medicalTraits} = payload;

            return {...state, medicalTraits};
        }

        case RECEIVE_ANCILLARY_PLANS: {
            const {ancillaryPlans} = payload;

            return {...state, ancillaryPlans};
        }

        case CLEAR_ANCILLARY_PLANS: {
            const ancillaryPlans = [];

            return {...state, ancillaryPlans};
        }

        case RECEIVE_PHARMACY_BENEFIT_MANAGERS: {
            const {pharmacyBenefitManagers} = payload;

            return {...state, pharmacyBenefitManagers};
        }

        case RECEIVE_WEBVIEW_PARAMS: {
            const {webviewParams} = payload;

            return {...state, webviewParams};
        }

        case RECEIVE_WALLET_CARDS: {
            const {walletCards} = payload;

            return {...state, walletCards};
        }

        case SET_IS_COPYING_ENABLED: {
            const {isCopyingEnabled} = payload;

            return {...state, isCopyingEnabled};
        }

        default: {
            return state;
        }
    }
};
