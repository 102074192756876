import React from 'react';
import PropTypes from 'prop-types';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import './index.scss';

const StickyActionSection = ({className, title, actionBar, children}) => (
    <ContentSection className={`sticky-action-section ${className}`}>
        <Heading className='sticky-action-section__title' type={HEADING_TYPES['4']}>{title}</Heading>

        <div className='sticky-action-section__content'>{children}</div>

        <div className='sticky-action-section__action-bar'>{actionBar}</div>
    </ContentSection>
);

StickyActionSection.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    actionBar: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node
};

StickyActionSection.defaultProps = {
    className: ''
};

export {StickyActionSection as TestableStickyActionSection};
export default React.memo(StickyActionSection);
