import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import CopyableField from '../CopyableField';
import DocumentUploader from '../DocumentUploader';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';
import {trimStart} from '../../../utils';

const POLICY_HOLDER_TOOLTIP_CONTENT = 'If you’re unsure, most of the time this is the Client’s name.';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'policy_holder', label: 'Policyholder'},
    {name: 'group_policy', label: 'Group Policy'},
    {name: 'life_benefit', label: 'Life Benefit'},
    {name: 'spouse_life_benefit', label: 'Spouse Life'},
    {name: 'child_life_benefit', label: 'Child Life'},
    {name: 'add_benefit', label: 'AD&D Benefit'},
    {name: 'spouse_add_benefit', label: 'Spouse AD&D'},
    {name: 'child_add_benefit', label: 'Child AD&D'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const BasicLifeForm = ({isReadonly, card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader isReadonly={isReadonly}/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below.
                It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo isReadonly={isReadonly}/>

            <CardHeaderInfo isReadonly={isReadonly}/>

            <CopyableField name='policy_holder' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter' label={<LabeledTooltip title='Policyholder' content={POLICY_HOLDER_TOOLTIP_CONTENT}/>} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='group_policy' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter' label='Group Policy' wrapperClassName='mb-12'/>}
            </CopyableField>

            <Text type={TEXT_TYPES.bodyBold} className='ancillary-plan-details-form__title'>Benefit Summary</Text>

            <Row>
                <Column sm={6}>
                    <CopyableField name='life_benefit' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $XX,XXX' label='Life Benefit' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='spouse_life_benefit' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $XX,XXX' label='Spouse Life' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='child_life_benefit' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $XX,XXX' label='Child Life'/>}
                    </CopyableField>
                </Column>

                <Column sm={6}>
                    <CopyableField name='add_benefit' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $XX,XXX' label='AD&D Benefit' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='spouse_add_benefit' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $XX,XXX' label='Spouse AD&D' wrapperClassName='mb-12'/>}
                    </CopyableField>

                    <CopyableField name='child_add_benefit' parse={trimStart}>
                        {props => <Input {...props} readonly={isReadonly} placeholder='e.g.: $XX,XXX' label='Child AD&D'/>}
                    </CopyableField>
                </Column>
            </Row>
        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

BasicLifeForm.propTypes = {
    isReadonly: PropTypes.bool,
    card: PropTypes.string
};

export {BasicLifeForm as TestableBasicLifeForm};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(BasicLifeForm);
