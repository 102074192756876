import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import TextSection from '../TextSection';
import {ROI_CHART_BREAKEVEN_POINT_LIMIT} from '../../../constants';

const ProgramSavingsOverview = ({isRoiVisible, totalSavingsChartData, roiChartData}) => {
    const [{data: roiDataByMonth}] = roiChartData?.datasets || [{data: []}];
    const isBreakevenPoint = Math.min(...roiDataByMonth) <= ROI_CHART_BREAKEVEN_POINT_LIMIT;

    const totalSavingsChartProps = {
        Chart: LineChart,
        title: 'Total Program Savings',
        description: 'Calculated as the sum of the total estimated savings of each service.',
        unit: '$',
        data: totalSavingsChartData,
        options: {scales: {yAxes: [{ticks: {suggestedMax: 250}}]}}
    };
    const roiChartProps = {
        Chart: LineChart,
        title: 'Return on Investment',
        description: `
            Return on Investment, calculated at the end of each month, is the Total Company Savings divided by the Company’s prorated investment in HealthJoy.
            An ROI of greater than 0 means that your company is saving more money than you are spending with the HealthJoy program.
        `,
        unit: 'x',
        data: roiChartData,
        options: {
            scales: {yAxes: [{ticks: {suggestedMin: 0, suggestedMax: 3}}]},
            annotation: {
                annotations: [{value: isBreakevenPoint ? 0 : null, borderColor: '#20c997', label: {content: 'Breakeven point'}}]
            }
        }
    };

    return (
        <PageContent title='2.0 Program Savings'>
            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...totalSavingsChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Program Savings'>
                        Calculated as the sum of the estimated savings for categories of utilization.
                        You can read more about how each category is calculated in the Program Savings Formula below.
                    </TextSection>
                </Column>
            </Row>

            {isRoiVisible && (
                <Row className='pdf-template-row mt-small'>
                    <Column className='pdf-template-row__column' xs={7}>
                        <PdfChart {...roiChartProps}/>
                    </Column>

                    <Column className='pdf-template-row__column' xs={5}>
                        <TextSection title='Return on Investment'>
                            Calculated at the end of each month, is the Total Company Savings divided by the Company’s prorated investment in HealthJoy.
                            An ROI of greater than 0% means that your company is saving more money than you are spending with the HealthJoy program.
                        </TextSection>
                    </Column>
                </Row>
            )}
        </PageContent>
    );
};

ProgramSavingsOverview.propTypes = {
    isRoiVisible: PropTypes.bool,
    totalSavingsChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    roiChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    })
};

export {ProgramSavingsOverview as TestableProgramSavingsOverview};
export default ProgramSavingsOverview;
