import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Link from '@frontend/ui-kit/Components/Link';
import Text from '@frontend/ui-kit/Components/Text';
import logo from '../../../static/images/hjLogoPurple.svg';
import './index.scss';

const Layout = ({children}) => {
    return (
        <Row className='authorization-layout'>
            <Column className='authorization-layout-section' sm={6}>
                <div className='authorization-content'>
                    <img className='authorization-content__logo' src={logo} alt=''/>

                    {children}
                </div>

                <div className='authorization-footer'>
                    <Text className='authorization-footer__support'>
                        Need help accessing the HealthJoy app? Download the HealthJoy app on your iOS or Android device to get started or visit our support page (link to <Link href='https://mygroups.healthjoy.com/membership' target='_blank'>https://mygroups.healthjoy.com/membership/</Link>) for help.
                    </Text>

                    <div className='footer-legal'>
                        <span className='footer-legal__copyright'>© HealthJoy</span>
                    </div>
                </div>
            </Column>

            <Column className='authorization-layout-aside' sm={6}/>
        </Row>
    );
};

Layout.propTypes = {
    children: PropTypes.node
};

export {Layout as TestableLayout};
export default Layout;
