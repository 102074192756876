import React from 'react';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/react';
import Container from '@frontend/ui-kit/Components/Container';
import store from './store';
import Router from './Router';

const App = () => {
    const onBeforeCapture = scope => {
        scope.setLevel('fatal');
    };

    return (
        <Sentry.ErrorBoundary beforeCapture={onBeforeCapture}>
            <Provider store={store}>
                <Container fluid>
                    <Router/>
                </Container>
            </Provider>
        </Sentry.ErrorBoundary>
    );
};

export {App as TestableApp};
export default App;
