import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import './index.scss';

const InfoTab = ({tooltipIcon, tooltipContent, tooltipDirection, title, children, className}) => {
    const infoTabClassName = `info-tab ${className}`;

    return (
        <div className={infoTabClassName} data-testid='info-tab'>
            <div className='info-tab-main'>
                <Text className='info-tab-main__title' type={TEXT_TYPES.caption}>{title}</Text>
                <Text className='info-tab-main__content' type={TEXT_TYPES.bodyBold}>{children}</Text>
            </div>

            {tooltipContent && (
                <div className='info-tab-description' data-testid='info-tab-description'>
                    <Tooltip className='info-tab-description__tooltip' content={tooltipContent} direction={tooltipDirection}>
                        <Icon className='description-tooltip__icon' type={tooltipIcon}/>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

InfoTab.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    tooltipIcon: PropTypes.string,
    tooltipContent: PropTypes.string,
    tooltipDirection: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

InfoTab.defaultProps = {
    tooltipIcon: ICON_TYPES.info,
    tooltipDirection: 'right',
    className: ''
};

export {InfoTab as TestableInfoTab};
export default React.memo(InfoTab);
