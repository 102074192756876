import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import classnames from 'classnames';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import {Field} from '../../../shared/FormComponents';
import {FAMILY_MANAGEMENT_OPTIONS, FIND_CARE_VENDOR_OPTIONS} from '../../../../options';
import {requestMailOrderPrograms} from '../../../../actions/adminPortal';
import useFormState from '../../../../hooks/useFormState';
import {validateRequired} from '../../../../utils';
import './index.scss';

const VALIDATION_FIELDS = ['find_care_data_vendor'];

/* istanbul ignore next */
const validate = values => {
    return {
        find_care_data_vendor: values.is_find_care_available ? validateRequired(values.find_care_data_vendor) : undefined
    };
};

const MemberServices = ({isEditDisabled}) => {
    const dispatch = useDispatch();
    const {values, submitFailed, errors} = useFormState();
    const [mailOrderPrograms, setMailOrderPrograms] = useState([]);
    const isValid = !submitFailed || !Object.keys(errors).some(error => VALIDATION_FIELDS.includes(error));

    useEffect(() => {
        (async () => {
            const {data, isSuccess} = await dispatch(requestMailOrderPrograms());

            if (isSuccess) {
                const mailOrderPrograms = data?.map(({name, id}) => ({label: name, value: id}));
                setMailOrderPrograms(mailOrderPrograms);
            }
        })();
    }, [dispatch]);

    const onChangeIsFindCareAvailable = ({target}, form) => {
        if (!target.checked) {
            form.change('find_care_data_vendor', null);
        }
    };

    return (
        <ContentSection className={classnames('member-service app-configuration-content__section', {'app-configuration-content__section_invalid': !isValid})}>
            <Heading className='app-configuration-content__title'>Member Services Information</Heading>

            <Row>
                <Column sm={6}>
                    <Field name='family_management'>
                        {props => <Select {...props} options={FAMILY_MANAGEMENT_OPTIONS} disabled={isEditDisabled} label='Family Management'/>}
                    </Field>
                </Column>
                <Column sm={6}>
                    <Field name='mail_order_program_id'>
                        {props => <Select {...props} options={mailOrderPrograms} disabled={isEditDisabled} label='RX Mail Order Programs'/>}
                    </Field>
                </Column>
            </Row>

            <Separator className='mb-8'/>

            <Collapse hasCollapseIcon
                isOpened
                initiator={(
                    <Field name='is_find_care_available' onChange={onChangeIsFindCareAvailable}>
                        {props => (
                            <Checkbox {...props}
                                wrapperClassName={classnames('collapse-checkbox', {'collapse-checkbox_invalid': !isValid})}
                                caption='Find Care'
                                disabled={isEditDisabled}/>
                        )}
                    </Field>
                )}
                content={(
                    <Field name='find_care_data_vendor'>
                        {props => <Select {...props} options={FIND_CARE_VENDOR_OPTIONS} label='Data Vendor' disabled={isEditDisabled || !values.is_find_care_available}/>}
                    </Field>
                )}/>

            {values.bind_plan_names && (
                <React.Fragment>
                    <Separator/>

                    <Row>
                        {values.bind_plan_names.map((plan, index) => (
                            <Column sm={4}>
                                <Input value={plan} disabled label={`Surest Plan #${index + 1}`}/>
                            </Column>
                        ))}
                    </Row>
                </React.Fragment>
            )}
        </ContentSection>
    );
};

MemberServices.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {validate, MemberServices as TestableMemberServices};
export default React.memo(MemberServices);
