import React from 'react';
import PropTypes from 'prop-types';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {APP_CONFIG_STATUSES} from '../../../constants';
import './index.scss';

const STICKER_TYPES_BY_STATUS = {
    [APP_CONFIG_STATUSES.active]: STICKER_TYPES.success,
    [APP_CONFIG_STATUSES.inactive]: STICKER_TYPES.default,
    [APP_CONFIG_STATUSES.pending]: STICKER_TYPES.tertiary,
    [APP_CONFIG_STATUSES.terminated]: STICKER_TYPES.warning,
    [APP_CONFIG_STATUSES.completed]: STICKER_TYPES.default
};
const ICON_TYPES_BY_STATUS = {
    [APP_CONFIG_STATUSES.active]: ICON_TYPES.confirm,
    [APP_CONFIG_STATUSES.inactive]: ICON_TYPES.circleMinus,
    [APP_CONFIG_STATUSES.pending]: ICON_TYPES.processing,
    [APP_CONFIG_STATUSES.terminated]: ICON_TYPES.close,
    [APP_CONFIG_STATUSES.terminated]: ICON_TYPES.confirm
};

const AppConfigurationStatusSticker = ({status}) => {
    const type = STICKER_TYPES_BY_STATUS[status];
    const iconType = ICON_TYPES_BY_STATUS[status];

    return <Sticker className='app-configuration-status-sticker' type={type} iconType={iconType}>{status}</Sticker>;
};

AppConfigurationStatusSticker.propTypes = {
    status: PropTypes.string
};

export {AppConfigurationStatusSticker as TestableAppConfigurationStatusSticker};
export default AppConfigurationStatusSticker;
