import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import {Field, Form} from '../../../shared/FormComponents';
import FileUploader from '../../../shared/FileUploader';
import {
    getImportSessionInitialValues,
    requestEligibilityImportSessionCreating,
    requestEligibilityImportSessions,
    requestImportsJsonrpc
} from '../../../../actions/adminPortal';
import {redirectTo} from '../../../../actions/general';
import {equal, generateUniqueId, matchFileName, parseQuery, validateFileNamesPattern, validateRequired} from '../../../../utils';
import {
    ELIGIBILITY_IMPORT_STATUSES,
    FILE_FORMATS,
    FORMS,
    IMPORT_SESSION_IN_PROGRESS_STATUSES,
    JSON_RPC_RESULTS,
    LAST_IMPORT_SESSION_IN_PROGRESS_POPUP_DATA,
    LAST_IMPORT_SESSION_WRITING_POPUP_DATA,
    ROUTES
} from '../../../../constants';
import withPopup from '../../../../HOC/withPopup';

const POPUP_ID = 'IndividualImportUploadCobraPopup';

/* istanbul ignore next */
const validate = (values, pattern) => {
    return {
        files: validateFileNamesPattern(values.files, pattern, matchFileName) || validateRequired(values.files)
    };
};

const UploadCobraFilePopup = ({closeParentPopup, closePopup, openPopup, importConfigId, filePattern}) => {
    const dispatch = useDispatch();
    const {group_alias: groupAlias} = useParams();
    const {search} = useLocation();
    const {group_name: groupName} = parseQuery(search, {arrayFormat: 'none'});
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        (async () => {
            const initialValues = await dispatch(getImportSessionInitialValues({companyAlias: groupAlias, import_config_id: importConfigId, is_cobra: true}));

            setInitialValues(initialValues);
        })();
    }, [groupAlias, importConfigId]);

    const onOpenPopup = (popupContent, actionBar, title) => {
        const popupProps = {title, actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const runImportSession = async values => {
        const {isSuccess, submissionErrors, data: session} = await dispatch(requestEligibilityImportSessionCreating(values));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(session);
        const jsonrpcObj = {jsonrpc: '2.0', method: 'run_individual_import', id: generateUniqueId(), params: {session}};
        const {jsonrpc} = await dispatch(requestImportsJsonrpc(jsonrpcObj));

        if (!equal(jsonrpc?.result, JSON_RPC_RESULTS.success)) {
            return false;
        }

        closePopup();
        closeParentPopup();
        dispatch(redirectTo(`${ROUTES.importsIndividual}/${groupAlias}?group_name=${encodeURIComponent(groupName)}&show_cobra_submit_alert=true`));
    };

    const onSubmit = useCallback(async values => {
        const {sessions} = await dispatch(requestEligibilityImportSessions({limit: 1, offset: 0, company_alias: groupAlias}));
        const lastSession = sessions[0] || {};

        if (equal(lastSession.status, ELIGIBILITY_IMPORT_STATUSES.writing)) {
            onOpenPopup(LAST_IMPORT_SESSION_WRITING_POPUP_DATA.content, <Button onClick={closePopup}>Okay</Button>);

            return false;
        }

        if (IMPORT_SESSION_IN_PROGRESS_STATUSES.some(status => equal(lastSession.status, status))) {
            const actionBar = (
                <React.Fragment>
                    <Button onClick={closePopup} type={BUTTON_TYPES.secondary}>Cancel</Button>
                    <Button className='ml-10' onClick={() => runImportSession(values)}>Yes, Continue</Button>
                </React.Fragment>
            );
            onOpenPopup(LAST_IMPORT_SESSION_IN_PROGRESS_POPUP_DATA.content, actionBar, LAST_IMPORT_SESSION_IN_PROGRESS_POPUP_DATA.title);

            return false;
        }

        runImportSession(values);
    }, [dispatch, groupAlias, closePopup]);

    return (
        <Form name={FORMS.uploadIndividualCobraImport} initialValues={initialValues} validate={values => validate(values, filePattern)} onSubmit={onSubmit}>
            {({handleSubmit, valid: isValid}) => {
                const actionBar = (
                    <Button type={BUTTON_TYPES.primary} onClick={handleSubmit} disabled={!isValid || !filePattern}>Upload File and Submit</Button>
                );
                const popupContent = (
                    <React.Fragment>
                        {!filePattern && (
                            <Alert className='mb-10' type={ALERT_TYPES.danger} description='File pattern is empty'/>
                        )}

                        <Field name='files'>
                            {props => <FileUploader {...props} isMultiple maxCount={1} isPrivateFile size='xs' accept={[FILE_FORMATS.csv, FILE_FORMATS.txt, FILE_FORMATS.xls, FILE_FORMATS.xlsx].map(format => `.${format}`)} label='Upload File' isRequired/>}
                        </Field>
                    </React.Fragment>
                );
                const popupProps = {title: 'Upload COBRA file', children: popupContent, actionBar};

                return <PopupContent {...popupProps}/>;
            }}
        </Form>
    );
};

UploadCobraFilePopup.propTypes = {
    filePattern: PropTypes.string,
    importConfigId: PropTypes.number,
    closeParentPopup: PropTypes.func,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {UploadCobraFilePopup as TestableUploadCobraFilePopup};
export default withPopup(POPUP_ID)(UploadCobraFilePopup);
