import React from 'react';
import PropTypes from 'prop-types';
import {Field as SimpleField} from 'react-final-form';
import useForm from '../../../../hooks/useForm';
import {equal, getEqual, pass} from '../../../../utils';

const Field = ({children, onChange, parse, format, ...restProps}) => {
    const form = useForm();
    const isEqual = (currValue, initialValue) => {
        if ([currValue, initialValue].every(Array.isArray)) {
            return currValue.length === initialValue.length && currValue.every((value, index) => equal(value, initialValue[index]));
        }

        if ([currValue, initialValue].some(Array.isArray)) {
            const arrayElement = Array.isArray(currValue) ? currValue : initialValue;
            return arrayElement.length === 0;
        }

        return [initialValue, currValue].every(value => [null, undefined, ''].some(getEqual(value))) || equal(initialValue, currValue);
    };
    const enhancedParse = (...args) => parse(...args, form);
    const enhancedFormat = (...args) => format(...args, form);

    return (
        <SimpleField {...restProps} parse={enhancedParse} format={enhancedFormat} isEqual={isEqual}>
            {props => {
                const {input, meta, ...restProps} = props;
                const {checked, ...restInput} = input;
                const {valid: isValid, error: errorMessage, submitError: submissionErrorMessage, touched: isTouched} = meta;

                return children({
                    ...restInput,
                    ...restProps,
                    isValid: !meta.dirtySinceLastSubmit ? isValid : true,
                    errorMessage: !meta.dirtySinceLastSubmit ? errorMessage || submissionErrorMessage : null,
                    isTouched,
                    onChange: event => {
                        if (!event?.defaultPrevented) {
                            input.onChange(event);
                        }

                        if (onChange) {
                            onChange(event, form);
                        }
                    }
                });
            }}
        </SimpleField>
    );
};

Field.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    allowNull: PropTypes.bool,
    subscription: PropTypes.shape({}),
    type: PropTypes.string,
    format: PropTypes.func,
    parse: PropTypes.func,
    onChange: PropTypes.func
};

Field.defaultProps = {
    parse: pass,
    format: pass
};

export {Field as TestableField};
export default React.memo(Field);
