import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Tabs from '@frontend/ui-kit/Components/Tabs';
import Column from '@frontend/ui-kit/Components/Column';
import Row from '@frontend/ui-kit/Components/Row';
import Pagination from '@frontend/ui-kit/Components/Pagination';
import MultipleChoice from '../MultipleChoice';
import Client from '../Client';
import EmptyData from '../../shared/EmptyData';
import {redirectTo, requestGroups, requestTPAPartners} from '../../../actions/general';
import {clearMultipleChoice} from '../../../actions/adminPortal';
import {equal, getEqual, getItemKeyValue, isEmpty} from '../../../utils';
import {ROUTES} from '../../../constants';
import emptyDataImage from '../../../static/images/empty_data1.svg';

const IMPORT_TYPES = {
    individual: 'individual',
    tpa: 'tpa'
};

const ALL_CLIENTS_TABS = [
    {index: 0, title: 'Individual Groups', key: IMPORT_TYPES.individual, route: ROUTES.importsIndividual},
    {index: 1, title: 'TPA Partners', key: IMPORT_TYPES.tpa, route: ROUTES.importsTpa}
];

const TABS = ALL_CLIENTS_TABS.map(getItemKeyValue('title'));

const CLIENTS_PER_PAGE = 20;

const AllClients = () => {
    const {pathname} = useHistory().location;
    const {index: activeTab, key: importType} = useMemo(() => {
        return ALL_CLIENTS_TABS.find(getEqual(pathname.split('/').pop(), 'key'));
    }, [pathname]);
    const [clientsSearch, setClientsSearch] = useState({});
    const [clients, setClients] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [clientsTotal, setClientsTotal] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearMultipleChoice());
        };
    }, []);

    useEffect(() => {
        (async () => {
            const pagination = {
                limit: CLIENTS_PER_PAGE,
                offset: currentPage * CLIENTS_PER_PAGE
            };
            const requestClients = equal(importType, IMPORT_TYPES.individual) ? requestGroups : requestTPAPartners;
            const {partners, groups, count, isSuccess} = await dispatch(requestClients({...pagination, ...clientsSearch}));

            if (!isSuccess) {
                return false;
            }

            setClients(groups || partners);
            setClientsTotal(count);
        })();
    }, [dispatch, importType, clientsSearch, currentPage]);

    const searchChoices = [{name: 'All', type: 'query'}];

    const onTabChange = tabIndex => dispatch(redirectTo(ALL_CLIENTS_TABS[tabIndex].route));

    const pageCount = Math.ceil(clientsTotal / CLIENTS_PER_PAGE);
    const paginationProps = {
        unitCount: clientsTotal,
        pageIndex: currentPage,
        pageSize: CLIENTS_PER_PAGE,
        pageCount,
        gotoPage: pageIndex => setCurrentPage(pageIndex),
        canNextPage: !equal(pageCount, currentPage + 1),
        canPreviousPage: !equal(currentPage, 0),
        className: 'mt-20'
    };

    return (
        <React.Fragment>
            <Heading type={HEADING_TYPES['1']}>All Clients</Heading>

            <ContentSection className='mt-20'>
                <MultipleChoice choices={searchChoices} onChange={choice => setClientsSearch(choice)}/>
            </ContentSection>

            <Tabs className='mt-20' tabs={TABS} onChange={onTabChange} activeTab={activeTab}/>

            <Row className='mt-10' rowGap='md'>
                {!isEmpty(clients) ? clients.map(client => (
                    <Column key={client.alias} sm={3}>
                        <Client client={client}/>
                    </Column>
                )) : (
                    <EmptyData image={emptyDataImage} description={'Sorry, could not find the client you’re looking for.\nPlease try again.'}/>
                )}
            </Row>

            {clientsTotal > CLIENTS_PER_PAGE && <Pagination {...paginationProps}/>}
        </React.Fragment>
    );
};

export {AllClients as TestableAllClients};
export default React.memo(AllClients);
