import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@frontend/ui-kit/Components/Icon';
import './index.scss';

const SidebarMainItem = ({title, icon, isActive, isHighlighted, onClick, path}) => {
    const activeClassName = isActive ? 'sidebar-main-item_active' : '';
    const highlightedClassName = isHighlighted ? 'sidebar-main-item_highlighted' : '';
    const className = `sidebar-main-item ${activeClassName} ${highlightedClassName}`;
    const enhancedTitle = title.split('\n').map(str => <div key={str}>{str}</div>);

    return (
        <div className={className} onClick={onClick} data-testid={path}>
            <Icon className='sidebar-main-item__icon sidebar-main-item__icon' type={icon}/>
            <div className='sidebar-main-item__title'>{enhancedTitle}</div>
        </div>
    );
};

SidebarMainItem.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string,
    isActive: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    onClick: PropTypes.func
};

export {SidebarMainItem as TestableSidebarMainItem};
export default React.memo(SidebarMainItem);
