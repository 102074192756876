import React from 'react';
import Input from '@frontend/ui-kit/Components/Input';
import {Field} from '../../../../shared/FormComponents';

export const WebLinkField = () => {
    return (
        <Field name='content.entry.link'>
            {props => <Input {...props} label='Web Link'/>}
        </Field>
    );
};
