import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const PdfChart = ({className, chartClassName, Chart, ...chartProps}) => {
    const enhancedChartProps = {
        ...chartProps,
        options: {
            ...chartProps.options,
            staticTooltip: true,
            tooltips: {
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                caretPadding: 16,
                titleFontSize: 26,
                titleMarginBottom: 24,
                footerFontSize: 30,
                xPadding: 24,
                yPadding: 16
            }
        }
    };

    return (
        <div className={`pdf-chart ${className}`}>
            <Chart className='chart' {...enhancedChartProps}/>
        </div>
    );
};

PdfChart.propTypes = {
    className: PropTypes.string,
    chartClassName: PropTypes.string,
    Chart: PropTypes.element.isRequired,
    data: PropTypes.exact({
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        descriptions: PropTypes.arrayOf(PropTypes.string),
        datasets: PropTypes.arrayOf(PropTypes.shape({
            data: PropTypes.arrayOf(PropTypes.number).isRequired
        }))
    }),
    paletteType: PropTypes.string,
    options: PropTypes.shape({
        scales: PropTypes.shape({
            yAxes: PropTypes.arrayOf(PropTypes.shape({})),
            xAxes: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        title: PropTypes.shape({text: PropTypes.string}),
        centerText: PropTypes.shape({text: PropTypes.string}),
        annotation: PropTypes.shape({annotations: PropTypes.arrayOf(PropTypes.shape({}))})
    })
};

PdfChart.defaultProps = {
    className: '',
    chartClassName: ''
};

export {PdfChart as TestablePdfChart};
export default PdfChart;
