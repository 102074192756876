import React from 'react';
import PropTypes from 'prop-types';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Link from '@frontend/ui-kit/Components/Link';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import ReportBox from '../ReportBox';
import withReportingInfo from '../../../HOC/withReportingInfo';
import {ROUTES, SATISFACTION_REVIEWS_MIN_LIMIT} from '../../../constants';
import {getDelimitedNum} from '../../../utils';

const ROI_TOOLTIP_CONTENT = (
    <React.Fragment>Return on Investment is calculated at the end of each month.</React.Fragment>
);

const UTILIZATION_TOOLTIP_CONTENT = (
    <React.Fragment>
        Service Requests include Telemedicine Consults, Rx Savings Requests, Provider and Facility Recommendations,
        Appointment Schedules, Medical Bill Reviews, and Benefit Support Interactions via phone call or chat.
    </React.Fragment>
);

const SATISFACTION_TOOLTIP_CONTENT = (
    <React.Fragment>Member satisfaction rating percentage is shown once there are 10 or more reviews.</React.Fragment>
);

const Summary = ({info, isRoiVisible}) => {
    const {
        roi,
        totalSavings,
        memberSatisfaction,
        satisfactionTotalReviews,
        utilizationTicketsCount,
        activatedEmployeesCount,
        eligibleEmployeesCount,
        activationRating,
        activationChartData
    } = info;
    const activationChartProps = {
        title: 'Activation Rate',
        titleInfo: `
            A HealthJoy-eligible employee is someone who meets your company’s eligibility criteria (e.g. employees enrolled in a medical plan or all employees).
            An employee is considered activated after registering for a HealthJoy account online or by calling our concierge team.
        `,
        description: `
            Activation rate is calculated as the number of distinct activated HealthJoy-eligible employees divided by
            the total number of distinct HealthJoy-eligible employees eligible.
        `,
        unit: '%',
        data: activationChartData,
        options: {scales: {yAxes: [{ticks: {max: 100}}]}}
    };

    return (
        <div className='reporting'>
            <ContentSection className='reporting-section'>
                <Row columnGap='xlg'>
                    <Column className='reporting-section__column reporting-section__column_separated' sm={6}>
                        <div className='reporting-chart'>
                            <LineChart {...activationChartProps}/>
                        </div>
                    </Column>

                    <Column className='reporting-section__column' sm={6}>
                        <Heading className='reporting-info-title' data-testid='summary-title' type={HEADING_TYPES['5']}>
                            Employee Activation
                        </Heading>

                        <Text className='reporting-info-subtitle' type={TEXT_TYPES.bodyBold}>
                            {activationRating}% of your eligible employees activated their accounts
                        </Text>

                        <Text className='reporting-info'>
                            {activatedEmployeesCount} of your {eligibleEmployeesCount} employees have registered for HealthJoy.
                        </Text>

                        <Text>Here are a few ways you can help boost your activation:</Text>
                        <ul className='reporting-list'>
                            <li className='reporting-list__item'>
                                <Text>
                                    Request a list of not activated users from your Customer Success contact and follow up
                                    with these users to activate.
                                </Text>
                            </li>

                            <li className='reporting-list__item'>
                                <Text>
                                    Offer an incentive to your employees to activate their accounts.
                                    Talk to your Customer Success contact for assistance with incentive ideas and process.
                                </Text>
                            </li>
                            <li className='reporting-list__item'>
                                <Text>
                                    Visit the <Link href={`${ROUTES.educationAndMarketing}/10#9`}>Education & Marketing</Link> section for emails,
                                    text messages, videos, and webinars to build employee awareness.
                                </Text>
                            </li>
                        </ul>
                    </Column>
                </Row>
            </ContentSection>

            <Row className='reporting-section' columnGap='xs'>
                <Column className='reporting-section__column' sm={3}>
                    {isRoiVisible && (
                        <ReportBox iconType={ICON_TYPES.repeat}
                            title='Return on Investment'
                            caption={`$${getDelimitedNum(totalSavings)} Saved`}
                            description={(
                                <React.Fragment>
                                    Calculated as the difference between the Total Company Savings and the Company's Prorated Investment.
                                    Total company savings is the sum of the total estimated savings for each service and administrative savings.
                                    View details under the <Link href={ROUTES.programSavingsReporting}>Program Savings</Link> tab.
                                </React.Fragment>
                            )}
                            tooltipText={ROI_TOOLTIP_CONTENT}
                            value={roi}
                            isPlaceholder={!roi}
                            unit='x'/>
                    )}

                    {!isRoiVisible && (
                        <ReportBox iconType={ICON_TYPES.repeat}
                            title='Total Savings'
                            caption='Claims Savings Estimate'
                            description={(
                                <React.Fragment>
                                    Sum total of estimated savings for each service and administrative savings.
                                    You can read more about how each category is calculated under the <Link href={ROUTES.programSavingsReporting}>Program Savings</Link> tab.
                                </React.Fragment>
                            )}
                            value={getDelimitedNum(totalSavings)}
                            unit='$'/>
                    )}
                </Column>

                <Column className='reporting-section__column' sm={3}>
                    <ReportBox iconType={ICON_TYPES.userCheck}
                        title='Member Utilization'
                        caption='Total Number of Unique Actions'
                        description='Includes Benefit Wallet views and Service Requests that have been completed for your company.'
                        tooltipText={UTILIZATION_TOOLTIP_CONTENT}
                        value={getDelimitedNum(utilizationTicketsCount)}/>
                </Column>

                <Column className='reporting-section__column' sm={3}>
                    <ReportBox iconType={ICON_TYPES.powerSignOut}
                        title='Activated Employees'
                        caption={`${activatedEmployeesCount} of ${eligibleEmployeesCount} Eligible Employees`}
                        description='An employee is considered activated once they register their HealthJoy account online or by calling our concierge team.'
                        value={activationRating}
                        unit='%'/>
                </Column>

                <Column className='reporting-section__column' sm={3}>
                    <ReportBox iconType={ICON_TYPES.achievement}
                        title='Member Satisfaction'
                        caption='Positive Rating Percentage'
                        description='Calculated as the total number of satisfied member ratings divided by the total number of member ratings.'
                        tooltipText={SATISFACTION_TOOLTIP_CONTENT}
                        value={memberSatisfaction}
                        isPlaceholder={satisfactionTotalReviews < SATISFACTION_REVIEWS_MIN_LIMIT}
                        unit='%'/>
                </Column>
            </Row>
        </div>
    );
};

Summary.propTypes = {
    isRoiVisible: PropTypes.bool,
    info: PropTypes.shape({
        activatedEmployeesCount: PropTypes.number,
        activationRating: PropTypes.number,
        eligibleEmployeesCount: PropTypes.number,
        memberSatisfaction: PropTypes.number,
        roi: PropTypes.number,
        satisfactionTotalReviews: PropTypes.number,
        totalSavings: PropTypes.number,
        utilizationTicketsCount: PropTypes.number,
        activationChartData: PropTypes.shape()
    })
};

export {Summary as TestableSummary};
export default withReportingInfo({isDisclaimerShowed: true})(Summary);
