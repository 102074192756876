import React from 'react';
import Layout from '../../../Components/Error/Layout';
import NotFound from '../../../Components/Error/NotFound';

const PageNotFound = () => (
    <Layout>
        <NotFound/>
    </Layout>
);

export {PageNotFound as TestablePageNotFound};
export default PageNotFound;
