import React, {useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import Select from '@frontend/ui-kit/Components/Select';
import PlanCopyPreview from '../PlanCopyPreview';
import {requestCompanies} from '../../../actions/general';
import {requestCorePlansList, requestAncillaryPlansList, requestCorePlanDetailsForCopying, requestAncillaryPlanDetailsForCopying} from '../../../actions/benefits';
import {getActiveCompany, getCompaniesMap} from '../../../selectors/general';
import {getAncillaryPlanLabel} from '../../../helpers';
import {equal, getEqual, promisifyAsyncFunction} from '../../../utils';
import planCopyPreviewCTA from '../../../static/images/planCopyPreviewCTA.svg';
import './index.scss';

const MIN_SELECT_COMPANY_COUNT = 2;

const PlanCopyPopup = ({isCorePlan, planId, planType, planCategory, planPeriodId, onSave, onClose}) => {
    const dispatch = useDispatch();
    const [plans, setPlans] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
    const [selectedCompanyAlias, setSelectedCompanyAlias] = useState(null);
    const {name: activeCompanyName, alias: activeCompanyAlias} = useSelector(getActiveCompany);
    const companiesMap = useSelector(getCompaniesMap);

    const {name: selectedCompanyName} = useMemo(() => Object.values(companiesMap).find(getEqual(selectedCompanyAlias, 'alias')) || {}, [companiesMap, selectedCompanyAlias]);
    const isSelectCompany = useMemo(() => Object.keys(companiesMap).length >= MIN_SELECT_COMPANY_COUNT, [companiesMap]);
    const normalizedPlans = useMemo(() => {
        return plans.map(({id, name, category, category_index: categoryIndex}) => {
            const label = isCorePlan ? name : getAncillaryPlanLabel({category, categoryIndex, cardTitle: name});

            return equal(id, planId) ? null : {label, value: id};
        }).filter(Boolean);
    }, [plans, planId, isCorePlan]);

    const loadCompanyOptions = useCallback(promisifyAsyncFunction(async query => {
        const {companies} = await dispatch(requestCompanies(query));

        return companies.map(({name: label, alias: value}) => ({label, value}));
    }), []);

    const onCopyPlan = useCallback(() => {
        const isCopyingAcrossDifferentCompanies = !!selectedCompanyAlias && !equal(selectedCompanyAlias, activeCompanyAlias);

        onSave(selectedPlanDetails, isCopyingAcrossDifferentCompanies);
        onClose();
    }, [selectedCompanyAlias, activeCompanyAlias, selectedPlanDetails, onSave, onClose]);

    const onChangePlan = useCallback(async selectedPlanId => {
        const companyAlias = selectedCompanyAlias ?? activeCompanyAlias;
        const {customized: isPlanCustomized} = plans.find(getEqual(selectedPlanId, 'id'));
        const requestPlanDetailsForCopying = isCorePlan ? requestCorePlanDetailsForCopying : requestAncillaryPlanDetailsForCopying;
        const {planDetails} = await dispatch(requestPlanDetailsForCopying(
            companyAlias,
            isCorePlan ? planType : selectedPlanId,
            isCorePlan ? selectedPlanId : isPlanCustomized
        ));

        setSelectedPlanId(selectedPlanId);
        setSelectedPlanDetails(planDetails);
    }, [dispatch, selectedCompanyAlias, activeCompanyAlias, planType, plans, isCorePlan]);

    const onRequestPlansForCopying = useCallback(async () => {
        const companyAlias = selectedCompanyAlias ?? activeCompanyAlias;
        const isCopyingAcrossSameCompany = equal(companyAlias, activeCompanyAlias);
        const requestPlansForCopying = isCorePlan ? requestCorePlansList : requestAncillaryPlansList;

        const params = {
            company_alias: companyAlias,
            plan_period_id: isCorePlan || !isCopyingAcrossSameCompany ? undefined : planPeriodId,
            ...(isCorePlan ? {plan_kinds: [planType]} : {category: planCategory}),
            with_related: 'wallets',
            only_active: true,
            with_progress: true
        };
        const {plans} = await dispatch(requestPlansForCopying(params));

        setPlans(plans);
    }, [dispatch, activeCompanyAlias, selectedCompanyAlias, planType, planPeriodId, planCategory, isCorePlan]);

    useEffect(() => {
        onRequestPlansForCopying();
    }, [onRequestPlansForCopying, planId, selectedCompanyAlias]);

    const companyAutocompleteProps = {
        isCreatable: false,
        value: selectedCompanyName ?? activeCompanyName,
        onChange: setSelectedCompanyAlias,
        loadOptions: loadCompanyOptions,
        defaultOptions: true,
        placeholder: 'Type to search for company'
    };
    const actionBar = (
        <React.Fragment>
            <Button onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
            <Button onClick={onCopyPlan} disabled={!selectedPlanId}>Copy</Button>
        </React.Fragment>
    );

    return (
        <PopupContent title='Copy Data From Another Plan' actionBar={actionBar}>
            <Row className='plan-copy-popup-body'>
                <Column sm={12}>
                    <Text className='plan-copy-popup-body__description'>Select the plan from which you'd like to copy over data.</Text>
                </Column>

                <Column sm={6}>
                    {isSelectCompany && (
                        <AsyncAutocomplete {...companyAutocompleteProps} isMenuPortal label='Select Company' wrapperClassName='mb-12'/>
                    )}

                    <Select value={selectedPlanId} options={normalizedPlans} onChange={onChangePlan} isMenuPortal label='Select Plan'/>
                </Column>

                <Column sm={6}>
                    {selectedPlanDetails && (
                        <PlanCopyPreview isCorePlan={isCorePlan} planDetails={selectedPlanDetails} planType={planType} planCategory={planCategory}/>
                    )}

                    {!selectedPlanDetails && (
                        <div className='plan-copy-preview-cta'>
                            <img className='plan-copy-preview-cta__img' src={planCopyPreviewCTA} alt=''/>
                            <Text className='plan-copy-preview-cta__text'>Select a benefit to display a preview</Text>
                        </div>
                    )}
                </Column>
            </Row>
        </PopupContent>
    );
};

PlanCopyPopup.propTypes = {
    isCorePlan: PropTypes.bool,
    planId: PropTypes.number,
    planType: PropTypes.string,
    planCategory: PropTypes.string,
    planPeriodId: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func
};

export {PlanCopyPopup as TestablePlanCopyPopup};
export default React.memo(PlanCopyPopup);
