import React from 'react';
import Text from '@frontend/ui-kit/Components/Text';
import hjLogo from '../../../static/images/hj-logo-white.svg';
import './index.scss';

const Legal = () => {
    return (
        <div className='legal terms'>
            <div className='terms-header'>
                <img className='terms-header__logo' src={hjLogo} alt=''/>

                <Text className='terms-header__title'>Terms & Conditions of Use</Text>
            </div>

            <div className='terms-body'>
                <Text>This Terms and Conditions of Use (&#8220;Terms of Use&#8221;) is an agreement between you and
                    HealthJoy. The terms and conditions in this Terms of Use are the rules that you agree to by using
                    HealthJoy, the HealthJoy website (&#8220;Site&#8221;) and its associated services. HealthJoy&#8217;s
                    Privacy Policy, while a separate document, is incorporated into this Terms of Use agreement between
                    you and HealthJoy. Additionally, app.healthjoy.com and HealthJoy Access are different services and
                    separate and different terms of use apply to app.healthjoy.com.<br/>Your use of this site signifies
                    your assent to all of the Terms of Use listed below. If you do not agree to all of these Terms and
                    Conditions listed below you should immediately leave and not use this site.<br/>HealthJoy may revise
                    and update any and all of these Terms and Conditions at any time. Your continued usage of the Site
                    will mean you accept those changes.</Text>

                <Text className='terms-body__title'>Table of Contents</Text>

                <ul className='terms-body-list left-side'>
                    <li>
                        <a className='terms-body-list__link' href='#part_introduction'>Introduction</a>
                    </li>

                    <li>
                        <a className='terms-body-list__link' href='#part_your_account'>Your Account</a>
                    </li>

                    <li>
                        <a className='terms-body-list__link' href='#part_content_and_services'>Content and Services</a>
                    </li>
                    <li>
                        <a className='terms-body-list__link' href='#part_liability'>Liability of HealthJoy and Its Licensors</a>
                    </li>

                    <li>
                        <a className='terms-body-list__link' href='#part_user_submissions'>User Submissions</a>
                    </li>

                    <li>
                        <a className='terms-body-list__link' href='#part_indemnity'>Indemnity</a>
                    </li>

                    <li>
                        <a className='terms-body-list__link' href='#part_notice_and_takedown'>Notice and Takedown Procedures, Copyright Agent</a>
                    </li>

                    <li>
                        <a className='terms-body-list__link' href='#part_complete_agreement'>Complete Agreement</a>
                    </li>
                    <li>
                        <a className='terms-body-list__link' href='#part_additional_terms'>Additional Terms: HealthJoy Healthcare Services</a>
                    </li>
                    <li>
                        <a className='terms-body-list__link' href='#part_rights'>Rights</a>
                    </li>
                </ul>

                <div id='part_introduction'>
                    <Text className='terms-body__title'>Introduction</Text>

                    <Text>HealthJoy connects you with information, applications, surveys, etc. HealthJoy does not provide
                        formal medical diagnosis, treatment, or prescriptions in any way, shape, or form. Any and all
                        information provided by HealthJoy or its Health Providers, including but not limited to
                        communications with HealthJoy Health Providers via chat, text, video or audio communication is
                        intended to be for general informational purposes only. In no way does HealthJoy intend to
                        create a physician-patient relationship as defined by state and federal law. HealthJoy is not a
                        substitute for professional medical diagnosis or treatment and therefore any reliance on
                        information provided by HealthJoy or its Health Providers is done so solely at your own risk and
                        you accept any and all consequences stemming from your misuse of or reliance on such
                        information. The contents of the HealthJoy Site, such as text, graphics, images, information
                        obtained from HealthJoy&#8217;s licensors, and other material contained on the HealthJoy Site
                        (&#8220;Content&#8221;) are for informational purposes only. The Content is not intended to be a
                        substitute for professional medical advice, diagnosis, or treatment and therefore you should
                        always seek the advice of your primary care physician or other qualified health provider with
                        any questions you may have regarding a medical condition. HealthJoy does not intend to interfere
                        with your primary care physician&#8217;s or other qualified health provider&#8217;s medical
                        advice and you should never disregard professional medical advice or delay in seeking it because
                        of something you have read on the HealthJoy Site. The Site may contain health- or
                        medical-related materials that are sexually explicit. If you find these materials offensive, you
                        may not want to use our Site. The services provided by HealthJoy are available for use by
                        children, but the Member for all patients under the age of 18 must be the patient&#8217;s parent
                        or legal guardian. If you register as the parent or legal guardian on behalf of a minor, you
                        will be fully responsible for complying with these Terms and Conditions of Use.</Text>
                </div>

                <div id='part_your_account'>
                    <Text className='terms-body__title'>Your Account</Text>

                    <Text>You are responsible for your own account passwords, information, etc. You are personally
                        representing all information you provide to HealthJoy is accurate and complete upon submission.
                        HealthJoy has several tools that allow you to record and store information and you are
                        personally responsible for taking all reasonable steps to ensure that no unauthorized person
                        shall have access to your HealthJoy passwords or accounts. It is your sole responsibility to
                        control the dissemination and use of sign-in name, screen name, and passwords, to authorize,
                        monitor, and control access to and use of your HealthJoy account and password, and to promptly
                        inform HealthJoy if you believe your account or password has been compromised or if there is any
                        other reason you need to deactivate a password. To send us an email, use the &#8220;Contact
                        Us&#8221; links located at the bottom of every page of our site. You grant HealthJoy and all
                        other persons or entities involved in the operation of the Site the right to transmit, monitor,
                        retrieve, store, and use your information in connection with the operation of the Site.
                        HealthJoy cannot and does not assume any responsibility or liability for any information you
                        submit, or your or third parties&#8217; use or misuse of information transmitted or received
                        using HealthJoy tools and services.</Text>
                </div>

                <div id='part_content_and_services'>
                    <Text className='terms-body__title'>Content and Services</Text>

                    <Text>HealthJoy authorizes you to view or download a single copy of the material on the HealthJoy Site
                        solely for your personal, noncommercial use if you include the following copyright
                        notice: &#8220;©2018, HealthJoy, LLC. All rights reserved.&#8221; and other copyright and
                        proprietary rights notices that are contained in the Content. Any special rules for the use of
                        certain software and other items accessible on the HealthJoy Site may be included elsewhere
                        within the Site and are incorporated into these Terms and Conditions by reference. The Content
                        is protected by copyright under both United States and foreign laws. Title to the Content
                        remains with HealthJoy and/or its licensors and any use of the Content not expressly permitted
                        by these Terms and Conditions is a breach of these Terms and Conditions and may violate not only
                        this agreement but also copyright, trademark, and other laws. HealthJoy has the editorial
                        discretion to change or terminate content or features without notice to you or any other user.
                        All rights not expressly granted herein are reserved to HealthJoy and its licensors. If you
                        violate any of these Terms and Conditions, your permission to use the Content automatically
                        terminates and you must immediately destroy any copies you have made of any portion of the
                        Content. None of the Site content (other than information you receive from Health Providers)
                        should be considered medical advice or an endorsement, representation or warranty that any
                        particular medication or treatment is safe, appropriate, or effective for you.</Text>
                </div>

                <div id='part_liability'>
                    <Text className='indt'>Liability of HealthJoy and Its Licensors</Text>

                    <Text>You are using the HealthJoy Site and the HealthJoy Content at your own risk. When using the
                        HealthJoy Site, information will be transmitted over a medium that may be beyond the control and
                        jurisdiction of HealthJoy and its suppliers. Accordingly, HealthJoy assumes no liability for or
                        relating to the delay, failure, interruption, or corruption of any data or other information
                        transmitted in connection with use of the HealthJoy Site. The HealthJoy Site and the content are
                        provided on an &#8220;as is&#8221; basis. NEITHER HealthJoy, ITS HEALTH PROVIDERS, NOR ANY OF
                        OUR LICENSORS OR SUPPLIERS, MAY BE HELD LIABLE UNDER THIS AGREEMENT FOR ANY DAMAGES OTHER THAN
                        DIRECT DAMAGES. EVEN IF THE PARTY ACTUALLY KNOWS OR REASONABLY SHOULD HAVE KNOWN THAT OTHER
                        DAMAGES ARE POSSIBLE OR THAT DIRECT DAMAGES ARE NOT A SATISFACTORY REMEDY, IT IS STILL THE SOLE
                        LEGAL REMEDY. TO THE FULLEST EXTENT PERMITTED BY LAW, HealthJoy DISCLAIMS ALL WARRANTIES, EITHER
                        EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES
                        OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES&#8217; RIGHTS, AND FITNESS FOR PARTICULAR
                        PURPOSE. Without limiting the foregoing, HealthJoy and any of its licensors and/or its suppliers
                        make no representations or warranties about the accuracy, reliability, completeness,
                        currentness, or timeliness of the Content, software, text, graphics, links, or communications
                        provided on or through the use of the HealthJoy Site or HealthJoy nor the satisfaction of any
                        government regulations requiring disclosure of information on prescription drug products or the
                        approval or compliance of any software tools with regard to the Content contained on the
                        HealthJoy Site. In no event shall HealthJoy, its licensors, its suppliers, or any third parties
                        mentioned on the HealthJoy Site be liable for any damages (including, without limitation,
                        incidental and consequential damages, personal injury/wrongful death, lost profits, or damages
                        resulting from lost data or business interruption) resulting from the use of or inability to use
                        the HealthJoy Site or the Content, whether based on warranty, contract, tort, or any other legal
                        theory, and whether or not HealthJoy, its licensors, its suppliers, or any third parties
                        mentioned on the HealthJoy Site are advised of the possibility of such damages. HealthJoy, its
                        licensors, its suppliers, or any third parties mentioned on the HealthJoy Site shall be liable
                        only to the extent of actual damages incurred by you, not to exceed U.S. $250. HealthJoy, its
                        licensors, its suppliers, or any third parties mentioned on the HealthJoy Site are not liable
                        for any personal injury, including death, caused by your use or misuse of the Site, Content, or
                        Public Areas (as defined below). Any claims arising in connection with your use of the Site, any
                        Content, or the Public Areas must be brought within one (1) year of the date of the event giving
                        rise to such action occurred. Remedies under these Terms and Conditions are exclusive and are
                        limited to those expressly provided for in these Terms and Conditions.</Text>
                </div>

                <div id='part_indemnity'>
                    <Text className='terms-body__title'>Indemnity</Text>

                    <Text>You agree to defend, indemnify, and hold HealthJoy, its officers, directors, employees, agents,
                        licensors, and suppliers, harmless from and against any claims, actions or demands, liabilities
                        and settlements including without limitation, reasonable legal and accounting fees, resulting
                        from, or alleged to result from, your violation of these Terms and Conditions.</Text>
                </div>

                <div id='part_user_submissions'>
                    <Text className='terms-body__title'>User Submissions</Text>

                    <Text>The personal information you submit to HealthJoy is governed by the <a
                        href='https://healthjoy.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>HealthJoy
                        Privacy Policy</a>. To the extent there is an inconsistency between this Agreement and the <a
                        href='https://healthjoy.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>HealthJoy
                        Privacy Policy</a>, this Agreement shall govern. The Site contains functionality (user reviews)
                        that allows users to upload content to the Site (collectively &#8220;Public Areas&#8221;). By
                        submitting communications or content to the Public Areas, you agree that such submission is
                        non-confidential for all purposes and that you will not upload or transmit any communications or
                        content of any type to the Public Areas that infringe or violate any rights of any party. You
                        agree that if you make any such submission you agree that you will not send or transmit to
                        HealthJoy by email, (including through the email addresses listed on the &#8220;Contact
                        Us&#8221; page) any communication or content that infringes or violates any rights of any party.
                        If you submit any business information, idea, concept or invention to HealthJoy by email, you
                        agree such submission is non-confidential for all purposes. If you make any submission to a
                        Public Area, including but not limited to, a submission of any business information, idea,
                        concept or invention to HealthJoy by email, you automatically grant-or warrant that the owner of
                        such content or intellectual property has expressly granted- HealthJoy a royalty-free,
                        perpetual, irrevocable, world-wide nonexclusive license to use, reproduce, create derivative
                        works from, modify, publish, edit, translate, distribute, perform, and display the communication
                        or content in any media or medium, or any form, format, or forum now known or hereafter
                        developed. Additionally, HealthJoy may sublicense any of its rights through multiple tiers of
                        sublicenses as it so chooses. If you wish to keep any business information, ideas, concepts or
                        inventions private or proprietary, do not submit them to the Public Areas or to HealthJoy by
                        email. We try to answer every email in a timely manner, but are not always able to do so.
                        HealthJoy reserves the right to review all media prior to submission to the site and to remove
                        any media for any reason, at any time, without prior notice, at our sole discretion. You agree
                        to only post or upload Media that you have taken yourself or that you have all rights to
                        transmit and license and which do not violate trademark, copyright, privacy, or any other rights
                        of any other person or entity. Uploading Media of other people, without their explicit
                        permission, is strictly prohibited and by uploading any media on the HealthJoy site, you warrant
                        that you have permission from all persons appearing in your Media for you to make this
                        contribution and grant rights described herein. It is strictly prohibited to upload Media of any
                        kind that contain expressions of hate, abuse, offensive images or conduct, obscenity,
                        pornography, sexually explicit or any material that could give rise to any civil or criminal
                        liability under applicable law or regulations or that otherwise may be in conflict with these
                        Terms and Conditions and the <a href='https://healthjoy.com/privacy-policy/' target='_blank'
                                                        rel='noopener noreferrer'>HealthJoy Privacy Policy</a>. You
                        agree that you will not upload any material that contains software viruses or any other computer
                        code, files or programs designed to interrupt, destroy or limit the functionality of any
                        computer software or this Web site. By uploading any media you grant to HealthJoy a perpetual,
                        non-exclusive, worldwide, royalty-free license to use, copy, print, display, reproduce, modify,
                        publish, post, transmit and distribute the media and any material included in the media, you
                        certify that any person pictured in the submitted media (or, if a minor, his/her parent/legal
                        guardian) authorizes HealthJoy to use, copy, print, display, reproduce, modify, publish, post,
                        transmit and distribute the media and any material included in such media, and you agree to
                        indemnify HealthJoy and its affiliates, directors, officers and employees and hold them harmless
                        from any and all claims and expenses, including attorneys&#8217; fees, arising from the media
                        and/or your failure to comply with these the terms described in this document.</Text>
                </div>

                <div id='part_notice_and_takedown'>
                    <Text className='terms-body__title'>Notice and Takedown Procedures; and Copyright Agent</Text>

                    <Text>If You believe any materials accessible on or from the Site infringe Your copyright, You may
                        request removal of those materials (or access thereto) by contacting Our copyright agent
                        (identified below) and providing the following information:</Text>

                    <ul className='terms-body-list'>
                        <li className='lst'>Identification of the copyrighted work that You believe to be infringed.
                            Please describe the work, and where possible include a copy or the location (e.g., URL) of
                            an authorized version of the work.
                        </li>
                        <li className='lst'>Identification of the material that You believe to be infringing and its
                            location. Please describe the material, and provide Us with its URL or any other pertinent
                            information that will allow Us to locate the material.
                        </li>
                        <li className='lst'>Your name, address, telephone number and (if available) e-mail address.</li>
                        <li className='lst'>A statement that You have a good faith belief that the complained of use of
                            the materials is not authorized by the copyright owner, its agent, or the law.
                        </li>
                        <li className='lst'>A statement that the information that You have supplied is accurate, and
                            indicating that &#8220;under penalty of perjury,&#8221; You are the copyright owner or are
                            authorized to act on the copyright owner&#8217;s behalf.
                        </li>
                        <li className='lst'>A signature or the electronic equivalent from the copyright holder or
                            authorized representative.
                        </li>
                    </ul>
                    <p>HealthJoy&#8217;s agent for copyright issues relating to this web site is as follows:</p>
                    <p>HealthJoy, LLC</p>
                    <p className='text erc'>Attn: Privacy Officer<br/>215 W Superior Street<br/>5th Floor<br/>Chicago,
                        Illinois 60654</p>
                    <p>customerservice@HealthJoy.com</p>
                    <p>In an effort to protect the rights of copyright owners, HealthJoy maintains a policy for the
                        termination, in appropriate circumstances, of subscribers and account holders of the Site who
                        are repeat infringers.</p>
                </div>

                <div id='part_complete_agreement'>
                    <Text className='terms-body__title'>Governing Law; Arbitration Agreement</Text>

                    <Text>HealthJoy may provide links to third-party web sites, may select certain sites as priority
                        responses to search terms you enter, and may agree to allow advertisers to respond to certain
                        search terms with advertisements or sponsored content. However, HealthJoy does not recommend or
                        endorse the content on any third-party websites nor does HealthJoy endorse any product, service,
                        or treatment advertised on the HealthJoy Site. HealthJoy is not responsible for the content of
                        linked third-party sites, sites framed within the HealthJoy Site, third-party sites provided as
                        search results, or third-party advertisements, and does not make any representations regarding
                        their content or accuracy. Your use of third-party websites is at your own risk and subject to
                        the terms and conditions of use for such sites.</Text>
                </div>
                <div id='part_additional_terms'>
                    <Text className='terms-body__title'>Additional Terms: HealthJoy Healthcare Services</Text>
                    <p>The following terms and conditions apply to your use of HealthJoy and its live consults performed
                        by chat, audio or video (collectively, &#8220;Virtual Consults&#8221;). Your use of HealthJoy
                        signifies your assent to the Terms of Use and you represent that you understand and agree to the
                        applicable HealthJoy terms and conditions stated above as well as these specific HealthJoy terms
                        and conditions as follows:</p>
                    <ul className='terms-body-list'>
                        <li className='lst'>You are entering into an agreement with HealthJoy, not HealthJoy.com, which
                            in agreement with HealthJoy, LLC shall be a provider of professional medical services to
                            you.
                        </li>
                        <li className='lst'>You are engaging in a physician-patient relationship with a HealthJoy, LLC
                            Health Provider when a Virtual Consult is performed.
                        </li>
                        <li className='lst'>You represent to HealthJoy that you have a primary care physician, you
                            understand and agree that HealthJoy&#8217;s Health Providers are not your primary care
                            physician, and you agree not to use HealthJoy&#8217;s Health Providers as a substitute for
                            interactions with your primary care physician.
                        </li>
                        <li className='lst'>You understand and agree that all HealthJoy medical diagnosis and
                            prescriptions are limited in type and duration and that you will not request diagnosis or
                            prescriptions that for any type or duration that is not permitted by HealthJoy.
                        </li>
                        <li className='lst'>You understand that HealthJoy&#8217;s Health Providers may not prescribe
                            you:
                            <ul className='terms-body-list '>
                                <li className='lst'>Prescriptions for narcotics or DEA (Drug Enforcement Administration)
                                    (http://www.deadiversion.usdoj.gov/schedules/) controlled substances (Schedule I,
                                    II, III, IV)
                                </li>
                                <li className='lst'>Prescriptions for medications that are restricted by states</li>
                                <li className='lst'>Prescriptions for medications for psychiatric illnesses</li>
                                <li className='lst'>Prescriptions for lifestyle medications such as erectile dysfunction
                                    or diet drugs.
                                </li>
                            </ul>
                        </li>
                        <li className='lst'>You understand you should never delay seeking advice from your primary care
                            physician or health professionals due to any information provided by a HealthJoy or a Health
                            Provider and you agree to seek emergency help when needed and continue to consult your
                            primary care physician.
                        </li>
                        <li className='lst'>You understand that if your medical condition warrants emergency help,
                            HealthJoy&#8217;s Health Providers will direct you to the local hospital emergency room.
                        </li>
                        <li className='lst'>You understand that HealthJoy is not, nor is a substitute for, health
                            insurance, a primary care physician, or an in-person health care interaction and you agree
                            not to use HealthJoy as a substitute for health insurance, a primary care physician, or an
                            in-person health care interaction.
                        </li>
                        <li className='lst'>You understand HealthJoy and the Health Services provided by HealthJoy,
                            LLC&#8217;s concierge team are not doctors and do not practice medicine and do not
                            participate in or interfere with the practice of medicine by doctors on HealthJoy and that
                            each of Health Providers is responsible for his or her services and compliance with the
                            requirements applicable to his or her profession and license.
                        </li>
                        <li className='lst'>You agree to the entry of your medical records into HealthJoy&#8217;s
                            computer database and understand that all reasonable measures have been taken to safeguard
                            your medical information, in accordance with federal HIPAA standards, as amended to date,
                            but no computer or phone system is totally secure. HealthJoy recognizes your privacy and, in
                            accordance with our Privacy Policy Statement, will not release information to anyone without
                            your written authorization or as required by law.
                        </li>
                        <li className='lst'>You understand and agree that Virtual Consults involve the communication of
                            your medical information, both orally and visually, to physicians and other health care
                            practitioners located in other parts of the state/jurisdiction or outside of the
                            state/jurisdiction.
                        </li>
                        <li className='lst'>You also understand that HealthJoy a &#8220;covered entity&#8221; as defined
                            under the Health Insurance Portability and Accountability Act and associated regulations.
                            However, you understand that HealthJoy, LCC and the physician associated with HealthJoy,
                            LLC&#8217;s physician groups are &#8220;covered entities&#8221; that are subject to the
                            provisions of HIPAA pursuant to 45 CFR 103. Therefore, you understand and agree that your
                            personally identifiable health information (&#8220;Health Information&#8221;) provided to
                            HealthJoy, LLC and the physician associated with HealthJoy, LLC&#8217;s physician groups are
                            subject to or protected by HIPAA. We will maintain the privacy of your Health Information as
                            required by HIPAA.
                        </li>
                        <li className='lst'>You also understand and agree that by using HealthJoy that we may
                            automatically send your own personal health information and related reminders to you and you
                            opt into receiving this information by email, mobile notification, or using other contact
                            information provided by you in your account settings. You may opt out of receiving any such
                            communication via email or mobile notification at any time through your account settings. If
                            you prefer not to receive or do not consent to the receipt of personal health information by
                            email or mobile notification, you agree to update your account notification settings before
                            using HealthJoy or any features utilizing reminders.
                        </li>
                        <li className='lst'>You agree to and accept the <a href='http://www.vidyo.com/eula/'
                                                                           target='_blank' rel='noopener noreferrer'>Vidyo
                            End User License Agreement</a>.
                        </li>
                        <li className='lst'>You understand and agree that we are not responsible for the data bandwidth,
                            signal strength, or internet or data connectivity of your mobile device or computer.
                        </li>
                        <li className='lst'>You agree to refrain from abusive language or engaging in inappropriate
                            behavior when with Health Providers during a consult and agree that a Health Provider may
                            terminate a consult at any time should inappropriate behavior or language be used or if in
                            the physician&#8217;s sole judgment the consult is no longer appropriate or productive.
                        </li>
                        <li className='lst'>You agree to refrain from contacting or seeking to contact the Health
                            Provider for telemedicine care outside of the platform. This protects both patients and
                            physicians and to ensure clinical care is delivered in a reliable, continuous and controlled
                            platform. HealthJoy is not responsible for any interactions with Health Providers not
                            conducted on the HealthJoy platform.
                        </li>
                        <li className='lst'>You represent and warrant to us that, to the extent you
                            enter &#8220;protected health information&#8221; (as such term is defined under HIPAA) for a
                            third person, you are legally authorized to do so on behalf of such third person, and such
                            third person has validly consented to your inputting of, and your review
                            of, &#8220;protected health information&#8221; of such third person on HealthJoy and via the
                            HealthJoy website and mobile apps.
                        </li>
                        <li className='lst'>HealthJoy&#8217; LLC&#8217;s Health Providers are independent contractors of
                            separate professional corporations and are not employees of HealthJoy in any way, shape, or
                            form.
                        </li>
                        <li className='lst'>HealthJoy is for personal use in accordance with local, state, and federal
                            law only and may not be used for commercial purposes. Licensed U.S. doctors and other
                            qualified Health Providers may apply to participate on HealthJoy for educational purposes or
                            to provide patient consults via HealthJoy only.
                        </li>
                        <li className='lst'>You understand and agree that using HealthJoy reveals personally
                            identifiable information of members that will be visible to the doctors with whom you
                            interact.
                        </li>
                    </ul>
                </div>
                <div id='part_rights'>
                    <Text className='terms-body__title'>Rights</Text>
                    <p>You have the right to withhold or withdraw Your consent to Virtual Consults at any time without
                        affecting Your right to future care or treatment.</p>
                    <p>You have the right to inspect all medical information transmitted during a Virtual Consult,
                        including, without limitation, the name of the licensed physician associated with
                        HealthJoy&#8217;s physician groups treating You and You may receive copies of this information
                        for a reasonable fee in processing and sending You such information.</p>
                    <p>You understand that the laws that protect the confidentiality of medical information apply to
                        Virtual Consults, and that no information or images from such interaction which identify You
                        will be disclosed to other entities without Your consent.</p>
                </div>
            </div>
        </div>
    );
};

export {Legal as TestableLegal};
export default Legal;
