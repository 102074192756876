import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import PreviewLayout from '../../../Components/Benefits/PreviewLayout';

const PAGE_HEADER_CAPTION = 'Preview your employees’ benefits within the HealthJoy app, based on the information you’ve provided thus far.';

const Preview = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='Benefits Preview' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <PreviewLayout/>
    </React.Fragment>
);

export {Preview as TestablePreview};
export default React.memo(Preview);
