export const REPORT_FULL_NAME = 'MSK Therapy Monthly Report';
export const REPORT_NAME = 'MSK Therapy Monthly Report';

export const REPORT_NOTATION = `
    This report is for informational purposes only. HealthJoy has made every attempt to ensure the
    accuracy and reliability of the information provided herein. However, the information is provided
    “as is” without warranty of any kind. HealthJoy does not accept any responsibility or liability for
    the accuracy, content, completeness, legality, or reliability of the information contained in this report.
`;

export const CONTENTS_DESCRIPTION = `
    This monthly report is intended to provide an overview and progress of members’ musculoskeletal therapy program.
    We find that many of our clients use this information to determine what kind of HealthJoy communication effort they want to do next to maximize awareness and engagement.
    For example, you may decide to send a direct email to all of your eligible employees who have not yet activated their HealthJoy accounts reminding them to do so.
    Your HealthJoy Customer Success team is here to help you make the most of this program — don’t hesitate to reach out!
`;
