import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Table from '@frontend/ui-kit/Components/Table';
import Link from '@frontend/ui-kit/Components/Link';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import EmptyData from '../../../shared/EmptyData';
import {requestEligibilityImportSessionErrors} from '../../../../actions/adminPortal';
import {redirectTo} from '../../../../actions/general';
import {isEmpty} from '../../../../utils';
import {ROUTES} from '../../../../constants';

const FileErrors = ({isAutoImport, import_info: importInfo = {}}) => {
    const [{blockers = [], action_required: actionRequired = [], informational = []}, setFileErrors] = useState({});
    const {id: importId, group_alias: groupAlias} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const {data, isSuccess} = await dispatch(requestEligibilityImportSessionErrors(importId));

            if (isSuccess) {
                setFileErrors(data);
            }
        })();
    }, [importId]);

    const onRedirectToDetails = (columnName, errorType) => {
        const route = `${ROUTES.importsIndividual}/${groupAlias}/validation_report/${importId}/blocker_errors/${columnName}?group_name=${importInfo?.additional_data?.company_name}&error_type=${errorType}`;
        dispatch(redirectTo(route));
    };

    const getTableColumns = isDetails => {
        const getActions = ({cell, value}) => value && isDetails
            ? <Button data-testid='view-button' type={BUTTON_TYPES.tertiary} onClick={() => onRedirectToDetails(value, cell?.row?.original?.error_type)}>View</Button>
            : <Link data-testid='view-button' href={importInfo?.additional_data?.slack_logs_url} target='_blank'>View</Link>;

        return [
            {Header: 'Description', accessor: 'error_type'},
            {Header: 'Explanation', accessor: 'explanation'},
            {Header: '# of Members', accessor: 'ees_count', width: 100},
            {Header: 'Actions', accessor: 'column_name', width: 100, Cell: getActions}
        ];
    };

    const errorsTableProps = isDetails => ({
        isFilterable: false,
        isSortable: false,
        isCellTooltip: false,
        className: 'pb-10',
        columns: getTableColumns(isDetails)
    });

    const getErrorsComponent = (data, title, isDetails) => {
        return <Collapse isOpened className='mt-10' hasCollapseIcon initiator={<Heading type={HEADING_TYPES['4']}>{title} ({data.length})</Heading>} content={<Table {...errorsTableProps(isDetails)} data={data}/>}/>;
    };

    return (
        <div className='file-errors'>
            {isEmpty(blockers) && isEmpty(actionRequired) && isEmpty(informational) && <EmptyData title='Great job! There are no errors to review with this file.'/>}

            {!isEmpty(blockers) && (
                <React.Fragment>
                    <Alert type={ALERT_TYPES.danger} title='Blockers' description={isAutoImport ? 'Review the blockers below for members that were not processed and take action accordingly.' : 'The following errors need to be corrected in order to submit the file to database.'}/>

                    {getErrorsComponent(blockers, 'Blockers', true)}
                </React.Fragment>
            )}

            {!isEmpty(actionRequired) && (
                <React.Fragment>
                    <Alert className='mt-20' type={ALERT_TYPES.warning} title='Action required' description='These errors require corrections to be made but will not be a blocker to file submission and committing to the database.'/>

                    {getErrorsComponent(actionRequired, 'Action Required')}
                </React.Fragment>
            )}

            {!isEmpty(informational) && getErrorsComponent(informational, 'Informational')}
        </div>
    );
};

FileErrors.propTypes = {
    isAutoImport: PropTypes.bool,
    import_info: PropTypes.shape({
        additional_data: PropTypes.shape({
            slack_logs_url: PropTypes.string
        })
    })
};

export {FileErrors as TestableFileErrors};
export default FileErrors;
