import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import AncillaryPlansForm from '../../../Components/Benefits/AncillaryPlansForm';

const PAGE_HEADER_CAPTION = `Select all of the benefits you offer so that employees will have a complete benefits wallet from Day 1.
                  If there are different levels of a benefit for different benefits classes, please indicate the number.`;

const Ancillary = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='All Your Benefits' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <AncillaryPlansForm/>
    </React.Fragment>
);

export {Ancillary as TestableAncillary};
export default React.memo(Ancillary);
