import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import Table from '@frontend/ui-kit/Components/Table';
import Layout from '../Layout';
import withReportingInfo from '../../../HOC/withReportingInfo';
import {requestPerformanceReport} from '../../../actions/reporting';
import {formatDate, formatBytes, pipe, getItemKeyValue} from '../../../utils';
import './index.scss';

const DownloadReports = ({info: {files}}) => {
    const dispatch = useDispatch();

    const getTableColumns = () => {
        return [
            {
                Header: 'Action',
                accessor: 'url',
                width: 120,
                Cell: ({row, value}) => {
                    const file = files[row.index];

                    return (
                        <Button className='download-reports-table__button'
                            type={BUTTON_TYPES.tertiary}
                            fontWeight='bold'
                            onClick={() => dispatch(requestPerformanceReport(file))}>
                            DOWNLOAD
                        </Button>
                    );
                }
            },
            {
                Header: 'File Name',
                accessor: 'name',
                minWidth: 600
            },
            {
                Header: 'Date Published',
                accessor: 'date_published',
                minWidth: 170,
                Cell: ({row, value}) => {
                    const isCSV = row.values.name.endsWith('.csv');

                    return isCSV ? 'All Time' : formatDate(value, 'MMMM yyyy');
                }
            },
            {
                Header: 'File Size',
                accessor: 'size',
                minWidth: 170,
                Cell: pipe(getItemKeyValue('value'), formatBytes)
            }
        ];
    };

    const tableProps = {
        className: 'download-reports-table',
        data: files,
        isSortable: false,
        isFilterable: false,
        columns: getTableColumns()
    };

    return (
        <Layout className='download-reports'>
            <Heading data-testid='download-reports-title' className='section-heading' type={HEADING_TYPES['2']}>Download Reports</Heading>
            <Text className='reporting-info'>
                Download your charts and metrics as a PDF to share with others. Reports appear on the first day of
                each month. If you've already had a plan renewal with HealthJoy, use the dropdown to the right to
                select your report's time frame. The CSV file with your raw data is updated daily.
            </Text>

            <ContentSection>
                <Table {...tableProps}/>
            </ContentSection>
        </Layout>
    );
};

DownloadReports.propTypes = {
    info: PropTypes.shape({
        files: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            last_modified: PropTypes.number,
            size: PropTypes.number,
            url: PropTypes.string
        }))
    })
};

export {DownloadReports as TestableDownloadReports};
export default withReportingInfo({isDisclaimerShowed: false})(DownloadReports);
