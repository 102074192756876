export const REPORT_FULL_NAME = 'Program Performance Report';
export const REPORT_NAME = 'Performance Report';
export const REPORT_NOTATION = `
    This report is for informational purposes only. HealthJoy has made every attempt to ensure the
    accuracy and reliability of the information provided herein. However, the information is provided
    “as is” without warranty of any kind. HealthJoy does not accept any responsibility or liability for
    the accuracy, content, completeness, legality, or reliability of the information contained in this report.
`;

export const CONTENTS_DESCRIPTION = `
    This program performance report is intended to provide an overview of how widely your eligible
    employee population has adapted the service based on the date range you selected. We hope you and
    your employees are finding great value in HealthJoy as we work to simplify the experience of
    navigating health care decisions and benefits questions. We find that many of our clients use this
    performance information to determine what kind of HealthJoy communication effort they want to do
    next to maximize awareness and engagement. For example, you may decide to send a direct email to all
    of your eligible employees who have not yet activated their HealthJoy accounts reminding them to do
    so. Your HealthJoy Customer Success team is here to help you make the most of this program ­– don’t
    hesitate to reach out!
`;

export const CONTENTS = [
    {chapter: '1.0', title: 'Performance Summary', page: 3},
    {chapter: '2.0', title: 'Program Savings', page: 4},
    {chapter: '2.1', title: 'Program Savings Formula', page: 6},
    {chapter: '3.0', title: 'Member Utilization', page: 12},
    {chapter: '3.1', title: 'Service Utilization', page: 14},
    {chapter: '4.0', title: 'Glossary', page: 18}
];
