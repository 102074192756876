import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import './index.scss';

const TPAGroup = ({text, onView, onToggleGroup, name, value}) => {
    return (
        <ContentSection className={classnames('tpa-group', {'tpa-group_active': value})}>
            <div className='tpa-group__content'>
                {onToggleGroup && (
                    <div className='tpa-group__checkbox'>
                        <Checkbox name={name} onChange={onToggleGroup} value={value}/>
                    </div>
                )}
                <Text className='tpa-group__content-title' type={TEXT_TYPES.bodyBold}>{text}</Text>
            </div>

            <Button className='tpa-group__button ml-5' data-testid='view-tpa-group' onClick={onView} type={BUTTON_TYPES.tertiary}>
                View
                <Icon type={ICON_TYPES.arrowRight} className='ml-2'/>
            </Button>
        </ContentSection>
    );
};

TPAGroup.propTypes = {
    text: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.bool,
    onView: PropTypes.func.isRequired,
    onToggleGroup: PropTypes.func
};

export {TPAGroup as TestableTPAGroup};
export default React.memo(TPAGroup);
