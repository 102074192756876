import React, {useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import CustomizablePopup from '../../Components/shared/CustomizablePopup';
import CardAvailabilityCondition from '../../Components/shared/CardAvailabilityCondition';
import withPopup from '../withPopup';
import useForm from '../../hooks/useForm';
import {compose, getUpdatedValues} from '../../utils';

const POPUP_ID = 'variableLinkingPopup';

const withVariableLinking = Component => {
    const WithVariableLinking = props => {
        const {openPopup, closePopup} = props;
        const form = useForm();
        const valuesRef = useRef({});

        const onLink = useCallback(prefixName => {
            const onCancel = () => {
                const formDiff = getUpdatedValues(form.getState().values, valuesRef.current);

                form.batch(() => {
                    Object.keys(formDiff).forEach(fieldName => form.change(fieldName, valuesRef.current[fieldName] || null));
                });

                closePopup();
            };
            const actionBar = (
                <React.Fragment>
                    <Button data-testid='cancel-button' type={BUTTON_TYPES.secondary} onClick={onCancel}>Cancel</Button>
                    <Button data-testid='save-button' type={BUTTON_TYPES.primary} onClick={closePopup}>Save</Button>
                </React.Fragment>
            );
            const content = <CardAvailabilityCondition prefixName={`${prefixName}.variables`} isFieldLevelConditions/>;
            const popupProps = {title: 'Content Settings', content, actionBar};
            const children = <CustomizablePopup {...popupProps}/>;

            valuesRef.current = form.getState().values;

            return openPopup({type: POPUP_TYPES.simple, children});
        }, [form, openPopup, closePopup]);

        return <Component {...props} onLinkToVariable={onLink}/>;
    };

    WithVariableLinking.propTypes = {
        openPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired
    };

    return React.memo(WithVariableLinking);
};

export {withVariableLinking as testableWithVariableLinking};
export default compose(
    withPopup(POPUP_ID),
    withVariableLinking
);
