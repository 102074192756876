import React from 'react';
import PropTypes from 'prop-types';
import Separator from '@frontend/ui-kit/Components/Separator';
import './index.scss';

const DynamicActionBar = props => {
    const {children} = props;

    return (
        <div className='dynamic-action-bar'>
            <Separator type='solid'/>

            <div className='action-bar-buttons'>{children}</div>
        </div>
    );
};

DynamicActionBar.propTypes = {
    children: PropTypes.node.isRequired
};

export default DynamicActionBar;
