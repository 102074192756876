import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Input from '@frontend/ui-kit/Components/Input';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {Field} from '../FormComponents';
import './index.scss';

const PasswordInput = ({name, ...restProps}) => {
    const [isPasswordPrivate, setIsPasswordPrivate] = useState(true);
    const onTogglePrivacy = () => setIsPasswordPrivate(isPasswordPrivate => !isPasswordPrivate);

    const type = isPasswordPrivate ? 'password' : 'text';
    const visibilityIcon = isPasswordPrivate ? ICON_TYPES.show : ICON_TYPES.hide;

    return (
        <div className='password-input'>
            <Field name={name}>
                {props => {
                    const icon = props.value ? visibilityIcon : ICON_TYPES.lock;
                    const inputProps = {...props, ...restProps, type, icon, iconClassName: 'password-input__icon', onIconClick: onTogglePrivacy};

                    return <Input {...inputProps}/>;
                }}
            </Field>
        </div>
    );
};

PasswordInput.propTypes = {
    name: PropTypes.string.isRequired
};

export {PasswordInput as TestablePasswordInput};
export default PasswordInput;
