import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import {getActiveCompanyPlanPeriods} from '../../../selectors/general';
import {getEqual, formatDate} from '../../../utils';
import {PLAN_PERIOD_TYPES} from '../../../constants';

const ALERT_DESCRIPTION = `
    The eligibility information listed here has been copied over from your previous plan year.
    Editing, adding, or deleting information will only impact your upcoming plan year.
`;
const RenewalEligibilityInfoAlert = ({className}) => {
    const planPeriods = useSelector(getActiveCompanyPlanPeriods);
    const {start_date: periodStartDate} = planPeriods.find(getEqual(PLAN_PERIOD_TYPES.renewal, 'status'));
    const formattedPeriodStartDate = formatDate(periodStartDate, 'yyyy');
    const title = `${formattedPeriodStartDate} RENEWAL ELIGIBILITY INFORMATION`;

    return <Alert className={className} type={ALERT_TYPES.info} title={title} description={ALERT_DESCRIPTION}/>;
};

RenewalEligibilityInfoAlert.propTypes = {
    className: PropTypes.string
};

export {RenewalEligibilityInfoAlert as TestableRenewalEligibilityInfoAlert};
export default React.memo(RenewalEligibilityInfoAlert);
