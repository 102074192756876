import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useDispatch} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import {redirectTo} from '../../../actions/general';
import {ROUTES} from '../../../constants';
import {setRecentlyViewedClients} from '../../../helpers';
import './index.scss';

const Client = ({client}) => {
    const {name, title, alias, id} = client;
    const clientName = name || title;
    const isTPA = !alias;
    const dispatch = useDispatch();

    const onClick = () => {
        const route = isTPA ? `${ROUTES.importsTpa}/${id}?partner_name=${encodeURIComponent(clientName)}` : `${ROUTES.importsIndividual}/${alias}?group_name=${encodeURIComponent(clientName)}`;

        dispatch(redirectTo(route));
        setRecentlyViewedClients([client]);
    };

    return (
        <ContentSection className={classnames('client', {client_tpa: isTPA})} onClick={onClick}>
            <Heading className='client__character' type={HEADING_TYPES['1']}>{clientName?.charAt(0).toUpperCase()}</Heading>

            {isTPA && <Sticker className='client__tpa' type={STICKER_TYPES.default}>TPA</Sticker>}

            <div className='mt-8 client__alias-wrapper'>
                {alias && <Heading className='client__alias' type={HEADING_TYPES['5']}>{alias}</Heading>}
            </div>

            <div className='mt-3 client__name-wrapper'>
                <Tooltip content={name} className='client__name-tooltip'>
                    <Heading className='client__name' type={HEADING_TYPES['3']} data-testid='client-name'>{clientName}</Heading>
                </Tooltip>
                <Icon className='client__icon' type={ICON_TYPES.chevronRight}/>
            </div>
        </ContentSection>
    );
};

Client.propTypes = {
    client: PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string,
        id: PropTypes.number.isRequired,
        alias: PropTypes.string
    })
};

export {Client as TestableClient};
export default React.memo(Client);
