import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {requestBroadcasterOnboardedSetting, setBroadcasterOnboarded} from '../../actions/general';
import {getIsBroadcasterOnboarded} from '../../selectors/general';
import {compose} from '../../utils';

const withBroadcastOnboarding = Component => {
    const WithBroadcastOnboarding = props => <Component {...props}/>;

    WithBroadcastOnboarding.propTypes = {
        isBroadcasterOnboarded: PropTypes.bool,
        requestBroadcasterOnboardedSetting: PropTypes.func.isRequired,
        setBroadcasterOnboarded: PropTypes.func.isRequired
    };

    return WithBroadcastOnboarding;
};

const mapStateToProps = state => {
    return {
        isBroadcasterOnboarded: getIsBroadcasterOnboarded(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        requestBroadcasterOnboardedSetting: () => dispatch(requestBroadcasterOnboardedSetting()),
        setBroadcasterOnboarded: () => dispatch(setBroadcasterOnboarded())
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withBroadcastOnboarding
);
