import AccidentForm from './AccidentForm';
import HsaForm from './HsaForm';
import HraForm from './HraForm';
import FsaForm from './FsaForm';
import CustomForm from './CustomForm';
import BasicLifeForm from './BasicLifeForm';
import VoluntaryLifeForm from './VoluntaryLifeForm';
import StdForm from './StdForm';
import LtdForm from './LtdForm';
import BenefitGuide from './BenefitGuide';
import CriticalIllnessForm from './CriticalIllnessForm';
import CancerForm from './CancerForm';
import HospitalIndemnityForm from './HospitalIndemnityForm';
import K401Form from './K401Form';
import UniversalLifeForm from './UniversalLifeForm';
import WholeLifeForm from './WholeLifeForm';
import EapForm from './EapForm';
import MedicalTravelForm from './MedicalTravelForm';
import RxProgramForm from './RxProgramForm';
import WellnessForm from './WellnessForm';
import './index.scss';

// FYI: big part of this forms looks the same - it can be some HOC (13/09/19 by: Stas)
export default {
    AccidentForm,
    HsaForm,
    HraForm,
    FsaForm,
    BasicLifeForm,
    VoluntaryLifeForm,
    StdForm,
    LtdForm,
    BenefitGuide,
    CriticalIllnessForm,
    CancerForm,
    HospitalIndemnityForm,
    K401Form,
    UniversalLifeForm,
    WholeLifeForm,
    EapForm,
    MedicalTravelForm,
    RxProgramForm,
    WellnessForm,
    CustomForm
};
