import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import FileExport from '../../../Components/Eligibility/FileExport';

const PAGE_HEADER_CAPTION = 'Below is a list of eligibility files you can download.';

const Export = () => (
    <React.Fragment>
        <Row>
            <Column sm={9}>
                <PageHeader title='Export Eligibility File' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <FileExport/>
    </React.Fragment>
);

export {Export as TestableExport};
export default React.memo(Export);
