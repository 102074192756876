import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import InternalMenu from '../../../Containers/shared/InternalMenu';
import Forms from '../CorePlansDetailsForms';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {requestCorePlans, clearCorePlans} from '../../../actions/benefits';
import {setInternalMenuItems} from '../../../actions/internalMenu';
import {getProfilePermissions} from '../../../selectors/general';
import {getCorePlans} from '../../../selectors/benefits';
import {getInternalMenuActiveItem, getInternalMenuItems} from '../../../selectors/internalMenu';
import {equal, getEqual, isEmpty, pass} from '../../../utils';
import {CORE_PLAN_TYPES, DEFAULT_INTERNAL_MENU_INDEX} from '../../../constants';

const FORM_BY_TYPE = {
    [CORE_PLAN_TYPES.medical]: Forms.MedicalPlanForm,
    [CORE_PLAN_TYPES.dental]: Forms.DentalPlanForm,
    [CORE_PLAN_TYPES.vision]: Forms.VisionPlanForm,
    [CORE_PLAN_TYPES.additional]: Forms.AdditionalPlanInfo
};

const CorePlansDetailsLayout = ({onComplete}) => {
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState(null);
    const [isCustomizedMode, setIsCustomizedMode] = useState(null);
    const {planPeriodId} = useParams();
    const profilePermissions = useSelector(getProfilePermissions);
    const corePlans = useSelector(getCorePlans);
    const internalMenuActiveItem = useSelector(getInternalMenuActiveItem);
    const internalMenuItems = useSelector(getInternalMenuItems);

    const corePlan = internalMenuItems ? corePlans.find(getEqual(activeItem, 'id')) : corePlans[DEFAULT_INTERNAL_MENU_INDEX];
    const {id: planId, plan_kind: planType, name: planName} = corePlan || {};
    const Form = FORM_BY_TYPE[planType];
    const formProps = {
        planId,
        planType,
        isReadonly: !profilePermissions.core_plan_edit,
        isCustomizedMode,
        isCustomizable: true,
        title: `${planType}: ${planName}`,
        onSuccess: profilePermissions.timeline_edit ? onComplete : pass,
        setCustomizedMode: setIsCustomizedMode
    };

    useEffect(() => {
        dispatch(requestCorePlans(planPeriodId));

        return () => dispatch(clearCorePlans());
    }, [dispatch, planPeriodId]);

    useEffect(() => {
        const internalMenuItems = corePlans.map(corePlan => {
            const {plan_kind: type, name, completed, id, active} = corePlan;

            return {label: `${type}: ${name}`, completed, id, active};
        });

        dispatch(setInternalMenuItems(internalMenuItems));
    }, [dispatch, corePlans]);

    useEffect(() => {
        const {customized: isCustomizedMode} = corePlans.find(getEqual(internalMenuActiveItem, 'id')) || {};

        if (!isEmpty(corePlans) && !equal(activeItem, internalMenuActiveItem)) {
            setIsCustomizedMode(isCustomizedMode);
            setActiveItem(internalMenuActiveItem);
        }
    }, [corePlans, activeItem, internalMenuActiveItem]);

    return !!corePlans.length && (
        <div className='plans-details-layout'>
            <Row className='plans-details-layout-row'>
                <Column className='plans-details-layout-row__column' sm={4}>
                    {!isEmpty(internalMenuItems) && <InternalMenu/>}
                </Column>

                <Column className='plans-details-layout-row__column' sm={8}>
                    <ContentSection className='plan-form-wrapper'>
                        {corePlan && <Form {...formProps}/>}
                    </ContentSection>
                </Column>
            </Row>
        </div>
    );
};

CorePlansDetailsLayout.propTypes = {
    onComplete: PropTypes.func
};

export {CorePlansDetailsLayout as TestableCorePlansDetailsLayout};
export default withSubStepCompletion(CorePlansDetailsLayout);
