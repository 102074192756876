import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Badge from '@frontend/ui-kit/Components/Badge';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import OverviewStepHeader from '../OverviewStepHeader';
import {Form, Field, FieldArray} from '../../shared/FormComponents';
import AssignableActionBar from '../../shared/AssignableActionBar';
import {requestCorePlansInfo, requestCorePlansInfoUpdating} from '../../../actions/eligibility';
import {equal, isEmpty, normalizeBoolean, validateRequired} from '../../../utils';
import {FORMS, CORE_PLAN_TYPES, SSN} from '../../../constants';
import {IDENTIFICATION_OPTIONS, SYSTEM_NAME_OPTIONS, CONDITION_OPTIONS} from '../../../options';
import './index.scss';

/* istanbul ignore next */
const validatePlans = ({source, plan_kind: planKind}) => {
    const isMedicalPlan = equal(planKind, CORE_PLAN_TYPES.medical);
    const {has_unique_member_ids: isUniqueId} = source;

    return {
        source: !isMedicalPlan ? {
            system_name: isUniqueId ? validateRequired(source.system_name) : undefined,
            identify_by: !isUniqueId ? validateRequired(source.identify_by) : undefined
        } : {system_name: validateRequired(source.system_name)}
    };
};
/* istanbul ignore next */
const validate = values => {
    const {plans = {}} = values;
    const {medical = [], dental = [], vision = []} = plans;

    return {
        plans: {
            medical: medical.map(validatePlans),
            dental: dental.map(validatePlans),
            vision: vision.map(validatePlans)
        }
    };
};

const CoreStep = ({isReadonly, ...props}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const {planPeriodId} = useParams();

    useEffect(() => {
        (async () => {
            const {corePlansInfo, isSuccess} = await dispatch(requestCorePlansInfo(planPeriodId));

            if (isSuccess) {
                const getNormalizedPlan = ({source, ...plan}) => ({...plan, source: {...source, has_unique_member_ids: !!source.has_unique_member_ids}});
                const plans = {
                    ...corePlansInfo.plans,
                    dental: corePlansInfo.plans?.dental?.map(getNormalizedPlan),
                    vision: corePlansInfo.plans?.vision?.map(getNormalizedPlan)
                };

                setInitialValues({...corePlansInfo, plans});
            }
        })();
    }, [dispatch, planPeriodId]);

    const resetFields = useCallback((form, fields) => () => {
        return form.batch(() => fields.forEach(field => form.change(field, null)));
    }, []);

    const getPlans = form => ({fields}) => {
        const [type] = fields.name.split('.').reverse();
        const arePlans = !isEmpty(fields.value);

        return (
            <React.Fragment>
                <Column sm={12} className={`plans-header plans-header_${type}`}>
                    {type} Plans {arePlans && <Badge className='plans-header__counter'>{fields.value.length}</Badge>}
                </Column>

                {arePlans && fields.map((field, index) => {
                    const {plan_kind: planKind, carrier_name: carrierName, name, type, source} = fields.value[index];
                    const {identify_by: identifyBy, has_unique_member_ids: isUniqueId} = source;
                    const isMedicalPlan = equal(planKind, CORE_PLAN_TYPES.medical);
                    const isLast = equal(index, fields.length - 1);
                    const resetIdSourcesFields = resetFields(form, [`${field}.source.system_name`, `${field}.source.identify_by`]);
                    const resetIdentifyByField = resetFields(form, [`${field}.source.identify_by`]);

                    return (
                        <React.Fragment key={field}>
                            <Column sm={12} className='plan'>
                                <Field name={`${field}.id`}>{props => <Input {...props} type='hidden'/>}</Field>
                                <Field name={`${field}.source.id`}>{props => <Input {...props} type='hidden'/>}</Field>

                                <Row className='plan-header'>
                                    <Column sm={4}><span className='plan-header__index-number'>{index + 1}</span>{carrierName}</Column>
                                    <Column sm={4}>{name}</Column>
                                    <Column sm={4}>{type}</Column>
                                </Row>

                                <div className='plan-body'>
                                    {isMedicalPlan && (
                                        <Field name={`${field}.source.system_name`}>
                                            {props => <Select {...props} readonly={isReadonly} options={SYSTEM_NAME_OPTIONS} className='plan-body__label' label={`Where will the ${planKind} ID's be coming from?`} isRequired data-testid={`source-system_name-${index}`}/>}
                                        </Field>
                                    )}

                                    {!isMedicalPlan && (
                                        <Field name={`${field}.source.has_unique_member_ids`} onChange={resetIdSourcesFields} parse={normalizeBoolean}>
                                            {props => <RadioGroup {...props} readonly={isReadonly} options={CONDITION_OPTIONS} className='plan-body__label' label='Does this plan assign unique member ID’s?' data-testid={`source-has_unique_member_ids-${index}`} wrapperClassName='mb-4'/>}
                                        </Field>
                                    )}
                                    {!isMedicalPlan && isUniqueId && (
                                        <Field name={`${field}.source.system_name`} onChange={resetIdentifyByField}>
                                            {props => <Select {...props} readonly={isReadonly} options={SYSTEM_NAME_OPTIONS} className='plan-body__label' label={`Where will the ${planKind} ID's be coming from?`} isRequired data-testid={`source-system_name-${index}`}/>}
                                        </Field>
                                    )}
                                    {!isMedicalPlan && !isUniqueId && (
                                        <React.Fragment>
                                            <Field name={`${field}.source.identify_by`}>
                                                {props => <Select {...props} readonly={isReadonly} options={IDENTIFICATION_OPTIONS} className='plan-body__label' label='What is Used for Member Identification?' isRequired data-testid={`source-identify_by-${index}`}/>}
                                            </Field>

                                            {equal(identifyBy, SSN) && (
                                                <React.Fragment>
                                                    In order to ensure that Social Security Numbers are not appearing in the HealthJoy app, we will default the Member ID text to 'Use your SSN'.
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </Column>

                            {!isLast && <Separator/>}
                        </React.Fragment>
                    );
                })}

                {!arePlans && <Column sm><Text className='no-plan-text'>No {type} Plans selected</Text></Column>}
            </React.Fragment>
        );
    };

    const onSubmit = useCallback(async values => {
        const {corePlansInfo, isSuccess, submissionErrors} = await dispatch(requestCorePlansInfoUpdating(values));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(corePlansInfo);
    }, [dispatch]);

    return (
        <React.Fragment>
            <OverviewStepHeader/>

            <Form name={FORMS.eligibilityCorePlansInfo} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit, form}) => (
                    <React.Fragment>
                        <form className='eligibility-wizard-step eligibility-wizard-step_core'>
                            <Field name='plan_period_id'>{props => <Input {...props} type='hidden'/>}</Field>

                            <Row>
                                <Column sm={4} className='header__title'>
                                    <Text type={TEXT_TYPES.bodyBold}>Carrier</Text>
                                </Column>

                                <Column sm={4} className='header__title'>
                                    <Text type={TEXT_TYPES.bodyBold}>Plan Name</Text>
                                </Column>

                                <Column sm={4} className='header__title'>
                                    <Text type={TEXT_TYPES.bodyBold}>Plan Type</Text>
                                </Column>
                            </Row>

                            <div className='plans'>
                                <Row className='plans-list'>
                                    <FieldArray name={`plans.${CORE_PLAN_TYPES.medical}`}>{getPlans(form)}</FieldArray>
                                </Row>

                                <Row className='plans-list'>
                                    <FieldArray name={`plans.${CORE_PLAN_TYPES.dental}`}>{getPlans(form)}</FieldArray>
                                </Row>

                                <Row className='plans-list'>
                                    <FieldArray name={`plans.${CORE_PLAN_TYPES.vision}`}>{getPlans(form)}</FieldArray>
                                </Row>
                            </div>
                        </form>

                        <AssignableActionBar {...props} isReadonly={isReadonly} onSubmitStep={handleSubmit}/>
                    </React.Fragment>
                )}
            </Form>
        </React.Fragment>
    );
};

CoreStep.propTypes = {
    isReadonly: PropTypes.bool.isRequired
};

export {CoreStep as TestableCoreStep};
export default CoreStep;
