import React from 'react';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {FILE_FORMATS, MAX_TPA_FILE_SIZE} from '../../../../constants';
import {isEmpty, pass} from '../../../../utils';
import FileUploader from '../../../shared/FileUploader';

const ReUploadFilePopup = ({file, onSave, onReturn, onReUploadFile}) => {
    const actionBar = (
        <React.Fragment>
            <Button type={BUTTON_TYPES.secondary} onClick={onReturn}>Return to TPA Config</Button>
            <Button type={BUTTON_TYPES.primary} onClick={onSave} disabled={isEmpty(file)}>Submit and Upload File</Button>
        </React.Fragment>
    );

    const acceptFormats = [FILE_FORMATS.csv, FILE_FORMATS.txt, FILE_FORMATS.xls, FILE_FORMATS.xlsx].map(format => `.${format}`);
    const popupContent = (
        <FileUploader value={file}
            onChange={nextFile => onReUploadFile(nextFile)}
            onBlur={pass}
            maxSize={MAX_TPA_FILE_SIZE}
            accept={acceptFormats}
            label='Upload File'
            isPrivateFile
            isRequired/>
    );

    const popupProps = {title: 'Re-upload File', actionBar, children: popupContent};

    return <PopupContent {...popupProps}/>;
};

ReUploadFilePopup.propTypes = {
    file: PropTypes.arrayOf(PropTypes.string),
    onSave: PropTypes.func,
    onReturn: PropTypes.func,
    onReUploadFile: PropTypes.func
};

export {ReUploadFilePopup as TestableReUploadFilePopup};
export default ReUploadFilePopup;
