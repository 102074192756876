import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {signOut} from '../../../actions/authorization';

const SignOut = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(signOut());
    }, [dispatch]);

    return null;
};

export {SignOut as TestableSignOut};
export default React.memo(SignOut);
