import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Row from '@frontend/ui-kit/Components/Row';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ImportGroupConfigContent from '../ImportGroupConfigContent';
import {Form} from '../../shared/FormComponents';
import {FORMS, IMPORT_CONFIG_TYPES} from '../../../constants';
import {
    requestIndividualConfig,
    requestIndividualConfigUpdating,
    requestTPAPartnerConfig,
    requestTPAPartnerConfigCreating,
    requestTPAPartnerConfigUpdating
} from '../../../actions/adminPortal';
import {getProfileInfo} from '../../../selectors/general';
import {
    getStringifiedImportConfigData,
    getParsedImportConfigData,
    getIndividualConfig,
    getLatestRegularConfig,
    getImportConfigJSONFields
} from '../../../helpers';
import {validateRequired, validateJSONObject, validateJSON, equal, getEqual, omit} from '../../../utils';
import './index.scss';

const JSON_OBJECT_FIELDS = ['ignore_members', 'ignore_fields', 'cobra_condition'];

const BASE_REQUIRED_FIELDS = ['first_name', 'last_name', 'id_fields', 'birthday', 'gender', 'email_fields', 'street_address', 'street_address_2', 'city', 'state', 'zip', 'relationship_field'];

/* istanbul ignore next */
const getRequiredFields = configType => equal(configType, IMPORT_CONFIG_TYPES.tpa)
    ? BASE_REQUIRED_FIELDS
    : [...BASE_REQUIRED_FIELDS, 'manual_file_pattern', 'config_activation_date'];

/* istanbul ignore next */
const getValidate = configType => values => {
    const requiredFields = getRequiredFields(configType);
    const jsonFields = getImportConfigJSONFields(configType);

    const requiredFieldsValidation = requiredFields.reduce((acc, field) => ({
        ...acc,
        [field]: validateRequired(values.import_config?.[field])
    }), {});

    const jsonFieldsValidation = jsonFields.reduce((acc, field) => {
        const validateFunc = JSON_OBJECT_FIELDS.some(getEqual(field)) ? validateJSONObject : validateJSON;

        return ({
            ...acc,
            [field]: validateFunc(values.import_config?.[field])
        });
    }, {});

    return {
        import_config: {
            ...requiredFieldsValidation,
            ...jsonFieldsValidation
        }
    };
};

const ImportGroupConfig = ({configType = IMPORT_CONFIG_TYPES.renewal, onChangeActiveStep}) => {
    const dispatch = useDispatch();
    const {email: userEmail} = useSelector(getProfileInfo);
    const {partner, group_alias: groupAlias} = useParams();
    const jsonFields = getImportConfigJSONFields(configType);
    const [isConfigAlreadyCreated, setIsConfigAlreadyCreated] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        (async () => {
            let configData;
            let isRequestSuccess;

            if (equal(configType, IMPORT_CONFIG_TYPES.tpa)) {
                const {data, isSuccess} = await dispatch(requestTPAPartnerConfig({tpaPartnerId: partner}));
                configData = data?.[0] || {import_config: {}};
                isRequestSuccess = isSuccess;

                setIsConfigAlreadyCreated(!!data?.[0]);
            } else {
                const {data, isSuccess} = await dispatch(requestIndividualConfig({companyAlias: groupAlias}));
                const isRenewal = equal(configType, IMPORT_CONFIG_TYPES.renewal);
                const individualConfig = getIndividualConfig(isRenewal, data);
                const initialValues = individualConfig || omit(getLatestRegularConfig(data), ['config_activation_date']);
                configData = {import_config: initialValues};
                isRequestSuccess = isSuccess;
            }

            if (isRequestSuccess) {
                setInitialValues(getStringifiedImportConfigData(jsonFields, configData));
            }
        })();
    }, []);

    const removeNullValues = (values, dirtyFieldsList = []) => Object.entries(values).reduce((acc, [key, value]) => {
        const isDirtyField = dirtyFieldsList.includes(key);
        return equal(value, null) && !isDirtyField ? acc : {...acc, [key]: value};
    }, {});

    const onSubmit = async ({is_submit, ...values}, form) => {
        const {dirtyFields} = form.getState();
        const dirtyFieldsList = Object.keys(dirtyFields).map(field => field.split('.').pop());

        if (equal(configType, IMPORT_CONFIG_TYPES.tpa)) {
            const parsedValues = getParsedImportConfigData(jsonFields, values);
            const enhancedValues = {
                ...parsedValues,
                tpaPartnerId: partner,
                import_config: removeNullValues(parsedValues.import_config, dirtyFieldsList)
            };

            const requestFunction = isConfigAlreadyCreated ? requestTPAPartnerConfigUpdating : requestTPAPartnerConfigCreating;
            const {data, isSuccess, submissionErrors} = await dispatch(requestFunction(enhancedValues));

            if (!isSuccess) {
                return submissionErrors;
            }

            setIsConfigAlreadyCreated(true);
            setInitialValues(getStringifiedImportConfigData(jsonFields, data));
        } else {
            const parsedValues = getParsedImportConfigData(jsonFields, values).import_config;
            const enhancedValues = {
                ...removeNullValues(parsedValues, dirtyFieldsList),
                updated_by: userEmail
            };

            const {data, isSuccess, submissionErrors} = await dispatch(requestIndividualConfigUpdating(enhancedValues));

            if (!isSuccess) {
                return {import_config: submissionErrors};
            }

            setInitialValues(getStringifiedImportConfigData(jsonFields, {import_config: data}));
        }
    };

    const onSubmitSuccess = ({values: {is_submit: isSubmit}}) => {
        if (isSubmit) {
            onChangeActiveStep('upload_file');
        }
    };

    return (
        <Form className='import-config' name={FORMS.importGroupConfig} initialValues={initialValues} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} validate={getValidate(configType)}>
            {({form, handleSubmit}) => {
                const onContinue = () => {
                    form.change('is_submit', true);
                    handleSubmit();
                };

                const onSaveProgress = () => {
                    form.change('is_submit', false);
                    handleSubmit();
                };

                return (
                    <form onSubmit={handleSubmit} className='import-config__form' noValidate>
                        <ImportGroupConfigContent configType={configType}/>

                        <Row columnGap='xs' end='xs' className='mt-20'>
                            <Column>
                                <Button data-testid='save-config-button' type={BUTTON_TYPES.secondary} onClick={onSaveProgress}>Save Progress</Button>
                            </Column>
                            <Column>
                                <Button data-testid='save-continue-config-button' onClick={onContinue}>Save & Continue</Button>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

ImportGroupConfig.propTypes = {
    configType: PropTypes.string,
    onChangeActiveStep: PropTypes.func
};

ImportGroupConfig.defaultProps = {
    onChangeActiveStep: () => {}
};

export {ImportGroupConfig as TestableImportGroupConfig};
export default ImportGroupConfig;
