export const getAuthorizationStatus = state => state.authorization.isAuthorized;

export const getAccessToken = state => state.authorization.accessToken;

export const getRefreshToken = state => state.authorization.refreshToken;

export const getTwoStepToken = state => state.authorization.twoStepToken;

export const getTwoStepCodeChannel = state => state.authorization.twoStepCodeChannel;

export const getAppId = state => state.authorization.appId;

export const getAppSecret = state => state.authorization.appSecret;

export const getIsTwoStepRequired = state => state.authorization.isTwoStepRequired;
