import React from 'react';
import Text from '@frontend/ui-kit/Components/Text';
import hjLogo from '../../../static/images/hj-logo-white.svg';
import './index.scss';

const Privacy = () => {
    return (
        <div className='privacy terms'>
            <div className='terms-header'>
                <img className='terms-header__logo' src={hjLogo} alt=''/>

                <Text className='terms-header__title'>Privacy Policy</Text>
            </div>

            <div className='terms-body'>
                <Text>
                    HealthJoy understands how important the privacy of personal information is to our users. We
                    understand that your privacy matters and we respect your privacy choices. This notice of our Privacy
                    Policy describes how your medical information is used and disclosed within HealthJoy. This notice of
                    our Privacy Policy describes how your medical information is used and disclosed within HealthJoy,
                    which offers technology that provides individuals with information and tools related to their health
                    and health insurance as well as interactions with healthcare professionals and support staff. Your
                    use of HealthJoy services indicates your acceptance of HealthJoy&#8217;s Privacy Policy. We urge you
                    to take the time to read our entire Privacy Policy for complete detail about our privacy practices
                    and your information. Below is a summary of our privacy policy, which is intended to give you an
                    overview of:
                </Text>

                <Text className='terms-body__title'>Summary:</Text>
                <ul className='terms-body-list left-side'>
                    <li>
                        <a className='terms-body-list__link' href="#info-collect">What information we collect about you</a>
                    </li>
                    <li>
                        <a className='terms-body-list__link' href="#choice">What choices you have about your information</a>
                    </li>
                    <li>
                        <a className='terms-body-list__link' href="#info-used">How we use your information</a>
                    </li>
                    <li>
                        <a className='terms-body-list__link' href="#who-see">How and with whom we share your information</a>
                    </li>
                    <li>
                        <a className='terms-body-list__link' href="#how-secure">How we protect your information</a>
                    </li>
                    <li>
                        <a className='terms-body-list__link' href="#contact-us">How to contact us with questions or concerns</a>
                    </li>
                </ul>
                <div id="info-collect">
                    <Text className='terms-body__title'>Information Collected About You</Text>
                    <p>In order to use HealthJoy, we may collect &#8220;Personally Identifying Information&#8221; about
                        you &#8211; such as your name, address, telephone number, email address as well
                        as &#8220;Protected Health Information&#8221; (PHI) such as:</p>
                    <ul className='terms-body-list left-side'>
                        <li>Your past, present, or future physical or mental health or condition,</li>
                        <li>The provision of health care, or</li>
                        <li>The past, present, or future payment for the provision of health care, and that identifies
                            the individual or for which there is a reasonable basis to believe can be used to identify
                            the individual. Protected health information includes many common identifiers (e.g., name,
                            address, birth date, Social Security Number) when they can be associated with the health
                            information listed above.
                        </li>
                    </ul>
                    <p>We will be using the aforementioned &#8220;Personal Information&#8221; in the following ways:</p>
                    <ul className='terms-body-list left-side'>
                        <li>When you register for or update an existing profile on our web sites or Apps;</li>
                        <li>When you use certain interactive tools and services (HealthJoy mobile application,
                            TeleHealth Services, Symptom Checker);
                        </li>
                        <li>When you sign-up for newsletters or other communications from HealthJoy;</li>
                        <li>When you participate in an online survey;</li>
                        <li>We may collect &#8220;Non-Personal Information&#8221; &#8211; information that cannot be
                            used to identify you &#8211; via Cookies, Web Beacons, HealthJoy mobile device applications
                            and from external sources, even if you have not registered with or provided any Personal
                            Information to HealthJoy.
                        </li>
                    </ul>
                </div>
                <div id="choice">
                    <Text className='terms-body__title'>What Choices Do I Have?</Text>
                    <p>If you do not want your Personal Information used by HealthJoy as provided in this Privacy
                        Policy, you should not register as a member or for any specific tool or application that
                        collects Personal Information. You may correct, update or review Personal Information you have
                        submitted through registration, tools and applications by signing in and updating your
                        registration information. If you have registered and no longer want HealthJoy to use your
                        Personal Information, you should delete your information as described in this Policy or contact
                        HealthJoy. Most browser software can be set to reject Cookies, and you can opt out of Cookies
                        for several Ad Servers by visiting the Network Advertising Initiative gateway opt-out site.</p>
                </div>
                <div id="info-used">
                    <Text className='terms-body__title'>How Information Collected About You Is Used</Text>
                    <p>We may use information we collect about you to:</p>
                    <ul className='terms-body-list left-side'>
                        <li>Administer your account;</li>
                        <li>Provide you with access to particular tools and services;</li>
                        <li>Respond to your inquiries and send you administrative communications;</li>
                        <li>Obtain your feedback on our sites and our offerings;</li>
                        <li>Statistically analyze user behavior and activity;</li>
                        <li>Provide you with more relevant content, services, and advertisements;</li>
                        <li>Conduct research and measurement activities;</li>
                        <li>Send you personalized emails or secure electronic messages pertaining to your health
                            interests, including news, announcements, reminders and opportunities.
                        </li>
                        <li>We may combine Personal and Non-Personal Information collected by HealthJoy about you, and
                            may combine this information with information from external sources. Third parties may also
                            use Non-Personal Information in order to display advertising that reflects the interests and
                            preferences of our user community.
                        </li>
                    </ul>
                </div>
                <div id="who-see">
                    <Text className='terms-body__title'>With Whom Do We Share Your Information?</Text>
                    <p>HealthJoy will not disclose any Personal Information about you unless agreed to under this
                        Privacy Policy:</p>
                    <ul className='terms-body-list left-side'>
                        <li>To comply with legal requirements, such as a law, regulation, search warrant, subpoena, or
                            court order;
                        </li>
                        <li>To our vendors and suppliers in the course of their provision of products or services to
                            HealthJoy;
                        </li>
                        <li>In the event of a corporate change in control resulting from, for example, a merger, a sale
                            of assets, or bankruptcy;
                        </li>
                        <li>In special cases, such as in response to a physical threat to you or others.</li>
                    </ul>
                </div>
                <div id="how-secure">
                    <Text className='terms-body__title'>How Do We Secure and Retain Your Information?</Text>
                    <p>We have put in place technical, physical, and administrative safeguards to protect the Personal
                        Information that we collect. All of your PHI, including but not limited to, your credit card
                        number and delivery address is transmitted through the internet using Secure Socket Layers (SSL)
                        technology. SSL is designed to prevent someone other than the operators of our website from
                        capturing and viewing your personal information. In addition, online access to your PHI is
                        protected with a password you select. We strongly recommend that you do not disclose your
                        password to anyone. HealthJoy will never ask you for your password in any unsolicited
                        communication (including unsolicited correspondence such as letters, phone calls, or E-mail
                        messages).</p></div>
                <div id="contact-us">
                    <Text className='terms-body__title'>Contact HealthJoy</Text>
                    <p>Please send us an email using the Contact Us link at the bottom of every page of our site if you
                        have any questions about this Privacy Policy or the Personal Information we maintain about
                        you.<br/>
                        You can also contact HealthJoy&#8217;s Privacy Office at:<br/>
                        HealthJoy, LLC<br/>
                        Attn: General Counsel<br/>
                        215 West Superior St.<br/>
                        5th Floor<br/>
                        Chicago, IL 60654<br/>
                        email:support@healthjoy.com</p>
                    <Text className='terms-body__title'>HealthJoy Privacy Policy</Text>
                    <p>HealthJoy understands how important the privacy of personal information is to our users. This
                        Privacy Policy will tell you what information we collect about you and about your use of
                        HealthJoy and its services. It will explain the choices you have about how your personal
                        information is used and how we protect that information. We urge you to read this Privacy Policy
                        carefully. This Privacy Policy applies to Web sites owned and operated by HealthJoy that are
                        intended for use by consumers (non-professionals) for non-commercial personal, family or
                        household purposes, including sa.healthjoy.com the mobile optimized versions of this site and
                        the HealthJoy mobile applications. References to &#8220;HealthJoy&#8221; mean HealthJoy, LLC,
                        including any company that HealthJoy, LLC controls (for example, a subsidiary that HealthJoy,
                        LLC owns). HealthJoy may share information among its subsidiaries or web sites that it owns or
                        controls, but information collected under this Privacy Policy is always protected under the
                        terms of this Privacy Policy.</p>
                    <Text className='terms-body__title'>Information Collected About You</Text>
                    <h4>Personal Information</h4>
                    <p>If you choose to register or update an existing member profile with HealthJoy or access certain
                        functionality on HealthJoy, you may be required to submit Personal Information. &#8220;Personal
                        Information&#8221; means information that we can use to identify or contact you, such as your
                        name, address, telephone number or email address. Some of our interactive tools and services may
                        request you to submit health information, which would also be considered Personal Information.
                        You are responsible for ensuring the accuracy of the Personal Information that you submit to
                        HealthJoy. Inaccurate information will affect your experience when using HealthJoy and tools and
                        our ability to contact you as described in this Privacy Policy.</p>
                    <Text className='terms-body__title'>Uses and Disclosures of PHI</Text>
                    <p>The HIPAA Privacy Rule establishes national standards to protect individuals&#8217; medical
                        records and other personal health information and applies to health plans, healthcare
                        clearinghouses, and those healthcare providers that conduct certain healthcare transactions
                        electronically. The Rule requires appropriate safeguards to protect the privacy of personal
                        health information, and sets limits and conditions on the uses and disclosures that may be made
                        of such information without patient authorization. The Rule also gives patients rights over
                        their health information, including rights to examine and obtain a copy of their health records,
                        and to request corrections. HealthJoy may use and disclose your PHI in the following ways:<br/>
                        Treatment and Health Care Operations. HealthJoy is permitted to use and disclose your PHI for
                        purposes of (a) treatment and (b) healthcare operations.<br/>
                        For example:</p>
                    <ul className='terms-body-list left-side'>
                        <li><b>Treatment</b>. HealthJoy may disclose your PHI to another physician or healthcare
                            provider for purposes of a consultation or in connection with the provision of follow-up
                            treatment.
                        </li>
                        <li><b>Health Care Operations</b>. HealthJoy may use and disclose your PHI in connection with
                            its healthcare operations, such as providing health consultations, customer service and
                            conducting quality review assessments.
                        </li>
                        <li><b>Payment</b>. We document the services and supplies you receive when we are providing care
                            to you so that you, your insurance company or another third party can pay us. We may tell
                            your health plan about upcoming treatment or services that require prior approval by your
                            health plan.
                        </li>
                        <li><b>Authorization</b>. HealthJoy is permitted to use and disclose your PHI for the normal
                            business activities that the law sees as falling into the categories of treatment,
                            healthcare operations and payment. You may revoke any such authorization at any time by
                            contact HealthJoy at support@HealthJoy.com.
                        </li>
                    </ul>
                    <h4>Registration</h4>
                    <p>Access to some HealthJoy interactive tools and services may be limited to users who have
                        registered. Some of our tools (such as certain quizzes or calculators) do not retain your
                        Personal Information, while others store your Personal Information in accordance with this
                        Privacy Policy. See also the Section below on Mobile Device Applications. You may also be asked
                        to provide authorization for uses of personal information in addition to what is in this Privacy
                        Policy when you use certain tools, such as providing personal information to a sponsor of the
                        tool. HealthJoy will not make any use of your personal information in addition to this Privacy
                        Policy unless you provide such additional consent in connection with the specific tool at the
                        time you submit Personal Information in order to use the tool. HealthJoy allows you a secure
                        place to gather, store, manage and share your Personal Information and provides tools and
                        services to better manage your health and the health of your family, requires additional
                        registration. HealthJoy has the ability to use Personal Information that you provide to send you
                        personalized emails or secure electronic messages. Furthermore, HealthJoy does not use any
                        information that you submit in connection with that tool to deliver any advertisements to you on
                        or off of HealthJoy.</p>
                    <h4>Newsletters &amp; Emails to You</h4>
                    <p>At registration and at various times as you use HealthJoy, you will be given the option of
                        providing us with Personal Information in order to receive recurring informational/promotional
                        newsletters &#8211; such as a newsletter relating to a specific health condition &#8211; via
                        email from HealthJoy and/or directly from third parties. From time to time HealthJoy makes
                        subscriptions to our newsletters available on third party websites. If you sign up for one of
                        our newsletters on a third party site the site will provide us with your Personal Information
                        such as your email address and we may use that information as described in this Privacy Policy
                        as though you had provided it directly to HealthJoy.<br/>
                        When you sign up for our email newsletters on HealthJoy or at any time, you can choose to opt
                        out of receiving additional promotional newsletters from HealthJoy.<br/>
                        You can unsubscribe from a newsletter by following the directions included at the bottom of the
                        newsletter.</p>
                    <h4>Market Research</h4>
                    <p>From time to time, HealthJoy may conduct online research surveys through email invitations,
                        pop-up surveys and online focus groups. When participating in a survey, we may ask you to enter
                        Personal Information. The Personal Information you submit in a survey may be used by HealthJoy
                        for research and measurement purposes, as described below, including to measure the
                        effectiveness of content, advertising or programs. When our market research surveys collect
                        Personal Information we will not knowingly accept participants who are under the age of 13.
                        Market research surveys conducted by or on behalf of HealthJoy will contain a link to this
                        Privacy Policy.</p>
                    <h4>Emails and Messages You Send to HealthJoy</h4>
                    <p>This Privacy Policy does not apply to information, content, business information, ideas, concepts
                        or inventions that you send to HealthJoy by email. If you want to keep content or business
                        information, ideas, concepts or inventions private or proprietary, do not send them in an email
                        to HealthJoy.</p>
                    <h4>Non-Personal Information</h4>
                    <p>Even if you do not register with or provide any Personal Information to HealthJoy, we collect
                        Non-Personal Information about your use of HealthJoy. &#8220;Non-Personal
                        Information&#8221; means information that we cannot use to identify or contact you. We may also
                        acquire Non-Personal Information about our users from external sources. While you may use some
                        of the functionality of HealthJoy without registration, many of the specific tools and services
                        on HealthJoy require that you register with HealthJoy.</p>
                    <h4>Cookies and Web Beacons</h4>
                    <p>We collect Non-Personal Information about your use of HealthJoy and your use of other web sites
                        through the use of Cookies and Web Beacons. &#8220;Cookies&#8221; are small data files that are
                        stored on the hard drive of the computer you use to view a web site. Every computer that
                        accesses HealthJoy is assigned a different cookie by HealthJoy. &#8220;Web Beacons&#8221; are
                        graphic image files embedded in a web page typically used to monitor activity on a web page and
                        send back to its home server (which can belong to the host site, a network advertiser or some
                        other third party) information from your browser, such as the IP address, the URL of the page on
                        which the beacon is located, the type of browser that is accessing the site and the ID number of
                        any Cookies on your computer previously placed by that server. Third parties under contract with
                        HealthJoy may use Cookies or Web Beacons to collect Non-Personal Information about your usage of
                        HealthJoy, including which health topics you have viewed. These third party advertising service
                        providers may use this information, on our behalf, to help HealthJoy deliver advertising on
                        HealthJoy as well as on other sites on the Internet based on your browsing activity on our
                        sites. HealthJoy may further tailor the advertising on these third party sites based on
                        additional Non-Personal Information to the extent known by HealthJoy or these third parties.</p>
                    <h4>Mobile Device Applications (&#8220;Apps&#8221;) and Mobile Optimized Sites</h4>
                    <p>The HealthJoy App allows you to use many features without being registered but require that you
                        are signed in as a registered user in order to use certain personalized features. The
                        information that we collect through your use of these Apps and tools will be treated as Personal
                        Information under this Privacy Policy. When you download and install the HealthJoy App onto your
                        mobile device we assign a random number to your App installation. This number cannot be used to
                        identify you personally, and we cannot identify you personally unless you choose to become a
                        registered user of the App. We use this random number in a manner similar to our use of Cookies
                        as described in this Privacy Policy. Unlike Cookies, the random number is assigned to your
                        installation of the App itself and not a browser, because the App does not work through your
                        browser. Therefore the random number cannot be removed through settings. If you do not want us
                        to use the random number for the purposes for which we use Cookies, please do not use the
                        HealthJoy App.</p>
                    <Text>What Choices Do I Have?</Text>
                    <h4>Updating/Removing Your Personal Information</h4>
                    <p>If you do not want your Personal Information used by HealthJoy as provided in this Privacy
                        Policy, you should not register as a member or for any specific tool or application that
                        collects Personal Information. You can correct, update or review Personal Information you have
                        previously submitted by going back to the specific tool or application, logging-in and making
                        the desired change. You can also update any Personal Information you have submitted by
                        contacting us using the contact information listed below.<br/>
                        If you have registered and desire to delete any of your Personal Information you have provided
                        to us from our systems please contact us using the contact information listed below. Upon your
                        request, we will delete your Personal Information from our active databases and where feasible
                        from our back-up media. You should be aware that it is not technologically possible to remove
                        each and every record of the information you have provided to HealthJoy from our servers. If you
                        decide that you would prefer not to receive personalized email or secure electronic messages
                        from HealthJoy, you may &#8220;Opt-out&#8221; of the service by clicking on the settings tab on
                        your Health Manager home page and changing your email preference or following the unsubscribe
                        instructions included in each promotional email.</p>
                    <h4>Research and Measurement Activities</h4>
                    <p>Information that HealthJoy collects about you may be combined by HealthJoy with other information
                        available to HealthJoy through third parties for research and measurement purposes, including
                        measuring the effectiveness of content or programs. This information from other sources may
                        include age, gender, demographic, geographic, personal interests, product purchase activity or
                        other information. We may report aggregate information, which is not able to be identified back
                        to an individual user of HealthJoy to our current or prospective business partners. We do not
                        sell or share any Personal Information with any third parties, except contractors performing
                        services on our behalf who agree not to use this information received from us for any purpose
                        other than performing services on our behalf.</p>
                    <h4>Disclosures to HealthJoy Operations and Maintenance Contractor</h4>
                    <p>HealthJoy contractors sometimes have limited access to your Personal and Non-Personal Information
                        in the course of providing products or services to HealthJoy. These contractors include vendors
                        and suppliers that provide us with technology, services, data analysis, research, advertising
                        and marketing assistance and/or content for the operation and maintenance of HealthJoy. We
                        contractually require that our operations and maintenance contractors: 1) protect the privacy of
                        your Personal and Non-Personal Information consistent with this Privacy Policy and 2) not use or
                        disclose your Personal or Non-Personal Information for any purpose other than providing the
                        limited service or function for HealthJoy.</p>
                    <h4>Disclosures to Third Party Web Sites</h4>
                    <p>Certain content, services and advertisements offered to you through HealthJoy are served on, or
                        contain links to, web sites hosted and operated by a company other than HealthJoy (&#8220;Third
                        Party Web Sites&#8221;). HealthJoy does not disclose your Personal Information to these Third
                        Party Web Sites without your consent, but you should be aware that any information you disclose
                        to the other web sites once you access these other web sites is not subject to this Privacy
                        Policy. HealthJoy does not endorse and is not responsible for the privacy practices of these
                        Third Party Web Sites. You should review the privacy policy posted on the other web site to
                        understand how that Third Party Web Site collects and uses your Personal Information. HealthJoy
                        makes an effort to make it obvious to you when you leave our website and enter a Third Party Web
                        Site, either by requiring you to click on a link or by notifying you on the site before you
                        visit the third party site. In addition, if you see a phrase such as &#8220;Powered
                        by&#8221; or &#8220;in association with&#8221; followed by the name of a company other than
                        HealthJoy, then you are on a web site hosted by a company other than HealthJoy.</p>
                    <Text>How Do We Secure and Retain Your Information?</Text>
                    <p>We take reasonable security measures to protect the security of your Personal Information.
                        Despite HealthJoy&#8217;s efforts to protect your Personal Information, there is always some
                        risk that an unauthorized third party may find a way around our security systems or that
                        transmissions of your information over the Internet may be intercepted.<br/>
                        The security of your Personal Information is important to us. When you enter Personal
                        Information (including personal health information in various tools on our website), we encrypt
                        the transmission of that information or use SSL connections (Secure Socket Layer)
                        technology.<br/>
                        We will retain your Personal Information as long as your account is active or needed to provide
                        you services. At any time you can remove your Personal Information or instruct us to remove it,
                        but you should be aware that it is not technologically possible to remove each and every record
                        of the information you have provided to HealthJoy from our servers. HealthJoy tools that collect
                        and store Personal Information allow you to correct, update or review information you have
                        submitted by going back to the specific tool, logging-in and making the desired changes. We will
                        also retain your Personal Information as necessary to comply with legal obligations, resolve
                        disputes and enforce our agreements.</p>
                    <h4>Children</h4>
                    <p>We are committed to protecting the privacy of children. HealthJoy is not designed or intended to
                        attract children under the age of 13. HealthJoy does not collect Personal Information from any
                        person we actually know is under the age of 13. A parent or guardian, however, may HealthJoy to
                        establish a personal health record and a HealthJoy home page for a minor. The parent or guardian
                        is solely responsible for providing supervision of the minor&#8217;s use of HealthJoy. The
                        parent or guardian assumes full responsibility for ensuring that the registration information is
                        kept secure and that the information submitted is accurate. The parent or guardian also assumes
                        full responsibility for the interpretation and use of any information or suggestions provided
                        through HealthJoy for the minor.</p>
                    <h4>Contacting HealthJoy About Your Personal Information or Privacy</h4>
                    <p>Please send us an email by using the Contact Us link at the bottom of every page of our site if
                        you have any questions about this Privacy Policy or the Personal Information we maintain about
                        you.<br/>
                        You can also contact HealthJoy&#8217;s Privacy Office at:<br/>
                        HealthJoy, LLC<br/>
                        Attn: General Counsel<br/>
                        215 West Superior St.<br/>
                        Chicago, IL 60654</p>
                    <h4>You have the right to:</h4>
                    <ul className='terms-body-list left-side'>
                        <li><b>(i) request certain restrictions on the use of your PHI;</b></li>
                        <li><b>(ii) receive an accounting of disclosures of use of your Protected Health
                            Information;</b></li>
                        <li><b>(iii) amend your PHI;</b></li>
                        <li><b>(iv) complain to HealthJoy&#8217;s designated privacy officer at 855.947.6900; or the
                            Secretary of Health and Human Services if you believe your privacy rights have been
                            violated; and/or</b></li>
                        <li><b>(v) revoke your authorization to HealthJoy to use and disclose your Protected Health
                            Information.</b></li>
                    </ul>
                    <h4>Changes to This Privacy Policy</h4>
                    <p>We reserve the right to change or modify this Privacy Policy or any of our tools or services at
                        any time and any changes will be effective upon being posted unless we advise otherwise. If we
                        make any material changes to this policy we will notify you by email (sent to the email address
                        specified when you register) and/or by means of a notice on this website prior to the change
                        becoming effective. We encourage you to periodically review this website for the latest
                        information on our privacy practices. If you do not accept the terms of this Privacy Policy, we
                        ask that you do not register with us and that you do not use HealthJoy. Please exit HealthJoy
                        immediately if you do not agree to the terms of this Privacy Policy.</p>
                </div>
            </div>
        </div>
    );
};

export {Privacy as TestablePrivacy};
export default Privacy;
