import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import TextSection from '../TextSection';

const ServiceUtilization = props => {
    const {
        isTelemedVisible,
        telemedUtilizationChartData,
        providerRecommendationUtilizationChartData,
        appointmentUtilizationChartData
    } = props;

    const telemedUtilizationChartProps = {
        Chart: LineChart,
        title: 'MeMD Consults',
        description: 'Count of total Telemedicine consults completed.',
        data: telemedUtilizationChartData
    };
    const providerUtilizationChartProps = {
        Chart: LineChart,
        title: 'Provider Requests',
        description: 'Count of total provider requests completed. Requests can include providers for primary care to surgery.',
        data: providerRecommendationUtilizationChartData
    };
    const appointmentUtilizationChartProps = {
        Chart: LineChart,
        title: 'Appointments Scheduled',
        description: 'Count of total appointments scheduled. Appointment scheduled can include annual physicals, imaging, procedures, and surgeries.',
        data: appointmentUtilizationChartData
    };

    return (
        <PageContent title='3.1 Service Utilization'>
            {isTelemedVisible && (
                <Row className='pdf-template-row mb-small'>
                    <Column className='pdf-template-row__column' xs={7}>
                        <PdfChart {...telemedUtilizationChartProps}/>
                    </Column>

                    <Column className='pdf-template-row__column' xs={5}>
                        <TextSection title='MeMD Consults'>
                            Count of total telemedicine consults completed.
                        </TextSection>
                    </Column>
                </Row>
            )}

            <Row className='pdf-template-row mb-small'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...providerUtilizationChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Provider Requests'>
                        Count of total provider requests completed. Requests can include providers for primary care to surgery.
                    </TextSection>
                </Column>
            </Row>

            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...appointmentUtilizationChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Appointments Scheduled'>
                        Count of total appointments scheduled. Appointment scheduled can include annual physicals, imaging, procedures, and surgeries.
                    </TextSection>
                </Column>
            </Row>
        </PageContent>
    );
};

ServiceUtilization.propTypes = {
    isTelemedVisible: PropTypes.bool,
    telemedUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    providerRecommendationUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    appointmentUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    })
};

export {ServiceUtilization as TestableServiceUtilization};
export default ServiceUtilization;
