import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import Counter from '@frontend/ui-kit/Components/Counter';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import CustomizablePopup from '../../shared/CustomizablePopup';
import {Form, Field, FieldArray} from '../../shared/FormComponents';
import RenewalPlanInfoAlert from '../RenewalPlanInfoAlert';
import AncillaryTemplatesPopup from '../AncillaryTemplatesPopup';
import withPopup from '../../../HOC/withPopup';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {redirectTo} from '../../../actions/general';
import {requestAncillaryPlansOverview, requestAncillaryPlansOverviewSetting} from '../../../actions/benefits';
import {getPlanPeriodTypeCriteria, getProfilePermissions} from '../../../selectors/general';
import {compose, getItemKeyValue, negateFunc, getEqual, getUniqueListBy} from '../../../utils';
import {ANCILLARY_PLANS, FORMS, PLAN_PERIOD_TYPES, ROUTES} from '../../../constants';
import './index.scss';

const POPUP_ID = 'ancillaryPlansFormPopup';
const UPDATED_ANCILLARY_PLANS = ANCILLARY_PLANS
    .filter(negateFunc(getItemKeyValue('isHidden')))
    .sort((planA, planB) => planA.priority - planB.priority);
const COUNTER_MIN = 1;

const AncillaryPlansForm = ({openPopup, closePopup, onComplete}) => {
    const dispatch = useDispatch();
    const {planPeriodId} = useParams();
    const profilePermissions = useSelector(getProfilePermissions);
    const isRenewalPlanPeriod = useSelector(state => getPlanPeriodTypeCriteria(state, planPeriodId, PLAN_PERIOD_TYPES.renewal));
    const [initialValues, setInitialValues] = useState({plans: {}, templates: []});
    const [isPlanCreationAllowed, isPlanDeletionAllowed] = ['ancillary_plan_create', 'ancillary_plan_delete'].map(key => profilePermissions[key]?.[planPeriodId]);

    useEffect(() => {
        (async () => {
            const data = await dispatch(requestAncillaryPlansOverview(planPeriodId));

            setInitialValues(data);
        })();
    }, []);

    const onOpenDeletionPopup = (fieldName, form) => {
        const content = `
            Are you sure you want to remove this benefit?
            All data will be deleted for any associated wallet cards by turning off this toggle.
            This action will only impact the plan year you are editing.
        `;
        const onDelete = () => {
            form.change(`${fieldName}.is_active`, false);
            closePopup();
        };
        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onDelete}>Delete</Button>
            </React.Fragment>
        );
        const popupProps = {title: 'Delete Benefit', content, actionBar};
        const children = <CustomizablePopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onOpenAncillaryTemplatesPopup = (values, form) => () => {
        const filteredTemplates = values.templates.filter(getEqual(true, 'is_active'));

        const onSave = templates => {
            const uniqueTemplates = getUniqueListBy([...values.templates, ...templates], 'product_alias');
            const updatedTemplates = uniqueTemplates.map(template => {
                const {product_alias: productAlias} = template;
                const isActive = templates.some(getEqual(productAlias, 'product_alias'));

                return {...template, is_active: isActive};
            });

            form.change('templates', updatedTemplates);
            closePopup();
        };

        const children = <AncillaryTemplatesPopup selectedTemplates={filteredTemplates} onSave={onSave} onClose={closePopup}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onSubmit = async values => {
        const {plans, templates} = values;
        const updatedPlans = Object.entries(plans).map(([key, value]) => ({category: key, ...value}));

        const {isSuccess, submissionErrors} = await dispatch(requestAncillaryPlansOverviewSetting(planPeriodId, updatedPlans, templates));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(values);

        if (profilePermissions.timeline_edit) {
            onComplete();
        }
        if (!profilePermissions.timeline_edit) {
            dispatch(redirectTo(ROUTES.root));
        }
    };

    return (
        <ContentSection className='ancillary-plans-form'>
            <Form name={FORMS.ancillaryPlansOverview} initialValues={initialValues} onSubmit={onSubmit}>
                {({handleSubmit, values, initialValues, form}) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Row between='sm'>
                            <Column sm={5}>
                                <Text className='mb-8' type={TEXT_TYPES.bodyBold}>{isPlanCreationAllowed ? 'Select All' : 'View'} Applicable Benefits</Text>
                            </Column>
                            <Column sm={5}>
                                {isRenewalPlanPeriod && <RenewalPlanInfoAlert className='mb-10'/>}
                            </Column>
                        </Row>

                        <div className='ancillary-plans-form-list'>
                            {UPDATED_ANCILLARY_PLANS.map(({category, label}) => {
                                const {count, is_active: isCounter} = values.plans[category] || {};
                                const {count: initialCount} = initialValues.plans[category] || {};
                                const categoryFieldName = `plans.${category}`;

                                const onChangeSwitcher = (event, form) => {
                                    if (event.target.checked) {
                                        return form.change(`${categoryFieldName}.count`, count || COUNTER_MIN);
                                    }

                                    // FYI: We need to set "true" because the Switcher will be disabled even if User clicks on "Cancel" button in popup (Slava, 06.13.2024)
                                    form.change(`${categoryFieldName}.is_active`, true);
                                    onOpenDeletionPopup(categoryFieldName, form);
                                };

                                return (
                                    <div key={category} className='ancillary-plans-form-item'>
                                        <div className='ancillary-plans-form-item__plan'>
                                            <Field name={`${categoryFieldName}.is_active`} onChange={onChangeSwitcher}>
                                                {props => {
                                                    const readonly = props.value ? !isPlanDeletionAllowed : !isPlanCreationAllowed;

                                                    return <Switcher {...props} readonly={readonly} caption={label}/>;
                                                }}
                                            </Field>

                                            {isCounter && (
                                                <Field name={`${categoryFieldName}.count`} component={Counter} min={initialCount || COUNTER_MIN}>
                                                    {props => <Counter {...props} readonly={!isPlanCreationAllowed} min={initialCount || COUNTER_MIN}/>}
                                                </Field>
                                            )}
                                        </div>

                                        <Separator className='ancillary-plans-form-item__separator'/>
                                    </div>
                                );
                            })}

                            <FieldArray name='templates'>{({fields = []}) => (
                                <React.Fragment>
                                    {fields.map((field, index) => {
                                        const {name} = fields.value[index];
                                        const onChangeSwitcher = (event, form) => {
                                            if (event.target.checked) {
                                                return;
                                            }

                                            // FYI: We need to set "true" because the Switcher will be disabled even if User clicks on "Cancel" button in popup (Slava, 06.13.2024)
                                            form.change(`${field}.is_active`, true);
                                            onOpenDeletionPopup(field, form);
                                        };

                                        return (
                                            <div className='ancillary-plans-form-item'>
                                                <div className='ancillary-plans-form-item__plan'>
                                                    <Field name={`${field}.is_active`} onChange={onChangeSwitcher}>
                                                        {props => {
                                                            const readonly = props.value ? !isPlanDeletionAllowed : !isPlanCreationAllowed;

                                                            return <Switcher {...props} readonly={readonly} caption={name}/>;
                                                        }}
                                                    </Field>
                                                </div>

                                                <Separator className='ancillary-plans-form-item__separator'/>
                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            )}
                            </FieldArray>

                            {isPlanCreationAllowed && isPlanDeletionAllowed && (
                                <Button type={BUTTON_TYPES.secondary}
                                    data-testid='ancillary-plans-form-add-button'
                                    className='ancillary-plans-form__add-button'
                                    onClick={onOpenAncillaryTemplatesPopup(values, form)}>
                                    <Icon type={ICON_TYPES.add} className='mr-4'/> Add Common Ancillary Benefits
                                </Button>
                            )}
                        </div>

                        {(isPlanCreationAllowed || isPlanDeletionAllowed) && (
                            <React.Fragment>
                                <Separator className='mt-20 mb-20' type='solid'/>

                                <div className='ancillary-plans-form-action-bar'>
                                    <Button className='ancillary-plans-form-action-bar__button'
                                        data-testid='save-and-continue-button'
                                        type={BUTTON_TYPES.primary}
                                        isSubmit>
                                        Save & Continue
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    </form>
                )}
            </Form>
        </ContentSection>
    );
};

AncillaryPlansForm.propTypes = {
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired
};

export {AncillaryPlansForm as TestableAncillaryPlansForm};
export default compose(
    withPopup(POPUP_ID),
    withSubStepCompletion
)(AncillaryPlansForm);
