import React from 'react';
import PropTypes from 'prop-types';
import Page from '../Page';
import PdfHeading from '../PdfHeading';
import Separator from '../Separator';
import {formatDate} from '../../../utils';
import HJLogo from '../../../static/images/hjLogoPurple.svg';
import './index.scss';

const CoverPage = ({title, companyName, startDate, endDate, launchDate}) => {
    const [formattedStartDate, formattedEndDate, formattedLaunchDate] = [startDate, endDate, launchDate].map(date => formatDate(date, 'MMMM d, yyyy'));

    return (
        <Page className='cover-page' isBlank>
            <img className='cover-page__logo' src={HJLogo} alt=''/>
            <PdfHeading className='cover-page__title' type={1}>{title}</PdfHeading>
            <PdfHeading className='cover-page__date' type={5}>{formattedStartDate} - {formattedEndDate}</PdfHeading>

            <Separator className='cover-page__separator'/>

            <PdfHeading className='cover-page__company' type={4}>Prepared for {companyName}</PdfHeading>
            {formattedLaunchDate && <PdfHeading className='cover-page__date' type={5}>Launched on {formattedLaunchDate}</PdfHeading>}
        </Page>
    );
};

CoverPage.propTypes = {
    title: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    launchDate: PropTypes.string
};

export {CoverPage as TestableCoverPage};
export default CoverPage;
