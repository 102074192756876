import React from 'react';
import {useSelector} from 'react-redux';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import CompanySearch from '../CompanySearch';
import UserInfo from '../UserInfo';
import HelpCenter from '../HelpCenter';
import useClickOutsideSidebar from '../../../hooks/useClickOutsideSidebar';
import {getActiveCompany, getIsAdminPortal} from '../../../selectors/general';
import hjLogoPurple from '../../../static/images/hjLogoPurple.svg';
import hjLogoAdmin from '../../../static/images/hjLogoAdmin.svg';
import './index.scss';

const Header = () => {
    const onClickOutsideSidebar = useClickOutsideSidebar();
    const isAdminPortal = useSelector(getIsAdminPortal);
    const {logo} = useSelector(getActiveCompany) || {};
    const hjLogo = isAdminPortal ? hjLogoAdmin : hjLogoPurple;

    return (
        <Row middle='xs' className='header' onClick={onClickOutsideSidebar}>
            <Column sm={3} className='header-search'>
                <div className='header-search__search'>
                    {!isAdminPortal && <CompanySearch/>}
                </div>
            </Column>

            <Column sm={6} className='header-logos'>
                {!isAdminPortal && (
                    <div className='header-logos-company'>
                        {logo && <img className='header-logos-company__logo' src={logo} alt=''/>}
                        {!logo && <Text type={TEXT_TYPES.bodyBold} className='header-logos-company__placeholder'>Client Logo</Text>}
                    </div>
                )}

                <div className='header-logos-healthjoy'>
                    <img src={hjLogo} alt='' className='header-logos-healthjoy__logo'/>
                </div>
            </Column>

            <Column sm={3} className='header-user'>
                <div className='header-user__zendesk'>
                    <HelpCenter/>
                </div>

                <UserInfo/>
            </Column>
        </Row>
    );
};

export {Header as TestableHeader};
export default React.memo(Header);
