import {RECEIVE_MARKETING_PAGE_INFO, DOWNLOAD_RESOURCE_FILE} from './actionTypes';
import apiServices from '../apiServices';
import {HTTP_RESPONSE_TYPES} from '../constants';
import {saveFile} from '../utils';

const receiveMarketingPageInfo = info => ({type: RECEIVE_MARKETING_PAGE_INFO, info});
const downloadResourceFile = fileData => ({type: DOWNLOAD_RESOURCE_FILE, fileData});

export const requestMarketingPageInfo = pageId => async dispatch => {
    const {data, isSuccess} = await apiServices.getMarketingPageInfo({pageId});

    if (!isSuccess) {
        return false;
    }

    dispatch(receiveMarketingPageInfo(data));

    return {data, isSuccess};
};

export const requestResourceFile = fileData => async dispatch => {
    const {url, name} = fileData;
    const {data: resourceFile, isSuccess} = await apiServices.getFromThirdParty(url, null, {responseType: HTTP_RESPONSE_TYPES.blob});

    if (!isSuccess) {
        return false;
    }

    saveFile(resourceFile, name);

    dispatch(downloadResourceFile(fileData));
};
