import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import './index.scss';

const CLASS_NAME_BY_TYPE = {
    [HEADING_TYPES['1']]: 'pdf-heading_1',
    [HEADING_TYPES['2']]: 'pdf-heading_2',
    [HEADING_TYPES['3']]: 'pdf-heading_3',
    [HEADING_TYPES['4']]: 'pdf-heading_4',
    [HEADING_TYPES['5']]: 'pdf-heading_5'
};

const PdfHeading = ({children, className, type, ...props}) => {
    const classNameByType = CLASS_NAME_BY_TYPE[type];

    return <Heading {...props} type={type} className={`pdf-heading ${className} ${classNameByType}`}>{children}</Heading>;
};

PdfHeading.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    type: PropTypes.number
};

PdfHeading.defaultProps = {
    className: '',
    type: HEADING_TYPES['4']
};

export {PdfHeading as TestablePdfHeading};
export default PdfHeading;
