import {useDispatch, useSelector} from 'react-redux';
import {changeSidebarMode} from '../actions/sidebar';
import {getSidebarMode} from '../selectors/sidebar';
import {equal} from '../utils';
import {SIDEBAR_FULL_MODE, SIDEBAR_HALF_MODE} from '../constants';

const useClickOutsideSidebar = () => {
    const dispatch = useDispatch();
    const sidebarMode = useSelector(getSidebarMode);

    return () => {
        const isSidebarFullMode = equal(sidebarMode, SIDEBAR_FULL_MODE);

        if (!isSidebarFullMode) {
            return;
        }

        dispatch(changeSidebarMode(SIDEBAR_HALF_MODE));
    };
};

export default useClickOutsideSidebar;
