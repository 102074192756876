import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import WysiwygEditor from '../../../shared/WysiwygEditor';
import {Field} from '../../../shared/FormComponents';
import useFormState from '../../../../hooks/useFormState';

const CustomCardConfig = () => {
    const {values} = useFormState();

    return (
        <React.Fragment>
            <Field name='custom_page_config.title'>
                {props => <Input {...props} isRequired wrapperClassName='mb-12' label='Title'/>}
            </Field>

            <Field name='custom_page_config.body'>
                {props => <WysiwygEditor {...props} className='mb-12' label='Body *'/>}
            </Field>

            <Row className='mb-12'>
                <Column sm={6}>
                    <Field name='custom_page_config.cta.is_enabled' className='mt-10'>
                        {props => <Switcher {...props} caption='Show Button in Custom Page '/>}
                    </Field>
                </Column>

                <Column sm={6}>
                    <Field name='custom_page_config.cta.text'>
                        {props => <Input {...props} disabled={!values?.custom_page_config?.cta?.is_enabled} maxLength={10} label='Button Label' description='Please enter button copy that best fits this card.'/>}
                    </Field>
                </Column>
            </Row>
        </React.Fragment>
    );
};

export {CustomCardConfig as TestableCustomCardConfig};
export default CustomCardConfig;
