import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import Select from '@frontend/ui-kit/Components/Select';
import {Form, Field} from '../../shared/FormComponents';
import StickyActionSection from '../../shared/StickyActionSection';
import Milestones from '../TimelineTemplatesMilestones';
import {requestTimelineTemplateCreating, requestTimelineTemplates} from '../../../actions/adminPortal';
import {redirectTo} from '../../../actions/general';
import {FORMS, ROUTES, TIMELINE_TEMPLATE_MILESTONES, TIMELINE_TEMPLATE_STATUSES} from '../../../constants';
import {TIMELINE_TEMPLATE_TYPES_OPTIONS} from '../../../options';
import {getEqual, isEmpty} from '../../../utils';
import './index.scss';

const getFormattedValues = ({type, launch_date, ...milestones}) => {
    const formattedMilestones = Object.entries(milestones).map(([key, value]) => ({type: key, due_date: value}));

    return {type, launch_date, steps: formattedMilestones};
};

const getFormattedResponse = ({steps, ...rest}) => {
    const formattedMilestones = steps.reduce((acc, {type, due_date}) => ({...acc, [type]: due_date}), {});

    return {...rest, ...formattedMilestones};
};

const getMappedSubmissionErrors = submissionErrors => {
    const errors = {...submissionErrors};

    if (errors.launch_date?.includes('not_unique')) {
        errors.launch_date = 'A template with this type and launch date combination already exists. Please select a new launch date for this timeline template or edit the template that already exists for this type and launch date combination.';
    }

    return errors;
};

const isSubmitDisabled = values => {
    const milestones = TIMELINE_TEMPLATE_MILESTONES[values.type] || [];
    const milestonesNotFilled = milestones.some(milestone => isEmpty(values[milestone]));

    return milestonesNotFilled || isEmpty(values.type) || isEmpty(values.launch_date);
};

const TimelineTemplatesCreationForm = () => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const [availableLaunchDates, setAvailableLaunchDates] = useState([]);

    const redirectToTemplatesList = useCallback(() => dispatch(redirectTo(ROUTES.timelineTemplates)), [dispatch]);
    const getAvailableLaunchDates = useCallback(templateType => {
        return availableLaunchDates.filter(getEqual(templateType, 'type')).map(({launch_date: launchDate}) => launchDate);
    }, [availableLaunchDates]);

    const onChangeType = (value, {getState, reset}) => {
        const {values: {type, launch_date}} = getState();

        reset({type, launch_date});
    };

    const onChangeLaunchDate = (value, {getRegisteredFields, batch, change}) => {
        const [type, launch_date, ...restRegisteredFieldsValues] = getRegisteredFields();

        batch(() => {
            restRegisteredFieldsValues.forEach(value => change(value, null));
        });
    };

    const onSubmit = useCallback(async values => {
        const timelineTemplate = getFormattedValues(values);
        const {isSuccess, submissionErrors, data} = await dispatch(requestTimelineTemplateCreating(timelineTemplate));

        if (!isSuccess) {
            return getMappedSubmissionErrors(submissionErrors);
        }

        setInitialValues(getFormattedResponse(data));
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestTimelineTemplates({status: TIMELINE_TEMPLATE_STATUSES.active}));
            setAvailableLaunchDates(data);
        })();
    }, [dispatch]);

    return (
        <Form name={FORMS.createTimelineCalendar} initialValues={initialValues} onSubmit={onSubmit} onSubmitSuccess={redirectToTemplatesList}>
            {({handleSubmit, values}) => {
                const milestones = TIMELINE_TEMPLATE_MILESTONES[values.type];
                const isMilestonesShown = !!(milestones?.length && values.launch_date);
                const excludedDates = getAvailableLaunchDates(values.type);
                const actionBar = (
                    <React.Fragment>
                        <Button data-testid='cancel-button' type={BUTTON_TYPES.secondary} onClick={redirectToTemplatesList}>Cancel</Button>
                        <Button data-testid='create-button' disabled={isSubmitDisabled(values)} type={BUTTON_TYPES.primary} onClick={handleSubmit}>Create</Button>
                    </React.Fragment>
                );

                return (
                    <form onSubmit={handleSubmit} className='template-creation' noValidate>
                        <Row>
                            <Column sm>
                                <ContentSection className='template-creation__general-information'>
                                    <Heading className='template-creation__title'>General Information</Heading>

                                    <Row>
                                        <Column sm={6}>
                                            <Field name='type' onChange={onChangeType}>
                                                {props => <Select {...props} options={TIMELINE_TEMPLATE_TYPES_OPTIONS} label='Template Type' isRequired/>}
                                            </Field>
                                        </Column>
                                        <Column sm={6}>
                                            <Field name='launch_date' onChange={onChangeLaunchDate}>
                                                {props => <DatePicker {...props} excludeDates={excludedDates} minDate={new Date()} label='Launch Date' isRequired/>}
                                            </Field>
                                        </Column>
                                    </Row>
                                </ContentSection>

                                <Milestones isMilestonesShown={isMilestonesShown} milestones={milestones} launchDate={values.launch_date}/>
                            </Column>

                            <Column constant>
                                <StickyActionSection title='Create Custom Template' actionBar={actionBar}>
                                    <Text>Create a new implementation or renewal timeline and add in due dates for each step.</Text>
                                </StickyActionSection>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

export {TimelineTemplatesCreationForm as TestableTimelineTemplatesCreationForm};
export default TimelineTemplatesCreationForm;
