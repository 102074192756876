import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import ProgramSavingsReporting from '../../../Components/Reporting/ProgramSavings';
import PlanPeriodSwitcher from '../../../Components/Reporting/PlanPeriodSwitcher';

const ProgramSavings = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='Performance Report'/>
            </Column>

            <Column constant>
                <PlanPeriodSwitcher/>
            </Column>
        </Row>

        <ReportingPagesTabs/>

        <ProgramSavingsReporting/>
    </React.Fragment>
);

export {ProgramSavings as TestableProgramSavings};
export default React.memo(ProgramSavings);
