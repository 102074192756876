import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Sticker from '@frontend/ui-kit/Components/Sticker';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {redirectTo} from '../../../actions/general';
import {requestPlanManagerPlanVersions} from '../../../actions/adminPortal';
import {getPlanManagerPlanVersions} from '../../../selectors/adminPortal';
import {negateFunc, getEqual, equal, getItemKeyValue, formatDate, stringifyQueryParams, isEmpty} from '../../../utils';
import {ROUTES, PLAN_MANAGER_PLAN_STATUSES} from '../../../constants';
import './index.scss';

const MAX_COMPARISON_COUNT = 2;
const MIN_COMPARISON_COUNT = 2;
const CHANGE_ORIGIN_LABEL_BY_TYPE = {
    admin_portal_plan_manager: 'Admin Portal Plan Manager',
    dashboard_core_overview: 'Dashboard Core Overview',
    dashboard_core_details_edit: 'Dashboard Core Details (edit)',
    dashboard_core_details_sbc_upload: 'Dashboard Core Details (SBC upload)',
    sbc_processing: 'SBC Processing',
    renewal: 'Renewal'
};

const PlanVersionHistoryPopup = ({planId, onClose}) => {
    const [comparisonVersions, setComparisonVersions] = useState([]);
    const planManagerPlanVersions = useSelector(getPlanManagerPlanVersions);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(requestPlanManagerPlanVersions(planId));
    }, [dispatch, planId]);

    const onComparisonVersionToggle = version => event => {
        const {checked: isVersionChecked} = event.target;

        setComparisonVersions(comparisonVersions => {
            return isVersionChecked
                ? [...comparisonVersions, version]
                : comparisonVersions.filter(negateFunc(getEqual(version.id, 'id')));
        });
    };

    const compareVersions = useCallback((...versions) => {
        const [versionId, ...comparisonVersionsIds] = versions.map(getItemKeyValue('id'));
        const query = stringifyQueryParams({version_id: versionId, comparison_versions_ids: comparisonVersionsIds});

        dispatch(redirectTo(`${ROUTES.planManager}/${planId}?${query}`));
        onClose();
    }, [dispatch, onClose, planId]);

    const getVersion = version => {
        const {
            id,
            history_status: status,
            history_version: versionNumber,
            history_changed_by: changedBy,
            history_changed_at: changedAt,
            change_origin: changeOrigin
        } = version;
        const isComparisonVersion = comparisonVersions.some(getEqual(id, 'id'));
        const isComparisonDisabled = !isComparisonVersion && equal(comparisonVersions.length, MAX_COMPARISON_COUNT);
        const isLastMerged = equal(status, PLAN_MANAGER_PLAN_STATUSES.lastMerged);
        const formattedChangedAt = formatDate(changedAt, 'MM/dd/yyyy h:mm:ss aa');
        const changeOriginLabel = CHANGE_ORIGIN_LABEL_BY_TYPE[changeOrigin];

        return (
            <div className='plan-version' key={id}>
                <div className='version-info'>
                    <Checkbox className='version-info__checkbox' disabled={isComparisonDisabled} value={isComparisonVersion} onChange={onComparisonVersionToggle(version)}/>
                    <div>
                        <Text type={TEXT_TYPES.bodyBold}>v{versionNumber}</Text>
                        {isLastMerged && <Sticker className='version-info__sticker'>current</Sticker>}
                    </div>
                </div>
                <div className='version-details'>
                    <div>
                        <Text className='version-details__email' type={TEXT_TYPES.helper}>{changedBy}</Text>
                        <Text className='version-details__change-origin' type={TEXT_TYPES.helper}>{changeOriginLabel}</Text>
                        {formattedChangedAt && <Text className='version-details__date' type={TEXT_TYPES.helper}>{formattedChangedAt} UTC</Text>}
                    </div>

                    <Button type={BUTTON_TYPES.tertiary} onClick={() => compareVersions(version)} className='version-details__link'><Icon className='version-details__arrow-icon' type={ICON_TYPES.arrowRight}/></Button>
                </div>
            </div>
        );
    };

    const reversedPlanVersions = useMemo(() => [...planManagerPlanVersions].reverse(), [planManagerPlanVersions]);
    const onCompare = () => compareVersions(...comparisonVersions);

    const actionBar = (
        <React.Fragment>
            <Button type={BUTTON_TYPES.secondary} onClick={onClose}>Cancel</Button>
            <Button onClick={onCompare} disabled={comparisonVersions.length < MIN_COMPARISON_COUNT}>Compare</Button>
        </React.Fragment>
    );

    return (
        <PopupContent title='Plan Version History' actionBar={actionBar}>
            <div className='plan-version-history-popup-body'>
                {isEmpty(planManagerPlanVersions) ? (
                    <Text>
                        Once you save your first new version of this plan it will appear in this list and you'll
                        be able to compare it with the original version.
                    </Text>
                ) : (
                    <div className='plan-versions'>{reversedPlanVersions.map(getVersion)}</div>
                )}
            </div>
        </PopupContent>
    );
};

PlanVersionHistoryPopup.propTypes = {
    planId: PropTypes.string,
    onClose: PropTypes.func
};

export {PlanVersionHistoryPopup as TestablePlanVersionHistoryPopup};
export default React.memo(PlanVersionHistoryPopup);
