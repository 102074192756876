import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ArrowStepper from '../../shared/ArrowStepper';
import {Form} from '../../shared/FormComponents';
import withUnsavedFormWarning from '../../../HOC/withUnsavedFormWarning';
import useFormState from '../../../hooks/useFormState';
import usePrevious from '../../../hooks/usePrevious';
import {compose, equal} from '../../../utils';
import {FORMS} from '../../../constants';
import './index.scss';

const PlanBenefitPopup = props => {
    const {
        title,
        activeStep,
        stepsLength,
        benefit,
        Component,
        onPrevStep,
        onNextStep,
        onClose,
        onDelete,
        onSave,
        onWarnAboutUnsavedForm
    } = props;
    const prevActiveStep = usePrevious(activeStep);
    const [initialValues, setInitialValues] = useState({});
    const {values} = useFormState();

    const preferredInNetworkDescription = values?.medtool_plan?.cost_share_variance?.location_preferred_in_network_description || 'Preferred In Network';
    const inNetworkDescription = values?.medtool_plan?.benefits_tab_name_1 || 'In Network';
    const outOfNetworkDescription = values?.medtool_plan?.benefits_tab_name_2 || 'Out Of Network';

    const onSubmit = useCallback(values => {
        onSave(values);
        setInitialValues(values);
    }, [onSave]);

    useEffect(() => {
        if (!equal(activeStep, prevActiveStep)) {
            setInitialValues(benefit);
        }
    }, [benefit, activeStep, prevActiveStep]);

    const stepHandler = (isDirtyForm, callback) => {
        return isDirtyForm ? onWarnAboutUnsavedForm(callback, () => {}, FORMS.planBenefit) : callback();
    };

    return (
        <Form name={FORMS.planBenefit} initialValues={initialValues} onSubmit={onSubmit}>
            {({handleSubmit, dirty: isDirtyForm}) => {
                const arrowStepperProps = {
                    activeStep,
                    stepsLength,
                    onPrevStep: () => stepHandler(isDirtyForm, onPrevStep),
                    onNextStep: () => stepHandler(isDirtyForm, onNextStep)
                };
                const onCancel = () => stepHandler(isDirtyForm, onClose);

                const actionBar = (
                    <React.Fragment>
                        <div className='plan-benefit-popup__stepper'><ArrowStepper {...arrowStepperProps}/></div>
                        <Button className='plan-benefit-popup__button' type={BUTTON_TYPES.secondary} onClick={onCancel}>Cancel</Button>
                        <Button className='plan-benefit-popup__button' type={BUTTON_TYPES.destructive} onClick={onDelete}>Delete</Button>
                        <Button className='plan-benefit-popup__button' type={BUTTON_TYPES.primary} onClick={handleSubmit}>Save</Button>
                    </React.Fragment>
                );

                return (
                    <PopupContent title={title} actionBar={actionBar} isScrollableContent>
                        <form className='plan-benefit-popup'>
                            <Component preferredInNetworkDescription={preferredInNetworkDescription}
                                inNetworkDescription={inNetworkDescription}
                                outOfNetworkDescription={outOfNetworkDescription}/>
                        </form>
                    </PopupContent>
                );
            }}
        </Form>
    );
};

PlanBenefitPopup.propTypes = {
    title: PropTypes.string,
    activeStep: PropTypes.number,
    stepsLength: PropTypes.number,
    benefit: PropTypes.shape({
        id: PropTypes.string
    }),
    Component: PropTypes.node,
    onPrevStep: PropTypes.func,
    onNextStep: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    onWarnAboutUnsavedForm: PropTypes.func
};

export {PlanBenefitPopup as TestablePlanBenefitPopup};
export default compose(
    withUnsavedFormWarning,
    React.memo
)(PlanBenefitPopup);
