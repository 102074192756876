import React from 'react';
import PageContent from '../PageContent';
import PdfTable from '../PdfTable';
import PdfText from '../PdfText';
import './index.scss';

const TABLE_DATA = [
    {
        category: 'Surgery (Private Facility / Below-Average Cost)',
        savings: '$4,320',
        explanation: (
            <React.Fragment>
                Patients save money by using the best cost/quality option that our team was able to identify in the area.
                The $4,320 in savings comes from internal data comparing the HealthJoy recommended surgical center costs with the average MD Save or hospital cost transparency tools for the same procedure.
                A UnitedHealth Group study shows that average savings of steering to ambulatory surgical centers are around $4,500, which we’ve used to validate our savings value of $4,320 per surgery recommendation.<sup>4</sup>
            </React.Fragment>
        )
    },
    {
        category: 'Urgent Care (Freestanding)',
        savings: '$200',
        explanation: (
            <React.Fragment>
                Patients save money by avoiding emergency room visits for non-emergency care.
                Urgent care visits cost $1,240 less than ER visits on average (Source: Debt.org).<sup>5</sup>&nbsp;
                Studies on the savings of (virtual) urgent care as an ED replacement attributed $242 in savings per episode of care (Souce: NCQA.org).<sup>6</sup>&nbsp;
                For in person visits, we are attributing a $200 savings per recommendation to be more conservative.
            </React.Fragment>
        )
    }
];

const ProgramSavingsFormulaContinuedThird = () => {
    const tableProps = {
        className: 'mb-small',
        columns: [
            {header: 'Category', accessor: 'category'},
            {header: 'Savings Per Service', accessor: 'savings', width: '34mm', textAlign: 'center'},
            {header: 'Explanation', accessor: 'explanation'}
        ],
        data: TABLE_DATA
    };

    return (
        <PageContent title='2.1 Program Savings Formula Continued' className='program-savings-formula-continued'>
            <PdfText className='mb-extra-small' type='body-bold'>Provider/Facility Steerage Savings Breakdown (continued)</PdfText>

            <PdfTable {...tableProps}/>

            <PdfText className='program-savings-formula-continued__table-explanation' type='helper'>
                <sup>4</sup>. Kelsey Waddill, “How Ambulatory Surgery Centers Lower Payer Outpatient Spending” (2021), TechTarget
            </PdfText>
            <PdfText className='program-savings-formula-continued__table-explanation' type='helper'>
                <sup>5</sup>. Robert J. Mills, “AMA analysis shows most physicians work outside of private practice” (2021), AMA
            </PdfText>
            <PdfText className='program-savings-formula-continued__table-explanation' type='helper'>
                <sup>6</sup>. “Findings and Recommendations:Telehealth Effect on Total Cost of Care”, NCQA
            </PdfText>
        </PageContent>
    );
};

export {ProgramSavingsFormulaContinuedThird as TestableProgramSavingsFormulaContinuedThird};
export default ProgramSavingsFormulaContinuedThird;
