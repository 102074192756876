import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import HomeButton from '../../../Components/shared/HomeButton';
import BenefitsLayout from '../../../Components/Overview/BenefitsLayout';

const PAGE_HEADER_CAPTION = 'Provide information on Medical, Dental and Vision plans so the HealthJoy team can start to build the framework for your HealthJoy app.';

const Overview = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='Benefits Overview: Review Your Information' caption={PAGE_HEADER_CAPTION}/>
            </Column>

            <Column constant>
                <HomeButton/>
            </Column>
        </Row>

        <BenefitsLayout/>
    </React.Fragment>
);

export {Overview as TestableOverview};
export default React.memo(Overview);
