import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {requestZendeskChat, requestZendeskHelpCenter} from '../../../actions/authorization';
import {getActiveCompany, getProfileInfo} from '../../../selectors/general';
import {ZENDESK_KEY} from '../../../constants';

const Zendesk = () => {
    const dispatch = useDispatch();
    const {id: activeCompanyId} = useSelector(state => getActiveCompany(state) || {});
    const {full_name: name, email} = useSelector(getProfileInfo);

    const onZendeskLoaded = () => {
        const webWidgetSettings = {
            authenticate: {
                jwtFn: async callback => {
                    const {data: helpCenterData, isSuccess: isHelpCenterSuccess} = await dispatch(requestZendeskHelpCenter());
                    const {external_access_token: helpCenterToken} = helpCenterData || {};

                    if (!isHelpCenterSuccess) {
                        return false;
                    }

                    callback(helpCenterToken);
                },
                chat: {
                    jwtFn: async callback => {
                        const {data: chatData, isSuccess: isChatSuccess} = await dispatch(requestZendeskChat());
                        const {external_access_token: chatToken} = chatData || {};

                        if (!isChatSuccess) {
                            return false;
                        }

                        callback(chatToken);
                    }
                }
            }
        };

        try {
            window.zE('webWidget', 'identify', {name, email});

            if (!window.zESettings) {
                window.zESettings = {
                    webWidget: webWidgetSettings
                };

                return false;
            }

            window.zE('webWidget', 'updateSettings', webWidgetSettings);
            window.zE('webWidget', 'helpCenter:reauthenticate');
            window.zE('webWidget', 'chat:reauthenticate');
            window.zE('webWidget', 'show');
        } catch (error) {
            console.error(error);
        }
    };

    const loadZendesk = () => {
        const script = document.createElement('script');

        script.defer = true;
        script.id = 'ze-snippet';
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
        script.addEventListener('load', onZendeskLoaded);
        document.body.appendChild(script);
    };

    useEffect(() => {
        if (!window.zE) {
            loadZendesk();
        } else {
            onZendeskLoaded();
        }

        return () => {
            if (window.zE) {
                try {
                    window.zE('webWidget', 'hide');
                    window.zE('webWidget', 'logout');
                } catch (error) {
                    console.error(error);
                }
            }
        };
    }, []);

    useEffect(() => {
        if (window.zE) {
            try {
                window.zE('webWidget', 'updateSettings', {});
            } catch (error) {
                console.error(error);
            }
        }
    }, [activeCompanyId]);

    return null;
};

export {Zendesk as TestableZendesk};
export default Zendesk;
