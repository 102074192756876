import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Badge from '@frontend/ui-kit/Components/Badge';
import Separator from '@frontend/ui-kit/Components/Separator';
import {equal, isEmpty} from '../../../utils';
import {MEDICAL_TRAIT_LABEL_BY_TYPE} from '../../../constants';
import './index.scss';

const getPlan = (plan, index, plans) => {
    const {id, plan_type: planType, plan_name: planName, app_name: appName, carrier_name: carrierName} = plan;
    const isLast = equal(index, plans.length - 1);

    return (
        <React.Fragment key={id}>
            <Row className='plan'>
                <Column sm={3}>
                    <Text><span className='plan__index'>{index + 1}</span> {carrierName}</Text>
                </Column>

                <Column sm={3}><Text>{planName}</Text></Column>

                <Column sm={3}>{appName ? <Text>{appName}</Text> : <Text>Left blank</Text>}</Column>

                <Column sm={3}><Text>{planType}</Text></Column>
            </Row>

            {!isLast && <Separator/>}
        </React.Fragment>
    );
};

const getMedicalTrait = ([traitType], index) => <Text className='medical-trait' key={index}>{MEDICAL_TRAIT_LABEL_BY_TYPE[traitType]}</Text>;

const CoreBenefitsSummary = ({plans, medicalTraits}) => {
    const {medical: medicalPlans = [], dental: dentalPlans = [], vision: visionPlans = []} = plans || {};
    const chosenMedicalTraits = Object.entries(medicalTraits).filter(([type, isChosen]) => isChosen);

    return (
        <div className='core-benefits-summary'>
            <Row className='summary-header'>
                <Column sm={3}>
                    <Text type={TEXT_TYPES.bodyBold}>Carrier</Text>
                </Column>

                <Column sm={3}>
                    <Text type={TEXT_TYPES.bodyBold}>Plan Name</Text>
                    <Text type={TEXT_TYPES.body}>As indicated in the carrier contract or SPD</Text>
                </Column>

                <Column sm={3}>
                    <Text type={TEXT_TYPES.bodyBold}>Plan Name Shown in the App</Text>
                    <Text type={TEXT_TYPES.body}>Leave blank if same as carrier plan name</Text>
                </Column>

                <Column sm={3}>
                    <Text type={TEXT_TYPES.bodyBold}>Plan Type</Text>
                </Column>
            </Row>

            <Row className='summary-body'>
                <Column sm={12} className='plans-header plans-header_medical'>
                    <Text type={TEXT_TYPES.bodyBold}>Medical Plans</Text>
                    <Badge className='plans-header__count'>{medicalPlans.length}</Badge>
                </Column>
                <Column sm={12} className='plans-list'>
                    {!isEmpty(medicalPlans) ? medicalPlans.map(getPlan) : <Text className='plans-list__placeholder'>No Medical Plans selected</Text>}
                </Column>

                <Column sm={12} className='plans-header plans-header_dental'>
                    <Text type={TEXT_TYPES.bodyBold}>Dental Plans</Text>
                    <Badge className='plans-header__count'>{dentalPlans.length}</Badge>
                </Column>
                <Column sm={12} className='plans-list'>
                    {!isEmpty(dentalPlans) ? dentalPlans.map(getPlan) : <Text className='plans-list__placeholder'>No Dental Plans selected</Text>}
                </Column>

                <Column sm={12} className='plans-header plans-header_vision'>
                    <Text type={TEXT_TYPES.bodyBold}>Vision Plans</Text>
                    <Badge className='plans-header__count'>{visionPlans.length}</Badge>
                </Column>
                <Column sm={12} className='plans-list'>
                    {!isEmpty(visionPlans) ? visionPlans.map(getPlan) : <Text className='plans-list__placeholder'>No Vision Plans selected</Text>}
                </Column>

                <Column sm={12} className='plans-header plans-header_additional'>
                    <Text type={TEXT_TYPES.bodyBold}>Additional Medical Plan information</Text>
                </Column>
                <Column sm={12} className='plans-list'>
                    {!isEmpty(chosenMedicalTraits) ? chosenMedicalTraits.map(getMedicalTrait) : <Text className='plans-list__placeholder'>No Additional Medical Plan information selected</Text>}
                </Column>
            </Row>
        </div>
    );
};

CoreBenefitsSummary.propTypes = {
    plans: PropTypes.object,
    medicalTraits: PropTypes.object
};

export {CoreBenefitsSummary as TestableCoreBenefitsSummary};
export default React.memo(CoreBenefitsSummary);
