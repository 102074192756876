import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import SFTPAffectableClients from '../SFTPAffectableClients';
import {isEmpty} from '../../../../utils';

const SFTPLinkGenerationPopup = ({affectableClients, onClose, onContinue}) => {
    const actionBar = (
        <React.Fragment>
            <Button data-testid='cancel-button' onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
            <Button data-testid='continue-button' onClick={onContinue}>Continue</Button>
        </React.Fragment>
    );
    const popupProps = {title: 'Generate New Password Link', actionBar};

    return (
        <PopupContent {...popupProps}>
            <Text className='mb-12'>
                You're generating a new password link. Please send the new link to the client to generate the new password.
                Please note that automation setup will be affected once a new password is generated.
                {!isEmpty(affectableClients) && `The following ${affectableClients.length} clients credentials are linked and will be affected:`}
            </Text>

            {!isEmpty(affectableClients) && <SFTPAffectableClients clients={affectableClients}/>}
        </PopupContent>
    );
};

SFTPLinkGenerationPopup.propTypes = {
    affectableClients: PropTypes.arrayOf(PropTypes.string),
    onClose: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired
};

export {SFTPLinkGenerationPopup as TestableSFTPLinkGenerationPopup};
export default React.memo(SFTPLinkGenerationPopup);
