import React from 'react';
import ImportDetails from '../../../Components/AdminPortal/EligibilityImportDetails';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'partner_name'},
    {name: 'Validation report'},
    {skip: true}
];

const TPAValidationReport = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <ImportDetails/>
    </React.Fragment>
);

export {TPAValidationReport as TestableTPAValidationReport};
export default React.memo(TPAValidationReport);
