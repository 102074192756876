import React from 'react';
import {useSelector} from 'react-redux';
import SimpleLoader from '@frontend/ui-kit/Components/Loader';
import {getLoaderStatus} from '../../../selectors/shared';

const Loader = () => {
    const isLoader = useSelector(getLoaderStatus);

    return <SimpleLoader isLoader={isLoader}/>;
};

export {Loader as TestableLoader};
export default Loader;
