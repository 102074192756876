import React from 'react';
import PropTypes from 'prop-types';
import AreaChart, {AREA_CHART_PALETTE_TYPES} from '@frontend/ui-kit/Components/AreaChart';
import DoughnutChart, {DOUGHNUT_CHART_PALETTE_TYPES} from '@frontend/ui-kit/Components/DoughnutChart';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import TextSection from '../TextSection';
import {getDelimitedNum} from '../../../utils';

const ServiceUtilizationContinuedSecond = props => {
    const {
        isPreCertificationVisible,
        preCertificationCasesCount,
        preCertificationSavings,
        benefitsSupportUtilizationChartData,
        preCertificationTotalChartData,
        preCertificationChartData
    } = props;

    const benefitsSupportUtilizationChartProps = {
        Chart: LineChart,
        title: 'Benefits Support Interactions',
        description: 'Count of total unique calls, chats, and messages with our concierge team for support with general benefits questions.',
        data: benefitsSupportUtilizationChartData
    };
    const preCertificationTotalChartProps = {
        Chart: DoughnutChart,
        title: 'Total Number of Cases',
        description: 'The percentage of pre-certification cases reviewed and the total rate of new provider recommendations given out of those cases reviewed.',
        data: preCertificationTotalChartData,
        paletteType: DOUGHNUT_CHART_PALETTE_TYPES.secondary,
        options: {
            title: {text: `${getDelimitedNum(preCertificationCasesCount)} Cases`},
            centerText: {text: `$${getDelimitedNum(preCertificationSavings)}\nSavings Opportunity`}
        }
    };
    const preCertificationChartProps = {
        Chart: AreaChart,
        title: 'Total Cases Over Time',
        data: preCertificationChartData,
        paletteType: AREA_CHART_PALETTE_TYPES.secondary,
        options: {scales: {yAxes: [{stacked: true}]}}
    };

    return (
        <PageContent title='3.1 Service Utilization (Continued)'>
            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...benefitsSupportUtilizationChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Benefits Support Interactions'>
                        Count of total unique calls, chats, and messages with our concierge team for support with general benefits questions.
                    </TextSection>
                </Column>
            </Row>

            {isPreCertificationVisible && (
                <React.Fragment>
                    <Row className='pdf-template-row mt-small'>
                        <Column className='pdf-template-row__column' xs={7}>
                            <PdfChart {...preCertificationTotalChartProps}/>
                        </Column>

                        <Column className='pdf-template-row__column' xs={5}>
                            <TextSection title='Pre-certification Steerage'>
                                The percentage of pre-certification cases reviewed and the total rate of new provider recommendations given out of those cases reviewed.
                            </TextSection>
                        </Column>
                    </Row>

                    <Row className='pdf-template-row mt-small'>
                        <Column className='pdf-template-row__column' xs={7}>
                            <PdfChart {...preCertificationChartProps}/>
                        </Column>

                        <Column className='pdf-template-row__column' xs={5}>
                            <TextSection title='Pre-certification Steerage Over Time'>
                                The number of pre-certification cases reviewed and the number of new provider recommendations completed out of those cases reviewed over time
                            </TextSection>
                        </Column>
                    </Row>
                </React.Fragment>
            )}
        </PageContent>
    );
};

ServiceUtilizationContinuedSecond.propTypes = {
    isPreCertificationVisible: PropTypes.bool,
    preCertificationCasesCount: PropTypes.number,
    preCertificationSavings: PropTypes.number,
    benefitsSupportUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    preCertificationTotalChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    preCertificationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    })
};

export {ServiceUtilizationContinuedSecond as TestableServiceUtilizationContinuedSecond};
export default ServiceUtilizationContinuedSecond;
