import {
    RECEIVE_BENEFITS_CLASSES,
    RECEIVE_COMPANY_EMPLOYEE_COUNT,
    RECEIVE_COMPANY_EMPLOYEES,
    RECEIVE_INDUSTRY_LIST,
    RECEIVE_REASON_LIST
} from './actionTypes';
import {updateActiveCompany} from './general';
import {getActiveCompany} from '../selectors/general';
import apiServices from '../apiServices';

const receiveIndustryList = industryList => ({type: RECEIVE_INDUSTRY_LIST, industryList});
const receiveReasonList = reasonList => ({type: RECEIVE_REASON_LIST, reasonList});
const receiveBenefitsClasses = benefitsClasses => ({type: RECEIVE_BENEFITS_CLASSES, benefitsClasses});
const receiveCompanyEmployees = employees => ({type: RECEIVE_COMPANY_EMPLOYEES, employees});
const receiveCompanyEmployeeCount = ({employeeCount, demoUserCount}) => ({type: RECEIVE_COMPANY_EMPLOYEE_COUNT, employeeCount, demoUserCount});

export const requestCompany = () => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.getCompanyDetails({companyId});

    return {data, isSuccess};
};

export const requestCompanyUpdating = companyInfo => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.editCompanyDetails({companyId, companyInfo});

    if (!isSuccess) {
        return {isSuccess, submissionErrors: data.messages};
    }

    dispatch(updateActiveCompany(data));

    return {data, isSuccess};
};

export const requestIndustryList = () => async dispatch => {
    const {data} = await apiServices.getIndustryList();

    dispatch(receiveIndustryList(data));
};

export const requestReasonList = () => async dispatch => {
    const {data} = await apiServices.getReasonList();

    dispatch(receiveReasonList(data));
};

export const requestBenefitsClassesInfo = planPeriodId => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getBenefitsClasses({companyId, planPeriodId});

    if (isSuccess) {
        dispatch(receiveBenefitsClasses(data.classes));
    }

    return {benefitsClassesInfo: data, isSuccess};
};

export const requestBenefitsClassesInfoUpdating = classesInfo => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.setBenefitsClasses({companyId, classesInfo});

    if (isSuccess) {
        dispatch(receiveBenefitsClasses(data.classes));
    }

    return {benefitsClassesInfo: data, isSuccess, submissionErrors: data.messages};
};

export const requestCompanyEmployees = query => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getCompanyEmployees({companyId, query});
    const employees = isSuccess ? data : [];

    dispatch(receiveCompanyEmployees(employees));

    return {employees, isSuccess};
};

export const requestCompanyEmployeeCount = () => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getCompanyEmployeeCount({companyId});
    const {count: employeeCount, demo_count: demoUserCount} = data;
    const count = isSuccess ? {employeeCount, demoUserCount} : {employeeCount: 0, demoUserCount: 0};

    dispatch(receiveCompanyEmployeeCount(count));
};
