import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Separator from '@frontend/ui-kit/Components/Separator';
import {Field} from '../../shared/FormComponents';
import TiersDeductible from './TiersDeductible';

const MedicationDeductible = ({preferredInNetworkDescription, inNetworkDescription, outOfNetworkDescription}) => (
    <div className='plan-benefit-content'>
        <Row>
            <Column sm={4}>
                <Field name='preferred_in_network_individual'>
                    {props => <Input {...props} autoComplete='on' maxLength={255} label={`Individual\n(${preferredInNetworkDescription})`}/>}
                </Field>
            </Column>
            <Column sm={4}>
                <Field name='in_network_individual'>
                    {props => <Input {...props} autoComplete='on' maxLength={255} label={`Individual\n(${inNetworkDescription})`}/>}
                </Field>
            </Column>
            <Column sm={4}>
                <Field name='out_of_network_individual'>
                    {props => <Input {...props} autoComplete='on' maxLength={255} label={`Individual\n(${outOfNetworkDescription})`}/>}
                </Field>
            </Column>
        </Row>

        <Separator/>

        <TiersDeductible preferredInNetworkDescription={preferredInNetworkDescription}
            inNetworkDescription={inNetworkDescription}
            outOfNetworkDescription={outOfNetworkDescription}
            isMoop={false}/>

        <Separator/>

        <Row rowGap='lg'>
            <Column sm={4}>
                <Field name='preferred_in_network_family'>
                    {props => <Input {...props} autoComplete='on' maxLength={255} label={`Family\n(${preferredInNetworkDescription})`}/>}
                </Field>
            </Column>
            <Column sm={4}>
                <Field name='in_network_family'>
                    {props => <Input {...props} autoComplete='on' maxLength={255} label={`Family\n(${inNetworkDescription})`}/>}
                </Field>
            </Column>
            <Column sm={4}>
                <Field name='out_of_network_family'>
                    {props => <Input {...props} autoComplete='on' maxLength={255} label={`Family\n(${outOfNetworkDescription})`}/>}
                </Field>
            </Column>
            <Column sm={4}>
                <Field name='type'>
                    {props => <Input {...props} disabled autoComplete='on' label='Type'/>}
                </Field>
            </Column>
        </Row>
    </div>
);

MedicationDeductible.propTypes = {
    preferredInNetworkDescription: PropTypes.string.isRequired,
    inNetworkDescription: PropTypes.string.isRequired,
    outOfNetworkDescription: PropTypes.string.isRequired
};

export {MedicationDeductible as TestableMedicationDeductible};
export default React.memo(MedicationDeductible);
