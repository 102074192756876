import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import BarChart from '@frontend/ui-kit/Components/BarChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import TextSection from '../TextSection';
import {getDelimitedNum} from '../../../utils';

const ServiceUtilizationContinuedThird = props => {
    const {
        isMskVisible,
        isTeladocVisible,
        isTeladocGeneralMedicalVisible,
        isTeladocMentalHealthVisible,
        rxSavingsUtilizationChartData,
        mskEnrollmentsChartData,
        teladocHealthUtilizationChartData,
        teladocGeneralMedicalConsultsCount,
        teladocMentalHealthConsultsCount,
        teladocGeneralMedicalAnnualizedRate,
        teladocMentalHealthAnnualizedRate
    } = props;

    const rxSavingsUtilizationChartProps = {
        Chart: LineChart,
        title: 'Rx Savings Requests',
        description: 'Count of total RX Savings requests completed. There can be multiple prescriptions per request.',
        data: rxSavingsUtilizationChartData
    };
    const mskEnrollmentsChartProps = {
        Chart: LineChart,
        title: 'Musculoskeletal (MSK) Program Enrollments Over Time',
        description: 'Count of total members enrolled in the Musculoskeletal Therapy program over time.',
        data: mskEnrollmentsChartData
    };
    const teladocConsultsChartProps = {
        Chart: BarChart,
        isLegendForced: true,
        title: 'Total Teladoc Health Consults',
        description: 'Count of total Teladoc Health consults completed per service.',
        data: teladocHealthUtilizationChartData
    };

    return (
        <PageContent title='3.1 Service Utilization (Continued)'>
            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...rxSavingsUtilizationChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Rx Savings Requests'>
                        Count of total RX Savings requests completed. There can be multiple prescriptions per request.
                    </TextSection>
                </Column>
            </Row>

            {isMskVisible && (
                <Row className='pdf-template-row mt-small'>
                    <Column className='pdf-template-row__column' xs={7}>
                        <PdfChart {...mskEnrollmentsChartProps}/>
                    </Column>

                    <Column className='pdf-template-row__column' xs={5}>
                        <TextSection title='Musculoskeletal (MSK) Program Enrollments Over Time'>
                            Count of total members enrolled in the Musculoskeletal Therapy program over time.
                        </TextSection>
                    </Column>
                </Row>
            )}

            {isTeladocVisible && (
                <Row className='pdf-template-row mt-small'>
                    <Column className='pdf-template-row__column' xs={7}>
                        <PdfChart {...teladocConsultsChartProps}/>
                    </Column>

                    <Column className='pdf-template-row__column' xs={5}>
                        <TextSection title='Total Teladoc Health Consults'>
                            Count of total Teladoc Health consults completed.

                            <ul>
                                {isTeladocGeneralMedicalVisible && (
                                    <React.Fragment>
                                        <li>{getDelimitedNum(teladocGeneralMedicalConsultsCount)}: General Medical (Urgent Care)</li>
                                        <li className='mb-default'>{teladocGeneralMedicalAnnualizedRate}% annualized utilization rate</li>
                                    </React.Fragment>
                                )}
                                {isTeladocMentalHealthVisible && (
                                    <React.Fragment>
                                        <li>{getDelimitedNum(teladocMentalHealthConsultsCount)}: Mental Health</li>
                                        <li>{teladocMentalHealthAnnualizedRate}% annualized utilization rate</li>
                                    </React.Fragment>
                                )}
                            </ul>
                        </TextSection>
                    </Column>
                </Row>
            )}
        </PageContent>
    );
};

ServiceUtilizationContinuedThird.propTypes = {
    isMskVisible: PropTypes.bool,
    isTeladocVisible: PropTypes.bool,
    isTeladocGeneralMedicalVisible: PropTypes.bool,
    isTeladocMentalHealthVisible: PropTypes.bool,
    rxSavingsUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    mskEnrollmentsChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    teladocHealthUtilizationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    teladocGeneralMedicalConsultsCount: PropTypes.number,
    teladocMentalHealthConsultsCount: PropTypes.number,
    teladocGeneralMedicalAnnualizedRate: PropTypes.number,
    teladocMentalHealthAnnualizedRate: PropTypes.number
};

export {ServiceUtilizationContinuedThird as TestableServiceUtilizationContinuedThird};
export default ServiceUtilizationContinuedThird;
