import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import TPATweakContent from '../../TPATweakContent';
import {Field} from '../../../shared/FormComponents';
import useFieldArray from '../../../../hooks/useFieldArray';
import {IMPORT_CONFIG_TYPES} from '../../../../constants';
import {equal} from '../../../../utils';

const Tweak = ({tweaksName, index, groups, filterBy}) => {
    const {fields} = useFieldArray(tweaksName);
    const field = `${tweaksName}[${index}]`;
    const {alias, isPending} = fields.value[index];
    const {title, group_launched: isLaunched} = groups.find(group => equal(group.alias, alias)) || {};
    const isVisible = isLaunched ? filterBy[IMPORT_CONFIG_TYPES.maintenance] : filterBy[IMPORT_CONFIG_TYPES.launch];

    const onDeleteGroup = () => fields.remove(index);

    const pendingInitiator = (
        <div className='pending-header'>
            <Icon type={ICON_TYPES.alert}/>
            <Heading className='pending-header__alias' type={HEADING_TYPES['2']}>{title}:</Heading>
            <Sticker className='mr-10' type={STICKER_TYPES.default}>{isLaunched ? '🛠️ Maintenance' : '🚀 Launch'}</Sticker>
            <Text>Already has a pending validation report. To continue here will cancel the existing configuration and resubmit this one. If you would like to keep the existing config, please remove this group from your adjustments.</Text>
        </div>
    );

    const collapseProps = {
        key: alias,
        isOpened: true,
        hasCollapseIcon: true,
        initiator: isPending ? pendingInitiator : (
            <React.Fragment>
                <Heading type={HEADING_TYPES['2']}>{title}</Heading>
                <Sticker className='ml-10' type={STICKER_TYPES.default}>{isLaunched ? '🛠️ Maintenance' : '🚀 Launch'}</Sticker>
            </React.Fragment>
        ),
        content: (
            <React.Fragment>
                <Field name={`${field}.company_id`}>{props => <Input {...props} type='hidden'/>}</Field>

                <TPATweakContent field={`${field}.`}/>

                <Separator/>

                <div className='action-bar'>
                    <Button type={BUTTON_TYPES.tertiary} onClick={onDeleteGroup}><Icon type={ICON_TYPES.delete}/>Remove Group Modification</Button>
                </div>
            </React.Fragment>
        )
    };

    return (
        <ContentSection key={alias} className={classnames('tweak', 'mt-20', {tweak_pending: isPending, tweak_hidden: !isVisible})}>
            <Collapse {...collapseProps}/>
        </ContentSection>
    );
};

Tweak.propTypes = {
    tweaksName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    filterBy: PropTypes.shape({})
};

export {Tweak as TestableTweak};
export default React.memo(Tweak);
