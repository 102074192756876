import React from 'react';
import TPAPartnerConfig from '../../../Components/AdminPortal/TPAPartnerConfig';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'partner_name'},
    {name: 'TPA Configuration'}
];

const TPAPartnerConfiguration = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <TPAPartnerConfig/>
    </React.Fragment>
);

export {TPAPartnerConfiguration as TestableTPAPartnerConfiguration};
export default React.memo(TPAPartnerConfiguration);
