import React from 'react';
import PropTypes from 'prop-types';
import Tooltip, {TOOLTIP_DIRECTIONS} from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Separator from '@frontend/ui-kit/Components/Separator';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading from '@frontend/ui-kit/Components/Heading';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import VideoPopup from '../VideoPopup';
import withPopup from '../../../HOC/withPopup';
import {compose} from '../../../utils';
import './index.scss';

const POPUP_ID = 'VIDEO_POPUP';
const TOOLTIP_Z_INDEX = 9999999; // FYI: should be less than popups' one (16.01.2024, Oleh);

const VideoTooltip = ({videoOptions, isOpened, dismissVideo, toggleIsOpened, openPopup}) => {
    const {thumbnail, videoTitle, videoDescription} = videoOptions;

    const playVideo = () => {
        const children = <VideoPopup {...videoOptions}/>;

        dismissVideo();
        openPopup({type: POPUP_TYPES.simple, children});
    };

    const tooltipContent = (
        <div className='video-tooltip'>
            <div className='video-tooltip-video__thumbnail' onClick={playVideo}>
                <img src={thumbnail} className='video-tooltip-video__thumbnail-image' alt=''/>
                <div className='video-tooltip-video__play-button'/>
            </div>
            <Heading className='video-tooltip-video__title'>{videoTitle}</Heading>
            <Separator type='solid' className='video-tooltip-popup__separator'/>
            <Text type={TEXT_TYPES.helper} className='video-tooltip-video__description'>{videoDescription}</Text>
            <div>
                <Button className='video-tooltip-actions__link' type={BUTTON_TYPES.tertiary} onClick={playVideo}>Watch the Video</Button>
                <Button className='video-tooltip-actions__link' type={BUTTON_TYPES.tertiary} onClick={dismissVideo}>Dismiss</Button>
            </div>
        </div>
    );

    const overlayClass = `video-tooltip-popup__overlay ${isOpened ? 'video-tooltip-popup__overlay_opened' : ''}`;

    return (
        <div className='video-tooltip'>
            <div className={overlayClass} onClick={() => toggleIsOpened(false)}/>
            <Tooltip content={tooltipContent} direction={TOOLTIP_DIRECTIONS.right} isOpened={isOpened} zIndex={TOOLTIP_Z_INDEX}>
                <Icon className='video-tooltip-info__icon' type={ICON_TYPES.info} onClick={() => toggleIsOpened(true)}/>
            </Tooltip>
        </div>
    );
};

VideoTooltip.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    openPopup: PropTypes.func.isRequired,
    videoOptions: PropTypes.shape({
        videoTitle: PropTypes.string,
        videoDescription: PropTypes.string,
        videoId: PropTypes.string,
        start: PropTypes.number,
        thumbnail: PropTypes.string
    }),
    dismissVideo: PropTypes.func.isRequired,
    toggleIsOpened: PropTypes.func.isRequired
};

export {VideoTooltip as TestableVideoTooltip};
export default compose(withPopup(POPUP_ID), React.memo)(VideoTooltip);
