import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import {requestCompanies, switchActiveCompany} from '../../../actions/general';
import {getActiveCompany, getCompaniesMap} from '../../../selectors/general';
import {promisifyAsyncFunction} from '../../../utils';
import './index.scss';

const CompanySearch = () => {
    const dispatch = useDispatch();
    const companiesMap = useSelector(getCompaniesMap);
    const activeCompany = useSelector(getActiveCompany);
    const [isOpened, setIsOpened] = useState(false);

    const loadOptions = promisifyAsyncFunction(async query => {
        const {companies} = await dispatch(requestCompanies(query));

        return companies.map(({name: label}) => ({label, value: label}));
    });

    const onChange = value => {
        const {id: companyId} = companiesMap[value] || {};

        dispatch(switchActiveCompany(companyId));
    };

    const props = {
        onChange,
        loadOptions,
        defaultOptions: true,
        className: 'company-search__autocomplete',
        isCreatable: false,
        value: isOpened ? null : activeCompany.name,
        onOpen: () => setIsOpened(true),
        onClose: () => setIsOpened(false),
        placeholder: 'Select Company…'
    };

    return (
        <div data-testid='company-search' className='company-search'>
            <AsyncAutocomplete {...props}/>
        </div>
    );
};

export {CompanySearch as TestableCompanySearch};
export default React.memo(CompanySearch);
