import AnalyticsService from '.';
import {LOCAL_STORAGE_ITEMS} from '../constants';
import {isEmpty} from '../utils';

// FYI: We need this middleware because by default user traits are not represented in each subsequent event after identify (Vlad, 11.01.21)
const traitsMiddleware = ({payload, next}) => {
    const traits = AnalyticsService.userTraits || JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.userTraits)); // HACK: On the first PageLoad event userTraits are not available inside window.analytics, so we're getting them from the localStorage (Vlad, 11.01.20)
    payload.obj.context = !isEmpty(traits) ? {...payload.obj.context, traits} : payload.obj.context; // eslint-disable-line no-param-reassign

    next(payload);
};

export default traitsMiddleware;
