import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import EligibilityDetailsLayout from '../../../Components/Eligibility/EligibilityDetailsLayout';

const PAGE_HEADER_CAPTION = `Please select how you will be providing the initial eligibility files and upload them for our team to begin processing.
               Download the sample file and review the file spec first to make sure they have all of the required information.
               NOTE: Please include SSN on each file so that we can appropriately merge the data for each member.`;

const Details = () => (
    <React.Fragment>
        <Row>
            <Column sm={9}>
                <PageHeader title='Eligibility' caption={PAGE_HEADER_CAPTION}/>
            </Column>
        </Row>

        <EligibilityDetailsLayout/>
    </React.Fragment>
);

export {Details as TestableDetails};
export default React.memo(Details);
