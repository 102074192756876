import React from 'react';
import PropTypes from 'prop-types';
import Text from '@frontend/ui-kit/Components/Text';
import Select from '@frontend/ui-kit/Components/Select';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import {Field} from '../../shared/FormComponents';
import FileUploader from '../../shared/FileUploader';
import LabeledTooltip from '../../shared/LabeledTooltip';
import useFormState from '../../../hooks/useFormState';
import {equal, validateRequired} from '../../../utils';
import {ELIGIBILITY_FILES_SOURCES, ELIGIBILITY_FILES_CATEGORIES, ACCEPTABLE_TEXT_FORMATS} from '../../../constants';
import {MEDICAL_IDS_PROVIDING_FILE, ELIGIBILITY_PROVIDING_FILE} from '../../../options';

const FILE_SOURCE_TOOLTIP_CONTENT = `
    Files uploaded to our dashboard can only be accessed by the HealthJoy team or the original uploader.
    To download your current Eligibility information please go to Dashboard > Eligibility > Export Eligibility Files (Current Period).
    Please note if the most recent file uploaded to this page has not yet been loaded by the HealthJoy team, you will not see that information in the Eligibility Export.
`;
// FYI: Eligibility/Census File form expects one file only, so file loader field name is 'file' in this case and 'files' in others.
// That's why we have to support this additional logic (18.03.20, Oleh)
const getFileLoaderName = ({category}) => {
    const isMultiple = !equal(category, ELIGIBILITY_FILES_CATEGORIES.eligibility);

    return `file${isMultiple ? 's' : ''}`;
};

/* istanbul ignore next */
const validateOnSubmit = values => {
    const {source, category} = values;
    const fileLoaderName = getFileLoaderName({category});
    const isUploadingDocuments = equal(source, ELIGIBILITY_FILES_SOURCES.upload);

    return {
        [fileLoaderName]: isUploadingDocuments ? validateRequired(values[fileLoaderName]) : undefined
    };
};

const FileSource = ({isReadonly, isMedicalIDs, onChangeSource}) => {
    const {values} = useFormState();
    const {source, category} = values;
    const isFileLoader = equal(source, ELIGIBILITY_FILES_SOURCES.upload);
    const isSFTP = equal(source, ELIGIBILITY_FILES_SOURCES.sftp);
    const isEmployeeNavigator = equal(source, ELIGIBILITY_FILES_SOURCES.employeeNavigator);
    const fileLoaderName = getFileLoaderName({category});
    const isEligibilityFileCategory = equal(category, ELIGIBILITY_FILES_CATEGORIES.eligibility);
    const sourceOptions = isMedicalIDs ? MEDICAL_IDS_PROVIDING_FILE : ELIGIBILITY_PROVIDING_FILE;

    return (
        <div className='eligibility-file-source'>
            <Field name='source' onChange={onChangeSource}>
                {props => <Select {...props} readonly={isReadonly} options={sourceOptions} label='How will you be providing the file?' wrapperClassName='mb-12'/>}
            </Field>

            {isFileLoader && (
                <Field name={fileLoaderName}>
                    {props => (
                        <FileUploader {...props}
                            readonly={isReadonly}
                            isMultiple={!isEligibilityFileCategory}
                            accept={ACCEPTABLE_TEXT_FORMATS}
                            label={<LabeledTooltip title='Upload Your File' content={FILE_SOURCE_TOOLTIP_CONTENT}/>}
                            isPrivateFile
                            isProtected/>
                    )}
                </Field>
            )}

            {isEligibilityFileCategory && (isSFTP || isEmployeeNavigator) && (
                <Alert className='mb-12'
                    type={ALERT_TYPES.warning}
                    title='Note'
                    description='All file feeds for automated groups will be required to use termination by omission'/>
            )}

            {isSFTP && (
                <Text data-testid='sftp'>Your Implementation Manager will provide you SFTP details and credentials within 5 business days</Text>
            )}

            {isEmployeeNavigator && (
                <Text data-testid='employee-navigator'>Please reach out to your Employee Navigator contact to request a Data Exchange with HealthJoy as the vendor</Text>
            )}
        </div>
    );
};

FileSource.propTypes = {
    isReadonly: PropTypes.bool,
    isMedicalIDs: PropTypes.bool,
    onChangeSource: PropTypes.func
};

export {validateOnSubmit, FileSource as TestableFileSource};
export default React.memo(FileSource);
