import React from 'react';
import Alert from '@frontend/ui-kit/Components/Alert';
import PropTypes from 'prop-types';

const UnavailableEligibilityDataAlert = ({className}) => (
    <Alert className={className} title='Unavailable:' description='This feature will become available once we have your eligibility data'/>
);

UnavailableEligibilityDataAlert.propTypes = {
    className: PropTypes.string
};

UnavailableEligibilityDataAlert.defaultProps = {
    className: ''
};

export {UnavailableEligibilityDataAlert as TestableUnavailableEligibilityDataAlert};
export default React.memo(UnavailableEligibilityDataAlert);
