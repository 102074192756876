import React, {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import ActionButton from '../../../Containers/shared/ActionButton';
import StickyActionSection from '../../shared/StickyActionSection';
import CarrierInfo, {validate as validateCarrierInfo} from '../CarrierInfo';
import {Form} from '../../shared/FormComponents';
import {redirectTo} from '../../../actions/general';
import {requestCarrierManagerCarrierCreating} from '../../../actions/adminPortal';
import {FORMS, ROUTES} from '../../../constants';
import './index.scss';

/* istanbul ignore next */
const validate = values => ({
    ...validateCarrierInfo(values)
});

const CarrierManagerCreateDetailsForm = () => {
    const [initialValues, setInitialValues] = useState({});
    const [carrierId, setCarrierId] = useState(null);
    const [isGoToCarrier, setIsGoToCarrier] = useState(false);
    const dispatch = useDispatch();

    const onGoToCarriersList = useCallback(() => dispatch(redirectTo(ROUTES.carrierManager)), [dispatch]);

    const onSubmit = useCallback(async values => {
        const {data, isSuccess, submissionErrors} = await dispatch(requestCarrierManagerCarrierCreating(values));
        const {id: carrierId} = data || {};

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(data);
        setCarrierId(carrierId);
    }, [dispatch]);

    const onSubmitSuccess = useCallback(() => {
        const successRoute = `${ROUTES.carrierManager}/${isGoToCarrier ? carrierId : ''}`;

        dispatch(redirectTo(successRoute));
    }, [dispatch, carrierId, isGoToCarrier]);

    return (
        <Form name={FORMS.carrierManagerDetailsCreating} initialValues={initialValues} validate={validate} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit}) => {
                const onSubmitEdit = () => {
                    setIsGoToCarrier(true);
                    handleSubmit();
                };

                const actionButtonOptions = [
                    {label: 'Save and Continue Editing', action: onSubmitEdit},
                    {label: 'Save and Exit to Carrier List', action: handleSubmit}
                ];

                const actionBar = (
                    <React.Fragment>
                        <Button type={BUTTON_TYPES.secondary} onClick={onGoToCarriersList}>Cancel</Button>
                        <ActionButton options={actionButtonOptions}>Save</ActionButton>
                    </React.Fragment>
                );

                return (
                    <form>
                        <Row className='carrier-manager-create-details'>
                            <Column sm>
                                <ContentSection>
                                    <Heading className='carrier-manager-create-details__title'>General Information</Heading>

                                    <CarrierInfo/>
                                </ContentSection>
                            </Column>

                            <Column constant>
                                <StickyActionSection title='Add New Carrier' actionBar={actionBar}>
                                    <Text>To add a new carrier, fill in the required fields listed here. Note: the carrier's National Provider Identifier (NPI) is a unique 10-digit identification number issues to US healthcare providers that can be found publicly via Google.</Text>
                                </StickyActionSection>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

export {CarrierManagerCreateDetailsForm as TestableCarrierManagerCreateDetailsForm};
export default CarrierManagerCreateDetailsForm;
