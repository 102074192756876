import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {IS_SAFARI_BROWSER, YOUTUBE_EMBED_URL} from '../../../constants';

const VideoPopup = ({videoTitle, videoId, start, isAutoplay, height, width, options}) => {
    const params = options ? Object.entries(options).map(([key, value]) => `${key}='${value}'`).join(' ') : '';
    const embedCode = `
        <iframe width='${width}'
            height='${height}'
            src='${YOUTUBE_EMBED_URL}/${videoId}?start=${start}'
            frameborder='0'
            allow='accelerometer; ${isAutoplay ? 'autoplay;' : ''} clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            ${!IS_SAFARI_BROWSER ? 'allowFullscreen' : ''}
            ${params}/>
    `;

    return (
        <PopupContent title={videoTitle}>
            <div dangerouslySetInnerHTML={{__html: embedCode}}/>
        </PopupContent>
    );
};

VideoPopup.propTypes = {
    videoTitle: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    start: PropTypes.number,
    isAutoplay: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    options: PropTypes.shape({})
};

VideoPopup.defaultProps = {
    start: 0,
    isAutoplay: false,
    height: 420,
    width: 750
};

export {VideoPopup as TestableVideoPopup};
export default React.memo(VideoPopup);
