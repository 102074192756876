import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Tooltip, {TOOLTIP_DIRECTIONS} from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Select from '@frontend/ui-kit/Components/Select';
import {setReportingPlanPeriod} from '../../../actions/reporting';
import {getReportingPlanPeriod} from '../../../selectors/reporting';
import {REPORTING_PLAN_PERIOD_OPTIONS} from '../../../options';
import './index.scss';

const TOOLTIP_CONTENT = 'Select time frame for the below charts and metrics';

const PlanPeriodSwitcher = () => {
    const reportingPlanPeriod = useSelector(getReportingPlanPeriod);
    const dispatch = useDispatch();
    const setPlanPeriod = planPeriod => dispatch(setReportingPlanPeriod(planPeriod));

    return (
        <div className='reporting-plan-period-switcher'>
            <Tooltip className='plan-period-switcher-tooltip' content={TOOLTIP_CONTENT} direction={TOOLTIP_DIRECTIONS.bottom}>
                <Icon className='plan-period-switcher-tooltip__icon' type={ICON_TYPES.info}/>
            </Tooltip>
            <div className='reporting-plan-period-switcher__select'>
                <Select value={reportingPlanPeriod} options={REPORTING_PLAN_PERIOD_OPTIONS} onChange={setPlanPeriod}/>
            </div>
        </div>
    );
};

export {PlanPeriodSwitcher as TestablePlanPeriodSwitcher};
export default PlanPeriodSwitcher;
