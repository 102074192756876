import React from 'react';
import PropTypes from 'prop-types';
import Input from '@frontend/ui-kit/Components/Input';
import CopyableField from '../CopyableField';
import {trimStart} from '../../../utils';

const recommendedFields = [
    {name: 'card_title', label: 'Card Title'},
    {name: 'card_subtitle', label: 'Card Subtitle'}
];

const CardHeaderInfo = ({isReadonly}) => (
    <React.Fragment>
        <CopyableField name='card_title' parse={trimStart}>
            {props => <Input {...props} readonly={isReadonly} placeholder="ex: Card's Title" label='Card Title' wrapperClassName='mb-12'/>}
        </CopyableField>

        <CopyableField name='card_subtitle' parse={trimStart}>
            {props => <Input {...props} readonly={isReadonly} placeholder="ex: Card's Subtitle" label='Card Subtitle (optional)' wrapperClassName='mb-12'/>}
        </CopyableField>
    </React.Fragment>
);

CardHeaderInfo.propTypes = {
    isReadonly: PropTypes.bool
};

export {recommendedFields, CardHeaderInfo as TestableCardHeaderInfo};
export default CardHeaderInfo;
