import React from 'react';
import AllClientsComponent from '../../../Components/AdminPortal/AllClients';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'}
];

const AllClients = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <AllClientsComponent/>
    </React.Fragment>
);

export {AllClients as TestableAllClients};
export default React.memo(AllClients);
