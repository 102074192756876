import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Heading from '@frontend/ui-kit/Components/Heading';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Text from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import MarkupEditorField from '@frontend/ui-kit/Components/MarkupEditorField';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ProgressButton from '../../shared/ProgressButton';
import {Field, FieldArray} from '../../shared/FormComponents';
import CongratulationPopup from '../../shared/CongratulationPopup';
import PlanBenefitPopup from '../PlanBenefitPopup';
import Contents from '../PlanBenefitContents';
import HJBenefitPopup from '../HJBenefitPopup';
import PlanBenefitsCustomizationImportPopup from '../PlanBenefitsCustomizationImportPopup';
import useForm from '../../../hooks/useForm';
import useFieldArray from '../../../hooks/useFieldArray';
import useFormState from '../../../hooks/useFormState';
import withPopup from '../../../HOC/withPopup';
import {getPlanManagerBenefitsOrdering} from '../../../selectors/adminPortal';
import {finishLoading, startLoading} from '../../../actions/shared';
import {compose, delay, equal, getEqual, isEmpty, isNumber, setPropForSort, splitByIndex, pass, getDottedObj, getObjFromDotted} from '../../../utils';
import doctorIcon from '../../../static/images/doctor.svg';

const POPUP_ID = 'PlanBenefitsCustomization';

const PLAN_BENEFIT_TYPES = {
    moop: 'moop',
    medicalMoop: 'medicalMoop',
    medicationMoop: 'medicationMoop',
    planDeductible: 'planDeductible',
    medicationDeductible: 'medicationDeductible'
};

const COST_SHARE_VARIANCE_NAME = 'medtool_plan.cost_share_variance';
const SERVICE_VISITS_NAME = `${COST_SHARE_VARIANCE_NAME}.service_visits`;

const PlanBenefitsCustomization = ({openPopup, closePopup}) => {
    const form = useForm();
    const {values} = useFormState();
    const {fields: categoriesFields} = useFieldArray(SERVICE_VISITS_NAME);
    const benefitsOrdering = useSelector(getPlanManagerBenefitsOrdering);
    const dispatch = useDispatch();

    const costShareVariance = values?.medtool_plan?.cost_share_variance || {};

    // FYI: Decided show loader to help the user with the understanding that form was changed (Pasha, 1.11.2021)
    const emulateLoader = () => {
        dispatch(startLoading(true));
        delay(() => dispatch(finishLoading()), 500);
    };

    const isPlanBenefitCompleted = ({type, ...values} = {}) => {
        const {cost_share_variance_id, id, ...filteredValues} = values;
        return Object.values(filteredValues).some(Boolean);
    };

    const onOpenPlanBenefitsCustomizationImportPopup = () => {
        const {company_alias: companyAlias, core_plan: corePlan} = values;

        const onSave = plan => {
            const {name, cost_share_variance: importedCostShareVariance} = plan.medtool_plan;
            const {id, plan_id} = costShareVariance;

            const formattedImportedCostShareVariance = getObjFromDotted(Object
                .entries(getDottedObj(importedCostShareVariance))
                .reduce((acc, [key, value]) => {
                    const [lastKey] = key.split('.').reverse();
                    const isImportable = !['id', 'cost_share_variance_id'].includes(lastKey);

                    return {...acc, [key]: isImportable ? value : undefined};
                }, {}));

            form.change(COST_SHARE_VARIANCE_NAME, {...formattedImportedCostShareVariance, id, plan_id});

            const actionBar = <Button type={BUTTON_TYPES.primary} onClick={closePopup}>Okay</Button>;
            const icon = <img src={doctorIcon} alt='doctor'/>;
            const children = (
                <CongratulationPopup actionBar={actionBar} icon={icon}>
                    Plan Benefits Customization details have been successfully imported from {name}!
                </CongratulationPopup>
            );

            return openPopup({type: POPUP_TYPES.fullscreen, children});
        };

        const children = <PlanBenefitsCustomizationImportPopup companyAlias={companyAlias} planPeriodId={corePlan.plan_period_id} onSave={onSave} onClose={closePopup}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const onOpenPlanBenefitPopup = type => () => {
        const [title, Component, fieldName, defaultBenefitType] = {
            [PLAN_BENEFIT_TYPES.planDeductible]: ['Plan Deductible Manage', Contents.PlanDeductible, 'plan_deductible'],
            [PLAN_BENEFIT_TYPES.medicationDeductible]: ['Medication Deductible Manage', Contents.MedicationDeductible, 'medication_deductible', 'DRUG_EHB_DEDUCTIBLE'],
            [PLAN_BENEFIT_TYPES.moop]: ['Maximum Out Of Pocket Limit Manage', Contents.MOOP, 'moop', 'MOOP_MEDICAL_DRUG_EHB_DEDUCTIBLE'],
            [PLAN_BENEFIT_TYPES.medicalMoop]: ['Medical MOOP Manage', Contents.MOOP, 'medical_moop', 'Maximum out of Pocket for Medical EHB'],
            [PLAN_BENEFIT_TYPES.medicationMoop]: ['Medication MOOP Manage', Contents.MOOP, 'medication_moop', 'Maximum out of Pocket for Drug EHB']
        }[type];
        const formSectionName = `medtool_plan.cost_share_variance.${fieldName}`;
        // FYI: since we invoke this func recursively we have to get costShareVariance from actual form state instead of closure (05.02.2024, Oleh);
        const costShareVariance = form.getState().values?.medtool_plan?.cost_share_variance || {};
        const benefit = costShareVariance[fieldName] || {};
        const enhancedBenefit = {...benefit, type: benefit.type ?? defaultBenefitType};

        const benefits = Object.keys(PLAN_BENEFIT_TYPES);
        const activeBenefitIndex = benefits.findIndex(benefit => equal(benefit, type));

        form.registerField(formSectionName, pass);

        const onDelete = () => {
            closePopup();

            if (equal(type, PLAN_BENEFIT_TYPES.planDeductible)) { // FYI: we should reset fields to null while deleting only for planDeductible benefit (business logic) (Pasha, 31.05.2021)
                form.batch(() => {
                    Object.keys(enhancedBenefit).forEach(fieldName => form.change(`${formSectionName}.${fieldName}`, null));
                });

                return false;
            }

            form.change(formSectionName, {});
        };

        const onSave = values => {
            emulateLoader();
            form.change(formSectionName, values);
            onOpenPlanBenefitPopup(type)();
        };

        const onPrevStep = onOpenPlanBenefitPopup(benefits[activeBenefitIndex - 1]);
        const onNextStep = onOpenPlanBenefitPopup(benefits[activeBenefitIndex + 1]);

        const planBenefitPopupProps = {
            Component,
            form,
            title,
            activeStep: activeBenefitIndex,
            stepsLength: benefits.length,
            benefit: enhancedBenefit,
            onPrevStep,
            onNextStep,
            onClose: closePopup,
            onDelete,
            onSave
        };

        const children = <PlanBenefitPopup {...planBenefitPopupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    // FYI: This function is used for finding the right index for a new category in the form serviceVisits.
    // We use sortedBenefitsCategories like a source of truth for correct categories order (Pasha, 21.10.2021)
    // TODO: For Oleg - will think about refactoring (Pasha, 21.10.2021)
    const getServiceVisitIndex = category => {
        const {service_visits: serviceVisits} = costShareVariance;
        const sortedBenefitsOrdering = Object.entries(benefitsOrdering)
            .sort(([categoryPrev, orderPrev], [categoryNext, orderNext]) => setPropForSort(orderPrev, orderNext))
            .reduce((acc, [category, order]) => ({...acc, [category]: order}), {});
        const sortedBenefitsCategories = Object.keys(sortedBenefitsOrdering);
        const benefitCategoryIndex = sortedBenefitsCategories.findIndex(getEqual(category));

        if (equal(benefitCategoryIndex, -1)) {
            return serviceVisits.length;
        }

        const prevBenefitsOrderingList = sortedBenefitsCategories.slice(0, benefitCategoryIndex).reverse();
        let prevServiceVisitIndex;

        for (const prevOrderingCategory of prevBenefitsOrderingList) {
            prevServiceVisitIndex = serviceVisits.findIndex(({category}) => equal(category, prevOrderingCategory));

            if (!equal(prevServiceVisitIndex, -1)) {
                break;
            }
        }

        return (!isNumber(prevServiceVisitIndex) || equal(prevServiceVisitIndex, -1)) ? 0 : prevServiceVisitIndex + 1;
    };

    const onAddServiceVisit = serviceVisit => {
        const {category} = serviceVisit || {};
        const {service_visits: serviceVisits} = costShareVariance;
        const insertedServiceVisitIndex = getServiceVisitIndex(category);
        const [start, end] = splitByIndex(serviceVisits, insertedServiceVisitIndex);
        const updatedServiceVisits = [...start, serviceVisit, ...end];

        form.change(SERVICE_VISITS_NAME, updatedServiceVisits);
    };

    const getBenefitsTypes = () => {
        const {service_visits: serviceVisits} = costShareVariance;

        return serviceVisits
            .reduce((acc, {items}) => [...acc, ...items], [])
            .map(({type}) => type);
    };

    const onOpenAddHJBenefitPopup = () => {
        const onSave = ({id, ...values}) => {
            const {service_visits: serviceVisits} = costShareVariance;
            const {category, type, ...filteredValues} = values;
            const enhancedValues = {...filteredValues, type: type.toLowerCase()};
            const serviceVisitIndex = serviceVisits.findIndex(getEqual(category, 'category')); // FYI: We should get index for adding new benefit to the correct category (Pasha, 22.06)

            emulateLoader();

            if (equal(serviceVisitIndex, -1)) {
                const serviceVisit = {category, items: [enhancedValues]};
                onAddServiceVisit(serviceVisit);
            }

            if (!equal(serviceVisitIndex, -1)) {
                const {items: categoryItems} = serviceVisits[serviceVisitIndex];
                form.change(`${SERVICE_VISITS_NAME}[${serviceVisitIndex}].items`, [...categoryItems, enhancedValues]);
            }

            onOpenAddHJBenefitPopup();
        };

        const children = <HJBenefitPopup existingTypes={getBenefitsTypes()} isFormResettable title='Add New HJ Benefits' onSave={onSave} onClose={closePopup}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const updateHJBenefitPopup = ({benefitType, benefitsFields}) => {
        const {service_visits: serviceVisits} = costShareVariance;
        const benefitsList = serviceVisits.reduce((acc, {items}) => [...acc, ...items], []);
        const benefitsIndex = benefitsList.findIndex(getEqual(benefitType, 'type'));

        onOpenHJBenefitPopup({benefitsIndex, benefitsFields});
    };

    const onSaveHJBenefit = ({categoryIndex, benefitIndex, benefitsFields}) => values => {
        const {service_visits: serviceVisits} = costShareVariance;
        const {category: updatedCategory, type, ...filteredValues} = values;
        const updatedType = type.toLowerCase();
        const enhancedValues = {...filteredValues, type: updatedType};
        const {category} = serviceVisits[categoryIndex] || {};
        const isCategoryChanged = !equal(category, updatedCategory);

        emulateLoader();

        if (isCategoryChanged) { // FYI: If the category was changed we should remove the current benefit and add new benefit into specific service visit item (Pasha, 23.06.2021)
            const serviceVisitIndex = serviceVisits.findIndex(getEqual(updatedCategory, 'category'));

            benefitsFields.remove(benefitIndex);

            if (equal(serviceVisitIndex, -1)) { // FYI: If service visits don't have updated category we should add new one with actual benefit (Pasha, 24.06.2021)
                const serviceVisit = {category: updatedCategory, items: [enhancedValues]};
                onAddServiceVisit(serviceVisit);
            }

            if (!equal(serviceVisitIndex, -1)) {
                const {items} = serviceVisits[serviceVisitIndex];

                categoriesFields.update(serviceVisitIndex, {category: updatedCategory, items: [...items, enhancedValues]});
            }

            // FYI: After changing category benefitsIndex should be updated to show new correct place in benefit list.
            // Form is changed with next render so should use daley func to wait form values updating (Pasha, 1.11.2021)
            return delay(() => updateHJBenefitPopup({benefitType: updatedType, benefitsFields}));
        }

        form.change(`${SERVICE_VISITS_NAME}[${categoryIndex}].items[${benefitIndex}]`, enhancedValues);
    };

    const onDeleteHJBenefit = ({benefitIndex, benefitsFields}) => () => {
        benefitsFields.remove(benefitIndex);
        closePopup();
    };

    const onOpenHJBenefitPopup = ({defaultBenefit, benefitsIndex, benefitsFields}) => {
        // FYI: since we invoke this func recursively we have to get costShareVariance from actual form state instead of closure (05.02.2024, Oleh);
        const {service_visits: serviceVisits} = form.getState().values?.medtool_plan?.cost_share_variance || {};
        const benefitsList = serviceVisits.reduce((acc, {items, category}) => {
            const enhancedBenefits = items.map(benefit => ({...benefit, category}));

            return [...acc, ...enhancedBenefits];
        }, []);
        const benefit = defaultBenefit || benefitsList[benefitsIndex];
        const {type, category} = benefit || {};
        const benefitsListIndex = benefitsList.findIndex(getEqual(type, 'type'));
        const categoryIndex = serviceVisits.findIndex(getEqual(category, 'category'));
        const benefitIndex = serviceVisits[categoryIndex].items.findIndex(getEqual(type, 'type'));

        const onPrevBenefit = () => onOpenHJBenefitPopup({benefitsIndex: benefitsListIndex - 1, benefitsFields});
        const onNextBenefit = () => onOpenHJBenefitPopup({benefitsIndex: benefitsListIndex + 1, benefitsFields});

        const benefitPopupProps = {
            benefit,
            title: type,
            existingTypes: getBenefitsTypes(),
            onSave: onSaveHJBenefit({categoryIndex, benefitIndex, benefitsFields}),
            onDelete: onDeleteHJBenefit({benefitIndex, benefitsFields}),
            onClose: closePopup,
            activeStep: benefitsListIndex,
            stepsLength: benefitsList.length,
            onPrevStep: onPrevBenefit,
            onNextStep: onNextBenefit
        };

        openPopup({type: POPUP_TYPES.simple, children: <HJBenefitPopup {...benefitPopupProps}/>});
    };

    const getHJBenefit = (defaultCategory, benefitsFields) => (benefit, index) => {
        const {value: benefits} = benefitsFields;
        const benefitDetails = benefits[index];
        const {type: benefitType} = benefitDetails;
        const enhancedBenefitDetails = {...benefitDetails, category: defaultCategory};

        const onOpen = () => onOpenHJBenefitPopup({defaultBenefit: enhancedBenefitDetails, benefitsFields});

        return (
            <ProgressButton key={benefit} onClick={onOpen} className='benefit-section__button' completed>
                {benefitType}
            </ProgressButton>
        );
    };

    const getHJBenefits = category => ({fields}) => fields.map(getHJBenefit(category, fields));

    const getHJBenefitsCategories = ({fields}) => fields.map((categoryName, index) => {
        const {category, items} = fields.value[index];

        return !isEmpty(items) && (
            <Column key={categoryName} sm={4} className='benefit-section'>
                <Heading className='benefit-section__title'>{category}</Heading>

                <FieldArray name={`${categoryName}.items`}>{getHJBenefits(category)}</FieldArray>
            </Column>
        );
    });

    const {
        moop,
        medication_moop: medicationMoop,
        medical_moop: medicalMoop,
        plan_deductible: planDeductible,
        medication_deductible: medicationDeductible,
        show_after_deductible_disclaimer_for_in_network: isAfterDeductibleDisclaimerForInNetwork,
        show_after_deductible_disclaimer_for_out_of_network: isAfterDeductibleDisclaimerForOutOfNetwork,
        show_after_deductible_disclaimer_for_preferred_in_network: isAfterDeductibleDisclaimerForPreferredInNetwork
    } = costShareVariance;

    return (
        <ContentSection className='details-section details-section_benefits'>
            <Row>
                <Column sm>
                    <Heading className='details-section__title'>Plan Benefits Customization</Heading>
                </Column>

                <Column constant>
                    <Button className='details-section__title-button' type={BUTTON_TYPES.tertiary} onClick={onOpenPlanBenefitsCustomizationImportPopup} data-testid='import-button'>
                        Import Data from Another Plan
                    </Button>
                </Column>
            </Row>
            <Text>The information provided here will be used to populate the Plan Benefits section within the HealthJoy app and stored in CRM.</Text>

            <Row rowGap='md' className='details-section__customization'>
                <Column sm={4}>
                    <ProgressButton completed={isPlanBenefitCompleted(moop)} onClick={onOpenPlanBenefitPopup(PLAN_BENEFIT_TYPES.moop)}>
                        Maximum Out Of Pocket Limit
                    </ProgressButton>
                </Column>
                <Column sm={4}>
                    <ProgressButton completed={isPlanBenefitCompleted(medicalMoop)} onClick={onOpenPlanBenefitPopup(PLAN_BENEFIT_TYPES.medicalMoop)}>
                        Medical MOOP
                    </ProgressButton>
                </Column>
                <Column sm={4}>
                    <ProgressButton completed={isPlanBenefitCompleted(medicationMoop)} onClick={onOpenPlanBenefitPopup(PLAN_BENEFIT_TYPES.medicationMoop)}>
                        Medication MOOP
                    </ProgressButton>
                </Column>
                <Column sm={4}>
                    <ProgressButton completed={isPlanBenefitCompleted(planDeductible)} onClick={onOpenPlanBenefitPopup(PLAN_BENEFIT_TYPES.planDeductible)}>
                        Plan Deductible
                    </ProgressButton>
                </Column>
                <Column sm={4}>
                    <ProgressButton completed={isPlanBenefitCompleted(medicationDeductible)} onClick={onOpenPlanBenefitPopup(PLAN_BENEFIT_TYPES.medicationDeductible)}>
                        Medication Deductible
                    </ProgressButton>
                </Column>
            </Row>

            <Separator/>

            <Row>
                <Column sm={4}>
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.location_preferred_in_network_description`}>
                        {props => <Input {...props} maxLength={25} placeholder='Display Name' description="Examples: 'Preferred In Network', 'Tier 1'. If left empty, this field will be displayed as 'Preferred In Network' in the HealthJoy app and CRM." label='Preferred In Network Description'/>}
                    </Field>
                </Column>

                <Column sm={4}>
                    <Field name='medtool_plan.benefits_tab_name_1'>
                        {props => <Input {...props} maxLength={25} placeholder='Display Name' description="Examples: 'In Network', 'Tier 1'. If left empty, this field will be displayed as 'In Network' in the HealthJoy app and CRM." isRequired label='In Network Description'/>}
                    </Field>
                </Column>

                <Column sm={4}>
                    <Field name='medtool_plan.benefits_tab_name_2'>
                        {props => <Input {...props} maxLength={25} placeholder='Display Name' description="Examples: 'Out of Network', 'Tier 2'. If left empty, this field will be displayed as 'Out of Network' in the HealthJoy app and CRM." isRequired label='Out of Network Description'/>}
                    </Field>
                </Column>
            </Row>

            <Separator/>

            <Row>
                <Column sm={4}>
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.tier2_description`}>
                        {props => <Input {...props} autoComplete='on' maxLength={25} label='Deductible/MOOP Tier 2 Description'/>}
                    </Field>
                </Column>
                <Column sm={4}>
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.tier3_description`}>
                        {props => <Input {...props} autoComplete='on' maxLength={25} label='Deductible/MOOP Tier 3 Description'/>}
                    </Field>
                </Column>
            </Row>

            <Separator/>

            <Collapse hasCollapseIcon
                isOpened={isAfterDeductibleDisclaimerForInNetwork}
                initiator={(
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.show_after_deductible_disclaimer_for_in_network`}>
                        {props => <Checkbox {...props} caption='Show Plan Benefits (In Network) ‘after deductible’ disclaimer'/>}
                    </Field>
                )}
                content={(
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.after_deductible_disclaimer_for_in_network`}>
                        {props => <MarkupEditorField {...props} label='Disclaimer' disabled={!isAfterDeductibleDisclaimerForInNetwork} maxLength={250} isAllowInputOverflow options={{disableExtraSpaces: true}} wrapperClassName='mt-6'/>}
                    </Field>
                )}/>

            <Separator/>

            <Collapse hasCollapseIcon
                isOpened={isAfterDeductibleDisclaimerForOutOfNetwork}
                initiator={(
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.show_after_deductible_disclaimer_for_out_of_network`}>
                        {props => <Checkbox {...props} caption='Show Plan Benefits (Out of Network) ‘after deductible’ disclaimer'/>}
                    </Field>
                )}
                content={(
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.after_deductible_disclaimer_for_out_of_network`}>
                        {props => <MarkupEditorField {...props} label='Disclaimer' disabled={!isAfterDeductibleDisclaimerForOutOfNetwork} maxLength={250} isAllowInputOverflow options={{disableExtraSpaces: true}} wrapperClassName='mt-6'/>}
                    </Field>
                )}/>

            <Separator/>

            <Collapse hasCollapseIcon
                isOpened={isAfterDeductibleDisclaimerForPreferredInNetwork}
                initiator={(
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.show_after_deductible_disclaimer_for_preferred_in_network`}>
                        {props => <Checkbox {...props} caption='Show Plan Benefits (Preferred In-network) ‘after deductible’ disclaimer'/>}
                    </Field>
                )}
                content={(
                    <Field name={`${COST_SHARE_VARIANCE_NAME}.after_deductible_disclaimer_for_preferred_in_network`}>
                        {props => <MarkupEditorField {...props} label='Disclaimer' disabled={!isAfterDeductibleDisclaimerForPreferredInNetwork} maxLength={250} isAllowInputOverflow options={{disableExtraSpaces: true}} wrapperClassName='mt-6'/>}
                    </Field>
                )}/>

            <Separator/>

            <Row>
                <Column sm={4}>
                    <ProgressButton onClick={onOpenAddHJBenefitPopup}>Add New HJ Benefits</ProgressButton>
                </Column>
            </Row>

            <Row>
                <FieldArray name={SERVICE_VISITS_NAME}>{getHJBenefitsCategories}</FieldArray>
            </Row>
        </ContentSection>
    );
};

PlanBenefitsCustomization.propTypes = {
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {PlanBenefitsCustomization as TestablePlanBenefitsCustomization};
export default compose(
    withPopup(POPUP_ID),
    React.memo
)(PlanBenefitsCustomization);
