import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Wizard from '../../../Containers/shared/Wizard';
import ContentStepV2 from '../BroadcastV2/BroadcastWizardSteps/ContentStep';
import ContentStep from '../BroadcastV1/BroadcastWizardSteps/ContentStep';
import InformationStep from '../BroadcastV1/BroadcastWizardSteps/InformationStep';
import InformationStepV2 from '../BroadcastV2/BroadcastWizardSteps/InformationStep';
import {clearBroadcast, setBroadcastTemplate} from '../../../actions/broadcaster';

const WIZARD_STEPS = [
    {StepComponent: ContentStep, stepName: 'content', stepTitle: 'Broadcast Content'},
    {StepComponent: InformationStep, stepName: 'information', stepTitle: 'Broadcast Information'}
];

const WIZARD_STEPS_V2 = [
    {StepComponent: ContentStepV2, stepName: 'content', stepTitle: 'Broadcast Content'},
    {StepComponent: InformationStepV2, stepName: 'information', stepTitle: 'Schedule'}
];

const BroadcastWizard = ({isNewBroadcasterUI}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearBroadcast());
            dispatch(setBroadcastTemplate(null));
        };
    }, [dispatch]);

    return <Wizard steps={isNewBroadcasterUI ? WIZARD_STEPS_V2 : WIZARD_STEPS}/>;
};

BroadcastWizard.propTypes = {
    isNewBroadcasterUI: PropTypes.bool
};

export {BroadcastWizard as TestableBroadcastWizard};
export default BroadcastWizard;
