import React from 'react';
import PropTypes from 'prop-types';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import MemberServiceUtilization from '../MemberServiceUtilization';
import withReportingInfo from '../../../HOC/withReportingInfo';
import {getDelimitedNum, isEmpty} from '../../../utils';

const Utilization = props => {
    const {
        info,
        isTelemedVisible,
        isTeladocVisible,
        isTeladocGeneralMedicalVisible,
        isTeladocMentalHealthVisible,
        isMedicalBillVisible,
        isPreCertificationVisible,
        isMskVisible,
        isFindCareVisible
    } = props;
    const {
        eligibleEmployeesCount,
        utilizationTicketsCount,
        memberUtilizationChartData,
        walletViewsCount,
        serviceRequestsCount,
        telemedicineConsultsCount,
        teladocGeneralMedicalConsultsCount,
        teladocMentalHealthConsultsCount,
        providerRecommendationsCount,
        appointmentsCount,
        facilityRecommendationsCount,
        findCareSearchesCount,
        rxReviewsCount,
        medicalBillReviewsCount,
        preCertificationCasesCount,
        mskEnrollmentsCount,
        benefitsSupportCount
    } = info;
    const teladocHealthConsultsCount = (isTeladocGeneralMedicalVisible ? teladocGeneralMedicalConsultsCount : 0)
        + (isTeladocMentalHealthVisible ? teladocMentalHealthConsultsCount : 0);
    const facilityProviderGuidanceCount = (isFindCareVisible ? findCareSearchesCount : 0) + facilityRecommendationsCount + providerRecommendationsCount + appointmentsCount;
    const memberUtilizationChartProps = {
        title: 'Total Unique Member Actions',
        titleInfo: `
            This includes views of the Benefit Wallet as well as Service Requests that have been completed on behalf of your employees.
            Service Requests include Telemedicine Consults, Rx Savings Requests, Provider and Facility Recommendations, Appointment Schedules, Medical Bill Reviews, and Benefit Support Interactions via phone call or chat.
        `,
        description: `
            Total Unique Member Actions is calculated as the total number of services that members have received through the HealthJoy app.
        `,
        data: memberUtilizationChartData,
        options: {scales: {yAxes: [{ticks: {suggestedMax: 1.5 * eligibleEmployeesCount}}]}}
    };

    return (
        <div className='reporting reporting_utilization' data-testid='utilization'>
            <ContentSection className='reporting-section'>
                <Row columnGap='xlg'>
                    <Column className='reporting-section__column reporting-section__column_separated' sm={6}>
                        <div className='reporting-chart'>
                            <LineChart {...memberUtilizationChartProps}/>
                        </div>
                    </Column>

                    <Column className='reporting-section__column' sm={6}>
                        <Heading className='reporting-info-title' data-testid='utilization-title' type={HEADING_TYPES['5']}>
                            Member Utilization
                        </Heading>

                        <Text className='reporting-info-subtitle' type={TEXT_TYPES.bodyBold}>
                            Members have used HealthJoy {getDelimitedNum(utilizationTicketsCount)} times
                        </Text>

                        <Text className='reporting-info reporting-info_list-description'>
                            Members have viewed their Benefits Wallet {getDelimitedNum(walletViewsCount)} times and
                            completed {getDelimitedNum(serviceRequestsCount)} total service requests.
                            Here is a breakdown of your company's service utilization:
                        </Text>

                        <ul className='reporting-list'>
                            {isTelemedVisible && (
                                <li className='reporting-list__item' data-testid='telemed-list-item'>
                                    <Text>{getDelimitedNum(telemedicineConsultsCount)} MeMD Consults</Text>
                                </li>
                            )}
                            {isTeladocVisible && (
                                <li className='reporting-list__item' data-testid='teladoc-health-list-item'>
                                    <Text>{getDelimitedNum(teladocHealthConsultsCount)} Teladoc Health Consults</Text>

                                    <ul className='reporting-list'>
                                        {isTeladocGeneralMedicalVisible && (
                                            <li className='reporting-list__item'>
                                                <Text>{getDelimitedNum(teladocGeneralMedicalConsultsCount)} General Medical (Urgent Care)</Text>
                                            </li>
                                        )}
                                        {isTeladocMentalHealthVisible && (
                                            <li className='reporting-list__item'>
                                                <Text>{getDelimitedNum(teladocMentalHealthConsultsCount)} Mental Health</Text>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                            <li className='reporting-list__item'>
                                <Text>
                                    {getDelimitedNum(facilityProviderGuidanceCount)} Provider/Facility Guidances
                                </Text>

                                <ul className='reporting-list'>
                                    {isFindCareVisible && (
                                        <li className='reporting-list__item' data-testid='find-care-list-item'>
                                            <Text>{getDelimitedNum(findCareSearchesCount)} Find Care Searches</Text>
                                        </li>
                                    )}
                                    <li className='reporting-list__item'>
                                        <Text>{getDelimitedNum(facilityRecommendationsCount)} Facility Recommendations</Text>
                                    </li>
                                    <li className='reporting-list__item'>
                                        <Text>{getDelimitedNum(providerRecommendationsCount)} Provider Recommendations</Text>
                                    </li>
                                    <li className='reporting-list__item'>
                                        <Text>{getDelimitedNum(appointmentsCount)} Appointments Scheduled</Text>
                                    </li>
                                </ul>
                            </li>
                            <li className='reporting-list__item'>
                                <Text>{getDelimitedNum(rxReviewsCount)} Rx Savings Requests</Text>
                            </li>
                            {isMedicalBillVisible && (
                                <li className='reporting-list__item' data-testid='mbr-list-item'>
                                    <Text>{getDelimitedNum(medicalBillReviewsCount)} Medical Bill Reviews</Text>
                                </li>
                            )}
                            <li className='reporting-list__item'>
                                <Text>{getDelimitedNum(benefitsSupportCount)} Benefits Support Interactions</Text>
                            </li>
                            {isPreCertificationVisible && (
                                <li className='reporting-list__item' data-testid='pre-certification-list-item'>
                                    <Text>{getDelimitedNum(preCertificationCasesCount)} Pre-Certification Steerage Recommendations</Text>
                                </li>
                            )}
                            {isMskVisible && (
                                <li className='reporting-list__item' data-testid='msk-list-item'>
                                    <Text>{getDelimitedNum(mskEnrollmentsCount)} MSK Therapy Program Enrollments</Text>
                                </li>
                            )}
                        </ul>

                        <Text>Contact the Customer Success Team for ideas on how to increase utilization.</Text>
                    </Column>
                </Row>
            </ContentSection>

            {!isEmpty(info) && (
                <MemberServiceUtilization info={info}
                    isTelemedVisible={isTelemedVisible}
                    isTeladocVisible={isTeladocVisible}
                    isTeladocGeneralMedicalVisible={isTeladocGeneralMedicalVisible}
                    isTeladocMentalHealthVisible={isTeladocMentalHealthVisible}
                    isMedicalBillVisible={isMedicalBillVisible}
                    isPreCertificationVisible={isPreCertificationVisible}
                    isMskVisible={isMskVisible}
                    isFindCareVisible={isFindCareVisible}/>
            )}
        </div>
    );
};

Utilization.propTypes = {
    isTelemedVisible: PropTypes.bool,
    isTeladocVisible: PropTypes.bool,
    isTeladocGeneralMedicalVisible: PropTypes.bool,
    isTeladocMentalHealthVisible: PropTypes.bool,
    isMedicalBillVisible: PropTypes.bool,
    isPreCertificationVisible: PropTypes.bool,
    isMskVisible: PropTypes.bool,
    isFindCareVisible: PropTypes.bool,
    info: PropTypes.shape({
        eligibleEmployeesCount: PropTypes.number,
        utilizationTicketsCount: PropTypes.number,
        walletViewsCount: PropTypes.number,
        serviceRequestsCount: PropTypes.number,
        telemedicineConsultsCount: PropTypes.number,
        teladocGeneralMedicalConsultsCount: PropTypes.number,
        teladocMentalHealthConsultsCount: PropTypes.number,
        providerRecommendationsCount: PropTypes.number,
        appointmentsCount: PropTypes.number,
        facilityRecommendationsCount: PropTypes.number,
        findCareSearchesCount: PropTypes.number,
        rxReviewsCount: PropTypes.number,
        medicalBillReviewsCount: PropTypes.number,
        preCertificationCasesCount: PropTypes.number,
        mskEnrollmentsCount: PropTypes.number,
        benefitsSupportCount: PropTypes.number,
        memberUtilizationChartData: PropTypes.shape({})
    })
};

export {Utilization as TestableUtilization};
export default withReportingInfo({isDisclaimerShowed: true})(Utilization);
