import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {formatDate, getEqual, equal} from '../../../utils';
import {ELIGIBLE_OPTIONS, ENROLMENT_SOURCE_OPTIONS} from '../../../options';
import './index.scss';

const fieldPlaceholder = <Text type={TEXT_TYPES.body}>Not Entered</Text>;

const EligibilityDetailsSummary = ({eligibilityInfo}) => {
    const isEmployerNavigator = equal(eligibilityInfo?.enrollment_source, 'employee_navigator');
    const {label: enrollmentSource} = ENROLMENT_SOURCE_OPTIONS.find(getEqual(eligibilityInfo?.enrollment_source, 'value')) || {};
    const {label: hjEligible} = ELIGIBLE_OPTIONS.find(getEqual(eligibilityInfo?.hj_eligible, 'value')) || {};

    return (
        <Row className='eligibility-details-summary'>
            <Column sm={6}>
                <Row className='eligibility-details-summary__item' sm={6}>
                    <Column sm={6}><Text>How do you collect employee enrollment information?</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{enrollmentSource}</Text></Column>
                </Row>

                {!isEmployerNavigator && (
                    <Row className='eligibility-details-summary__item' sm={6}>
                        <Column sm={6}><Text>Which system?</Text></Column>
                        <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{eligibilityInfo.enrollment_system}</Text></Column>
                    </Row>
                )}

                <Row className='eligibility-details-summary__item' sm={6}>
                    <Column sm={6}><Text>Who will be eligible for HealthJoy?</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{hjEligible || fieldPlaceholder}</Text></Column>
                </Row>

                <Row className='eligibility-details-summary__item' sm={6}>
                    <Column sm={6}><Text>Will employees on COBRA also get HealthJoy?</Text></Column>
                    <Column sm={6}><Text type={TEXT_TYPES.bodyBold}>{eligibilityInfo.cobra_enroll ? 'Yes' : 'No'}</Text></Column>
                </Row>
            </Column>

            <Column sm={6}>
                <Row className='eligibility-details-summary__item' sm={6}>
                    <Column sm={6}><Text>Open Enrollment End Date:</Text></Column>
                    <Column sm={6}>
                        <Text type={TEXT_TYPES.bodyBold}>
                            {formatDate(eligibilityInfo.open_enrollment_due_date, 'M/d/yyyy') || fieldPlaceholder}
                        </Text>
                    </Column>
                </Row>

                <Row className='eligibility-details-summary__item' sm={6}>
                    <Column sm={6}><Text>When do you expect to be able to send a clean eligibility file?</Text></Column>
                    <Column sm={6}>
                        <Text type={TEXT_TYPES.bodyBold}>
                            {formatDate(eligibilityInfo.date_clean_eligibility_file, 'M/d/yyyy') || fieldPlaceholder}
                        </Text>
                    </Column>
                </Row>

                <Row className='eligibility-details-summary__item' sm={6}>
                    <Column sm={6}><Text>Approximately what % of employees have an email or cell phone listed in the eligibility file?</Text></Column>
                    <Column sm={6}>
                        <Text type={TEXT_TYPES.bodyBold}>
                            {eligibilityInfo.employees_have_email ? `${eligibilityInfo.employees_have_email}%` : fieldPlaceholder}
                        </Text>
                    </Column>
                </Row>
            </Column>
        </Row>
    );
};

EligibilityDetailsSummary.propTypes = {
    eligibilityInfo: PropTypes.object
};

export {EligibilityDetailsSummary as TestableEligibilityDetailsSummary};
export default React.memo(EligibilityDetailsSummary);
