import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const MainWrapper = ({children}) => <div className='main-wrapper'>{children}</div>;

MainWrapper.propTypes = {
    children: PropTypes.node
};

export {MainWrapper as TestableMainWrapper};
export default React.memo(MainWrapper);
