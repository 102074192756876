import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import './index.scss';

const LabeledTooltip = ({title, content, initiator, direction, ...props}) => (
    <React.Fragment>
        <span className='labeled-tooltip-title title'>{title}</span>
        {content && (
            <Tooltip content={content} direction={direction} {...props}>
                {initiator || <Icon className='labeled-tooltip-icon tooltip-info-icon' type={ICON_TYPES.info}/>}
            </Tooltip>
        )}
    </React.Fragment>
);

LabeledTooltip.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    initiator: PropTypes.string,
    direction: PropTypes.string
};

export {LabeledTooltip as TestableLabeledTooltip};
export default React.memo(LabeledTooltip);
