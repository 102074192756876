import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import {equal, splitIntoParts} from '../../../../utils';
import './index.scss';

const SHOWING_CLIENTS_COUNT = 20;

const SFTPAffectableClients = ({clients}) => {
    const [showedClientsCount, setShowedClientsCount] = useState(Math.min(clients.length, SHOWING_CLIENTS_COUNT));
    const columnedClients = splitIntoParts(clients.slice(0, showedClientsCount));

    const onClickViewMore = () => {
        const actualShowedClientsLength = Math.min(clients.length, showedClientsCount + SHOWING_CLIENTS_COUNT);

        setShowedClientsCount(actualShowedClientsLength);
    };

    return (
        <div className='sftp-affectable-clients'>
            <Row>
                {columnedClients.map((columnClients, index) => {
                    return (
                        <Column key={index} sm={6}>
                            <ul className='clients-list' data-testid='client-list'>
                                {columnClients.map((client, index) => (
                                    <li key={index} data-testid='client-list-item'>
                                        <Text>{client}</Text>
                                    </li>
                                ))}
                            </ul>
                        </Column>
                    );
                })}
            </Row>

            {!equal(clients.length, showedClientsCount) && (
                <Button type={BUTTON_TYPES.tertiary} onClick={onClickViewMore} data-testid='view-more-button'>
                    view 20 more
                </Button>
            )}
        </div>
    );
};

SFTPAffectableClients.propTypes = {
    clients: PropTypes.arrayOf(PropTypes.string).isRequired
};

export {SFTPAffectableClients as TestableSFTPAffectableClients};
export default React.memo(SFTPAffectableClients);
