import React from 'react';
import PropTypes from 'prop-types';
import PageContent from '../PageContent';
import PdfText from '../PdfText';

const Glossary = ({isTelemedVisible, isMedicalBillVisible}) => (
    <PageContent title='4.0 Glossary' data-testid='glossary'>
        {isTelemedVisible && (
            <PdfText data-testid='telemed-utilization-section'>
                <PdfText type='body-bold' isInline>Annualized Telemedicine Utilization</PdfText> -
                Calculated for the current contract year by multiplying the daily weighted average of telemedicine consults by 365.
                For instance, if a company with 1,000 eligible employees on day 1 has 10 telemedicine consults and that
                same company has 1,010 eligible employees on day 2 with 2 telemedicine consults,
                the weighted average of the 2 days would be 0.597% ((10+2)/(1000+1010)), and the annualized rate would be 218% (0.597% x 365).
            </PdfText>
        )}

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Appointment Scheduling</PdfText> - Count of total appointments scheduled.
            These can include doctor visits, imaging, procedures and surgery.
        </PdfText>

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Benefit Support Interactions</PdfText> -
            Count of total unique calls, chats, and messages with our concierge team to support with general benefits questions.
        </PdfText>

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Employee Activation</PdfText> -
            Calculated on the number of distinct activated HealthJoy-eligible employees, divided by the total number of distinct HealthJoy-eligible employees.
            An employee is activated when they have registered for their HealthJoy account online or by calling our concierge team.
        </PdfText>

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Facility Guidance</PdfText> - Count of total facility requests completed.
            These can include facilities from MRI’s to procedures and surgery.
        </PdfText>

        {isMedicalBillVisible && (
            <React.Fragment>
                <PdfText className='mt-small' data-testid='mbr-section'>
                    <PdfText type='body-bold' isInline>Medical Bill Reviews</PdfText> -
                    Count of Medical Bill Review requests completed claims advocacy can take months to resolve so there can be more bills in process than are reflected here.
                </PdfText>

                <PdfText className='mt-small' data-testid='mbr-savings-section'>
                    <PdfText type='body-bold' isInline>Medical Bill Reviews Savings</PdfText> -
                    Calculated as $689 per medical bill, an average dollar savings value. Total Medical Bill Review savings is the sum of all of the savings from each review.
                </PdfText>
            </React.Fragment>
        )}
    </PageContent>
);

Glossary.propTypes = {
    isTelemedVisible: PropTypes.bool,
    isMedicalBillVisible: PropTypes.bool
};

export {Glossary as TestableGlossary};
export default Glossary;
