import React from 'react';
import classnames from 'classnames';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import CardPreviewPopup from '../CardPreviewPopup';
import useFormState from '../../../../hooks/useFormState';
import './index.scss';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import withPopup from '../../../../HOC/withPopup';

const POPUP_ID = 'cardPreviewPopup';

const CardPreview = ({openPopup}) => {
    const {values} = useFormState();

    const onShowCardPreviewPopup = () => openPopup({type: POPUP_TYPES.simple, children: <CardPreviewPopup/>});

    return (
        <div className='card-preview'>
            <Text className='mb-6'>In-app preview of Health Recommendation card:</Text>

            <div className={classnames('card-preview__container mb-6', {
                'without-image': !values?.background?.image_url,
                'without-cta': !values?.cta?.is_enabled
            })}
                onClick={onShowCardPreviewPopup}
                style={{
                    backgroundColor: values?.background?.color,
                    color: values?.title?.color
                }}>

                {values?.background?.image_url && (
                    <img data-testid='preview-image' className='card-preview__background-image' src={values.background.image_url} alt='card-background'/>
                )}

                {(values?.category?.text || values?.label?.text) && (
                    <div data-testid='preview-header' className='card-preview__header'>
                        <span>{values?.category?.text}</span>

                        {values?.label?.is_enabled && (
                            <span data-testid='preview-header-label'
                                className='preview-header-label'
                                style={{
                                    color: values?.label?.color,
                                    backgroundColor: values?.label?.background_color
                                }}>
                                {values?.label?.text}
                            </span>
                        )}
                    </div>
                )}

                <p className='card-preview__title'>{values?.title?.text}</p>
                <p>{values?.description?.text}</p>
                {values?.cta?.is_enabled && (
                    <span data-testid='preview-cta-label' className='card-preview__cta' style={{color: values?.cta?.color}}>{values?.cta?.text}</span>
                )}
            </div>

            <Text className='mb-6' type={TEXT_TYPES.helper}>Click on the preview image to see different in-app font size view</Text>
        </div>
    );
};

export {CardPreview as TestableCardPreview};
export default withPopup(POPUP_ID)(CardPreview);
