import {
    RECEIVE_ELIGIBILITY_FILES_INFO_LIST,
    RECEIVE_ELIGIBILITY_BEN_ADMIN_LIST,
    RECEIVE_ELIGIBILITY_PLANS,
    RECEIVE_EXPORTED_FILES,
    SET_STARTED_FILE_EXPORT_IDS
} from '../actions/actionTypes';
import {LOCAL_STORAGE_ITEMS} from '../constants';

export const initialState = {
    filesInfoList: [],
    benAdminList: [],
    plans: {
        medical: [],
        dental: [],
        vision: []
    },
    startedFileExportIds: JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.startedEligibilityFileExportIds)) ?? [], // FYI: list of export ids (22.02.2022, Oleh)
    exportedFiles: []
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case RECEIVE_ELIGIBILITY_PLANS: {
            const {plans} = payload;

            return {...state, plans};
        }

        case RECEIVE_ELIGIBILITY_FILES_INFO_LIST: {
            const {filesInfoList} = payload;

            return {...state, filesInfoList};
        }

        case RECEIVE_ELIGIBILITY_BEN_ADMIN_LIST: {
            const {benAdminList} = payload;

            return {...state, benAdminList};
        }

        case SET_STARTED_FILE_EXPORT_IDS: {
            const {startedFileExportIds} = payload;

            return {...state, startedFileExportIds};
        }

        case RECEIVE_EXPORTED_FILES: {
            const {exportedFiles} = payload;

            return {...state, exportedFiles};
        }

        default: {
            return state;
        }
    }
};
