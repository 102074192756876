import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Input from '@frontend/ui-kit/Components/Input';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import {Field} from '../../shared/FormComponents';
import {MBR_ELIGIBILITY_OPTIONS} from '../../../options';
import useFormState from '../../../hooks/useFormState';
import {formatDate} from '../../../utils';

const MedicalBillReview = ({isEditDisabled}) => {
    const {values} = useFormState();

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={4}>
                        <Field name='mbr_availability'>
                            {props => <Select {...props} options={MBR_ELIGIBILITY_OPTIONS} disabled={isEditDisabled} label='Eligibility'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.mbr_effective_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.mbr_expiration_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                </Row>
            </ContentSection>
        </div>
    );
};

MedicalBillReview.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {MedicalBillReview as TestableMedicalBillReview};
export default React.memo(MedicalBillReview);
