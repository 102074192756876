import React from 'react';
import PropTypes from 'prop-types';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Link from '@frontend/ui-kit/Components/Link';
import {PLAN_PERIOD_TYPES, ROUTES} from '../../../constants';
import {formatDate, getEqual} from '../../../utils';
import './index.scss';

const PERIOD_NAMES_MAP = {
    [PLAN_PERIOD_TYPES.launch]: 'current',
    [PLAN_PERIOD_TYPES.inService]: 'current',
    [PLAN_PERIOD_TYPES.renewal]: 'renewal',
    [PLAN_PERIOD_TYPES.past]: ''
};

const DEFAULT_DESCRIPTION_CTA = 'To make changes, simply edit the plan information you provided in previous steps.';

const DESCRIPTION_CTA_MAP = {
    [PLAN_PERIOD_TYPES.launch]: DEFAULT_DESCRIPTION_CTA,
    [PLAN_PERIOD_TYPES.inService]: 'To preview the renewal plan year information, click the Renewal Preview button.',
    [PLAN_PERIOD_TYPES.renewal]: 'To preview the current plan year information, click the Current Year Preview button.',
    [PLAN_PERIOD_TYPES.past]: 'This is historical, view-only information and is not visible to members in the app.'
};

const PreviewPlanInfoAlert = props => {
    const {isCompanyRenewing, planPeriod, planPeriods} = props;
    const {status: periodStatus, start_date: periodStartDate} = planPeriod;

    const [isRenewalSelected, isCurrentYearSelected] = [PLAN_PERIOD_TYPES.renewal, PLAN_PERIOD_TYPES.inService].map(getEqual(periodStatus));
    const formattedStartDate = formatDate(periodStartDate, 'yyyy');
    const periodName = PERIOD_NAMES_MAP[periodStatus] ?? '';

    const title = `${formattedStartDate} ${periodName} PLAN INFORMATION`;
    const descriptionCTA = (isCurrentYearSelected && !isCompanyRenewing) ? DEFAULT_DESCRIPTION_CTA : DESCRIPTION_CTA_MAP[periodStatus] ?? DEFAULT_DESCRIPTION_CTA;
    const description = `The benefits information in this preview belongs to the ${formattedStartDate} ${periodName} plan year. ${descriptionCTA}`;

    const isPreviewLink = isRenewalSelected || (isCurrentYearSelected && isCompanyRenewing);
    const previewLinkText = `${isRenewalSelected ? 'Current Year' : 'Renewal'} Preview`;
    const {id: previewLinkPeriodId} = planPeriods.find(getEqual(isRenewalSelected ? PLAN_PERIOD_TYPES.inService : PLAN_PERIOD_TYPES.renewal, 'status')) || {};

    return (
        <Alert className='preview-plan-info-alert' type={ALERT_TYPES.info} title={title} description={description}>
            {isPreviewLink && <Link className='preview-plan-info-alert__link' href={`${ROUTES.benefits}/${previewLinkPeriodId}/preview`} isButton>{previewLinkText}</Link>}
        </Alert>
    );
};

PreviewPlanInfoAlert.propTypes = {
    planPeriod: PropTypes.shape({
        start_date: PropTypes.string,
        status: PropTypes.string
    }),
    planPeriods: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number
    })),
    isCompanyRenewing: PropTypes.bool
};

export {PreviewPlanInfoAlert as TestablePreviewPlanInfoAlert};
export default React.memo(PreviewPlanInfoAlert);
