import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';

export const getItems = () => {
    return [
        {
            title: 'Plan\nManager',
            icon: ICON_TYPES.clipboard,
            path: 'admin_portal/plan_manager'
        },
        {
            title: 'Carrier\nManager',
            icon: ICON_TYPES.emergency,
            path: 'admin_portal/carrier_manager'
        },
        {
            title: 'Timeline',
            icon: ICON_TYPES.timer,
            path: 'admin_portal/timeline'
        },
        {
            title: 'App\nConfigurations',
            icon: ICON_TYPES.settings,
            path: 'admin_portal/app_configurations'
        },
        {
            title: 'Recommendations\nManager',
            icon: ICON_TYPES.edit,
            path: 'admin_portal/recommendations_manager'
        },
        {
            title: 'Eligibility\nImports',
            icon: ICON_TYPES.eligibility,
            path: 'admin_portal/eligibility_imports'
        }
    ];
};
