import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading from '@frontend/ui-kit/Components/Heading';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import StepProgressPopup from '../../Containers/shared/StepProgressPopup';
import CongratulationPopup from '../../Components/shared/CongratulationPopup';
import withPopup from '../withPopup';
import {redirectTo, requestTimelineStepUpdating} from '../../actions/general';
import {getProcessedTimelineSubStep} from '../../selectors/general';
import {compose} from '../../utils';
import {ROUTES, TIMELINE_STEP_STATUSES} from '../../constants';
import './index.scss';

const POPUP_ID = 'stepProgressPopup';

const withSubStepCompletion = Component => {
    const WithSubStepCompletion = props => {
        const dispatch = useDispatch();
        const processedSubStep = useSelector(getProcessedTimelineSubStep);
        const {openPopup, closePopup} = props;

        const onRedirectTo = route => dispatch(redirectTo(route));

        const onOpenStepProgressPopup = async subStepId => {
            const {isSuccess: isSubStepCompleted} = await dispatch(requestTimelineStepUpdating(subStepId, {status: TIMELINE_STEP_STATUSES.completed}));
            const popupProps = {onClose: closePopup, subStepId, isSubStepCompleted};
            const children = <StepProgressPopup {...popupProps}/>;

            openPopup({type: POPUP_TYPES.fullscreen, children});
        };

        const onOpenExtraSubStepProgressPopup = () => {
            const onGoToRoot = () => {
                closePopup();
                onRedirectTo(ROUTES.root);
            };

            const actionBar = <Button className='extra-sub-step-progress-popup__button' type={BUTTON_TYPES.secondary} onClick={onGoToRoot}>Take me home</Button>;

            const children = (
                <CongratulationPopup className='extra-sub-step-progress-popup' actionBar={actionBar}>
                    <Heading>You’ve entered all the info we need at this point. Nice job!</Heading>
                </CongratulationPopup>
            );

            openPopup({type: POPUP_TYPES.fullscreen, children});
        };

        const onComplete = () => {
            const {id: subStepId} = processedSubStep || {};

            return subStepId ? onOpenStepProgressPopup(subStepId) : onOpenExtraSubStepProgressPopup(); // TODO: add test for existing subStepId (Pasha, 8.04.2021)
        };

        const componentProps = {
            ...props,
            redirectTo: onRedirectTo,
            processedSubStep,
            onComplete
        };

        return <Component {...componentProps}/>;
    };

    WithSubStepCompletion.propTypes = {
        openPopup: PropTypes.func,
        closePopup: PropTypes.func
    };

    return WithSubStepCompletion;
};

export {withSubStepCompletion as testableWithSubStepCompletion};
export default compose(
    withPopup(POPUP_ID),
    withSubStepCompletion
);
