import React from 'react';
import Layout from '../../Components/Authorization/Layout';
import Loader from '../../Containers/shared/Loader';

const withAuthorizationLayout = Component => {
    return props => (
        <React.Fragment>
            <Loader/>

            <Layout>
                <Component {...props}/>
            </Layout>
        </React.Fragment>
    );
};

export {withAuthorizationLayout as testableWithAuthorizationLayout};
export default withAuthorizationLayout;
