import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import GlobalErrorMessage from '@frontend/ui-kit/Components/GlobalErrorMessage';
import {Form, Field} from '../../shared/FormComponents';
import {requestAdminInvitation} from '../../../actions/home';
import {validateRequired, validateEmail, getRegisteredFieldsValues, formatDate} from '../../../utils';
import {FORM_GLOBAL_ERROR} from '../../../constants';
import {USER_TYPE_OPTIONS} from '../../../options';
import './index.scss';

/* istanbul ignore next */
const validate = values => ({
    first_name: validateRequired(values.first_name),
    last_name: validateRequired(values.last_name),
    email: validateEmail(values.email),
    user_type: validateRequired(values.user_type)
});

const AdminContactForm = ({name, initialAdminContact}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState(initialAdminContact);

    const onSubmit = useCallback(async (values, form) => {
        const registeredFieldsValues = getRegisteredFieldsValues(form.getRegisteredFields(), values);
        const {isSuccess, invitedContact, submissionErrors, submissionGlobalError} = await dispatch(requestAdminInvitation(registeredFieldsValues));

        if (!isSuccess) {
            return {[FORM_GLOBAL_ERROR]: submissionGlobalError, ...submissionErrors};
        }

        setInitialValues(invitedContact);
    }, [dispatch]);

    return (
        <Form name={name} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, values, submitError, valid: isValid, dirtySinceLastSubmit: isDirtySinceLastSubmit}) => {
                const {last_invited_at: latestInvite} = values;

                return (
                    <form className='admin-contact-form' onSubmit={handleSubmit} noValidate>
                        <Row>
                            <Column sm={4}>
                                <Field name='id'>{props => <Input {...props} type='hidden'/>}</Field>

                                <Field name='first_name'>
                                    {props => <Input {...props} placeholder='Enter first name...' label='First Name' wrapperClassName='mb-12'/>}
                                </Field>

                                <Field name='email'>
                                    {props => <Input {...props} disabled={latestInvite} type='email' icon={ICON_TYPES.message} placeholder='Enter your email address...' label='Email'/>}
                                </Field>
                            </Column>

                            <Column sm={4}>
                                <Field name='last_name'>
                                    {props => <Input {...props} placeholder='Enter last name...' label='Last Name' wrapperClassName='mb-12'/>}
                                </Field>

                                <Field name='user_type'>
                                    {props => <Select {...props} disabled={latestInvite} options={USER_TYPE_OPTIONS} label='Contact Type'/>}
                                </Field>
                            </Column>

                            <Column className='admin-contact-form-info' sm={4}>
                                {latestInvite && (
                                    <div className='admin-contact-form-invitation'>
                                        <Icon className='admin-contact-form-invitation__icon' type={ICON_TYPES.confirm}/>
                                        <Text type={TEXT_TYPES.bodyBold}>Invited on {formatDate(latestInvite, 'MM/dd/yyyy')}</Text>
                                    </div>
                                )}

                                <Button className='admin-contact-form-info__button' type={BUTTON_TYPES.primary} disabled={!isValid && !isDirtySinceLastSubmit} isSubmit>{latestInvite ? 'Re-invite admin' : 'Invite admin'}</Button>

                                {submitError && <GlobalErrorMessage>{submitError}</GlobalErrorMessage>}
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

AdminContactForm.propTypes = {
    name: PropTypes.string.isRequired,
    initialAdminContact: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        email: PropTypes.string,
        last_name: PropTypes.string,
        user_type: PropTypes.string,
        last_invited_at: PropTypes.string
    })
};

export {AdminContactForm as TestableAdminContactForm};
export default AdminContactForm;
