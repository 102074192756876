import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import PhonePreview from '@frontend/ui-kit/Components/PhonePreview';
import Row from '@frontend/ui-kit/Components/Row';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Column from '@frontend/ui-kit/Components/Column';
import {getActiveCompanyName} from '../../../../selectors/general';
import {formatDate, isEmpty, equal, getFileFormat, getEqual, getFileName} from '../../../../utils';
import flagIcon from '../../../../static/images/icons/flag.svg';
import globeIcon from '../../../../static/images/icons/globe.svg';
import {BROADCASTER_LINK_TYPES} from '../../../../constants';

import broadcasterMessagePreview from '../../../../static/images/broadcasterMessagePreview.svg';

const BroadcastPreview = ({messages, link, linkType, walletLinks}) => {
    const companyName = useSelector(getActiveCompanyName);
    const isEmptyMessages = isEmpty(messages?.filter(Boolean));

    return (
        <PhonePreview title='Chat'>
            <div className='screen'>
                {!isEmptyMessages && (
                    <div className='company-info'>
                        <div className='company-logo'>
                            <img src={flagIcon} alt='company logo'/>
                        </div>
                        <div className='company-info__title'>{companyName}</div>
                    </div>
                )}
                <Row className='preview-useful-screen'>
                    {isEmptyMessages ? (
                        <div className='empty-message-container'>
                            <div>
                                <img src={broadcasterMessagePreview} alt='no messages'/>
                            </div>
                            <div className='title'>Message preview</div>
                            <div className='subtitle'>All entered data will be generated here for preview</div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <Column sm={3} className='company-logo-container'>
                                <div className='company-logo'>
                                    <img src={flagIcon} alt='company logo'/>
                                </div>
                            </Column>
                            <Column sm={9} className='messages-scroll'>
                                <div className='messages-container'>
                                    {(messages || []).map(message => (
                                        <div className='message'>
                                            <div>{message}</div>
                                            <div className='message-time'>{formatDate(new Date(), 'h:mm a')}</div>
                                        </div>
                                    ))}
                                    {link && equal(linkType, BROADCASTER_LINK_TYPES.wallet) && (
                                        <div className='message message__wallet'>
                                            <div className='text-wrapper'>
                                                <img src={globeIcon} alt='wallet'/>
                                                <div>
                                                    <div>{walletLinks.find(getEqual(link, 'link'))?.name}</div>
                                                    <div className='subtext'>tap to view</div>
                                                </div>
                                            </div>
                                            <Icon type={ICON_TYPES.chevronRight}/>
                                        </div>
                                    )}
                                    {link && equal(linkType, BROADCASTER_LINK_TYPES.web) && (
                                        <div className='message'>
                                            <div><a href={link} target='_blank' rel='noreferrer'>{link}</a></div>
                                            <div className='message-time'>{formatDate(new Date(), 'h:mm a')}</div>
                                        </div>
                                    )}
                                    {link && equal(linkType, BROADCASTER_LINK_TYPES.file) && (
                                        <div className='message'>
                                            <div className='file-wrapper'>
                                                <div className='file-type'>{getFileFormat(link)}</div>
                                                <div className='file-name'>{getFileName(link)}</div>
                                            </div>
                                            <div className='message-time'>{formatDate(new Date(), 'h:mm a')}</div>
                                        </div>
                                    )}
                                </div>
                            </Column>
                        </React.Fragment>
                    )}
                </Row>
            </div>
        </PhonePreview>
    );
};

BroadcastPreview.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.string,
    linkType: PropTypes.string,
    walletLinks: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string
    }))
};

export default BroadcastPreview;
