import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import ActionButton from '../../../Containers/shared/ActionButton';
import StickyActionSection from '../../shared/StickyActionSection';
import CarrierInfo, {validate as validateCarrierInfo} from '../CarrierInfo';
import {Form} from '../../shared/FormComponents';
import {redirectTo} from '../../../actions/general';
import {requestCarrierManagerCarrier, requestCarrierManagerCarrierUpdating} from '../../../actions/adminPortal';
import {FORMS, ROUTES} from '../../../constants';
import './index.scss';

/* istanbul ignore next */
const validate = values => ({
    ...validateCarrierInfo(values)
});

const CarrierManagerDetailsForm = () => {
    const [initialValues, setInitialValues] = useState({});
    const [isGoToCarriersList, setIsGoToCarriersList] = useState(false);
    const {id: carrierId} = useParams();
    const dispatch = useDispatch();

    const onGoToCarriersList = useCallback(() => dispatch(redirectTo(ROUTES.carrierManager)), [dispatch]);

    useEffect(() => {
        (async () => {
            const {data, isSuccess} = await dispatch(requestCarrierManagerCarrier(carrierId));

            if (!isSuccess) {
                onGoToCarriersList();
            }

            setInitialValues(data);
        })();
    }, [carrierId, dispatch, onGoToCarriersList]);

    const onSubmit = useCallback(async values => {
        const {data, isSuccess, submissionErrors} = await dispatch(requestCarrierManagerCarrierUpdating(carrierId, values));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(data);
    }, [dispatch, carrierId]);

    const onSubmitSuccess = useCallback(() => {
        if (!isGoToCarriersList) {
            return false;
        }

        onGoToCarriersList();
    }, [isGoToCarriersList, onGoToCarriersList]);

    return (
        <Form name={FORMS.carrierManagerDetails} initialValues={initialValues} validate={validate} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit}) => {
                const onSubmitExit = () => {
                    setIsGoToCarriersList(true);
                    handleSubmit();
                };

                const actionButtonOptions = [
                    {label: 'Save and Continue Editing', action: handleSubmit},
                    {label: 'Save and Exit to Carrier List', action: onSubmitExit}
                ];

                const actionBar = (
                    <React.Fragment>
                        <Button type={BUTTON_TYPES.secondary} onClick={onGoToCarriersList}>Cancel</Button>
                        <ActionButton options={actionButtonOptions}>Save</ActionButton>
                    </React.Fragment>
                );

                return (
                    <form>
                        <Row className='carrier-manager-details'>
                            <Column sm>
                                <ContentSection>
                                    <Heading className='carrier-manager-details__title'>General Information</Heading>

                                    <CarrierInfo isIssuerId/>
                                </ContentSection>
                            </Column>

                            <Column constant>
                                <StickyActionSection title='Edit Details Carrier' actionBar={actionBar}>
                                    <Text>Use the fields on this page to modify details about this carrier. Once you are finished, remember to save your changes.</Text>
                                </StickyActionSection>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

export {CarrierManagerDetailsForm as TestableCarrierManagerDetailsForm};
export default CarrierManagerDetailsForm;
