import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import RenewalEligibilityInfoAlert from '../RenewalEligibilityInfoAlert';
import {getPlanPeriodTypeCriteria} from '../../../selectors/general';
import {PLAN_PERIOD_TYPES} from '../../../constants';
import './index.scss';

const OverviewStepHeader = () => {
    const {planPeriodId} = useParams();
    const isRenewalPlanPeriod = useSelector(state => getPlanPeriodTypeCriteria(state, planPeriodId, PLAN_PERIOD_TYPES.renewal));

    return (
        <div className='overview-step-header'>
            {isRenewalPlanPeriod && <RenewalEligibilityInfoAlert className='overview-step-header__alert'/>}
        </div>
    );
};

export {OverviewStepHeader as TestableOverviewStepHeader};
export default OverviewStepHeader;
