import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import VersionHistoryPopup from '../../../Containers/shared/VersionHistoryPopup';
import {requestVersionHistoryDetails, requestVersionHistoryList} from '../../../actions/general';
import {getActiveCompany} from '../../../selectors/general';

const COMPANY_VERSION_HISTORY_POPUP_DESCRIPTION = 'Keep track of what changes have been made in the HealthJoy Dashboard. View the differences between various versions and see who made changes, when they were made, and what exactly was edited.';

const FIELD_LABELS_MAP = {
    name: 'Company Name',
    pbm: 'Pharmacy Benefit Manager (PBM)',
    phone_number: 'Phone Number',
    address: 'Address',
    funding_structure: 'Funding Structure',
    renewal_date: 'Renewal date',
    tpa: 'TPA or Carrier',
    is_existing_carrier: 'Existing carrier',
    industry_id: 'Industry',
    logo: 'Upload Company Logo',
    reasons: 'Reasons'
};

const CompanyInfoVersionHistoryPopup = ({closePopup}) => {
    const dispatch = useDispatch();
    const activeCompany = useSelector(getActiveCompany);

    const [selectedVersion, setSelectedVersion] = useState(null);
    const [versionHistoryDetailsMap, setVersionHistoryDetailsMap] = useState({});
    const [versionHistoryList = [], setVersionHistoryList] = useState([]);
    const getVersionHistoryList = ({versionHistoryId, versionHistoryType}) => dispatch(requestVersionHistoryList({versionHistoryId, versionHistoryType}));
    const getVersionHistoryDetails = versionId => dispatch(requestVersionHistoryDetails(versionId));

    const getNormalizedVersionHistoryDetails = versionHistoryDetails => {
        const {diffs} = versionHistoryDetails;
        const {added = [], edited = [], deleted = []} = diffs ?? {};
        const normalizeDiff = diff => diff.map(diffElement => ({...diffElement, label: FIELD_LABELS_MAP[diffElement.field] ?? ''}));
        const [normalizedAdded, normalizedEdited, normalizedDeleted] = [added, edited, deleted].map(normalizeDiff);

        return {...versionHistoryDetails, diffs: {...diffs, added: normalizedAdded, edited: normalizedEdited, deleted: normalizedDeleted}};
    };

    const onChangeVersion = async versionId => {
        if (versionHistoryDetailsMap[versionId]) {
            setSelectedVersion(versionId);
            return false;
        }

        const versionHistoryDetails = await getVersionHistoryDetails(versionId);
        const normalizedVersionHistoryDetails = getNormalizedVersionHistoryDetails(versionHistoryDetails);

        setSelectedVersion(versionId);
        setVersionHistoryDetailsMap({...versionHistoryDetailsMap, [versionId]: normalizedVersionHistoryDetails});
    };

    useEffect(() => {
        (async () => {
            const {id: activeCompanyId} = activeCompany;
            const versionHistoryList = await getVersionHistoryList({versionHistoryType: 'company', versionHistoryId: activeCompanyId});
            if (!versionHistoryList.length) {
                return;
            }

            const [{id: versionId} = {}] = versionHistoryList;
            const versionHistoryDetails = await getVersionHistoryDetails(versionId);
            const normalizedVersionHistoryDetails = getNormalizedVersionHistoryDetails(versionHistoryDetails);

            setSelectedVersion(versionId);
            setVersionHistoryList(versionHistoryList);
            setVersionHistoryDetailsMap({...versionHistoryDetailsMap, [versionId]: normalizedVersionHistoryDetails});
        })();
    }, []);

    const actionBar = <Button onClick={closePopup} type={BUTTON_TYPES.secondary}>Back to Company Info</Button>;
    const {diffs: versionHistoryDetails} = versionHistoryDetailsMap[selectedVersion] ?? {};

    return (
        <VersionHistoryPopup title='Company Info Version History'
            description={COMPANY_VERSION_HISTORY_POPUP_DESCRIPTION}
            actionBar={actionBar}
            selectedVersion={selectedVersion}
            onChangeVersion={onChangeVersion}
            versionHistoryList={versionHistoryList}
            versionHistoryDetails={versionHistoryDetails}/>
    );
};

CompanyInfoVersionHistoryPopup.propTypes = {
    closePopup: PropTypes.func
};

export {CompanyInfoVersionHistoryPopup as TestableCompanyInfoVersionHistoryPopup};
export default CompanyInfoVersionHistoryPopup;
