import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import SourceStep from '../WizardSteps/SourceStep';
import CoreStep from '../WizardSteps/CoreStep';
import AncillaryStep from '../WizardSteps/AncillaryStep';
import HealthjoyEligibilityStep from '../WizardSteps/HealthjoyEligibilityStep';
import TimingRolloutStep from '../WizardSteps/TimingRolloutStep';
import Wizard from '../../../Containers/shared/Wizard';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {redirectTo} from '../../../actions/general';
import {getProfilePermissions} from '../../../selectors/general';
import {ROUTES} from '../../../constants';
import './index.scss';

const WIZARD_STEPS = [
    {StepComponent: SourceStep, stepName: 'source', stepTitle: 'Source', isStepSkippable: false},
    {StepComponent: CoreStep, stepName: 'core_details', stepTitle: 'Core Details'},
    {StepComponent: AncillaryStep, stepName: 'ancillary_details', stepTitle: 'Ancillary Details'},
    {StepComponent: HealthjoyEligibilityStep, stepName: 'healthjoy_eligibility', stepTitle: 'Healthjoy Eligibility'},
    {StepComponent: TimingRolloutStep, stepName: 'timing_and_ollout', stepTitle: 'Timing & Rollout'}
];

const EligibilityWizard = ({onComplete}) => {
    const dispatch = useDispatch();
    const profilePermissions = useSelector(getProfilePermissions);

    const wizardProps = {
        steps: WIZARD_STEPS,
        isReadonly: !profilePermissions.eligibility_overview_edit,
        onSuccess: profilePermissions.timeline_edit ? onComplete : () => dispatch(redirectTo(ROUTES.root))
    };

    return (
        <div className='eligibility-form'>
            <Wizard {...wizardProps}/>
        </div>
    );
};

EligibilityWizard.propTypes = {
    onComplete: PropTypes.func.isRequired
};

export {EligibilityWizard as TestableEligibilityWizard};
export default withSubStepCompletion(EligibilityWizard);
