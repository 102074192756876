import MedicalPlanForm from './MedicalPlanForm';
import DentalPlanForm from './DentalPlanForm';
import VisionPlanForm from './VisionPlanForm';
import AdditionalPlanInfo from './AdditionalPlanInfo';
import './index.scss';

export default {
    MedicalPlanForm,
    DentalPlanForm,
    VisionPlanForm,
    AdditionalPlanInfo
};
