import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Header from '../../Containers/shared/Header';
import PageLayout from '../../Containers/shared/PageLayout';
import MainWrapper from '../../Components/shared/MainWrapper';
import Sidebar from '../../Containers/shared/Sidebar';
import Loader from '../../Containers/shared/Loader';
import Toast from '../../Components/shared/Toast';
import Zendesk from '../../Containers/shared/Zendesk';
import TermsAndConditionsPopup from '../../Components/shared/TermsAndConditionsPopup';
import withRedirectionStrategy from '../withRedirectionStrategy';
import withNavigationControl from '../withNavigationControl';
import withWorkspaceControl from '../withWorkspaceControl';
import withPopup from '../withPopup';
import {requestBasicData, requestMarketingPages, setStepAlias} from '../../actions/general';
import {getActiveCompany, getProfileInfo} from '../../selectors/general';
import {USER_TYPES, WORKSPACES} from '../../constants';
import {delay, compose, equal, parseQuery} from '../../utils';

const POPUP_ID = 'dashboardLayoutPopup';

const withDashboardLayout = Component => {
    const EnhancedComponent = withRedirectionStrategy(Component);

    const WithDashboardLayout = props => {
        const {stepAlias, openPopup, closePopup} = props;
        const dispatch = useDispatch();
        const {id: activeCompanyId, should_tc_be_signed: shouldTermsAndConditionsBeSigned} = useSelector(getActiveCompany);
        const {id: profileId, user_type: profileType} = useSelector(getProfileInfo);
        const {search, pathname} = useLocation();
        const isLoaded = activeCompanyId && profileId;

        useEffect(() => {
            const {company_id: companyId} = parseQuery(search);

            dispatch(requestBasicData(companyId));
            dispatch(requestMarketingPages());
        }, []);

        useEffect(() => {
            dispatch(setStepAlias(stepAlias));
        }, [dispatch, stepAlias]);

        useEffect(() => {
            if (shouldTermsAndConditionsBeSigned && equal(profileType, USER_TYPES.employee)) {
                // HACK: delay is needed here in order to avoid popup disappearing when user switches active company (because of withRedirectionStrategy and withPopup logic);
                // We have to think over workaround once router version got bumped and/or withRedirectionStrategy and withPopup logic got updated (16.01.2024, Oleh);
                delay(() => {
                    openPopup({type: POPUP_TYPES.fullscreen, children: <TermsAndConditionsPopup onClose={closePopup}/>});
                });
            }
        }, [profileType, shouldTermsAndConditionsBeSigned, pathname, openPopup, closePopup]);

        return (
            <React.Fragment>
                <Loader/>
                <Toast/>

                {isLoaded && (
                    <React.Fragment>
                        <Zendesk/>

                        <Header/>

                        <MainWrapper>
                            <Sidebar/>

                            <PageLayout>
                                { /* FYI: key prop helps to remount component after each relocation */ }
                                <EnhancedComponent key={pathname} {...props}/>
                            </PageLayout>
                        </MainWrapper>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    WithDashboardLayout.propTypes = {
        stepAlias: PropTypes.string,
        openPopup: PropTypes.func.isRequired,
        closePopup: PropTypes.func.isRequired
    };

    return WithDashboardLayout;
};

export {withDashboardLayout as testableWithDashboardLayout};
export default compose(
    withNavigationControl,
    withWorkspaceControl({workspace: WORKSPACES.dashboard}),
    withPopup(POPUP_ID),
    withDashboardLayout
);
