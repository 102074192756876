import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import './index.scss';

const InformationRow = props => {
    const {title, children, placeholder, className} = props;
    const rowClassName = `information-row ${className}`;
    const valueClassName = `information-row__value ${!children ? 'information-row__value_placeholder' : ''}`;

    return (
        <Row className={rowClassName}>
            <Column sm={4}>
                <Text className='information-row__title'>{title}:</Text>
            </Column>

            <Column sm={8}>
                <Text className={valueClassName} type={TEXT_TYPES.bodyBold}>{children || placeholder}</Text>
            </Column>
        </Row>
    );
};

InformationRow.defaultProps = {
    placeholder: 'No data',
    className: ''
};

InformationRow.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string
};

export {InformationRow as TestableInformationRow};
export default InformationRow;
