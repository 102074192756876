import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const ActionPanel = ({children}) => <div className='action-panel'>{children}</div>;

ActionPanel.propTypes = {
    children: PropTypes.node
};

export {ActionPanel as TestableActionPanel};
export default React.memo(ActionPanel);
