import React from 'react';
import PropTypes from 'prop-types';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import ImportCard from '../../ImportCard';
import ImportCardDetails from '../../ImportCardDetails';
import {FAMILY_MANAGEMENT_TYPES} from '../../../../constants';
import {equal} from '../../../../utils';
import './index.scss';

const BASE_MEMBER_STATISTIC_LABELS = {
    added: 'Added',
    updated: 'Updated',
    banned: 'Banned by Current Import (overall)',
    banned_by_omission_in_file: 'Banned by Omission in File',
    banned_in_future: 'Banned in Future',
    restored: 'Restored',
    migrated: 'Migrated'
};

const EMPLOYEES_LABELS = {
    ...BASE_MEMBER_STATISTIC_LABELS,
    from_system_not_processed: 'From System Not Processed',
    from_file_not_processed: 'From File Not Processed',
    cobra_added: 'COBRA Added',
    cobra_banned: 'COBRA Banned'
};

const DEPENDENTS_LABELS = {
    ...BASE_MEMBER_STATISTIC_LABELS,
    not_eligible: 'Not Eligible',
    inherit_core_plans: 'Inherit Core Plans',
    inherit_ancillary_plans: 'Inherit Ancillary Plans',
    inherit_member_ids: 'Inherit Member IDs'
};

const CONTACTS_LABELS = {
    employees_with_valid_email: 'EEs with Valid Email',
    employees_with_invalid_email: 'EEs with Invalid Email',
    employees_percent_with_valid_email: 'Percent with Valid Email',
    employees_with_valid_phone_number: 'EEs with Valid Phone Number',
    employees_with_skipped_phone_number: 'EEs with Skipped Phone Number',
    employees_percent_with_valid_phone_numbers: 'Percent with Valid Phone Number',
    employees_with_neither_phone_nor_email: 'EEs with Neither',
    employees_percent_with_neither_phone_nor_email: 'Percent with Neither',
    dependents_with_valid_email: 'Dependents with Valid Email',
    dependents_with_valid_phone_number: 'Dependents with Valid Phone Number'
};

const COBRA_CONTACTS_LABELS = {
    employees_with_valid_email: 'Cobra EEs with Valid Email Address',
    employees_with_invalid_email: 'Cobra EEs with Invalid Email Address',
    employees_with_valid_phone_number: 'Cobra EEs with Valid Phone Number'
};

const DEMOS_LABELS = {
    converted_from_sales: 'Converted From Sales Demo',
    duplicate_name_match: 'Duplicated Sales Demo Matched'
};

const TOOLTIP_CONTENT_BY_KEY = {
    banned: 'This count include all members who are banned, regardless of the termination reason.',
    banned_by_omission_in_file: 'This count shows only the members banned by omission'
};

const PERCENT_VALUES = ['employees_percent_with_valid_email', 'employees_percent_with_valid_phone_numbers', 'employees_percent_with_neither_phone_nor_email'];

const GeneralMemberStatistics = ({general_statistics: generalStats = {}, import_info: importInfo = {}}) => {
    const generalStatistics = [
        {title: 'Total Records', value: generalStats.total_records_in_file},
        {title: 'Eligible Employee Records', value: generalStats.total_eligible_employees},
        {title: 'Eligible COBRA Employee Records', value: generalStats.total_eligible_cobra_employees},
        {title: 'Eligible Dependent Records', value: generalStats.total_eligible_dependents_records},
        {title: 'Eligible COBRA Dependent Records', value: generalStats.total_eligible_cobra_dependents}
    ];

    const getEnhancedData = (data, labels) => Object.entries(labels).map(([key, label]) => {
        const tooltip = TOOLTIP_CONTENT_BY_KEY[key] && <Tooltip content={TOOLTIP_CONTENT_BY_KEY[key]}><Icon className='ml-2 mt-1 label__icon' type={ICON_TYPES.info}/></Tooltip>;

        return ({
            key: <div className='label'><span className='label__text'>{label}</span>{tooltip}</div>,
            value: `${Math.ceil(data[key]) || 0}${PERCENT_VALUES.includes(key) ? '%' : ''}`
        });
    });

    const memberStatistics = [
        {title: 'Eligible Employees', data: getEnhancedData(generalStats.employees, EMPLOYEES_LABELS)},
        {title: 'Eligible Dependents', data: getEnhancedData(generalStats.dependents, DEPENDENTS_LABELS)},
        {title: 'Contact Information', data: getEnhancedData(generalStats.contact_information, CONTACTS_LABELS)},
        {title: 'COBRA Contact Information', data: getEnhancedData(generalStats.contact_information_cobra, COBRA_CONTACTS_LABELS)},
        {title: 'Demos', data: getEnhancedData(generalStats.demos, DEMOS_LABELS)}
    ];

    return (
        <div className='general-member-statistics'>
            {equal(importInfo?.company_data?.family_management, FAMILY_MANAGEMENT_TYPES.disabled) && (
                <Alert className='mt-10 mb-20' type={ALERT_TYPES.warning} description='Family Management is disabled. There may be discrepancies in member counts because dependents have been filtered out due to this setting.'/>
            )}

            <Row className='mt-10' rowGap='md'>
                {generalStatistics.map(({title, value}) => (
                    <Column key={title} sm={3}>
                        <ImportCard title={title} value={value}/>
                    </Column>
                ))}
            </Row>

            <Separator/>

            <Row rowGap='md'>
                {memberStatistics.map(({title, data}) => (
                    <Column key={title} sm={3}>
                        <ImportCardDetails title={title} data={data}/>
                    </Column>
                ))}
            </Row>
        </div>
    );
};

const BaseMemberStatistic = {
    added: PropTypes.number,
    updated: PropTypes.number,
    banned: PropTypes.number,
    banned_by_omission_in_file: PropTypes.number,
    banned_in_future: PropTypes.number,
    restored: PropTypes.number,
    migrated: PropTypes.number
};

GeneralMemberStatistics.propTypes = {
    import_info: PropTypes.shape({company_data: PropTypes.shape({family_management: PropTypes.string})}),
    general_statistics: PropTypes.shape({
        total_records_in_file: PropTypes.number,
        total_eligible_employees: PropTypes.number,
        total_eligible_cobra_employees: PropTypes.number,
        total_eligible_dependents_records: PropTypes.number,
        total_eligible_cobra_dependents: PropTypes.number,
        employees: PropTypes.shape({
            ...BaseMemberStatistic,
            from_system_not_processed: PropTypes.number,
            from_file_not_processed: PropTypes.number,
            cobra_added: PropTypes.number,
            cobra_banned: PropTypes.number
        }),
        dependents: PropTypes.shape({
            ...BaseMemberStatistic,
            not_eligible: PropTypes.number,
            inherit_core_plans: PropTypes.number,
            inherit_ancillary_plans: PropTypes.number,
            inherit_member_ids: PropTypes.number
        }),
        contact_information: PropTypes.shape({
            employees_with_valid_email: PropTypes.number,
            employees_with_invalid_email: PropTypes.number,
            employees_percent_with_valid_email: PropTypes.number,
            employees_with_valid_phone_number: PropTypes.number,
            employees_with_skipped_phone_number: PropTypes.number,
            employees_percent_with_valid_phone_numbers: PropTypes.number,
            employees_with_neither_phone_nor_email: PropTypes.number,
            employees_percent_with_neither_phone_nor_email: PropTypes.number,
            dependents_with_valid_email: PropTypes.number,
            dependents_with_valid_phone_number: PropTypes.number
        }),
        contact_information_cobra: PropTypes.shape({
            employees_with_valid_email: PropTypes.number,
            employees_with_invalid_email: PropTypes.number,
            employees_with_valid_phone_number: PropTypes.number
        }),
        demos: PropTypes.shape({
            converted_from_sales: PropTypes.number,
            duplicate_name_match: PropTypes.number
        })
    })
};

export {GeneralMemberStatistics as TestableGeneralMemberStatistics};
export default GeneralMemberStatistics;
