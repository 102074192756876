import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {Field} from '../FormComponents';
import {getFormRevisions} from '../../../selectors/shared';
import {negate, getFromObjSafe, isEmpty} from '../../../utils';
import './index.scss';

const RevisionsField = ({children, ...fieldProps}) => {
    const formRevisions = useSelector(getFormRevisions);

    const {originalRevision, comparisonRevisions = []} = formRevisions ?? {};
    const [originalRevisionId, ...comparisonRevisionIds] = useMemo(() => {
        return [originalRevision, ...comparisonRevisions].map(revision => revision?.meta?.id);
    }, [originalRevision, comparisonRevisions]);
    const key = `${originalRevisionId}_${comparisonRevisionIds.join('_')}_${fieldProps.name}`;

    // FYI: comparisonRevisions contain only those fields that have been changed, therefore, to make sure that we have something to show, we check that the field exists in the first revision (Vlad, 18.05.21)
    const areRevisions = negate([null, undefined].includes(getFromObjSafe(fieldProps.name, comparisonRevisions?.[0])));

    return (
        <Field key={key} {...fieldProps}>
            {props => {
                const getRevision = revision => {
                    const {meta: {id, history_version: versionNumber}} = revision;
                    const value = getFromObjSafe(fieldProps.name, revision);
                    const enhancedProps = {...props, value, className: 'revision-field__component', isInfoBar: false, disabled: true};

                    return (
                        <div key={id} className='revision-field'>
                            <Text type={TEXT_TYPES.helperBold} className='revision-field__number'>v.{versionNumber}</Text>
                            {children(enhancedProps)}
                        </div>
                    );
                };

                return areRevisions
                    ? [originalRevision, ...comparisonRevisions].map(getRevision)
                    : children({...props, disabled: !isEmpty(comparisonRevisions)});
            }}
        </Field>
    );
};

RevisionsField.propTypes = {
    children: PropTypes.func.isRequired
};

export {RevisionsField as TestableRevisionsField};
export default React.memo(RevisionsField);
