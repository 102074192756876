import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import Link from '@frontend/ui-kit/Components/Link';
import AreaChart, {AREA_CHART_PALETTE_TYPES} from '@frontend/ui-kit/Components/AreaChart';
import DoughnutChart, {DOUGHNUT_CHART_PALETTE_TYPES} from '@frontend/ui-kit/Components/DoughnutChart';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import BarChart from '@frontend/ui-kit/Components/BarChart';
import {getReportingPlanPeriod} from '../../../selectors/reporting';
import {splitIntoParts, getIncreasedByCoefficient, getEqual, equal, getDelimitedNum, getItemKeyValue} from '../../../utils';
import {REPORTING_PLAN_PERIODS, MEMBER_SERVICE_UTILIZATION_TYPES} from '../../../constants';
import {MEMBER_SERVICE_UTILIZATION_OPTIONS} from '../../../options';
import analyticsService from '../../../analyticsService';
import {TRACKED_SELECTIONS} from '../../../analyticsService/constants';
import './index.scss';

const CONDITIONAL_MEMBER_SERVICE_UTILIZATION_TYPES = [
    MEMBER_SERVICE_UTILIZATION_TYPES.telemedConsults,
    MEMBER_SERVICE_UTILIZATION_TYPES.teladocHealthConsults,
    MEMBER_SERVICE_UTILIZATION_TYPES.mbr,
    MEMBER_SERVICE_UTILIZATION_TYPES.preCertificationSteerage,
    MEMBER_SERVICE_UTILIZATION_TYPES.mskEnrollments
];

const trackOnChangeServiceUtilizationType = serviceUtilizationType => analyticsService.trackToggleSelection(TRACKED_SELECTIONS.serviceUtilizationSelection, serviceUtilizationType);

const MemberServiceUtilization = ({info, isTelemedVisible, isTeladocVisible, isTeladocGeneralMedicalVisible, isTeladocMentalHealthVisible, isMedicalBillVisible, isPreCertificationVisible, isMskVisible, isFindCareVisible}) => {
    const [serviceUtilizationType, setServiceUtilizationType] = useState(MEMBER_SERVICE_UTILIZATION_TYPES.benefitsWalletUtilization);
    const reportingPlanPeriod = useSelector(getReportingPlanPeriod);

    const onChangeServiceUtilizationType = serviceUtilizationType => {
        setServiceUtilizationType(serviceUtilizationType);
        trackOnChangeServiceUtilizationType(serviceUtilizationType);
    };

    const getChartScalesOptions = (rangeCoef, {stacked} = {}) => {
        const {eligibleEmployeesCount} = info || {};
        const suggestedMax = getIncreasedByCoefficient(eligibleEmployeesCount, rangeCoef);

        return {yAxes: [{stacked, ticks: {suggestedMax}}]};
    };

    const COMPONENTS_BY_SERVICE_UTILIZATION_TYPE = {
        [MEMBER_SERVICE_UTILIZATION_TYPES.benefitsSupportUtilization]: [{
            Component: LineChart,
            title: 'Benefits Support Interactions',
            description: 'Count of total unique calls, chats, and messages with our concierge team for support with general benefits questions.',
            data: info.benefitsSupportUtilizationChartData,
            options: {scales: getChartScalesOptions(0.5)}
        }],
        [MEMBER_SERVICE_UTILIZATION_TYPES.benefitsSupportUtilization]: [{
            Component: LineChart,
            title: 'Benefits Support Interactions',
            description: 'Count of total unique calls, chats, and messages with our concierge team for support with general benefits questions.',
            data: info.benefitsSupportUtilizationChartData,
            options: {scales: getChartScalesOptions(0.5)}
        }],
        [MEMBER_SERVICE_UTILIZATION_TYPES.benefitsWalletUtilization]: [{
            Component: LineChart,
            title: 'Benefits Wallet Views',
            description: 'The total number of unique Benefits Wallet views, compared against your total eligible population.',
            data: info.benefitsWalletUtilizationChartData,
            options: {scales: getChartScalesOptions(0.5)}
        }],
        ...isMedicalBillVisible && {
            [MEMBER_SERVICE_UTILIZATION_TYPES.mbr]: [{
                Component: LineChart,
                title: 'Medical Bill Reviews',
                description: `
                    Count of Medical Bill Review requests completed.
                    Complicated claims advocacy can take months to resolve, so there can be more bills in review than are reflected here.
                `,
                data: info.medicalBillReviewUtilizationChartData,
                options: {scales: getChartScalesOptions(0.01)}
            }]
        },
        ...isPreCertificationVisible && {
            [MEMBER_SERVICE_UTILIZATION_TYPES.preCertificationSteerage]: [
                {
                    Component: DoughnutChart,
                    title: 'Total Number of Cases',
                    description: 'The percentage of pre-certification cases reviewed and the total rate of new provider recommendations given out of those cases reviewed.',
                    data: info.preCertificationTotalChartData,
                    paletteType: DOUGHNUT_CHART_PALETTE_TYPES.secondary,
                    options: {
                        title: {text: `${getDelimitedNum(info.preCertificationCasesCount)} Cases`},
                        centerText: {text: `$${getDelimitedNum(info.preCertificationSavings)}\nSavings Opportunity`}
                    }
                },
                {
                    Component: AreaChart,
                    title: 'Total Cases Over Time',
                    data: info.preCertificationChartData,
                    paletteType: AREA_CHART_PALETTE_TYPES.secondary,
                    options: {scales: getChartScalesOptions(0.5, {stacked: true})}
                }
            ]
        },
        ...isMskVisible && {
            [MEMBER_SERVICE_UTILIZATION_TYPES.mskEnrollments]: [{
                Component: LineChart,
                title: 'MSK Therapy Program Enrollments Over Time',
                description: 'Count of total members enrolled in the MSK Therapy program over time.',
                data: info.mskEnrollmentsChartData,
                options: {scales: getChartScalesOptions(0.01)}
            }]
        },
        [MEMBER_SERVICE_UTILIZATION_TYPES.providerFacilityGuidance]: [
            {
                Component: LineChart,
                title: 'Provider Requests',
                description: 'Count of total provider requests completed. Requests can include providers for primary care to surgery.',
                data: info.providerRecommendationUtilizationChartData,
                options: {scales: getChartScalesOptions(0.25)}
            },
            {
                Component: LineChart,
                title: 'Appointment Scheduled',
                description: 'Count of total appointments scheduled. Appointment scheduled can include annual physicals, imaging, procedures, and surgeries.',
                data: info.appointmentUtilizationChartData,
                options: {scales: getChartScalesOptions(0.01)}
            },
            {
                Component: LineChart,
                title: 'Facility Requests',
                description: 'Count of total facility requests completed. Requests can include various facilities for imaging, surgeries, and medical examinations.',
                data: info.facilityRecommendationUtilizationChartData,
                options: {scales: getChartScalesOptions(0.05)}
            },
            isFindCareVisible && {
                Component: LineChart,
                title: 'Find Care Searches',
                description: 'Count of total number of Find Care searches with at least one facility/provider result within network.',
                data: info.findCareSearchUtilizationChartData,
                options: {scales: getChartScalesOptions(0.05)}
            }
        ].filter(Boolean),
        [MEMBER_SERVICE_UTILIZATION_TYPES.rxSavingsReviews]: [{
            Component: LineChart,
            title: 'Rx Savings Requests',
            description: 'Count of total RX Savings requests completed. There can be multiple prescriptions per request.',
            data: info.rxSavingsUtilizationChartData,
            options: {scales: getChartScalesOptions(0.25)}
        }],
        ...isTelemedVisible && {
            [MEMBER_SERVICE_UTILIZATION_TYPES.telemedConsults]: [{
                Component: LineChart,
                title: 'Total MeMD Consults',
                description: 'Count of total Telemedicine consults completed.',
                data: info.telemedUtilizationChartData,
                options: {scales: getChartScalesOptions(0.5)}
            }]
        },
        ...isTeladocVisible && {
            [MEMBER_SERVICE_UTILIZATION_TYPES.teladocHealthConsults]: [
                {
                    Component: BarChart,
                    title: 'Total Teladoc Health Consults',
                    description: 'Count of total Teladoc Health consults completed per service.',
                    isForcedLegend: true,
                    data: info.teladocHealthUtilizationChartData,
                    options: {scales: getChartScalesOptions(0.5)}
                },
                equal(reportingPlanPeriod, REPORTING_PLAN_PERIODS.all) && {
                    Component: () => {
                        const rateMetrics = [
                            isTeladocGeneralMedicalVisible && {title: 'General Medical', value: info.teladocGeneralMedicalAnnualizedRate, target: info.teladocGeneralMedicalAnnualizedTarget},
                            isTeladocMentalHealthVisible && {title: 'Mental Health', value: info.teladocMentalHealthAnnualizedRate, target: info.teladocMentalHealthAnnualizedTarget}
                        ].filter(Boolean);

                        return (
                            <div className='annualized-telemed-rate'>
                                <Heading className='mb-16' type={HEADING_TYPES['5']}>Current Annualized Telemedicine Rate</Heading>

                                {rateMetrics.map(({title, value, target}) => {
                                    return (
                                        <div className='telemed-rate-metric mb-21' key={title}>
                                            <Heading type={HEADING_TYPES['2']} className='telemed-rate-metric__value'>{value}%</Heading>

                                            <div>
                                                <Text className='mb-2' type={TEXT_TYPES.bodyBold}>{title}</Text>
                                                <Text className='telemed-rate-metric__target' type={TEXT_TYPES.caption}>{target}% utilization target</Text>
                                            </div>
                                        </div>
                                    );
                                })}

                                <Text type={TEXT_TYPES.helper}>
                                    Annualized Telemedicine Rate is calculated as the total number of consultations divided by the total number of eligible employees.
                                    This value is then multiplied by 365 to project the annual figure.
                                </Text>
                            </div>
                        );
                    }
                }
            ].filter(Boolean)
        },
        [MEMBER_SERVICE_UTILIZATION_TYPES.totalUtilization]: [{
            Component: LineChart,
            title: 'Total Service Requests',
            description: `
                The total number of service requests that have been completed for your company. Service Requests include
                ${isTelemedVisible ? 'Telemedicine Consults, ' : ''}RX Savings Reviews, Provider and Facility Recommendations,
                Appointment Schedules, ${isMedicalBillVisible ? 'Medical Bill Reviews, ' : ''}and Benefits Questions asked via phone call or chat.
            `,
            data: info.totalServiceRequestsChartData,
            options: {scales: getChartScalesOptions(1.5)}
        }]
    };
    const currentComponents = COMPONENTS_BY_SERVICE_UTILIZATION_TYPE[serviceUtilizationType] || [];
    const currentComponentRows = splitIntoParts(currentComponents, Math.round(currentComponents.length / 2));

    const isSelectOptionByValue = {
        [MEMBER_SERVICE_UTILIZATION_TYPES.telemedConsults]: isTelemedVisible,
        [MEMBER_SERVICE_UTILIZATION_TYPES.teladocHealthConsults]: isTeladocVisible,
        [MEMBER_SERVICE_UTILIZATION_TYPES.mbr]: isMedicalBillVisible,
        [MEMBER_SERVICE_UTILIZATION_TYPES.preCertificationSteerage]: isPreCertificationVisible,
        [MEMBER_SERVICE_UTILIZATION_TYPES.mskEnrollments]: isMskVisible
    };
    const getSelectOptions = ({value}) => !CONDITIONAL_MEMBER_SERVICE_UTILIZATION_TYPES.some(getEqual(value)) || isSelectOptionByValue[value];
    const selectOptions = MEMBER_SERVICE_UTILIZATION_OPTIONS.filter(getSelectOptions);

    return (
        <Row className='member-service-utilization'>
            <Column sm={currentComponents.length > 1 ? 12 : 6}>
                <ContentSection className='reporting-section'>
                    <div className='member-service-utilization-header'>
                        <Heading className='reporting-info-title' type={HEADING_TYPES['5']}>Program Utilization</Heading>

                        <Select className='member-service-utilization-header__select'
                            value={serviceUtilizationType}
                            options={selectOptions}
                            onChange={onChangeServiceUtilizationType}/>
                    </div>

                    {currentComponentRows.map((componentRowItems, index, componentRows) => {
                        const isLast = equal(index, componentRows.length - 1);

                        return (
                            <React.Fragment key={index}>
                                <Row rowGap='xlg' columnGap='xlg'>
                                    {componentRowItems.map(({Component, ...componentProps}, index, componentItems) => {
                                        const isLast = equal(index, componentItems.length - 1);
                                        const className = `reporting-section__column ${!isLast ? 'reporting-section__column_separated' : ''}`;

                                        return (
                                            <Column key={index} sm={currentComponents.length > 1 ? 6 : 12} className={className}>
                                                <div className='reporting-chart'>
                                                    <Component {...componentProps}/>
                                                </div>
                                            </Column>
                                        );
                                    })}
                                </Row>

                                {!isLast && <Separator className='member-service-utilization__separator'/>}
                            </React.Fragment>
                        );
                    })}
                </ContentSection>
            </Column>
        </Row>
    );
};

MemberServiceUtilization.propTypes = {
    isTelemedVisible: PropTypes.bool,
    isTeladocVisible: PropTypes.bool,
    isTeladocGeneralMedicalVisible: PropTypes.bool,
    isTeladocMentalHealthVisible: PropTypes.bool,
    isMedicalBillVisible: PropTypes.bool,
    isPreCertificationVisible: PropTypes.bool,
    isMskVisible: PropTypes.bool,
    isFindCareVisible: PropTypes.bool,
    info: PropTypes.shape({
        eligibleEmployeesCount: PropTypes.number,
        preCertificationCasesCount: PropTypes.number,
        preCertificationSavings: PropTypes.number,
        teladocGeneralMedicalAnnualizedRate: PropTypes.number,
        teladocGeneralMedicalAnnualizedTarget: PropTypes.number,
        teladocMentalHealthAnnualizedRate: PropTypes.number,
        teladocMentalHealthAnnualizedTarget: PropTypes.number,
        telemedUtilizationChartData: PropTypes.shape({}),
        teladocHealthUtilizationChartData: PropTypes.shape({}),
        totalServiceRequestsChartData: PropTypes.shape({}),
        benefitsWalletUtilizationChartData: PropTypes.shape({}),
        providerRecommendationUtilizationChartData: PropTypes.shape({}),
        facilityRecommendationUtilizationChartData: PropTypes.shape({}),
        findCareSearchUtilizationChartData: PropTypes.shape({}),
        appointmentUtilizationChartData: PropTypes.shape({}),
        rxSavingsUtilizationChartData: PropTypes.shape({}),
        medicalBillReviewUtilizationChartData: PropTypes.shape({}),
        benefitsSupportUtilizationChartData: PropTypes.shape({}),
        preCertificationChartData: PropTypes.shape({}),
        preCertificationTotalChartData: PropTypes.shape({}),
        mskEnrollmentsChartData: PropTypes.shape({})
    }).isRequired
};

export {MemberServiceUtilization as TestableMemberServiceUtilization};
export default MemberServiceUtilization;
