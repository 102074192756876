import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import PdfChart from '../PdfChart';
import PdfHeading from '../PdfHeading';
import PdfMetricBox from '../PdfMetricBox';

const MskProgramSummaryContinued = props => {
    const {
        mskDepressionAnxietyReduction,
        mskProductivityImprovement,
        mskSatisfactionRating,
        mskEnrollmentsChartData
    } = props;
    const mskMembersChartProps = {
        Chart: LineChart,
        data: mskEnrollmentsChartData
    };

    return (
        <PageContent title='1.0 Program Summary (continued)'>
            <PdfHeading className='mb-small'>Employee Wellbeing</PdfHeading>
            <Row className='pdf-template-row pdf-template-row_small mb-default'>
                <Column className='pdf-template-row__column' xs={4}>
                    <PdfMetricBox className='msk-metric-box' title='Depression/Anxiety Reduction' value={mskDepressionAnxietyReduction} unit='%' isPlaceholder={!mskDepressionAnxietyReduction}/>
                </Column>

                <Column className='pdf-template-row__column' xs={4}>
                    <PdfMetricBox className='msk-metric-box' title='Productivity Improvement' value={mskProductivityImprovement} unit='%' isPlaceholder={!mskProductivityImprovement}/>
                </Column>

                <Column className='pdf-template-row__column' xs={4}>
                    <PdfMetricBox className='msk-metric-box' title='Member Satisfaction' value={mskSatisfactionRating} unit='%' isPlaceholder={!mskSatisfactionRating}/>
                </Column>
            </Row>

            <PdfHeading className='mb-small'>Total Members by Month</PdfHeading>
            <PdfChart {...mskMembersChartProps}/>
        </PageContent>
    );
};

MskProgramSummaryContinued.propTypes = {
    mskDepressionAnxietyReduction: PropTypes.number,
    mskProductivityImprovement: PropTypes.number,
    mskSatisfactionRating: PropTypes.number,
    mskEnrollmentsChartData: PropTypes.shape({
        datasets: PropTypes.arrayOf(PropTypes.shape({})),
        labels: PropTypes.arrayOf(PropTypes.string)
    })
};

export {MskProgramSummaryContinued as TestableMskProgramSummaryContinued};
export default MskProgramSummaryContinued;
