import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import MarketingCard from '../MarketingCard';
import {isEven} from '../../../utils';
import './index.scss';

const CLASS_NAME_BY_COLOR = {
    cyan: 'marketing-section_cyan',
    scrub: 'marketing-section_scrub',
    yellow: 'marketing-section_yellow',
    pink: 'marketing-section_pink',
    indigo: 'marketing-section_indigo'
};

const getMarketingCard = color => card => <MarketingCard key={card.id} color={color} {...card}/>;

const cardsReducer = (acc, card, index) => isEven(index) ? {...acc, evenCards: [...acc.evenCards, card]} : {...acc, oddCards: [...acc.oddCards, card]};

const MarketingSection = ({title, subtitle, cards, color}) => {
    const colorClassName = CLASS_NAME_BY_COLOR[color];
    const {oddCards, evenCards} = cards.reduce(cardsReducer, {evenCards: [], oddCards: []});

    return (
        <div className={`marketing-section ${colorClassName}`}>
            <Heading type={HEADING_TYPES['3']} className='marketing-section__title'>{title}</Heading>
            <Text type={TEXT_TYPES.bodyBold} className='marketing-section__subtitle'>{subtitle}</Text>

            <Row>
                <Column sm={6}>{evenCards.map(getMarketingCard(color))}</Column>
                <Column sm={6}>{oddCards.map(getMarketingCard(color))}</Column>
            </Row>
        </div>
    );
};

MarketingSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    color: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        section_id: PropTypes.number,
        order: PropTypes.number,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        resources: PropTypes.array,
        is_published: PropTypes.bool
    }))
};

export {MarketingSection as TestableMarketingSection};
export default React.memo(MarketingSection);
