import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import CustomizablePopup from '../../../shared/CustomizablePopup';
import DynamicActionBar from '../../../shared/DynamicActionBar';
import InformationRow from '../../../shared/InformationRow';
import withPopup from '../../../../HOC/withPopup';
import {redirectTo} from '../../../../actions/general';
import {clearBroadcast, requestBroadcast, requestBroadcastUpdating} from '../../../../actions/broadcaster';
import {getActiveCompanyName, getIsSuperAdmin} from '../../../../selectors/general';
import {getBroadcast} from '../../../../selectors/broadcaster';
import {equal, formatDate, getEqual} from '../../../../utils';
import {ROUTES, BROADCASTER_LINK_TYPES, BROADCAST_STATUSES, FAMILY_SEGMENTS, BROADCAST_VERSIONS} from '../../../../constants';
import {FAMILY_SEGMENT_OPTIONS} from '../../../../options';
import analyticsService from '../../../../analyticsService';
import './index.scss';

const POPUP_ID = 'broadcastOverviewPopup';

const NOTIFICATION_INFO_BY_STATUS = {
    [BROADCAST_STATUSES.drafting]: {
        title: 'This is a draft.',
        description: 'To schedule this broadcast, please complete the missing information.'
    },
    [BROADCAST_STATUSES.pending]: {
        title: 'This broadcast is pending!',
        description: 'Our team will review your broadcast within 2-3 business days. You’ll receive an email notification once the broadcast has been approved.'
    },
    [BROADCAST_STATUSES.scheduled]: {title: 'This broadcast has been scheduled!'},
    [BROADCAST_STATUSES.sending]: {title: 'This broadcast is sending!'},
    [BROADCAST_STATUSES.sent]: {title: 'This broadcast has been sent.'},
    [BROADCAST_STATUSES.canceled]: {title: 'This broadcast has been canceled.'},
    [BROADCAST_STATUSES.failed]: {title: 'This broadcast sending has failed.'}
};

const BroadcastOverview = ({openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const activeCompanyName = useSelector(getActiveCompanyName);
    const broadcast = useSelector(getBroadcast);

    useEffect(() => {
        dispatch(requestBroadcast(id, BROADCAST_VERSIONS.v1));

        return () => dispatch(clearBroadcast());
    }, []);

    const getMessage = (message, index) => <InformationRow key={index} title={`Message ${index + 1}`}>{message}</InformationRow>;

    const onOpenPopup = popupProps => openPopup({type: POPUP_TYPES.simple, children: <CustomizablePopup {...popupProps}/>});

    const onEdit = ({target}) => {
        analyticsService.trackClicked(target.textContent, {broadcast_id: broadcast.id});
        dispatch(redirectTo(`${ROUTES.broadcastEditor}/${broadcast.id}`));
    };

    const onOpenCancellationPopup = ({target}) => {
        const onCancel = async () => {
            const {isSuccess} = await dispatch(requestBroadcastUpdating({status: BROADCAST_STATUSES.canceled}, broadcast.id, broadcast));

            return isSuccess && dispatch(redirectTo(ROUTES.broadcasterWelcome));
        };
        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>No</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onCancel}>Yes</Button>
            </React.Fragment>
        );

        analyticsService.trackClicked(target.textContent, {broadcast_id: broadcast.id});

        return onOpenPopup({title: 'Cancel Broadcast', content: 'Are you sure you want to cancel?', actionBar});
    };

    const onOpenApprovalPopup = () => {
        const onApprove = async () => {
            const {isSuccess} = await dispatch(requestBroadcastUpdating({status: BROADCAST_STATUSES.scheduled}, broadcast.id));

            return isSuccess && closePopup();
        };
        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button onClick={onApprove}>Approve</Button>
            </React.Fragment>
        );

        return onOpenPopup({
            title: 'Approve',
            content: (`
                After approving this outbound there will be a confirmation email sent to clients HR team.
                Are you sure you want to approve this broadcast?
            `),
            actionBar
        });
    };

    const {status, name, scheduled_for: deliveryTime, content = {}} = broadcast;
    const {messages = [], link, link_title: linkTitle, link_type: linkType} = content.entry ?? {};
    // TODO: roll segment logic back back when HJ is ready (03.03.2021, Oleh)
    // const {label: segmentTitle} = FAMILY_SEGMENT_OPTIONS.find(getEqual(familySegment, 'value')) || {};
    const {label: segmentTitle} = FAMILY_SEGMENT_OPTIONS.find(getEqual(FAMILY_SEGMENTS.primary, 'value')) || {};
    const {title: notificationTitle, description: notificationDescription} = NOTIFICATION_INFO_BY_STATUS[status] || {};
    const isApprovable = isSuperAdmin && equal(status, BROADCAST_STATUSES.pending);
    const isActionBar = [BROADCAST_STATUSES.scheduled, BROADCAST_STATUSES.pending, BROADCAST_STATUSES.drafting].some(getEqual(status));
    const formattedLink = {
        [BROADCASTER_LINK_TYPES.wallet]: linkTitle,
        [BROADCASTER_LINK_TYPES.web]: <Link className='broadcast-link' href={link} target='_blank'>{link}</Link>,
        [BROADCASTER_LINK_TYPES.file]: <Link className='broadcast-link' href={link} target='_blank'>{linkTitle}</Link>
    }[linkType ?? BROADCASTER_LINK_TYPES.wallet]; // FYI: we use 'wallet' as default for backward compatibility with old broadcasts without link_type (08.02.2020, Oleh);

    return (
        <Row className='broadcast-overview'>
            <Column sm={8}>
                <ContentSection>
                    <InformationRow title='Campaign Name'>{name}</InformationRow>

                    <InformationRow title='Push Notification'>{content.push_text}</InformationRow>

                    {messages.map(getMessage)}

                    <InformationRow title='Link' placeholder='Chosen nothing'>{link && formattedLink}</InformationRow>

                    <InformationRow title='Delivery time (CT)'>{formatDate(deliveryTime, 'MM/dd/yyyy h:mm aa')}</InformationRow>

                    <InformationRow title='Client'>{activeCompanyName}</InformationRow>

                    <InformationRow title='Segment'>{segmentTitle}</InformationRow>

                    {/* <InformationRow title='Segment Size'>{estimatedCount}</InformationRow> */}

                    {isActionBar && (
                        <DynamicActionBar>
                            <Button type={BUTTON_TYPES.destructive} onClick={onOpenCancellationPopup}>Cancel Broadcast</Button>
                            <Button type={BUTTON_TYPES.secondary} onClick={onEdit}>Edit Broadcast</Button>
                        </DynamicActionBar>
                    )}
                </ContentSection>
            </Column>

            <Column sm={4}>
                <ContentSection className='broadcast-notification-information'>
                    <Heading type={HEADING_TYPES['4']} className='mb-8'>
                        {notificationTitle}
                    </Heading>

                    {notificationDescription && <Text className='broadcast-notification-information__description'>{notificationDescription}</Text>}

                    {isApprovable && <Button className='mb-13' type={BUTTON_TYPES.primary} onClick={onOpenApprovalPopup}>Approve</Button>}
                    {!isApprovable && <Link className='mb-13' isButton href={ROUTES.broadcasterWelcome} type={BUTTON_TYPES.primary}>Done</Link>}
                </ContentSection>
            </Column>
        </Row>
    );
};

BroadcastOverview.propTypes = {
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {BroadcastOverview as TestableBroadcastOverview};
export default withPopup(POPUP_ID)(BroadcastOverview);
