import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import Input from '@frontend/ui-kit/Components/Input';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import GlobalErrorMessage from '@frontend/ui-kit/Components/GlobalErrorMessage';
import {Form, Field} from '../../shared/FormComponents';
import PasswordInput from '../../shared/PasswordInput';
import {redirectTo} from '../../../actions/general';
import {login, failAuthorization, setAuthorizationData, setTwoStepAuthorizationData} from '../../../actions/authorization';
import {validateEmail, validateRequired} from '../../../utils';
import {FORM_GLOBAL_ERROR, FORMS, ROUTES, POLICY_URLS} from '../../../constants';

/* istanbul ignore next */
const validate = values => ({
    username: validateEmail(values.username),
    password: validateRequired(values.password)
});

const LoginForm = () => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({username: '', password: ''});
    const [authData, setAuthData] = useState(null);

    const onSubmit = useCallback(async values => {
        const {isSuccess, authData, submissionGlobalError} = await dispatch(login(values.username, values.password));

        if (!isSuccess) {
            if (authData.is_temporary_banned) {
                dispatch(redirectTo(ROUTES.locked));
                return;
            }

            dispatch(failAuthorization());
            return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
        }

        setInitialValues(values);
        setAuthData(authData);
    }, [dispatch]);

    const onSubmitSuccess = useCallback(async () => {
        const {
            access_token: accessToken,
            refresh_token: refreshToken,
            two_step_is_required: isTwoStepRequired,
            two_step_token: twoStepToken,
            two_step_code_channel: twoStepCodeChannel
        } = authData;

        if (isTwoStepRequired) {
            dispatch(setTwoStepAuthorizationData({isTwoStepRequired, twoStepToken, twoStepCodeChannel}));
            dispatch(redirectTo(ROUTES.verification));
            return;
        }

        dispatch(setAuthorizationData({accessToken, refreshToken, isTwoStepRequired}));
    }, [dispatch, authData]);

    return (
        <Form name={FORMS.login} initialValues={initialValues} validate={validate} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit, submitError}) => (
                <form className='authorization-form' onSubmit={handleSubmit} noValidate>
                    <div className='authorization-form-header'>
                        <Heading className='authorization-form-header__title' type={HEADING_TYPES['1']}>Dashboard Login</Heading>

                        <Text className='authorization-form-header__subtitle'>
                            Access the HealthJoy Dashboard to view reporting on your program, send custom benefits communication, view education and marketing materials, and more.
                        </Text>
                    </div>

                    <div className='authorization-form-body'>
                        <Field name='username' type='email'>
                            {props => <Input {...props} type='email' placeholder='Enter your email...' wrapperClassName='mb-8' label='Email'/>}
                        </Field>

                        <PasswordInput name='password' placeholder='Enter your password...' wrapperClassName='mb-5' label='Password'/>
                        Forgot Password? <Link href={ROUTES.passwordRecovery}>Get Help</Link>

                        {submitError && <GlobalErrorMessage className='authorization-form-body__error-message'>{submitError}</GlobalErrorMessage>}

                        <div className='authorization-form-action-bar'>
                            <Button className='authorization-form-action-bar__button' type={BUTTON_TYPES.primary} isSubmit>Login</Button>
                        </div>
                        <Text type={TEXT_TYPES.helper}>
                            By selecting Login, you accept our&nbsp;
                            <Link href={POLICY_URLS.termsAndConditions} target='_blank'>Client Terms and Conditions</Link>,&nbsp;
                            <Link href={POLICY_URLS.termsOfUse} target='_blank'>Terms of Use</Link>, and&nbsp;
                            <Link href={POLICY_URLS.privacyPolicy} target='_blank'>Privacy Policy</Link>.
                        </Text>
                    </div>
                </form>
            )}
        </Form>
    );
};

export {LoginForm as TestableLoginForm};
export default LoginForm;
