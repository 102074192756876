import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {Field} from '../../shared/FormComponents';
import {getIsSuperAdmin} from '../../../selectors/general';
import {normalizeMarkupEditor} from '../../../utils';

const LinkableField = ({children, onLink, ...restProps}) => {
    const isSuperAdmin = useSelector(getIsSuperAdmin);

    return (
        <Field {...restProps}>
            {props => {
                // FYI: we can't use Field parse api because it's not triggered for the first render (09.03.22, Yuri)
                const enhancedProps = isSuperAdmin ? {...props, value: normalizeMarkupEditor(props?.value), icon: ICON_TYPES.settings, onIconClick: onLink} : props;

                return children(enhancedProps);
            }}
        </Field>
    );
};

LinkableField.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    onLink: PropTypes.func
};

export {LinkableField as TestableLinkableField};
export default React.memo(LinkableField);
