import React from 'react';
import PropTypes from 'prop-types';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import {Field} from '../../../../shared/FormComponents';

const MESSAGES_COUNT_LIMIT = 5;
export const LINK_FILE_MAX_SIZE = 10;

export const Messages = ({fields: messages}) => {
    const onAddMessage = () => messages.push();
    const isAddButtonShowed = messages.length < MESSAGES_COUNT_LIMIT;
    const onRemoveMessage = index => messages.remove(index);
    const isRemoveButtonShowed = messages.length > 1;

    return (
        <div className='messages' data-testid='messages-form'>
            {messages.map((message, index) => {
                const messageProps = {
                    isAllowInputOverflow: true,
                    isRequired: true,
                    maxLength: 128,
                    label: `Message ${index + 1}`,
                    wrapperClassName: 'mb-12',
                    ...isRemoveButtonShowed && {
                        icon: ICON_TYPES.delete,
                        onIconClick: () => onRemoveMessage(index)
                    }
                };

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className='message' key={index}>
                        <Field name={message}>
                            {props => <Input {...props} {...messageProps}/>}
                        </Field>
                    </div>
                );
            })}

            {isAddButtonShowed && (
                <div className='button-wrapper'>
                    <Button className='button-wrapper__button' type={BUTTON_TYPES.secondary} onClick={onAddMessage}>
                        <Icon type={ICON_TYPES.circlePlus}/> Add another message
                    </Button>
                </div>
            )}
        </div>
    );
};

Messages.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.object.isRequired
};
