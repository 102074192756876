import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import UnsavedFormPopup from '../../Components/shared/UnsavedFormPopup';
import withPopup from '../withPopup';
import {setFormsDirtiness} from '../../actions/shared';
import {getFormsDirtiness} from '../../selectors/shared';
import {compose, omit} from '../../utils';

const POPUP_ID = 'dirtyFormPopup';

const withUnsavedFormWarning = Component => {
    const WithUnsavedFormWarning = props => {
        const {openPopup, closePopup} = props;
        const dispatch = useDispatch();
        const formsDirtiness = useSelector(getFormsDirtiness);

        const onWarn = (leave, stay = () => {}, form) => {
            const onLeave = () => {
                // FYI: we decided to destroy all dirty forms except ignorable ones because of incorrect behaviour
                // in conjunction with redirection strategy: if there is reserve route, warning popup is triggered repeatedly
                // because form still isn't destroyed (28.04.2020, Oleh)
                // FYI: if there is the third argument we should destroy only one form for case when we stay on the same page and have several dirty forms (1.10.2021, Pasha)
                dispatch(setFormsDirtiness(form ? omit(formsDirtiness, [form]) : {}));
                closePopup();
                leave();
            };
            const onStay = () => {
                closePopup();
                stay();
            };

            const children = <UnsavedFormPopup onLeave={onLeave} onStay={onStay}/>;

            return openPopup({type: POPUP_TYPES.simple, children});
        };

        return <Component {...props} onWarnAboutUnsavedForm={onWarn}/>;
    };

    WithUnsavedFormWarning.propTypes = {
        openPopup: PropTypes.func,
        closePopup: PropTypes.func
    };

    return WithUnsavedFormWarning;
};

export {withUnsavedFormWarning as testableWithUnsavedFormWarning};
export default compose(
    withPopup(POPUP_ID),
    withUnsavedFormWarning
);
