import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import PlanPeriodSwitcher from '../../../Components/Reporting/PlanPeriodSwitcher';
import DownloadReportsReporting from '../../../Components/Reporting/DownloadReports';

const DownloadReports = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='Performance Report'/>
            </Column>

            <Column constant>
                <PlanPeriodSwitcher/>
            </Column>
        </Row>

        <ReportingPagesTabs/>

        <DownloadReportsReporting/>
    </React.Fragment>
);

export {DownloadReports as TestableDownloadReports};
export default React.memo(DownloadReports);
