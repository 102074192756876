import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import {Form, Field} from '../../../shared/FormComponents';
import {requestSFTPCredentialsCreation} from '../../../../actions/adminPortal';
import {equal, validateMinLength, validateRequired, validateSFTPUsername} from '../../../../utils';
import {FORMS, SFTP_HOST_TYPES, SFTP_USERNAME_MIN_LENGTH, SFTP_USERNAME_MAX_LENGTH} from '../../../../constants';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    const isTPAHost = equal(values.host_type, SFTP_HOST_TYPES.tpa);

    return {
        multi_client_feed_name: validateRequired(values.multi_client_feed_name),
        username: validateRequired(values.username) || validateMinLength(values.username, SFTP_USERNAME_MIN_LENGTH) || validateSFTPUsername(values.username),
        password: isTPAHost ? validateRequired(values.password) : undefined
    };
};

const SFTPNewCredentialsPopup = ({initialValues, onClose, onSave}) => {
    const dispatch = useDispatch();
    const {companyAlias} = useParams();
    const isTPAHost = equal(initialValues.host_type, SFTP_HOST_TYPES.tpa);

    const onSubmit = useCallback(async values => {
        const {data, isSuccess, submissionErrors} = await dispatch(requestSFTPCredentialsCreation({company_alias: companyAlias, is_multi_client_feed: true, ...values}));

        if (!isSuccess) {
            return submissionErrors;
        }

        onSave(data.id);
    }, [dispatch, companyAlias, onSave]);

    return (
        <Form name={FORMS.SFTPNewCredentials} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({handleSubmit, submitErrors = {}, hasValidationErrors, dirtyFieldsSinceLastSubmit}) => {
                const isValid = !hasValidationErrors && Object.keys(submitErrors).every(key => dirtyFieldsSinceLastSubmit[key]);
                const actionBar = (
                    <React.Fragment>
                        <Button data-testid='cancel-button' onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
                        <Button data-testid='add-button' onClick={handleSubmit} disabled={!isValid}>Save and Add</Button>
                    </React.Fragment>
                );
                const popupProps = {title: 'Add New Linked Credentials', actionBar};

                return (
                    <PopupContent {...popupProps}>
                        <form onSubmit={handleSubmit} noValidate className='sftp-new-credentials-popup'>
                            <Field name='host_type'>
                                {props => <Input {...props} type='hidden'/>}
                            </Field>
                            <Field name='host'>
                                {props => <Input {...props} type='hidden'/>}
                            </Field>

                            <Row>
                                <Column sm={4}>
                                    <Field name='multi_client_feed_name'>
                                        {props => <Input {...props} label='Linked Credentials Name'/>}
                                    </Field>
                                </Column>

                                <Column sm={4}>
                                    <Field name='username'>
                                        {props => <Input {...props} label='SFTP Login' maxLength={SFTP_USERNAME_MAX_LENGTH}/>}
                                    </Field>
                                </Column>

                                {isTPAHost && (
                                    <Column sm={4}>
                                        <Field name='password'>
                                            {props => <Input {...props} label='SFTP Password' maxLength={128}/>}
                                        </Field>
                                    </Column>
                                )}
                            </Row>
                        </form>
                    </PopupContent>
                );
            }}
        </Form>
    );
};

SFTPNewCredentialsPopup.propTypes = {
    initialValues: PropTypes.shape({
        host_type: PropTypes.string,
        host: PropTypes.string
    }),
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export {SFTPNewCredentialsPopup as TestableSFTPNewCredentialsPopup};
export default React.memo(SFTPNewCredentialsPopup);
