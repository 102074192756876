import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Input from '@frontend/ui-kit/Components/Input';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import LabeledTooltip from '../../shared/LabeledTooltip';
import CardHeaderInfo, {recommendedFields as cardHeaderRecommendedFields} from '../CardHeaderInfo';
import AncillaryCarrierInfo, {
    recommendedFields as ancillaryCarrierRecommendedFields,
    validateOnSubmit as validateAncillaryCarrierOnSubmit
} from '../AncillaryCarrierInfo';
import CopyableField from '../CopyableField';
import DocumentUploader from '../DocumentUploader';
import withAncillaryPlanDetails from '../../../HOC/withAncillaryPlanDetails';
import {formatList, normalizeList, trimStart} from '../../../utils';

const POLICY_HOLDER_TOOLTIP_CONTENT = 'If you’re unsure, most of the time this is the Client’s name.';

const recommendedFields = [
    ...ancillaryCarrierRecommendedFields,
    ...cardHeaderRecommendedFields,
    {name: 'main_paragraph', label: 'Main Paragraph'},
    {name: 'card_list', label: 'List'},
    {name: 'policy_holder', label: 'Policyholder'},
    {name: 'group_policy', label: 'Group Policy'}
];

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateAncillaryCarrierOnSubmit(values)});

const CriticalIllnessForm = ({isReadonly, card}) => (
    <Row className='ancillary-plan-details-form'>
        <Column className='ancillary-plan-details-form__column' sm={12}>
            <DocumentUploader isReadonly={isReadonly}/>
        </Column>

        <Column className='ancillary-plan-details-form__column' sm>
            <Text className='ancillary-plan-details-form__description'>
                Please enter the information below.
                It will be mapped to the corresponding wallet card (example shown here) and viewable in Benefits Preview.
                Fields left blank will not appear on the wallet card.
            </Text>

            <AncillaryCarrierInfo isReadonly={isReadonly}/>

            <CardHeaderInfo isReadonly={isReadonly}/>

            <CopyableField name='policy_holder' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter' label={<LabeledTooltip title='Policyholder' content={POLICY_HOLDER_TOOLTIP_CONTENT}/>} wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='group_policy' parse={trimStart}>
                {props => <Input {...props} readonly={isReadonly} placeholder='Please enter' label='Group Policy' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='main_paragraph' parse={trimStart}>
                {props => <Textarea {...props} readonly={isReadonly} placeholder='Please enter...' label='Main Paragraph' wrapperClassName='mb-12'/>}
            </CopyableField>

            <CopyableField name='card_list' parse={normalizeList} format={formatList}>
                {props => <Textarea {...props} readonly={isReadonly} placeholder='List' label='List'/>}
            </CopyableField>
        </Column>

        <Column className='ancillary-plan-details-form__column' constant><img src={card} alt=''/></Column>
    </Row>
);

CriticalIllnessForm.propTypes = {
    isReadonly: PropTypes.bool,
    card: PropTypes.string
};

export {CriticalIllnessForm as TestableCriticalIllnessForm};
export default withAncillaryPlanDetails({recommendedFields, validateOnSubmit})(CriticalIllnessForm);
