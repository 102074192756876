import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import Separator from '@frontend/ui-kit/Components/Separator';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {Form, Field} from '../../shared/FormComponents';
import {redirectTo} from '../../../actions/general';
import {requestContactInfo, requestContactUpdating} from '../../../actions/contacts';
import {getProfileInfo} from '../../../selectors/general';
import {validateRequired} from '../../../utils';
import {DEFAULT_PHONE_PLACEHOLDER, FORMS, ROUTES} from '../../../constants';

/* istanbul ignore next */
const validate = values => ({
    first_name: validateRequired(values.first_name),
    last_name: validateRequired(values.last_name),
    phone: validateRequired(values.phone)
});

const BaseContactInfoForm = () => {
    const dispatch = useDispatch();
    const profileInfo = useSelector(getProfileInfo);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestContactInfo(profileInfo.id));

            setInitialValues(data);
        })();
    }, []);

    const redirectToContacts = () => dispatch(redirectTo(ROUTES.contacts));

    const onSubmit = useCallback(async ({id, ...values}) => {
        const {isSuccess, data, submissionErrors} = await dispatch(requestContactUpdating({...values, user_id: id}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(data);
    }, [dispatch]);

    return (
        <ContentSection className='contact-info-basic-form'>
            <Form name={FORMS.baseContactInfo} initialValues={initialValues} validate={validate} onSubmit={onSubmit} onSubmitSuccess={redirectToContacts}>
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Field name='first_name'>
                            {props => <Input {...props} placeholder='Enter first name...' label='First Name' wrapperClassName='mb-12'/>}
                        </Field>

                        <Field name='last_name'>
                            {props => <Input {...props} placeholder='Enter last name...' label='Last Name' wrapperClassName='mb-12'/>}
                        </Field>

                        <Field name='email'>
                            {props => <Input {...props} disabled placeholder='Enter valid email...' icon={ICON_TYPES.message} label='Email Address' wrapperClassName='mb-12'/>}
                        </Field>

                        <Field name='phone'>
                            {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Phone'/>}
                        </Field>

                        <Field name='id'>{props => <Input {...props} type='hidden'/>}</Field>

                        <Separator type='solid'/>

                        <Row middle='xs'>
                            <Column sm/>

                            <Column className='contact-form-action-bar' constant>
                                <Button className='contact-form-action-bar__button' type={BUTTON_TYPES.secondary} onClick={redirectToContacts}>Cancel</Button>

                                <Button className='contact-form-action-bar__button' type={BUTTON_TYPES.primary} isSubmit>Save Changes</Button>
                            </Column>
                        </Row>
                    </form>
                )}
            </Form>
        </ContentSection>
    );
};

export {BaseContactInfoForm as TestableBaseContactInfoForm};
export default BaseContactInfoForm;
