import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import MarketingResource from '../MarketingResource';
import './index.scss';

const CLASS_NAME_BY_COLOR = {
    cyan: 'marketing-card_cyan',
    scrub: 'marketing-card_scrub',
    yellow: 'marketing-card_yellow',
    pink: 'marketing-card_pink',
    indigo: 'marketing-card_indigo'
};

const getMarketingResource = resource => <MarketingResource key={resource.id} {...resource}/>;

const MarketingCard = ({title, color, subtitle, resources = []}) => {
    const colorClassName = CLASS_NAME_BY_COLOR[color];

    return (
        <div className={`marketing-card ${colorClassName}`}>
            <div className='marketing-card__content'>
                <Heading className='marketing-card__title' type={HEADING_TYPES['3']}>{title}</Heading>
                <Text>{subtitle}</Text>
                <div className='marketing-card__resources'>
                    {resources.map(getMarketingResource)}
                </div>
            </div>
        </div>
    );
};

MarketingCard.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    subtitle: PropTypes.string,
    resources: PropTypes.arrayOf(PropTypes.object)
};

export {MarketingCard as TestableMarketingCard};
export default React.memo(MarketingCard);
