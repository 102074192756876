import React from 'react';
import PropTypes, {arrayOf} from 'prop-types';
import PhonePreview from '@frontend/ui-kit/Components/PhonePreview';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {getHtmlFromDraft} from '../../../shared/WysiwygEditor';
import {equal} from '../../../../utils';
import './index.scss';

const ITEM_TYPES = {
    walletCard: 'walletCard',
    document: 'document',
    additionalText: 'additionalText'
};

const previewItems = {
    [ITEM_TYPES.walletCard]: walletLink => (
        <Button type={BUTTON_TYPES.secondary} href={walletLink} target='_blank' className='custom-preview__button mb-10'>
            <Icon type={ICON_TYPES.wallet}/>
            View Benefit Card
        </Button>
    ),
    [ITEM_TYPES.document]: documentLink => (
        <Button type={BUTTON_TYPES.secondary} href={documentLink} target='_blank' className='custom-preview__button mb-10'>
            <Icon type={ICON_TYPES.pdfFile}/>
            View Attachment
        </Button>
    ),
    [ITEM_TYPES.additionalText]: additionalText => additionalText && <div className='custom-preview__description mb-10' dangerouslySetInnerHTML={{__html: getHtmlFromDraft(additionalText)}}/>
};

const BroadcastPreview = ({broadcastValues}) => {
    const {title, image_url: image, details, blocks: additionalFields} = broadcastValues?.content || {};

    const getPreviewItem = item => {
        const walletLink = equal(item.type, ITEM_TYPES.walletCard) && item.link;
        const documentLink = equal(item.type, ITEM_TYPES.document) && item.description;
        const additionalText = equal(item.type, ITEM_TYPES.additionalText) && item.body;

        const previewComponent = previewItems[item.type];

        return previewComponent ? previewComponent(walletLink || documentLink || additionalText) : null;
    };

    const additionalItems = additionalFields && additionalFields.map(item => getPreviewItem(item));

    return (
        <PhonePreview title='Inbox' className='custom-preview'>
            <div className='screen'>
                {title && (
                    <div className='custom-preview__title'>{title}</div>
                )}

                {image && (
                    <div className='custom-preview__logo'>
                        <img src={image} alt=''/>
                    </div>
                )}

                {details && (
                    <div className='custom-preview__details mb-10'>
                        <div data-testid='custom-page-body' dangerouslySetInnerHTML={{__html: getHtmlFromDraft(details)}}/>
                    </div>
                )}

                {additionalItems && (
                    <div>{additionalItems}</div>
                )}
            </div>
        </PhonePreview>
    );
};

BroadcastPreview.propTypes = {
    broadcastValues: PropTypes.shape({
        content: PropTypes.shape({
            title: PropTypes.string,
            image_url: PropTypes.string,
            details: PropTypes.string,
            blocks: arrayOf(PropTypes.shape({}))
        })
    })
};

export default BroadcastPreview;
