import React, {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import {setInternalMenuActiveItem, setInternalMenuItems} from '../../../actions/internalMenu';
import {getInternalMenuActiveItem, getInternalMenuItems} from '../../../selectors/internalMenu';
import {DEFAULT_INTERNAL_MENU_INDEX} from '../../../constants';
import {equal} from '../../../utils';
import './index.scss';

const InternalMenu = () => {
    const items = useSelector(getInternalMenuItems);
    const activeItem = useSelector(getInternalMenuActiveItem);
    const dispatch = useDispatch();

    const setActiveItem = useCallback(activeItem => dispatch(setInternalMenuActiveItem(activeItem)), [dispatch]);

    // TODO: Vlad and Oleg discussed possibility of removing internalMenu list from Redux and set only amount items. Will think about it (5.11.20, Pasha)
    useEffect(() => {
        const {id: defaultActiveItem} = items[DEFAULT_INTERNAL_MENU_INDEX];

        setActiveItem(defaultActiveItem);

        return () => {
            dispatch(setInternalMenuItems([]));
            setActiveItem(0);
        };
    }, []);

    const getItem = (item, index) => {
        const {label, completed, id, active = true} = item;
        const isActiveItem = equal(activeItem, id);
        const activeClassName = isActiveItem ? 'internal-menu-item_active' : '';
        const stickerType = isActiveItem ? STICKER_TYPES.primary : STICKER_TYPES.default;
        const onClick = () => setActiveItem(id);

        return (
            <div key={index} className={`internal-menu-item ${activeClassName}`} onClick={onClick}>
                <Text className='internal-menu-item__label' type={TEXT_TYPES.body}>
                    {completed && <Icon className='internal-menu-item__icon' type={ICON_TYPES.confirm}/>}
                    {label}
                </Text>

                {!active && <Sticker type={stickerType} className='internal-menu-item__sticker'>Deactivated</Sticker>}
            </div>
        );
    };

    return (
        <div className='internal-menu'>
            {items.map(getItem)}
        </div>
    );
};

export {InternalMenu as TestableInternalMenu};
export default React.memo(InternalMenu);
