import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getDraftFromHtml} from '../Components/shared/WysiwygEditor';
import {requestBroadcast} from '../actions/broadcaster';
import {requestFeatureFlag} from '../actions/general';
import {getActiveCompany} from '../selectors/general';
import {getBroadcastTemplate} from '../selectors/broadcaster';
import {equal} from '../utils';
import {FEATURE_FLAGS, BROADCAST_VERSIONS} from '../constants';

const ENHANCED_ADDITIONAL_BLOCKS = {
    wallet_link: (block, index) => ({
        type: 'walletCard',
        link: block.link,
        name: 'link',
        key: index
    }),
    file: (block, index) => ({
        type: 'document',
        description: block.media_url,
        name: 'description',
        key: index
    }),
    text: (block, index) => ({
        type: 'additionalText',
        body: getDraftFromHtml(block.body),
        name: 'body',
        key: index
    })
};

const getEnhancedBlock = (block, index) => {
    const transform = ENHANCED_ADDITIONAL_BLOCKS[block.type];

    return transform(block, index);
};

const useBroadcast = broadcastId => {
    const [broadcastData, setBroadcastData] = useState();
    const dispatch = useDispatch();
    const broadcasterTemplate = useSelector(getBroadcastTemplate);
    const [broadcastVersion, setBroadcasterVersion] = useState(null);
    const {logo} = useSelector(getActiveCompany) || {};
    const enhancedBlocks = blocks => blocks?.map(getEnhancedBlock);

    useEffect(() => {
        (async () => {
            const {flag: isNewBroadcast} = await dispatch(requestFeatureFlag(FEATURE_FLAGS.NewBroadcasterUI));
            const broadcastVersion = isNewBroadcast ? BROADCAST_VERSIONS.v2 : BROADCAST_VERSIONS.v1;
            setBroadcasterVersion(broadcastVersion);
        })();
    }, [dispatch]);

    useEffect(() => {
        const loadBroadcastData = async () => {
            if (!broadcastVersion) return;

            const {broadcast} = await dispatch(requestBroadcast(broadcastId, broadcastVersion));

            setBroadcastData({
                ...broadcast,
                content: {
                    ...broadcast.content,
                    details: broadcast?.content?.details ? getDraftFromHtml(broadcast?.content?.details) : '',
                    blocks: enhancedBlocks(broadcast.content.blocks)
                }
            });
        };

        const defaultValues = equal(broadcastVersion, BROADCAST_VERSIONS.v2)
            ? {content: {image_url: logo, details: '', blocks: []}}
            : {content: {entry: {image: logo, messages: ['']}}};

        if (!broadcastId) {
            setBroadcastData(broadcasterTemplate || defaultValues);
            return;
        }

        loadBroadcastData();
    }, [broadcasterTemplate, broadcastId, broadcastVersion, dispatch, logo]);

    return broadcastData;
};

export default useBroadcast;
