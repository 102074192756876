import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Collapse from '@frontend/ui-kit/Components/Collapse';
import TimePicker from '@frontend/ui-kit/Components/TimePicker';
import {Field} from '../../../shared/FormComponents';
import useFormState from '../../../../hooks/useFormState';
import {normalizePositiveNumber} from '../../../../utils';
import './index.scss';

const CommunicationChannelSection = ({formConfig}) => {
    const {values} = useFormState();
    const isPushNotificationsDisabled = !values?.push_notification?.is_enabled;
    const isDefaultEmailCampaignDisabled = !values?.default_email_campaign?.is_enabled;
    const isCustomEmailCampaignDisabled = !values?.custom_email_campaign?.is_enabled;

    return (
        <ContentSection className='communication-channel mb-5'>
            <Heading type={HEADING_TYPES['5']} className='mb-12'>Communication channel</Heading>

            <Collapse hasCollapseIcon
                initiator={(
                    <Field name='push_notification.is_enabled'>
                        {props => <Checkbox {...props} caption='Push Notifications' wrapperClassName='collapse-checkbox'/>}
                    </Field>
                )}
                content={(
                    <React.Fragment>
                        <Field name='push_notification.title' maxLength={250} wrapperClassName='mb-12'>
                            {props => <Input {...props} disabled={isPushNotificationsDisabled} label='Title' placeholder='Text' description='Please enter notification title that best fits this notification.'/>}
                        </Field>

                        <Field name='push_notification.body' maxLength={250} wrapperClassName='mb-12'>
                            {props => <Input {...props} disabled={isPushNotificationsDisabled} label='Body' placeholder='Text' description='Please enter notification body that best fits this notification.'/>}
                        </Field>

                        <Row className='mb-12'>
                            <Column sm={4}>
                                <Field name='push_notification.start_day_hours_to_receive'>
                                    {props => (
                                        <TimePicker {...props}
                                            label='Start hours to receive'
                                            placeholder='Select time'
                                            disabled={isPushNotificationsDisabled}/>
                                    )}
                                </Field>
                            </Column>

                            <Column sm={4}>
                                <Field name='push_notification.end_day_hours_to_receive'>
                                    {props => (
                                        <TimePicker {...props}
                                            label='Finish hours to receive'
                                            placeholder='Select time'
                                            disabled={isPushNotificationsDisabled}/>
                                    )}
                                </Field>
                            </Column>

                            <Column sm={4}>
                                <Field name='push_notification.publish_delay' parse={normalizePositiveNumber}>
                                    {props => <Input {...props} disabled={isPushNotificationsDisabled} label='Delay after the card is published' placeholder='Number' description='*in days'/>}
                                </Field>
                            </Column>
                        </Row>
                    </React.Fragment>
                )}/>

            <Collapse hasCollapseIcon
                initiator={(
                    <Field name='default_email_campaign.is_enabled'>
                        {props => <Checkbox {...props} caption='Default Email campaign' wrapperClassName='collapse-checkbox'/>}
                    </Field>
                )}
                content={(
                    <React.Fragment>
                        <Input value={formConfig?.default_email_campaign_trigger_event} className='mb-12' label='Campaign Trigger Event' disabled/>

                        <Row className='mb-12'>
                            <Column sm={4}>
                                <Field name='default_email_campaign.start_day_hours_to_receive'>
                                    {props => (
                                        <TimePicker {...props}
                                            label='Start hours to receive'
                                            placeholder='Select time'
                                            disabled={isDefaultEmailCampaignDisabled}/>
                                    )}
                                </Field>
                            </Column>

                            <Column sm={4}>
                                <Field name='default_email_campaign.end_day_hours_to_receive'>
                                    {props => (
                                        <TimePicker {...props}
                                            label='Finish hours to receive'
                                            placeholder='Select time'
                                            disabled={isDefaultEmailCampaignDisabled}/>
                                    )}
                                </Field>
                            </Column>

                            <Column sm={4}>
                                <Field name='default_email_campaign.publish_delay' parse={normalizePositiveNumber}>
                                    {props => <Input {...props} disabled={isDefaultEmailCampaignDisabled} label='Delay after the card is published' placeholder='Number' description='*in days'/>}
                                </Field>
                            </Column>
                        </Row>
                    </React.Fragment>
                )}/>

            <Collapse hasCollapseIcon
                initiator={(
                    <Field name='custom_email_campaign.is_enabled'>
                        {props => <Checkbox {...props} caption='Custom Email campaign' wrapperClassName='collapse-checkbox'/>}
                    </Field>
                )}
                content={(
                    <React.Fragment>
                        <Field name='custom_email_campaign.trigger_event' maxLength={50} wrapperClassName='mb-12'>
                            {props => <Input {...props} disabled={isCustomEmailCampaignDisabled} label='Campaign Trigger Event' placeholder='Text' isRequired/>}
                        </Field>

                        <Row className='mb-12'>
                            <Column sm={4}>
                                <Field name='custom_email_campaign.start_day_hours_to_receive'>
                                    {props => (
                                        <TimePicker {...props}
                                            label='Start hours to receive'
                                            placeholder='Select time'
                                            disabled={isCustomEmailCampaignDisabled}/>
                                    )}
                                </Field>
                            </Column>

                            <Column sm={4}>
                                <Field name='custom_email_campaign.end_day_hours_to_receive'>
                                    {props => (
                                        <TimePicker {...props}
                                            label='Finish hours to receive'
                                            placeholder='Select time'
                                            disabled={isCustomEmailCampaignDisabled}/>
                                    )}
                                </Field>
                            </Column>

                            <Column sm={4}>
                                <Field name='custom_email_campaign.publish_delay' parse={normalizePositiveNumber}>
                                    {props => <Input {...props} disabled={isCustomEmailCampaignDisabled} label='Delay after the card is published' placeholder='Number' description='*in days'/>}
                                </Field>
                            </Column>
                        </Row>
                    </React.Fragment>
                )}/>
        </ContentSection>
    );
};

CommunicationChannelSection.propTypes = {
    formConfig: PropTypes.shape({
        default_email_campaign_trigger_event: PropTypes.string
    })
};

export {CommunicationChannelSection as TestableCommunicationChannelSection};
export default CommunicationChannelSection;
