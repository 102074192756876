import React from 'react';
import PropTypes from 'prop-types';
import PageContent from '../PageContent';
import PdfText from '../PdfText';

const MskMemberTestimonials = props => {
    const {mskTestimonials, isContinued} = props;

    return (
        <PageContent title={`2.0 Member Testimonials${isContinued ? ' (continued)' : ''}`}>
            {mskTestimonials.map((testimonial, index) => {
                return (
                    <PdfText key={index} className='mb-small'>
                        “{testimonial}“
                    </PdfText>
                );
            })}
        </PageContent>
    );
};

MskMemberTestimonials.propTypes = {
    mskTestimonials: PropTypes.arrayOf(PropTypes.string),
    isContinued: PropTypes.bool
};

export {MskMemberTestimonials as TestableMskMemberTestimonials};
export default MskMemberTestimonials;
