import React from 'react';
import IndividualGroupDetails from '../../../Components/AdminPortal/IndividualGroupDetails';
import BreadCrumbs from '../../../Components/shared/BreadCrumbs';
import {ADMIN_BASE_ROUTE} from '../../../constants';

const routesMapper = [
    {name: 'Eligibility imports'},
    {name: 'All Clients'},
    {displayName: 'group_name'}
];

const IndividualGroup = () => (
    <React.Fragment>
        <BreadCrumbs startsAfter={ADMIN_BASE_ROUTE} routesMapper={routesMapper}/>
        <IndividualGroupDetails/>
    </React.Fragment>
);

export {IndividualGroup as TestableIndividualGroup};
export default React.memo(IndividualGroup);
