import React from 'react';
import PropTypes from 'prop-types';
import PdfHeading from '../PdfHeading';
import PdfText from '../PdfText';
import {REVERSED_UNITS} from '../../../constants';
import './index.scss';

const PdfMetricBox = ({className, title, value, unit, isPlaceholder}) => {
    const formattedValue = REVERSED_UNITS.includes(unit) ? `${unit}${value}` : `${value}${unit}`;

    return (
        <div className={`pdf-metric-box ${className}`}>
            <PdfText className='pdf-metric-box__title' type='helper-bold'>{title}</PdfText>

            <PdfHeading className='pdf-metric-box__value' type={2}>
                {isPlaceholder ? 'N/A' : formattedValue}
            </PdfHeading>
        </div>
    );
};

PdfMetricBox.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    isPlaceholder: PropTypes.bool
};

PdfMetricBox.defaultProps = {
    className: '',
    unit: '',
    isPlaceholder: false
};

export {PdfMetricBox as TestablePdfMetricBox};
export default React.memo(PdfMetricBox);
