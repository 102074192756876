import React from 'react';
import PropTypes from 'prop-types';
import PageContent from '../PageContent';
import PdfText from '../PdfText';

const GlossaryContinuedSecond = ({isTeladocVisible}) => (
    <PageContent title='4.0 Glossary Continued' data-testid='glossary-continued-second'>
        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Return on Investment</PdfText> -
            The Total Company Savings, minus the Company’s prorated investment in HealthJoy, divided by the Company’s prorated investment.
        </PdfText>

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Rx Savings</PdfText> -
            Calculated as the sum of the actual savings found in actual identified prescriptions.
            Savings are accrued according to the refill cadence.
            For example, if a member has a $10 savings opportunity on a monthly prescription, over 12 months that would total $120 in savings.
        </PdfText>

        <PdfText className='mt-small'>
            <PdfText type='body-bold' isInline>Rx Savings Requests</PdfText> - Count of total Rx Savings requests completed.
            There can be multiple prescriptions per request.
        </PdfText>

        {isTeladocVisible && (
            <PdfText className='mt-small' data-testid='teladoc-health-section'>
                <PdfText type='body-bold' isInline>Teladoc Health Consults</PdfText> - Count of total Teladoc Health consults completed per service.
            </PdfText>
        )}
    </PageContent>
);

GlossaryContinuedSecond.propTypes = {
    isTeladocVisible: PropTypes.bool
};

export {GlossaryContinuedSecond as TestableGlossaryContinuedSecond};
export default GlossaryContinuedSecond;
