import {SET_PDF_TEMPLATE_SUBJECT, RECEIVE_PDF_REPORT_INFO} from '../actions/actionTypes';

export const initialState = {
    dataPath: null,
    companyAlias: null,
    companyData: {},
    metrics: {
        // Performance report metrics
        summary: {},
        memberUtilization: {},
        serviceUtilization: {},
        programSavings: {},
        // MSK report metrics
        mskSummary: {},
        mskMemberTestimonials: {}
    }
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case SET_PDF_TEMPLATE_SUBJECT: {
            const {companyAlias, dataPath} = payload;

            return {...state, companyAlias, dataPath};
        }

        case RECEIVE_PDF_REPORT_INFO: {
            const {companyData, metrics} = payload;

            return {...state, companyData, metrics};
        }

        default: {
            return state;
        }
    }
};
