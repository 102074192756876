import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import {Field} from '../../shared/FormComponents';
import {MSK_ACCESS_MANAGEMENT_OPTIONS} from '../../../options';
import useFormState from '../../../hooks/useFormState';
import {formatDate} from '../../../utils';

const Musculoskeletal = ({isEditDisabled}) => {
    const {values} = useFormState();

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={4}>
                        <Field name='msk_access_management'>
                            {props => <Select {...props} options={MSK_ACCESS_MANAGEMENT_OPTIONS} disabled={isEditDisabled} label='Eligibility'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.msk_effective_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.msk_expiration_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                </Row>

                <Separator/>

                <Row>
                    <Column sm={4}>
                        <Input value={values.msk_product} description='This field is populated from Salesforce' disabled label='MSK Product'/>
                    </Column>
                </Row>
            </ContentSection>
        </div>
    );
};

Musculoskeletal.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {Musculoskeletal as TestableMusculoskeletal};
export default React.memo(Musculoskeletal);
