import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import {CORE_PLAN_TYPES} from '../../../constants';
import {equal, formatPhone, getEqual, getItemKeyValue, getTextFromHtml} from '../../../utils';
import './index.scss';

const TITLE_INFO_BLOCK_TYPE = 'title_info';
const CARD_PREVIEW_FIELDS = {
    cardTitle: 'card_title',
    type: 'type',
    carrierName: 'carrier_name',
    carrierUrl: 'carrier_url',
    carrierUrlAlias: 'carrier_url_alias',
    carrierPhone: 'carrier_phone',
    groupNumber: 'group_number',
    networkName: 'network_name',
    provider_network: 'provider_network',
    pharmacyBenefitManager: 'pharmacy_benefit_manager'
};
const FIELD_LABELS_MAP = {
    [CARD_PREVIEW_FIELDS.type]: 'Plan Type',
    [CARD_PREVIEW_FIELDS.carrierName]: 'Carrier',
    [CARD_PREVIEW_FIELDS.carrierUrl]: 'Website Link',
    [CARD_PREVIEW_FIELDS.carrierUrlAlias]: 'Website Alias',
    [CARD_PREVIEW_FIELDS.carrierPhone]: 'Phone Number',
    [CARD_PREVIEW_FIELDS.groupNumber]: 'Group ID',
    [CARD_PREVIEW_FIELDS.networkName]: 'Medical Network Name',
    [CARD_PREVIEW_FIELDS.provider_network]: 'Provider Network',
    [CARD_PREVIEW_FIELDS.pharmacyBenefitManager]: 'Pharmacy Benefit Manager'
};
const CORE_CUSTOM_PLAN_FIELDS = [CARD_PREVIEW_FIELDS.groupNumber, CARD_PREVIEW_FIELDS.networkName];

const getCustomFields = (blocks, fields = []) => {
    const widgets = blocks.flatMap(getItemKeyValue('widgets')) ?? [];
    const contents = widgets.flatMap(getItemKeyValue('content')) ?? [];

    return contents.reduce((acc, contentItem) => {
        const {field, value} = contentItem ?? {};

        if (!fields.some(getEqual(field))) {
            return acc;
        }

        return {...acc, [field]: getTextFromHtml(value)};
    }, {});
};

const PlanCopyPreview = ({planDetails, isCorePlan, planType, planCategory}) => {
    const {
        card_title: uncustomizedCardTitle,
        blocks = [],
        type,
        pharmacy_benefit_manager,
        carrier_name,
        carrier_url,
        carrier_url_alias,
        carrier_phone: carrierPhone,
        group_number,
        network_name,
        customized: isPlanCustomized
    } = planDetails;
    const {widgets = []} = blocks.find(getEqual(TITLE_INFO_BLOCK_TYPE, 'type')) ?? {};
    const {content: customizedCardTitle} = widgets.find(getEqual(CARD_PREVIEW_FIELDS.cardTitle, 'field')) ?? {};
    const defaultTitle = (isCorePlan ? planType : planCategory).replace('_', ' ').toUpperCase();
    const formattedCustomizedCardTitle = customizedCardTitle && getTextFromHtml(customizedCardTitle);
    const title = formattedCustomizedCardTitle || uncustomizedCardTitle || defaultTitle;
    const formattedCarrierPhone = formatPhone(carrierPhone, false);
    const {network_name: customNetworkName, ...restCustomFields} = getCustomFields(blocks, CORE_CUSTOM_PLAN_FIELDS);
    const isMedicalPlan = equal(planType, CORE_PLAN_TYPES.medical);
    const coreFields = {
        carrier_name,
        type,
        group_number,
        ...(!isMedicalPlan && {provider_network: network_name}), // FYI: For Dental && Vision plans we're showing "Provider Network" label for "network_name" field (Vlad, 10.09.21)
        ...(isMedicalPlan && {pharmacy_benefit_manager, network_name}),
        ...(isPlanCustomized && (isMedicalPlan ? {network_name: customNetworkName, ...restCustomFields} : {provider_network: customNetworkName, ...restCustomFields}))
    };
    const ancillaryFields = {carrier_name, carrier_url, carrier_url_alias, carrier_phone: formattedCarrierPhone};
    const fields = isCorePlan ? coreFields : ancillaryFields;

    return (
        <div className='plan-copy-preview'>
            <div className='plan-preview-header'>
                <Text className='plan-preview-header__title'>{title}</Text>
            </div>

            <Separator className='plan-copy-preview__separator'/>

            <Row className='plan-preview-body'>
                {Object.entries(fields).filter(([_, fieldValue]) => fieldValue).map(([fieldName, fieldValue]) => (
                    <React.Fragment key={fieldName}>
                        <Column sm={6}>
                            <Text className='plan-preview-body__text'>{FIELD_LABELS_MAP[fieldName]}:</Text>
                        </Column>
                        <Column sm={6}>
                            <Text className='plan-preview-body__text black'>{fieldValue}</Text>
                        </Column>
                    </React.Fragment>
                ))}
            </Row>
        </div>
    );
};

PlanCopyPreview.propTypes = {
    planDetails: PropTypes.shape({
        blocks: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            widgets: PropTypes.arrayOf(PropTypes.shape({
                content: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
            }))
        })),
        type: PropTypes.string,
        pharmacy_benefit_manager: PropTypes.string,
        customized: PropTypes.bool,
        card_title: PropTypes.string,
        carrier_name: PropTypes.string,
        carrier_url: PropTypes.string,
        carrier_url_alias: PropTypes.string,
        carrier_phone: PropTypes.string
    }),
    isCorePlan: PropTypes.bool,
    planType: PropTypes.string,
    planCategory: PropTypes.string
};

export {PlanCopyPreview as TestablePlanCopyPreview};
export default React.memo(PlanCopyPreview);
