import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {formatDate} from '../../../utils';
import './index.scss';

const VersionHistoryItem = ({versionInfo, isVersionHistoryItemSelected, onVersionItemClick}) => {
    const {version, created_at: createdAt, created_by_email: createdBy} = versionInfo;
    const time = formatDate(createdAt, 'hh:mm a');
    const date = formatDate(createdAt, 'MM/dd/yyyy');

    const versionHistoryItemClassName = `version-history-item__row ${isVersionHistoryItemSelected ? 'version-history-item-selected' : ''}`;
    const iconClassName = `version-history-item__icon ${isVersionHistoryItemSelected ? 'version-history-item__icon-selected' : ''}`;

    return (
        <li className='version-history-item'>
            <Row className={versionHistoryItemClassName} onClick={onVersionItemClick}>
                <Column sm={3} className='version-history-item__column version-history-item__column-version'>V {version}</Column>
                <Column sm={9} className='version-history-item__column version-history-item__column-content'>
                    <div className='version-info'>
                        <Text className='version-info-created-by'>{createdBy}</Text>
                        <Text className='version-info-created-date'>{time} . {date}</Text>
                    </div>

                    <Icon type={ICON_TYPES.arrowRight} className={iconClassName}/>
                </Column>
            </Row>
        </li>
    );
};

VersionHistoryItem.propTypes = {
    isVersionHistoryItemSelected: PropTypes.bool,
    versionInfo: PropTypes.shape({
        version: PropTypes.number,
        created_at: PropTypes.string,
        created_by_email: PropTypes.string
    }),
    onVersionItemClick: PropTypes.func
};

export {VersionHistoryItem as TestableVersionHistoryItem};
export default React.memo(VersionHistoryItem);
