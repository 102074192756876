import React, {useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Table from '@frontend/ui-kit/Components/Table';
import BroadcastTemplates from './BroadcastTemplates';
import withBroadcastOnboarding from '../../../../HOC/withBroadcastOnboarding';
import {redirectTo} from '../../../../actions/general';
import {requestBroadcasts} from '../../../../actions/broadcaster';
import {getActiveCompanyName, getProfilePermissions} from '../../../../selectors/general';
import {getBroadcasts} from '../../../../selectors/broadcaster';
import {formatDate, toCapitalize, toPercent, getTimeZoneLabelByOffset, removeOffset} from '../../../../utils';
import {BROADCAST_STATUSES, ROUTES} from '../../../../constants';
import {BROADCAST_USA_TIMEZONES_OPTIONS} from '../../../../options';
import './index.scss';

const STICKER_TYPE_BY_BROADCAST_STATUS = {
    [BROADCAST_STATUSES.draft]: STICKER_TYPES.primary,
    [BROADCAST_STATUSES.scheduled]: STICKER_TYPES.warning,
    [BROADCAST_STATUSES.canceled]: STICKER_TYPES.danger,
    [BROADCAST_STATUSES.sent]: STICKER_TYPES.success,
    [BROADCAST_STATUSES.sending]: STICKER_TYPES.success
};

const BROACAST_ALERT_TEXT = 'Please note: Your company is responsible for all messages sent to your employees';

const BROADCASTS_TABLE_COLUMNS = [
    {
        Header: 'Broadcast Title',
        accessor: 'name',
        width: 220,
        Cell: ({value}) => value ?? <Text className='broadcasts-list__cell broadcasts-list__cell_italic'>Untitled Broadcast</Text>
    },
    {Header: 'Status', accessor: 'status', isSortable: false, Cell: ({value}) => <Sticker type={STICKER_TYPE_BY_BROADCAST_STATUS[value]}>{toCapitalize(value)}</Sticker>, width: 90},
    {
        Header: 'Delivery Date and Time',
        accessor: 'scheduled_for',
        width: 210,
        Cell: ({value}) => {
            if (!value) {
                return '-';
            }
            const formattedScheduledDate = `${formatDate(removeOffset(value), 'MM/dd/yyyy h:mm aa')} ${getTimeZoneLabelByOffset(value, BROADCAST_USA_TIMEZONES_OPTIONS)}`;

            return formattedScheduledDate;
        }
    },
    {
        Header: 'Delivered',
        accessor: 'delivered_count',
        width: 100,
        Cell: ({value}) => value || '-'
    },
    {
        Header: 'Opened',
        accessor: 'state_finished_count',
        width: 100,
        Cell: ({value, row}) => {
            const {delivered_count: deliveredCount} = row.values;

            return value ? (
                <React.Fragment>
                    {value} <Text type={TEXT_TYPES.bodyBold} isInline>({toPercent(value / deliveredCount, 1)}%)</Text>
                </React.Fragment>
            ) : '-';
        }
    }
];

const WelcomeLayout = () => {
    const [unitCount, setUnitCount] = useState(0);
    const broadcasts = useSelector(getBroadcasts);
    const companyName = useSelector(getActiveCompanyName);
    const profilePermissions = useSelector(getProfilePermissions);
    const dispatch = useDispatch();

    const onClickBroadcastsTableRow = ({original}) => {
        const url = `${ROUTES.broadcastDetails}/${original.id}`;

        return dispatch(redirectTo(url));
    };

    const onFetchBroadcastsTableData = useMemo(() => async ({pageIndex, pageSize}) => {
        const params = {
            version: 2,
            limit: pageSize,
            offset: pageIndex * pageSize
        };
        const {count} = await dispatch(requestBroadcasts(params));

        setUnitCount(count);
    }, [dispatch]);

    const broadcastsTableProps = {
        className: 'broadcasts-list',
        data: broadcasts,
        columns: BROADCASTS_TABLE_COLUMNS,
        isFilterable: false,
        onFetchData: onFetchBroadcastsTableData,
        onRowClick: onClickBroadcastsTableRow,
        unitCount
    };

    return (
        <div className='broadcaster-welcome-layout'>
            <Heading type={HEADING_TYPES['4']}>Create a Broadcast</Heading>
            <Alert className='mb-12 mt-12' type={ALERT_TYPES.warning} description={BROACAST_ALERT_TEXT}/>
            {profilePermissions.broadcast_create && <BroadcastTemplates/>}

            <div className='broadcasts'>
                <Heading type={HEADING_TYPES['4']}>{companyName} Broadcasts</Heading>

                <Table {...broadcastsTableProps}/>
            </div>
        </div>
    );
};

export {WelcomeLayout as TestableWelcomeLayout};
export default withBroadcastOnboarding(WelcomeLayout);
