import {useRef, useCallback, useEffect} from 'react';

const useDebouncedCallback = (func, delay = 300) => {
    const timeout = useRef();

    useEffect(() => {
        return () => clearTimeout(timeout.current);
    }, [func, delay]);

    return useCallback((...args) => {
        const later = () => {
            clearTimeout(timeout.current);
            func(...args);
        };

        clearTimeout(timeout.current);
        timeout.current = setTimeout(later, delay);
    }, [func, delay]);
};

export default useDebouncedCallback;
