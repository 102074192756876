import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text from '@frontend/ui-kit/Components/Text';
import Link from '@frontend/ui-kit/Components/Link';
import Select from '@frontend/ui-kit/Components/Select';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import FileSource, {validateOnSubmit as validateFileSourceOnSubmit} from '../FileSource';
import {Field} from '../../shared/FormComponents';
import withEligibilityDetailsForm from '../../../HOC/withEligibilityDetailsForm';
import useFormState from '../../../hooks/useFormState';
import {requestCorePlansInfo} from '../../../actions/eligibility';
import {getPlans} from '../../../selectors/eligibility';
import {getEqual, normalizeBoolean} from '../../../utils';
import {ROUTES} from '../../../constants';
import {
    MEDICAL_ID_FREQUENCY_OPTIONS,
    CONDITION_OPTIONS,
    SYSTEM_NAME_OPTIONS,
    UNIQUE_MEMBER_IDENTIFIER_OPTIONS
} from '../../../options';

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateFileSourceOnSubmit(values)});

const MedicalIds = ({isReadonly}) => {
    const dispatch = useDispatch();
    const {planPeriodId} = useParams();
    const {medical: medicalPlans} = useSelector(getPlans);
    const {values} = useFormState();
    const {unique_member_key: uniqueMemberKey} = values;
    const {label: uniqMemberKeyName} = useMemo(() => UNIQUE_MEMBER_IDENTIFIER_OPTIONS.find(getEqual(uniqueMemberKey, 'value')) || {}, [uniqueMemberKey]);

    useEffect(() => {
        dispatch(requestCorePlansInfo(planPeriodId));
    }, [dispatch, planPeriodId]);

    const getPlan = ({name, source}, index) => {
        const {label: systemName} = SYSTEM_NAME_OPTIONS.find(getEqual(source.system_name, 'value')) || {};

        return (
            <Column sm={10} className='overview-selection-body overview-selection-body_with-indent eligibility-details-form-row__column' key={index}>
                <Row className='eligibility-details-form-row'>
                    <Column sm={6} className='eligibility-details-form-row__column'>Plan name:</Column>
                    <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{name}</Column>
                </Row>

                <Row className='eligibility-details-form-row'>
                    <Column sm={6} className='eligibility-details-form-row__column'>Where will the Medical IDs be coming from?</Column>
                    <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{systemName}</Column>
                </Row>
            </Column>
        );
    };

    return (
        <div className='eligibility-details-form medical-ids-file'>
            <Separator/>

            <Row className='eligibility-details-form-row'>
                <Column className='eligibility-details-form-row__column' sm>
                    <Row className='eligibility-details-form-row'>
                        <Column className='eligibility-details-form-row__column' sm={6}>
                            <Field name='more_than_half' parse={normalizeBoolean}>
                                {props => <RadioGroup {...props} readonly={isReadonly} options={CONDITION_OPTIONS} label='Will you be providing Medical ID’s for at least 50% of employees in the file?' wrapperClassName='mb-7'/>}
                            </Field>

                            <Field name='update_frequency'>
                                {props => <Select {...props} readonly={isReadonly} options={MEDICAL_ID_FREQUENCY_OPTIONS} label='How often will we receive an updated Medical ID file?'/>}
                            </Field>
                        </Column>

                        <Column className='eligibility-details-form-row__column' sm={6}>
                            <FileSource isReadonly={isReadonly} isMedicalIDs/>
                        </Column>
                    </Row>

                    <Separator/>

                    <Row className='overview-selection eligibility-details-form-row'>
                        <Column sm={12} className='eligibility-details-form-row__column'>
                            <Row className='overview-selection-heading eligibility-details-form-row'>
                                <Column sm={10} className='eligibility-details-form-row__column'>
                                    <Text className='overview-selection-heading__title'>Overview Selections</Text>
                                </Column>

                                <Column sm={2} className='edit-wrapper eligibility-details-form-row__column'>
                                    {!isReadonly && (
                                        <Link className='edit-wrapper-link' href={`${ROUTES.eligibility}/${planPeriodId}/implementation`} data-testid='edit-link'>
                                            <Icon className='edit-wrapper-link__link' type={ICON_TYPES.edit}/>

                                            <Text className='edit-wrapper-link__title'>Edit</Text>
                                        </Link>
                                    )}
                                </Column>
                            </Row>
                        </Column>

                        <Column sm={10} className='overview-selection-body overview-selection-body_with-indent eligibility-details-form-row__column'>
                            <Row className='eligibility-details-form-row'>
                                <Column sm={6} className='eligibility-details-form-row__column'>Unique member identifier</Column>
                                <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{uniqMemberKeyName}</Column>
                            </Row>
                        </Column>

                        {medicalPlans.map(getPlan)}
                    </Row>
                </Column>
            </Row>
        </div>
    );
};

MedicalIds.propTypes = {
    isReadonly: PropTypes.bool.isRequired
};

export {MedicalIds as TestableMedicalIds};
export default withEligibilityDetailsForm({validateOnSubmit})(MedicalIds);
