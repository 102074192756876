import React from 'react';
import PageContent from '../PageContent';
import TextSection from '../TextSection';

const ProgramSavingsFormulaContinuedFifth = () => (
    <PageContent title='2.1 Program Savings Formula Continued'>
        <TextSection title='Administrative Savings' className='mb-default'>
            Calculated as the sum of Health Coverage clicks, Wallet Card clicks, and benefit question tickets.
            We multiply the sum by $8.80 as the estimated value added per administrative connection to calculate the total administrative savings value.
        </TextSection>
    </PageContent>
);

export {ProgramSavingsFormulaContinuedFifth as TestableProgramSavingsFormulaContinuedFifth};
export default ProgramSavingsFormulaContinuedFifth;
