import React from 'react';
import PageContent from '../PageContent';
import PdfTable from '../PdfTable';
import PdfText from '../PdfText';
import './index.scss';

const TABLE_DATA = [
    {
        category: 'PCP (Non-Hospital)',
        savings: '$280',
        explanation: (
            <React.Fragment>
                Individuals redirected to non-hospital-based and lower-priced PCP visits are costing $700 less in total spend (Source: HealthAffairs.org).²
                ~40% of PCP practices are owned or partially owned by hospital systems (Source: American Medical Association).<sup>3</sup>&nbsp;
                $700 saved on 40% of PCP requests (those who would have otherwise gone to a high-cost PCP) = $280 average savings.
            </React.Fragment>
        )
    },
    {
        category: 'Self-serve Care Searches',
        savings: '$100',
        explanation: (
            <React.Fragment>
                <b>Only applicable for clients with Find Care. </b>
                In-network searches done by members across any provider, specialty, or facility that did not result in a ticket to our concierge team.
                At a minimum, we can guarantee that the client/member will see savings that reflect the difference between an in-network vs. an out-of-network visit.
                Since this is how we calculate provider verification savings, we are attributing the same savings per service to searches performed by members on their own.
            </React.Fragment>
        )
    },
    {
        category: 'Specialist (Non-Hospital)',
        savings: '$300',
        explanation: (
            <React.Fragment>
                Patients avoid high hospital bills and unnecessary surgeries in hospital settings.
                $300 savings value is based on HealthJoy’s historical recommendations where we’ve identified physicians with lower-cost visits.
                For surgical specialties we find physicians who are credentialed at Centers of Excellence and Ambulatory Surgical Centers (fewer complications after procedures, reputable, lower facility fees).
            </React.Fragment>
        )
    }
];

const ProgramSavingsFormulaContinuedSecond = () => {
    const tableProps = {
        className: 'mb-small',
        columns: [
            {header: 'Category', accessor: 'category'},
            {header: 'Savings Per Service', accessor: 'savings', width: '34mm', textAlign: 'center'},
            {header: 'Explanation', accessor: 'explanation'}
        ],
        data: TABLE_DATA
    };

    return (
        <PageContent title='2.1 Program Savings Formula Continued' className='program-savings-formula-continued'>
            <PdfText className='mb-extra-small' type='body-bold'>Provider/Facility Steerage Savings Breakdown (continued)</PdfText>

            <PdfTable {...tableProps}/>

            <PdfText className='program-savings-formula-continued__table-explanation' type='helper'>
                <sup>2</sup>. Ateev Mehrotra et al, “Patients Who Choose Primary Care Physicians Based On Low Office Visit Price Can Realize Broader Savings” (2016), Health Affairs
            </PdfText>
            <PdfText className='program-savings-formula-continued__table-explanation' type='helper'>
                <sup>3</sup>. Robert J. Mills, “AMA analysis shows most physicians work outside of private practice” (2021), AMA
            </PdfText>
        </PageContent>
    );
};

export {ProgramSavingsFormulaContinuedSecond as TestableProgramSavingsFormulaContinuedSecond};
export default ProgramSavingsFormulaContinuedSecond;
