import React from 'react';
import PropTypes from 'prop-types';
import Table from '@frontend/ui-kit/Components/Table';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Link from '@frontend/ui-kit/Components/Link';
import {equal, formatDate, isEmpty, isValidDate, trimByMaxLength} from '../../../utils';
import './index.scss';

const NO_INFORMATION = '[no information]';
const INFORMATION_DELETED = '[information deleted]';
const MAX_FIELD_LENGTH = 50;

const getVersionCell = ({column: {id}, value}) => {
    if (!value) {
        return equal(id, 'prev_version') ? NO_INFORMATION : INFORMATION_DELETED;
    }

    if (Array.isArray(value)) {
        return <React.Fragment>{value.map(element => <div key={element}>{element}</div>)}</React.Fragment>;
    }

    if (isValidDate(value)) {
        return formatDate(value, 'M/d/yyyy');
    }

    if (['http', 'https'].some(protocol => value.startsWith(protocol))) {
        return (
            <div>
                <Link href={value} target='_blank'>
                    {trimByMaxLength(value, MAX_FIELD_LENGTH, '...', '')}
                    <Icon type={ICON_TYPES.externalLink} className='version-history-details-link'/>
                </Link>
            </div>
        );
    }

    return value;
};

const TABLE_COLUMNS = [
    {
        Header: 'Field',
        accessor: 'label',
        width: 200
    },
    {
        Header: 'Previous Version',
        accessor: 'prev_version',
        minWidth: 200,
        Cell: getVersionCell
    },
    {
        Header: 'New Version',
        accessor: 'new_version',
        minWidth: 200,
        Cell: getVersionCell
    }
];

const VersionHistoryDetails = ({edited = [], added = [], deleted = []}) => {
    const tableProps = {isSortable: false, isFilterable: false, columns: TABLE_COLUMNS};
    const details = [{data: edited, title: 'Edited'}, {data: added, title: 'Added'}, {data: deleted, title: 'Deleted'}];

    return details.filter(({data}) => !isEmpty(data)).map(({data, title}) => (
        <React.Fragment key={title}>
            <Heading type={HEADING_TYPES['4']} className={`version-history-details-heading version-history-details-heading-${title.toLowerCase()}`}>{title}</Heading>
            <Table {...tableProps} data={data}/>
        </React.Fragment>
    ));
};

VersionHistoryDetails.propTypes = {
    added: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        prev_version: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        new_version: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
    })),
    edited: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        prev_version: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        new_version: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
    })),
    deleted: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        prev_version: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        new_version: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
    }))
};

export {VersionHistoryDetails as TestableVersionHistoryDetails};
export default React.memo(VersionHistoryDetails);
