import React, {useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import Select from '@frontend/ui-kit/Components/Select';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import {requestCorePlansList, requestCorePlanDetailsForCopying} from '../../../actions/benefits';
import {getActiveCompany} from '../../../selectors/general';
import {CORE_PLAN_TYPES} from '../../../constants';
import {getEqual} from '../../../utils';

const PlanCopyRxFromMedicalPopup = ({planPeriodId, onSave, onClose}) => {
    const dispatch = useDispatch();
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [plans, setPlans] = useState([]);
    const {alias: companyAlias} = useSelector(getActiveCompany);
    const planOptions = useMemo(() => plans.map(({name, id}) => ({label: name, value: id})), [plans]);
    const {completed: isSelectedPlanCompleted} = plans.find(getEqual(selectedPlanId, 'id')) ?? {};

    const onCopy = useCallback(async () => {
        const {planDetails} = await dispatch(requestCorePlanDetailsForCopying(companyAlias, CORE_PLAN_TYPES.medical, selectedPlanId));

        onSave(planDetails);
        onClose();
    }, [dispatch, companyAlias, selectedPlanId, onSave, onClose]);

    useEffect(() => {
        (async () => {
            const params = {
                company_alias: companyAlias,
                plan_period_id: planPeriodId,
                plan_kinds: [CORE_PLAN_TYPES.medical]
            };
            const {plans} = await dispatch(requestCorePlansList(params));

            setPlans(plans);
        })();
    }, [dispatch, companyAlias, planPeriodId]);

    const actionBar = (
        <React.Fragment>
            <Button onClick={onClose} type={BUTTON_TYPES.secondary}>Cancel</Button>
            <Button onClick={onCopy} disabled={!selectedPlanId}>Copy</Button>
        </React.Fragment>
    );

    return (
        <PopupContent title='Copy Rx Data From Medical Plan' actionBar={actionBar}>
            {selectedPlanId && !isSelectedPlanCompleted && (
                <Alert className='mb-12'
                    type={ALERT_TYPES.warning}
                    description='This medical plan is not marked as done and information may change.'/>
            )}

            <Text className='mb-12'>Select the plan from which you’d like to copy over data.</Text>

            <Select value={selectedPlanId} options={planOptions} onChange={setSelectedPlanId} isMenuPortal label='Select Plan'/>
        </PopupContent>
    );
};

PlanCopyRxFromMedicalPopup.propTypes = {
    planPeriodId: PropTypes.number.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export {PlanCopyRxFromMedicalPopup as TestablePlanCopyRxFromMedicalPopup};
export default React.memo(PlanCopyRxFromMedicalPopup);
