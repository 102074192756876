import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Separator from '@frontend/ui-kit/Components/Separator';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import OverviewStepHeader from '../OverviewStepHeader';
import {Form, Field, FieldArray} from '../../shared/FormComponents';
import AssignableActionBar from '../../shared/AssignableActionBar';
import Notation from '../../shared/Notation';
import BenefitsClassesFormPopup from '../BenefitsClassesFormPopup';
import withPopup from '../../../HOC/withPopup';
import {requestAncillaryPlansInfo, requestAncillaryPlansInfoUpdating} from '../../../actions/eligibility';
import {requestBenefitsClassesInfo} from '../../../actions/company';
import {getBenefitsClasses} from '../../../selectors/company';
import {equal, validateRequired} from '../../../utils';
import {FORMS} from '../../../constants';
import {ENROLMENT_OPTIONS, WALLET_VISIBILITY_OPTIONS} from '../../../options';
import './index.scss';

const POPUP_ID = 'companyClasses';

/* istanbul ignore next */
const validate = values => {
    const {plans = [], voluntary_source: voluntarySource} = values;

    return {
        voluntary_source: validateRequired(voluntarySource),
        plans: plans.map(({eligible_members: eligibleMembers, classes}) => ({
            eligible_members: validateRequired(eligibleMembers),
            classes: equal(eligibleMembers, 'classes') ? validateRequired(classes) : undefined
        }))
    };
};

const AncillaryStep = ({isReadonly, openPopup, closePopup, ...restProps}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});
    const {planPeriodId} = useParams();
    const benefitsClasses = useSelector(getBenefitsClasses);

    useEffect(() => {
        (async () => {
            dispatch(requestBenefitsClassesInfo(planPeriodId));
            const {ancillaryPlansInfo, isSuccess} = await dispatch(requestAncillaryPlansInfo(planPeriodId));

            if (isSuccess) {
                setInitialValues(ancillaryPlansInfo);
            }
        })();
    }, [dispatch, planPeriodId]);

    const onOpenBenefitsClassesPopup = () => {
        const children = <BenefitsClassesFormPopup onClose={closePopup}/>;

        openPopup({type: POPUP_TYPES.right, children});
    };

    const getPlans = ({fields}) => fields.map((field, index) => {
        const {name, category, title, eligible_members: eligibleMembers, category_index: categoryIndex} = fields.value[index];
        const {category: nextPlanCategory} = fields.value[index + 1] || {};

        const isCardTitleAvail = title && title.trim() && name && !equal(title.toLowerCase().trim(), name.toLowerCase().trim());
        const cardTitle = isCardTitleAvail ? `(${title.trim()})` : '';
        const label = [name, cardTitle].filter(Boolean).join(' ');

        return (
            <React.Fragment key={field}>
                <Row className='selected-benefits'>
                    <Column className='selected-benefits__column' sm={6}>
                        <Text>
                            {categoryIndex && <span className='selected-benefits_num'>{categoryIndex} </span>}
                            {label} *
                        </Text>
                    </Column>

                    <Column className='selected-benefits__column' sm={6}>
                        <Field name={`${field}.eligible_members`}>
                            {props => <Select {...props} readonly={isReadonly} options={WALLET_VISIBILITY_OPTIONS}/>}
                        </Field>

                        {equal(eligibleMembers, 'classes') && (
                            <Field name={`${field}.classes`}>
                                {props => <Select {...props} readonly={isReadonly} options={benefitsClasses} isMulti placeholder='Select classes...' className='mt-12' label='Select All Applicable Benefit Classes'/>}
                            </Field>
                        )}
                    </Column>
                </Row>

                {!equal(category, nextPlanCategory) && <Separator/>}
            </React.Fragment>
        );
    });

    const onSubmit = useCallback(async values => {
        const {ancillaryPlansInfo, isSuccess, submissionErrors} = await dispatch(requestAncillaryPlansInfoUpdating(values));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(ancillaryPlansInfo);
    }, [dispatch]);

    return (
        <React.Fragment>
            <OverviewStepHeader/>

            <Form name={FORMS.eligibilityAncillaryPlansInfo} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit}) => {
                    return (
                        <React.Fragment>
                            <form data-testid='eligibility-wizard-step-ancillary' className='eligibility-wizard-step eligibility-wizard-step_ancillary'>
                                <Row>
                                    <Column sm>
                                        <Row>
                                            <Field name='id'>{props => <Input {...props} type='hidden'/>}</Field>
                                            <Field name='plan_period_id'>{props => <Input {...props} type='hidden'/>}</Field>

                                            <Column sm={8}>
                                                <Field name='voluntary_source'>
                                                    {props => <Select {...props} readonly={isReadonly} options={ENROLMENT_OPTIONS} label='Where will the voluntary elections be coming from?' isRequired wrapperClassName='mb-12'/>}
                                                </Field>

                                                {!isReadonly && (
                                                    <Button className='benefits-classes-button' data-testid='add-benefits-classes-button' type={BUTTON_TYPES.secondary} onClick={onOpenBenefitsClassesPopup}>
                                                        <Icon type={ICON_TYPES.circlePlus}/> Add Benefits Classes
                                                    </Button>
                                                )}

                                                <Separator type='solid'/>

                                                <Row className='sub-heading'>
                                                    <Column className='sub-heading__column' sm={6}>
                                                        <Text type={TEXT_TYPES.bodyBold}>Selected Benefit</Text>
                                                    </Column>

                                                    <Column className='sub-heading__column' sm={6}>
                                                        <Text type={TEXT_TYPES.bodyBold}>Who should see this in their HealthJoy Benefits Wallet?</Text>
                                                    </Column>
                                                </Row>

                                                <FieldArray name='plans'>{getPlans}</FieldArray>
                                            </Column>
                                        </Row>
                                    </Column>

                                    <Column constant className='note'>
                                        <Notation title='Add something about classes'>
                                            If election amounts for a benefit vary and are listed for each employee in the eligibility file, please select 'Enrollments for this Benefit will be populated in the eligibility file'.
                                        </Notation>
                                    </Column>
                                </Row>
                            </form>

                            <AssignableActionBar {...restProps} isReadonly={isReadonly} onSubmitStep={handleSubmit}/>
                        </React.Fragment>
                    );
                }}
            </Form>
        </React.Fragment>
    );
};

AncillaryStep.propTypes = {
    isReadonly: PropTypes.bool.isRequired,
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {AncillaryStep as TestableAncillaryStep};
export default withPopup(POPUP_ID)(AncillaryStep);
