import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {getItemKeyValue, isEmpty} from '../../../utils';
import {WALLET_VISIBILITY_OPTIONS} from '../../../options';
import './index.scss';

const VISIBILITY_LABEL_BY_ELIGIBLE_MEMBERS = WALLET_VISIBILITY_OPTIONS.reduce((acc, {label, value}) => {
    return {...acc, [value]: label};
}, {});

const getPlan = plan => {
    const {id, name, category_index: categoryIndex, eligible_members: eligibleMembers, classes} = plan;
    const visibilityLabel = VISIBILITY_LABEL_BY_ELIGIBLE_MEMBERS[eligibleMembers];

    return (
        <Row key={id} className='plan-list__plan'>
            <Column sm={2}><Text>{name} {categoryIndex}</Text></Column>

            <Column sm={4}>
                <Text type={TEXT_TYPES.bodyBold}>{visibilityLabel}</Text>
            </Column>

            <Column sm={6}>
                <Text>{classes.map(getItemKeyValue('class_name')).join(', ')}</Text>
            </Column>
        </Row>
    );
};

const EligibilityAncillaryBenefitsSummary = ({plans}) => {
    const arePlans = !isEmpty(plans);

    return (
        <div className='eligibility-ancillary-benefits-summary'>
            {arePlans && (
                <React.Fragment>
                    <Row className='plan-list-header'>
                        <Column sm={2}>
                            <Text type={TEXT_TYPES.bodyBold}>Selected Benefit</Text>
                        </Column>

                        <Column sm={10}>
                            <Text type={TEXT_TYPES.bodyBold}>Who should see this in their HealthJoy Benefits Wallet?</Text>
                        </Column>
                    </Row>

                    <div className='plan-list'>{plans.map(getPlan)}</div>
                </React.Fragment>
            )}

            {!arePlans && <Text className='eligibility-ancillary-benefits-summary__placeholder'>No Benefits selected</Text>}
        </div>
    );
};

EligibilityAncillaryBenefitsSummary.propTypes = {
    plans: PropTypes.arrayOf(PropTypes.shape({}))
};

export {EligibilityAncillaryBenefitsSummary as TestableEligibilityAncillaryBenefitsSummary};
export default React.memo(EligibilityAncillaryBenefitsSummary);
