import {
    ADD_ADMIN_CONTACT,
    RECEIVE_ADMIN_CONTACTS,
    CLEAR_ADMIN_CONTACTS,
    RECEIVE_IMPLEMENTATION_MANAGERS_LIST,
    RECEIVE_IMPLEMENTATION_SPECIALISTS_LIST,
    SET_EXPANDED_TIMELINE_STEP,
    RECEIVE_CSM_LIST
} from '../actions/actionTypes';

export const initialState = {
    expandedTimelineStep: 0,
    adminContacts: [],
    implementationManagersList: [],
    implementationSpecialists: [],
    csmList: []
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case SET_EXPANDED_TIMELINE_STEP: {
            const {expandedTimelineStep} = payload;

            return {...state, expandedTimelineStep};
        }

        case RECEIVE_IMPLEMENTATION_MANAGERS_LIST: {
            const {implementationManagersList} = payload;

            return {...state, implementationManagersList};
        }

        case RECEIVE_IMPLEMENTATION_SPECIALISTS_LIST: {
            const {implementationSpecialists} = payload;

            return {...state, implementationSpecialists};
        }

        case RECEIVE_CSM_LIST: {
            const {csmList} = payload;

            return {...state, csmList};
        }

        case RECEIVE_ADMIN_CONTACTS: {
            const {adminContacts} = payload;

            return {...state, adminContacts};
        }

        case CLEAR_ADMIN_CONTACTS: {
            const adminContacts = [];

            return {...state, adminContacts};
        }

        case ADD_ADMIN_CONTACT: {
            const {adminContact} = payload;
            const adminContacts = [...state.adminContacts, adminContact];

            return {...state, adminContacts};
        }

        default: {
            return state;
        }
    }
};
