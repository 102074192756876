import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text from '@frontend/ui-kit/Components/Text';
import Link from '@frontend/ui-kit/Components/Link';
import Select from '@frontend/ui-kit/Components/Select';
import Input from '@frontend/ui-kit/Components/Input';
import FileSource, {validateOnSubmit as validateFileSourceOnSubmit} from '../FileSource';
import {Field} from '../../shared/FormComponents';
import withEligibilityDetailsForm from '../../../HOC/withEligibilityDetailsForm';
import useFormState from '../../../hooks/useFormState';
import {equal, getEqual, validateRequired} from '../../../utils';
import {ROUTES, ACCESS_RESTRICTION_TO_HJ_TYPES, ELIGIBILITY_FILES_SOURCES} from '../../../constants';
import {
    ACCESS_RESTRICTION_TO_HJ_OPTIONS,
    ELIGIBILITY_UPDATE_FREQUENCY_OPTIONS,
    INVITATION_OPTIONS,
    UNIQUE_MEMBER_IDENTIFIER_OPTIONS,
    ENROLMENT_SOURCE_OPTIONS
} from '../../../options';

/* istanbul ignore next */
const validateOnSubmit = values => ({...validateFileSourceOnSubmit(values)});
/* istanbul ignore next */
const validate = values => {
    const {eligibility_fire_attr: accessRestrictions, eligibility_fire_attr_description: accessRestrictionDescription} = values;
    const isAccessRestrictionDescription = accessRestrictions?.includes(ACCESS_RESTRICTION_TO_HJ_TYPES.other);

    return {
        eligibility_fire_attr_description: isAccessRestrictionDescription ? validateRequired(accessRestrictionDescription) : undefined,
    };
};

const EligibilityCensusFile = ({isReadonly}) => {
    const {planPeriodId} = useParams();
    const {values} = useFormState();
    const {
        eligibility_fire_attr: accessRestrictions,
        eligibility_fire_attr_description: accessRestrictionDescription,
        enrollment_source: enrollmentSource,
        enrollment_system: enrollmentSystem,
        unique_member_key: uniqMemberKey
    } = values;
    const isEnrollmentSourceNavigator = equal(enrollmentSource, ELIGIBILITY_FILES_SOURCES.employeeNavigator);
    const isAccessRestrictionDescription = useMemo(() => accessRestrictions?.includes(ACCESS_RESTRICTION_TO_HJ_TYPES.other), [accessRestrictions]);
    const {label: uniqMemberKeyName} = useMemo(() => UNIQUE_MEMBER_IDENTIFIER_OPTIONS.find(getEqual(uniqMemberKey, 'value')) || {}, [uniqMemberKey]);
    const {label: enrollmentSourceName} = useMemo(() => ENROLMENT_SOURCE_OPTIONS.find(getEqual(enrollmentSource, 'value')) || {}, [enrollmentSource]);

    const onChangeFileSource = (value, form) => {
        if (![ELIGIBILITY_FILES_SOURCES.sftp, ELIGIBILITY_FILES_SOURCES.employeeNavigator].includes(value)) {
            return;
        }

        const updatedAccessRestrictions = accessRestrictions?.includes(ACCESS_RESTRICTION_TO_HJ_TYPES.notAppear)
            ? accessRestrictions
            : [ACCESS_RESTRICTION_TO_HJ_TYPES.notAppear].concat(accessRestrictions).filter(Boolean);
        form.change('eligibility_fire_attr', updatedAccessRestrictions);
    };

    const onChangeAccessRestriction = (value, form) => {
        if (value.includes(ACCESS_RESTRICTION_TO_HJ_TYPES.other) || !accessRestrictionDescription) {
            return;
        }

        form.change('eligibility_fire_attr_description', null);
    };

    return (
        <div className='eligibility-details-form eligibility-census-file'>
            <Separator/>

            <Row className='eligibility-details-form-row'>
                <Column className='eligibility-details-form-row__column' sm>
                    <Row className='eligibility-details-form-row'>
                        <Column className='eligibility-details-form-row__column' sm={6}>
                            <Field name='eligibility_update_frequency'>
                                {props => <Select {...props} readonly={isReadonly} options={ELIGIBILITY_UPDATE_FREQUENCY_OPTIONS} label='How often will you be sending a new eligibility file?' wrapperClassName='mb-12'/>}
                            </Field>

                            <Field name='eligibility_fire_attr' onChange={onChangeAccessRestriction}>
                                {props => <Select {...props} readonly={isReadonly} options={ACCESS_RESTRICTION_TO_HJ_OPTIONS} isMulti label='How do we know someone should no longer have access to HealthJoy?' wrapperClassName='mb-12'/>}
                            </Field>

                            {isAccessRestrictionDescription && (
                                <Field name='eligibility_fire_attr_description'>
                                    {props => <Input {...props} readonly={isReadonly} label='Other Description' maxLength={200} wrapperClassName='mb-12'/>}
                                </Field>
                            )}

                            <Field name='eligibility_invite_when'>
                                {props => <Select {...props} readonly={isReadonly} options={INVITATION_OPTIONS} isMulti label='When should someone receive an invite to HealthJoy?'/>}
                            </Field>
                        </Column>

                        <Column className='eligibility-details-form-row__column' sm={6}>
                            <FileSource isReadonly={isReadonly} onChangeSource={onChangeFileSource}/>
                        </Column>
                    </Row>

                    <Separator/>

                    <Row className='overview-selection eligibility-details-form-row'>
                        <Column className='eligibility-details-form-row__column' sm={12}>
                            <Row className='overview-selection-heading eligibility-details-form-row'>
                                <Column className='eligibility-details-form-row__column' sm={10}>
                                    <Text className='overview-selection-heading__title'>Overview Selections</Text>
                                </Column>

                                <Column className='edit-wrapper eligibility-details-form-row__column' sm={2}>
                                    {!isReadonly && (
                                        <Link className='edit-wrapper-link' href={`${ROUTES.eligibility}/${planPeriodId}/implementation`} data-testid='edit-link'>
                                            <Icon className='edit-wrapper-link__link' type={ICON_TYPES.edit}/>

                                            <Text className='edit-wrapper-link__title'>Edit</Text>
                                        </Link>
                                    )}
                                </Column>

                                <Column sm={10} className='overview-selection-body overview-selection-body_with-indent eligibility-details-form-row__column'>
                                    <Row className='eligibility-details-form-row'>
                                        <Column sm={6} className='eligibility-details-form-row__column'>Unique member identifier</Column>

                                        <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{uniqMemberKeyName}</Column>
                                    </Row>

                                    <Row className='eligibility-details-form-row'>
                                        <Column sm={6} className='eligibility-details-form-row__column'>How do you collect employee enrollment information?</Column>

                                        <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{enrollmentSourceName}</Column>
                                    </Row>

                                    {!isEnrollmentSourceNavigator && (
                                        <Row className='eligibility-details-form-row'>
                                            <Column sm={6} className='eligibility-details-form-row__column'>Which system?</Column>

                                            <Column sm={6} className='overview-selection-body__value eligibility-details-form-row__column'>{enrollmentSystem}</Column>
                                        </Row>
                                    )}
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Column>
            </Row>
        </div>
    );
};

EligibilityCensusFile.propTypes = {
    isReadonly: PropTypes.bool.isRequired
};

export {EligibilityCensusFile as TestableEligibilityCensusFile};
export default withEligibilityDetailsForm({validate, validateOnSubmit})(EligibilityCensusFile);
