import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import useForm from '../../../../../hooks/useForm';
import {equal} from '../../../../../utils';
import {BROADCASTER_LINK_TYPES} from '../../../../../constants';

const LINK_TYPE_ICONS = [
    {linkType: BROADCASTER_LINK_TYPES.wallet, iconType: ICON_TYPES.wallet},
    {linkType: BROADCASTER_LINK_TYPES.web, iconType: ICON_TYPES.link},
    {linkType: BROADCASTER_LINK_TYPES.file, iconType: ICON_TYPES.fileFull}
];
const LinkIcon = ({linkIcon, isActive}) => {
    const form = useForm();
    const selectLink = linkIcon => {
        form.batch(() => {
            form.change('content.entry.link_type', linkIcon.linkType);
            form.change('content.entry.link', null);
        });
    };
    return (
        <Icon type={linkIcon.iconType}
            className={classnames('link-source-switcher__icon', {
                'link-source-switcher__icon_active': isActive
            })}
            onClick={() => selectLink(linkIcon)}/>
    );
};

LinkIcon.propTypes = {
    isActive: PropTypes.bool,
    linkIcon: PropTypes.objectOf(PropTypes.shape(
        {linkType: PropTypes.string, iconType: PropTypes.string}
    )).isRequired
};

export const LinkIcons = ({linkType}) => {
    return (
        <ContentSection className='link-source-switcher mb-12'>
            {LINK_TYPE_ICONS.map(linkIcon => (
                <LinkIcon key={linkIcon.linkType} linkIcon={linkIcon} isActive={equal(linkType, linkIcon.linkType)}/>
            ))}
        </ContentSection>
    );
};

LinkIcons.propTypes = {
    linkType: PropTypes.string
};
