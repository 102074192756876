import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import OpenEnrollmentForm from '../../../Components/Overview/OpenEnrollmentForm';

const OpenEnrollment = () => (
    <React.Fragment>
        <Row>
            <Column sm>
                <PageHeader title='Open Enrollment' caption='Please provide us with the following information pertaining to open enrollment.'/>
            </Column>
        </Row>

        <OpenEnrollmentForm/>
    </React.Fragment>
);

export {OpenEnrollment as TestableOpenEnrollment};
export default React.memo(OpenEnrollment);
