import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import WelcomeLayoutV1 from '../../../Components/Broadcaster/BroadcastV1/WelcomeLayout';
import WelcomeLayoutV2 from '../../../Components/Broadcaster/BroadcastV2/WelcomeLayout';
import {requestFeatureFlag} from '../../../actions/general';
import {FEATURE_FLAGS} from '../../../constants';

const PAGE_HEADER_CAPTION = 'Home for your benefits communication. Start with a template or create a custom broadcast.';

const Welcome = () => {
    const dispatch = useDispatch();
    // TODO: remove isNewBroadcasterUI and isReady once BroadcasterV2 is released (14.05.2024, Oleh);
    const [isNewBroadcasterUI, setIsNewBroadcasterUI] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        (async () => {
            const {flag: isNewBroadcast} = await dispatch(requestFeatureFlag(FEATURE_FLAGS.NewBroadcasterUI));

            setIsNewBroadcasterUI(isNewBroadcast);
            setIsReady(true);
        })();
    }, [dispatch]);

    return (
        <React.Fragment>
            <Row>
                <Column sm={9}>
                    <PageHeader title='Welcome to Broadcaster' caption={PAGE_HEADER_CAPTION}/>
                </Column>
            </Row>

            {isReady && (isNewBroadcasterUI ? <WelcomeLayoutV2/> : <WelcomeLayoutV1/>)}
        </React.Fragment>
    );
};

export {Welcome as TestableWelcome};
export default React.memo(Welcome);
