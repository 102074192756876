import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '@frontend/ui-kit/Components/LineChart';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageContent from '../PageContent';
import TextSection from '../TextSection';
import PdfChart from '../PdfChart';
import PdfReportBox from '../PdfReportBox';
import {getDelimitedNum} from '../../../utils';
import {SATISFACTION_REVIEWS_MIN_LIMIT} from '../../../constants';

const PerformanceSummary = props => {
    const {
        activationChartData,
        isRoiVisible,
        totalSavings,
        roi,
        utilizationTicketsCount,
        activatedEmployeesCount,
        eligibleEmployeesCount,
        activationRating,
        memberSatisfaction,
        satisfactionTotalReviews
    } = props;
    const activationChartProps = {
        Chart: LineChart,
        title: 'Activation Rate',
        description: `
            Activation rate is calculated as the number of distinct activated HealthJoy-eligible employees divided by
            the total number of distinct HealthJoy-eligible employees eligible.
        `,
        unit: '%',
        data: activationChartData,
        options: {scales: {yAxes: [{ticks: {max: 100}}]}}
    };

    return (
        <PageContent title='1.0 Performance Summary'>
            <Row className='pdf-template-row'>
                <Column className='pdf-template-row__column' xs={7}>
                    <PdfChart {...activationChartProps}/>
                </Column>

                <Column className='pdf-template-row__column' xs={5}>
                    <TextSection title='Employee Activation'>
                        Calculated as the number of distinct activated HealthJoy-eligible employees, divided by the total
                        number of distinct HealthJoy-eligible employees. An employee is activated when they have registered for
                        their HealthJoy account online or by calling our concierge team.
                    </TextSection>
                </Column>
            </Row>

            <Row className='pdf-template-row pdf-template-row_small mt-small'>
                <Column className='pdf-template-row__column' xs={6}>
                    {isRoiVisible && (
                        <PdfReportBox className='performance-report-box'
                            title='Return on Investment'
                            caption={`$${getDelimitedNum(totalSavings)} Saved`}
                            description="Calculated as the difference between the Total Company Savings and the Company's Prorated Investment."
                            value={roi}
                            isPlaceholder={!roi}
                            unit='x'/>
                    )}

                    {!isRoiVisible && (
                        <PdfReportBox className='performance-report-box'
                            title='Total Savings'
                            caption='Claims Savings Estimate'
                            description='Sum total of estimated savings for each service and administrative savings.'
                            value={getDelimitedNum(totalSavings)}
                            unit='$'/>
                    )}
                </Column>

                <Column className='pdf-template-row__column' xs={6}>
                    <PdfReportBox className='performance-report-box'
                        title='Member Utilization'
                        caption='Total Number of Unique Actions'
                        description='Includes Benefit Wallet views and Service Requests that have been completed for your company.'
                        value={getDelimitedNum(utilizationTicketsCount)}/>
                </Column>
            </Row>
            <Row className='pdf-template-row pdf-template-row_small mt-small'>
                <Column className='pdf-template-row__column' xs={6}>
                    <PdfReportBox className='performance-report-box'
                        title='Activated Employees'
                        caption={`${activatedEmployeesCount} of ${eligibleEmployeesCount} Eligible Employees`}
                        description='An employee is considered activated once they register their HealthJoy account online or by calling our concierge team.'
                        value={activationRating}
                        unit='%'/>
                </Column>

                <Column className='pdf-template-row__column' xs={6}>
                    <PdfReportBox className='performance-report-box'
                        title='Member Satisfaction'
                        caption='Positive Rating Percentage'
                        description='Calculated as the total number of satisfied member ratings divided by the total number of member ratings. This metric shows once there are more than 10 reviews.'
                        value={memberSatisfaction}
                        isPlaceholder={satisfactionTotalReviews < SATISFACTION_REVIEWS_MIN_LIMIT}
                        unit='%'/>
                </Column>
            </Row>
        </PageContent>
    );
};

PerformanceSummary.propTypes = {
    activationChartData: PropTypes.shape({
        datasets: PropTypes.array,
        labels: PropTypes.array
    }),
    isRoiVisible: PropTypes.bool,
    totalSavings: PropTypes.number,
    roi: PropTypes.number,
    utilizationTicketsCount: PropTypes.number,
    activatedEmployeesCount: PropTypes.number,
    eligibleEmployeesCount: PropTypes.number,
    activationRating: PropTypes.number,
    memberSatisfaction: PropTypes.number,
    satisfactionTotalReviews: PropTypes.number
};

export {PerformanceSummary as TestablePerformanceSummary};
export default PerformanceSummary;
