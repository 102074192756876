import EligibilityCensusFile from './EligibilityCensusFile';
import MedicalIds from './MedicalIds';
import DentalVisionIds from './DentalVisionIds';
import VoluntaryElections from './VoluntaryElections';
import Custom from './Custom';
import './index.scss';

export default {
    EligibilityCensusFile,
    MedicalIds,
    DentalVisionIds,
    VoluntaryElections,
    Custom
};
