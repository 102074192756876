import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Input from '@frontend/ui-kit/Components/Input';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {Field} from '../../shared/FormComponents';
import useFormState from '../../../hooks/useFormState';
import {TELEMED_ELIGIBILITY_OPTIONS, TELEMED_PROVIDER_OPTIONS, TELEMED_UTILIZATION_REPORT_OPTIONS} from '../../../options';
import {formatDate, normalizePositiveNumber} from '../../../utils';

const Telemedicine = ({isEditDisabled}) => {
    const {values} = useFormState();

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={4}>
                        <Field name='telemed_availability'>
                            {props => <Select {...props} options={TELEMED_ELIGIBILITY_OPTIONS} disabled={isEditDisabled} label='Eligibility'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.telemed_effective_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.telemed_expiration_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                </Row>

                <Separator/>

                <Row middle='xs'>
                    <Column sm={4}>
                        <Field name='deductible_telemed'>
                            {props => <Checkbox {...props} caption='Consult Fee Applied to Deductible' disabled={isEditDisabled}/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='telemed_provider'>
                            {props => <Select {...props} options={TELEMED_PROVIDER_OPTIONS} disabled={isEditDisabled} label='Provider'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='telemedicine_utilization_report'>
                            {props => <Select {...props} options={TELEMED_UTILIZATION_REPORT_OPTIONS} disabled={isEditDisabled} label='Utilization Report Type'/>}
                        </Field>
                    </Column>
                </Row>

                <Separator/>

                <Row>
                    <Column sm={4}>
                        <Field name='telemedicine_utilization_report_diff_mode'>
                            {props => <Checkbox {...props} caption='Telemedicine Utilization Report Diff Mode' disabled={isEditDisabled}/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='telemeds_ee_only'>
                            {props => <Checkbox {...props} caption='Exclude Dependents from Utilization Report' disabled={isEditDisabled}/>}
                        </Field>
                    </Column>
                </Row>
            </ContentSection>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>Consult Fees</Heading>

                <Row>
                    <Column sm={4}>
                        <Field name='consult_fee' icon={ICON_TYPES.money} parse={normalizePositiveNumber} placeholder='0.00'>
                            {props => <Input {...props} disabled={isEditDisabled} label='Telemedicine Fee'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Input value={values.hsa_consult_fee} icon={ICON_TYPES.money} placeholder='' disabled label='Telemedicine Fee - HSA Members'/>
                    </Column>
                </Row>
            </ContentSection>
        </div>
    );
};

Telemedicine.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {Telemedicine as TestableTelemedicine};
export default React.memo(Telemedicine);
