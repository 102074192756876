import {getEqual} from '../utils';

export const getCorePlans = state => state.benefits.corePlans;

export const getCorePlansByType = (state, type) => {
    const corePlans = getCorePlans(state);

    return corePlans.filter(getEqual(type, 'plan_kind'));
};

export const getAncillaryPlans = state => state.benefits.ancillaryPlans;

export const getPharmacyBenefitManagers = state => state.benefits.pharmacyBenefitManagers;

export const getMedicalTraits = state => state.benefits.medicalTraits;

export const getWebviewParams = state => state.benefits.webviewParams;

export const getIsCopyingEnabled = state => state.benefits.isCopyingEnabled;
