import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import {Form} from '../../Components/shared/FormComponents';
import DetailsActionBar from '../../Components/shared/DetailsActionBar';
import CustomizablePopup from '../../Components/shared/CustomizablePopup';
import AdvancedCustomizationForm, {validateOnSubmit as validateAdvancedCustomizationFormOnSubmit} from '../../Components/Benefits/AdvancedCustomizationForm';
import PlanFormHeader from '../../Components/Benefits/PlanFormHeader';
import withPopup from '../withPopup';
import withPlanCopying from '../withPlanCopying';
import {
    requestCorePlanDetails,
    requestCorePlanDetailsSetting,
    requestPlanDetailsUpdating,
    requestCoreConditionalVariablesSetting,
    setIsCopyingEnabled
} from '../../actions/benefits';
import {compose, getDottedObj, getErrorFieldNames, isEmpty, isError} from '../../utils';
import {FORMS} from '../../constants';

const POPUP_ID = 'sbcValidationPopup';

const withCorePlanDetails = ({recommendedFields = [], validate, validateOnSubmit}) => Component => {
    const WithCorePlanDetails = props => {
        const {
            title,
            planId,
            planType,
            isReadonly,
            isCustomizable,
            isCustomizedMode,
            setCustomizedMode,
            getCopiedPlanDetails,
            onSuccess,
            openPopup,
            closePopup,
            ...restProps
        } = props;
        const dispatch = useDispatch();
        const [initialValues, setInitialValues] = useState({});
        let form;

        useEffect(() => {
            (async () => {
                const {corePlanDetails, isSuccess} = await dispatch(requestCorePlanDetails(planType, planId));

                if (isSuccess) {
                    setInitialValues(corePlanDetails);
                }
            })();
        }, [dispatch, planType, planId]);

        const onOpenValidationPopup = ({markAsValid}) => {
            const content = `
                Our system is having trouble recognizing this file.
                Please check to make sure it is the appropriate file.
                If it is, you can ignore this message.
            `;
            const onMarkAsValid = () => {
                markAsValid();
                closePopup();
            };
            const actionBar = (
                <React.Fragment>
                    <Button data-testid='close-button' onClick={closePopup} type={BUTTON_TYPES.secondary}>Close</Button>
                    <Button data-testid='mark-as-valid-button' onClick={onMarkAsValid} type={BUTTON_TYPES.primary}>Mark as Valid</Button>
                </React.Fragment>
            );
            const popupProps = {title: 'Validation Error', content, actionBar};
            const children = <CustomizablePopup {...popupProps}/>;

            openPopup({type: POPUP_TYPES.simple, children});
        };

        const validateFile = async (file, fieldName, validationFieldName) => {
            const {corePlanDetails} = await dispatch(requestPlanDetailsUpdating(planType, planId, {[fieldName]: file}));
            const {[fieldName]: fileUrl, [validationFieldName]: isFileValid} = corePlanDetails;

            form.batch(() => {
                form.change(fieldName, fileUrl);
                form.change(validationFieldName, isFileValid);
            });

            if (isFileValid) {
                return false;
            }

            const markAsValid = () => form.change(validationFieldName, true);

            onOpenValidationPopup({markAsValid});
        };

        const onChangeValidatableFile = (fieldName, validationFieldName) => file => {
            if (!file || isError(file)) {
                return;
            }

            validateFile(file, fieldName, validationFieldName);
        };

        const copyPlan = useCallback((planDetails, isCopyingAcrossDifferentCompanies) => {
            const {customized: isPlanCustomized, blocks: copiedBlocks, ...copiedPlanDetails} = getCopiedPlanDetails(planDetails, isCopyingAcrossDifferentCompanies);

            dispatch(setIsCopyingEnabled(true));
            form.batch(() => {
                Object.entries(getDottedObj(copiedPlanDetails)).forEach(([fieldName, fieldValue]) => form.change(fieldName, fieldValue));
                // FYI: blocks have a lot of nesting therefore we are changing the "blocks" field in one operation to avoid the performance hit (Vlad, 01.09.21)
                form.change('blocks', copiedBlocks);
            });

            setCustomizedMode(isPlanCustomized);
        }, [dispatch, form, getCopiedPlanDetails, setCustomizedMode]);

        const onSubmit = useCallback(async values => {
            const {meta = {}, blocks, card_view: cardView, ...restValues} = values;
            const isCompleted = values.completed || meta.completed; // FYI: if plan is already completed according to requirements we need to send always true as "completed", even if it's save progress action (Pasha, 18.01.2021)

            const validationErrors = isCustomizedMode
                ? validateAdvancedCustomizationFormOnSubmit(values, {planType, isCustomizedMode})
                : validateOnSubmit(values, {planType, isCustomizedMode});
            if (meta.completed && !isEmpty(getErrorFieldNames(validationErrors))) {
                return validationErrors;
            }

            const enhancedValues = {...(isCustomizedMode ? values : restValues), completed: isCompleted};
            const {isSuccess, submissionErrors} = await dispatch(requestCorePlanDetailsSetting(planType, planId, enhancedValues));

            if (!isSuccess) {
                return submissionErrors;
            }

            setInitialValues(values);
        }, [dispatch, isCustomizedMode, planId, planType]);

        const DetailsForm = isCustomizedMode ? AdvancedCustomizationForm : Component;

        return (
            <Form key={planId} name={FORMS.planDetails} initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({handleSubmit, values, form: formInstance}) => {
                    form = formInstance;

                    const headerProps = {
                        title,
                        planId,
                        planType,
                        isReadonly,
                        isCustomizable,
                        isAvailabilityConditionShowed: true,
                        isCorePlan: true,
                        isDeactivated: !values.active,
                        onSubmit: handleSubmit,
                        copyPlan,
                        isCustomizedMode,
                        setCustomizedMode,
                        requestConditionalVariablesSetting: (planId, conditions) => dispatch(requestCoreConditionalVariablesSetting(planId, conditions)),
                        ...restProps
                    };
                    const componentProps = {planId, planType, isReadonly, onChangeValidatableFile, ...restProps};
                    const actionBarProps = {isReadonly, recommendedFields, onSuccess, onSubmit: handleSubmit};

                    return (
                        <form noValidate data-testid='plan-details-form'>
                            <PlanFormHeader {...headerProps}/>
                            <DetailsForm {...componentProps}/>
                            <DetailsActionBar {...actionBarProps}/>
                        </form>
                    );
                }}
            </Form>
        );
    };

    WithCorePlanDetails.propTypes = {
        isCustomizedMode: PropTypes.bool,
        isCustomizable: PropTypes.bool,
        isReadonly: PropTypes.bool,
        planType: PropTypes.string,
        title: PropTypes.string,
        planId: PropTypes.number,
        onSuccess: PropTypes.func,
        getCopiedPlanDetails: PropTypes.func,
        openPopup: PropTypes.func,
        closePopup: PropTypes.func,
        setCustomizedMode: PropTypes.func
    };

    return WithCorePlanDetails;
};

export {withCorePlanDetails as testableWithCorePlanDetails};
export default ({recommendedFields, validate, validateOnSubmit}) => compose(
    withPopup(POPUP_ID),
    withPlanCopying,
    withCorePlanDetails({recommendedFields, validate, validateOnSubmit})
);
